import {useAuthFunctions} from '../../AuthContext';
import BSCButton from '../common/bscButton/bscButton';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import BSCTypography from '../common/bscTypography/bscTypography';

export const SignOutButton = ({size14 = false, size16 = false, ...otherProps}) => {
    const authFunc = useAuthFunctions();

    const handleLogout = () => {
        authFunc.logout();
    };

    return (
        <BSCButton
            {...otherProps}
            onClick={handleLogout}
            variant="text"
            color="secondary"
            startIcon={<ExitToAppSharpIcon color="primary" {...otherProps} />}
        >
            <BSCTypography color="textPrimary" size14={size14} size16={size16} label="Sign Out"></BSCTypography>
        </BSCButton>
    );
};
