import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import BSCTypography from '../bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    spinnerWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gutterBottom: {
        marginBottom: theme.spacing(2),
    },
}));

function getLoadingReason(reasons) {
    for (const key in reasons) {
        if (!reasons[key]) continue;

        return key;
    }

    return null;
}

interface BSCLoadingProps {
    loading: boolean;
    loadingReasons?: object;
    gutterBottom?: boolean;
}

function BSCLoading({loading, loadingReasons = null, gutterBottom = false}: BSCLoadingProps) {
    const classes = useStyles();

    if (!loading) {
        return null;
    }

    return (
        <div className={classNames(classes.spinnerWrap, {[classes.gutterBottom]: gutterBottom})}>
            {loadingReasons && (
                <div>
                    <BSCTypography gutterBottom variant="h3" label={getLoadingReason(loadingReasons)} />
                </div>
            )}
            <CircularProgress color="secondary" size={80} />
        </div>
    );
}

export default BSCLoading;
