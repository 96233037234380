import React, {useState} from 'react';
import {DwollaFundingSourceCreate, useDwollaWeb} from '@dwolla/react-drop-ins';
import type {DwollaWebOptions} from '@dwolla/react-drop-ins';
import Grid from '@material-ui/core/Grid';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';

const DwollaWebClient = ({sellerUserApi, customerId, customerUrl, onDwollaComplete}) => {
    const {addToast} = useReduxToast();
    const [hasEstablishedFundingSource, setHasEstablishedFundingSource] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function dwollaAPIToken(req, additional) {
        const tokenUrl = `${process.env.REACT_APP_API_BASE_URL}/seller/user/dwolla-client-token`;
        const data = {
            action: req.action,
            _links: {
                customer: {href: customerUrl},
            },
        };
        // const data = {
        //     action: req.action,
        // };
        const headers = {
            'Content-Type': 'application/json',
        };
        return fetch(`${tokenUrl}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers,
        })
            .then(response => {
                return response.json();
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    const config: DwollaWebOptions = {
        environment: process.env.REACT_APP_DWOLLA_ENV,
        token: req => Promise.resolve(dwollaAPIToken(req, {})),
        onSuccess: async function success(response) {
            if (response.response?._embedded?.errors?.length > 0) {
                setHasEstablishedFundingSource(false);
            } else {
                setHasEstablishedFundingSource(true);
                try {
                    const dwollaResponse = await sellerUserApi.updateSellerPaymentSetting({customerUrl});
                    return dwollaResponse;
                } catch (error) {
                    console.log(error);
                    addToast({
                        severity: 'error',
                        message: 'Failed to interact with Dwolla.',
                        contextKey: 'DwollaWebClient',
                    });
                }
            }
        },
        onError: function error(err) {
            console.log('Error', err);
        },
    };

    const handleConfirm = () => {
        onDwollaComplete();
    };

    // Initialize the useDwollaWeb hook
    const {ready, error} = useDwollaWeb(config);
    if (!ready) return <div>Loading...</div>;
    if (error) return <div>Error</div>;

    return (
        <>
            {!hasEstablishedFundingSource && customerId && customerUrl && (
                <DwollaFundingSourceCreate customerId={customerId} initiateMicroDeposits />
            )}
            <div>
                {hasEstablishedFundingSource && (
                    <Grid container style={{textAlign: 'center'}}>
                        <Grid item xs={12} md={12} style={{textAlign: 'left', margin: '8px 16px 8px'}}>
                            <BSCTypography size16 style={{fontWeight: 700}}>
                                Thank you for sucessfully adding your bank account.
                            </BSCTypography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{textAlign: 'left', margin: '8px 16px 8px'}}>
                            <BSCTypography size16 style={{fontWeight: 700}}>
                                You will be sent two micro-deposits of less than 10 cents.
                                <br />
                                Please allow 2-3 business days to appear in your account.
                            </BSCTypography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{textAlign: 'left', margin: '8px 16px 8px'}}>
                            <BSCTypography size16 style={{fontWeight: 700}}>
                                Once you receive the deposits, please return to the Seller's Locker to verify those amounts.
                            </BSCTypography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{textAlign: 'left', margin: '8px 16px 8px'}}>
                            <BSCTypography size16 style={{fontWeight: 700}}>
                                You can now select "Ok" to return to the BSC homepage.
                            </BSCTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <BSCButton color="secondary" onClick={handleConfirm} style={{marginTop: 16}}>
                                Ok
                            </BSCButton>
                        </Grid>
                    </Grid>
                )}
            </div>
        </>
    );
};

export default DwollaWebClient;
