import * as constants from '../../../../shared/constants';
import {BulkUploadApi, BulkUploadResultsRequestProductTypeEnum} from '../../../../../src/client/openapitools/seller/api';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useHistory} from 'react-router-dom';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import BSCHeaderBlock from '../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import BSCSelect from '../../../../features/common/bscSelect/bscSelect';
import BSCTextField from '../../../../features/common/bscTextField/bscTextField';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCAccordion from '../../../../features/common/bscAccordion/bscAccordion';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
import SearchList from '../../../marketplace/pages/search/sidebar/searchList';

import {useProductReferenceContext} from '../../../../ProductReferenceContext';
import {currencyFormatter} from '../../../../packages/core/src/util/util';

import BulkUploadResults from './bulkUploadResults';
import AbandonConfirmationModal from './abandonConfirmationModal';
import RestrictedBulkUploadAdvisoryModal from './restrictedBulkUploadModal';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {SellerApi as SellersLockerSearchApi} from '../../../../client/openapitools/search/api';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {Configuration} from '../../../../client/openapitools/seller';

const useStyles = makeStyles(() => ({
    root: {
        background: 'none',
    },
    selectionsWrapper: {
        margin: '12px 0',
        padding: '12px',
        border: '#c9c9c9 3px solid',
        backgroundColor: '#fff',
    },
    fullWidth: {
        width: '100%',
        flexWrap: 'nowrap',
        height: 275,
        overflow: 'hidden',
    },
    selectDimensions: {
        height: 34,
        paddingTop: 10,
    },
    selectEllipsis: {
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

const BulkUpload = () => {
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let sellersLockerSearchApi = new SellersLockerSearchApi(apiAuthConfig);
    let bulkUploadApi = new BulkUploadApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const firstUpdate = useRef(true);
    const classes = useStyles();
    const history = useHistory();
    const {isMobile, isTablet} = useScreenSnap();
    const dispatch = useDispatch();
    const sellerState = useSelector((state: RootState) => state.seller);

    const {productReferences: cardRef} = useProductReferenceContext();
    const [open, setOpen] = useState(true);
    const handleClick = useCallback(() => setOpen(!open), [open, setOpen]);

    const [isVintage, setIsVintage] = useState(false);
    const [cardCondition, setCardCondition] = useState('');
    const [cardConditionDescription, setCardConditionDescription] = useState('');

    const [hasCompletedCategories, setHasCompletedCategories] = useState(false);
    const [hasProperSelections, setHasProperSelections] = useState(false);
    const [defaultPrice, setDefaultPrice] = useState('');
    const [defaultSKU, setDefaultSKU] = useState('');
    const [hasGeneratedResults, setHasGeneratedResults] = useState(false);

    const [aggregations, setAggregations] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [bulkUploadResults, setBulkUploadResults] = useState([]);
    const [toggleKey, setToggleKey] = useState(null);
    const handleToggleClick = key => {
        if (key === toggleKey) {
            setToggleKey(null);
        } else {
            setToggleKey(key);
        }
    };

    const resetCTAs = () => {
        setHasGeneratedResults(false);
        setHasProperSelections(false);
        setCardCondition('');
        setDefaultPrice('');
        setDefaultSKU('');
    };

    const handleConditionChange = e => {
        let selectedRawCondition;
        setCardCondition(e.target.value);
        if (isVintage) {
            selectedRawCondition = cardRef.conditionVintage.filter(rawCardCondition => rawCardCondition['key'] === e.target.value);
        } else {
            selectedRawCondition = cardRef.condition?.filter(rawCardCondition => rawCardCondition['key'] === e.target.value);
        }
        setCardConditionDescription(selectedRawCondition[0]?.name);
        if (e.target.value !== '') {
            setHasProperSelections(true);
        } else {
            setHasProperSelections(false);
        }
    };

    const handlePriceBlur = event => {
        let formattedPrice = event.target.value;
        if (event.target.value.indexOf('.') === -1) {
            formattedPrice = currencyFormatter(`${event.target.value}.00`);
        } else {
            formattedPrice = currencyFormatter(event.target.value);
        }
        if (event.target.value === '') {
            formattedPrice = '';
        } else {
            const numericPrice = Number(formattedPrice.replace(/[^0-9.-]+/g, ''));
            if (numericPrice < 0.25) {
                formattedPrice = currencyFormatter('0.25');
                toggleModalOpenAdvisory(true);
            }
            event.target.value = formattedPrice;
        }
        setDefaultPrice(formattedPrice);
    };

    const handleDefaultPriceChange = e => {
        setDefaultPrice(e.target.value);
    };

    const rebuildFilters = isGenerating => {
        setIsGenerating(isGenerating);
        const sportArray = sellerState.bulkUploadSelections.filter(selection => selection.category === 'sport');
        const yearArray = sellerState.bulkUploadSelections.filter(selection => selection.category === 'year');
        const setArray = sellerState.bulkUploadSelections.filter(selection => selection.category === 'setName');
        const variantArray = sellerState.bulkUploadSelections.filter(selection => selection.category === 'variant');
        const variantNameArray = sellerState.bulkUploadSelections.filter(selection => selection.category === 'variantName');

        const bulkUploadFilters = {};
        let outgoingArray;
        if (sportArray.length > 0) {
            outgoingArray = [];
            sportArray.forEach(arrayElement => outgoingArray.push(arrayElement.selection));
            bulkUploadFilters['sport'] = outgoingArray;
        }
        if (yearArray.length > 0) {
            outgoingArray = [];
            yearArray.forEach(arrayElement => outgoingArray.push(arrayElement.selection));
            bulkUploadFilters['year'] = outgoingArray;
        }
        if (setArray.length > 0) {
            outgoingArray = [];
            setArray.forEach(arrayElement => outgoingArray.push(arrayElement.selection));
            bulkUploadFilters['setName'] = outgoingArray;
        }
        if (variantArray.length > 0) {
            outgoingArray = [];
            variantArray.forEach(arrayElement => outgoingArray.push(arrayElement.selection));
            bulkUploadFilters['variant'] = outgoingArray;
        }
        if (variantNameArray.length > 0) {
            outgoingArray = [];
            variantNameArray.forEach(arrayElement => outgoingArray.push(arrayElement.selection));
            bulkUploadFilters['variantName'] = outgoingArray;
        }
        return bulkUploadFilters;
    };

    const generateSearch = () => {
        setHasGeneratedResults(true);
        const newFilters = rebuildFilters(true);
        const bulkUploadResultsRequest = {
            filters: newFilters,
            condition: cardCondition,
            productType: BulkUploadResultsRequestProductTypeEnum.Raw,
            currentListings: true,
        };
        bulkUploadApi.getBulkUploadResults(bulkUploadResultsRequest).then(res => {
            setBulkUploadResults(res?.data?.results);
        });
    };

    const [mode, setMode] = useState(null);
    const handleCTA = ctaMode => {
        setMode(ctaMode);
        if (sellerState?.bulkUploadFormDirty === true) {
            toggleModalOpen(true);
        } else {
            if (ctaMode === 'edit') {
                handleEdit();
            } else {
                handleReset();
            }
        }
    };

    const handleEdit = () => {
        setHasGeneratedResults(false);
        setIsGenerating(false);
    };

    const handleReset = () => {
        setAggregations([]);
        resetCTAs();
        dispatch({
            type: constants.SET_BULK_UPLOAD_SELECTIONS,
            payload: {category: 'sport', selection: ''},
        });
    };

    const [modalOpen, toggleModalOpen] = useState(false);
    const handleClose = e => {
        if (e === true) {
            if (mode === 'edit') {
                handleEdit();
            } else {
                handleReset();
            }
            dispatch({
                type: constants.BULK_UPLOAD_FORM_DIRTY,
                payload: {status: false},
            });
        }
        toggleModalOpen(false);
    };

    const [modalOpenAdvisory, toggleModalOpenAdvisory] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCloseAdvisory = e => {
        if (isMobile || isTablet) {
            history.push('/sellers');
        }
        toggleModalOpenAdvisory(false);
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            toggleModalOpenAdvisory(true);
        } else {
            if (apiAuthConfig.accessToken) {
                const requestFilters = {filters: {}};
                sellersLockerSearchApi.getFilters(requestFilters).then(res => {
                    setAggregations(res?.data?.aggregations);
                });
            }
        }
    }, [isMobile, isTablet, apiAuthConfig]);

    useEffect(() => {
        if (sellerState.bulkUploadSelections) {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                dispatch({
                    type: constants.RESET_BULK_UPLOAD_SELECTIONS,
                    payload: {},
                });
                return;
            }
            if (sellerState.bulkUploadSelections.length === 0) {
                resetCTAs();
            }
            // set the condition selections
            const yearSelection = sellerState.bulkUploadSelections.filter(bulkUploadSelection => bulkUploadSelection.category === 'year');
            if (yearSelection.length > 0) {
                const selectedYear = parseInt(yearSelection[0].selection, 10);
                if (selectedYear <= 1980) {
                    setIsVintage(true);
                } else {
                    setIsVintage(false);
                }
            } else {
                setIsVintage(false);
            }
            // enable/disable CTAs
            const variantSelection = sellerState.bulkUploadSelections.filter(
                bulkUploadSelection => bulkUploadSelection.category === 'variant'
            );
            const variantNameSelection = sellerState.bulkUploadSelections.filter(
                bulkUploadSelection => bulkUploadSelection.category === 'variantName'
            );
            if (variantSelection.length > 0) {
                if (variantSelection[0].selection === 'base') {
                    setHasCompletedCategories(true);
                } else {
                    if (variantNameSelection.length > 0) {
                        setHasCompletedCategories(true);
                    } else {
                        setHasCompletedCategories(false);
                        resetCTAs();
                    }
                }
            } else {
                setHasCompletedCategories(false);
                resetCTAs();
            }
            const newFilters = rebuildFilters(false);
            const requestFilters = {filters: newFilters};
            if (!apiAuthConfig.accessToken) {
                apiAuthConfig.accessToken = localStorage.getItem('accessToken');
            }
            try {
                sellersLockerSearchApi = new SellersLockerSearchApi(apiAuthConfig);
                sellersLockerSearchApi.getFilters(requestFilters).then(res => {
                    setAggregations(res?.data?.aggregations);
                });
            } catch (error) {
                addToast({
                    severity: 'error',
                    message: 'Error fetching filters image.',
                    contextKey: 'Bulk Upload',
                });
            }
        }
    }, [sellerState.bulkUploadSelections]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            sellersLockerSearchApi = new SellersLockerSearchApi(apiConfig);
                            bulkUploadApi = new BulkUploadApi(apiConfig);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Error preparing Bulk Upload.',
                                contextKey: 'Bulk Upload',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <>
            <div className={classes.root}>
                <BSCHeaderBlock align="center" label="Bulk Upload" size18 />
            </div>
            {!aggregations && <BSCLoading loading={true}></BSCLoading>}
            {aggregations && !isMobile && !isTablet && (
                <>
                    <div className={classes.selectionsWrapper}>
                        <BSCAccordion open={open} label="Filters" onAccordionClick={handleClick} style={{marginBottom: 0}} />
                        <Collapse in={open}>
                            <Grid container xs={12} className={classes.fullWidth}>
                                <Grid
                                    item
                                    md={2}
                                    justifyContent="center"
                                    style={aggregations.sport ? {visibility: 'visible', flex: '1 auto'} : {visibility: 'hidden'}}
                                >
                                    <div style={{maxHeight: 240}}>
                                        <SearchList
                                            searchTermKey="sport"
                                            menuItems={aggregations?.sport}
                                            toggleKey={toggleKey}
                                            onListClick={handleToggleClick}
                                            isBulkUpload={true}
                                            hasGeneratedResults={hasGeneratedResults}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    justifyContent="center"
                                    style={aggregations.year ? {visibility: 'visible', flex: '1 auto'} : {visibility: 'hidden'}}
                                >
                                    <div style={{maxHeight: 240}}>
                                        <SearchList
                                            searchTermKey="year"
                                            menuItems={aggregations?.year}
                                            toggleKey={toggleKey}
                                            onListClick={handleToggleClick}
                                            isBulkUpload={true}
                                            hasGeneratedResults={hasGeneratedResults}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    ms={2}
                                    justifyContent="center"
                                    style={aggregations.setName ? {visibility: 'visible', flex: '1 auto'} : {visibility: 'hidden'}}
                                >
                                    <div style={{maxHeight: 240}}>
                                        <SearchList
                                            searchTermKey="setName"
                                            menuItems={aggregations?.setName}
                                            toggleKey={toggleKey}
                                            onListClick={handleToggleClick}
                                            isBulkUpload={true}
                                            hasGeneratedResults={hasGeneratedResults}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    justifyContent="center"
                                    style={aggregations.variant ? {visibility: 'visible', flex: '1 auto'} : {visibility: 'hidden'}}
                                >
                                    <div style={{maxHeight: 240}}>
                                        <SearchList
                                            searchTermKey="variant"
                                            menuItems={aggregations?.variant}
                                            toggleKey={toggleKey}
                                            onListClick={handleToggleClick}
                                            isBulkUpload={true}
                                            hasGeneratedResults={hasGeneratedResults}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    justifyContent="center"
                                    style={
                                        aggregations.variantName && aggregations.variantName.length > 0
                                            ? {visibility: 'visible', flex: '1 auto'}
                                            : {visibility: 'hidden'}
                                    }
                                >
                                    <div style={{maxHeight: 240}}>
                                        <SearchList
                                            searchTermKey="variantName"
                                            menuItems={aggregations?.variantName}
                                            toggleKey={toggleKey}
                                            onListClick={handleToggleClick}
                                            isBulkUpload={true}
                                            hasGeneratedResults={hasGeneratedResults}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={2} justifyContent="center" style={{marginTop: 16}}>
                                    <Grid container xs={12}>
                                        <Grid item xs={6}>
                                            <BSCTypography
                                                className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                label="Condition:"
                                                semibold
                                                size16
                                                style={{paddingTop: 14}}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <BSCSelect
                                                style={{width: 114}}
                                                value={cardCondition}
                                                onInputChange={e => handleConditionChange(e)}
                                                disabled={!hasCompletedCategories || hasGeneratedResults}
                                            >
                                                <MenuItem disabled key="placeHolder" value="">
                                                    <BSCTypography
                                                        className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                        label="Select..."
                                                    />
                                                </MenuItem>
                                                {!isVintage &&
                                                    cardRef?.condition?.map(cardRefCondition => {
                                                        return (
                                                            <MenuItem key={cardRefCondition.key} value={cardRefCondition.key}>
                                                                <BSCTypography
                                                                    className={classes.selectDimensions}
                                                                    label={cardRefCondition.name}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}

                                                {isVintage &&
                                                    cardRef?.conditionVintage?.map(cardRefCondition => {
                                                        return (
                                                            <MenuItem key={cardRefCondition.key} value={cardRefCondition.key}>
                                                                <BSCTypography
                                                                    className={classes.selectDimensions}
                                                                    label={cardRefCondition.name}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </BSCSelect>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} style={{marginTop: 16}}>
                                        <Grid item xs={6}>
                                            <BSCTypography
                                                className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                label="Default Price:"
                                                semibold
                                                size16
                                                style={{paddingTop: 14}}
                                            />
                                            <BSCTypography
                                                label="(Optional)"
                                                size14
                                                style={{position: 'relative', top: -12, color: '#aaa'}}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <BSCTextField
                                                value={defaultPrice}
                                                onBlur={handlePriceBlur}
                                                onChange={e => {
                                                    const re = /^(\d*\.{0,1}\d{0,2}$)/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        handleDefaultPriceChange(e);
                                                    }
                                                }}
                                                style={
                                                    !hasCompletedCategories || hasGeneratedResults
                                                        ? {backgroundColor: 'rgba(0, 0, 0, 0.12)'}
                                                        : {backgroundColor: 'white'}
                                                }
                                                disabled={!hasCompletedCategories || hasGeneratedResults}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12}>
                                        <Grid item xs={6}>
                                            <BSCTypography
                                                className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                label="Default SKU:"
                                                semibold
                                                size16
                                                style={{paddingTop: 14}}
                                            />
                                            <BSCTypography
                                                label="(Optional)"
                                                size14
                                                style={{position: 'relative', top: -12, color: '#aaa'}}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <BSCTextField
                                                style={{marginRight: 4}}
                                                value={defaultSKU}
                                                onChange={e => setDefaultSKU(e.target.value)}
                                                style={
                                                    !hasCompletedCategories || hasGeneratedResults
                                                        ? {backgroundColor: 'rgba(0, 0, 0, 0.12)'}
                                                        : {backgroundColor: 'white'}
                                                }
                                                disabled={!hasCompletedCategories || hasGeneratedResults}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} style={{marginTop: 16}}>
                                        {hasGeneratedResults && (
                                            <>
                                                <Grid item xs={6} style={{paddingRight: 8}}>
                                                    <BSCButton fullWidth color="secondary" onClick={() => handleCTA('reset')}>
                                                        <BSCTypography label="Reset" size14></BSCTypography>
                                                    </BSCButton>
                                                </Grid>
                                                <Grid item xs={6} style={{paddingLeft: 8}}>
                                                    <BSCButton fullWidth color="primary" onClick={() => handleCTA('edit')}>
                                                        <BSCTypography label="Edit" size14></BSCTypography>
                                                    </BSCButton>
                                                </Grid>
                                            </>
                                        )}
                                        {!hasGeneratedResults && (
                                            <BSCButton fullWidth color="secondary" onClick={generateSearch} disabled={!hasProperSelections}>
                                                <BSCTypography label="Generate" size14></BSCTypography>
                                            </BSCButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </div>

                    {isGenerating && (
                        <BulkUploadResults
                            cardCondition={cardConditionDescription}
                            defaultPrice={defaultPrice}
                            defaultSKU={defaultSKU}
                            products={bulkUploadResults}
                        />
                    )}
                </>
            )}
            <AbandonConfirmationModal open={modalOpen} onClose={(e: boolean) => handleClose(e)} />
            <RestrictedBulkUploadAdvisoryModal
                open={modalOpenAdvisory}
                onClose={(e: boolean) => handleCloseAdvisory(e)}
                advisoryMessage="$0.25 Minimum Price Required"
            />
        </>
    );
};

export default BulkUpload;
