import React, {useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../../../../features/common/skeletons/BSCTableRowSkeleton';
import BSCCurrency from '../../../../../../../features/common/bscCurrency/bscCurrency';
import Grid from '@material-ui/core/Grid';
import {Product} from '../../../../../../../client/openapitools/marketplace/api';
import BSCHeaderBlock from '../../../../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import {useCardNormalizer} from '../../../../../../../client/common/card/cardNormalizer';
import BscImagePopper from '../../../../../../../features/common/bscImagePopper/bscImagePopper';
import BSCImage from '../../../../../../../features/common/bscImage/bscImage';
import Normalizer from '../../../../../../../client/common/normalizer/normalizer';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: '#F5F8FA',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '8px',
        padding: theme.spacing(0),
        '& > MuiSelect-icon': {
            color: '#EA3E2E !important',
        },
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    th: {
        color: '#41474C',
        textAlign: 'center',
        padding: '8px 16px 8px 12px',
        background: '#DAE0E6',
        borderBottom: 'none',
        fontSize: '16px',
    },
    td: {
        background: '#F5F8FA',
        borderBottom: '2px solid #E9EDF2',
        textAlign: 'center',

        '& p': {
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.subtitle2.fontSize,
            },
        },
    },
    cardText: {
        fontSize: 12,
    },
    cardTextMobile: {
        fontSize: 10,
    },
}));

interface CardsProps {
    data: Product[];
    loading?: boolean;
    hasTitle?: boolean;
}

const Cards = ({data, loading, hasTitle = true}: CardsProps) => {
    const classes = useStyles();
    const normalizer = useCardNormalizer();
    const {isDesktop} = useScreenSnap();

    const [hasSellerSku, setHasSellerSku] = useState(false);
    useEffect(() => {
        if (data) {
            data.forEach(item => {
                if (item['sellerSku']) {
                    setHasSellerSku(true);
                }
            });
        }
    }, [data]);

    return (
        <BSCCardBox className={classes.root}>
            {hasTitle && <BSCHeaderBlock label="Cards" />}
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell key="name" className={classes.th}>
                                <Grid container alignItems="center" style={{flexDirection: 'row'}}>
                                    <Grid item md={hasSellerSku ? 5 : 8} xs={hasSellerSku ? 5 : 8}>
                                        <BSCTypography label="Name" />
                                    </Grid>
                                    {hasSellerSku && (
                                        <Grid item md={3} xs={3}>
                                            <BSCTypography label="Seller SKU" />
                                        </Grid>
                                    )}
                                    <Grid item md={2} xs={2}>
                                        <BSCTypography label="Price" />
                                    </Grid>
                                    <Grid item md={2} xs={2}>
                                        <BSCTypography label="QTY" />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && <BSCTableRowSkeleton amountOfRows={2} amountOfCells={hasSellerSku ? 4 : 3} />}
                        {data?.map(prod => {
                            let isVintage = false;
                            if (Number(prod.card.year) <= 1980) {
                                isVintage = true;
                            } else {
                                isVintage = false;
                            }
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={prod.card.cardNo}>
                                    <TableCell key="name" style={{paddingLeft: '16px 8px 16px 12px'}}>
                                        <Grid container alignItems="center" style={{flexDirection: 'row'}}>
                                            <Grid item md={2} xs={2}>
                                                <BscImagePopper
                                                    id={`${prod.productId}-front-popper`}
                                                    imageUrl={Normalizer.getS3ImagePath(prod.sellerImgFront)}
                                                >
                                                    <BSCImage
                                                        style={{maxHeight: 75}}
                                                        src={Normalizer.getS3ImagePath(prod.sellerImgFront)}
                                                    ></BSCImage>
                                                </BscImagePopper>
                                            </Grid>
                                            <Grid item md={hasSellerSku ? 3 : 6} xs={hasSellerSku ? 3 : 6} style={{padding: '4px 16px'}}>
                                                <BSCTypography
                                                    className={isDesktop ? classes.cardText : classes.cardTextMobile}
                                                    label={normalizer.getPlayerName(prod.card)}
                                                />
                                                <BSCTypography
                                                    className={isDesktop ? classes.cardText : classes.cardTextMobile}
                                                    label={normalizer.getSetAndVariant(prod.card)}
                                                />
                                                <BSCTypography
                                                    className={isDesktop ? classes.cardText : classes.cardTextMobile}
                                                    label={normalizer.getProductTypeAndCondition(prod, isVintage)}
                                                />
                                            </Grid>
                                            {hasSellerSku && (
                                                <Grid item md={3} xs={3}>
                                                    <BSCTypography
                                                        className={isDesktop ? classes.cardText : classes.cardTextMobile}
                                                        label={prod?.sellerSku}
                                                        style={{textAlign: 'center'}}
                                                    />
                                                </Grid>
                                            )}

                                            <Grid item md={2} xs={2}>
                                                <BSCCurrency
                                                    className={isDesktop ? classes.cardText : classes.cardTextMobile}
                                                    amount={prod.price}
                                                    style={{textAlign: 'center'}}
                                                />
                                            </Grid>
                                            <Grid item md={2} xs={2}>
                                                <BSCTypography
                                                    className={isDesktop ? classes.cardText : classes.cardTextMobile}
                                                    label={prod.orderQuantity}
                                                    style={{textAlign: 'center'}}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </BSCCardBox>
    );
};

export default Cards;
