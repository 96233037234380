import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as constants from '../../../../shared/constants';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles(() => ({
    bannerWrap: {
        padding: 0,
        alignItems: 'center',
        margin: 8,
        backgroundColor: '#fae6e6',
        borderRadius: 4,
        minHeight: 52,
    },
    wideBannerWrap: {
        padding: 0,
        alignItems: 'center',
        margin: '8px 0',
        width: 'auto',
        backgroundColor: '#fae6e6',
        borderRadius: 4,
        minHeight: 52,
    },
    noItalic: {
        fontSize: 12,
        right: '0px',
        backgroundColor: '#bd1c1c',
        minWidth: 132,
    },
}));

interface StorefrontBannerProps {
    sellerId: string;
    sellerName: string;
    cta: string;
    hasWideBanner?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function StorefrontBanner({sellerId, sellerName, cta, hasWideBanner = false}: StorefrontBannerProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isDesktop} = useScreenSnap();

    const handleLeave = () => {
        if (cta === 'Leave') {
            dispatch({
                type: constants.SELLER_STOREFRONT_RESET,
                payload: {},
            });
        } else {
            dispatch({
                type: constants.SHOW_ALL_LISTINGS,
                payload: true,
            });
        }
    };

    const [selectedSellerName, setSelectedSellerName] = useState(sellerName);
    useEffect(() => {
        if (isDesktop) {
            setSelectedSellerName(sellerName);
        } else {
            if (sellerName?.length > 18) {
                setSelectedSellerName(sellerName.slice(0, 18) + '...');
            } else {
                setSelectedSellerName(sellerName);
            }
        }
    }, [isDesktop]);

    return (
        <Grid container spacing={1} className={hasWideBanner ? classes.wideBannerWrap : classes.bannerWrap}>
            <Grid item container md={12}>
                <Grid item md={10} style={{flex: 1, paddingTop: 8}}>
                    <BSCTypography
                        style={{display: 'inline-flex', padding: '0 4px', color: '#600e0e'}}
                        size14
                        normal={true}
                        label="Shopping from:"
                    />
                    <BSCTypography
                        style={{display: 'inline-flex', padding: '0 4px', color: '#600e0e'}}
                        size14
                        bold={true}
                        label={selectedSellerName}
                    />
                </Grid>
                <Grid
                    item
                    md={2}
                    style={!isDesktop ? {flex: 0, margin: 'auto', paddingRight: 8} : {flex: 0, marginLeft: 'auto', paddingRight: 8}}
                >
                    <BSCButton className={classes.noItalic} color="primary" onClick={handleLeave}>
                        {cta}
                    </BSCButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default StorefrontBanner;
