import React, {MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BSCLoading from '../../../../../features/common/bscLoading/bscLoading';
import BSCRadio from '../../../../../features/common/bscRadio/bscRadio';
import BSCSelect from '../../../../../features/common/bscSelect/bscSelect';
import BSCTextField from '../../../../../features/common/bscTextField/bscTextField';
import MenuItem from '@material-ui/core/MenuItem';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import {Alert} from '@material-ui/lab';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import {Configuration, ShippingApi} from '../../../../../client/openapitools/seller';
import {OrderApi} from '../../../../../client/openapitools/seller/api';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, AuthenticationResult, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../authConfig';

const styles = {
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    disclaimer: {
        fontSize: 12,
    },
};

const useStyles = makeStyles(() => ({
    required: {
        '&:after': {
            fontSize: 14,
            content: '"*"',
            color: '#f00',
            display: 'inline-block',
        },
    },
}));

function BuyShippingForm({
    orderId,
    onCreateShippingLabel,
    onCancelBuy,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getSellersOrderInstance,
    orderTotal,
}: {
    orderId: string;
    onCreateShippingLabel: Function;
    onCancelBuy: MouseEventHandler<HTMLButtonElement>;
    getSellersOrderInstance: string;
    orderTotal: number;
}) {
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let shippingApi = new ShippingApi(apiAuthConfig);
    let orderApi = new OrderApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {isDesktop} = useScreenSnap();
    const THRESHOLD_AMOUNT = 500.0;
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [carrierPackages, setCarrierPackages] = useState(null);
    const [shippingPackages, setShippingPackages] = useState(null);
    const [carrierPackagesLoading, setCarrierPackagesLoading] = useState(false);
    const [shippingLabelLoading, setShippingLabelLoading] = useState(false);
    const loading = carrierPackagesLoading || shippingLabelLoading;
    const reasons = {
        ['Loading Package Info']: carrierPackagesLoading,
        ['Creating Shipping Label']: shippingLabelLoading,
    };
    const handleCreateShippingLabel = useCallback(
        reqBody => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                setShippingLabelLoading(true);
                                orderApi = new OrderApi(apiConfig);
                                const response = await orderApi.createLabel(reqBody, orderId);
                                if (response.status === 200) {
                                    onCreateShippingLabel(orderId, reqBody);
                                    setShippingLabelLoading(false);
                                }
                            } catch (error) {
                                setShippingLabelLoading(false);
                                console.error('Error creating shipping label: ', error);
                                addToast({
                                    severity: 'error',
                                    message: 'Error creating shipping label.',
                                    contextKey: 'Shipping',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        },
        [orderId, onCreateShippingLabel]
    );

    const [isCompleteShippingForm, setCompleteShippingForm] = useState(false);
    const [shippingFormValues, setShippingFormValues] = useState({
        shippingPackageType: '',
        shippingWeight: '',
        shippingWeightUnit: 'ounce',
        shippingConfirmation: orderTotal >= THRESHOLD_AMOUNT ? 'signature' : 'delivery',
        shippingInsurePackage: false,
        shippingPreference: null,
    });

    const [hasBSCStandardEnvelope, setHasBSCStandardEnvelope] = useState(false);
    const [filteredShippingServices, setFilteredShippingServices] = useState(null);
    const [shippingRateLoading, setShippingRateLoading] = useState(false);
    const [shippingRateData, setShippingRateData] = useState(null);

    useEffect(() => {
        if (shippingRateData) {
            setFilteredShippingServices(
                shippingRateData?.data?.rate_response?.rates
                    .filter(x => x.package_type === shippingFormValues.shippingPackageType)
                    .sort((first, second) => first.total_shipment_amount - second.total_shipment_amount)
            );
        }
    }, [shippingRateData]);

    const onChangeShippingPackageType = e => {
        setShippingFormValues(values => ({
            ...values,
            shippingPackageType: e.target.value,
            shippingPreference: null,
        }));
        if (e.target.value === 'bsc_standard_envelope') {
            setHasBSCStandardEnvelope(true);
        } else {
            setHasBSCStandardEnvelope(false);
        }
    };

    const onChangeShippingWeight = e => {
        setShippingFormValues(values => ({
            ...values,
            shippingWeight: e.target.value,
            shippingPreference: null,
        }));
    };

    const [bscStandardEnvelopeShippingWeight, setBSCStandardEnvelopeShippingWeight] = useState('');
    const onChangeBSCStandardEnvelopeShippingWeight = e => {
        setBSCStandardEnvelopeShippingWeight(e.target.value);
        setShippingFormValues(values => ({
            ...values,
            shippingWeight: e.target.value,
            shippingWeightUnit: 'ounce',
            shippingPreference: null,
        }));
    };

    const onChangeShippingWeightUnit = e => {
        setShippingFormValues(values => ({
            ...values,
            shippingWeightUnit: e.target.value,
            shippingPreference: null,
        }));
    };

    const handleShippingConfirmation = e => {
        setShippingFormValues(values => ({
            ...values,
            shippingConfirmation: e.target.value,
            shippingPreference: null,
        }));
    };

    const handleShippingInsurance = (isInsuring: boolean) => {
        setShippingFormValues(values => ({
            ...values,
            shippingInsurePackage: isInsuring,
            shippingPreference: null,
        }));
    };

    const handleShippingPreference = e => {
        setShippingFormValues(values => ({
            ...values,
            shippingPreference: e.target.value,
        }));
    };

    useEffect(() => {
        setCompleteShippingForm(false);
        if (shippingFormValues.shippingPackageType.length > 0 && shippingFormValues.shippingWeight.length > 0) {
            if (shippingFormValues.shippingPreference === null) {
                const account = instance.getActiveAccount();
                if (account !== null) {
                    const tokenRequest: SilentRequest = {
                        account: account as AccountInfo | undefined,
                        scopes: AUTH_REQUESTS.LOGIN.scopes,
                    };
                    instance
                        .acquireTokenSilent(tokenRequest)
                        .then(async (response: AuthenticationResult) => {
                            if (response.accessToken) {
                                setShippingRateLoading(true);
                                const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                                try {
                                    const getShippingRatesConfig = {
                                        package_code: shippingFormValues.shippingPackageType,
                                        // @ts-ignore
                                        // Name exists its just not on the swagger for some reason
                                        name: shippingPackages?.find(x => x.package_code === shippingFormValues.shippingPackageType).name,
                                        weight: {
                                            value: Number(shippingFormValues.shippingWeight),
                                            unit: shippingFormValues.shippingWeightUnit,
                                        },
                                        insurance: shippingFormValues.shippingInsurePackage,
                                        confirmation: shippingFormValues.shippingConfirmation,
                                    };
                                    orderApi = new OrderApi(apiConfig);
                                    const shippingRatesResponse = await orderApi.getShippingRates(getShippingRatesConfig, orderId);
                                    if (shippingRatesResponse.status === 200) {
                                        setShippingRateData(shippingRatesResponse);
                                        setShippingRateLoading(false);
                                    }
                                } catch (error) {
                                    setShippingRateLoading(false);
                                    console.error('Error getting shipping rates: ', error);
                                    addToast({
                                        severity: 'error',
                                        message: 'Error getting shipping rates.',
                                        contextKey: 'Shipping',
                                    });
                                }
                            }
                        })
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        .catch(error => {
                            addToast({
                                severity: 'error',
                                message: 'User must be Signed In.',
                                contextKey: 'Authentication',
                            });
                            setTimeout(() => {
                                authFunc.logout();
                            }, 5000);
                        });
                }
            }
        }
        if (shippingFormValues.shippingPreference !== null) {
            setCompleteShippingForm(true);
        }
    }, [shippingFormValues]);

    const handleSubmit = e => {
        e.preventDefault();
        const shippingLabelInfo = {
            service_code: shippingFormValues.shippingPreference,
            package: {
                package_code: shippingFormValues.shippingPackageType,
                weight: {
                    value: Number(shippingFormValues.shippingWeight),
                    unit: shippingFormValues.shippingWeightUnit,
                },
                insurance: shippingFormValues.shippingInsurePackage,
                confirmation: shippingFormValues.shippingConfirmation,
            },
        };
        handleCreateShippingLabel(shippingLabelInfo);
    };

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            setCarrierPackagesLoading(true);
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            orderApi = new OrderApi(apiConfig);
                            shippingApi = new ShippingApi(apiConfig);
                            const carrierPackagesResponse = await shippingApi.getCarrierPackages();
                            if (carrierPackagesResponse.status === 200) {
                                setCarrierPackages(carrierPackagesResponse.data);
                                setShippingPackages(carrierPackagesResponse.data.packages);
                                setCarrierPackagesLoading(false);
                            }
                        } catch (error) {
                            console.error('Error getting carrier packages: ', error);
                            addToast({
                                severity: 'error',
                                message: 'Error getting carrier packages.',
                                contextKey: 'Shipping',
                            });
                            setCarrierPackagesLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    if (loading) {
        return <BSCLoading loading={loading} loadingReasons={reasons} />;
    }

    return (
        <form style={{width: '100%'}}>
            <Grid container>
                <Grid item container md={8} xs={12} style={{padding: '0 12px'}}>
                    <Grid item md={7} xs={12}>
                        <BSCTypography
                            className={classes.required}
                            style={{textDecoration: 'underline', fontWeight: 600, fontSize: 16}}
                            label="Package"
                        />
                        <BSCSelect value={shippingFormValues.shippingPackageType} onInputChange={onChangeShippingPackageType}>
                            <MenuItem value="" autoFocus={true}>
                                <BSCTypography label="Select" />
                            </MenuItem>
                            {shippingPackages?.map((shippingPackage, index) => (
                                <MenuItem key={index} value={shippingPackage.package_code}>
                                    <BSCTypography label={shippingPackage.name} />
                                </MenuItem>
                            ))}
                        </BSCSelect>
                    </Grid>

                    <Grid item md={5} xs={12} style={!isDesktop ? {paddingTop: 32} : {}}>
                        <BSCTypography
                            className={classes.required}
                            style={{textDecoration: 'underline', fontWeight: 600, fontSize: 16}}
                            label="Weight"
                        />
                        {!hasBSCStandardEnvelope && (
                            <>
                                <BSCTextField
                                    value={shippingFormValues.shippingWeight}
                                    style={{
                                        flexDirection: 'row',
                                        height: 26,
                                        width: 64,
                                        marginRight: 4,
                                        fontFamily: 'Poppins',
                                        fontSize: 14,
                                        textAlign: 'right',
                                        backgroundColor: '#fff',
                                    }}
                                    onChange={e => {
                                        const re = /^(\d*\.{0,1}\d{0,2}$)/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            onChangeShippingWeight(e);
                                        }
                                    }}
                                    inputKey={''}
                                />
                                <BSCSelect value={shippingFormValues.shippingWeightUnit} onInputChange={onChangeShippingWeightUnit}>
                                    <MenuItem value="ounce" autoFocus={true}>
                                        <BSCTypography label="oz" />
                                    </MenuItem>
                                    <MenuItem value="pound">
                                        <BSCTypography label="lb" />
                                    </MenuItem>
                                    <MenuItem value="gram">
                                        <BSCTypography label="g" />
                                    </MenuItem>
                                    <MenuItem value="kilogram">
                                        <BSCTypography label="kg" />
                                    </MenuItem>
                                </BSCSelect>
                            </>
                        )}
                        {hasBSCStandardEnvelope && (
                            <BSCSelect value={bscStandardEnvelopeShippingWeight} onInputChange={onChangeBSCStandardEnvelopeShippingWeight}>
                                <MenuItem value="1" autoFocus={true}>
                                    <BSCTypography label="1 oz." />
                                </MenuItem>
                                <MenuItem value="2">
                                    <BSCTypography label="2 oz." />
                                </MenuItem>
                                <MenuItem value="3">
                                    <BSCTypography label="3 oz." />
                                </MenuItem>
                            </BSCSelect>
                        )}
                    </Grid>

                    {hasBSCStandardEnvelope && (
                        <Grid container md={12} xs={12} style={{paddingTop: 32}}>
                            <BSCTypography
                                style={styles.disclaimer}
                                label="A plain, number 10 envelope works just fine, but it’s not required. You can use any envelope that fits the following specifications:"
                            />
                            <ul>
                                <li>
                                    <BSCTypography style={styles.disclaimer} label="No smaller than 3.5” x 5”" />
                                </li>
                                <li>
                                    <BSCTypography style={styles.disclaimer} label="No larger than 6.125” x 11.5”" />
                                </li>
                                <li>
                                    <BSCTypography style={styles.disclaimer} label="No heavier than 3 oz." />
                                </li>
                                <li>
                                    <BSCTypography style={styles.disclaimer} label="No plastic, strings or closures " />
                                </li>
                                <li>
                                    <BSCTypography
                                        style={styles.disclaimer}
                                        label="Recipient address is parallel to envelope’s longest side."
                                    />
                                </li>
                                <li>
                                    <BSCTypography style={styles.disclaimer} label="Uniform thickness not greater than .25”" />
                                </li>
                            </ul>
                        </Grid>
                    )}

                    {!hasBSCStandardEnvelope && (
                        <>
                            <Grid container md={12} xs={12} style={{paddingTop: 32}}>
                                <Grid item md={7} xs={7}>
                                    <BSCTypography
                                        className={classes.required}
                                        style={{textDecoration: 'underline', fontWeight: 600, fontSize: 16}}
                                        label="Confirmation"
                                    />
                                </Grid>
                                <Grid item md={5} xs={5}>
                                    <BSCTypography
                                        className={classes.required}
                                        style={{textDecoration: 'underline', fontWeight: 600, fontSize: 16}}
                                        label="Insurance"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container md={12} xs={12}>
                                <Grid item container md={7} xs={7} style={{padding: 0}}>
                                    <Grid item md={12} xs={12}>
                                        <BSCRadio
                                            id="deliveryConfirmation"
                                            name="confirmation"
                                            value="delivery"
                                            disabled={orderTotal >= THRESHOLD_AMOUNT}
                                            checked={shippingFormValues.shippingConfirmation === 'delivery'}
                                            onChange={handleShippingConfirmation}
                                            style={{filter: 'drop-shadow(0px 1px 1px)', padding: '4px 0'}}
                                        />
                                        <label htmlFor="deliveryConfirmation" style={{marginLeft: 4, fontFamily: 'Poppins', fontSize: 12}}>
                                            Delivery
                                        </label>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <BSCRadio
                                            id="signatureConfirmation"
                                            name="confirmation"
                                            value="signature"
                                            checked={shippingFormValues.shippingConfirmation === 'signature'}
                                            onChange={handleShippingConfirmation}
                                            style={{filter: 'drop-shadow(0px 1px 1px)', padding: '4px 0'}}
                                        />
                                        <label htmlFor="signatureConfirmation" style={{marginLeft: 4, fontFamily: 'Poppins', fontSize: 12}}>
                                            Signature
                                        </label>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <BSCTypography
                                            size10
                                            style={
                                                shippingFormValues.shippingConfirmation === 'delivery' && orderTotal >= THRESHOLD_AMOUNT
                                                    ? {color: '#f00', paddingRight: 16, minHeight: 32}
                                                    : {visibility: 'hidden', minHeight: 32}
                                            }
                                            label="Signature Confirmation Required on Orders over $500"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container md={5} xs={5} style={{padding: 0}}>
                                    <Grid item md={12} xs={12}>
                                        <BSCRadio
                                            id="shippingInsuranceYes"
                                            name="shippingInsurance"
                                            checked={shippingFormValues.shippingInsurePackage}
                                            /* eslint-disable */
                                            onClick={e => handleShippingInsurance(true)}
                                            style={{filter: 'drop-shadow(0px 1px 1px)', padding: '4px 0'}}
                                        />
                                        <label htmlFor="shippingInsuranceNo" style={{marginLeft: 4, fontFamily: 'Poppins', fontSize: 12}}>
                                            Yes
                                        </label>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <BSCRadio
                                            id="shippingInsuranceNo"
                                            name="shippingInsurance"
                                            checked={!shippingFormValues.shippingInsurePackage}
                                            /* eslint-disable */
                                            onClick={e => handleShippingInsurance(false)}
                                            style={{filter: 'drop-shadow(0px 1px 1px)', padding: '4px 0'}}
                                        />
                                        <label htmlFor="shippingInsuranceNo" style={{marginLeft: 4, fontFamily: 'Poppins', fontSize: 12}}>
                                            No
                                        </label>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <BSCTypography
                                            size10
                                            style={
                                                shippingFormValues.shippingInsurePackage
                                                    ? {color: '#358856', paddingRight: 16, minHeight: 32}
                                                    : {visibility: 'hidden', minHeight: 32}
                                            }
                                            label={`Insured for Order Total (${new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }).format(orderTotal)})`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    style={
                        isDesktop
                            ? {
                                  padding: '0 24px',
                                  borderLeft: 'solid 3px #DAE0E6',
                              }
                            : {
                                  padding: '24px 12px',
                              }
                    }
                >
                    <Grid item md={12} xs={12} spacing={1}>
                        {shippingRateLoading && <BSCLoading loading={shippingRateLoading} />}
                        {!shippingRateData && !shippingRateLoading && (
                            <Alert style={{margin: '0 16px'}} severity="warning" variant="filled">
                                Fill out form for Shipping Service
                            </Alert>
                        )}
                        {shippingRateData && !filteredShippingServices?.some(() => true) && (
                            <Alert severity="warning" variant="filled">
                                No shipping rates found for the selected Package Type, Confirmation, Insurance, or Package Weight
                                combination.
                            </Alert>
                        )}
                        {shippingRateData && filteredShippingServices?.some(() => true) && (
                            <div>
                                <BSCTypography
                                    className={classes.required}
                                    style={{textDecoration: 'underline', fontWeight: 600, fontSize: 16}}
                                    label="Select Shipping Service"
                                />
                                {filteredShippingServices.slice(0, 3).map((shippingRate, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '4px 0',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <BSCRadio
                                            name="shippingPreference"
                                            value={shippingRate.service_code}
                                            checked={shippingFormValues.shippingPreference === shippingRate.service_code}
                                            /* eslint-disable */
                                            onChange={e => handleShippingPreference(e)}
                                            style={{filter: 'drop-shadow(0px 1px 1px)', padding: '4px 0'}}
                                        />
                                        <BSCTypography
                                            label={
                                                shippingRate.service_type +
                                                ` (${new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                }).format(shippingRate?.total_shipment_amount)})`
                                            }
                                            size12
                                            style={{paddingLeft: 4}}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <BSCDivider />
            <Box textAlign="center">
                <BSCButton onClick={onCancelBuy} color="primary" style={styles.marginRight}>
                    <BSCTypography style={{fontSize: 12}} label="Cancel"></BSCTypography>
                </BSCButton>
                <BSCButton onClick={handleSubmit} color="secondary" disabled={!isCompleteShippingForm}>
                    <BSCTypography style={{fontSize: 12}} label="Buy Shipping"></BSCTypography>
                </BSCButton>
            </Box>
        </form>
    );
}

export default BuyShippingForm;
