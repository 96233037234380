import * as queryString from 'query-string';
export const QueryString = {
    parse: data =>
        queryString.parse(data, {
            arrayFormat: 'bracket-separator',
            arrayFormatSeparator: ',',
        }),
    stringify: data =>
        queryString.stringify(data, {
            arrayFormat: 'bracket-separator',
            arrayFormatSeparator: ',',
        }),
    fuse: data =>
        queryString.stringify(data, {
            arrayFormat: 'bracket-separator',
            arrayFormatSeparator: ',',
        }),
};
