import {Conversation} from '../../../openapitools/common';

const ConversationHistoryNormalizer = {
    getPagedConversationHistory: (conversationHistory, page, sizePerPage): Conversation[] => {
        if (conversationHistory && page >= 0 && sizePerPage >= 0) {
            //console.log('HELLO', conversationHistory);
            return conversationHistory?.slice(page * sizePerPage, page * sizePerPage + sizePerPage);
        }
        return null;
    },
};

export default ConversationHistoryNormalizer;
