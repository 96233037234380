import {ChangeEvent, memo, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import FilterEnum, {SearchParsed} from '../../../../../enums/filterEnum';
import SearchListItem from './searchListItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import {useState} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import SearchShopResultsNormalizer from '../../../../../client/search/shop/results/searchShopResultsNormalizer';
import List from 'react-virtualized/dist/commonjs/List';
import Alert from '@material-ui/lab/Alert';
import {useQuery} from '../../../../../packages/core/src/hooks/useQuery';
import {Item} from '../../../../../client/openapitools/search';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';

interface SearchListProps {
    menuItems: Item[];
    parsedQueryResults: SearchParsed;
    onRadioChange: Function;
    onCheckboxChange: Function;
    onListClick: Function;
    searchTermKey: string;
    toggleKey: string;
    isBulkUpload?: boolean;
    hasGeneratedResults?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
    box: {
        padding: theme.spacing(0.5, 0),
        '& .MuiMenuItem-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
    },
    boxMobile: {
        padding: theme.spacing(0.5, 0),
        '& .MuiMenuItem-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
            minHeight: '36px !important',
        },
    },
    catHeader: {
        fontSize: theme.typography.subtitle1.fontSize,
    },
    catMenuItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    keyBoardArrow: {
        transition: '.5s ease',
    },
    keyboardArrowFlipped: {
        transform: 'rotate(-180deg)',
    },
    filterInput: {
        width: '100%',
        marginBottom: theme.spacing(1),
        ['& > input']: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    collapseWrapper: {
        padding: theme.spacing(0, 2),
    },
    searchSize: {
        fontSize: '1.25rem',
    },
    topPadding: {
        padding: '2px 16px',
    },
}));

const rowHeight = 32;
const listMaxHeight = 200;

function SearchList({
    parsedQueryResults,
    onRadioChange,
    onCheckboxChange,
    menuItems,
    searchTermKey,
    onListClick,
    toggleKey,
    isBulkUpload = false,
    hasGeneratedResults = false,
}: SearchListProps) {
    const classes = useStyles();
    const {isMobile} = useScreenSnap();
    const [filterText, setFilterText] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const {loading: isLoading, data: shopData} = useQuery(
        () => new Promise(resolve => setTimeout(() => resolve(SearchShopResultsNormalizer.getFilteredMenuItems(menuItems, filterText)), 0)),
        [menuItems, filterText]
    );

    useEffect(() => {
        if (menuItems) {
            if (menuItems.some(x => x.active)) {
                setOpen(true);
            }
        }
    }, [menuItems]);

    useEffect(() => {
        if (isBulkUpload) {
            setOpen(true);
        } else {
            setOpen(toggleKey === searchTermKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleKey]);

    function rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        style, // Style object to be applied to row (to position it)
    }) {
        return (
            <div key={key} style={style}>
                <SearchListItem
                    data={shopData?.[index]}
                    searchTermKey={searchTermKey}
                    parsedQueryResults={parsedQueryResults}
                    onRadioChange={onRadioChange}
                    onCheckboxChange={onCheckboxChange}
                    isBulkUpload={isBulkUpload}
                    hasGeneratedResults={hasGeneratedResults}
                />
            </div>
        );
    }

    return (
        <Box className={isMobile ? classes.boxMobile : classes.box}>
            {/* <MenuItem className={classes.catMenuItem} onClick={() => setOpen((prevOpen: boolean) => !prevOpen)}> */}
            <div onClick={() => onListClick(searchTermKey)}>
                <MenuItem disableRipple={isBulkUpload} className={classes.catMenuItem}>
                    <BSCTypography className={classes.catHeader} label={FilterEnum[searchTermKey]} />
                    {!isBulkUpload && (
                        <KeyboardArrowDownIcon
                            className={classNames({
                                [classes.keyBoardArrow]: true,
                                [classes.keyboardArrowFlipped]: open,
                                [classes.searchSize]: true,
                            })}
                        />
                    )}
                </MenuItem>
            </div>
            <Collapse in={open}>
                <div className={classes.collapseWrapper}>
                    <TextField
                        className={classes.filterInput}
                        variant="outlined"
                        margin="dense"
                        placeholder={`Search ${FilterEnum[searchTermKey]}`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFilterText(e?.target?.value);
                        }}
                        style={isBulkUpload && menuItems?.length === 1 ? {display: 'none'} : {}}
                    />
                    {shopData && (
                        <List
                            height={shopData?.length * rowHeight > listMaxHeight ? listMaxHeight : shopData?.length * rowHeight}
                            width={1}
                            rowHeight={rowHeight}
                            containerStyle={{
                                width: '100%',
                                maxWidth: '100%',
                            }}
                            style={{
                                width: '100%',
                            }}
                            rowCount={shopData?.length}
                            // eslint-disable-next-line react/jsx-no-bind
                            rowRenderer={rowRenderer}
                        />
                    )}
                    {!shopData && !isLoading && (
                        <Alert severity="info" className={classes.topPadding}>
                            <BSCTypography variant="body1">No Matches</BSCTypography>
                        </Alert>
                    )}
                    {isLoading && <Alert severity="info">Loading...</Alert>}
                </div>
            </Collapse>
        </Box>
    );
}

export default memo(SearchList);
