import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import BSCCardBox from '../../common/bscCardBox/bscCardBox';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        pointerEvents: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    cardBox: {
        padding: theme.spacing(1),
        borderRadius: 0,
    },
    menuTitle: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        fontSize: 14,
    },
    menuItem: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            padding: 0,
            minHeight: 0,
        },
    },
}));

interface SubNavContentProps {
    //TODO: Fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menus: undefined;
    onClose: () => {};
}

function SubNavContent({menus, onClose}: SubNavContentProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <BSCCardBox className={classes.cardBox}>
                <div>
                    <Grid container>
                        {menus?.map(menu => (
                            <Grid item key={menu.order} xs={12}>
                                <MenuItem component={Link} to={menu.url} onClick={onClose} className={classes.menuTitle}>
                                    {menu.title}
                                </MenuItem>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </BSCCardBox>
        </div>
    );
}

export default SubNavContent;
