import {Grid, Hidden, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import moment from 'moment';
import {useState} from 'react';
import BSCTypography from '../bscTypography/bscTypography';

import BSCDatePicker, {BSCDatePickerProps} from './bscDatePicker';

interface BSCDateRangePickerProps {
    startPickerProps?: BSCDatePickerProps;
    endPickerProps?: BSCDatePickerProps;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    startGridProps?: undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    endGridProps?: undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gridContainerProps?: undefined;
    toFromHidded?;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#F5F8FA',
        minHeight: 136,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    filterGroup: {
        borderRadius: 0,
    },
    formWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginRight: theme.spacing(0),
            '& > *': {
                width: '100%',
            },
        },
        '& input': {
            background: theme.palette.common.white,
            padding: '10px 14px',
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(1),
        },
    },
    label: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(1),
        },
    },
    actions: {
        margin: theme.spacing(0, 2),
        padding: theme.spacing(2),
        '& button:first-child': {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
            width: 'calc(100% - 32px)',
        },
    },
    loading: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
}));

function BSCDateRangePicker({
    startPickerProps,
    endPickerProps,
    startGridProps,
    endGridProps,
    gridContainerProps,
    toFromHidded = {smDown: true},
    setMinMaxValidation,
}: BSCDateRangePickerProps) {
    const classes = useStyles();

    const [maxStartDate, setMaxStartDate] = useState(moment.now);
    const [minEndDate, setMinEndDate] = useState(moment.now);

    const onStartChange = e => {
        if (setMinMaxValidation) setMinEndDate(e.target.value);

        startPickerProps?.onChange(e);
    };

    const onEndChange = e => {
        if (setMinMaxValidation) setMaxStartDate(e.target.value);

        endPickerProps?.onChange(e);
    };

    return (
        <Grid container {...gridContainerProps}>
            <Grid item {...startGridProps}>
                <div className={classes.formWrapper}>
                    <Grid container alignItems="center">
                        <Hidden {...toFromHidded}>
                            <Grid item xs={3} container justifyContent="flex-end">
                                <BSCTypography className={classes.label} label="From" />
                            </Grid>
                        </Hidden>
                        <Grid item xs>
                            <BSCDatePicker
                                {...startPickerProps}
                                onChange={onStartChange}
                                maxDate={setMinMaxValidation ? maxStartDate : '1/1/2200'}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item {...endGridProps}>
                <div className={classes.formWrapper}>
                    <Grid container alignItems="center">
                        <Hidden {...toFromHidded}>
                            <Grid item xs={3} container justifyContent="flex-end">
                                <BSCTypography className={classes.label} label="To" />
                            </Grid>
                        </Hidden>
                        <Grid item xs>
                            <BSCDatePicker
                                {...endPickerProps}
                                onChange={onEndChange}
                                minDate={setMinMaxValidation ? minEndDate : '1/1/2000'}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

export default BSCDateRangePicker;
