import {Switch, Route} from 'react-router-dom';
import SellerPaymentDetails from './SellerPaymentDetails';
import SellerPaymentsMain from './SellerPaymentsMain';

const SellerPayments = () => {
    return (
        <Switch>
            <Route exact path={'/sellers/payments'}>
                <SellerPaymentsMain />
            </Route>
            <Route path={'/sellers/payments/:sellerPaymentId'}>
                <SellerPaymentDetails />
            </Route>
        </Switch>
    );
};

export default SellerPayments;
