import React from 'react';

const ProductReferenceContext = React.createContext({});

const ProductReferenceContextProvider = ProductReferenceContext.Provider;
/*  eslint-disable @typescript-eslint/no-explicit-any */

export const useProductReferenceContext = (): undefined => {
    const context = React.useContext(ProductReferenceContext);
    if (context === undefined) {
        throw new Error('useProductReferenceContext must be used within an ProductReferenceContextProvider');
    }
    return context;
};

export default ProductReferenceContextProvider;
