import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../bscTypography/bscTypography';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import {ReactNode} from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    gutterBottom: {
        marginBottom: theme.spacing(1),
    },
}));

interface BSCGridTableRowProps {
    col8: string | number | ReactNode;
    col4: string | number | ReactNode;
    bold?: boolean;
    gutterBottom?: boolean;
    className?: string;
    flip?: boolean;
}

function BSCGridTableRow({col8, col4, bold = false, gutterBottom = false, className = null, flip = false}: BSCGridTableRowProps) {
    const classes = useStyles();
    return (
        <Box
            className={classNames(
                {
                    [classes.gutterBottom]: gutterBottom,
                },
                className
            )}
        >
            <Grid container alignItems="center" direction={flip ? 'row-reverse' : 'row'}>
                <Grid item xs={8}>
                    <BSCTypography variant="subtitle1" bold={bold} className={className} label={col8} />
                </Grid>
                <Grid container item alignItems="center" xs={4}>
                    <BSCTypography
                        style={{width: '100%'}}
                        className={className}
                        display="block"
                        bold={bold}
                        align={flip ? 'left' : 'right'}
                        label={col4}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default BSCGridTableRow;
