/* eslint-disable indent */
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';
import {Hidden} from '@material-ui/core';
import classNames from 'classnames';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {useCallback, useState} from 'react';
import {Menu, MenuItem} from '@material-ui/core';
// import BSCBack from '../../../../features/common/bscBack/bscBack';
import {useHistory} from 'react-router-dom';
import {Order} from '../../../../client/openapitools/seller';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
            marginTop: 10,
        },
    },
    paginationBtn: {
        height: 32,
        minWidth: 32,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0),
            height: 37,
            minWidth: 37,
        },
    },
    textRight: {
        textAlign: 'end',
    },
    arrowBtn: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.black,
            opacity: 0.6,
        },
        //  [theme.breakpoints.down('sm')]: {
        backgroundColor: '#fff',
        color: '#EF2424',
        // },
        width: 35,
        '&:nth-of-type(1)': {
            marginRight: theme.spacing(2),
        },
        '&:nth-last-of-type(1)': {
            marginLeft: theme.spacing(2),
        },
    },
    divider: {
        margin: theme.spacing(1, -1.5),
    },
    fullWidth: {
        width: 'calc(100vw - 32px)',
    },
    label: {
        textAlign: 'center',
    },
    desktopButtons: {
        fontSize: 12,
    },
    menu: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
    },
    pointer: {
        cursor: 'pointer',
    },
    weight400: {
        fontWeight: 400,
    },
    noPrint: {
        display: 'flex',
        '@media print': {
            display: 'none',
        },
    },
}));
// eslint-disable-next-line
const useHeaderGrid = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3,1fr)',
        },
        selfCenter: {
            justifySelf: 'center',
        },
        selfStart: {
            justifySelf: 'start',
        },
        selfEnd: {
            justifySelf: 'end',
        },
    };
});

const OrderPaging = ({onPrevOrder, classes, onNextOrder, selectedIndex, totalResults}) => {
    const {isMobile} = useScreenSnap();

    return (
        <Grid container alignItems="center" style={!isMobile ? {justifyContent: 'center'} : {}}>
            <Grid item className={classes.textRight}>
                <BSCButton
                    onClick={onPrevOrder}
                    type="button"
                    className={classNames(classes.paginationBtn, classes.arrowBtn)}
                    style={{marginLeft: 0}}
                >
                    <KeyboardArrowLeftIcon />
                </BSCButton>
            </Grid>
            <Grid item>
                <BSCTypography className={classes.label} label={`Order ${selectedIndex + 1} of ${totalResults}`} />
            </Grid>
            <Grid item>
                <BSCButton onClick={onNextOrder} type="button" className={classNames(classes.paginationBtn, classes.arrowBtn)}>
                    <KeyboardArrowRightIcon />
                </BSCButton>
            </Grid>
        </Grid>
    );
};

interface OrderDetailsHeaderProps {
    onNextOrder: () => void;
    onPrevOrder: () => void;
    selectedIndex: number;
    totalResults: number;
    onRefund: () => void;
    printSlip: () => void;
    selectedOrderId: string;
    order: Order;
    orderIsCanceled?: boolean;
}

function OrderDetailsHeader({
    onNextOrder,
    onPrevOrder,
    selectedOrderId,
    selectedIndex,
    totalResults,
    printSlip,
    onRefund,
    order,
    orderIsCanceled = false,
}: OrderDetailsHeaderProps) {
    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
    const history = useHistory();
    const {isMobile, isTablet, isDesktop} = useScreenSnap();
    const handleMenuOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenMenu(event.currentTarget);
        },
        [setOpenMenu]
    );

    const handleMenuClose = useCallback(() => {
        setOpenMenu(null);
    }, [setOpenMenu]);

    const handleRefund = useCallback(() => {
        handleMenuClose();
        onRefund?.();
    }, [onRefund]);

    const headerGridClasses = useHeaderGrid();

    return (
        <div className={classes.noPrint}>
            {isMobile && (
                <Grid container justifyContent="space-between" alignItems="center" direction="row" spacing={1}>
                    <Grid item xs={8}>
                        <OrderPaging
                            onPrevOrder={onPrevOrder}
                            classes={classes}
                            onNextOrder={onNextOrder}
                            selectedIndex={selectedIndex}
                            totalResults={totalResults}
                        />
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={4}>
                        <BSCButton color="secondary" onClick={handleMenuOpen} my="auto">
                            <BSCTypography label="Manage" />
                        </BSCButton>
                    </Grid>
                </Grid>
            )}
            {!isMobile && (
                <div
                    style={
                        isDesktop
                            ? {
                                  padding: '12px 16px',
                                  background: '#fff',
                                  boxShadow: '0px 0px 4px #b8c2cc',
                                  borderRadius: 4,
                                  width: 'calc(100% - 32px)',
                                  display: 'flex',
                              }
                            : {
                                  padding: '12px 16px',
                                  background: '#fff',
                                  boxShadow: '0px 0px 4px #b8c2cc',
                                  borderRadius: 4,
                                  width: 'calc(100% - 48px)',
                                  display: 'flex',
                              }
                    }
                >
                    <div
                        style={isMobile || isTablet ? {flex: 0} : {flex: 1}}
                        className={classNames(classes.menu, classes.pointer)}
                        onClick={() => history.goBack()}
                    >
                        <KeyboardArrowLeftIcon />
                        <Hidden smDown>
                            <BSCTypography
                                label="Back To orders"
                                className={classes.weight400}
                                capitalize
                                variant="subtitle2"
                                color="textPrimary"
                            />
                        </Hidden>
                        <Hidden mdUp>
                            <BSCTypography label="Back" bold />
                        </Hidden>
                    </div>
                    <div style={{flex: 1}} className={headerGridClasses.selfCenter}>
                        <OrderPaging
                            onPrevOrder={onPrevOrder}
                            classes={classes}
                            onNextOrder={onNextOrder}
                            selectedIndex={selectedIndex}
                            totalResults={totalResults}
                        />
                    </div>
                    <div style={isDesktop ? {flex: 1} : {}}>
                        <Grid container spacing={2} style={{justifyContent: 'end'}}>
                            <Grid item>
                                <BSCButton
                                    color="secondary"
                                    onClick={() => {
                                        handleMenuClose();
                                        order.orderId = selectedOrderId;
                                        history.push({
                                            pathname: '/sellers/messages/create',
                                            state: {
                                                order: order,
                                            },
                                        });
                                    }}
                                    fullWidth
                                    fullHeight
                                >
                                    <BSCTypography label="Contact Buyer" className={classes.desktopButtons} />
                                </BSCButton>
                            </Grid>
                            {!orderIsCanceled && (
                                <Grid item>
                                    <BSCButton color="secondary" onClick={printSlip} fullWidth fullHeight>
                                        <BSCTypography label="Print Packing Slip" className={classes.desktopButtons} />
                                    </BSCButton>
                                </Grid>
                            )}

                            {!orderIsCanceled && (
                                <Grid item>
                                    <BSCButton color="primary" onClick={handleRefund} fullWidth fullHeight>
                                        <BSCTypography label="Issue Refund" className={classes.desktopButtons} />
                                    </BSCButton>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </div>
            )}

            {isMobile && (
                <Menu id="simple-menu" anchorEl={openMenu} keepMounted open={Boolean(openMenu)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleMenuClose} className={classes.fullWidth}>
                        <BSCButton
                            color="secondary"
                            onClick={() => {
                                handleMenuClose();
                                order.orderId = selectedOrderId;
                                history.push({
                                    pathname: '/sellers/messages/create',
                                    state: {
                                        order: order,
                                    },
                                });
                            }}
                            fullWidth
                        >
                            <BSCTypography label="Contact Buyer" />
                        </BSCButton>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <BSCButton color="secondary" onClick={printSlip} fullWidth>
                            <BSCTypography label="Print Packing Slip" />
                        </BSCButton>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <BSCButton color="primary" onClick={handleRefund} fullWidth>
                            <BSCTypography label="Issue Refund" />
                        </BSCButton>
                    </MenuItem>
                </Menu>
            )}
        </div>
    );
}

export default OrderDetailsHeader;
