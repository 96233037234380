/* eslint-disable @typescript-eslint/no-unused-vars */
import {FormControlLabel} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useCallback, useEffect, useState} from 'react';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCHidingHeaderBar from '../../../../features/common/bscHidingHeaderBar/bscHidingHeaderBar';
import BSCRadio from '../../../../features/common/bscRadio/bscRadio';
import BSCRadioGroup from '../../../../features/common/bscRadioGroup/bscRadioGroup';
import BSCSearchField from '../../../../features/common/bscSearchField/bscSearchField';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 20%',

        [theme.breakpoints.down('sm')]: {
            width: '60%',
            margin: 'auto',
            padding: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
    searchBar: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    flex: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
    },
    radioGroup: {
        minWidth: 140,
        '&>label>span:first-child': {
            padding: theme.spacing(0.5),
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    filterBtn: {
        width: '35%',
    },
    smallRadio: {
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
        },
    },
}));

const Bar = ({classes, belowTablet, onMobileFilter, totalAppliedFilters, handleSearchCatalogType, searchCatalogType}) => {
    return (
        <div className={classes.flex}>
            {belowTablet && (
                <BSCButton className={classes.filterBtn} onClick={onMobileFilter} color="primary">
                    <BSCTypography label={`Filters${totalAppliedFilters ? ` (${totalAppliedFilters})` : ''}`} />
                </BSCButton>
            )}
            <BSCRadioGroup
                className={classes.radioGroup}
                inputKey="searchCatalogType"
                onChange={handleSearchCatalogType}
                value={searchCatalogType}
            >
                <FormControlLabel className={classes.smallRadio} value="false" control={<BSCRadio />} label="Card Catalog" />
                <FormControlLabel className={classes.smallRadio} value="true" control={<BSCRadio />} label="My Inventory" />
            </BSCRadioGroup>
        </div>
    );
};

interface SLInventoryHeaderProps {
    onRadioChange: (searchTermKey, selectedSlug, clearSearch) => void;
    onMobileFilter: () => void;
    belowTablet: boolean;
    q?: string;
    searchCatalogType?: boolean;
    totalAppliedFilters: number;
    handleClear;
}

function SLInventoryHeader({
    onRadioChange,
    q,
    searchCatalogType,
    belowTablet,
    onMobileFilter,
    totalAppliedFilters,
    handleClear,
}: SLInventoryHeaderProps) {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [isSearchTermEntered, setIsSearchTermEntered] = useState(false);

    const handleEnterDown = useCallback(() => {
        if (search) {
            onRadioChange(search, searchCatalogType, isSearchTermEntered);
        }
    }, [search, onRadioChange]);

    const handleSearchChange = useCallback(e => {
        setIsSearchTermEntered(true);
        setSearch(e.target.value);
    }, []);

    const handleSearchCatalogType = useCallback(
        e => {
            onRadioChange(search, e.target.value, true);
        },
        [onRadioChange]
    );

    const [placeHolder, setPlaceholder] = useState('');
    useEffect(() => {
        if (searchCatalogType && searchCatalogType === 'false') {
            setPlaceholder('Search Card Catalog');
        } else {
            setPlaceholder('Search Your Inventory');
        }
    }, [searchCatalogType]);

    useEffect(() => {
        setSearch(q);
    }, [q]);

    const onClear = () => {
        // handleClear();
        setSearch(undefined);
    };

    return (
        <div className={classes.root}>
            <div className={classes.searchBar}>
                <BSCSearchField
                    placeholder={placeHolder}
                    value={search}
                    onChange={handleSearchChange}
                    onEnterDown={handleEnterDown}
                    inputKey="search"
                    loading={false}
                    onClear={onClear}
                />
            </div>
            {belowTablet && (
                <BSCHidingHeaderBar relativePositionThreshold={100} disapearingThreshold={140}>
                    <Bar
                        classes={classes}
                        belowTablet={belowTablet}
                        onMobileFilter={onMobileFilter}
                        totalAppliedFilters={totalAppliedFilters}
                        handleSearchCatalogType={handleSearchCatalogType}
                        searchCatalogType={searchCatalogType}
                    ></Bar>
                </BSCHidingHeaderBar>
            )}
            {!belowTablet && (
                <Bar
                    classes={classes}
                    belowTablet={belowTablet}
                    onMobileFilter={onMobileFilter}
                    totalAppliedFilters={totalAppliedFilters}
                    handleSearchCatalogType={handleSearchCatalogType}
                    searchCatalogType={searchCatalogType}
                ></Bar>
            )}
        </div>
    );
}

export default SLInventoryHeader;
