import {SellerCartGroup} from '../../../../client/openapitools/marketplace';

const CartUserNormalizer = {
    getSellerCartGroup: userCart => {
        userCart?.sellerCartGroup ?? null;

        if (userCart?.sellerCartGroup) {
            return userCart?.sellerCartGroup.length > 0 ? userCart?.sellerCartGroup : null;
        }

        return null;
    },
    getCartItems: (sellerCartGroup: SellerCartGroup) => sellerCartGroup?.cartItems ?? null,
};

export default CartUserNormalizer;
