import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        background: 'linear-gradient(180deg, #FFFFFF 65.52%, #DAE0E6 100%)',
        boxShadow: '0px 2px 0px #B8C2CC',
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        position: 'static',
        width: '100%',
        background: 'linear-gradient(180deg, #FFFFFF 65.52%, #DAE0E6 100%)',
        boxShadow: '0px 2px 0px #B8C2CC',
        borderRadius: '4px 4px 0px 0px',
        boxSizing: 'border-box',
        fontSize: 16,
    },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeedbackListHeader = ({page, perPage, setPerPage, feedbackSize}) => {
    const classes = useStyles();
    return (
        <Grid container justify-content="center" alignItems="center" className={classes.wrapper}>
            <BSCTypography className={classes.header} label="Recent Reviews" />
        </Grid>
    );
};

export default FeedbackListHeader;
