import axios, {AxiosResponse} from 'axios';
import getMockData from '../../../../util/mockData';
import _ from 'lodash';
import {UrlPath} from '../../../../@types/clientTypes';

const isLiveServices = true; //localStorage.getItem('BSC-dev-liveServices') === 'true';

const strippedPath = (urlPath: UrlPath) => {
    let newUrlPath = urlPath;
    if (_.isObject(urlPath)) {
        newUrlPath = isLiveServices ? urlPath.live : urlPath.mock;
    }

    if (newUrlPath.search('s3/') !== -1) {
        return `${newUrlPath.replace('s3/', '')}.json`;
    }

    return newUrlPath;
};
const buildUrl = (baseUrl, urlPath) => `${baseUrl}${strippedPath(urlPath)}`;

export function Client(baseUrl: string) {
    const instance = axios.create();
    //TODO: Add an axios interceptor to auto refresh the token.  just have to figure out how to get it back to the useReduxUser.
    return {
        instance,
        get: (urlPath: UrlPath, options: Object = {}): Promise<AxiosResponse> => {
            if (isLiveServices) {
                return instance.get(buildUrl(baseUrl, urlPath), options);
            }

            return getMockData(urlPath);
        },
        post: (urlPath: UrlPath, data: Object, options: Object = {}): Promise<AxiosResponse> => {
            if (isLiveServices) {
                return instance.post(buildUrl(baseUrl, urlPath), data, options);
            }
            return getMockData(urlPath, data, 'POST');
        },
        put: (urlPath: UrlPath, data: Object, options: Object = {}): Promise<AxiosResponse> => {
            if (isLiveServices) {
                return instance.put(buildUrl(baseUrl, urlPath), data, options);
            }
            return getMockData(urlPath, data, 'PUT');
        },
        delete: (urlPath: UrlPath, options: Object = {}): Promise<AxiosResponse> => {
            if (isLiveServices) {
                return instance.delete(buildUrl(baseUrl, urlPath), options);
            }
            return getMockData(urlPath, null, 'DELETE');
        },
    };
}
