import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import React from 'react';
import Dwolla from 'react-dwolla-iav';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import {UserApi} from '../../../../../client/openapitools/seller';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';

const DwollaPayment = ({iavToken = '', onSignupComplete = () => {}}) => {
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const dwollaConfig = {
        backButton: true,
        customerToken: iavToken,
        environment: process.env.REACT_APP_DWOLLA_ENV,
        fallbackToMicroDeposits: true,
        microDeposits: true,
        stylesheets: [],
        subscriber: () => {},
    };

    const onSuccess = data => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const sellerUserApi = new UserApi(apiConfig);
                            sellerUserApi
                                .updateSellerPaymentSetting({
                                    fundingSourceUrl: `${process.env.REACT_APP_DWOLLA_URL}funding-sources/${data}`,
                                    iavStatus: 'Completed',
                                })
                                .then(res => {
                                    console.log('res', res);
                                    onSignupComplete();
                                });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to interact with Dwolla.',
                                contextKey: 'DwollaWebClient',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };
    const onError = err => {
        const account = instance.getActiveAccount();
        /* handle err */
        switch (err.code) {
            // try new bank
            case 'UnsupportedBank':
            case 'RateLimitReached':
                // let the user know he can retry after sometime.
                if (account !== null) {
                    const tokenRequest: SilentRequest = {
                        account: account as AccountInfo | undefined,
                        scopes: AUTH_REQUESTS.LOGIN.scopes,
                    };
                    instance
                        .acquireTokenSilent(tokenRequest)
                        .then(async response => {
                            if (response.accessToken) {
                                const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                                try {
                                    const sellerUserApi = new UserApi(apiConfig);
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    return await sellerUserApi.updateSellerPaymentSetting({iavStatus: err.code}).then(res => {
                                        onSignupComplete();
                                    });
                                } catch (error) {
                                    console.log(error);
                                    addToast({
                                        severity: 'error',
                                        message: 'Failed to interact with Dwolla.',
                                        contextKey: 'DwollaWebClient',
                                    });
                                }
                            }
                        })
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        .catch(error => {
                            addToast({
                                severity: 'error',
                                message: 'User must be Signed In.',
                                contextKey: 'Authentication',
                            });
                            setTimeout(() => {
                                authFunc.logout();
                            }, 5000);
                        });
                }
                break;
            case 'UnexpectedPage':
            case 'InvalidIavToken':
                // restart
                return console.log(err.code);
            default:
                return;
        }
    };
    return <div>{iavToken && <Dwolla onSuccess={onSuccess} onError={onError} dwollaConfig={dwollaConfig} />}</div>;
};

export default DwollaPayment;
