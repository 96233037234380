import {memo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import {Menus} from '../../../../@types/menus';
import SubNavContent from '../subNavContent';
interface SubNavProps {
    menus: Array<Menus> | null;
    open: boolean;
    anchorEl: HTMLElement | null;
    popoverEnter: (id: number) => void;
    popoverLeave: () => void;
    id: number;
    url: string;
}

const useStyles = makeStyles({
    popoverContent: {
        pointerEvents: 'auto',
        borderRadius: '0 0 4px 4px',
        boxShadow: '0 1px 1px 0',
    },
});

function SubNav({menus, open, anchorEl, popoverEnter, popoverLeave, id, url}: SubNavProps) {
    const classes = useStyles();

    if (!open) {
        return null;
    }

    return (
        <Popover
            style={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transitionDuration={{appear: 2, enter: 1, exit: 0.2}}
            PaperProps={{onMouseEnter: () => popoverEnter(id), onMouseLeave: popoverLeave}}
            classes={{
                paper: classes.popoverContent,
            }}
        >
            <SubNavContent menus={menus} menuId={id} onGoatClick={popoverLeave} url={url} />
        </Popover>
    );
}

export default memo(SubNav);
