/* eslint-disable @typescript-eslint/no-unused-vars */
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles, Theme} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {forwardRef, KeyboardEvent, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import {RootState} from '../../../redux/reducers';
import BSCTextField, {BSCTextFieldProps} from '../bscTextField/bscTextField';

const useStyles = makeStyles((theme: Theme) => ({
    searchInput: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            //   width: 'auto',
            width: 'calc(100% - 20px)',
            padding: '5px 10px 8px 10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
        },
    },
    pwaSearchInput: {
        flexGrow: 1,
        width: '310px !important',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            // width: '295px !important',
            padding: '8px 10px 8px 10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
        },
    },
    pwaSearchResults: {
        flexGrow: 1,
        width: '295px !important',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '295px !important',
            padding: '8px 10px 8px 10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
        },
    },
    searchInputStyleProp: {
        background: theme.palette.common.white,
        borderRadius: 4,
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('md')]: {
            //   width: 'auto',
            width: '100%',
        },
    },
    endAdornmentInput: {
        //position: 'absolute',
        right: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            right: theme.spacing(0),
        },
    },
    endAdormentClick: {
        cursor: 'pointer',
    },
}));

interface BSCSearchFieldProps extends BSCTextFieldProps {
    onEnterDown?: (keyCode: string) => void;
    loading: boolean;
    onClear?: () => void;
    isPWA?: boolean;
    pwaActiveTab?: number;
}
const BSCSearchField = forwardRef<HTMLDivElement, BSCSearchFieldProps>(
    (
        {
            inputKey = null,
            onEnterDown,
            loading,
            formStateValidations = null,
            onClear,
            value,
            isPWA = false,
            ...otherProps
        }: BSCSearchFieldProps,
        ref
    ) => {
        const classes = useStyles();
        const isPWASearch = useRouteMatch('/pwa/dashboard/search');
        const isPWASearchResults = useRouteMatch('/search');
        const marketplaceState = useSelector((state: RootState) => state.marketplace);
        const handleKeyDown = useCallback(
            (e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                    onEnterDown?.('Enter');
                }
            },
            [onEnterDown]
        );

        const [pwaOS, setPwaOS] = useState(null);
        useEffect(() => {
            if (marketplaceState) {
                if (marketplaceState.pwaSettings.pwaOS !== '') {
                    setPwaOS(marketplaceState.pwaSettings.pwaOS);
                }
            }
        }, [marketplaceState]);

        return (
            <BSCTextField
                {...otherProps}
                ref={ref}
                onKeyDown={handleKeyDown}
                value={value}
                className={isPWASearch ? classes.pwaSearchInput : classes.searchInput}
                style={pwaOS !== null && isPWASearchResults ? {width: 210} : {}}
                InputProps={{
                    className: classes.searchInputStyleProp,
                    startAdornment: (
                        <>
                            {loading && <CircularProgress className={classes.endAdornmentInput} size={20} color="primary" />}
                            {!loading && (
                                <SearchIcon
                                    className={classes.endAdornmentInput}
                                    onClick={() => {
                                        onEnterDown?.('Enter');
                                    }}
                                />
                            )}
                        </>
                    ),
                    endAdornment: (
                        <>
                            {loading && <CircularProgress className={classes.endAdornmentInput} size={20} color="primary" />}
                            {!loading && value?.length > 0 && (
                                <CloseIcon onClick={onClear} className={(classes.endAdornmentInput, classes.endAdormentClick)}></CloseIcon>
                            )}
                        </>
                    ),
                }}
                inputKey={inputKey || ''}
                formStateValidations={formStateValidations}
            />
        );
    }
);

export default BSCSearchField;
