import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import {addToast, removeToast, Toast} from '../../../../redux/slices/toast.slice';

function useReduxToast() {
    const toasts = useSelector((s: RootState) => s.toast.que);
    const dispatch = useDispatch();

    const addToastWrapper = useCallback((toastObj: Toast) => dispatch(addToast(toastObj)), [dispatch]);
    const removeToastWrapper = useCallback((index: string) => dispatch(removeToast(index)), [dispatch]);

    return {toasts, addToast: addToastWrapper, removeToast: removeToastWrapper};
}

export default useReduxToast;
