const FilterEnum: {[searchTermKey: string]: string} = {
    sport: 'Sport',
    year: 'Year',
    setName: 'Set',
    playerAttribute: 'Card Attribute',
    teamName: 'Team',
    players: 'Player',
    variant: 'Variant',
    variantName: 'Variant Name',
    q: null,
    p: null,
    sort: null,
    inStock: 'true',
    type: 'all',
    rawCondition: null,
    gradingCompany: null,
    gradeRangeStart: null,
    gradeRangeEnd: null,
    letterGrade: null,
    priceRangeStart: null,
    priceRangeEnd: null,
    cardNo: 'Card #',
    myInventory: null,
};

export interface MainFilters {
    sport?: string[];
    year?: string[];
    setName?: string[];
    playerAttribute?: string[];
    teamName?: string[];
    players?: string[];
    variant?: string[];
    cardNo?: string[];
    type?: string;
    rawCondition?: string[];
    gradingCompany?: string[];
    gradeRangeStart?: number;
    gradeRangeEnd?: number;
    letterGrade?: string[];
}

export type SearchSorts =
    | 'best-sellers'
    | 'cardCatalog'
    | 'myInventory'
    | 'raw-price-hi-lo'
    | 'raw-price-lo-hi'
    | 'newest'
    | 'oldest'
    | 'a-z'
    | 'z-a'
    | null;

export interface SearchParsed extends MainFilters {
    q?: string;
    p?: string;
    sort?: SearchSorts;
    inStock?: boolean;
    type?: string;
    rawCondition?: undefined;
    gradingCompany?: undefined;
    gradeRangeStart?: undefined;
    gradeRangeEnd?: undefined;
    letterGrade?: undefined;
    priceRangeStart?: number;
    priceRangeEnd?: number;
    myInventory?: boolean;
}

export interface AppliedFilter extends MainFilters {
    label: string;
    slug: string;
    type: string;
}

export default FilterEnum;
