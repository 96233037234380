import {MessageReference} from '../../openapitools/common/api';

// import {maxCharacter} from '../../../packages/core/src/util/util';
const S3_PATH = process.env.REACT_APP_S3_BASE_URL.endsWith('/')
    ? process.env.REACT_APP_S3_BASE_URL.substring(0, process.env.REACT_APP_S3_BASE_URL.length - 1)
    : process.env.REACT_APP_S3_BASE_URL;

const ORDER_STATUS = {
    new: 'Ready To Ship',
    placed: 'Ready To Ship',
    ready_to_ship: 'Ready To Ship',
    refunded: 'Cancelled',
    cancelled: 'Cancelled',
    partially_refunded: 'Incorrect Data',
    shipped: 'Shipped',
    partially_refunded_ready_to_ship: 'Ready To Ship',
    partially_refunded_shipped: 'Shipped',
};

interface CogetPagedResultmmonNormalizer {
    getPagedResult<T>(data: T[], page: number, sizePerPage: number): T[];
    getViewRange(page: number, perPage: number, totalSize: number): string;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    getObjectKeys(obj: undefined): string[];
    getMessageSubject(msgRef: MessageReference, subjectKey: string): string;
    getS3ImagePath(path: string): string;
    getOrderStatusDisplay(status: string): string;
}

const Normalizer: CogetPagedResultmmonNormalizer = {
    getPagedResult: (data, page, sizePerPage) => {
        if (data && page >= 0 && sizePerPage >= 0) {
            return data?.slice(page * sizePerPage, page * sizePerPage + sizePerPage);
        }
    },
    getViewRange: (page: number, perPage: number, totalSize: number) =>
        `Viewing ${(page - 1) * perPage > 0 ? (page - 1) * perPage : 1} - ${
            page * perPage > totalSize ? totalSize : (page + 1) * perPage
        } of ${totalSize}`,
    getObjectKeys: obj => Object.keys(obj),
    getMessageSubject: (msgRef, subjectKey) => (msgRef?.subjectTypes?.find(subjectType => subjectType.key === subjectKey) ?? {})['name'],
    getS3ImagePath: path => S3_PATH + path,
    getOrderStatusDisplay: status => {
        return ORDER_STATUS[status?.toLowerCase() ?? ''] ?? '';
    },
};

export default Normalizer;
