// TODO: Double check anytypes later
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useReducer, DependencyList, useRef} from 'react';
import E from '../events/event';
import {v4 as uuidv4} from 'uuid';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';

function resolvePromise(promise: Function | Promise<undefined>) {
    if (typeof promise === 'function') {
        return promise();
    }

    return promise;
}

const types = {
    ERROR: 'ERROR',
    LOADING: 'LOADING',
    RELOADING: 'RELOADING',
    RESULT: 'RESULT',
};

const initialState = {
    data: null,
    errors: null,
    loading: false,
};

interface UsePromiseState {
    data: null | undefined;
    errors: null | Error;
    loading: boolean;
}

interface Action {
    type: string;
    payload?: undefined;
}

const reducer = (state: UsePromiseState, action: Action): UsePromiseState => {
    switch (action.type) {
        case types.ERROR:
            return {
                data: null,
                errors: action.payload as Error,
                loading: false,
            };
        case types.LOADING:
            return {
                data: null,
                errors: null,
                loading: true,
            };
        case types.RELOADING:
            return {
                data: state.data,
                errors: null,
                loading: true,
            };
        case types.RESULT:
            return {
                data: action.payload as undefined,
                errors: null,
                loading: false,
            };
        default:
            return state;
    }
};

interface UseQueryReturn<T> {
    loading: boolean;
    error: Error | null;
    data: T | null;
    instance: string | null;
    refetch?: () => void;
}

export function useQuery<T>(promise: () => Promise<T>, deps: DependencyList): UseQueryReturn<T> {
    const [{errors, loading, data}, dispatch] = useReducer(reducer, initialState);
    const {addToast} = useReduxToast();

    useEffect(() => {
        if (errors) {
            addToast({
                severity: 'error',
                message: errors.message,
                contextKey: 'Network',
            });
        }
    }, [errors]);

    const instanceRef = useRef(null);

    const refetch = () => {
        const resolvedPromise = resolvePromise(promise);
        resolvedPromise.then(
            (result: undefined) => dispatch({type: types.RESULT, payload: result}),
            (error: Error) => dispatch({type: types.ERROR, payload: error})
        );
    };

    useEffect(() => {
        instanceRef.current = uuidv4();
        const resolvedPromise = resolvePromise(promise);

        if (!resolvedPromise) {
            return;
        }

        let unmounted = false;
        let subscriptionCalled = false;

        E.on(`mutation-${instanceRef.current}`, () => {
            dispatch({type: types.RELOADING});
            const newPromise = resolvePromise(promise);
            subscriptionCalled = true;
            newPromise.then(
                (result: undefined) => !unmounted && dispatch({type: types.RESULT, payload: result}),
                (error: Error) => !unmounted && dispatch({type: types.ERROR, payload: error})
            );
        });

        dispatch({type: types.LOADING});
        resolvedPromise.then(
            (result: undefined) => !unmounted && !subscriptionCalled && dispatch({type: types.RESULT, payload: result}),
            (error: Error) => !unmounted && !subscriptionCalled && dispatch({type: types.ERROR, payload: error})
        );

        return () => {
            unmounted = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps ?? []);

    return {loading, error: errors, data: data, instance: instanceRef.current, refetch};
}

export default useQuery;
