import {Theme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface useScreenSnapReturn {
    isDesktop: boolean;
    isTablet: boolean;
    isMobile: boolean;
    isBelowDesktop: boolean;
    isBelowTablet: boolean;
    isLaptop: boolean;
}

function useScreenSnap(): useScreenSnapReturn {
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'sm'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const isBelowDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isBelowTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isLaptop = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

    return {isDesktop, isTablet, isMobile, isBelowDesktop, isBelowTablet, isLaptop};
}

export default useScreenSnap;
