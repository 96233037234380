import {makeStyles, Theme} from '@material-ui/core';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCRadioGroup from '../../../../features/common/bscRadioGroup/bscRadioGroup';
import BSCRadio from '../../../../features/common/bscRadio/bscRadio';
import {FormControlLabel} from '@material-ui/core';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {useEffect, useState} from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {marginRight: theme.spacing(1)},
    marginTop: {marginTop: theme.spacing(1)},
    previewLabel: {
        '@media screen': {
            textAlign: 'center',
            '& img': {
                width: '50%',
                height: 'auto',
            },
        },
    },
    printLabel: {
        '@media print': {
            position: 'fixed !important',
            margin: '0mm 0mm 0mm 0mm !important',
            textAlign: 'left !important',
            top: '6mm !important',
            left: '3mm !important',
        },
        '& img': {
            height: '148mm !important',
        },
    },
    printLetter: {
        '@media print': {
            position: 'fixed !important',
            margin: '0mm 0mm 0mm 0mm !important',
            top: '-10px !important',
            left: '240px !important',
            transform: 'rotate(90deg) !important',
        },
        '& img': {
            height: '148mm !important',
        },
    },
    formWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '8px 8px 8px 0',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginRight: theme.spacing(0),
            '& > *': {
                width: '100%',
            },
        },
        '& input': {
            background: theme.palette.common.white,
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(1),
        },
    },
    smallRadio: {
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 'bold',
        },
        marginRight: 16,
        padding: '0 8px',
    },
    label: {
        marginRight: theme.spacing(2),
        textDecoration: 'underline',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(1),
        },
    },
}));

interface PrintLabelModalProps {
    open: boolean;
    onClose(): void;
    printLabelImage: string | undefined;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrintLabelModal = ({open, onClose, printLabelImage}: PrintLabelModalProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const classes = useStyles();

    const handleOnClose = () => {
        onClose();
    };

    const [isPrintReady, setIsPrintReady] = useState(false);
    const [printSize, setPrintSize] = useState(null);
    const printTheLabel = () => {
        setTimeout(() => {
            const css = '@page { size: 6in 4in landscape; margin: 0in 0in 0in 0in; }';
            const head = document.head || document.getElementsByTagName('head')[0];
            const style = document.createElement('style');

            style.media = 'print';
            style.appendChild(document.createTextNode(css));

            head.appendChild(style);
            window.print();
            head.removeChild(style);
            setIsPrintReady(false);
            handleOnClose();
        }, 50);
    };
    const handlePrintPref = e => {
        if (e.target.value === '0') {
            setPrintSize('Letter');
        } else {
            setPrintSize('4x6');
        }
    };

    useEffect(() => {
        if (printSize) {
            setIsPrintReady(true);
            printTheLabel();
        }
    }, [printSize]);

    return (
        <BSCModal
            open={open}
            onClose={handleOnClose}
            headerLabel="Print Label"
            size="sm"
            bscScrollerProps={{scrollX: false, scrollY: false}}
            isPlainForPrint={isPrintReady}
        >
            <>
                <BSCDivider></BSCDivider>
                <div className={classes.formWrapper} style={isPrintReady ? {visibility: 'hidden'} : {visibility: 'visible'}}>
                    <BSCTypography className={classes.label} label="Preference:" />
                    <BSCRadioGroup
                        value="-1"
                        onChange={e => {
                            handlePrintPref(e);
                        }}
                        start
                        noLabel={true}
                        style={{width: 'max-content'}}
                    >
                        <FormControlLabel
                            className={classes.smallRadio}
                            value="0"
                            control={<BSCRadio style={{padding: '9px 4px'}} />}
                            label='8.5"x11" Paper'
                        />
                        <FormControlLabel
                            className={classes.smallRadio}
                            value="1"
                            control={<BSCRadio style={{padding: '9px 4px'}} />}
                            label='4"x6" Label'
                        />
                    </BSCRadioGroup>
                </div>
                {printLabelImage && isPrintReady && printSize === 'Letter' && (
                    <div className={classes.printLetter}>
                        <img src={printLabelImage} alt="shipping Label" />
                    </div>
                )}
                {printLabelImage && isPrintReady && printSize === '4x6' && (
                    <div className={classes.printLabel}>
                        <img src={printLabelImage} alt="shipping Label" />
                    </div>
                )}
                {printLabelImage && !isPrintReady && (
                    <div className={classes.previewLabel}>
                        <img src={printLabelImage} alt="shipping Label" />
                    </div>
                )}
            </>
        </BSCModal>
    );
};

export default PrintLabelModal;
