/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import SetSliderClient from '../../../../client/s3/homepage/setSliderClient';
import CarouselClient from '../../../../client/s3/mpCarousel/carouselClient';
import SliderClient from '../../../../client/s3/mpCarousel/sliderClient';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import PhoneSportSlider from './bestSellers/PhoneSportSlider';
import SportSlider from './bestSellers/SportSlider';
import HomeCarousel from './homeCarousel/homeCarousel';

const useStyles = makeStyles((theme: Theme) => ({
    padding: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '15px',
        },
    },
}));
export type GraphData = {
    displayName: string;
    jobTitle: string;
    mail: string;
    businessPhones: string[];
    officeLocation: string;
};

export default function Home() {
    const {data: carouselItems} = useQuery(() => CarouselClient.getCarouselItems(), []);
    const {data: sliderItems} = useQuery(() => SliderClient.getSliderItems(), []);
    const classes = useStyles();
    const {loading: setSliderLoading, data: setSliders} = useQuery(() => SetSliderClient.getSets(), []);
    const {isBelowTablet} = useScreenSnap();

    const sliderCategoriesOrder = [
        'players',
        'releases',
        'brands',
        'rainbow',
        'classic_sets',
        'sports',
        'lineup_legends',
        'teams',
        '1990s',
        'quality_qbs',
        'attributes',
        '2000s',
        'graders',
        '1980s',
    ];
    const [sliderData, setSliderData] = useState([]);
    useEffect(() => {
        let orderedSliderCategories = [];
        if (sliderItems && sliderItems.sliders) {
            for (let x = 0; x < sliderItems.sliders.length; x++) {
                const itemsArray = sliderItems.sliders[x].items;
                const filteredItemsArray = itemsArray.filter(itemsArrayElement => itemsArrayElement.title !== '');
                // and now - shuffle
                let currentIndex = filteredItemsArray.length;
                while (currentIndex !== 0) {
                    const randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;
                    [filteredItemsArray[currentIndex], filteredItemsArray[randomIndex]] = [
                        filteredItemsArray[randomIndex],
                        filteredItemsArray[currentIndex],
                    ];
                }
                sliderItems.sliders[x].items = filteredItemsArray;
            }
            for (let y = 0; y < sliderCategoriesOrder.length; y++) {
                const sliderSection: never[] = sliderItems.sliders.filter(sliderItem => sliderItem.slider_key === sliderCategoriesOrder[y]);
                if (sliderSection.length > 0) {
                    orderedSliderCategories = [...orderedSliderCategories, ...sliderSection];
                }
            }
            setSliderData(orderedSliderCategories);
        }
    }, [sliderItems]);

    useEffect(() => {
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HomeCarousel carouselItems={carouselItems} />
            {sliderData && (
                <>
                    {isBelowTablet && (
                        <div style={{margin: 'auto', padding: '12px 0 12px 12px'}}>
                            <PhoneSportSlider sliders={sliderData} />
                        </div>
                    )}
                    {!isBelowTablet && (
                        <div style={{margin: 'auto', padding: '20px 0 20px 20px'}}>
                            <SportSlider sliders={sliderData} />
                        </div>
                    )}
                </>
            )}
        </>
    );
}
