import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCCurrency from '../../../../../../../features/common/bscCurrency/bscCurrency';
import BSCRatingField from '../../../../../../../features/common/bscRatingField/bscRating';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import {Order} from '../../../../../../../client/openapitools/marketplace/api';
import OrderFeedbackModal from './orderFeedback';
import {Link} from '@material-ui/core';
import useDateTimeNormalizer from '../../../../../../../client/common/normalizer/dateTimeNormalizer';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import {OrderApi as MarketplaceOrderApi} from '../../../../../../../client/openapitools/marketplace/api';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#fff',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: 4,
        padding: theme.spacing(2),
        '& p': {
            fontSize: '12px',
        },
    },
    mobile: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    soldBy: {
        paddingBottom: theme.spacing(2),
    },
    paddingBottom: {
        paddingBottom: theme.spacing(1),
    },
    editBtnColor: {
        backgroundColor: '#DAE0E5',
    },
    fullOpaque: {
        opacity: 1,
    },
}));

interface OrderInfoProps {
    order: Order;
}

function OrderHistoryItemInfo({order}: OrderInfoProps) {
    const classes = useStyles();
    const history = useHistory();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const dateNormalizer = useDateTimeNormalizer();
    const handleContactClick = useCallback(() => {
        history.push({
            pathname: '/dashboard/messages/create',
            state: {order},
        });
    }, [history, order]);

    const [feedbackModalOpen, setFeedBackModalOpen] = useState(false);
    const handleFeedbackClick = useCallback(() => {
        setFeedBackModalOpen(true);
    }, [history, order]);

    const submitFeedback = ({rating, comments}) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const marketplaceOrderApi = new MarketplaceOrderApi(apiConfig);
                            const response = await marketplaceOrderApi.createFeedback({
                                orderId: order.orderId,
                                rating: rating,
                                comment: comments,
                                sellerId: order.seller.id,
                            });
                            if (response.data) {
                                order.feedback = response.data;
                                setFeedBackModalOpen(false);
                            } else {
                                throw new Error('Failed to process Order History feedback.');
                            }
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to process Order History feedback.',
                                contextKey: 'Order History',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={4} justifyContent="space-between">
                <Grid item lg={2} md={6} xs={12}>
                    <BSCTypography label="Order Summary" bold />
                    <BSCDivider noTopMargin />
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <BSCTypography label="Subtotal" />
                        </Grid>
                        <Grid item>
                            <BSCCurrency amount={order.subTotal} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <BSCTypography label="Shipping" />
                        </Grid>
                        <Grid item>
                            <BSCCurrency amount={order.shippingCost} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <BSCTypography label="Sales Tax" />
                        </Grid>
                        <Grid item>
                            <BSCCurrency amount={order.salesTax} />
                            {/** */}
                        </Grid>
                    </Grid>
                    {order.refundTotal > 0 && (
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <BSCTypography label="Refunds" />
                            </Grid>
                            <Grid item style={{color: '#EF2424'}}>
                                <div style={{display: 'inline-flex'}}>
                                    <BSCTypography label="(" />
                                    <BSCCurrency amount={order.refundTotal} />
                                    <BSCTypography label=")" />
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    <BSCDivider noMargin />
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <BSCTypography label="Total" bold />
                        </Grid>
                        <Grid item>
                            <BSCCurrency amount={order.total} bold />
                        </Grid>
                    </Grid>
                </Grid>
                <BSCDivider noMargin className={classes.mobile} />
                <Grid item lg={3} md={6} xs={12}>
                    <BSCTypography label="Shipping" bold />
                    <BSCDivider noTopMargin />

                    {order.shippingAddress?.firstName && (
                        <BSCTypography label={`${order.shippingAddress?.firstName} ${order.shippingAddress?.lastName}`} />
                    )}
                    {!order.shippingAddress?.firstName && order?.seller?.sellerProfile?.sellerStoreName && (
                        <BSCTypography label={order?.seller?.sellerProfile?.sellerStoreName} />
                    )}

                    <BSCTypography label={order?.shippingAddress?.addressLine1} />
                    {order?.shippingAddress?.addressLine2 && <BSCTypography label={order?.shippingAddress?.addressLine2} />}
                    <BSCTypography
                        label={order?.shippingAddress?.city + ', ' + order?.shippingAddress?.state + ', ' + order?.shippingAddress?.zipCode}
                    />
                </Grid>
                <BSCDivider noMargin className={classes.mobile} />
                <Grid item lg={3} md={6} xs={12}>
                    <BSCTypography label="Billing" bold />
                    <BSCDivider noTopMargin />
                    {order.billingAddress?.firstName && (
                        <BSCTypography label={`${order.billingAddress?.firstName} ${order.billingAddress?.lastName}`} />
                    )}
                    {order.billingAddress?.firstName && (
                        <>
                            <BSCTypography label={order.buyer.username} />
                            <BSCTypography label={order?.billingAddress?.addressLine1} />
                            {order?.billingAddress?.addressLine2 && <BSCTypography label={order?.billingAddress?.addressLine2} />}
                            <BSCTypography
                                label={
                                    order?.billingAddress?.city + ', ' + order?.billingAddress?.state + ' ' + order?.billingAddress?.zipCode
                                }
                            />
                        </>
                    )}
                </Grid>
                <BSCDivider noMargin className={classes.mobile} />
                <Grid item lg={3} md={6} xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between" className={classes.paddingBottom}>
                        <Grid item xs={6}>
                            <BSCTypography label={`Sold By: ${order?.seller?.sellerProfile?.sellerStoreName}`} bold />
                        </Grid>
                        <Grid item xs={6}>
                            <BSCButton color="primary" onClick={handleContactClick}>
                                <BSCTypography label="Contact" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={6}>
                            {order?.feedback && (
                                <BSCRatingField
                                    name={order.orderId}
                                    className={classes.fullOpaque}
                                    disabled
                                    value={order?.feedback?.rating ? parseInt(order?.feedback?.rating) : 0}
                                ></BSCRatingField>
                            )}
                            {!order?.feedback && <BSCTypography label={'No Feedback Left'} semibold />}
                        </Grid>
                        <Grid item xs={6}>
                            {!order?.feedback && (
                                <BSCButton color="secondary" onClick={handleFeedbackClick}>
                                    <BSCTypography label="Leave Feedback" />
                                </BSCButton>
                            )}
                            {order?.feedback && (
                                <BSCButton className={classes.editBtnColor} onClick={handleFeedbackClick}>
                                    <BSCTypography label="Edit Feedback" />
                                </BSCButton>
                            )}
                        </Grid>
                    </Grid>
                    <BSCDivider />
                    {/* WARNING: This will probably need to be swapped out with something.
                    Probably with order.trackingnumber and a switch on it

                    //Change shipped date to the tracking number prop currently missing 11/18/21*/}
                    {order?.shipments && order?.shipments[0]?.shippedDate && order?.shipments[0]?.trackingNumber && (
                        <Grid container>
                            <BSCTypography
                                label={`Shipped with Tracking (${dateNormalizer.getDisplayDateTime(order?.shipments[0]?.shippedDate)})`}
                                bold
                            />
                            <br />
                            <Link href={`https://parcelsapp.com/en/tracking/${order?.shipments[0]?.trackingNumber}`} target="_blank">
                                <BSCTypography label={order?.shipments[0]?.trackingNumber} bold />
                            </Link>
                        </Grid>
                    )}
                    {order?.shipments && order?.shipments[0]?.shippedDate && !order?.shipments[0]?.trackingNumber && (
                        <Grid container>
                            <BSCTypography
                                label={`Shipped without Tracking (${dateNormalizer.getDisplayDateTime(order?.shipments[0]?.shippedDate)})`}
                                bold
                            />
                        </Grid>
                    )}
                    {!order?.shipments || (!order?.shipments[0]?.shippedDate && <BSCTypography label={'Order Not Shipped'} bold />)}
                </Grid>
            </Grid>

            <OrderFeedbackModal
                order={order}
                modalOpen={feedbackModalOpen}
                setModalOpen={setFeedBackModalOpen}
                onSubmit={submitFeedback}
            ></OrderFeedbackModal>
        </div>
    );
}

export default OrderHistoryItemInfo;
