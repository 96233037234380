import {useMemo} from 'react';

/* eslint-disable */
const FormValidationsNormalizer = {
    getShippingAddressValidationsSchema: validationResults =>
        null ?? {
            firstName: {
                id: 'firstName',
                pattern: null,
                label: 'First Name',
                notDependable: false,
                deps: null,
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            },
            lastName: {
                id: 'lastName',
                pattern: null,
                label: 'Last Name',
                notDependable: false,
                deps: null,
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            },
            addressLine1: {
                id: 'addressLine1',
                pattern: null,
                label: 'Street Address 1',
                notDependable: false,
                deps: [['city'], ['state'], ['zipCode']],
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            },
            addressLine2: {
                id: 'addressLine2',
                pattern: null,
                label: 'Street Address 2',
                notDependable: true,
                deps: null,
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            },
            city: {
                id: 'city',
                pattern: null,
                label: 'City',
                notDependable: false,
                deps: [['addressLine1'], ['state'], ['zipCode']],
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            },
            state: {
                id: 'state',
                pattern: null,
                label: 'State',
                notDependable: false,
                deps: [['city'], ['addressLine1'], ['zipCode']],
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            },
            zipCode: {
                id: 'zipCode',
                pattern: '^\\d{5}(-\\d{4})?$',
                label: 'Zip Code',
                notDependable: false,
                deps: [['city'], ['state'], ['addressLine1']],
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            },
        },
    getConversationValidationsSchema: validationResults => validationResults?.conversation ?? null,
    getOfferModalValidationsSchema: validationResults => validationResults?.offerModal ?? null,
    getReportModalValidationsSchema: validationResults => validationResults?.reporting ?? null,
    getUserInfoValidationsSchema: validationResults =>
        validationResults?.userInfo ??
        useMemo(() => {
            return {
                firstName: {
                    id: 'firstName',
                    pattern: null,
                    label: 'First Name',
                    notDependable: false,
                    deps: [['all']],
                    isRequired: true,
                    maxLength: null,
                    minLength: null,
                    type: 'string',
                    defaultValue: null,
                },
                lastName: {
                    id: 'lastName',
                    pattern: null,
                    label: 'Last Name',
                    notDependable: false,
                    deps: [['all']],
                    isRequired: true,
                    maxLength: null,
                    minLength: null,
                    type: 'string',
                    defaultValue: null,
                },
                username: {
                    id: 'username',
                    pattern: null,
                    label: 'User Name',
                    notDependable: false,
                    deps: [['all']],
                    isRequired: true,
                    maxLength: null,
                    minLength: null,
                    type: 'string',
                    defaultValue: null,
                },
                phoneNumber: {
                    id: 'phoneNumber',
                    pattern: null,
                    label: 'Phone Number',
                    notDependable: true,
                    deps: null,
                    isRequired: true,
                    maxLength: null,
                    minLength: null,
                    type: 'string',
                    defaultValue: null,
                },
            };
        }, []),
};

export default FormValidationsNormalizer;
