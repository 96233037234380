import {FormControl, FormHelperText, OutlinedInput, Grid, TextField} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCCardBox from '../bscCardBox/bscCardBox';
import BSCDivider from '../bscDivider/bscDivider';
import BSCModal from '../bscModal/bscModal';
import BSCTypography from '../bscTypography/bscTypography';
import {ReactComponent as EditIcon} from '../../../svgs/edit.svg';
import {useState, useMemo, forwardRef} from 'react';
import {Alert, Autocomplete} from '@material-ui/lab';
import BSCButton from '../bscButton/bscButton';
import BSCGridTableRow from '../bscGridTableRow/bscGridTableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {useEffect} from 'react';
import {GoogleAutoCompelete, GetPostalCode} from '../../../client/google/places';
import CloseIcon from '@material-ui/icons/Close';

import {Address} from '../../../client/openapitools/marketplace';

const useStyles = makeStyles((theme: Theme) => ({
    customerInfo: {
        marginBottom: theme.spacing(2),
    },
    alert: {
        marginBottom: theme.spacing(2),
    },
    streetAddressInputStyleProp: {
        background: theme.palette.common.white,
        borderRadius: 4,
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('md')]: {
            //   width: 'auto',
            width: '100%',
        },
    },
    endAdornmentInput: {
        //position: 'absolute',
        right: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            right: theme.spacing(0),
        },
    },
    endAdormentClick: {
        cursor: 'pointer',
    },
}));

export interface AddressErrors {
    firstName?: boolean;
    lastName?: boolean;
    addressLine1?: boolean;
    city?: boolean;
    state?: boolean;
    zipCode?: boolean;
    country?: boolean;
}

export const useAutoCompleteAddress = (initialAddress: Address, InputProps = {}, WarningProps = {}) => {
    const classes = useStyles();
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState(initialAddress);
    const [errors, setErrors] = useState<AddressErrors>({});

    // const formValid =
    //     !(errors.firstName || errors.lastName || errors.addressLine1 || errors.city || errors.state || errors.zipCode || errors.country) &&
    //     address?.firstName &&
    //     address?.lastName &&
    //     address?.addressLine1 &&
    //     address?.city &&
    //     address?.state &&
    //     address?.zipCode &&
    //     address?.country;

    const [formValid, setFormValid] = useState(false);
    useEffect(() => {
        if (address && errors) {
            if (
                !(
                    errors.firstName ||
                    errors.lastName ||
                    errors.addressLine1 ||
                    errors.city ||
                    errors.state ||
                    errors.zipCode ||
                    errors.country
                ) &&
                address?.firstName &&
                address?.lastName &&
                address?.addressLine1 &&
                address?.city &&
                address?.state &&
                address?.zipCode &&
                address?.country
            ) {
                setFormValid(true);
            } else {
                setFormValid(false);
            }
        }
    }, [address, errors]);

    const onFieldBlur = (required, name, value) => {
        if (required) {
            if (!value) {
                setErrors(state => {
                    return {...state, [name]: true};
                });
            } else {
                setErrors(state => {
                    return {...state, [name]: false};
                });
            }
        }
    };
    const [autoCompleteAddress, setAutoCompleteAddress] = useState(false);
    interface strippedEvent {
        target: {
            name: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: undefined;
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const changeAddress = async (value, registerFormChange = (event: strippedEvent) => {}) => {
        if (value === null) {
            setAutoCompleteAddress(false);
            setAddress(state => {
                return {
                    firstName: state.firstName,
                    lastName: state.lastName,
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: '',
                };
            });
        }

        let result = null;
        let selAdd = null;
        for (let x = 0; x < addresses.length; x++) {
            if (value === addresses[x].description) {
                result = await GetPostalCode(addresses[x].place_id);
                selAdd = addresses[x];
                // Get Zip code
            }
        }
        if (!result) {
            return;
        }
        //setAutoCompleteAddress(true);
        // let streetNumber = null;
        // let street = null;

        // for (let i = 0; i < result.address_components.length; i++) {
        //     if (result.address_components[i].types[0] === 'street_number') {
        //         streetNumber = result.address_components[i].long_name;
        //     }
        // }
        // //if google wont return the street number grab it from the selected address object
        // if (!streetNumber) streetNumber = selAdd?.terms[0]?.value;

        // for (let i = 0; i < result.address_components.length; i++) {
        //     if (result.address_components[i].types[0] === 'route') {
        //         street = result.address_components[i].long_name;
        //     }
        // }

        const newAddr = {} as Address;
        // newAddr.addressLine1 = `${streetNumber} ${street}`;
        newAddr.addressLine1 = selAdd.structured_formatting.main_text;
        setIsUsingAutoComplete(false);
        newAddr.addressLine2 = null;

        let postcode = null;
        for (let i = 0; i < result.address_components.length; i++) {
            if (result.address_components[i].types[0] === 'postal_code') {
                postcode = result.address_components[i].long_name;
            }
        }
        newAddr.zipCode = postcode;

        // Get city
        let city = null;
        for (let i = 0; i < result.address_components.length; i++) {
            if (result.address_components[i].types[0] === 'locality') {
                city = result.address_components[i].long_name;
            }
        }
        if (!city) {
            for (let i = 0; i < result.address_components.length; i++) {
                if (result.address_components[i].types[0] === 'administrative_area_level_2') {
                    city = result.address_components[i].long_name;
                }
            }
        }
        newAddr.city = city;

        // Get State
        let state = null;
        for (let i = 0; i < result.address_components.length; i++) {
            if (result.address_components[i].types[0] === 'administrative_area_level_1') {
                state = result.address_components[i].short_name;
            }
        }
        if (!state) {
            for (let i = 0; i < result.address_components.length; i++) {
                if (result.address_components[i].types[0] === 'administrative_area_level_2') {
                    state = result.address_components[i].short_name;
                }
            }
        }
        newAddr.state = state;

        // Get Country
        let country = null;
        for (let i = 0; i < result.address_components.length; i++) {
            if (result.address_components[i].types[0] === 'country') {
                state = result.address_components[i].short_name;
            }
        }
        if (!country) {
            country = 'US';
        }
        newAddr.country = country;

        setAddress({...address, ...newAddr});

        onFieldBlur(true, 'addressLine1', 'auto');
        onFieldBlur(true, 'city', 'auto');
        onFieldBlur(true, 'state', 'auto');
        onFieldBlur(true, 'zipCode', 'auto');
    };

    const handleChangeAddress = async searchValue => {
        setAddress(state => {
            return {...state, addressLine1: searchValue.target.value};
        });

        if (!searchValue.target.value) {
            return null;
        }
        const results = await GoogleAutoCompelete(searchValue.target.value);
        if (results) {
            setAddresses(results);
        } else {
            setAddresses([]);
        }
    };

    const onAddresSearchTrigger = e => {
        handleChangeAddress(e);
    };

    const onNonAutoCompleteChange = (name, value) => {
        setAddress(state => {
            return {...state, [name]: value};
        });
    };

    const [isUsingAutoComplete, setIsUsingAutoComplete] = useState(address?.addressLine1 ? false : true);
    const onClear = () => {
        setIsUsingAutoComplete(true);
    };

    useEffect(() => {
        if (initialAddress && initialAddress.addressLine1) {
            setIsUsingAutoComplete(false);
        } else {
            setIsUsingAutoComplete(true);
        }
    }, [initialAddress]);

    return {
        setAddress,
        address,
        handleChangeAddress,
        onAddresSearchTrigger,
        errors,
        formValid,
        firstName: (
            <FormControl error={errors.firstName} fullWidth>
                <OutlinedInput
                    {...InputProps}
                    autoComplete="off"
                    error={errors.firstName}
                    name="firstName"
                    fullWidth
                    onChange={e => onNonAutoCompleteChange('firstName', e.target.value)}
                    value={address?.firstName}
                    onBlur={e => onFieldBlur(true, 'firstName', e.currentTarget.value)}
                />
                <FormHelperText hidden={!errors.firstName} id="component-error-text" {...WarningProps}>
                    First Name is required
                </FormHelperText>
            </FormControl>
        ),
        lastName: (
            <FormControl error={errors.lastName} fullWidth>
                <OutlinedInput
                    {...InputProps}
                    autoComplete="off"
                    error={errors.lastName}
                    name="lastName"
                    fullWidth
                    onChange={e => onNonAutoCompleteChange('lastName', e.target.value)}
                    value={address?.lastName}
                    onBlur={e => onFieldBlur(true, 'lastName', e.currentTarget.value)}
                />
                <FormHelperText hidden={!errors.lastName} id="component-error-text" {...WarningProps}>
                    Last Name is required
                </FormHelperText>
            </FormControl>
        ),
        address1: initialAddress ? (
            <>
                {isUsingAutoComplete && (
                    <Autocomplete
                        {...InputProps}
                        options={addresses.map(option => option.description)}
                        // closeIcon= { () => { return; } }
                        onChange={(event, value) => {
                            changeAddress(value);
                        }}
                        inputValue={address?.addressLine1}
                        autoComplete={false}
                        renderInput={params => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                onChange={onAddresSearchTrigger}
                                fullWidth
                                name="addressLine1"
                                variant="outlined"
                                error={errors.addressLine1}
                                onBlur={e => onFieldBlur(true, 'addressLine1', e.currentTarget.value)}
                                onFocus={onAddresSearchTrigger}
                                onPaste={onAddresSearchTrigger}
                            />
                        )}
                    />
                )}
                {!isUsingAutoComplete && (
                    <TextField
                        {...InputProps}
                        InputProps={{
                            endAdornment: (
                                <CloseIcon onClick={onClear} className={(classes.endAdornmentInput, classes.endAdormentClick)}></CloseIcon>
                            ),
                        }}
                        autoComplete="off"
                        onChange={onAddresSearchTrigger}
                        fullWidth
                        name="addressLine1"
                        variant="outlined"
                        value={address?.addressLine1}
                        error={errors.addressLine1}
                        onBlur={e => onFieldBlur(true, 'addressLine1', e.currentTarget.value)}
                        onFocus={onAddresSearchTrigger}
                        onPaste={onAddresSearchTrigger}
                    />
                )}
            </>
        ) : (
            <Autocomplete
                {...InputProps}
                options={addresses.map(option => option.description)}
                // closeIcon= { () => { return; } }
                onChange={(event, value) => {
                    changeAddress(value);
                }}
                inputValue={address?.addressLine1}
                autoComplete={false}
                renderInput={params => (
                    <TextField
                        {...params}
                        autoComplete="off"
                        onChange={onAddresSearchTrigger}
                        fullWidth
                        name="addressLine1"
                        variant="outlined"
                        error={errors.addressLine1}
                        onBlur={e => onFieldBlur(true, 'addressLine1', e.currentTarget.value)}
                        onFocus={onAddresSearchTrigger}
                        onPaste={onAddresSearchTrigger}
                    />
                )}
            />
        ),
        address2: (
            <TextField
                {...InputProps}
                variant="outlined"
                id="field4"
                inputProps={{list: 'autocompleteOff', autoComplete: 'new-street-address'}}
                fullWidth
                onChange={e => onNonAutoCompleteChange('addressLine2', e.target.value)}
                value={address?.addressLine2}
            />
        ),
        city: (
            <FormControl error={errors.city && !autoCompleteAddress} fullWidth>
                <OutlinedInput
                    {...InputProps}
                    disabled={autoCompleteAddress}
                    inputProps={{list: 'autocompleteOff', autoComplete: 'new-street-address'}}
                    id="field1"
                    fullWidth
                    onChange={e => onNonAutoCompleteChange('city', e.target.value)}
                    value={address?.city}
                    error={errors.city}
                    onBlur={e => onFieldBlur(true, 'city', e.currentTarget.value)}
                />
                <FormHelperText hidden={!errors.city || autoCompleteAddress} id="component-error-text" {...WarningProps}>
                    City is required
                </FormHelperText>
            </FormControl>
        ),
        state: (
            <FormControl error={errors.state && !autoCompleteAddress} fullWidth>
                <OutlinedInput
                    {...InputProps}
                    disabled={autoCompleteAddress}
                    inputProps={{list: 'autocompleteOff', autoComplete: 'new-street-address'}}
                    id="field2"
                    fullWidth
                    onChange={e => onNonAutoCompleteChange('state', e.target.value)}
                    value={address?.state}
                    onBlur={e => onFieldBlur(true, 'state', e.currentTarget.value)}
                />
                <FormHelperText hidden={!errors.state || autoCompleteAddress} id="component-error-text" {...WarningProps}>
                    State is required
                </FormHelperText>
            </FormControl>
        ),
        zip: (
            <FormControl error={errors.zipCode && !autoCompleteAddress} fullWidth>
                <OutlinedInput
                    {...InputProps}
                    disabled={autoCompleteAddress}
                    inputProps={{list: 'autocompleteOff', autoComplete: 'new-street-address'}}
                    id="field3"
                    fullWidth
                    onChange={e => onNonAutoCompleteChange('zipCode', e.target.value)}
                    value={address?.zipCode}
                    onBlur={e => onFieldBlur(true, 'zipCode', e.currentTarget.value)}
                />
                <FormHelperText hidden={!errors.state || autoCompleteAddress} id="component-error-text" {...WarningProps}>
                    Zip Code is required
                </FormHelperText>
            </FormControl>
        ),
    };
};

interface FormEditProps {
    type: string;
    defaultValue: Address;
    checked?: boolean;
    onChecked?: (s: boolean, t: string) => void;
    onComplete?: () => void;
    //eslint-disable-next-line
    onSaveAddress: (address: Address) => Promise<undefined>;
    //eslint-disable-next-line
    formValidation: undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FormEdit({type, defaultValue, checked, onChecked, onComplete, formValidation, onSaveAddress}: FormEditProps) {
    const {setAddress, address, formValid, firstName, lastName, address1, address2, city, state, zip} =
        useAutoCompleteAddress(defaultValue);

    const handleSubmit = e => {
        e.preventDefault();
        onSaveAddress({...address, type});
        onComplete();
    };

    useEffect(() => setAddress(defaultValue), [defaultValue]);

    return (
        <form onSubmit={handleSubmit}>
            <BSCGridTableRow flip gutterBottom col4={<BSCTypography bold label="First Name:" />} col8={firstName} />
            <BSCGridTableRow flip gutterBottom col4={<BSCTypography bold label="Last Name:" />} col8={lastName} />

            {/* Google Places address 1 */}
            <BSCGridTableRow flip gutterBottom col4={<BSCTypography bold label="Street Address 1:" />} col8={address1} />
            {/* <BSCGridTableRow
                flip
                gutterBottom
                col4={<BSCTypography bold label="Street Address 1:" />}
                col8={
                    <BSCTextField
                        autoComplete="off"
                        onBlur={registerFormBlur}
                        formStateValidations={formStateValidations}
                        inputKey="addressLine1"
                        fullWidth
                        onChange={registerFormChange}
                    />
                }
            /> */}

            <BSCGridTableRow flip gutterBottom col4={<BSCTypography bold label="Street Address 2:" />} col8={address2} />
            <BSCGridTableRow flip gutterBottom col4={<BSCTypography bold label="City:" />} col8={city} />
            <BSCGridTableRow flip gutterBottom col4={<BSCTypography bold label="State:" />} col8={state} />
            <BSCGridTableRow flip gutterBottom col4={<BSCTypography bold label="Zip Code:" />} col8={zip} />
            {type === 'billing' && (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Checkbox color="primary" checked={checked} onChange={e => onChecked(e.target.checked)} />
                    <BSCTypography label="Same as Shipping address" />
                </div>
            )}
            {type === 'shipping' && (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Checkbox color="primary" checked={checked} onChange={e => onChecked(e.target.checked)} />
                    <BSCTypography label="Same as Billing address" />
                </div>
            )}
            <BSCDivider />
            <BSCButton disabled={!formValid} fullWidth type="submit" color="primary">
                Save
            </BSCButton>
        </form>
    );
}

interface BSCAddressProps {
    address: Address;
    addressTypeDisplay: string;
    addressType: string;
    infoComponent?: React.ReactNode;
    addPadding?: boolean;
    //eslint-disable-next-line
    onSaveAddress: (address: Address) => Promise<undefined>;
    onFormClose?: () => void;
    //eslint-disable-next-line
    formValidation: undefined;
    isBillingSameAsShippingChecked?: boolean;
    onBillingSameAsShippingChecked?: (e: boolean) => void;
    isCartAddress?: boolean;
    duplicateAddress: Address;
}

const BSCAddress = forwardRef(
    (
        {
            address,
            addressTypeDisplay,
            addressType,
            infoComponent,
            formValidation,
            isBillingSameAsShippingChecked,
            addPadding = true,
            isCartAddress = false,
            onBillingSameAsShippingChecked,
            onSaveAddress,
            onFormClose = () => {},
            duplicateAddress,
        }: BSCAddressProps,
        ref
    ) => {
        const classes = useStyles();
        const [isFormOpen, toggleFormOpen] = useState(false);
        const validation = useMemo(() => {
            const woot = {...formValidation};
            woot.googleAddress = {
                id: 'googleAddress',
                pattern: null,
                label: 'Autocomplete Address Search',
                notDependable: true,
                deps: null,
                isRequired: true,
                maxLength: null,
                minLength: null,
                type: 'string',
                defaultValue: null,
            };
            return woot;
        }, [formValidation]);

        const [editFormDefaultAddress, setEditFormDefaultAddress] = useState<Address>(address);

        useEffect(() => setEditFormDefaultAddress(address), [address]);
        useEffect(() => {
            if (ref) {
                ref.current = {
                    setEditFormAddress: (address: Address) => {
                        setEditFormDefaultAddress(address);
                    },
                };
            }
        }, []);

        useEffect(() => {
            if (duplicateAddress && duplicateAddress !== {}) {
                setEditFormDefaultAddress(duplicateAddress);
            }
        }, [duplicateAddress]);

        return (
            <>
                <BSCCardBox className={addPadding ? classes.customerInfo : ''} style={isCartAddress ? {minHeight: 181} : {}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <BSCTypography variant="body2" label={addressTypeDisplay} />
                        <EditIcon
                            onClick={() => toggleFormOpen(true)}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </Grid>
                    <BSCDivider />

                    {!address && (
                        <Alert className={classes.alert} severity="warning" variant="filled">
                            No {addressTypeDisplay} available
                        </Alert>
                    )}
                    {address && (
                        <>
                            <BSCTypography label={address && `${address?.firstName} ${address?.lastName}`} variant="subtitle1" />
                            <BSCTypography label={address?.addressLine1} variant="subtitle1" />
                            <BSCTypography label={address?.addressLine2} variant="subtitle1" />
                            <BSCTypography
                                label={address && `${address?.city}, ${address?.state}, ${address?.zipCode}`}
                                variant="subtitle1"
                            />
                            {infoComponent && (
                                <>
                                    <BSCDivider />
                                    {infoComponent}
                                </>
                            )}
                        </>
                    )}
                </BSCCardBox>
                {isFormOpen && (
                    <BSCModal open={isFormOpen} onClose={() => toggleFormOpen(false)}>
                        <FormEdit
                            onComplete={() => {
                                toggleFormOpen(false);
                                onFormClose();
                            }}
                            defaultValue={editFormDefaultAddress}
                            type={addressType}
                            formValidation={validation}
                            onSaveAddress={onSaveAddress}
                            checked={isBillingSameAsShippingChecked}
                            onChecked={onBillingSameAsShippingChecked}
                        />
                    </BSCModal>
                )}
            </>
        );
    }
);
export default BSCAddress;
