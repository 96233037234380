import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import React from 'react';
import {Switch, Route} from 'react-router';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import ReplyMessage from '../../../marketplace/pages/dashboard/tabs/messages/components/replyMessage';
import CreateMessage from '../../../marketplace/pages/dashboard/tabs/messages/components/createMessage';
import ReferencesClient from '../../../../client/references/referencesClient';
import SellersMessageList from './sellerMessageList';

function SellersMessages() {
    const {data: msgRef} = useQuery(() => ReferencesClient.getMessageReferences(), []);
    return (
        <BSCContainer variant="page">
            <Switch>
                <Route exact path="/sellers/messages">
                    <SellersMessageList msgRef={msgRef} />
                </Route>
                <Route exact path={'/sellers/messages/reply/:messageId'}>
                    <ReplyMessage isMarketPlace={false} messageRef={msgRef} orderUrl="/sellers/orders" />
                </Route>
                <Route exact path={'/sellers/messages/create/'}>
                    <CreateMessage isMarketPlace={false} messageRef={msgRef} orderUrl="/sellers/orders" />
                </Route>
            </Switch>
        </BSCContainer>
    );
}

export default SellersMessages;
