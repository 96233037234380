import {useState, memo, useCallback, MouseEvent} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import SubNav from './subNav';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {MarketPlaceMenu} from '../../../../@types/menus';
import classNames from 'classnames';
import useReduxHomePage from '../../../hooks/redux/homePage/homePage';
import {useEffect} from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        marginRight: 4,
        fontSize: theme.typography.body2.fontSize,
        padding: theme.spacing(1),
        '&:hover': {
            background: theme.palette.common.white,
            color: theme.palette.common.black,
        },
    },
    menuContainer: {background: theme.palette.primary.main},
    skeletonMenuItem: {marginRight: 20},
    menuWrap: {
        ...theme.containerWraps.page,
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    opened: {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    navLinks: {
        color: 'transparent',
    },
}));

interface NavbarProps {
    menus: MarketPlaceMenu;
    menusLoading?: boolean;
}

function Navbar({menus, menusLoading = false}: NavbarProps) {
    const classes = useStyles();
    const {getTopSeller} = useReduxHomePage();
    const [hover, setHover] = useState<null | HTMLElement>(null);
    const [activeSubMenuKey, setActiveSubMenuKey] = useState<null | number>();
    const handleMouseEnter = (htmlElement: HTMLElement, i: number) => {
        setActiveSubMenuKey(i);
        setHover(htmlElement);
    };
    const [potentialNavigationRoutes, setPotentialNavigationRoutes] = useState([]);
    useEffect(() => {
        if (menus && menus.categories && menus.categories.length > 0) {
            const tempMenus = [];
            for (let x = 0; x < menus.categories.length; x++) {
                tempMenus.push({
                    focus: menus.categories[x].title,
                    url: menus.categories[x].url,
                });
                for (let y = 0; y < menus.categories[x].menus.length; y++) {
                    tempMenus.push({
                        focus: menus.categories[x].title + ' - ' + menus.categories[x].menus[y].title,
                        url: menus.categories[x].menus[y].url,
                    });
                    for (let z = 0; z < menus.categories[x].menus[y].links.length; z++) {
                        tempMenus.push({
                            focus:
                                menus.categories[x].title +
                                ' - ' +
                                menus.categories[x].menus[y].title +
                                ' - ' +
                                menus.categories[x].menus[y].links[z].title,
                            url: menus.categories[x].menus[y].links[z].url,
                        });
                    }
                }
            }
            setPotentialNavigationRoutes(tempMenus);
        }
    }, [menus]);

    useEffect(() => {
        getTopSeller();
    }, []);

    const handleMouseLeave = useCallback(() => {
        setActiveSubMenuKey(null);
    }, []);

    const handlePopoverEnter = useCallback((id: number) => {
        setActiveSubMenuKey(id);
    }, []);

    const handlePopoverLeave = useCallback(() => {
        setActiveSubMenuKey(null);
    }, []);

    return (
        <div className={classes.menuContainer}>
            <div className={classes.menuWrap}>
                <Grid container justifyContent="space-around">
                    {menusLoading && (
                        <>
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                        </>
                    )}
                    {menus?.categories?.map((cats, i) => (
                        <div key={i}>
                            <MenuItem
                                onMouseEnter={(e: MouseEvent<HTMLElement>) => {
                                    handleMouseEnter(e.currentTarget, i);
                                }}
                                onMouseLeave={handleMouseLeave}
                                className={classNames(classes.root, {[classes.opened]: activeSubMenuKey === i})}
                            >
                                {cats.title}
                                <KeyboardArrowDownIcon
                                    style={{
                                        color: 'black',
                                    }}
                                />
                            </MenuItem>
                            {activeSubMenuKey === i && (
                                <SubNav
                                    id={i}
                                    anchorEl={hover}
                                    open={activeSubMenuKey === i}
                                    menus={cats.menus}
                                    url={cats.url}
                                    popoverEnter={handlePopoverEnter}
                                    popoverLeave={handlePopoverLeave}
                                />
                            )}
                        </div>
                    ))}
                </Grid>
                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: -500, left: -1500}}>
                        {potentialNavigationRoutes &&
                            potentialNavigationRoutes.map((navRoute, index) => (
                                <a href={navRoute.url} key={index} className={classes.navLinks}>
                                    {navRoute.focus}
                                </a>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Navbar);
