import React, {useState} from 'react';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import {ReactComponent as NotificationIcon} from '../../../../../../../svgs/notification.svg';
import {makeStyles} from '@material-ui/core/styles';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import BSCCustomSwitch from '../../../../../../../features/common/bscSwitch/bscCustomSwitch';
import useReduxUser from '../../../../../../../features/hooks/redux/user/useReduxUser';
import {useEffect} from 'react';
import BscToggledEditButton from '../../../../../../../features/common/bscEditIcon/bscEditIcon';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';
import {SilentRequest, AccountInfo} from '@azure/msal-browser';
import {UserApi} from '../../../../../../../client/openapitools/marketplace';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';

const useStyles = makeStyles(theme => ({
    headerStyle: {
        fontWeight: 400,
    },
    font14: {
        fontSize: '14px',
    },
    switch: {
        '& > span:nth-child(2)': {
            backgroundColor: '#EF2424 !important',
            '&:before': {
                content: "''",
                fontSize: theme.typography.subtitle2.fontSize,
                fontStyle: 'italic',
            },

            '&:after': {
                content: "'OFF'",
                color: theme.palette.common.white,
                fontSize: theme.typography.subtitle2.fontSize,
                fontStyle: 'italic',
            },
        },
    },
    checked: {
        '& > span:nth-child(2)': {
            backgroundColor: '#358856 !important',
            '&:before': {
                content: "'ON'",
            },

            '&:after': {
                content: "''",
            },
        },
    },
}));
const FIELD_IDS = {
    ORDER_CONFIRMATION: 'orderConfirmation',
    RECEIVED_OFFERS: 'receivedOffers',
    MESSAGES: 'messages',
    Discounts: 'discountsOffersAndSpecialPromotions',
};

function Notification() {
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {userState, setUser} = useReduxUser();
    const {apiAuthConfig} = useAuthState();
    const [notification, setNotification] = useState({
        id: null,
        orderConfirmation: false,
        receivedOffers: false,
        messages: false,
        discountsOffersAndSpecialPromotions: false,
    });
    const {instance} = useMsal();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    useEffect(() => {
        if (userState) {
            setNotification({
                id: userState.userNotifications.id,
                orderConfirmation: userState.userNotifications.orderConfirmation,
                receivedOffers: userState.userNotifications.receivedOffers,
                messages: userState.userNotifications.messages,
                discountsOffersAndSpecialPromotions: userState.userNotifications.discountsOffersAndSpecialPromotions,
            });
        }
    }, [userState]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotification({...notification, [event.target.name]: event.target.checked});
    };

    const onSave = async () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const result = await userApi.updateUserNotifications(notification);
                            setNotification({
                                id: result.data.id,
                                orderConfirmation: result.data.orderConfirmation,
                                receivedOffers: result.data.receivedOffers,
                                messages: result.data.messages,
                                discountsOffersAndSpecialPromotions: result.data.discountsOffersAndSpecialPromotions,
                            });
                            setNotificationEditMode(false);
                        } catch (e) {
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Authentication',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const [notificationEditMode, setNotificationEditMode] = useState(false);
    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <div style={{display: 'inline-flex'}}>
                        <NotificationIcon style={{paddingRight: '15px'}} />
                        <BSCTypography label="Notifications" variant="body2" />
                    </div>
                </Grid>
                <Grid item>
                    <BscToggledEditButton isEdit={notificationEditMode} onCLick={() => setNotificationEditMode(!notificationEditMode)} />
                </Grid>
            </Grid>
            <BSCDivider />
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={10} xs={8}>
                    <BSCTypography label="Order Confirmation" variant="subtitle1" />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Grid container justifyContent="flex-end" spacing={0}>
                        <Grid item>
                            <BSCCustomSwitch
                                label=""
                                disabled={!notificationEditMode}
                                className={classNames(classes.switch, {
                                    [classes.checked]: notification.orderConfirmation,
                                })}
                                checked={notification.orderConfirmation}
                                onChange={handleChange}
                                name={FIELD_IDS.ORDER_CONFIRMATION}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={10} xs={8}>
                    <BSCTypography label="Offers" variant="subtitle1" />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Grid container justifyContent="flex-end" spacing={0}>
                        <Grid item>
                            <BSCCustomSwitch
                                label=""
                                disabled={!notificationEditMode}
                                className={classNames(classes.switch, {
                                    [classes.checked]: notification.receivedOffers,
                                })}
                                checked={notification.receivedOffers}
                                onChange={handleChange}
                                name={FIELD_IDS.RECEIVED_OFFERS}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={10} xs={8}>
                    <BSCTypography label="Messages" variant="subtitle1" />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Grid container justifyContent="flex-end" spacing={0}>
                        <Grid item>
                            <BSCCustomSwitch
                                label=""
                                disabled={!notificationEditMode}
                                className={classNames(classes.switch, {
                                    [classes.checked]: notification.messages,
                                })}
                                checked={notification.messages}
                                onChange={handleChange}
                                name={FIELD_IDS.MESSAGES}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={10} xs={8}>
                    <BSCTypography label="Deals, Discounts and Special Promotions" variant="subtitle1" />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Grid container justifyContent="flex-end" spacing={0}>
                        <Grid item>
                            <BSCCustomSwitch
                                label=""
                                disabled={!notificationEditMode}
                                className={classNames(classes.switch, {
                                    [classes.checked]: notification.discountsOffersAndSpecialPromotions,
                                })}
                                checked={notification.discountsOffersAndSpecialPromotions}
                                onChange={handleChange}
                                name={FIELD_IDS.Discounts}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                {notificationEditMode && (
                    <BSCButton color="primary" className={classes.font14} fullWidth onClick={() => onSave()}>
                        Save
                    </BSCButton>
                )}
            </Grid>
        </>
    );
}

export default Notification;
