import {styled} from '@material-ui/core';
import {Rating} from '@material-ui/lab';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

const BSCRatingField = props => {
    return <StyledRating {...props} icon={<StarIcon fontSize="inherit" />} emptyIcon={<StarOutlineIcon fontSize="inherit" />} />;
};

export default BSCRatingField;
