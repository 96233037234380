export interface Condition {
    id: string;
    /**
     * The text for the grid / accordian
     */
    display: string;
    /**
     * string array.  Each entry will be a new line.
     * For an empty line add an empty string
     */
    infoText: string[];
    /**
     * an image to display on the info drop down
     */
    infoImage: string;
    /**
     * the info for the grid
     */
    corners: string;
    /**
     * the info for the grid
     */
    edges: string;
    /**
     * the info for the grid
     */
    discoloration: string;
    /**
     * the info for the grid
     */
    surfaceIndentations: string;
    /**
     * the info for the grid
     */
    creases: string;
    /**
     * the info for the grid
     */
    scratches: string;
    /**
     * the info for the grid
     */
    staining: string;
}

export interface CardPart {
    id: string;
    /**
     * display text in the table
     */
    display: string;
    /**
     * the Info text for the popupup
     */
    infoText: string;
    /**
     * an array of images to show.  Just the AWS keys as the root s3 url will come from configuration
     */
    infoImage: string[];
}

export interface ConditionResponseData {
    condition: Condition[];
    cardPart: CardPart[];
}

export interface getConditionsResponse {
    /**
     * mimic Axios calls
     */
    data: ConditionResponseData;
}

class ConditionsApi {
    getConditions(): Promise<getConditionsResponse> {
        return Promise.resolve({
            data: {
                condition: [
                    {
                        id: 'nm',
                        display: 'Near Mint or Better',
                        infoText: [
                            'Cards in Near Mint condition are equivalent to a fresh pack. They only have slight imperfections such as 1–⁠3 rounding or soft corners and slightly rough edges. Any damage aside from this would automatically lower the card’s condition.',
                            '',
                            'Signs of wear:',
                            '1. Corner wear',
                        ],
                        infoImage: '',
                        corners: '	Minor chipping',
                        edges: 'Minor chipping',
                        discoloration: 'None',
                        surfaceIndentations: 'Minor',
                        creases: 'None',
                        scratches: 'Minor',
                        staining: 'None',
                    },
                    {
                        id: 'ex',
                        display: 'Excellent',
                        infoText: [
                            'Excellent condition cards have clearly visible signs of wear. These cards have rough edges, moderate chipping, minor discoloration, minor indentation, fuzzy corners, or noticeable scratches.',
                            '',
                            'Signs of wear:',
                            '1. Minor surface scuffing',
                            '2. Edge chipping',
                            '3. Fuzzy corners',
                        ],
                        infoImage: '',
                        corners: 'Moderate corner wear with multiple dinged corners',
                        edges: 'Slightly rough edges with moderate chipping and/or light indentations',
                        discoloration: 'Minor (e.g. wax stains on the reverse)',
                        surfaceIndentations: 'Moderate',
                        creases: 'Minor surface wrinkles',
                        scratches: 'Moderate',
                        staining: 'Minor',
                    },
                    {
                        id: 'vg',
                        display: 'Very Good',
                        infoText: [
                            'Very Good condition cards show moderate-to-heavy damage all over the card. These cards have a moderate amount of discoloration, chipping, indentation, creases, or scratches. There may also be a slight paper loss.',
                            '',
                            'Signs of wear:',
                            '1. Moderate discoloration',
                            '2. Creasing',
                            '3. Edge chipping',
                            '4. Rounding and soft corners',
                        ],
                        infoImage: '',
                        corners: 'Rounding on all four corners',
                        edges: 'Moderate-to-heavy chipping and/or indentations',
                        discoloration: 'Moderate to major',
                        surfaceIndentations: 'Moderate to major',
                        creases: 'Moderate with multiple creases allowed',
                        scratches: 'Moderate to major, some slight paper loss',
                        staining: '	Moderate to major',
                    },
                    {
                        id: 'p',
                        display: 'Poor',
                        infoText: [
                            'A card in Poor condition is extremely worn or missing corners. These cards display flaws like torn edges, extreme discoloration, multiple major scratches, multiple creases, or stains.',
                            '',
                            'Signs of wear:',
                            '1. Heavily worn and rounded corners',
                            '2. Major crease',
                            '3. Moderate discoloration',
                            '4. Border wear',
                        ],
                        infoImage: '',
                        corners: 'Major rounding with one or more corners missing',
                        edges: 'Major chipping and indentation with paper loss',
                        discoloration: 'Major discoloration over a large area',
                        surfaceIndentations: 'Major',
                        creases: 'Multiple major creases',
                        scratches: 'Major scratches/scuffing with paper loss, tears and pin holes',
                        staining: 'Major',
                    },
                ],
                cardPart: [
                    {
                        id: 'corners',
                        display: 'Corners',
                        infoText:
                            'The quality of corners is determined by the number of flaws on the corner of a card. Flaws include rounding, softening, or splitting.',
                        infoImage: [],
                    },
                    {
                        id: 'edges',
                        display: 'Edges',
                        infoText: 'The quality of edges is determined by the amount of apparent wear or damage.',
                        infoImage: [],
                    },
                    {
                        id: 'discoloration',
                        display: 'Discoloration',
                        infoText: 'Refers to any yellowing, cloudiness, staining, or sun-bleaching on a card.',
                        infoImage: [],
                    },
                    {
                        id: 'surfaceIndentations',
                        display: 'Surface Indentations',
                        infoText: 'Refers to any dents, craters, pinholes, or cavities on the surface of the card.',
                        infoImage: [],
                    },
                    {
                        id: 'creases',
                        display: 'Creases',
                        infoText: 'Refers to any dents, craters, pinholes, or cavities on the surface of the card.',
                        infoImage: [],
                    },
                    {
                        id: 'scratches',
                        display: 'Scratches/Scuffing',
                        infoText:
                            'Refers to any marks caused by scraping or brushing. This type of damage can appear on either face of the card.',
                        infoImage: [],
                    },
                    {
                        id: 'staining',
                        display: 'Staining',
                        infoText: 'Refers to any wax stains, ink stains, or bleeding color on the card.',
                        infoImage: [],
                    },
                ],
            },
        });
    }
    getVintageConditions(): Promise<getConditionsResponse> {
        return Promise.resolve({
            data: {
                condition: [
                    {
                        id: 'mt',
                        display: 'Mint',
                        infoText: [
                            'Mint is a superb condition card that exhibits only one of the following minor flaws: a very slight wax stain on reverse, a minor printing imperfection or slightly off-white borders. Centering must be approximately 60/40 to 65/35 or better on the front and 90/10 or better on the reverse.',
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'nm-mt',
                        display: 'Near Mint-Mint (NM-MT)',
                        infoText: [
                            'NM-MT is a super high-end card that appears Mint at first glance, but upon closer inspection, the card can exhibit the following: a very slight wax stain on reverse, slightest fraying at one or two corners, a minor printing imperfection, and/or slightly off-white borders. Centering must be approximately 65/35 to 70/30 or better on the front and 90/10 or better on the reverse.',
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'nm',
                        display: 'Near Mint (NM)',
                        infoText: [
                            'NM is a card with just a slight surface wear visible upon close inspection. There may be slight fraying on some corners. Picture focus may be slightly out-of-register. A minor printing blemish is acceptable. Slight wax staining is acceptable on the back of the card only. Most of the original gloss is retained. Centering must be approximately 70/30 to 75/25 or better on the front and 90/10 or better on the back.',
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'ex-mt',
                        display: 'Excellent-Mint (EX-MT)',
                        infoText: [
                            'EX-MT is a card that may have visible surface wear or a printing defect which does not detract from its overall appeal. A very light scratch may be detected only upon close inspection. Corners may have slightly graduated fraying. Picture focus may be slightly out-of-register. Cards may show some loss of original gloss, may have minor wax stain on reverse, may exhibit very slight notching on edges and may also show some off-whiteness on borders. Centering must be 80/20 or better on the front and 90/10 or better on the reverse.',
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'ex',
                        display: 'Excellent (EX)',
                        infoText: [
                            'On EX cards, very minor rounding of the corners is becoming evident. Surface wear or printing defects are more visible. There may be minor chipping on edges. Loss of original gloss will be more apparent. Focus of the picture may be slightly out-of-register. Several light scratches may be visible upon close inspection, but do not detract from the appeal of the card. Cards may show some off-whiteness of borders. Centering must be 85/15 or better on the front and 90/10 or better on the back.',
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'vg-ex',
                        display: 'Very Good-Excellent (VG-EX)',
                        infoText: [
                            "A VG-EX card's corners may be slightly rounded. Surface wear is noticeable but modest. The card may have light scuffing or light scratches. Some original gloss will be retained. Borders may be slightly off-white. A light crease may be visible. Centering must be 85/15 or better on the front and 90/10 or better on the back.",
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'vg',
                        display: 'Very Good (VG)',
                        infoText: [
                            "A VG card reveals some rounding of the corners, though not extreme. Some surface wear will be apparent, along with possible light scuffing or light scratches. Focus may be somewhat off-register and edges may exhibit noticeable wear. Much, but not all, of the card's original gloss will be lost. Borders may be somewhat yellowed and/or discolored. A crease may be visible. Printing defects are possible. Slight stain may show on the obverse and wax staining on the reverse may be more prominent. Centering must be 90/10 or better on the front and back.",
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'gd',
                        display: 'Good',
                        infoText: [
                            "A Good card's corners show accelerated rounding and surface wear is starting to become obvious. A good card may have scratching, scuffing, light staining, or chipping of enamel on the obverse. There may be several creases. Original gloss may be completely absent. Cards may show considerable discoloration. Centering must be 90/10 or better on the front and back.",
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'fr',
                        display: 'Fair',
                        infoText: [
                            "A Fair card's corners will show extreme wear, possibly affecting framing of the picture. The surface of the card will show advanced stages of wear, including scuffing, scratching, pitting, chipping and staining. The picture will possibly be quite out-of-register and the borders may have become brown and dirty. The card may have one or more heavy creases. In order to achieve a Fair grade, a card must be fully intact. Even though the card may be heavily worn, it cannot achieve this grade if it is missing solid pieces of the card as a result of a major tear, etc. This would include damage such as the removal of the back layer of the card or an entire corner. The centering must be approximately 90/10 or better on the front and back.",
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                    {
                        id: 'pr',
                        display: 'Poor',
                        infoText: [
                            'A Poor card will exhibit many of the same qualities of a Fair card but the defects may have advanced to such a serious stage that the eye appeal of the card has nearly vanished in its entirety. A Poor card may be missing one or two small pieces, exhibit major creasing that nearly breaks through all the layers of cardboard or it may contain extreme discoloration or dirtiness throughout that may make it difficult to identify the issue or content of the card on either the front or back. A card of this nature may also show noticeable warping or another type of destructive defect.',
                        ],
                        infoImage: '',
                        corners: '',
                        edges: '',
                        discoloration: '',
                        surfaceIndentations: '',
                        creases: '',
                        scratches: '',
                        staining: '',
                    },
                ],
                cardPart: [
                    {
                        id: 'corners',
                        display: 'Corners',
                        infoText:
                            'The quality of corners is determined by the number of flaws on the corner of a card. Flaws include rounding, softening, or splitting.',
                        infoImage: [],
                    },
                    {
                        id: 'edges',
                        display: 'Edges',
                        infoText: 'The quality of edges is determined by the amount of apparent wear or damage.',
                        infoImage: [],
                    },
                    {
                        id: 'discoloration',
                        display: 'Discoloration',
                        infoText: 'Refers to any yellowing, cloudiness, staining, or sun-bleaching on a card.',
                        infoImage: [],
                    },
                    {
                        id: 'surfaceIndentations',
                        display: 'Surface Indentations',
                        infoText: 'Refers to any dents, craters, pinholes, or cavities on the surface of the card.',
                        infoImage: [],
                    },
                    {
                        id: 'creases',
                        display: 'Creases',
                        infoText: 'Refers to any dents, craters, pinholes, or cavities on the surface of the card.',
                        infoImage: [],
                    },
                    {
                        id: 'scratches',
                        display: 'Scratches/Scuffing',
                        infoText:
                            'Refers to any marks caused by scraping or brushing. This type of damage can appear on either face of the card.',
                        infoImage: [],
                    },
                    {
                        id: 'staining',
                        display: 'Staining',
                        infoText: 'Refers to any wax stains, ink stains, or bleeding color on the card.',
                        infoImage: [],
                    },
                ],
            },
        });
    }
}

export default ConditionsApi;
