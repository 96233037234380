import {s3Client} from '../../';

const serviceUrls = {
    mpSetSlider: 'mpBrands.json',
};

const SetSliderClient = {
    getSets: () => s3Client.get(serviceUrls.mpSetSlider).then(res => res),
};

export default SetSliderClient;
