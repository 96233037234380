import {memo} from 'react';
import Grid from '@material-ui/core/Grid';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Navbar from './sellersNavbar';
import BSCButton from '../../../common/bscButton/bscButton';
//import StorefrontIcon from '@material-ui/icons/Storefront';
import {SellerSignInButton} from '../../../users/sellerSignInButton';
import {ReactComponent as BSCBuyersLogo} from '../../../../svgs/bscSellers.svg';

const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        background: theme.palette.primary.main,
        padding: theme.spacing(2),
    },
    headerWrap: {
        ...theme.containerWraps.page,
        paddingTop: 0,
        paddingBottom: 0,
    },
    logo: {
        height: 51,
        width: 121,
        ['&:hover']: {
            cursor: 'pointer',
        },
    },
    sellersPortal: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
    sellersLinkPortal: {
        textDecoration: 'none',
        marginRight: 12,
        '& span': {
            textTransform: 'none',
        },
    },
    menuRight: {display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'flex-end'},
    logoTxt: {
        color: theme.palette.common.white,
    },
}));

interface DesktopNavProps {
    //TODO: Fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menus: undefined;
    menusLoading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenu: undefined;
    userMenuLoading: boolean;
    onPortalChange: () => void;
}

function DesktopNav({menus, menusLoading = false, userMenu, userMenuLoading = false, onPortalChange}: DesktopNavProps) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.headerWrap}>
                    <Grid container alignItems="center">
                        <Grid item container alignItems="center" sm={2}>
                            <BSCBuyersLogo className={classes.logo} onClick={() => history.push('/sellers')} />
                        </Grid>
                        <Grid className={classes.menuRight}>
                            <SellerSignInButton style={{marginRight: 24}} userMenuLoading={userMenuLoading} userMenu={userMenu} />
                            <BSCButton
                                variant="text"
                                onClick={onPortalChange}
                                className={classes.sellersLinkPortal}
                                style={{color: 'white', fontSize: 12}}
                                to="/"
                                component={Link}
                                //   startIcon={<StorefrontIcon color="primary" />}
                            >
                                Return to Marketplace
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Navbar menus={menus} menusLoading={menusLoading} />
        </>
    );
}

export default memo(DesktopNav);
