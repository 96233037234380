/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect, useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import useForm from '../../../../../../../packages/core/src/hooks/useForm';
import {useQuery} from '../../../../../../../packages/core/src/hooks/useQuery';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCTextField from '../../../../../../../features/common/bscTextField/bscTextField';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import SellersFormValidationsClient from '../../../../../../../client/s3/sellersFormValidations/sellersFormValidationsClient';
import SellersFormValidationsNormalizer from '../../../../../../../client/s3/sellersFormValidations/sellersFormValidationsNormalizer';
import BSCEditToggle from '../../../../../../../features/common/bscEditToggle/bscEditToggle';
import {PaymentSetting} from '../../../../../../../client/openapitools/seller';
// import BscToggledEditButton from '../../../../../../../features/common/bscEditIcon/bscEditIcon';

interface PaymentSettingsProps {
    information: PaymentSetting;
    onSave: Function;
}

const PaymentSettings = ({information = {}, onSave}: PaymentSettingsProps) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const {data: sellersFormValidations, loading: sellersFormValidationsLoading} = useQuery(
        () => SellersFormValidationsClient.getSellersFormValidations(),
        []
    );
    const {
        formState,
        validatedSchema: sellersFormStateValidations,
        registerFormChange,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formComplete,
        setDefaultValues,
    } = useForm({
        validationSchema: SellersFormValidationsNormalizer.getPaymentSettings(sellersFormValidations),
    });
    const edit = useCallback(() => {
        setIsEdit(prevState => !prevState);
    }, [setIsEdit]);
    const onSubmit = useCallback(() => {
        onSave({paymentSettings: formState});
        setIsEdit(!isEdit);
    }, [onSave, setIsEdit, isEdit, formState]);

    useEffect(() => {
        setDefaultValues({
            paymentOption: information.bankAccountType,
            accountName: information.fundingSourceName,
            accountNumber: information.accountNumber,
        });
    }, [information]);

    return (
        <BSCCardBox>
            <Grid container justifyContent="space-between">
                <BSCTypography variant="body2" label="Payment Settings" />
                {/* <BscToggledEditButton isEdit={isEdit} onCLick={edit}></BscToggledEditButton> */}
            </Grid>
            <BSCDivider />
            {sellersFormValidationsLoading && <BSCLoading loading={sellersFormValidationsLoading} />}
            {!sellersFormValidationsLoading && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={3}>
                                <BSCTypography label="Account Name:" variant="subtitle1" />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <BSCEditToggle
                                    isEdit={isEdit}
                                    trueChild={
                                        <BSCTextField
                                            inputKey="accountName"
                                            disabled={!isEdit}
                                            formStateValidations={sellersFormStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    }
                                    falseChild={<BSCTypography label={information.fundingSourceName} variant="subtitle1" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <BSCDivider noMargin />
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            style={{color: '#41474D', fontSize: 12, fontFamily: "'Poppins', sans-serif", fontWeight: 400, lineHeight: 1.75}}
                        >
                            If you need to change your bank account information, please contact us at&nbsp;
                            <a href="mailto:support@buysportscards.com">support@buysportscards.com</a>.
                        </div>
                    </Grid>

                    {isEdit && (
                        <Grid item xs={12}>
                            <BSCDivider />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <BSCButton fullWidth color="primary" onClick={onSubmit}>
                                        <BSCTypography label="Save Changes" size14 />
                                    </BSCButton>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BSCButton fullWidth color="secondary" onClick={onSubmit}>
                                        <BSCTypography label="Remove Payment" size14 />
                                    </BSCButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </BSCCardBox>
    );
};

export default PaymentSettings;
