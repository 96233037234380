import {memo} from 'react';
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router-dom';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Navbar from './navbar';
import AsyncSearchInput from '../../../../features/asyncSearchInput/asyncSearchInput';
import {MarketPlaceMenu} from '../../../../@types/menus';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import {SignInButton} from '../../../users/signInButton';
import {ReactComponent as BSCBuyersLogo} from '../../../../svgs/bscBuyers.svg';
import CartButton from '../../../cartButton/cartButton';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import {useAuthFunctions} from '../../../../AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        background: 'black',
        padding: theme.spacing(1.5),
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    headerWrap: {
        ...theme.containerWraps.page,
        paddingTop: 0,
        paddingBottom: 0,
    },
    logo: {
        height: 43,
        width: 102,
        verticalAlign: 'middle',
        ['&:hover']: {
            cursor: 'pointer',
        },
    },
    sellersPortal: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
    sellersLinkPortal: {
        textDecoration: 'none',
        marginRight: 12,
        marginLeft: 10,
        textTransform: 'inherit',
        '& span': {
            marginRight: theme.spacing(0.5),
        },
    },
    menuRight: {display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'flex-end'},
}));

interface DesktopNavProps {
    // setSearch: Function;
    // suggestionLoading: boolean;
    // search: string | null;
    // suggestionResult: SearchCard[];
    menus: MarketPlaceMenu;
    menusLoading?: boolean;
    userMenuLoading?: boolean;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenu: undefined;
}

function DesktopNav({
    // search,
    // setSearch,
    // suggestionLoading,
    // suggestionResult,
    menus,
    menusLoading = false,
    userMenuLoading,
    userMenu,
}: DesktopNavProps) {
    const classes = useStyles();
    const history = useHistory();
    const {userState} = useReduxUser();
    const authFunc = useAuthFunctions();

    function handleOnClick() {
        if (userState) {
            if (userState.roleId === '2') {
                //is seller
                history.push('/sellers');
            } else if (userState.roleId === '1') {
                //is buyer
                history.push('/sellers/signup');
            }
        } else {
            authFunc.login();
        }
    }

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.headerWrap}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <BSCBuyersLogo className={classes.logo} onClick={() => history.push('/')} />
                        </Grid>
                        <Grid item style={{flexGrow: 2, padding: '0 4rem'}}>
                            <AsyncSearchInput
                            // search={search}
                            // setSearch={setSearch}
                            // suggestionLoading={suggestionLoading}
                            // suggestionResult={suggestionResult}
                            />
                        </Grid>
                        <Grid item style={{alignSelf: 'flex-end'}}>
                            <Grid container alignItems="center" justifyContent="flex-end">
                                <Grid item>
                                    <SignInButton userMenu={userMenu} style={{fontSize: '12px'}} userMenuLoading={userMenuLoading} />
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <BSCButton
                                                variant="text"
                                                className={classes.sellersLinkPortal}
                                                style={{color: 'white', fontSize: '12px'}}
                                                onClick={handleOnClick}
                                            >
                                                Sell with BSC
                                            </BSCButton>
                                        </Grid>
                                        <Grid item>
                                            <CartButton />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Navbar menus={menus} menusLoading={menusLoading} />
        </>
    );
}

export default memo(DesktopNav);
