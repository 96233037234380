import {forwardRef} from 'react';
import Switch, {SwitchProps} from '@material-ui/core/Switch';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: 86,
        height: 32,
        padding: 0,
        margin: theme.spacing(1),
        marginRight: 0,
    },
    switchBase: {
        padding: 1,
        top: '50%',
        transform: 'translate(4px, -48%)',

        '&$checked': {
            transform: 'translate(56px, -48%)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 'none',
            },
            '&.Mui-disabled ': {
                border: 'none',
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main,
                opacity: '0.4!important' as '0.4',
            },
        },
        '&.Mui-disabled +$track': {
            opacity: '0.4!important' as '0.4',
        },
        // '&$checked + span': {
        //     background: '#B8C2CC',
        // },
        '&$focusVisible $thumb': {
            color: theme.palette.primary.main,
            border: `6px solid  ${theme.palette.common.white}`,
        },
        '& input': {
            left: '-200%',
            width: '500%',
        },
    },
    thumb: {
        width: 25,
        height: 25,
        borderRadius: 12,
        boxShadow: '0px 0px 0px 1.13273px rgba(16, 22, 26, 0.1), 0px 1.13273px 2.26546px rgba(16, 22, 26, 0.2)',
    },
    track: {
        borderRadius: 16,
        background: '#B8C2CC',
        boxShadow: 'inset 0px -4px 4px rgba(0, 0, 0, 0.25)',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        '&:before': {
            content: '"ON"',
            position: 'absolute',
            top: '50%',
            left: '16px',
            transform: 'translateY(-50%)',
            fontSize: 18,
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            color: theme.palette.common.white,
        },
        ['&:after']: {
            content: '"OFF"',
            position: 'absolute',
            top: '50%',
            left: '34px',
            transform: 'translateY(-50%)',
            fontSize: 18,
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
        },
    },
    checked: {},
    focusVisible: {},
}));

interface BSCSwitchProps extends SwitchProps {
    label: string;
    classesOverrides?: Object;
}
const BSCCustomSwitch = forwardRef<HTMLButtonElement, BSCSwitchProps>(({...otherProps}: BSCSwitchProps, ref) => {
    const classes = useStyles();
    return (
        <Switch
            {...otherProps}
            ref={ref}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
        />
    );
});

export default BSCCustomSwitch;
