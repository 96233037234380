import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setUser, setNotification} from '../../../../redux/slices/users.slice';
import {addSessionId, clearSessionId} from '../../../../redux/slices/cart.slice';
// import {v4 as uuidv4} from 'uuid';
import {RootState} from '../../../../redux/reducers';
import jwt_decode from 'jwt-decode';
import {useAuthState} from '../../../../AuthContext';
import useApi from '../apiConfig/useApi';
import {User} from '../../../../client/openapitools/marketplace';

//const sessionId = uuidv4();

const getShippingAddress = (userState: RootState['user']['user']) => {
    return userState?.buyerProfile?.shippingAddresses?.length > 0 ? userState?.buyerProfile?.shippingAddresses[0] : null;
};

const getBillingAddress = (userState: RootState['user']['user']) => {
    return userState?.buyerProfile?.billingAddresses?.length > 0 ? userState?.buyerProfile?.billingAddresses[0] : null;
};

const getPrimaryCard = (userState: RootState['user']['user']) => {
    return userState?.buyerProfile.paymentMethods?.find(payment => payment.primary);
};

const getDeviceId = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        deviceId = 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, () => {
            return ((Math.random() * 16) | 0).toString(16);
        });
        localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
};

function useReduxUser() {
    const dispatch = useDispatch();
    const userState = useSelector((s: RootState) => s.user.user);
    const userLoading = useSelector((s: RootState) => s.user.loading);
    const cartId = useSelector((s: RootState) => s.cart.cartId);

    const {isAuthenticated, account, accessToken} = useAuthState();

    const getCartSessionId = useCallback(() => {
        if (cartId) return cartId;
        else {
            // const newCartId = uuidv4();
            const newCartId = getDeviceId();
            dispatch(addSessionId(newCartId));
            return newCartId;
        }
    }, [cartId]);

    let userRole = account && account.idTokenClaims?.extension_roleId;
    if (userState?.sellerProfile?.applicationStatus === 'completed') {
        userRole = '2';
    }

    const api = useApi();
    const setUserWrapper = (user: User) => {
        return dispatch(setUser(user));
    };

    const setNotificationWrapper = userNotifications => {
        return dispatch(setNotification({api, userNotifications}));
    };

    const userShipping = useMemo(() => getShippingAddress(userState), [userState]);
    const userBilling = useMemo(() => getBillingAddress(userState), [userState]);
    const payments = useMemo(() => userState?.buyerProfile?.paymentMethods, [userState]);
    const userPrimaryCard = useMemo(() => getPrimaryCard(userState), [userState]);
    const decodedJWT = useMemo(() => accessToken && jwt_decode(accessToken), [accessToken]);
    return {
        accounts: [account],
        sessionId: cartId,
        decodedJWT,
        getCartSessionId: getCartSessionId,
        removeCartSessionId: dispatch(() => clearSessionId),
        userState,
        userLoading,
        userCompleteInfo: userShipping && userBilling,
        userShipping,
        userBilling,
        payments,
        userPrimaryCard,
        isAuth: isAuthenticated,
        userRole,
        setUser: setUserWrapper,
        setUserNotification: setNotificationWrapper,
    };
}

export default useReduxUser;
