import {ModalProps} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Alert} from '@material-ui/lab';
import {useHistory} from 'react-router';
import {useAuthFunctions} from '../../../../AuthContext';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';

const useStyles = makeStyles((theme: Theme) => ({
    marginBottom: {marginBottom: theme.spacing(2)},
}));

interface SellProductModalProps extends Omit<ModalProps, 'children'> {}

const SellProductModal = ({open, onClose}: SellProductModalProps) => {
    const classes = useStyles();
    const history = useHistory();
    const {isAuth, userRole} = useReduxUser();

    const {login} = useAuthFunctions();

    const handleRedirectLogin = () => {
        login();
    };

    const handleRedirectSignupSeller = () => {
        history.push({
            pathname: '/sellers/signup',
        });
    };

    return (
        <BSCModal open={open} onClose={onClose}>
            <div>
                <BSCTypography align="center" variant="h3" label="Sell product" />
                <BSCDivider />
                {userRole === '1' ? (
                    <div>
                        <Alert severity="warning" className={classes.marginBottom}>
                            Sign up as a seller to use this feature.
                        </Alert>
                        <BSCButton color="primary" fullWidth onClick={handleRedirectSignupSeller}>
                            Sign up
                        </BSCButton>
                    </div>
                ) : !isAuth ? (
                    <div>
                        <Alert severity="error" className={classes.marginBottom}>
                            Sign in/Sign up to use this feature.
                        </Alert>
                        <BSCButton color="primary" fullWidth onClick={handleRedirectLogin}>
                            Sign in/Sign up
                        </BSCButton>
                    </div>
                ) : null}
            </div>
        </BSCModal>
    );
};

export default SellProductModal;
