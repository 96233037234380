import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import Rating from '@material-ui/lab/Rating';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import {Rating as SellerRating} from '../../../../../../../client/openapitools/seller';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(0),
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        position: 'static',
        width: '100%',
        background: 'linear-gradient(180deg, #FFFFFF 65.52%, #DAE0E6 100%)',
        boxShadow: '0px 2px 0px #B8C2CC',
        borderRadius: '4px 4px 0px 0px',
        boxSizing: 'border-box',
        fontSize: 16,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        margin: 'auto',
    },
    ratingLabel: {
        marginBottom: theme.spacing(2),
    },
    commentWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    comment: {
        border: '1px solid #B8C2CC',
        borderRadius: 4,
        boxSizing: 'border-box',
        margin: '2px',
        maxWidth: 324,
        padding: theme.spacing(2),
    },
}));

interface CurrentRatingProps {
    rating: SellerRating;
    comments: Array<String>;
    loading: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CurrentRating = ({rating, comments, loading}: CurrentRatingProps) => {
    const classes = useStyles();

    return (
        <BSCCardBox className={classes.root}>
            <BSCTypography className={classes.header} label="Current Rating" />
            {loading && (
                <div className={classes.content}>
                    <BSCLoading loading={loading} />
                </div>
            )}
            {rating && (
                <div className={classes.content}>
                    <Rating
                        className={classes.ratingLabel}
                        name="half-rating"
                        size="large"
                        defaultValue={Number(rating.average)}
                        precision={0.5}
                        readOnly
                        style={{fontSize: '3rem'}}
                    />
                    <BSCTypography label={`${rating.average} Stars (${rating.reviews} Total Reviews)`} variant="body2" />
                </div>
            )}
        </BSCCardBox>
    );
};

export default CurrentRating;
