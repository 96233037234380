import React from 'react';

import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(1),
    },
}));

const BePro = () => {
    const classes = useStyles();
    return <div className={classes.root}></div>;
};

export default BePro;
