/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useRef} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {usePagination} from '@material-ui/lab/Pagination';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import BSCButton from '../bscButton/bscButton';
import BSCTypography from '../bscTypography/bscTypography';
import classNames from 'classnames';
import {getPageNum, numberWithCommas} from '../../../packages/core/src/util/util';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        '& li:first-child': {
            marginLeft: 'auto',
        },
        '& li:last-child': {
            marginRight: 'auto',
        },
    },
    li: {
        display: 'flex',
        alignItems: 'center',
    },
    hide: {
        display: 'none',
    },
    paginationBtn: {
        height: 32,
        minWidth: 32,
        margin: theme.spacing(0, 0.5),
        fontSize: theme.typography.subtitle1.fontSize,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0),
        },
    },
    ellipsis: {
        margin: theme.spacing(0, 1),
        marginTop: theme.spacing(2),
        color: '#0003',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0.5),
        },
    },
    arrowBtn: {
        marginTop: 5,
        color: 'black',
        padding: theme.spacing(1),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            opacity: 0.6,
        },
    },
    arrowBtnNoHover: {
        color: 'lightslategrey',
        marginTop: 5,
        padding: theme.spacing(1),
    },
    lastPage: {
        padding: theme.spacing(0.5),
        fontSize: '14px',
    },
}));

function BSCPagination({total, sizePerPage, defaultPage, onChange}) {
    const {isMobile} = useScreenSnap();
    const classes = useStyles();
    const count = getPageNum(total, sizePerPage);
    const {items} = usePagination({
        count: count,
        onChange: onChange,
        page: defaultPage,
        boundaryCount: 1,
        siblingCount: 1,
    });

    const pageIndex = useRef(defaultPage);

    useEffect(() => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].page === defaultPage) {
                pageIndex.current = i;
                break;
            }
        }
    }, [items]);

    return (
        <nav>
            <ul className={classes.ul}>
                {items.map(({page, type, selected, ...item}, index) => {
                    let children = null;
                    if (isMobile && Math.abs(page - defaultPage) > (defaultPage === 1 ? 2 : 1)) {
                        return null;
                    } else if (!isMobile) {
                        if (items.length === 8) {
                            if (index === 4 && defaultPage < 3) {
                                return null;
                            } else if (index === 5 && defaultPage <= 3) {
                                //|| (index === 2 && defaultPage >= 4)) {
                                //fifth page
                                type = 'end-ellipsis';
                            }
                        } else if (items.length >= 8 && defaultPage < 3) {
                            if (index >= 4 && index < items.length - 3) {
                                return null;
                            } else if (index === items.length - 3) {
                                type = 'end-ellipsis';
                            }
                        } else if (items.length >= 8 && pageIndex.current >= 3) {
                            if (
                                index > 2 &&
                                (index < pageIndex.current - 1 || index > pageIndex.current + 1) &&
                                index < items.length - 3 &&
                                pageIndex.current <= 5
                            ) {
                                return null;
                                // eslint-disable-next-line prettier/prettier
                            } else if (
                                (pageIndex.current <= 5 &&
                                    // eslint-disable-next-line prettier/prettier
                                    index === items.length - 3 &&
                                    items.length - 3 !== pageIndex.current + 1) ||
                                (index === 2 && pageIndex.current - 1 !== 2)
                            ) {
                                type = 'end-ellipsis';
                            }
                        }
                    }

                    if (page === 1 && !selected && type === 'page') {
                        children = (
                            <>
                                <BSCButton type="button" {...item} className={classes.paginationBtn}>
                                    1
                                </BSCButton>
                            </>
                        );
                    } else if (page === count && !selected && type === 'page' && count >= 8) {
                        children = (
                            <>
                                {defaultPage < count - 2 && (
                                    <>
                                        <BSCTypography label={numberWithCommas(count)} className={classes.lastPage} />
                                    </>
                                )}
                                {defaultPage > count - 3 && (
                                    <BSCButton type="button" {...item} className={classes.paginationBtn}>
                                        {numberWithCommas(count)}
                                    </BSCButton>
                                )}
                            </>
                        );
                    } else if (type === 'start-ellipsis') {
                        if (isMobile) return null;
                        children = <BSCTypography label="..." className={classes.ellipsis} bold />;
                    } else if (type === 'end-ellipsis') {
                        if (isMobile) return null;
                        children = <BSCTypography label="..." className={classes.ellipsis} bold />;
                    } else if (type === 'page') {
                        children = (
                            <BSCButton
                                type="button"
                                {...item}
                                style={{background: selected ? '#fff' : ''}}
                                //  className={isHidable(page) ? classes.hide : classes.paginationBtn}
                                className={classes.paginationBtn}
                            >
                                {numberWithCommas(page)}
                            </BSCButton>
                        );
                    } else if (type === 'previous') {
                        children = (
                            <BSCTypography
                                label={<KeyboardArrowLeftIcon />}
                                {...item}
                                className={classNames({
                                    [classes.arrowBtn]: defaultPage !== 1 && defaultPage !== count - 1,
                                    [classes.arrowBtnNoHover]: defaultPage === 1 || defaultPage === count - 1,
                                })}
                            />
                        );
                    } else if (type === 'next') {
                        children = <BSCTypography label={<KeyboardArrowRightIcon />} {...item} className={classes.arrowBtn} />;
                    } else {
                        children = (
                            <BSCButton type="button" {...item}>
                                {type}
                            </BSCButton>
                        );
                    }

                    return (
                        <li key={index} className={classes.li}>
                            {children}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}

export default BSCPagination;
