import React, {useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../features/common/skeletons/BSCTableRowSkeleton';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import OfferHistoryNormalizer from '../../../../client/common/offer/history/offerHistoryNormalizer';
import BSCHeaderBlock from '../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import classNames from 'classnames';
import OrderCard from '../../../marketplace/pages/dashboard/components/orderCard';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import OfferResponseModal from './offerResponseModal';
import {OfferApi as CommonOfferApi, OfferHistory} from '../../../../client/openapitools/common/api';
import useDateTimeNormalizer from '../../../../client/common/normalizer/dateTimeNormalizer';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {OfferApi as SellerOfferApi} from '../../../../client/openapitools/seller/api';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'none',
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    th: {
        color: '#41474C',
        textAlign: 'center',
        padding: theme.spacing(1),
        background: '#DAE0E6',
        borderBottom: 'none',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '4px 4px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    itemWrapper: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        border: '1.5px solid #dae0e5',
        textAlign: 'center',
        background: '#FFFFFF',
        //flexWrap: 'inherit',

        '& button': {
            width: '75%',
            marginTop: '12px',
        },
        display: 'grid',
        alignItems: 'center',
    },
    td: {
        padding: theme.spacing(2),
    },
    status: {
        marginBottom: theme.spacing(1),
        textTransform: 'capitalize',
    },
    cardWrapper: {
        background: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5),
            boxShadow: '0px 0px 4px #B8C2CC',
        },
    },
    offersHeader: {
        background: '#FFFFFF',
    },
    verticalWrapper: {
        alignContent: 'center',
        height: '100%',
        margin: 0,
    },
    flexButton: {
        flex: 1,
    },
}));

const Offers = () => {
    const NEW_OFFER_STATUS = 'new';
    const YOUR_OFFERS_ARE_EMPTY = 'Your Offers are Empty';
    const classes = useStyles();
    const [offerId, setOfferId] = useState<string | undefined>(undefined);
    const [productName, setProductName] = useState(null);
    const [offerPrice, setOfferPrice] = useState(0);
    const [sellerPrice, setSellerPrice] = useState(0);
    const [sellerImgFront, setSellerImgFront] = useState(null);
    const [sellerImgBack, setSellerImgBack] = useState(null);
    const [shippingCost, setShippingCost] = useState(0);
    const [modalOpen, toggleModalOpen] = useState(false);
    const normalizer = useDateTimeNormalizer();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let commonOfferApi = new CommonOfferApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const [offerHistory, setOfferHistory] = useState(null);
    const [loadingOfferHistory, setLoadingOfferHistory] = useState(true);

    const [mutationLoading, setMutationLoading] = useState(false);
    const offers = OfferHistoryNormalizer.getOffers(offerHistory?.data);

    const handleOfferRequest = (offerId, productName, price, offerPrice, sellerImgFront, sellerImgBack, offerShippingCost) => {
        setOfferId(offerId);
        setProductName(productName);
        setOfferPrice(offerPrice);
        setSellerPrice(price);
        setSellerImgFront(sellerImgFront);
        setSellerImgBack(sellerImgBack);
        setShippingCost(offerShippingCost);
        toggleModalOpen(true);
    };

    const handleModalResponse = (modalResponse: boolean) => {
        toggleModalOpen(false);
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        setMutationLoading(true);
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const sellerOfferApi = new SellerOfferApi(apiConfig);
                            const response = await sellerOfferApi.updateOffer(
                                {accept: modalResponse, offerId: offerId},
                                undefined,
                                offerId
                            );
                            if (response.status === 200 || response.status === 204) {
                                addToast({
                                    severity: 'success',
                                    message: 'Offer Response processed.',
                                    contextKey: 'Offer Response',
                                });
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }
                            setMutationLoading(false);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to update Offer.',
                                contextKey: 'Offers',
                            });
                            setMutationLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const handleClose = () => {
        toggleModalOpen(false);
    };

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            commonOfferApi = new CommonOfferApi(apiConfig);
                            const offerResponse = await commonOfferApi.getSellerOfferHistory();
                            const rejectionFreeResponse = offerResponse.data.history.filter(
                                offer => offer.offerStatus !== 'Accepted' && offer.offerStatus !== 'Rejected'
                            );
                            offerResponse.data.history = rejectionFreeResponse;
                            setOfferHistory(offerResponse);
                            setLoadingOfferHistory(false);
                        } catch (error) {
                            console.log('>>>', error);
                            addToast({
                                severity: 'error',
                                message: 'Error fetching seller offers.',
                                contextKey: 'Offers',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <div className={classes.root}>
            <BSCHeaderBlock align="center" label="Your Offers" size18 />
            <div className={classes.th}>
                <Grid container>
                    <Grid item xs={4} justifyContent="center">
                        <BSCTypography label="cards" capitalize />
                    </Grid>
                    <Grid item xs={4} justifyContent="center">
                        <BSCTypography label="offer details" capitalize />
                    </Grid>
                    <Grid item xs={4} justifyContent="center">
                        <BSCTypography label="Status" capitalize />
                    </Grid>
                </Grid>
            </div>

            {!offers && loadingOfferHistory && (
                <Table>
                    <BSCTableRowSkeleton amountOfRows={2} amountOfCells={3} />
                </Table>
            )}
            {offers && offers?.length === 0 && (
                <div style={{padding: 32, textAlign: 'center'}}>
                    <BSCTypography style={{fontSize: 16}} label={YOUR_OFFERS_ARE_EMPTY} />
                </div>
            )}
            {offers &&
                offers?.length > 0 &&
                offers?.map((offer: OfferHistory) => {
                    if (mutationLoading && offer.offerId === offerId) {
                        return (
                            <Table key={offer.offerId}>
                                <BSCTableRowSkeleton amountOfRows={1} amountOfCells={3} />{' '}
                            </Table>
                        );
                    }

                    return (
                        <div key={offer.offerId} className={classes.cardWrapper}>
                            <Grid container>
                                <Grid item md={4} xs={12}>
                                    <div className={classes.itemWrapper}>
                                        <OrderCard card={offer.offerItem} flag hasOffer={true} />
                                    </div>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <div className={classNames(classes.itemWrapper, classes.td)}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Buyer Name: " size14 />
                                            </Grid>
                                            <Grid item>
                                                <BSCTypography variant="body1" label={offer?.buyerName} size14 />
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Offer Date: " size14 />
                                            </Grid>
                                            <Grid item>
                                                <BSCTypography variant="body1" label={normalizer.getDisplayDateTime(offer.date)} size14 />
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Offer (each): " size14 />
                                            </Grid>
                                            <Grid item>
                                                <BSCCurrency variant="body1" amount={offer.offerPrice} size14 />
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Quantity: " size14 />
                                            </Grid>
                                            <Grid item>
                                                <BSCTypography variant="body1" label={offer.offerQuantity} size14 />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item md={4} xs={12} justifyContent="center">
                                    <div className={classNames(classes.itemWrapper, classes.td)}>
                                        {offer.offerStatus.toLowerCase() !== NEW_OFFER_STATUS && (
                                            <BSCTypography label={offer.offerStatus} className={classes.status} size14 />
                                        )}
                                        {offer.offerStatus.toLowerCase() === NEW_OFFER_STATUS && (
                                            <Grid container spacing={1} className={classes.verticalWrapper}>
                                                <Grid item xs={12} className={classes.flexButton}>
                                                    <BSCButton
                                                        size14
                                                        color="primary"
                                                        onClick={() =>
                                                            handleOfferRequest(
                                                                offer.offerId,
                                                                offer.offerItem.card.productName,
                                                                offer.offerItem.price,
                                                                offer.offerPrice,
                                                                offer.offerItem.sellerImgFront,
                                                                offer.offerItem.sellerImgBack,
                                                                offer.offerShippingCost
                                                            )
                                                        }
                                                    >
                                                        <BSCTypography label={'Review Offer'} size14></BSCTypography>
                                                    </BSCButton>
                                                    <BSCTypography
                                                        size14
                                                        variant="subtitle2"
                                                        label={`Time left: ${offer.expiration}`}
                                                        className={classes.status}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            <OfferResponseModal
                open={modalOpen}
                productName={productName}
                offerPrice={offerPrice}
                sellerPrice={sellerPrice}
                sellerImgFront={sellerImgFront}
                sellerImgBack={sellerImgBack}
                shippingCost={shippingCost}
                onClose={handleClose}
                onModalResponse={handleModalResponse}
            />
        </div>
    );
};

export default Offers;
