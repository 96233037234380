import React from 'react';
// import {useQuery} from '../packages/core/src/hooks/useQuery';
// import FooterClient from '../client/s3/footer/footerClient';
import BSCContainer from '../features/common/bscContainer/bscContainer';
import {makeStyles} from '@material-ui/core/styles';
import Normalizer from '../client/common/normalizer/normalizer';
import useScreenSnap from '../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    wrapper: {
        width: '100%',
        height: '100%',

        overflow: 'auto',
        position: 'relative',
        overflowScrolling: 'touch', // -webkit-overflow-scrolling: touch;',
    },
}));

const BSCPrivacy = () => {
    //const {data} = useQuery(FooterClient.getPrivacyPolicy, []);
    const classes = useStyles();
    const {isMobile, isTablet} = useScreenSnap();

    const base = Normalizer.getS3ImagePath('/static/BSC+-+Privacy.pdf');
    const src = isMobile || isTablet ? `https://docs.google.com/gview?url=${base}&embedded=true` : base;

    return (
        <div>
            {/* className={classes.wrapper} */}
            <BSCContainer variant="page" className={classes.root}>
                {/* <div dangerouslySetInnerHTML={{__html: data}}></div> process.env.REACT_APP_S3_BASE_URL + 'static/BSC+-+Terms.pdf'*/}
                <iframe src={src} width={'100%'} height={'900px'} scrolling={'true'}></iframe>
            </BSCContainer>
        </div>
    );
};

export default BSCPrivacy;
