import {useCallback, forwardRef, ReactNode, ChangeEvent} from 'react';
import MuiTabs, {TabsProps as MuiTabsProps} from '@material-ui/core/Tabs';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    tabs: {
        boxShadow: 'inset 0px -4px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        padding: '4px',
        borderRadius: (props: {radius: string}) => props.radius,
        minHeight: 'auto',
        background: '#B8C2CC',
    },
    indicator: {
        background: 'none',
    },
}));

interface TabsProps {
    activeTab: number;
    onTabChange: (value: number, e: ChangeEvent) => void;
    children: ReactNode;
    TabIndicatorProps?: MuiTabsProps['TabIndicatorProps'];
    radius?: string;
    variant?: string;
}

const BSCTabs = forwardRef<HTMLDivElement, TabsProps>(
    ({activeTab, onTabChange, children, TabIndicatorProps, radius = '8px', ...otherProps}: TabsProps, ref) => {
        const classes = useStyles({radius});
        const handleTabChange = useCallback(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (e: ChangeEvent, value: undefined) => {
                onTabChange(parseInt(value), e);
            },
            [onTabChange]
        );
        return (
            <MuiTabs
                {...otherProps}
                ref={ref}
                component="div"
                TabIndicatorProps={TabIndicatorProps}
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                className={classes.tabs}
                classes={{indicator: classes.indicator}}
            >
                {children}
            </MuiTabs>
        );
    }
);

export default BSCTabs;
