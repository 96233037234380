import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {CartRequestActionEnum} from '../../../../client/openapitools/marketplace';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import useReduxCart from '../../../../features/hooks/redux/cart/useReduxCart';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import CartDetails from './cartDetails';
import MyCart from './myCart';

const useStyles = makeStyles((theme: Theme) => ({
    root: theme.containerWraps.page,
    container: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    list: {
        [theme.breakpoints.down('sm')]: {
            marginTop: -theme.spacing(3),
        },
    },
}));

function EditCart() {
    const classes = useStyles();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {cartState, deleteCart, updateCart, getCart} = useReduxCart();
    const {getCartSessionId} = useReduxUser();
    const history = useHistory();
    const itemsLoading = cartState.deleteCartLoading || cartState.updateCartLoading;
    const loadingReasons = {
        // ['Getting Updated Cart Details']: loading,
        ['Modifying Cart Details']: itemsLoading,
    };

    const handleCheckout = useCallback(() => {
        history.push('/cart/checkout');
    }, [history]);

    const lowDollarAmount = process.env.REACT_APP_MINIMUM_PURCHASE_SINGLE_SELLER;

    const handleDeleteCartItem = useCallback(
        cartId => {
            console.log('>>>', cartId, getCartSessionId());
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                deleteCart({
                                    id: cartId,
                                    cartSessionId: getCartSessionId(),
                                    apiConfig,
                                    action: CartRequestActionEnum.Delete,
                                });
                            } catch (error) {
                                console.error('Error deleting cart', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'Delete cart',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            } else {
                try {
                    deleteCart({
                        id: cartId,
                        cartSessionId: getCartSessionId(),
                        apiAuthConfig,
                        action: CartRequestActionEnum.Delete,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } catch (error) {
                    console.error('Error deleting cart', error);
                    addToast({
                        severity: 'error',
                        message: 'An error has occurred.',
                        contextKey: 'Delete cart',
                    });
                }
            }
        },
        [deleteCart]
    );
    const handleQuantitySelect = useCallback(
        (quantity, cartItem) => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                updateCart({
                                    id: cartItem.id,
                                    cartSessionId: cartItem.sessionId,
                                    productId: cartItem.productItem.productId,
                                    quantity,
                                    apiConfig,
                                    action: CartRequestActionEnum.Update,
                                });
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 3000);
                            } catch (error) {
                                console.error('Error deleting cart', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'Delete cart',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            } else {
                try {
                    updateCart({
                        id: cartItem.id,
                        cartSessionId: cartItem.sessionId,
                        productId: cartItem.productItem.productId,
                        quantity,
                        apiAuthConfig,
                        action: CartRequestActionEnum.Update,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } catch (error) {
                    console.error('Error deleting cart', error);
                    addToast({
                        severity: 'error',
                        message: 'An error has occurred.',
                        contextKey: 'Delete cart',
                    });
                }
            }
        },
        [updateCart]
    );

    useEffect(() => {
        getCart();
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} md={8} className={classes.list}>
                    <BSCLoading loading={itemsLoading} loadingReasons={loadingReasons} gutterBottom />
                    <CartDetails
                        onQuantitySelect={handleQuantitySelect}
                        onDeleteCartItem={handleDeleteCartItem}
                        loading={itemsLoading}
                        userCart={cartState}
                        lowDollarAmount={lowDollarAmount}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MyCart {...cartState} onOrderSubmit={handleCheckout} loading={itemsLoading} buttonLabel="Check Out" />
                </Grid>
            </Grid>
        </div>
    );
}

export default EditCart;
