import React from 'react';
import {makeStyles, FormControlLabel, IconButton, MenuItem, Menu, Box} from '@material-ui/core';
import BSCRadio from '../../../../../../../features/common/bscRadio/bscRadio';
import {MoreHorizOutlined} from '@material-ui/icons';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import {Grid} from '@material-ui/core';
import {PaymentMethod} from '../../../../../../../client/openapitools/marketplace';

const useStyles = makeStyles(() => ({
    fullWidth: {
        width: '100%',
    },
    mt: {
        marginTop: 10,
    },
    borderButton: {
        border: '2px solid rgba(0, 0, 0, 0.54)',
        borderRadius: 15,
    },
    defaultLabel: {
        borderRadius: '2px',
        backgroundColor: 'black',
        color: 'white',
        padding: '2px',
        fontSize: '8px',
        marginRight: '10px',
        marginLeft: '10px',
        alignSelf: 'center',
    },
    radioWithDefault: {
        '& span': {
            display: 'inline-flex',
        },
    },
    noRadioDefault: {
        marginLeft: '10px',
        '& span': {
            display: 'inline-flex',
        },
    },
}));

interface PaymentItemProps {
    payment: PaymentMethod;
    paymentSelected: PaymentMethod;
    setPaymentAction: (value) => void;
    setPaymentSelected: (value) => void;
    enableSelectCard: boolean;
    canDelete: boolean;
    showRadioButton?: boolean;
}

function PaymentItem({
    payment,
    setPaymentAction = () => {},
    paymentSelected,
    setPaymentSelected = () => {},
    enableSelectCard,
    canDelete,
    showRadioButton = false,
}: PaymentItemProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setPaymentAction({
            payment: payment,
            type: 'edit',
        });
        handleClose();
    };

    const handleDelete = () => {
        setPaymentAction({
            payment: payment,
            type: 'delete',
        });
        handleClose();
    };

    const handleSelectPayment = () => {
        if (enableSelectCard) {
            setPaymentSelected(payment);
        }
    };
    return payment ? (
        <Grid container className={classes.mt} key={payment.id}>
            <Grid item xs={8}>
                {showRadioButton && (
                    <FormControlLabel
                        value={payment.id}
                        checked={paymentSelected ? paymentSelected.id === payment.id : payment.primary}
                        onChange={handleSelectPayment}
                        className={classes.radioWithDefault}
                        control={<BSCRadio />}
                        label={
                            <>
                                <BSCTypography variant="subtitle1" label={`Card ending in ${payment.maskedCardNumber}`} />
                                {payment.primary && (
                                    <BSCTypography variant="subtitle1" label={'DEFAULT'} className={classes.defaultLabel} />
                                )}
                            </>
                        }
                    />
                )}
                {!showRadioButton && (
                    <FormControlLabel
                        value={payment.id}
                        // onChange={handleSelectPayment}
                        control={<div></div>}
                        className={classes.noRadioDefault}
                        label={
                            <>
                                <BSCTypography variant="subtitle1" label={`Card ending in ${payment.maskedCardNumber}`} />
                                {payment.primary && (
                                    <BSCTypography variant="subtitle1" label={'DEFAULT'} className={classes.defaultLabel} />
                                )}
                            </>
                        }
                    />
                )}
            </Grid>
            <Grid item xs={3}>
                <BSCTypography variant="subtitle1" label={`Exp ${payment.expiry.replace(/\/[0-9]{2}/, '/')}`} />
            </Grid>
            <Grid item xs={1}>
                <Box>
                    <IconButton
                        aria-controls={`basic-menu-${payment.id}`}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        id={`button-${payment.id}`}
                        size="small"
                    >
                        <MoreHorizOutlined className={classes.borderButton} />
                    </IconButton>
                    <Menu
                        id={`basic-menu-${payment.id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': `button-${payment.id}`,
                        }}
                    >
                        <MenuItem onClick={handleEdit}>
                            <BSCTypography variant="subtitle1" label="Edit" />
                        </MenuItem>
                        <MenuItem disabled={!canDelete} onClick={handleDelete}>
                            <BSCTypography variant="subtitle1" label="Delete" />
                        </MenuItem>
                    </Menu>
                </Box>
            </Grid>
        </Grid>
    ) : null;
}

export default PaymentItem;
