/* eslint-disable indent */
import {ModalProps} from '@material-ui/core';
import BSCModal from '../../../../../features/common/bscModal/bscModal';

interface MissingDataModalProps extends Omit<ModalProps, 'children'> {
    open: boolean;
    onClose: (open: boolean) => void;
}

const MissingDataModal = ({open, onClose}: MissingDataModalProps) => {
    return (
        <BSCModal open={open} onClose={onClose} size="lg">
            <div style={{padding: 16}}>
                <p style={{color: '#000', fontSize: 16, fontFamily: "'Poppins', sans-serif", fontWeight: 600}}>
                    If you think we are missing something, please let us know at&nbsp;
                    <a href="mailTo:support@buysportscards.com">support@buysportscards.com</a>
                </p>
                <p style={{color: '#000', fontSize: 16, fontFamily: "'Poppins', sans-serif", fontWeight: 600}}>
                    We are working to ensure that data for new releases is added on release date; if the set is new, there may be situations
                    where there are delays and we don't meet our goal.
                </p>
            </div>
        </BSCModal>
    );
};

export default MissingDataModal;
