import {FormControl, FormHelperText, FormLabel, RadioGroup, RadioGroupProps} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import {forwardRef} from 'react';
import {validatedSchema} from '../../../@types/validations/validations';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: (props: {start: boolean}) => (props.start ? 'start' : 'flex-end'),
    },
    radioGroup: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: theme.spacing(2),
    },
    formControl: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    fullWidthFormControl: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    label: {
        color: `${theme.palette.secondary.main} !important`,
        marginBottom: theme.spacing(1),
    },
    columnView: {
        flexDirection: 'column',
        alignItems: 'start',
        ['& > div']: {
            flexDirection: 'column',
            alignItems: 'start',
        },
        ['& > div > div']: {
            flexDirection: 'column',
            alignItems: 'start',
            marginLeft: 2,
        },
    },
    required: {
        color: `${theme.palette.secondary.main} !important`,
        marginBottom: theme.spacing(1),
        '&:after': {
            fontSize: 14,
            content: '"*"',
            color: '#f00',
            display: 'inline-block',
        },
    },
}));

interface BSCRadioGroupProps extends RadioGroupProps {
    formStateValidations?: validatedSchema;
    inputKey?: string;
    name?: string;
    placeholder?: null | string;
    value?: null | string | number;
    label?: string;
    column?: boolean;
    start?: boolean;
    semicolon?: boolean;
    noLabel?: boolean;
    isEmphasized?: boolean;
    hasFullWidth?: boolean;
}

const BSCRadioGroup = forwardRef<HTMLDivElement, BSCRadioGroupProps>(
    (
        {
            column = false,
            inputKey,
            name,
            formStateValidations,
            value = '',
            children,
            className,
            label,
            start = false,
            semicolon = false,
            noLabel = false,
            isEmphasized = false,
            hasFullWidth = false,
            ...otherProps
        }: BSCRadioGroupProps,
        ref
    ) => {
        const classes = useStyles({start});
        return (
            <div ref={ref} className={classNames(classes.root, {[classes.columnView]: column})}>
                <FormControl className={hasFullWidth ? classes.fullWidthFormControl : classes.formControl} component="div">
                    {!noLabel && (
                        <FormLabel
                            style={isEmphasized ? {textDecoration: 'underline', fontWeight: 600, fontSize: 16} : {fontWeight: 400}}
                            className={isEmphasized ? classes.required : classes.label}
                            focused={false}
                            component="div"
                        >
                            {formStateValidations?.[inputKey]?.label || label} {semicolon ? ':' : ''}
                        </FormLabel>
                    )}
                    <RadioGroup
                        {...otherProps}
                        className={classNames(classes.radioGroup, className)}
                        name={inputKey || name}
                        value={formStateValidations?.[inputKey]?.value || value}
                    >
                        {children}
                    </RadioGroup>
                </FormControl>
                {formStateValidations?.[inputKey]?.errors && (
                    <FormHelperText error>{formStateValidations?.[inputKey]?.errors}</FormHelperText>
                )}
            </div>
        );
    }
);

export default BSCRadioGroup;
