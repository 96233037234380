import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
}));
const FileNameList = ({nameList, removeFile}) => {
    const classes = useStyles();

    return nameList.map(name => (
        <div className={classes.root} key={name}>
            <IconButton onClick={() => removeFile(name)}>
                <HighlightOffIcon />
            </IconButton>
            <BSCTypography label={name} />
        </div>
    ));
};

export default FileNameList;
