import {createAction, createSlice} from '@reduxjs/toolkit';

export interface Portal {
    context: 'sellers' | 'buyers';
}

interface PortalState {
    context: 'sellers' | 'buyers';
}
/**
 * @deprecated use the API Config and the new generated clients.  Left for backwards compatibility with the legay axios client
 */
export const changePortal = createAction<Portal>('portal/changePortal');

const initialState = {context: 'buyers'} as PortalState;

const portalSlice = createSlice({
    name: 'portal',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(changePortal, (state, action) => {
            state.context = action.payload.context;
        });
    },
});

export default portalSlice.reducer;
