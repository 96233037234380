/* tslint:disable */
/* eslint-disable */
/**
 * Seller API
 * All Seller APIs for UI to access
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipCode'?: string;
}
/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface BulkUploadDeactivateRequest
 */
export interface BulkUploadDeactivateRequest {
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof BulkUploadDeactivateRequest
     */
    'filters'?: { [key: string]: Set<string>; };
    /**
     * 
     * @type {string}
     * @memberof BulkUploadDeactivateRequest
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadDeactivateRequest
     */
    'condition'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUploadDeactivateRequest
     */
    'preview'?: boolean;
}
/**
 * 
 * @export
 * @interface BulkUploadResults
 */
export interface BulkUploadResults {
    /**
     * 
     * @type {string}
     * @memberof BulkUploadResults
     */
    'resultId'?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof BulkUploadResults
     */
    'results'?: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof BulkUploadResults
     */
    'totalResults'?: number;
}
/**
 * 
 * @export
 * @interface BulkUploadResultsRequest
 */
export interface BulkUploadResultsRequest {
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof BulkUploadResultsRequest
     */
    'filters'?: { [key: string]: Set<string>; };
    /**
     * 
     * @type {string}
     * @memberof BulkUploadResultsRequest
     */
    'sort'?: string;
    /**
     * The condition is the value set by seller.
     * @type {string}
     * @memberof BulkUploadResultsRequest
     */
    'condition': string;
    /**
     * The product type or listing type of the product.
     * @type {string}
     * @memberof BulkUploadResultsRequest
     */
    'productType': BulkUploadResultsRequestProductTypeEnum;
    /**
     * Flag to retrieve current listings for th given filters.
     * @type {boolean}
     * @memberof BulkUploadResultsRequest
     */
    'currentListings'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadResultsRequest
     */
    'sellerId'?: string;
}

export const BulkUploadResultsRequestProductTypeEnum = {
    Raw: 'raw',
    Graded: 'graded'
} as const;

export type BulkUploadResultsRequestProductTypeEnum = typeof BulkUploadResultsRequestProductTypeEnum[keyof typeof BulkUploadResultsRequestProductTypeEnum];

/**
 * 
 * @export
 * @interface BulkUploadSaveRequest
 */
export interface BulkUploadSaveRequest {
    /**
     * 
     * @type {Array<Product>}
     * @memberof BulkUploadSaveRequest
     */
    'listings'?: Array<Product>;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadSaveRequest
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadSaveRequest
     */
    'vendorId'?: string;
}
/**
 * 
 * @export
 * @interface BulkUploadSaveResponse
 */
export interface BulkUploadSaveResponse {
    /**
     * 
     * @type {string}
     * @memberof BulkUploadSaveResponse
     */
    'result'?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof BulkUploadSaveResponse
     */
    'listings'?: Array<Product>;
}
/**
 * 
 * @export
 * @interface BulkUploadSwapRequest
 */
export interface BulkUploadSwapRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BulkUploadSwapRequest
     */
    'swapCardIds'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof BulkUploadSwapRequest
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadSwapRequest
     */
    'vendorId'?: string;
}
/**
 * A User\'s buyer profile associated with marketplace
 * @export
 * @interface BuyerProfile
 */
export interface BuyerProfile {
    /**
     * 
     * @type {Array<Address>}
     * @memberof BuyerProfile
     */
    'shippingAddresses'?: Array<Address>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof BuyerProfile
     */
    'billingAddresses'?: Array<Address>;
    /**
     * 
     * @type {Array<object>}
     * @memberof BuyerProfile
     */
    'paymentMethods'?: Array<object>;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * The Set Name associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'setName'?: string;
    /**
     * The name of the Player or Players on the Card.
     * @type {string}
     * @memberof Card
     */
    'players'?: string;
    /**
     * The Card Number associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'cardNo'?: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    'cardNoOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    'cardNoSequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'cardNoSort'?: string;
    /**
     * The variant of the card.
     * @type {string}
     * @memberof Card
     */
    'variant': CardVariantEnum;
    /**
     * The Variant name associated with this Set variant for Card.
     * @type {string}
     * @memberof Card
     */
    'variantName'?: string;
    /**
     * The name of Sport associated with this Cards Player when it was printed.
     * @type {string}
     * @memberof Card
     */
    'sport'?: CardSportEnum;
    /**
     * The Year or Season when this Set or Card was release.
     * @type {string}
     * @memberof Card
     */
    'year'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'rawLeastPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'gradedLeastPrice'?: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    'lastSoldPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttributeAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttributeDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'stockImgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'stockImgBack'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'imgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'imgBack'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'totalListings'?: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    'lowestPrice'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Card
     */
    'listings'?: Array<Product>;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    '_id'?: string;
}

export const CardVariantEnum = {
    Base: 'Base',
    Insert: 'Insert',
    MailIn: 'Mail-In',
    Parallel: 'Parallel',
    Promo: 'Promo',
    Proof: 'Proof'
} as const;

export type CardVariantEnum = typeof CardVariantEnum[keyof typeof CardVariantEnum];
export const CardSportEnum = {
    Baseball: 'Baseball',
    Basketball: 'Basketball',
    Boxing: 'Boxing',
    Football: 'Football',
    Golf: 'Golf',
    Hockey: 'Hockey',
    MiscSports: 'Misc. Sports',
    Mma: 'MMA',
    MultiSport: 'Multi-Sport',
    NonSport: 'Non-Sport',
    Racing: 'Racing',
    Soccer: 'Soccer',
    Tennis: 'Tennis',
    Wrestling: 'Wrestling'
} as const;

export type CardSportEnum = typeof CardSportEnum[keyof typeof CardSportEnum];

/**
 * 
 * @export
 * @interface CarrierPackage
 */
export interface CarrierPackage {
    /**
     * 
     * @type {Weight}
     * @memberof CarrierPackage
     */
    'weight'?: Weight;
    /**
     * 
     * @type {Dimensions}
     * @memberof CarrierPackage
     */
    'dimensions'?: Dimensions;
    /**
     * 
     * @type {number}
     * @memberof CarrierPackage
     */
    'sequence'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarrierPackage
     */
    'package_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarrierPackage
     */
    'package_code'?: string;
    /**
     * 
     * @type {InsuredValue}
     * @memberof CarrierPackage
     */
    'insured_value'?: InsuredValue;
    /**
     * 
     * @type {string}
     * @memberof CarrierPackage
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {LabelMessages}
     * @memberof CarrierPackage
     */
    'label_messages'?: LabelMessages;
    /**
     * 
     * @type {string}
     * @memberof CarrierPackage
     */
    'external_package_id'?: string;
}
/**
 * 
 * @export
 * @interface CarrierPackageResponse
 */
export interface CarrierPackageResponse {
    /**
     * 
     * @type {Array<CarrierPackage>}
     * @memberof CarrierPackageResponse
     */
    'packages'?: Array<CarrierPackage>;
}
/**
 * 
 * @export
 * @interface ConfirmationAmount
 */
export interface ConfirmationAmount {
    /**
     * 
     * @type {string}
     * @memberof ConfirmationAmount
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfirmationAmount
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface ConfirmationCost
 */
export interface ConfirmationCost {
    /**
     * 
     * @type {string}
     * @memberof ConfirmationCost
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfirmationCost
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface Dimensions
 */
export interface Dimensions {
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof Dimensions
     */
    'unit'?: string;
}
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'rating'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'orderNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'buyerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface FeedbackResponse
 */
export interface FeedbackResponse {
    /**
     * 
     * @type {Rating}
     * @memberof FeedbackResponse
     */
    'rating'?: Rating;
    /**
     * 
     * @type {Array<Ranking>}
     * @memberof FeedbackResponse
     */
    'rankings'?: Array<Ranking>;
    /**
     * 
     * @type {Array<Feedback>}
     * @memberof FeedbackResponse
     */
    'feedback'?: Array<Feedback>;
}
/**
 * 
 * @export
 * @interface InsuranceAmount
 */
export interface InsuranceAmount {
    /**
     * 
     * @type {string}
     * @memberof InsuranceAmount
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceAmount
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface InsuranceClaim
 */
export interface InsuranceClaim {
    /**
     * 
     * @type {string}
     * @memberof InsuranceClaim
     */
    'href'?: string;
}
/**
 * 
 * @export
 * @interface InsuredValue
 */
export interface InsuredValue {
    /**
     * 
     * @type {string}
     * @memberof InsuredValue
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuredValue
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface LabelDownload
 */
export interface LabelDownload {
    /**
     * 
     * @type {string}
     * @memberof LabelDownload
     */
    'pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelDownload
     */
    'png'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelDownload
     */
    'zpl'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelDownload
     */
    'href'?: string;
}
/**
 * 
 * @export
 * @interface LabelMessages
 */
export interface LabelMessages {
    /**
     * 
     * @type {string}
     * @memberof LabelMessages
     */
    'reference1'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelMessages
     */
    'reference2'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelMessages
     */
    'reference3'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'error_source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'error_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'carrier_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'carrier_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'carrier_name'?: string;
}
/**
 * 
 * @export
 * @interface OfferRequest
 */
export interface OfferRequest {
    /**
     * 
     * @type {boolean}
     * @memberof OfferRequest
     */
    'accept'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'offerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'declineReason'?: string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'confirmationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'subTotal'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'orderSubtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'shippingRefundable'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'subtotalRefundable'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'refundTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'shippingRefundTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'salesTax'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'sellerFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'sellerRate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderCurrency'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'total'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'buyerDiscountAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'sellerNetTotal'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shippingCharge'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'numberOfPackages'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'stripeTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shippingAddressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'billingAddressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'stripePaymentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'stripePaymentFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'sellerId'?: string;
    /**
     * 
     * @type {OrderPayment}
     * @memberof Order
     */
    'payment'?: OrderPayment;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    'shippingAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    'billingAddress'?: Address;
    /**
     * 
     * @type {User}
     * @memberof Order
     */
    'seller'?: User;
    /**
     * 
     * @type {User}
     * @memberof Order
     */
    'buyer'?: User;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Order
     */
    'orderItems'?: Array<Product>;
    /**
     * 
     * @type {Array<Shipment>}
     * @memberof Order
     */
    'shipments'?: Array<Shipment>;
    /**
     * 
     * @type {Array<OrderRefund>}
     * @memberof Order
     */
    'refunds'?: Array<OrderRefund>;
    /**
     * 
     * @type {OrderFeedback}
     * @memberof Order
     */
    'feedback'?: OrderFeedback;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface OrderFeedback
 */
export interface OrderFeedback {
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'rating'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'comment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderFeedback
     */
    'itemAsDescribed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface OrderHistoryRequest
 */
export interface OrderHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryRequest
     */
    'orderNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryRequest
     */
    'toDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryRequest
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderHistoryRequest
     */
    'status'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OrderHistoryResponse
 */
export interface OrderHistoryResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {Array<Order>}
     * @memberof OrderHistoryResponse
     */
    'results'?: Array<Order>;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryResponse
     */
    'totalUnshipped'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryResponse
     */
    'currentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface OrderPayment
 */
export interface OrderPayment {
    /**
     * 
     * @type {PaymentMethod}
     * @memberof OrderPayment
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof OrderPayment
     */
    'amountCharged'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPayment
     */
    'currency'?: string;
}
/**
 * 
 * @export
 * @interface OrderRefund
 */
export interface OrderRefund {
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'subtotalRefund'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'subtotalRefundTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'subtotalRefundTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'shippingRefund'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'shippingRefundTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'shippingRefundTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'transactionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'refundType'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'refundFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'refundReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRefund
     */
    'buyerReturn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'avaTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefund
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefund
     */
    'refundTotal'?: number;
}
/**
 * 
 * @export
 * @interface OtherAmount
 */
export interface OtherAmount {
    /**
     * 
     * @type {string}
     * @memberof OtherAmount
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof OtherAmount
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface OtherCost
 */
export interface OtherCost {
    /**
     * 
     * @type {string}
     * @memberof OtherCost
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof OtherCost
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface Package
 */
export interface Package {
    /**
     * 
     * @type {Weight}
     * @memberof Package
     */
    'weight'?: Weight;
    /**
     * 
     * @type {Dimensions}
     * @memberof Package
     */
    'dimensions'?: Dimensions;
    /**
     * 
     * @type {InsuredValue}
     * @memberof Package
     */
    'insured_value'?: InsuredValue;
}
/**
 * 
 * @export
 * @interface PackageOptions
 */
export interface PackageOptions {
    /**
     * 
     * @type {string}
     * @memberof PackageOptions
     */
    'name'?: string;
    /**
     * 
     * @type {Weight}
     * @memberof PackageOptions
     */
    'weight'?: Weight;
    /**
     * 
     * @type {InsuredValue}
     * @memberof PackageOptions
     */
    'insuredValue'?: InsuredValue;
    /**
     * 
     * @type {string}
     * @memberof PackageOptions
     */
    'insuranceProvider'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PackageOptions
     */
    'insurance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackageOptions
     */
    'confirmation'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageOptions
     */
    'package_code'?: string;
}
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'expiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'cardType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'maskedCardNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    'primary'?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentOrder
 */
export interface PaymentOrder {
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'orderNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'buyer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'totalSale'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentOrder
     */
    'paymentProcessingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentOrder
     */
    'bscShippingCharge'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'bscSellerFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'totalFees'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'refundedOrders'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'refundedFees'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOrder
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface PaymentSetting
 */
export interface PaymentSetting {
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'fundingSourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'fundingSourceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'verifyMicroDepositsUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSetting
     */
    'fundingStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'fundingSourceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'customerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'iavStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'bankAccountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'bankAccountType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {VerifyAmount}
     * @memberof PaymentSetting
     */
    'verifyAmount'?: VerifyAmount;
}
/**
 * 
 * @export
 * @interface PriceListing
 */
export interface PriceListing {
    /**
     * 
     * @type {Product}
     * @memberof PriceListing
     */
    'product'?: Product;
}
/**
 * 
 * @export
 * @interface PricingData
 */
export interface PricingData {
    /**
     * 
     * @type {string}
     * @memberof PricingData
     */
    'lowestPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof PricingData
     */
    'difference'?: string;
    /**
     * 
     * @type {string}
     * @memberof PricingData
     */
    'differenceInPercent'?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * The Product Id is unique identifier of a listing added by seller.
     * @type {string}
     * @memberof Product
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerImgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerImgBack'?: string;
    /**
     * The product type or listing type of the product.
     * @type {string}
     * @memberof Product
     */
    'productType': ProductProductTypeEnum;
    /**
     * The Card Id associated with this product listing.
     * @type {string}
     * @memberof Product
     */
    'cardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'buyerPrice'?: string;
    /**
     * The condition is the value set by seller.
     * @type {string}
     * @memberof Product
     */
    'condition'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'availableQuantity'?: number;
    /**
     * The number of products in the order placed by the buyer from website.
     * @type {number}
     * @memberof Product
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'active'?: boolean;
    /**
     * The grading companies that BSC allows to sell on the website.
     * @type {string}
     * @memberof Product
     */
    'gradingCompany'?: ProductGradingCompanyEnum;
    /**
     * The various grades given by the grading companies.
     * @type {string}
     * @memberof Product
     */
    'grade'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'cartQty'?: number;
    /**
     * 
     * @type {Card}
     * @memberof Product
     */
    'card'?: Card;
    /**
     * 
     * @type {User}
     * @memberof Product
     */
    'seller'?: User;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lastSoldPrice'?: number;
    /**
     * The BSC Low for this card given the same condition and type
     * @type {number}
     * @memberof Product
     */
    'leastPriceForType'?: number;
    /**
     * 
     * @type {PricingData}
     * @memberof Product
     */
    'pricingData'?: PricingData;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'year'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'sellerInventoryStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updatedTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'matchVal'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'vendorProductId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'cardYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'imageChanged'?: boolean;
}

export const ProductProductTypeEnum = {
    Raw: 'raw',
    Graded: 'graded'
} as const;

export type ProductProductTypeEnum = typeof ProductProductTypeEnum[keyof typeof ProductProductTypeEnum];
export const ProductGradingCompanyEnum = {
    Bvg: 'bvg',
    Psa: 'psa',
    Sgc: 'sgc',
    Hga: 'hga',
    Bgs: 'bgs',
    Csg: 'csg'
} as const;

export type ProductGradingCompanyEnum = typeof ProductGradingCompanyEnum[keyof typeof ProductGradingCompanyEnum];

/**
 * 
 * @export
 * @interface ProductConfig
 */
export interface ProductConfig {
    /**
     * 
     * @type {string}
     * @memberof ProductConfig
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfig
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfig
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfig
     */
    'sellerLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfig
     */
    'maxSellingPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConfig
     */
    'requireImagesFor'?: string;
    /**
     * 
     * @type {Array<ProductGroup>}
     * @memberof ProductConfig
     */
    'prices'?: Array<ProductGroup>;
}
/**
 * 
 * @export
 * @interface ProductGroup
 */
export interface ProductGroup {
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'productType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'gradingCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'grade'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'matchVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    'leastPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    'lastSoldPrice'?: number;
}
/**
 * 
 * @export
 * @interface Ranking
 */
export interface Ranking {
    /**
     * 
     * @type {string}
     * @memberof Ranking
     */
    'duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ranking
     */
    'durationKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ranking
     */
    'positive'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ranking
     */
    'neutral'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ranking
     */
    'negative'?: string;
    /**
     * 
     * @type {number}
     * @memberof Ranking
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'trackable'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'rate_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'rate_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'carrier_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'package_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'delivery_days'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'guaranteed_service'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'estimated_delivery_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'carrier_delivery_days'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'ship_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'negotiated_rate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'service_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'service_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'carrier_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'carrier_nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'carrier_friendly_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'validation_status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Rate
     */
    'warning_messages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Rate
     */
    'error_messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    'total_shipment_amount'?: number;
    /**
     * 
     * @type {InsuranceAmount}
     * @memberof Rate
     */
    'insurance_amount'?: InsuranceAmount;
    /**
     * 
     * @type {ShippingAmount}
     * @memberof Rate
     */
    'shipping_amount'?: ShippingAmount;
    /**
     * 
     * @type {ConfirmationAmount}
     * @memberof Rate
     */
    'confirmation_amount'?: ConfirmationAmount;
    /**
     * 
     * @type {OtherAmount}
     * @memberof Rate
     */
    'other_amount'?: OtherAmount;
}
/**
 * 
 * @export
 * @interface RateResponse
 */
export interface RateResponse {
    /**
     * 
     * @type {Array<Rate>}
     * @memberof RateResponse
     */
    'rates'?: Array<Rate>;
    /**
     * 
     * @type {string}
     * @memberof RateResponse
     */
    'rate_request_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RateResponse
     */
    'shipment_id'?: string;
    /**
     * 
     * @type {Array<Error>}
     * @memberof RateResponse
     */
    'errors'?: Array<Error>;
}
/**
 * 
 * @export
 * @interface Rating
 */
export interface Rating {
    /**
     * 
     * @type {number}
     * @memberof Rating
     */
    'average'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rating
     */
    'reviews'?: number;
}
/**
 * 
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportRequest
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface SellerDashboard
 */
export interface SellerDashboard {
    /**
     * 
     * @type {SellerProfile}
     * @memberof SellerDashboard
     */
    'profile'?: SellerProfile;
    /**
     * 
     * @type {SellerStatistic}
     * @memberof SellerDashboard
     */
    'statistics'?: SellerStatistic;
}
/**
 * 
 * @export
 * @interface SellerPayment
 */
export interface SellerPayment {
    /**
     * 
     * @type {SellerPaymentDetails}
     * @memberof SellerPayment
     */
    'totalSellerPaymentSummary'?: SellerPaymentDetails;
    /**
     * 
     * @type {Array<SellerPaymentDetails>}
     * @memberof SellerPayment
     */
    'results'?: Array<SellerPaymentDetails>;
    /**
     * 
     * @type {number}
     * @memberof SellerPayment
     */
    'totalResults'?: number;
}
/**
 * 
 * @export
 * @interface SellerPaymentDetails
 */
export interface SellerPaymentDetails {
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'estimatedTransferArrivalDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'numberOfOrders'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'totalSales'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'refundedOrders'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'refundedFees'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'totalRefundAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'payment'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'bscSellerFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'paymentInitiatedOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'adjustment'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'transactionRefNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'dwollaFundingSrcUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'paymentProcessingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'bscShippingCharge'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerPaymentDetails
     */
    'achTransferCharge'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'sellerPayoutScheduleId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerPaymentDetails
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'createdModule'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'createdTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'updatedModule'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'updatedTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerPaymentDetails
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface SellerProductRequest
 */
export interface SellerProductRequest {
    /**
     * The options for sellers product request.
     * @type {string}
     * @memberof SellerProductRequest
     */
    'action': SellerProductRequestActionEnum;
    /**
     * 
     * @type {Product}
     * @memberof SellerProductRequest
     */
    'listing'?: Product;
}

export const SellerProductRequestActionEnum = {
    Add: 'add',
    Update: 'update',
    Delete: 'delete'
} as const;

export type SellerProductRequestActionEnum = typeof SellerProductRequestActionEnum[keyof typeof SellerProductRequestActionEnum];

/**
 * If User is a seller as well you will get the additional details for his profile
 * @export
 * @interface SellerProfile
 */
export interface SellerProfile {
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerStoreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerRating'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerSales'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'totalQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'storePhoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerProfile
     */
    'localCardShop'?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof SellerProfile
     */
    'storeAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'addressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'applicationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerMarketUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerIconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerEmailId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'referralChannel'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'salesNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'shipmentCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'defaultCurrency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerProfile
     */
    'inventoryStatus'?: boolean;
    /**
     * 
     * @type {SellerSetting}
     * @memberof SellerProfile
     */
    'listingSettings'?: SellerSetting;
    /**
     * 
     * @type {PaymentSetting}
     * @memberof SellerProfile
     */
    'paymentSettings'?: PaymentSetting;
    /**
     * 
     * @type {Array<ShippingSettings>}
     * @memberof SellerProfile
     */
    'shippingSettings'?: Array<ShippingSettings>;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'totalCards'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'totalListings'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'totalValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'credits'?: number;
}
/**
 * 
 * @export
 * @interface SellerSetting
 */
export interface SellerSetting {
    /**
     * 
     * @type {string}
     * @memberof SellerSetting
     */
    'shippingFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerSetting
     */
    'currency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerSetting
     */
    'offers'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SellerSetting
     */
    'shippingTierId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerSetting
     */
    'rate'?: number;
}
/**
 * 
 * @export
 * @interface SellerStatistic
 */
export interface SellerStatistic {
    /**
     * 
     * @type {number}
     * @memberof SellerStatistic
     */
    'todaysSales'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerStatistic
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerStatistic
     */
    'openOrders'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerStatistic
     */
    'buyerMessages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerStatistic
     */
    'newOffers'?: number;
}
/**
 * 
 * @export
 * @interface SellerSummary
 */
export interface SellerSummary {
    /**
     * 
     * @type {number}
     * @memberof SellerSummary
     */
    'totalSales'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerSummary
     */
    'openOrders'?: number;
}
/**
 * 
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'shippedDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    'bscShipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'shippingCharge'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Shipment
     */
    'shipmentShippingAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Shipment
     */
    'shipmentStoreAddress'?: Address;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Shipment
     */
    'shipmentItems'?: Array<Product>;
    /**
     * 
     * @type {ShippingLabel}
     * @memberof Shipment
     */
    'shippingLabel'?: ShippingLabel;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface ShipmentCost
 */
export interface ShipmentCost {
    /**
     * 
     * @type {string}
     * @memberof ShipmentCost
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentCost
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface ShippingAmount
 */
export interface ShippingAmount {
    /**
     * 
     * @type {string}
     * @memberof ShippingAmount
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingAmount
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface ShippingLabel
 */
export interface ShippingLabel {
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'status'?: string;
    /**
     * 
     * @type {PackageOptions}
     * @memberof ShippingLabel
     */
    'packageOptions'?: PackageOptions;
    /**
     * 
     * @type {Array<CarrierPackage>}
     * @memberof ShippingLabel
     */
    'packages'?: Array<CarrierPackage>;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'label_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'shipment_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'ship_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'created_at'?: string;
    /**
     * 
     * @type {ShipmentCost}
     * @memberof ShippingLabel
     */
    'shipment_cost'?: ShipmentCost;
    /**
     * 
     * @type {ShipmentCost}
     * @memberof ShippingLabel
     */
    'insurance_cost'?: ShipmentCost;
    /**
     * 
     * @type {ConfirmationCost}
     * @memberof ShippingLabel
     */
    'confirmation_cost'?: ConfirmationCost;
    /**
     * 
     * @type {OtherCost}
     * @memberof ShippingLabel
     */
    'other_cost'?: OtherCost;
    /**
     * 
     * @type {number}
     * @memberof ShippingLabel
     */
    'total_shipment_cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabel
     */
    'is_return_label'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'rma_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabel
     */
    'is_international'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'batch_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'carrier_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'service_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'package_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabel
     */
    'voided'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'voided_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'label_format'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'display_scheme'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'label_layout'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabel
     */
    'trackable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'label_image_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'carrier_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'tracking_status'?: string;
    /**
     * 
     * @type {LabelDownload}
     * @memberof ShippingLabel
     */
    'label_download'?: LabelDownload;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'form_download'?: string;
    /**
     * 
     * @type {InsuranceClaim}
     * @memberof ShippingLabel
     */
    'insurance_claim'?: InsuranceClaim;
    /**
     * 
     * @type {string}
     * @memberof ShippingLabel
     */
    'charge_event'?: string;
}
/**
 * 
 * @export
 * @interface ShippingLabelRequest
 */
export interface ShippingLabelRequest {
    /**
     * 
     * @type {string}
     * @memberof ShippingLabelRequest
     */
    'service_code'?: string;
    /**
     * 
     * @type {PackageOptions}
     * @memberof ShippingLabelRequest
     */
    'package'?: PackageOptions;
}
/**
 * 
 * @export
 * @interface ShippingRateResponse
 */
export interface ShippingRateResponse {
    /**
     * 
     * @type {RateResponse}
     * @memberof ShippingRateResponse
     */
    'rate_response'?: RateResponse;
    /**
     * 
     * @type {string}
     * @memberof ShippingRateResponse
     */
    'shipment_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingRateResponse
     */
    'carrier_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingRateResponse
     */
    'status'?: string;
    /**
     * 
     * @type {Array<Package>}
     * @memberof ShippingRateResponse
     */
    'packages'?: Array<Package>;
    /**
     * 
     * @type {Weight}
     * @memberof ShippingRateResponse
     */
    'total_weight'?: Weight;
}
/**
 * 
 * @export
 * @interface ShippingSettings
 */
export interface ShippingSettings {
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'shippingTierId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingSettings
     */
    'rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingSettings
     */
    'rangeStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingSettings
     */
    'rangeEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'tierCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ShippingTier
 */
export interface ShippingTier {
    /**
     * 
     * @type {number}
     * @memberof ShippingTier
     */
    'rangeStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingTier
     */
    'rangeEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingTier
     */
    'tierCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingTier
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingTier
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'customerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'customerId'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Unique identifier of the User.
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * Username as chosen by the application for the User.
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * FirstName of the User.
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * Lastname of the User.
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * Role Identifier of the User.
     * @type {string}
     * @memberof User
     */
    'roleId': string;
    /**
     * Phone number of the User.
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * Email of the User.
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Customer identifier on payment system Stripe.
     * @type {string}
     * @memberof User
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {UserNotifications}
     * @memberof User
     */
    'userNotifications'?: UserNotifications;
    /**
     * 
     * @type {BuyerProfile}
     * @memberof User
     */
    'buyerProfile'?: BuyerProfile;
    /**
     * 
     * @type {SellerProfile}
     * @memberof User
     */
    'sellerProfile'?: SellerProfile;
}
/**
 * All notification settings for the user in marketplace
 * @export
 * @interface UserNotifications
 */
export interface UserNotifications {
    /**
     * 
     * @type {string}
     * @memberof UserNotifications
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'orderConfirmation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'receivedOffers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'messages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'discountsOffersAndSpecialPromotions'?: boolean;
}
/**
 * 
 * @export
 * @interface VerifyAmount
 */
export interface VerifyAmount {
    /**
     * 
     * @type {Amount}
     * @memberof VerifyAmount
     */
    'amount1'?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof VerifyAmount
     */
    'amount2'?: Amount;
}
/**
 * 
 * @export
 * @interface VoidLabelResponse
 */
export interface VoidLabelResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VoidLabelResponse
     */
    'approved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoidLabelResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Weight
 */
export interface Weight {
    /**
     * 
     * @type {number}
     * @memberof Weight
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof Weight
     */
    'unit'?: string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all shipping tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingTiers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/shipping-tier/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Card by card id
         * @param {string} cardId Card id to get the listings for
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListings: async (cardId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/card-listing/{cardId}`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Seller listings settings
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductConfig: async (cardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/card-listing/{cardId}/settings`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Seller feedback object contains array of feedback information and also ratings for different times since seller joined.
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerFeedback: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/feedback/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seller dashboard information
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerInfo: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/dashboard/information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seller price listing
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerPriceListing: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/listing/pricing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Seller summary
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerSummary: async (sellerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/summary/{sellerId}`
                .replace(`{${"sellerId"}}`, encodeURIComponent(String(sellerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Seller product update info
         * @param {SellerProductRequest} sellerProductRequest 
         * @param {string} cardId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerCardInformation: async (sellerProductRequest?: SellerProductRequest, cardId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/card-listing/{cardId}/product`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Seller Inventory Switch On/Off
         * @param {string} status 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerInventoryStatus: async (status?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/profile/inventory-status/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all shipping tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShippingTiers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingTier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShippingTiers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Card by card id
         * @param {string} cardId Card id to get the listings for
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardListings(cardId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardListings(cardId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Seller listings settings
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductConfig(cardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductConfig(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Seller feedback object contains array of feedback information and also ratings for different times since seller joined.
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerFeedback(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerFeedback(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seller dashboard information
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerInfo(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerInfo(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seller price listing
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerPriceListing(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerPriceListing(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Seller summary
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerSummary(sellerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerSummary(sellerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Seller product update info
         * @param {SellerProductRequest} sellerProductRequest 
         * @param {string} cardId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerCardInformation(sellerProductRequest?: SellerProductRequest, cardId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerCardInformation(sellerProductRequest, cardId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Seller Inventory Switch On/Off
         * @param {string} status 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerInventoryStatus(status?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerInventoryStatus(status, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all shipping tiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingTiers(options?: undefined): AxiosPromise<ShippingTier> {
            return localVarFp.getAllShippingTiers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Card by card id
         * @param {string} cardId Card id to get the listings for
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListings(cardId?: string, authorization?: string, options?: undefined): AxiosPromise<Card> {
            return localVarFp.getCardListings(cardId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Seller listings settings
         * @param {string} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductConfig(cardId?: string, options?: undefined): AxiosPromise<ProductConfig> {
            return localVarFp.getProductConfig(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Seller feedback object contains array of feedback information and also ratings for different times since seller joined.
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerFeedback(authorization?: string, options?: undefined): AxiosPromise<FeedbackResponse> {
            return localVarFp.getSellerFeedback(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seller dashboard information
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerInfo(authorization?: string, options?: undefined): AxiosPromise<SellerDashboard> {
            return localVarFp.getSellerInfo(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seller price listing
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerPriceListing(authorization?: string, options?: undefined): AxiosPromise<PriceListing> {
            return localVarFp.getSellerPriceListing(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Seller summary
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerSummary(sellerId?: string, options?: undefined): AxiosPromise<SellerSummary> {
            return localVarFp.getSellerSummary(sellerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Seller product update info
         * @param {SellerProductRequest} sellerProductRequest 
         * @param {string} cardId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerCardInformation(sellerProductRequest?: SellerProductRequest, cardId?: string, authorization?: string, options?: undefined): AxiosPromise<Card> {
            return localVarFp.updateSellerCardInformation(sellerProductRequest, cardId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Seller Inventory Switch On/Off
         * @param {string} status 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerInventoryStatus(status?: string, authorization?: string, options?: undefined): AxiosPromise<boolean> {
            return localVarFp.updateSellerInventoryStatus(status, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Get all shipping tiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAllShippingTiers = (options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).getAllShippingTiers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Card by card id
     * @param {string} cardId Card id to get the listings for
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getCardListings = (cardId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).getCardListings(cardId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Seller listings settings
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getProductConfig = (cardId?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).getProductConfig(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Seller feedback object contains array of feedback information and also ratings for different times since seller joined.
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSellerFeedback = (authorization?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).getSellerFeedback(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seller dashboard information
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSellerInfo = (authorization?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).getSellerInfo(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seller price listing
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSellerPriceListing = (authorization?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).getSellerPriceListing(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Seller summary
     * @param {string} sellerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSellerSummary = (sellerId?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).getSellerSummary(sellerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Seller product update info
     * @param {SellerProductRequest} sellerProductRequest 
     * @param {string} cardId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateSellerCardInformation = (sellerProductRequest?: SellerProductRequest, cardId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).updateSellerCardInformation(sellerProductRequest, cardId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Seller Inventory Switch On/Off
     * @param {string} status 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateSellerInventoryStatus = (status?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return AccountApiFp(this.configuration).updateSellerInventoryStatus(status, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulkUploadApi - axios parameter creator
 * @export
 */
export const BulkUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete all listings in Bulk upload
         * @param {BulkUploadDeactivateRequest} bulkUploadDeactivateRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProducts: async (bulkUploadDeactivateRequest?: BulkUploadDeactivateRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/bulk-upload/deactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUploadDeactivateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Results for bulk upload
         * @param {BulkUploadResultsRequest} bulkUploadResultsRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkUploadResults: async (bulkUploadResultsRequest?: BulkUploadResultsRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/bulk-upload/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUploadResultsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save all listings in Bulk upload
         * @param {BulkUploadSaveRequest} bulkUploadSaveRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (bulkUploadSaveRequest?: BulkUploadSaveRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/bulk-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUploadSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Swap all listings in Bulk upload
         * @param {BulkUploadSwapRequest} bulkUploadSwapRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swap: async (bulkUploadSwapRequest?: BulkUploadSwapRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/bulk-upload/swap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUploadSwapRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkUploadApi - functional programming interface
 * @export
 */
export const BulkUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete all listings in Bulk upload
         * @param {BulkUploadDeactivateRequest} bulkUploadDeactivateRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateProducts(bulkUploadDeactivateRequest?: BulkUploadDeactivateRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUploadSaveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateProducts(bulkUploadDeactivateRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Results for bulk upload
         * @param {BulkUploadResultsRequest} bulkUploadResultsRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBulkUploadResults(bulkUploadResultsRequest?: BulkUploadResultsRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUploadResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBulkUploadResults(bulkUploadResultsRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save all listings in Bulk upload
         * @param {BulkUploadSaveRequest} bulkUploadSaveRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(bulkUploadSaveRequest?: BulkUploadSaveRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUploadSaveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(bulkUploadSaveRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Swap all listings in Bulk upload
         * @param {BulkUploadSwapRequest} bulkUploadSwapRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swap(bulkUploadSwapRequest?: BulkUploadSwapRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUploadSaveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swap(bulkUploadSwapRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkUploadApi - factory interface
 * @export
 */
export const BulkUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkUploadApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete all listings in Bulk upload
         * @param {BulkUploadDeactivateRequest} bulkUploadDeactivateRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateProducts(bulkUploadDeactivateRequest?: BulkUploadDeactivateRequest, authorization?: string, options?: undefined): AxiosPromise<BulkUploadSaveResponse> {
            return localVarFp.deactivateProducts(bulkUploadDeactivateRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Results for bulk upload
         * @param {BulkUploadResultsRequest} bulkUploadResultsRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkUploadResults(bulkUploadResultsRequest?: BulkUploadResultsRequest, authorization?: string, options?: undefined): AxiosPromise<BulkUploadResults> {
            return localVarFp.getBulkUploadResults(bulkUploadResultsRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save all listings in Bulk upload
         * @param {BulkUploadSaveRequest} bulkUploadSaveRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(bulkUploadSaveRequest?: BulkUploadSaveRequest, authorization?: string, options?: undefined): AxiosPromise<BulkUploadSaveResponse> {
            return localVarFp.save(bulkUploadSaveRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Swap all listings in Bulk upload
         * @param {BulkUploadSwapRequest} bulkUploadSwapRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swap(bulkUploadSwapRequest?: BulkUploadSwapRequest, authorization?: string, options?: undefined): AxiosPromise<BulkUploadSaveResponse> {
            return localVarFp.swap(bulkUploadSwapRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkUploadApi - object-oriented interface
 * @export
 * @class BulkUploadApi
 * @extends {BaseAPI}
 */
export class BulkUploadApi extends BaseAPI {
    /**
     * 
     * @summary Delete all listings in Bulk upload
     * @param {BulkUploadDeactivateRequest} bulkUploadDeactivateRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUploadApi
     */
    public deactivateProducts = (bulkUploadDeactivateRequest?: BulkUploadDeactivateRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return BulkUploadApiFp(this.configuration).deactivateProducts(bulkUploadDeactivateRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Results for bulk upload
     * @param {BulkUploadResultsRequest} bulkUploadResultsRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUploadApi
     */
    public getBulkUploadResults = (bulkUploadResultsRequest?: BulkUploadResultsRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return BulkUploadApiFp(this.configuration).getBulkUploadResults(bulkUploadResultsRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save all listings in Bulk upload
     * @param {BulkUploadSaveRequest} bulkUploadSaveRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUploadApi
     */
    public save = (bulkUploadSaveRequest?: BulkUploadSaveRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return BulkUploadApiFp(this.configuration).save(bulkUploadSaveRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Swap all listings in Bulk upload
     * @param {BulkUploadSwapRequest} bulkUploadSwapRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUploadApi
     */
    public swap = (bulkUploadSwapRequest?: BulkUploadSwapRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return BulkUploadApiFp(this.configuration).swap(bulkUploadSwapRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer: async (offerRequest?: OfferRequest, authorization?: string, offerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOffer(offerRequest?: OfferRequest, authorization?: string, offerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOffer(offerRequest, authorization, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Update offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer(offerRequest?: OfferRequest, authorization?: string, offerId?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.updateOffer(offerRequest, authorization, offerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Update offer
     * @param {OfferRequest} offerRequest 
     * @param {string} authorization 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public updateOffer = (offerRequest?: OfferRequest, authorization?: string, offerId?: string, options?: AxiosRequestConfig) => {
        return OfferApiFp(this.configuration).updateOffer(offerRequest, authorization, offerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Shipping Label
         * @param {ShippingLabelRequest} shippingLabelRequest 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: async (shippingLabelRequest?: ShippingLabelRequest, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/{orderId}/shipping-label`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shippingLabelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Shipping Label by Rate
         * @param {ShippingLabelRequest} shippingLabelRequest 
         * @param {string} orderId 
         * @param {string} rateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelForRate: async (shippingLabelRequest?: ShippingLabelRequest, orderId?: string, rateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/{orderId}/rate-label/{rateId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"rateId"}}`, encodeURIComponent(String(rateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shippingLabelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order by orderId
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderById: async (orderId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order history
         * @param {OrderHistoryRequest} orderHistoryRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistory: async (orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Shipping Rates
         * @param {PackageOptions} packageOptions 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingRates: async (packageOptions?: PackageOptions, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/{orderId}/shipping-rates`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order Refund
         * @param {OrderRefund} orderRefund 
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundOrderById: async (orderRefund?: OrderRefund, orderId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/{orderId}/refund`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRefund, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the order with tracking number or details sent by seller
         * @param {Shipment} shipment 
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder: async (shipment?: Shipment, orderId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shipment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Void Shipping Label for an order
         * @param {string} orderId 
         * @param {string} labelId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voidLabel: async (orderId?: string, labelId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/order/{orderId}/shipping-label/{labelId}/void`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"labelId"}}`, encodeURIComponent(String(labelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Shipping Label
         * @param {ShippingLabelRequest} shippingLabelRequest 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLabel(shippingLabelRequest?: ShippingLabelRequest, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingLabel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLabel(shippingLabelRequest, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Shipping Label by Rate
         * @param {ShippingLabelRequest} shippingLabelRequest 
         * @param {string} orderId 
         * @param {string} rateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLabelForRate(shippingLabelRequest?: ShippingLabelRequest, orderId?: string, rateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingLabel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLabelForRate(shippingLabelRequest, orderId, rateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order by orderId
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderById(orderId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderById(orderId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order history
         * @param {OrderHistoryRequest} orderHistoryRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistory(orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistory(orderHistoryRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Shipping Rates
         * @param {PackageOptions} packageOptions 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingRates(packageOptions?: PackageOptions, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShippingRates(packageOptions, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order Refund
         * @param {OrderRefund} orderRefund 
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundOrderById(orderRefund?: OrderRefund, orderId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRefund>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundOrderById(orderRefund, orderId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the order with tracking number or details sent by seller
         * @param {Shipment} shipment 
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrder(shipment?: Shipment, orderId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrder(shipment, orderId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Void Shipping Label for an order
         * @param {string} orderId 
         * @param {string} labelId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voidLabel(orderId?: string, labelId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidLabelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voidLabel(orderId, labelId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Shipping Label
         * @param {ShippingLabelRequest} shippingLabelRequest 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(shippingLabelRequest?: ShippingLabelRequest, orderId?: string, options?: undefined): AxiosPromise<ShippingLabel> {
            return localVarFp.createLabel(shippingLabelRequest, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Shipping Label by Rate
         * @param {ShippingLabelRequest} shippingLabelRequest 
         * @param {string} orderId 
         * @param {string} rateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelForRate(shippingLabelRequest?: ShippingLabelRequest, orderId?: string, rateId?: string, options?: undefined): AxiosPromise<ShippingLabel> {
            return localVarFp.createLabelForRate(shippingLabelRequest, orderId, rateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order by orderId
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderById(orderId?: string, authorization?: string, options?: undefined): AxiosPromise<Order> {
            return localVarFp.getOrderById(orderId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order history
         * @param {OrderHistoryRequest} orderHistoryRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistory(orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options?: undefined): AxiosPromise<OrderHistoryResponse> {
            return localVarFp.getOrderHistory(orderHistoryRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Shipping Rates
         * @param {PackageOptions} packageOptions 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingRates(packageOptions?: PackageOptions, orderId?: string, options?: undefined): AxiosPromise<ShippingRateResponse> {
            return localVarFp.getShippingRates(packageOptions, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order Refund
         * @param {OrderRefund} orderRefund 
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundOrderById(orderRefund?: OrderRefund, orderId?: string, authorization?: string, options?: undefined): AxiosPromise<OrderRefund> {
            return localVarFp.refundOrderById(orderRefund, orderId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the order with tracking number or details sent by seller
         * @param {Shipment} shipment 
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(shipment?: Shipment, orderId?: string, authorization?: string, options?: undefined): AxiosPromise<Order> {
            return localVarFp.updateOrder(shipment, orderId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Void Shipping Label for an order
         * @param {string} orderId 
         * @param {string} labelId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voidLabel(orderId?: string, labelId?: string, authorization?: string, options?: undefined): AxiosPromise<VoidLabelResponse> {
            return localVarFp.voidLabel(orderId, labelId, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Create Shipping Label
     * @param {ShippingLabelRequest} shippingLabelRequest 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createLabel = (shippingLabelRequest?: ShippingLabelRequest, orderId?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).createLabel(shippingLabelRequest, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Shipping Label by Rate
     * @param {ShippingLabelRequest} shippingLabelRequest 
     * @param {string} orderId 
     * @param {string} rateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createLabelForRate = (shippingLabelRequest?: ShippingLabelRequest, orderId?: string, rateId?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).createLabelForRate(shippingLabelRequest, orderId, rateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order by orderId
     * @param {string} orderId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderById = (orderId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).getOrderById(orderId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order history
     * @param {OrderHistoryRequest} orderHistoryRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderHistory = (orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).getOrderHistory(orderHistoryRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Shipping Rates
     * @param {PackageOptions} packageOptions 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getShippingRates = (packageOptions?: PackageOptions, orderId?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).getShippingRates(packageOptions, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order Refund
     * @param {OrderRefund} orderRefund 
     * @param {string} orderId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public refundOrderById = (orderRefund?: OrderRefund, orderId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).refundOrderById(orderRefund, orderId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the order with tracking number or details sent by seller
     * @param {Shipment} shipment 
     * @param {string} orderId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrder = (shipment?: Shipment, orderId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).updateOrder(shipment, orderId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Void Shipping Label for an order
     * @param {string} orderId 
     * @param {string} labelId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public voidLabel = (orderId?: string, labelId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).voidLabel(orderId, labelId, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get payment order details 
         * @param {string} authorization 
         * @param {string} sellerPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentOrders: async (authorization?: string, sellerPaymentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/payment/{sellerPaymentId}`
                .replace(`{${"sellerPaymentId"}}`, encodeURIComponent(String(sellerPaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seller future payment details 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerFuturePayments: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/payment/future`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seller payment history detail 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerPaymentsHistory: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/payment/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get payment order details 
         * @param {string} authorization 
         * @param {string} sellerPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentOrders(authorization?: string, sellerPaymentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentOrders(authorization, sellerPaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seller future payment details 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerFuturePayments(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerFuturePayments(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seller payment history detail 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerPaymentsHistory(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerPaymentsHistory(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get payment order details 
         * @param {string} authorization 
         * @param {string} sellerPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentOrders(authorization?: string, sellerPaymentId?: string, options?: undefined): AxiosPromise<PaymentOrder> {
            return localVarFp.getPaymentOrders(authorization, sellerPaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seller future payment details 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerFuturePayments(authorization?: string, options?: undefined): AxiosPromise<SellerPayment> {
            return localVarFp.getSellerFuturePayments(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seller payment history detail 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerPaymentsHistory(authorization?: string, options?: undefined): AxiosPromise<SellerPayment> {
            return localVarFp.getSellerPaymentsHistory(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Get payment order details 
     * @param {string} authorization 
     * @param {string} sellerPaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getPaymentOrders = (authorization?: string, sellerPaymentId?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).getPaymentOrders(authorization, sellerPaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seller future payment details 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getSellerFuturePayments = (authorization?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).getSellerFuturePayments(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seller payment history detail 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getSellerPaymentsHistory = (authorization?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).getSellerPaymentsHistory(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a product inventory
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: async (product?: Product, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/product/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardYear: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/product/update-card-year`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCatalogByCardIds: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/product/update-catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCatalogBySeller: async (sellerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/product/{sellerId}/update-catalog`
                .replace(`{${"sellerId"}}`, encodeURIComponent(String(sellerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a product inventory
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add(product?: Product, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add(product, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCardYear(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCardYear(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCatalogByCardIds(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCatalogByCardIds(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCatalogBySeller(sellerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCatalogBySeller(sellerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a product inventory
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(product?: Product, options?: undefined): AxiosPromise<Product> {
            return localVarFp.add(product, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: undefined): AxiosPromise<string> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id?: string, options?: undefined): AxiosPromise<Product> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardYear(requestBody?: Array<string>, options?: undefined): AxiosPromise<void> {
            return localVarFp.updateCardYear(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCatalogByCardIds(requestBody?: Array<string>, options?: undefined): AxiosPromise<void> {
            return localVarFp.updateCatalogByCardIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCatalogBySeller(sellerId?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.updateCatalogBySeller(sellerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Create a product inventory
     * @param {Product} product 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public add = (product?: Product, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).add(product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getAll = (options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Product by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getById = (id?: string, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateCardYear = (requestBody?: Array<string>, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).updateCardYear(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateCatalogByCardIds = (requestBody?: Array<string>, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).updateCatalogByCardIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sellerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateCatalogBySeller = (sellerId?: string, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).updateCatalogBySeller(sellerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Seller Daily Sales summary
         * @param {ReportRequest} reportRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesDailySummary: async (reportRequest?: ReportRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/report/sales-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Seller Daily Sales summary
         * @param {ReportRequest} reportRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesDailySummary(reportRequest?: ReportRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesDailySummary(reportRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Seller Daily Sales summary
         * @param {ReportRequest} reportRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesDailySummary(reportRequest?: ReportRequest, authorization?: string, options?: undefined): AxiosPromise<string> {
            return localVarFp.getSalesDailySummary(reportRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Get Seller Daily Sales summary
     * @param {ReportRequest} reportRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getSalesDailySummary = (reportRequest?: ReportRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return ReportsApiFp(this.configuration).getSalesDailySummary(reportRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShippingApi - axios parameter creator
 * @export
 */
export const ShippingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Carrier Packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierPackages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/shipping/carrier-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingApi - functional programming interface
 * @export
 */
export const ShippingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Carrier Packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarrierPackages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierPackageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarrierPackages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShippingApi - factory interface
 * @export
 */
export const ShippingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Carrier Packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierPackages(options?: undefined): AxiosPromise<CarrierPackageResponse> {
            return localVarFp.getCarrierPackages(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingApi - object-oriented interface
 * @export
 * @class ShippingApi
 * @extends {BaseAPI}
 */
export class ShippingApi extends BaseAPI {
    /**
     * 
     * @summary Get Carrier Packages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingApi
     */
    public getCarrierPackages = (options?: AxiosRequestConfig) => {
        return ShippingApiFp(this.configuration).getCarrierPackages(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Sellers Account profile or Seller Profile in details
         * @param {User} user 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSellerUserProfile: async (user?: User, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Dwolla Customer
         * @param {SellerProfile} sellerProfile 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (sellerProfile?: SellerProfile, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFundingSrc: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/funding-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerProfile: async (authorization?: string, customerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Dwolla Token
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDwollaToken: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/dwolla-client-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Dwolla IAV Token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIavToken: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/iav-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seller user profile information
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerUserProfile: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Dwolla AuthToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add seller address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAddressByType: async (address?: Address, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress: async (address?: Address, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Payment Settings
         * @param {PaymentSetting} paymentSetting 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerPaymentSetting: async (paymentSetting?: PaymentSetting, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/profile/payment-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Sellers Account profile or Seller Profile in details
         * @param {User} user 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerUserProfile: async (user?: User, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Dwolla Micro Deposits
         * @param {VerifyAmount} verifyAmount 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMicroDeposits: async (verifyAmount?: VerifyAmount, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/seller/user/verify-micro-deposits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyAmount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Sellers Account profile or Seller Profile in details
         * @param {User} user 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSellerUserProfile(user?: User, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSellerUserProfile(user, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Dwolla Customer
         * @param {SellerProfile} sellerProfile 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(sellerProfile?: SellerProfile, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(sellerProfile, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFundingSrc(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFundingSrc(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSellerProfile(authorization?: string, customerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSellerProfile(authorization, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Dwolla Token
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDwollaToken(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDwollaToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Dwolla IAV Token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIavToken(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIavToken(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seller user profile information
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerUserProfile(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerUserProfile(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Dwolla AuthToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add seller address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAddressByType(address?: Address, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAddressByType(address, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddress(address?: Address, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAddress(address, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Payment Settings
         * @param {PaymentSetting} paymentSetting 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerPaymentSetting(paymentSetting?: PaymentSetting, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerPaymentSetting(paymentSetting, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Sellers Account profile or Seller Profile in details
         * @param {User} user 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerUserProfile(user?: User, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerUserProfile(user, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify Dwolla Micro Deposits
         * @param {VerifyAmount} verifyAmount 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyMicroDeposits(verifyAmount?: VerifyAmount, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyMicroDeposits(verifyAmount, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Sellers Account profile or Seller Profile in details
         * @param {User} user 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSellerUserProfile(user?: User, authorization?: string, options?: undefined): AxiosPromise<SellerProfile> {
            return localVarFp.addSellerUserProfile(user, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Dwolla Customer
         * @param {SellerProfile} sellerProfile 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(sellerProfile?: SellerProfile, authorization?: string, options?: undefined): AxiosPromise<SellerProfile> {
            return localVarFp.createCustomer(sellerProfile, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFundingSrc(authorization?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteFundingSrc(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerProfile(authorization?: string, customerId?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.deleteSellerProfile(authorization, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Dwolla Token
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDwollaToken(body?: string, options?: undefined): AxiosPromise<TokenResponse> {
            return localVarFp.getDwollaToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Dwolla IAV Token
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIavToken(authorization?: string, options?: undefined): AxiosPromise<TokenResponse> {
            return localVarFp.getIavToken(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seller user profile information
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerUserProfile(authorization?: string, options?: undefined): AxiosPromise<User> {
            return localVarFp.getSellerUserProfile(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Dwolla AuthToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(options?: undefined): AxiosPromise<Address> {
            return localVarFp.getToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add seller address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAddressByType(address?: Address, authorization?: string, options?: undefined): AxiosPromise<Address> {
            return localVarFp.saveAddressByType(address, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress(address?: Address, authorization?: string, options?: undefined): AxiosPromise<Address> {
            return localVarFp.updateAddress(address, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Payment Settings
         * @param {PaymentSetting} paymentSetting 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerPaymentSetting(paymentSetting?: PaymentSetting, authorization?: string, options?: undefined): AxiosPromise<Address> {
            return localVarFp.updateSellerPaymentSetting(paymentSetting, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Sellers Account profile or Seller Profile in details
         * @param {User} user 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerUserProfile(user?: User, authorization?: string, options?: undefined): AxiosPromise<SellerProfile> {
            return localVarFp.updateSellerUserProfile(user, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Dwolla Micro Deposits
         * @param {VerifyAmount} verifyAmount 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMicroDeposits(verifyAmount?: VerifyAmount, authorization?: string, options?: undefined): AxiosPromise<User> {
            return localVarFp.verifyMicroDeposits(verifyAmount, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Create Sellers Account profile or Seller Profile in details
     * @param {User} user 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addSellerUserProfile = (user?: User, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).addSellerUserProfile(user, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Dwolla Customer
     * @param {SellerProfile} sellerProfile 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createCustomer = (sellerProfile?: SellerProfile, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).createCustomer(sellerProfile, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteFundingSrc = (authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).deleteFundingSrc(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {string} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteSellerProfile = (authorization?: string, customerId?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).deleteSellerProfile(authorization, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Dwolla Token
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getDwollaToken = (body?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getDwollaToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Dwolla IAV Token
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getIavToken = (authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getIavToken(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seller user profile information
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getSellerUserProfile = (authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getSellerUserProfile(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Dwolla AuthToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getToken = (options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add seller address
     * @param {Address} address 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public saveAddressByType = (address?: Address, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).saveAddressByType(address, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Address
     * @param {Address} address 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateAddress = (address?: Address, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).updateAddress(address, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Payment Settings
     * @param {PaymentSetting} paymentSetting 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateSellerPaymentSetting = (paymentSetting?: PaymentSetting, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).updateSellerPaymentSetting(paymentSetting, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Sellers Account profile or Seller Profile in details
     * @param {User} user 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateSellerUserProfile = (user?: User, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).updateSellerUserProfile(user, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Dwolla Micro Deposits
     * @param {VerifyAmount} verifyAmount 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public verifyMicroDeposits = (verifyAmount?: VerifyAmount, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).verifyMicroDeposits(verifyAmount, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


