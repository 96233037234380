/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Order} from '../../../../client/openapitools/seller';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import useDateTimeNormalizer from '../../../../client/common/normalizer/dateTimeNormalizer';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import TableFooter from '@material-ui/core/TableFooter';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: 'white',
            padding: '15px',
            maxWidth: '8.5in',
        },
        dashedBottomBorder: {
            paddingTop: '3rem',
            paddingBottom: '3rem',
            borderBottom: '1px grey dashed',
        },
        detailBox: {
            border: '1px solid black',
        },
        daily: {},
        tr: {},
        td: {
            border: '1px black solid',
            padding: '4px',
        },
        tf: {
            border: '1px black solid',
            color: 'black',
            padding: '4px',
        },
        textCenter: {
            textAlign: 'center',
        },
    };
});

function PackingSlip({order}: {order: Order}) {
    const classes = useStyles();
    const dateNormalizer = useDateTimeNormalizer();
    const [totalQty, setTotalQty] = useState(0);
    const condition = useCardNormalizer();

    const [hasSellerSku, setHasSellerSku] = useState(false);
    const [disclaimerPadding, setDisclaimerPadding] = useState(0);
    useEffect(() => {
        let tq = 0;
        if (!order) return;

        for (const item of order?.orderItems ?? []) {
            if (item && item.orderQuantity) {
                tq += item.orderQuantity;
            }
            if (item && item['sellerSku']) {
                setHasSellerSku(true);
            }
        }
        setDisclaimerPadding(tq * 8);
        setTotalQty(tq);
    }, [order]);

    return (
        <>
            {order && (
                <BSCContainer variant="page">
                    <Grid container className={classes.container} direction="column" alignContent="top">
                        <Grid item xs={12} className={classes.dashedBottomBorder}>
                            <BSCTypography label="Ship To:" size12 />
                            <BSCTypography label={`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`} size16 semibold />
                            <BSCTypography label={order.shippingAddress.addressLine1} size16 semibold />
                            {order.shippingAddress.addressLine2 && (
                                <BSCTypography label={order.shippingAddress.addressLine2} size16 semibold />
                            )}
                            <BSCTypography
                                label={`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.zipCode}`}
                                size16
                                semibold
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <BSCTypography label={`Order Number: ${order.orderNo}`} size14 semibold />
                            <BSCTypography
                                label={`Thanks for buying from ${order.seller.sellerProfile.sellerStoreName} on BuySportsCards.com`}
                                size12
                                semibold
                            />
                            <br />
                            <Grid container xs={12} className={classes.detailBox} justifyContent="center">
                                <Grid item xs={5} spacing={2}>
                                    <BSCTypography label="Shipping Address:" size12 semibold />
                                    <BSCTypography
                                        label={`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}
                                        size12
                                        variant="body1"
                                    />
                                    <BSCTypography label={order.shippingAddress.addressLine1} variant="body1" size12 />
                                    {order.shippingAddress.addressLine2 && (
                                        <BSCTypography label={order.shippingAddress.addressLine2} size12 variant="body1" />
                                    )}
                                    <BSCTypography
                                        label={`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.zipCode}`}
                                        size12
                                        variant="body1"
                                    />
                                </Grid>
                                <Grid item xs={7} spacing={2} container justifyContent="space-between">
                                    <Grid item xs={4} justifyContent="flex-start">
                                        <BSCTypography label="Order Date: " variant="body1" size12 />
                                        <BSCTypography label="Buyer Name: " variant="body1" size12 />
                                        <BSCTypography label="Seller Name: " variant="body1" size12 />
                                    </Grid>
                                    <Grid item xs={8} justifyContent="flex-start">
                                        <BSCTypography label={dateNormalizer.getDisplayDate(order.orderDate)} variant="body1" size12 />
                                        <BSCTypography label={`${order.buyer.firstName} ${order.buyer.lastName}`} variant="body1" size12 />
                                        <BSCTypography label={order.seller.sellerProfile.sellerStoreName} variant="body1" size12 />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={[classes.td, classes.textCenter]}>
                                            <BSCTypography label="Qty" size10 semibold />
                                        </TableCell>
                                        <TableCell className={[classes.td, classes.textCenter]}>
                                            <BSCTypography label="Sport" size10 semibold />
                                        </TableCell>
                                        <TableCell className={[classes.td, classes.textCenter]}>
                                            <BSCTypography label="Description" size10 semibold />
                                        </TableCell>
                                        <TableCell className={[classes.td, classes.textCenter]}>
                                            <BSCTypography label="Condition" size10 semibold />
                                        </TableCell>
                                        {hasSellerSku && (
                                            <TableCell className={[classes.td, classes.textCenter]}>
                                                <BSCTypography label="Seller SKU" size10 semibold />
                                            </TableCell>
                                        )}
                                        <TableCell className={[classes.td, classes.textCenter]}>
                                            <BSCTypography label="Price" size10 semibold />
                                        </TableCell>
                                        <TableCell className={[classes.td, classes.textCenter]}>
                                            <BSCTypography label="Total" size10 semibold />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order?.orderItems?.map((item, sI) => {
                                        let isVintage = false;
                                        if (Number(item.card.year) <= 1980) {
                                            isVintage = true;
                                        } else {
                                            isVintage = false;
                                        }
                                        return (
                                            <TableRow hover role="button" tabIndex={-1} key={`${sI}`} className={classes.tr}>
                                                <TableCell key={`${sI}-qty`} className={[classes.td, classes.textCenter]}>
                                                    <BSCTypography
                                                        label={item.orderQuantity}
                                                        variant="body1"
                                                        className={classes.textCenter}
                                                        style={{fontSize: 8}}
                                                    />
                                                </TableCell>
                                                <TableCell key={`${sI}-sport`} className={[classes.td, classes.textCenter]}>
                                                    <BSCTypography
                                                        label={item.card.sport}
                                                        variant="body1"
                                                        className={classes.textCenter}
                                                        style={{fontSize: 8}}
                                                    />
                                                </TableCell>
                                                <TableCell key={`${sI}-desc`} className={classes.td}>
                                                    <BSCTypography
                                                        variant="body1"
                                                        style={{fontSize: 8}}
                                                        maxCharacters={90}
                                                        label={condition.getPackingSlipPlayerName(item.card)}
                                                    />
                                                    <BSCTypography
                                                        variant="body1"
                                                        style={{fontSize: 8}}
                                                        label={`${condition.getSetAndVariant(item.card)} (${item.card.variant})`}
                                                    />
                                                </TableCell>
                                                <TableCell key={`${sI}--condition`} className={[classes.td, classes.textCenter]}>
                                                    <BSCTypography
                                                        variant="body1"
                                                        style={{fontSize: 8}}
                                                        label={condition.getProductTypeAndCondition(item, isVintage)}
                                                    />
                                                </TableCell>
                                                {hasSellerSku && (
                                                    <TableCell className={[classes.td, classes.textCenter]}>
                                                        <BSCTypography
                                                            label={item.sellerSku}
                                                            variant="body1"
                                                            className={classes.textCenter}
                                                            style={{fontSize: 8}}
                                                        />
                                                    </TableCell>
                                                )}
                                                <TableCell key={`${sI}--price`} className={[classes.td, classes.textCenter]}>
                                                    <BSCCurrency amount={item.price} variant="body1" style={{fontSize: 8}} />
                                                </TableCell>
                                                <TableCell key={`${sI}--total`} className={[classes.td, classes.textCenter]}>
                                                    <BSCCurrency
                                                        amount={item.price * item.orderQuantity}
                                                        variant="body1"
                                                        style={{fontSize: 8}}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label="" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        <TableCell className={[classes.tf]}>
                                            <BSCTypography label="Shipping" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label="" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label="" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        {hasSellerSku && (
                                            <TableCell className={[classes.tf, classes.textCenter]}>
                                                <BSCTypography label="" style={{fontSize: 8}} semibold />
                                            </TableCell>
                                        )}
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label="" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCCurrency amount={order.shippingCost} style={{fontSize: 8}} semibold />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label={totalQty} style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        <TableCell className={[classes.tf]}>
                                            <BSCTypography label="Total" style={{fontSize: 8}} bold />
                                        </TableCell>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label="" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label="" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        {hasSellerSku && (
                                            <TableCell className={[classes.tf, classes.textCenter]}>
                                                <BSCTypography label="" style={{fontSize: 8}} semibold />
                                            </TableCell>
                                        )}
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCTypography label="" style={{fontSize: 8}} semibold />
                                        </TableCell>
                                        <TableCell className={[classes.tf, classes.textCenter]}>
                                            <BSCCurrency
                                                amount={parseFloat(order.subTotal) + parseFloat(order.shippingCost)}
                                                style={{fontSize: 8}}
                                                semibold
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: `${disclaimerPadding}px`}}>
                            <br />
                            <BSCTypography label={'For Any Issues With the Contents of Your Order:'} size12 semibold />
                            <BSCTypography
                                label={`Please contact the Seller directly by logging into your account and
                    navigating to the "Order History" page. From here you will be able to see each order that you've placed as well as a
                    link to "Contact Seller." If after reaching out to the Seller about your order issue you have not received a response
                    within 48 hours, please contact support@buysportscards.com for further assistance.`}
                                size10
                            />
                            <br />

                            <BSCTypography label={'Thank you for buying on BuySportsCards.com'} size12 variant="subtitle1" />
                            <BSCTypography
                                label={`To provide feedback for the
                    seller, please visit our Feedback page. To contact the seller, please visit BuySportsCards.com and click on "Order
                    History" at the top of the page. Find the order and click the "Contact Seller" button.`}
                                size10
                            />
                        </Grid>
                    </Grid>
                </BSCContainer>
            )}
        </>
    );
}

export default PackingSlip;
