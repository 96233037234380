import {s3Client} from '../..';

const serviceUrls = {
    formValidations: 's3/formValidations',
};

const FormValidationsClient = {
    getFormValidations: () => s3Client.get(serviceUrls.formValidations).then(res => res.data),
};

export default FormValidationsClient;
