import BSCRepeater from '../bscRepeater/bscRepeater';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
interface BSCTableRowSkeletonProps {
    amountOfRows: number;
    amountOfCells: number;
}

function BSCTableRowSkeleton({amountOfRows, amountOfCells}: BSCTableRowSkeletonProps) {
    return (
        <BSCRepeater amount={amountOfRows}>
            <TableRow hover>
                <BSCRepeater amount={amountOfCells}>
                    <TableCell align="center">
                        <Skeleton height={40} />
                    </TableCell>
                </BSCRepeater>
            </TableRow>
        </BSCRepeater>
    );
}

export default BSCTableRowSkeleton;
