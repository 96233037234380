/* eslint-disable @typescript-eslint/no-unused-vars */
import {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Alert} from '@material-ui/lab';
import {SearchCard} from '../../../../client/openapitools/search';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import ManageSellButton from './manageSellButton';
import Normalizer from '../../../../client/common/normalizer/normalizer';

const useStyles = makeStyles((theme: Theme) => ({
    manageBtn: {
        marginTop: theme.spacing(1),
        fontSize: 12,
        width: '100%',
    },
}));

interface MobileSLSearchResultsCardProps {
    sellerSearchResults: SearchCard[] | null;
    loading: boolean;
    searchCatalogType: string;
    isInitialMyCatalog: boolean;
    noResultsMessage: string;
}

function MobileSLSearchResultsCard({
    sellerSearchResults,
    loading,
    searchCatalogType,
    isInitialMyCatalog,
    noResultsMessage,
}: MobileSLSearchResultsCardProps) {
    const classes = useStyles();
    const normalizer = useCardNormalizer();
    const [manageButtonProductQty, setManageButtonProductQty] = useState([]);
    useEffect(() => {
        if (sellerSearchResults) {
            const manageButtonQtyArray = [];
            sellerSearchResults.forEach(searchResult => {
                let totalProductQty = 0;
                if (searchResult.products !== null) {
                    searchResult.products.forEach(searchResultProduct => {
                        totalProductQty += searchResultProduct.quantity;
                    });
                }
                manageButtonQtyArray.push(totalProductQty);
            });
            setManageButtonProductQty(manageButtonQtyArray);
        }
    }, [sellerSearchResults]);

    if (loading) {
        return <BSCLoading loading />;
    }

    if (isInitialMyCatalog && !loading) {
        return <Alert severity="warning">No Search Results - please enter a valid Search Term</Alert>;
    }

    return (
        <div>
            {sellerSearchResults?.map((sellerSearchResult, index) => (
                <BSCCardBox style={{display: 'flex'}} key={sellerSearchResult.id}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <BSCImage style={{maxHeight: '80px'}} src={Normalizer.getS3ImagePath(sellerSearchResult.stockImgFront)} />
                        </Grid>
                        <Grid item xs={9}>
                            <BSCTypography label={normalizer.getPlayerName(sellerSearchResult)} semibold />
                            <BSCTypography variant="subtitle2" label={sellerSearchResult.setName} color="textPrimary" />
                            <BSCTypography
                                color="textPrimary"
                                variant="subtitle2"
                                label={normalizer.getVariantAndVariantName(sellerSearchResult)}
                                capitalize
                            />{' '}
                            <BSCTypography color="textPrimary" variant="subtitle2" label={sellerSearchResult.playerAttribute} capitalize />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" style={{flex: 0, alignItems: 'center', paddingBottom: 8}}>
                        <ManageSellButton
                            className={classes.manageBtn}
                            //Was sellerSearchResult.sellerQty until the api Rewrite
                            sellerQty={manageButtonProductQty[index]}
                            cardId={sellerSearchResult.id}
                            searchCatalogType={searchCatalogType}
                        />
                    </Grid>
                </BSCCardBox>
            ))}
            {!loading && !(sellerSearchResults?.length > 0) && <Alert severity="warning">{noResultsMessage}</Alert>}
        </div>
    );
}

export default MobileSLSearchResultsCard;
