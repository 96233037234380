import * as constants from '../../../../shared/constants';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {TableContainer, Table, TableHead, TableCell, Paper, TableRow, TableBody, Grid} from '@material-ui/core';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../features/common/skeletons/BSCTableRowSkeleton';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {Alert} from '@material-ui/lab';
import {SearchCard} from '../../../../client/openapitools/search';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BscImagePopper from '../../../../features/common/bscImagePopper/bscImagePopper';
import Normalizer from '../../../../client/common/normalizer/normalizer';
import ManageSellButton from './manageSellButton';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
    thead: {
        background: '#e5e5e5',
    },
    bold: {
        fontWeight: 600,
    },
    tbody: {
        '& td': {
            textAlign: 'left',
        },
        '& td h6': {
            fontSize: '12px',
        },
    },
    manageBtn: {
        paddingTop: '4px',
        paddingBottom: '4px',
        fontSize: '12px',
    },
    blackRow: {
        color: 'black',
    },
    alignTop: {
        verticalAlign: 'top',
    },
}));

interface SLSearchResultsProps {
    loading: Boolean;
    sellerSearchResults: SearchCard[] | null;
    searchCatalogType: boolean;
    noResultsMessage: string;
    onSortChange: (item: string) => void;
    scrollableId: string;
    isInitialMyCatalog: boolean;
}

function SLSearchResults({
    loading,
    sellerSearchResults,
    searchCatalogType,
    noResultsMessage = 'No Results found',
    onSortChange,
    scrollableId,
    isInitialMyCatalog,
}: SLSearchResultsProps) {
    const DASH = '-';
    const classes = useStyles();
    const {isMobile} = useScreenSnap();
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sortAsc, setSortAsc] = useState(true);

    const handleCardNoClick = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        e => {
            if (sortAsc) {
                onSortChange('z-1');
                setSortAsc(false);
            } else {
                onSortChange('default');
                setSortAsc(true);
            }
        },
        [onSortChange, sortAsc]
    );

    const handleClick = id => {
        dispatch({
            type: constants.SET_SELLER_SCROLLABLE_ID,
            payload: {scrollableId: id},
        });
    };

    useEffect(() => {
        if (scrollableId !== '') {
            const returnPoint = document.getElementById(scrollableId);
            if (returnPoint !== null) {
                returnPoint.scrollIntoView({behavior: 'auto', block: 'start'});
            }
        }
    });

    const [manageButtonProductQty, setManageButtonProductQty] = useState([]);
    useEffect(() => {
        if (sellerSearchResults) {
            const manageButtonQtyArray = [];
            sellerSearchResults.forEach(searchResult => {
                let totalProductQty = 0;
                if (searchResult.products !== null) {
                    searchResult.products.forEach(searchResultProduct => {
                        totalProductQty += searchResultProduct.quantity;
                    });
                }
                if (searchResult.playerAttribute.length > 0) {
                    let playerAttributeString = '';
                    searchResult.playerAttribute.forEach(searchResultAttribute => {
                        playerAttributeString += searchResultAttribute + ', ';
                    });
                    searchResult.playerAttribute = playerAttributeString.slice(0, -2);
                } else {
                    searchResult.playerAttribute = null;
                }
                manageButtonQtyArray.push(totalProductQty);
            });
            setManageButtonProductQty(manageButtonQtyArray);
        }
    }, [sellerSearchResults]);

    if (isInitialMyCatalog && !loading) {
        return <Alert severity="warning">No Search Results - please enter a valid Search Term</Alert>;
    }

    if (!sellerSearchResults && !loading) {
        return <Alert severity="warning">No Search Results</Alert>;
    }

    return (
        <>
            {sellerSearchResults && (
                <TableContainer style={{maxHeight: 636}} component={Paper} square={false} elevation={0}>
                    <Table stickyHeader size="small" aria-label="simple table" style={{width: '100%'}}>
                        <TableHead>
                            <TableRow style={{fontSize: 12, padding: '6px 12px'}}>
                                <TableCell align="left" style={{paddingRight: 0}}>
                                    &nbsp;
                                </TableCell>
                                <TableCell align="left" style={{fontSize: 14, width: 140}}>
                                    Name
                                </TableCell>
                                <TableCell
                                    align="left"
                                    style={{fontSize: 14, width: 140, whiteSpace: 'nowrap'}}
                                    onClick={handleCardNoClick}
                                >
                                    Card #{sortAsc && <ArrowDropUpIcon className={classes.alignTop}></ArrowDropUpIcon>}
                                    {!sortAsc && <ArrowDropDownIcon className={classes.alignTop}></ArrowDropDownIcon>}
                                </TableCell>
                                <TableCell align="left" style={{fontSize: 14, width: 240}}>
                                    Set
                                </TableCell>
                                <TableCell align="left" style={{fontSize: 14, width: 80}}>
                                    Variant
                                </TableCell>
                                <TableCell align="left" style={{fontSize: 14, width: 200}}>
                                    Variant Name
                                </TableCell>
                                <TableCell align="left" style={{fontSize: 14}}>
                                    Attribute(s)
                                </TableCell>
                                <TableCell align="left">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tbody}>
                            {loading && <BSCTableRowSkeleton amountOfRows={6} amountOfCells={7} />}

                            {sellerSearchResults.map((listing, index) => {
                                return (
                                    <TableRow hover key={listing.id}>
                                        <TableCell align="center">
                                            <BscImagePopper
                                                id={`${listing.id}-${index}`}
                                                imageUrl={Normalizer.getS3ImagePath(listing?.stockImgFront)}
                                            >
                                                <BSCImage
                                                    style={{maxHeight: '80px', width: '80px'}}
                                                    src={Normalizer.getS3ImagePath(listing?.stockImgFront)}
                                                />
                                            </BscImagePopper>
                                        </TableCell>
                                        <TableCell align="center">
                                            <BSCTypography
                                                className={classes.blackRow}
                                                label={listing.players}
                                                variant="subtitle1"
                                                semibold={isMobile ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <BSCTypography
                                                className={classes.blackRow}
                                                label={`#${listing.cardNo}`}
                                                variant="subtitle1"
                                                semibold={isMobile ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <BSCTypography
                                                className={classes.blackRow}
                                                label={listing?.setName ? listing.setName : DASH}
                                                variant="subtitle1"
                                                semibold={isMobile ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <BSCTypography
                                                className={classes.blackRow}
                                                label={listing?.variant ? listing.variant : DASH}
                                                variant="subtitle1"
                                                semibold={isMobile ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <BSCTypography
                                                className={classes.blackRow}
                                                label={listing?.variantName ? listing.variantName : DASH}
                                                variant="subtitle1"
                                                semibold={isMobile ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <BSCTypography
                                                className={classes.blackRow}
                                                label={listing?.playerAttribute ? listing.playerAttribute : DASH}
                                                variant="subtitle1"
                                                semibold={isMobile ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell id={listing.id} align="center" onClick={() => handleClick(listing.id)}>
                                            <ManageSellButton
                                                className={classes.manageBtn}
                                                sellerQty={manageButtonProductQty[index]}
                                                cardId={listing.id}
                                                searchCatalogType={searchCatalogType}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {!loading && !(sellerSearchResults?.length > 0) && (
                <Grid container alignContent="center" style={{background: '#fff', margin: '8px 0', padding: 8}}>
                    <Grid item xs>
                        <BSCTypography
                            className={classes.blackRow}
                            label={noResultsMessage}
                            variant="subtitle1"
                            semibold={isMobile ? true : false}
                            center
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default SLSearchResults;
