/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, Drawer, MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ChevronLeft, Close} from '@material-ui/icons';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import {Skeleton} from '@material-ui/lab';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {Link} from 'react-router-dom';
import * as constants from '../../../../shared/constants';
import {ReactComponent as BSCBuyersLogo} from '../../../../svgs/bscSellers.svg';
import BSCButton from '../../../common/bscButton/bscButton';
import BSCDivider from '../../../common/bscDivider/bscDivider';
import BSCLoading from '../../../common/bscLoading/bscLoading';
import BSCScroller from '../../../common/bscScroller/bscScroller';
import BSCTypography from '../../../common/bscTypography/bscTypography';
import useReduxUser from '../../../hooks/redux/user/useReduxUser';
import {SellerSignInButton} from '../../../users/sellerSignInButton';
import {SignOutButton} from '../../../users/signOutButton';
import SubNavContent from '../sellersSubNavContent';
// import BSCImage from '../../../common/bscImage/bscImage';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useDispatch, useSelector} from 'react-redux';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {ConversationApi} from '../../../../client/openapitools/common/api';
import {AccountApi} from '../../../../client/openapitools/seller/api';
import {Configuration} from '../../../../client/openapitools/seller/configuration';
import {RootState} from '../../../../redux/reducers';
import useReduxToast from '../../../hooks/redux/toast/useReduxToast';

const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        background: theme.palette.primary.main,
        position: 'sticky',
        left: 0,
        top: -1,
        zIndex: 10,
    },
    headerWrap: {
        maxWidth: 1400,
        margin: 'auto',
        // borderTop: 'solid #000 4px',
    },
    menuIcon: {
        padding: theme.spacing(1, 0.5),
    },
    logoWrapper: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    capitalCase: {
        textTransform: 'none!important' as 'none',
    },
    logo: {
        height: 50,
        width: 85,
        ['&:hover']: {
            cursor: 'pointer',
        },
    },
    searchInput: {
        flexGrow: 2,
        width: '100%',
    },
    searchInputStyleProp: {
        background: theme.palette.common.white,
        borderRadius: 4,
        width: '100%',
    },
    sellersPortal: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
    sellersLinkPortal: {
        textDecoration: 'none',
        marginRight: 12,
    },
    menuLeft: {
        zIndex: 1,
    },
    menuRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        zIndex: 1,
    },
    menuItem: {
        color: theme.palette.common.white,
        background: theme.palette.common.black,
        boxShadow:
            '0px 1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px -1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px 3.82222px 3.82222px rgba(255, 255, 255, 0.24)',
        borderRadius: 4,
        textAlign: 'center',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        ...theme.typography.button,
        fontSize: theme.typography.body2.fontSize,
        textTransform: 'inherit',
    },
    mobileSubNavContainer: {
        background: theme.palette.primary.main,
        height: '100%',
    },
    menuTitle: {
        position: 'relative',
        color: theme.palette.common.white,
        padding: theme.spacing(2, 0),
        background: theme.palette.primary.main,
        textAlign: 'center',
    },
    chevronLeft: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    mobileSubNavWrap: {
        padding: theme.spacing(0, 1),
    },
    typography: {
        color: theme.palette.common.white,
    },
    drawer: {
        width: '100%',
    },
    headerTitle: {
        color: '#fff',
        paddingTop: 12,
    },
    envelopeWrapper: {position: 'relative'},
    envelope: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 4,
        right: 4,
        zIndex: 1,
        background: theme.palette.secondary.main,
        padding: 5,
        width: 14,
        height: 14,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
}));

interface SellerPWANavProps {
    //TODO: Fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menus: undefined;
    menusLoading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenuLoading?: boolean;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenu: undefined;
    onPortalChange: () => void;
}

const MenuStepEnum = {
    // MAIN_MENU: 0,
    SPORTS: 0,
    SUB_NAV: 1,
};

function MobileDrawer({menus, open, onClose, menusLoading, setCurrentMenuStep, currentMenuStep}) {
    const classes = useStyles();
    //const [currentMenuStep, setCurrentMenuStep] = useState(0);
    const [selectedMenu, setSelectedMenu] = useState(null);

    useEffect(() => {
        if (open) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = null;
        }
    }, [open]);

    return (
        <Drawer open={open} onClose={onClose} anchor="left" className={classes.drawer} PaperProps={{className: classes.drawer}}>
            <div className={classes.mobileSubNavContainer}>
                <div className={classes.menuTitle}>
                    {currentMenuStep > MenuStepEnum.SPORTS && (
                        <IconButton className={classes.chevronLeft} onClick={() => setCurrentMenuStep(s => s - 1)}>
                            <ChevronLeft fontSize="large" htmlColor="white" />
                        </IconButton>
                    )}
                    <BSCTypography variant="h2" label={currentMenuStep === 0 ? "Seller's Locker" : selectedMenu?.title} />
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <Close fontSize="large" htmlColor="white" />
                    </IconButton>
                </div>
                <div className={classes.mobileSubNavWrap}>
                    <BSCScroller maxHeight="calc(100vh - 70px)">
                        <div>
                            {currentMenuStep === MenuStepEnum.SPORTS && (
                                <>
                                    <BSCLoading loading={menusLoading} />
                                    {menus?.categories?.map(menu => (
                                        <div key={menu.order}>
                                            {menu?.menus?.length > 0 && (
                                                <MenuItem
                                                    key={menu.order}
                                                    className={classes.menuItem}
                                                    onClick={() => {
                                                        setCurrentMenuStep(MenuStepEnum.SUB_NAV);
                                                        setSelectedMenu(menu);
                                                    }}
                                                >
                                                    {menu.title}
                                                </MenuItem>
                                            )}
                                            {menu?.menus?.length === 0 && (
                                                <MenuItem
                                                    key={menu.order}
                                                    component={Link}
                                                    to={menu.url}
                                                    onClick={onClose}
                                                    className={classes.menuItem}
                                                >
                                                    {menu.title}
                                                </MenuItem>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}
                            {currentMenuStep === MenuStepEnum.SUB_NAV && <SubNavContent menus={selectedMenu?.menus} onClose={onClose} />}
                        </div>
                    </BSCScroller>
                </div>
            </div>
        </Drawer>
    );
}
//eslint-disable-next-line @typescript-eslint/no-unused-vars
function SellerPWANav({menus, menusLoading = false, userMenu, userMenuLoading = false, onPortalChange}: SellerPWANavProps) {
    const classes = useStyles();
    const {instance} = useMsal();
    const isPWASellersDashboard = useRouteMatch('/sellers/pwa/dashboard/seller');
    // const isPWASellersProfile = useRouteMatch('/sellers/dashboard/profile');
    const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {apiAuthConfig} = useAuthState();
    const conversationApi = new ConversationApi(apiAuthConfig);
    let sellerAccountApi = new AccountApi(apiAuthConfig);
    // const {data: dashboardInformation, loading: dashboardInformationLoading} = useQuery(sellerAccountApi.getSellerInfo, []);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dashboardInformation, setDashboardInformation] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dashboardInformationLoading, setDashboardInformationLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [accountInfoOpen, setAccountInfoOpen] = useState(false);
    const history = useHistory();
    const {isAuth, userState} = useReduxUser();
    const [currentMenuStep, setCurrentMenuStep] = useState(0);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const isPWASellersProfile = useRouteMatch('/sellers/dashboard/profile');
    const isPWASellersInventory = useRouteMatch('/sellers/inventory/inventory');
    const isPWASellersOrders = useRouteMatch('/sellers/orders');
    const isPWASellersOffers = useRouteMatch('/sellers/offers');
    const isPWASellersSalesSummary = useRouteMatch('/sellers/sales-summary');
    const isPWASellersMessages = useRouteMatch('/sellers/messages');
    const isPWASellersPayments = useRouteMatch('/sellers/payments');
    const isPWASellersFeedback = useRouteMatch('/sellers/dashboard/profile');

    // const launchPromo = () => {
    //     history.push('/sellers/promo');
    // };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pwaOS, setPwaOS] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isShowingBSCLogo, setIsShowingBSCLogo] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [storeName, setStoreName] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
            if (marketplaceState.pwaNavbarActive > 0) {
                setIsShowingBSCLogo(false);
            } else {
                setIsShowingBSCLogo(true);
            }
        }
    }, [marketplaceState]);

    const handleReturnToPWASellerDashboard = () => {
        if (isPWASellersProfile || isPWASellersInventory || isPWASellersMessages) {
            history.push('/sellers/pwa/dashboard/seller');
            dispatch({
                type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                payload: {pwaBuyerDashboardSelection: 4},
            });
        } else {
            history.goBack();
        }
    };

    const routeToMessages = () => {
        history.push('/sellers/messages');
        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 11,
            },
        });
    };

    const handleClose = useCallback(() => {
        setOpen(false);
        setAccountInfoOpen(false);
    }, []);

    const [buyerMessagesCount, setBuyerMessagesCount] = useState('');
    const [sellersMessagesCount, setSellersMessagesCount] = useState('');
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                    try {
                        sellerAccountApi = new AccountApi(apiConfig);
                        const response = await sellerAccountApi.getSellerInfo();
                        setDashboardInformation(response);
                        console.log('>>>', response.data.profile.sellerStoreName);
                        setStoreName(response.data.profile.sellerStoreName);
                        setDashboardInformationLoading(false);
                    } catch (error) {
                        console.log(error);
                    }
                    try {
                        const conversationApi = new ConversationApi(apiConfig);
                        conversationApi.getConversationsForBuyer().then(res => {
                            const messagesData = res?.data.results;
                            if (messagesData !== null) {
                                const unreadMessages = messagesData.filter(msg => msg.read === false);
                                let tempBuyerMessagesCount = '';
                                if (unreadMessages.length > 0) {
                                    tempBuyerMessagesCount = unreadMessages.length + '';
                                }
                                setBuyerMessagesCount(tempBuyerMessagesCount);
                                dispatch({
                                    type: constants.BUYER_MESSAGES_COUNT,
                                    payload: {buyerMessagesCount: tempBuyerMessagesCount},
                                });
                            }
                        });
                    } catch (error) {
                        console.log(error);
                        addToast({
                            severity: 'error',
                            message: 'Error fetching buyer messages.',
                            contextKey: 'Messages',
                        });
                    }
                    try {
                        const conversationApi = new ConversationApi(apiConfig);
                        conversationApi.getConversationsForSeller().then(res => {
                            const messagesData = res?.data.results;
                            if (messagesData !== null) {
                                const unreadMessages = messagesData.filter(msg => msg.read === false);
                                let tempSellersMessagesCount = '';
                                if (unreadMessages.length > 0) {
                                    tempSellersMessagesCount = unreadMessages.length + '';
                                }
                                setSellersMessagesCount(tempSellersMessagesCount);
                                dispatch({
                                    type: constants.SELLER_MESSAGES_COUNT,
                                    payload: {sellerMessagesCount: tempSellersMessagesCount},
                                });
                            }
                        });
                    } catch (error) {
                        console.log(error);
                        addToast({
                            severity: 'error',
                            message: 'Error fetching seller messages.',
                            contextKey: 'Messages',
                        });
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.log(error);
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.headerWrap}>
                    <Grid container justifyContent="space-between">
                        <Grid className={classes.menuLeft}>
                            <IconButton
                                style={
                                    pwaOS === null ? {visibility: 'visible'} : isShowingBSCLogo ? {visibility: 'hidden'} : {display: 'none'}
                                }
                                onClick={() => {
                                    setCurrentMenuStep(0);
                                    setOpen(s => !s);
                                }}
                                className={classes.menuIcon}
                            >
                                <MenuIcon htmlColor="white" fontSize={'large'} />
                            </IconButton>
                            {pwaOS !== null && !isShowingBSCLogo && (
                                <Grid item xs={12} container>
                                    {!isPWASellersDashboard && (
                                        <Grid item xs={2} onClick={handleReturnToPWASellerDashboard}>
                                            <IconButton className={classes.largeIcon}>
                                                <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    {isPWASellersDashboard && (
                                        <Grid item xs={10} container style={{padding: '6px 0px 0px 16px'}}>
                                            <Grid item xs={12}>
                                                <BSCTypography size14 style={{color: '#fff'}} label="Welcome Back," />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <BSCTypography size18 style={{color: '#fff'}} label={storeName} />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>

                        {marketplaceState.pwaBuyerDashboardSelection === 1 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Profile" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 2 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Sell Your Cards" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 3 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="My Inventory" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 4 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Orders" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 5 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Offers" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 6 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Sales Summary" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 7 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Payments" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 8 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Feedback" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 9 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="FAQs" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 10 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Contact Support" />
                            </Grid>
                        )}
                        {marketplaceState.pwaBuyerDashboardSelection === 11 && (
                            <Grid item>
                                <BSCTypography size24 className={classes.headerTitle} label="Messages" />
                            </Grid>
                        )}

                        {isShowingBSCLogo && (
                            <Grid item className={classes.logoWrapper}>
                                <BSCBuyersLogo
                                    className={classes.logo}
                                    onClick={() => {
                                        history.push('/sellers');
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            style={marketplaceState.pwaBuyerDashboardSelection === 11 ? {visibility: 'hidden'} : {visibility: 'visible'}}
                        >
                            <Grid container justifyContent="flex-end" alignItems="center">
                                <Grid item style={pwaOS === null ? {visibility: 'visible', position: 'relative'} : {display: 'none'}}>
                                    <IconButton onClick={() => setAccountInfoOpen(true)} className={classes.largeIcon}>
                                        <PersonOutlineOutlinedIcon htmlColor="white" fontSize="large" />
                                    </IconButton>
                                </Grid>
                                <Grid item className={classes.envelopeWrapper} style={{marginRight: 8}} onClick={routeToMessages}>
                                    {sellersMessagesCount !== '' && sellersMessagesCount !== '0' && (
                                        <span className={classes.envelope}>{sellersMessagesCount}</span>
                                    )}
                                    <IconButton className={classes.largeIcon}>
                                        <EmailOutlinedIcon htmlColor="white" fontSize="large" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {
                <Drawer
                    PaperProps={{style: {maxWidth: '90%'}}}
                    anchor={'right'}
                    open={accountInfoOpen}
                    onClose={() => setAccountInfoOpen(false)}
                >
                    <Box p={2}>
                        {isPWASellersDashboard && userState?.firstName && (
                            <div>
                                <BSCTypography
                                    align="center"
                                    label={
                                        userState?.sellerProfile?.sellerStoreName
                                            ? `Welcome Back, ${userState?.sellerProfile?.sellerStoreName}!`
                                            : 'Hello!'
                                    }
                                    variant="h4"
                                />
                                <BSCDivider />
                            </div>
                        )}
                        {userMenuLoading && <Skeleton />}
                        {!userMenuLoading && userMenu && (
                            <>
                                {userMenu.dashboard.map((x, idx) => (
                                    <div key={`userMenu-${idx}`}>
                                        <BSCTypography
                                            variant="body2"
                                            align="center"
                                            onClick={() => {
                                                handleClose();
                                                history.push({
                                                    pathname: x['url'],
                                                });
                                            }}
                                            label={x['title']}
                                        />
                                        <BSCDivider />
                                    </div>
                                ))}
                            </>
                        )}
                        <div style={{textAlign: 'center'}}>
                            <BSCButton
                                style={{padding: 0}}
                                color="secondary"
                                variant="text"
                                align="center"
                                component={Link}
                                onClick={onPortalChange}
                                to="/"
                            >
                                <BSCTypography
                                    align="center"
                                    variant="body2"
                                    className={classes.capitalCase}
                                    label="Return to Marketplace"
                                />
                            </BSCButton>
                        </div>
                        <BSCDivider />
                        <div style={{textAlign: 'center'}}>
                            {isAuth ? (
                                <SignOutButton style={{padding: 0}} className={classes.capitalCase} size16 />
                            ) : (
                                <SellerSignInButton
                                    style={{padding: 0}}
                                    userMenuLoading={userMenuLoading}
                                    className={classes.capitalCase}
                                    userMenu={userMenu}
                                />
                            )}
                        </div>
                    </Box>
                </Drawer>
            }
            <MobileDrawer
                open={open}
                menus={menus}
                onClose={handleClose}
                menusLoading={menusLoading}
                currentMenuStep={currentMenuStep}
                setCurrentMenuStep={setCurrentMenuStep}
            />
        </>
    );
}

export default memo(SellerPWANav);
