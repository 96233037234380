import React from 'react';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import Offers from './offers';

const SellerOffers = () => {
    return (
        <BSCContainer variant="page">
            <Offers />
        </BSCContainer>
    );
};

export default SellerOffers;
