import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {CartRequestActionEnum} from '../../../../client/openapitools/marketplace';
import {BSCErrorAlert} from '../../../../features/common/bscAlert/bscAlert';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCGridTableRow from '../../../../features/common/bscGridTableRow/bscGridTableRow';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useReduxCart from '../../../../features/hooks/redux/cart/useReduxCart';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import ClearCartConfirmationModal from './clearCartConfirmationModal';
import NotSignedInModal from './notSignedInModal';

const useStyles = makeStyles(() => ({
    headerStyle: {
        fontWeight: 400,
    },
    font14: {
        fontSize: '14px',
    },
    fontBlack: {
        fontSize: '14px',
        color: 'black!important' as 'black',
    },
    fontBlackMarginBottom: {
        fontSize: '14px',
        color: 'black!important' as 'black',
        marginBottom: 4,
    },
    clearCart: {
        fontSize: '12px',
        fontWeight: 'bold',
        paddingTop: 4,
        cursor: 'pointer',
    },
}));

function MyCart({
    noOfItems,
    noOfPackages,
    price,
    shippingCost,
    totalPrice,
    isLowDollarOnSeller,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onOrderSubmit,
    estimatedSalesTax,
    loading,
    buttonLabel = 'Place Order',
    disabled = false,
}) {
    const classes = useStyles();
    const {instance} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const isCheckoutInRoute = useRouteMatch('/cart/checkout');
    const lowDollarAmount = process.env.REACT_APP_MINIMUM_PURCHASE_SINGLE_SELLER;
    const [showTax, setShowTax] = useState(false);
    const [modalOpen, toggleModalOpen] = useState(false);
    const [notSignedInModalOpen, toggleNotSignedInModalOpen] = useState(false);
    const {getCartSessionId} = useReduxUser();
    const {cartState, deleteCartAll} = useReduxCart();

    const handleDeleteCartAll = useCallback(
        cartId => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                deleteCartAll({
                                    id: cartId,
                                    cartSessionId: getCartSessionId(),
                                    apiConfig,
                                    action: CartRequestActionEnum.Delete,
                                });
                                addToast({
                                    severity: 'success',
                                    message: 'Cart successfully cleared',
                                });
                                setTimeout(() => {
                                    window.location.reload();
                                }, 3000);
                            } catch (error) {
                                console.error('Error deleting cart', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'Delete cart',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            } else {
                try {
                    deleteCartAll({
                        id: cartId,
                        cartSessionId: getCartSessionId(),
                        apiConfig: apiAuthConfig,
                        action: CartRequestActionEnum.Delete,
                    });
                    addToast({
                        severity: 'success',
                        message: 'Cart successfully cleared',
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } catch (error) {
                    console.error('Error deleting cart', error);
                    addToast({
                        severity: 'error',
                        message: 'An error has occurred.',
                        contextKey: 'Delete cart',
                    });
                }
            }
        },
        [deleteCartAll]
    );

    const handleClose = isClearCard => {
        toggleModalOpen(false);
        if (isClearCard) {
            handleDeleteCartAll(cartState.cartId);
        }
    };

    const handleClearCart = () => {
        toggleModalOpen(true);
    };

    const handleNotSignedInClose = () => {
        toggleNotSignedInModalOpen(false);
    };

    const checkForSignedIn = () => {
        if (isAuthenticated) {
            onOrderSubmit();
        } else {
            toggleNotSignedInModalOpen(true);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-empty
        if (disabled) {
        }
    }, [disabled]);

    useEffect(() => {
        if (estimatedSalesTax && estimatedSalesTax !== '0.00' && isCheckoutInRoute) {
            setShowTax(true);
        }
    }, [estimatedSalesTax]);

    useEffect(() => {
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
    }, []);

    return (
        <>
            <BSCCardBox>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <BSCTypography uppercase variant="h4" capitalize label="My Cart" className={classes.headerStyle} />
                    </Grid>
                    <Grid item>
                        <BSCTypography uppercase label="Clear Cart" className={classes.clearCart} onClick={handleClearCart} />
                    </Grid>
                </Grid>
                <BSCDivider />
                <BSCGridTableRow gutterBottom col8="Package(s):" col4={noOfPackages} className={classes.fontBlack} />
                <BSCGridTableRow col8="Number of Item(s):" col4={noOfItems} className={classes.fontBlack} />
                <BSCDivider style={{margin: '16px 0'}} />
                <BSCGridTableRow
                    gutterBottom
                    col8="Item(s):"
                    col4={<BSCCurrency amount={price} className={classes.fontBlack} />}
                    className={classes.fontBlack}
                />
                <BSCGridTableRow
                    col8="Shipping:"
                    gutterBottom={showTax}
                    className={isCheckoutInRoute ? classes.fontBlack : classes.fontBlackMarginBottom}
                    col4={<BSCCurrency amount={shippingCost} className={classes.fontBlack} />}
                />
                {showTax && (
                    <BSCGridTableRow
                        col8="Est. Sales Tax:"
                        className={classes.fontBlack}
                        col4={<BSCCurrency amount={estimatedSalesTax} className={classes.fontBlack} />}
                    />
                )}
                <BSCDivider style={{margin: '16px 0'}} />
                <BSCGridTableRow
                    gutterBottom
                    col8={<BSCTypography semibold label="Total:" className={classes.fontBlack} />}
                    col4={<BSCCurrency amount={totalPrice} semibold className={classes.fontBlack} />}
                    className={classes.fontBlack}
                />
                {isLowDollarOnSeller && (
                    <BSCErrorAlert icon={false} message={`All Seller Packages Must Exceed $${currencyFormatter(lowDollarAmount)}`} />
                )}
                <BSCButton
                    style={{marginTop: 5}}
                    disabled={disabled}
                    className={classes.font14}
                    loading={loading}
                    onClick={checkForSignedIn}
                    fullWidth
                    color="primary"
                >
                    {buttonLabel}
                </BSCButton>
            </BSCCardBox>

            <ClearCartConfirmationModal open={modalOpen} onClose={(e: boolean) => handleClose(e)} />
            <NotSignedInModal open={notSignedInModalOpen} onClose={handleNotSignedInClose} />
        </>
    );
}

export default memo(MyCart);
