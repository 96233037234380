import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as constants from '../../../../../shared/constants';
import SellCard from './sellCard';
import {Product, ProductConfig, AccountApi} from '../../../../../client/openapitools/seller';

import {useParams, useHistory} from 'react-router';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../authConfig';
import {Configuration} from '../../../../../client/openapitools/common';

interface SellYourCardEditProps {
    loading: boolean;
    data: Product;
    sellerSettings: ProductConfig;
    sellerCardInstance: string;
    sellerCardData: Product;
    isVintage: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SellYourCardEdit = ({loading, data, sellerSettings, sellerCardInstance, sellerCardData, isVintage}: SellYourCardEditProps) => {
    const {apiAuthConfig} = useAuthState();
    const history = useHistory();
    const dispatch = useDispatch();
    const {addToast} = useReduxToast();
    const {instance} = useMsal();
    const authFunc = useAuthFunctions();
    const {cardId} = useParams<{cardId: string}>();
    let product = Object.assign({}, data);

    const [sportId, setSportId] = useState(null);
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (cardId && account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const accountApi = new AccountApi(apiConfig);
                            const sellerInfoResponse = await accountApi.getCardListings(cardId);
                            if (sellerInfoResponse.status === 200) {
                                if (sportId === null) {
                                    setSportId(sellerInfoResponse.data.sport);
                                }
                            }
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error deleting Seller Card Information',
                                contextKey: 'Sell Your Card',
                            });
                            setDeleteCardLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [cardId]);

    const [updateCardLoading, setUpdateCardLoading] = useState(false);
    const update = (formState, data) => {
        // updateProduct({...data, ...formState});
        const account = instance.getActiveAccount();
        if (cardId && account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setUpdateCardLoading(true);
                            const accountApi = new AccountApi(apiConfig);
                            data['sportId'] = sportId;
                            const sellerCardInformation = {
                                action: 'update',
                                listing: {...data, availableQuantity: formState.quantity, price: formState.price},
                            };
                            const sellerInfoResponse = await accountApi.updateSellerCardInformation(sellerCardInformation, cardId);
                            if (sellerInfoResponse.status === 200) {
                                setUpdateCardLoading(false);
                                addToast({
                                    severity: 'success',
                                    message: 'Listing Updated',
                                });
                                const tempUpdatedProducts = sellerInfoResponse.data.listings;
                                const tempUpdatedProduct: Product | undefined = tempUpdatedProducts.find(
                                    listing => listing.productId === product.productId
                                );
                                if (tempUpdatedProduct) {
                                    product = Object.assign({}, tempUpdatedProduct);
                                    dispatch({
                                        type: constants.CURRENT_LISTING_CARD,
                                        payload: {currentListingCard: tempUpdatedProduct},
                                    });
                                }
                                history.goBack();
                            }
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Error updating Seller Card Information',
                                contextKey: 'Sell Your Card',
                            });
                            setUpdateCardLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const [deleteCardLoading, setDeleteCardLoading] = useState(false);
    const deleteCard = data => {
        const account = instance.getActiveAccount();
        if (cardId && account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setDeleteCardLoading(true);
                            const accountApi = new AccountApi(apiConfig);
                            const sellerCardInformation = {
                                action: 'delete',
                                listing: {...data, availableQuantity: data.quantity},
                            };
                            const sellerInfoResponse = await accountApi.updateSellerCardInformation(sellerCardInformation, cardId);
                            if (sellerInfoResponse.status === 200) {
                                setDeleteCardLoading(false);
                                addToast({
                                    severity: 'success',
                                    message: 'Listing Deleted',
                                });
                                window.location.reload();
                            }
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error deleting Seller Card Information',
                                contextKey: 'Sell Your Card',
                            });
                            setDeleteCardLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    return (
        <SellCard
            isFormDisabled={true}
            loading={loading || updateCardLoading || deleteCardLoading}
            isVintage={isVintage}
            setting={sellerSettings}
            product={product}
            sellerCardData={sellerCardData}
            leftButtonLabel={'Delete'}
            rightButtonLabel={'Save'}
            onFormSubmit={update}
            onLeftButtonClick={() => deleteCard(data)}
            disableGrades={false}
        />
    );
};

export default SellYourCardEdit;
