/* tslint:disable */
/* eslint-disable */
/**
 * Search API
 * All Search APIs on marketplace and seller portal.
 *
 * The version of the OpenAPI document: 0.9.30
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AggregationItem
 */
export interface AggregationItem {
    /**
     * 
     * @type {string}
     * @memberof AggregationItem
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof AggregationItem
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof AggregationItem
     */
    'slug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AggregationItem
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface AppliedFilter
 */
export interface AppliedFilter {
    /**
     * 
     * @type {string}
     * @memberof AppliedFilter
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppliedFilter
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppliedFilter
     */
    'slug'?: string;
}
/**
 * 
 * @export
 * @interface BulkUploadCard
 */
export interface BulkUploadCard {
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'setName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'players'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'cardNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'playerAttribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'imgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'imgBack'?: string;
    /**
     * 
     * @type {number}
     * @memberof BulkUploadCard
     */
    'cardNoOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUploadCard
     */
    'cardNoSequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadCard
     */
    'cardNoSort'?: string;
}
/**
 * 
 * @export
 * @interface BulkUploadDeactivateCriteria
 */
export interface BulkUploadDeactivateCriteria {
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof BulkUploadDeactivateCriteria
     */
    'filters'?: { [key: string]: Set<string>; };
    /**
     * 
     * @type {string}
     * @memberof BulkUploadDeactivateCriteria
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUploadDeactivateCriteria
     */
    'condition'?: string;
}
/**
 * 
 * @export
 * @interface BulkUploadFilterRequest
 */
export interface BulkUploadFilterRequest {
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof BulkUploadFilterRequest
     */
    'filters'?: { [key: string]: Set<string>; };
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'setName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'players'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'cardNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'variant'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'variantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'sport'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'year'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'rawLeastPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'gradedLeastPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttributeDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'stockImgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'stockImgBack'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'totalListings'?: string;
}
/**
 * 
 * @export
 * @interface CatalogCard
 */
export interface CatalogCard {
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'refCardId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'refSetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'year'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'sport'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'sportSlug'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'setName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'setNameSansYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'setNameSlug'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogCard
     */
    'players'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogCard
     */
    'playersSlug'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogCard
     */
    'playerAttribute'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogCard
     */
    'playerAttributeAlias'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'playerAttributeDesc'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogCard
     */
    'playerAttributeSlug'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogCard
     */
    'teamName'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogCard
     */
    'teamSlug'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'variantSlug'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'variantNameSlug'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'variant'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'variantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'cardNo'?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'cardNoOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'cardNoSequence'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'rawLeastPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'gradedLeastPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'totalListings'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'releaseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'productType'?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof CatalogCard
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {Array<Seller>}
     * @memberof CatalogCard
     */
    'sellers'?: Array<Seller>;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'defaultOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogCard
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'searchText'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'searchTextFuzzy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'lastUpdatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'lastInventoryUpdatedTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'sortPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogCard
     */
    'sellerQty'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'stockImgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogCard
     */
    'stockImgBack'?: string;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'sport'?: string;
    /**
     * 
     * @type {Array<Card>}
     * @memberof Category
     */
    'cards'?: Array<Card>;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {Array<string>}
     * @memberof Condition
     */
    'raw'?: Array<string>;
    /**
     * 
     * @type {Graded}
     * @memberof Condition
     */
    'graded'?: Graded;
}
/**
 * 
 * @export
 * @interface FeatureCard
 */
export interface FeatureCard {
    /**
     * 
     * @type {Category}
     * @memberof FeatureCard
     */
    'category'?: Category;
}
/**
 * 
 * @export
 * @interface Graded
 */
export interface Graded {
    /**
     * 
     * @type {Array<string>}
     * @memberof Graded
     */
    'company'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Graded
     */
    'rangeStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof Graded
     */
    'rangeEnd'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Graded
     */
    'letterGrades'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'slug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'productType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'rawCondition'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'grade'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'gradingCompany'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'quantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'proSeller'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'updatedTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'complete'?: boolean;
}
/**
 * 
 * @export
 * @interface ProductCriteria
 */
export interface ProductCriteria {
    /**
     * 
     * @type {string}
     * @memberof ProductCriteria
     */
    'type'?: string;
    /**
     * 
     * @type {Condition}
     * @memberof ProductCriteria
     */
    'condition'?: Condition;
    /**
     * 
     * @type {number}
     * @memberof ProductCriteria
     */
    'priceRangeStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCriteria
     */
    'priceRangeEnd'?: number;
}
/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'resultId'?: string;
    /**
     * 
     * @type {Array<CatalogCard>}
     * @memberof SearchResponse
     */
    'results'?: Array<CatalogCard>;
    /**
     * 
     * @type {{ [key: string]: Array<AggregationItem>; }}
     * @memberof SearchResponse
     */
    'aggregations'?: { [key: string]: Array<AggregationItem>; };
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    'totalResults'?: number;
    /**
     * 
     * @type {Array<AppliedFilter>}
     * @memberof SearchResponse
     */
    'appliedFilters'?: Array<AppliedFilter>;
}
/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'sellerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    'sortPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    'quantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    'updatedTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    'complete'?: boolean;
}
/**
 * 
 * @export
 * @interface SellerFilter
 */
export interface SellerFilter {
    /**
     * 
     * @type {string}
     * @memberof SellerFilter
     */
    'sellerKey'?: string;
}
/**
 * 
 * @export
 * @interface SellerSearchRequest
 */
export interface SellerSearchRequest {
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof SellerSearchRequest
     */
    'filters'?: { [key: string]: Set<string>; };
    /**
     * 
     * @type {number}
     * @memberof SellerSearchRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerSearchRequest
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerSearchRequest
     */
    'sort'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerSearchRequest
     */
    'inStock'?: boolean;
    /**
     * 
     * @type {ProductCriteria}
     * @memberof SellerSearchRequest
     */
    'productCriteria'?: ProductCriteria;
    /**
     * 
     * @type {Array<AppliedFilter>}
     * @memberof SellerSearchRequest
     */
    'appliedFilters'?: Array<AppliedFilter>;
    /**
     * 
     * @type {boolean}
     * @memberof SellerSearchRequest
     */
    'myInventory'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SellerSearchRequest
     */
    'sellerId'?: string;
}
/**
 * 
 * @export
 * @interface ShopAggregations
 */
export interface ShopAggregations {
    /**
     * 
     * @type {Array<Item>}
     * @memberof ShopAggregations
     */
    'set'?: Array<Item>;
    /**
     * 
     * @type {Array<Item>}
     * @memberof ShopAggregations
     */
    'year'?: Array<Item>;
    /**
     * 
     * @type {Array<Item>}
     * @memberof ShopAggregations
     */
    'team'?: Array<Item>;
    /**
     * 
     * @type {Array<Item>}
     * @memberof ShopAggregations
     */
    'sport'?: Array<Item>;
}
/**
 * 
 * @export
 * @interface ShopSearchRequest
 */
export interface ShopSearchRequest {
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof ShopSearchRequest
     */
    'filters'?: { [key: string]: Set<string>; };
    /**
     * 
     * @type {number}
     * @memberof ShopSearchRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShopSearchRequest
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShopSearchRequest
     */
    'sort'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopSearchRequest
     */
    'inStock'?: boolean;
    /**
     * 
     * @type {ProductCriteria}
     * @memberof ShopSearchRequest
     */
    'productCriteria'?: ProductCriteria;
    /**
     * 
     * @type {Array<AppliedFilter>}
     * @memberof ShopSearchRequest
     */
    'appliedFilters'?: Array<AppliedFilter>;
    /**
     * 
     * @type {SellerFilter}
     * @memberof ShopSearchRequest
     */
    'listingSearch'?: SellerFilter;
}
/**
 * 
 * @export
 * @interface ShopSearchResponseSwagger
 */
export interface ShopSearchResponseSwagger {
    /**
     * 
     * @type {string}
     * @memberof ShopSearchResponseSwagger
     */
    'resultId'?: string;
    /**
     * 
     * @type {Array<CatalogCard>}
     * @memberof ShopSearchResponseSwagger
     */
    'results'?: Array<CatalogCard>;
    /**
     * 
     * @type {ShopAggregations}
     * @memberof ShopSearchResponseSwagger
     */
    'aggregations'?: ShopAggregations;
    /**
     * 
     * @type {number}
     * @memberof ShopSearchResponseSwagger
     */
    'totalResults'?: number;
}

/**
 * BulkUploadControllerApi - axios parameter creator
 * @export
 */
export const BulkUploadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards: async (bulkUploadFilterRequest?: BulkUploadFilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/search/bulk-upload/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUploadFilterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkUploadDeactivateCriteria} bulkUploadDeactivateCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForDeactivation: async (bulkUploadDeactivateCriteria?: BulkUploadDeactivateCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/search/bulk-upload/products-for-deactivation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUploadDeactivateCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkUploadControllerApi - functional programming interface
 * @export
 */
export const BulkUploadControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkUploadControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCards(bulkUploadFilterRequest?: BulkUploadFilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkUploadCard>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCards(bulkUploadFilterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkUploadDeactivateCriteria} bulkUploadDeactivateCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForDeactivation(bulkUploadDeactivateCriteria?: BulkUploadDeactivateCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForDeactivation(bulkUploadDeactivateCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkUploadControllerApi - factory interface
 * @export
 */
export const BulkUploadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkUploadControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(bulkUploadFilterRequest?: BulkUploadFilterRequest, options?: undefined): AxiosPromise<Array<BulkUploadCard>> {
            return localVarFp.getCards(bulkUploadFilterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkUploadDeactivateCriteria} bulkUploadDeactivateCriteria 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForDeactivation(bulkUploadDeactivateCriteria?: BulkUploadDeactivateCriteria, options?: undefined): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.getProductsForDeactivation(bulkUploadDeactivateCriteria, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkUploadControllerApi - object-oriented interface
 * @export
 * @class BulkUploadControllerApi
 * @extends {BaseAPI}
 */
export class BulkUploadControllerApi extends BaseAPI {
    /**
     * 
     * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUploadControllerApi
     */
    public getCards = (bulkUploadFilterRequest?: BulkUploadFilterRequest, options?: AxiosRequestConfig) => {
        return BulkUploadControllerApiFp(this.configuration).getCards(bulkUploadFilterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkUploadDeactivateCriteria} bulkUploadDeactivateCriteria 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUploadControllerApi
     */
    public getProductsForDeactivation = (bulkUploadDeactivateCriteria?: BulkUploadDeactivateCriteria, options?: AxiosRequestConfig) => {
        return BulkUploadControllerApiFp(this.configuration).getProductsForDeactivation(bulkUploadDeactivateCriteria, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketplaceApi - axios parameter creator
 * @export
 */
export const MarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get suggestions for a given string
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSuggestions: async (q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/search/shop/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureCards: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/search/shop/featureCards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search results for a given  search request
         * @param {ShopSearchRequest} shopSearchRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults: async (shopSearchRequest?: ShopSearchRequest, q?: ModelString, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/search/shop/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceApi - functional programming interface
 * @export
 */
export const MarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get suggestions for a given string
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSuggestions(q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSuggestions(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureCards(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureCard>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureCards(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search results for a given  search request
         * @param {ShopSearchRequest} shopSearchRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResults(shopSearchRequest?: ShopSearchRequest, q?: ModelString, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResults(shopSearchRequest, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketplaceApi - factory interface
 * @export
 */
export const MarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get suggestions for a given string
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSuggestions(q?: string, options?: undefined): AxiosPromise<CatalogCard> {
            return localVarFp.fetchSuggestions(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureCards(options?: undefined): AxiosPromise<Array<FeatureCard>> {
            return localVarFp.getFeatureCards(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search results for a given  search request
         * @param {ShopSearchRequest} shopSearchRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults(shopSearchRequest?: ShopSearchRequest, q?: ModelString, options?: undefined): AxiosPromise<SearchResponse> {
            return localVarFp.getResults(shopSearchRequest, q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketplaceApi - object-oriented interface
 * @export
 * @class MarketplaceApi
 * @extends {BaseAPI}
 */
export class MarketplaceApi extends BaseAPI {
    /**
     * 
     * @summary Get suggestions for a given string
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public fetchSuggestions = (q?: string, options?: AxiosRequestConfig) => {
        return MarketplaceApiFp(this.configuration).fetchSuggestions(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getFeatureCards = (options?: AxiosRequestConfig) => {
        return MarketplaceApiFp(this.configuration).getFeatureCards(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search results for a given  search request
     * @param {ShopSearchRequest} shopSearchRequest 
     * @param {ModelString} q Selected/entered query string slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getResults = (shopSearchRequest?: ShopSearchRequest, q?: ModelString, options?: AxiosRequestConfig) => {
        return MarketplaceApiFp(this.configuration).getResults(shopSearchRequest, q, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SellerApi - axios parameter creator
 * @export
 */
export const SellerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get filter results for a bulk upload
         * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilters: async (bulkUploadFilterRequest?: BulkUploadFilterRequest, q?: ModelString, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/search/bulk-upload/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUploadFilterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search results for a given  search request
         * @param {SellerSearchRequest} sellerSearchRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults1: async (sellerSearchRequest?: SellerSearchRequest, q?: ModelString, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/search/seller/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerApi - functional programming interface
 * @export
 */
export const SellerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get filter results for a bulk upload
         * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilters(bulkUploadFilterRequest?: BulkUploadFilterRequest, q?: ModelString, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopSearchResponseSwagger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilters(bulkUploadFilterRequest, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search results for a given  search request
         * @param {SellerSearchRequest} sellerSearchRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResults1(sellerSearchRequest?: SellerSearchRequest, q?: ModelString, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResults1(sellerSearchRequest, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerApi - factory interface
 * @export
 */
export const SellerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get filter results for a bulk upload
         * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilters(bulkUploadFilterRequest?: BulkUploadFilterRequest, q?: ModelString, options?: undefined): AxiosPromise<ShopSearchResponseSwagger> {
            return localVarFp.getFilters(bulkUploadFilterRequest, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search results for a given  search request
         * @param {SellerSearchRequest} sellerSearchRequest 
         * @param {ModelString} q Selected/entered query string slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults1(sellerSearchRequest?: SellerSearchRequest, q?: ModelString, options?: undefined): AxiosPromise<SearchResponse> {
            return localVarFp.getResults1(sellerSearchRequest, q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerApi - object-oriented interface
 * @export
 * @class SellerApi
 * @extends {BaseAPI}
 */
export class SellerApi extends BaseAPI {
    /**
     * 
     * @summary Get filter results for a bulk upload
     * @param {BulkUploadFilterRequest} bulkUploadFilterRequest 
     * @param {ModelString} q Selected/entered query string slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public getFilters = (bulkUploadFilterRequest?: BulkUploadFilterRequest, q?: ModelString, options?: AxiosRequestConfig) => {
        return SellerApiFp(this.configuration).getFilters(bulkUploadFilterRequest, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search results for a given  search request
     * @param {SellerSearchRequest} sellerSearchRequest 
     * @param {ModelString} q Selected/entered query string slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public getResults1 = (sellerSearchRequest?: SellerSearchRequest, q?: ModelString, options?: AxiosRequestConfig) => {
        return SellerApiFp(this.configuration).getResults1(sellerSearchRequest, q, options).then((request) => request(this.axios, this.basePath));
    }
}


