import {makeStyles} from '@material-ui/core/styles';
import {DatePicker} from '@material-ui/pickers';
import classNames from 'classnames';

import {Moment} from 'moment';
import {useCallback, useState} from 'react';
import {validatedSchema} from '../../../@types/validations/validations';

const useStyles = makeStyles(() => ({root: {}}));

export interface BSCDatePickerProps {
    inputKey: string;
    onChange: (date) => void;
    onKeyDown: (date) => void;
    formStateValidations?: validatedSchema;
    placeholder?: null | string;
    value?: null | string | number;
    label?: null | string;
    error?: undefined | string | boolean;
}

function BSCDatePicker({
    onChange,
    onKeyDown,
    placeholder,
    value = null,
    label,
    error,
    formStateValidations,
    inputKey,
    emptyLabel,
    ...otherProps
}: BSCDatePickerProps) {
    const classes = useStyles();
    const [errors, setErrors] = useState(null);

    const handleChange = useCallback(
        (date: Moment) => {
            const formattedDate = date.format();
            if (formattedDate !== 'Invalid date') {
                onChange({
                    target: {name: inputKey, value: formattedDate},
                });
                setErrors(null);
            } else {
                setErrors(formattedDate);
            }
        },
        [onChange, inputKey]
    );

    return (
        <DatePicker
            {...otherProps}
            className={classNames(classes.root)}
            disableToolbar
            variant="inline"
            onKeyDown={onKeyDown}
            inputVariant="outlined"
            format="MM/DD/yyyy"
            emptyLabel={emptyLabel ?? '-- / -- / ----'}
            //margin="normal"
            onChange={handleChange}
            autoOk
            placeholder={formStateValidations?.[inputKey]?.label ?? placeholder}
            name={inputKey || ''}
            value={formStateValidations?.[inputKey]?.value || value}
            label={errors ?? formStateValidations?.[inputKey]?.errors ?? label}
            error={Boolean(errors) ?? Boolean(formStateValidations?.[inputKey]?.errors) ?? Boolean(error)}
        />
    );
}

export default BSCDatePicker;
