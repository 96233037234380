/* eslint-disable @typescript-eslint/no-unused-vars */
import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    listWrapper: {
        padding: '8px 8px 8px 6px !important',
    },
    listWrapperContainer: {
        minHeight: 'calc(100vh - 300px)',
    },
    itemWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        marginBottom: 6,
    },
}));

interface SearchDashboardListProps {
    view: number;
    index: number;
    listings: [];
}

function SearchDashboardList({view, listings}: SearchDashboardListProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const clearAllRecent = () => {
        console.log('>>> clear all recent');
    };

    const [searchListings, setSearchListings] = useState([]);
    useEffect(() => {
        if (listings) {
            console.log('>>>', listings);
            // const tempListings = [];
            // listings.forEach(listing => {
            //     if (listing.criteria.query !== null) {
            //         const queryURI = listing.criteria.query.substring(1, listing.criteria.query.length);
            //         console.log('>>>', queryURI);
            //         const queryURIParams = queryURI.split('&');
            //         queryURIParams.forEach(queryURIParam => {
            //             console.log('>>>', queryURIParam);
            //             const tempListing = {};
            //             const queryURIKey = queryURIParam.split('=')[0];
            //             if (queryURIKey === 'q') {
            //                 const queryURIValue = queryURIParam.split('=')[1];
            //                 tempListing['listingTitle'] = queryURIValue;
            //                 tempListings.push(tempListing);
            //             }
            //         });

            // const spaceSwap = listing.criteria.filters.setName[0].replace(/-/g, ' ');
            // const properSetName = spaceSwap
            //     .toLowerCase()
            //     .split(' ')
            //     .map(word => word.charAt(0).toUpperCase() + word.substring(1))
            //     .join(' ');
            // const cardData = {
            //     setName: properSetName,
            // };
        }
        // });
        // console.log('>>>', tempListings);
        // }
    }, [listings]);

    return (
        <div className={classes.listWrapper}>
            <Grid container className={classes.listWrapperContainer}>
                <Grid item xs={12}>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <BSCTypography size16 style={{fontWeight: 700}}>
                                Searches:
                            </BSCTypography>
                        </Grid>
                        {/* {view === 0 && recentSearches.length > 0 && (
                            <>
                                {recentSearches.map((searchItem, index) => (
                                    <Grid key={index} item xs={12}>
                                        <Link to={'../../../search' + searchItem.searchQuery} style={{textDecoration: 'none'}}>
                                            <BSCTypography size14>{searchItem.searchPlayer}</BSCTypography>
                                        </Link>
                                    </Grid>
                                ))}
                                <Grid item xs={12} style={{paddingTop: 8}}>
                                    <BSCTypography size14 color="primary" onClick={clearAllRecent} label="Clear All" />
                                </Grid>
                            </>
                        )}
                        {view === 0 && savedSearches.length > 0 && (
                            <>
                                {savedSearches.map((searchItem, index) => (
                                    <Grid key={index} item xs={12}>
                                        <BSCTypography size14>{searchItem.searchPlayer}</BSCTypography>
                                    </Grid>
                                ))}
                            </>
                        )} */}
                    </Grid>
                </Grid>
                {view === 1 && (
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <BSCTypography size16 style={{fontWeight: 700}}>
                                    Sellers:
                                </BSCTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCTypography size14>item</BSCTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCTypography size14>item</BSCTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCTypography size14>item</BSCTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCTypography size14>item</BSCTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCTypography size14>item</BSCTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCTypography size14>item</BSCTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCTypography size14>item</BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

export default SearchDashboardList;
