interface BSCEditToggleProps {
    isEdit: boolean;
    trueChild: React.ReactNode;
    falseChild: React.ReactNode;
}

const BSCEditToggle = ({isEdit, trueChild, falseChild}: BSCEditToggleProps) => {
    if (isEdit) {
        return <>{trueChild}</>;
    } else {
        return <>{falseChild}</>;
    }
};

export default BSCEditToggle;
