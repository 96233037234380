import React, {useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useCardNormalizer} from '../../../../../client/common/card/cardNormalizer';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCCurrency from '../../../../../features/common/bscCurrency/bscCurrency';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import {Product} from '../../../../../client/openapitools/common/api';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(2),
        alignItems: 'center',
        // width: '100%',
    },
    cardImg: {
        width: 58,
        height: 60,
        objectFit: 'contain',
        marginRight: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            height: 80,
            width: 80,
        },
    },
    cardInfo: {
        textAlign: 'left',
    },
    capitalized: {
        textTransform: 'capitalize',
    },
}));
function OrderCard({card, flag, hasOffer = false}: {card: Product; flag: boolean; hasOffer?: boolean}) {
    const classes = useStyles();
    const {isMobile} = useScreenSnap();
    if (!card) return null;
    const normalizer = useCardNormalizer();

    const [isVintage, setIsVintage] = useState(false);
    useEffect(() => {
        if (card) {
            if (Number(card.card.year) <= 1980) {
                setIsVintage(true);
            } else {
                setIsVintage(false);
            }
        }
    }, [card]);

    return (
        <div className={classes.root}>
            <BSCImage
                src={Normalizer.getS3ImagePath(card.sellerImgFront)}
                className={classes.cardImg}
                style={hasOffer ? {width: 100, height: 'auto'} : {}}
            />
            <div className={classes.cardInfo}>
                <BSCTypography variant="body1" label={normalizer.getPlayerName(card.card)} size12={isMobile} />
                <BSCTypography variant="body1" label={normalizer.getSetAndVariant(card.card)} capitalize size12={isMobile} />
                <BSCTypography label={normalizer.getProductTypeAndCondition(card, isVintage)} size12={isMobile} />

                {card.price && flag && <BSCCurrency variant="body1" amount={card.price} size12={isMobile} />}
            </div>
        </div>
    );
}

export default OrderCard;
