import {Slide, useScrollTrigger, Paper} from '@material-ui/core';

interface BSCHidingHeaderBar {
    children;
    /**
     * y axis scroll position where the header should be set as fixed at the top of the screen
     * Set this to approximiatly the length of any content above the bar
     */
    relativePositionThreshold: number;
    /**
     * y axis scroll position where the header will start hiding on scroll towards the bottom of the page and showing when scrolling towards the top of the page
     */
    disapearingThreshold: number;
}

const BSCHidingHeaderBar = ({children, relativePositionThreshold, disapearingThreshold}) => {
    const isScrolledDown = useScrollTrigger({target: window, threshold: disapearingThreshold});

    const below200 = useScrollTrigger({target: window, threshold: relativePositionThreshold, disableHysteresis: true});

    return (
        <div style={below200 ? {position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 999, marginTop: 0} : {width: '100%'}}>
            <Slide appear={false} direction="down" in={!isScrolledDown} mountOnEnter={true} unmountOnExit={true}>
                <Paper>{children}</Paper>
            </Slide>
        </div>
    );
};

export default BSCHidingHeaderBar;
