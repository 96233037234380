import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {MenuCategory} from '../../@types/menus';
import topSellerClient from '../../client/s3/mpNavigation/topSellerClient';

interface NavigationState {
    loading: boolean;
    mpNavigation: MenuCategory[];
}

const initialState = {loading: false, mpNavigation: []} as NavigationState;

export const getTopSeller = createAsyncThunk('posts/getTopSeller', async () => {
    return await topSellerClient.getTopSeller();
});

const homePageSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        // omit existing reducers here
    },
    extraReducers: builder => {
        builder.addCase(getTopSeller.pending, state => {
            state.loading = true;
        });
        builder.addCase(getTopSeller.fulfilled, (state, action) => {
            state.loading = false;
            state.mpNavigation = action.payload;
        });
        builder.addCase(getTopSeller.rejected, state => {
            state.loading = false;
            state.mpNavigation = [];
        });
    },
});

export default homePageSlice.reducer;
