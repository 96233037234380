import {Modal} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    root: {display: 'flex', justifyContent: 'center', alignItems: 'center'},
    cardBoxWrap: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9);',
        maxHeight: '100vh',
    },
    closeIcon: {
        position: 'absolute',
        right: 32,
        top: 16,
    },
}));

function BSCEnlargedModal({open, onClose, children = null}) {
    const classes = useStyles();

    return (
        <Modal className={classes.root} open={open} onClose={onClose}>
            <div className={classes.cardBoxWrap}>
                <div style={{height: '100%', marginTop: 5}}>
                    <IconButton className={classes.closeIcon} color="default" aria-label="close" component="span" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>

                    {children}
                </div>
            </div>
        </Modal>
    );
}

export default BSCEnlargedModal;
