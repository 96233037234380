import {OfferResponse} from '../../../openapitools/common/api';
//import Normalizer from '../../normalizer/normalizer';
const OfferHistoryNormalizer = {
    getFilteredOfferHistory: (offerHistory: OfferResponse, search: String) =>
        offerHistory.history.filter(
            offer =>
                //TODO: Update the offer.sellerId to be the seller name once the data is coming back from the API.
                //This at least stops things from breaking
                offer.offerId.toLowerCase().includes(search.toLowerCase()) || offer.sellerId.toLowerCase().includes(search.toLowerCase())
        ),
    //Not sure if any of these are used anywhere but they are probably wrong with the API updates.

    //TODO: If these are used make sure to update to the right common objects.
    // getPagedAndFilteredHistory: (offerHistory, page, sizePerPage, search) => {
    //     const pagedHistory = Normalizer.getPagedResult(offerHistory, page, sizePerPage);
    //     return pagedHistory ? OfferHistoryNormalizer.getFilteredOfferHistory(pagedHistory, search) : null;
    // },
    getOffers: (offerHistory: OfferResponse) => offerHistory?.history,
    // getTotalOfferNum: offerHistory => offerHistory?.totalResults,
};

export default OfferHistoryNormalizer;
