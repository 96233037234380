import {s3Client} from '../../index';

const serviceUrls = {
    menu: 's3/mpNavigation',
};

const NavigationClient = {
    getMenus: () => s3Client.get(serviceUrls.menu).then(res => res.data),
    getUserMenus: async () => {
        return {
            dashboard: [
                {
                    title: 'Profile',
                    url: '/dashboard/profile',
                },
                {
                    title: 'Messages',
                    url: '/dashboard/messages',
                },
                {
                    title: 'Order History',
                    url: '/dashboard/orderHistory',
                },
                {
                    title: 'Offers',
                    url: '/dashboard/offers',
                },
                {
                    title: 'FAQ',
                    url: '/dashboard/faq',
                },
            ],
        };
    },
};

export default NavigationClient;
