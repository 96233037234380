import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCSelect from '../../../../../features/common/bscSelect/bscSelect';
import BSCRadioGroup from '../../../../../features/common/bscRadioGroup/bscRadioGroup';
import BSCRadio from '../../../../../features/common/bscRadio/bscRadio';
import {Hidden, FormControlLabel} from '@material-ui/core';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import {useLocation} from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        background: '#F5F8FA',
        boxShadow: '0px 0px 4px #B8C2CC',
        marginTop: theme.spacing(0.25),
        padding: '12px 0',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            padding: '0',
        },
    },
    wrapperMobile: {
        background: '#F5F8FA',
        boxShadow: '0px 0px 4px #B8C2CC',
        marginTop: theme.spacing(0.25),
        justifyContent: 'center',
    },
    menu: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        padding: theme.spacing(0.5, 2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5, 2),
        },
    },
    menuMobile: {
        padding: '8px 0 0',
    },
    pageSizeLabel: {
        display: 'flex',
        height: '48px',
        alignItems: 'center',
    },
    expandIcon: {
        color: '#EA3E2E',
    },
    marginRight: {marginRight: theme.spacing(1)},
    menuItem: {
        '&.MuiMenuItem-root': {
            fontSize: theme.typography.subtitle1.fontSize,
        },
        '& .MuiSelect-root': {
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },

    formWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginRight: theme.spacing(0),
            '& > *': {
                width: '100%',
            },
        },
        '& input': {
            background: theme.palette.common.white,
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(1),
        },
    },
    smallRadio: {
        '& .MuiFormControlLabel-label': {
            fontSize: 12,
        },
        marginRight: 16,
    },
    label: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(1),
        },
    },
}));
const SellerOrderResultsHeader = ({
    perPage,
    onPageSizeChange,
    unshippedCount,
    //  handleFilterChange,
    // formState,
    sellersFormStateValidations,
    registerFormChange,
    handleRadioSearch,
}) => {
    const classes = useStyles();
    const {isDesktop} = useScreenSnap();
    const location = useLocation();

    return (
        <Grid container direction="row" justifyContent="space-between" className={isDesktop ? classes.wrapper : classes.wrapperMobile}>
            <div className={isDesktop ? classes.menu : classes.menuMobile}>
                <div className={classes.formWrapper}>
                    <Hidden mdDown>
                        <BSCTypography className={classes.label} label="Order Status:" />
                    </Hidden>
                    <BSCRadioGroup
                        // className={classes.radioGroup}
                        inputKey="status"
                        value="-1"
                        onChange={e => {
                            registerFormChange(e);
                            handleRadioSearch(e);
                        }}
                        formStateValidations={sellersFormStateValidations}
                        start
                        noLabel={true}
                        style={!isDesktop ? {marginLeft: 0} : {}}
                    >
                        <FormControlLabel
                            className={classes.smallRadio}
                            value="-1"
                            control={<BSCRadio style={{padding: '9px 4px'}} />}
                            label="All"
                        />
                        <FormControlLabel
                            className={classes.smallRadio}
                            value="0"
                            control={<BSCRadio style={{padding: '9px 4px'}} />}
                            label={`Unshipped (${unshippedCount})`}
                        />
                        <FormControlLabel
                            className={classes.smallRadio}
                            value="1"
                            control={<BSCRadio style={{padding: '9px 4px'}} />}
                            label="Cancelled"
                        />
                        <FormControlLabel
                            className={classes.smallRadio}
                            value="2"
                            control={<BSCRadio style={{padding: '9px 4px'}} />}
                            label="Shipped"
                            style={{marginRight: 0}}
                        />
                    </BSCRadioGroup>
                </div>
            </div>
            {isDesktop && !location?.state?.orderNumber && (
                <div className={classes.menu}>
                    <BSCTypography variant="subtitle1" className={classes.marginRight} label="Result per page" capitalize />
                    <BSCSelect className={classes.menuItem} selectedSortValue={perPage} onSortChange={onPageSizeChange}>
                        <MenuItem className={classes.menuItem} value={10}>
                            10
                        </MenuItem>
                        <MenuItem className={classes.menuItem} value={25}>
                            25
                        </MenuItem>
                        <MenuItem className={classes.menuItem} value={50}>
                            50
                        </MenuItem>
                        <MenuItem className={classes.menuItem} value={100}>
                            100
                        </MenuItem>
                    </BSCSelect>
                </div>
            )}
        </Grid>
    );
};

export default SellerOrderResultsHeader;
