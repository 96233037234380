/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import BSCTextField from '../../../../../../../features/common/bscTextField/bscTextField';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        '& input': {
            padding: theme.spacing(1.25, 1.75),
        },
    },
    topMargin: {
        marginTop: '8px',
    },
    shippingTiersCell: {
        width: '14%',
        margin: '0 auto',
        borderRight: 'solid 1px #bdbdbd',
    },
    shippingTiersCellMobile: {
        width: '14%',
        margin: '0 auto',
        borderRight: 'solid 1px #bdbdbd',
        '& div > div >  input': {
            paddingLeft: 4,
            paddingRight: 0,
        },
    },
    shippingTiersLabel: {
        fontSize: 12,
        padding: 2,
        color: '#41474D',
    },
    shippingTierGraded: {
        '& div > div >  input': {
            paddingLeft: 4,
            paddingRight: 0,
        },
    },
    shippingTiersOptionsLabel: {
        fontSize: 12,
        padding: 2,
        marginTop: 7,
        color: '#41474D',
    },
    shippingTiersOptionsCheckbox: {
        paddingRight: 0,
        paddingTop: 6,
        display: 'inline-block',
    },
    ctas: {
        textAlign: 'left',
        '& p:first-child': {
            color: '#358856 !important',
        },
    },
}));

//

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShippingTiersSettings = ({isEdit = false, shippingTiers, onModification}) => {
    const classes = useStyles();
    const {isMobile} = useScreenSnap();
    const [shippingTier_XS, setShippingTier_XS] = useState('');
    const [shippingTier_S, setShippingTier_S] = useState('');
    const [shippingTier_M, setShippingTier_M] = useState('');
    const [shippingTier_L, setShippingTier_L] = useState('');
    const [shippingTier_XL, setShippingTier_XL] = useState('');
    const [shippingTierGraded, setShippingTierGraded] = useState('');
    const [shippingTierFree, setShippingTierFree] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hasFreeShippingTier, setHasFreeShippingTier] = useState(false);
    const re = /^(\d*\.{0,1}\d{0,2}$)/;

    const handleModification = () => {
        // eslint-disable-next-line @typescript-eslint/no-array-constructor
        const shippingSettings = new Array();
        shippingSettings.push({tierCode: 'XS', rate: parseFloat(shippingTier_XS ?? 0)});
        shippingSettings.push({tierCode: 'S', rate: parseFloat(shippingTier_S ?? 0)});
        shippingSettings.push({tierCode: 'M', rate: parseFloat(shippingTier_M ?? 0)});
        shippingSettings.push({tierCode: 'L', rate: parseFloat(shippingTier_L ?? 0)});
        shippingSettings.push({tierCode: 'XL', rate: parseFloat(shippingTier_XL ?? 0)});
        shippingSettings.push({tierCode: 'G', rate: parseFloat(shippingTierGraded ?? 0)});
        if (hasFreeShippingTier) {
            shippingSettings.push({tierCode: 'F', rate: parseFloat(shippingTierFree)});
        }
        onModification({shippingSettings});
    };

    const shippingRateFormatter = rate => {
        if (rate !== '') {
            const formattedRate = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(rate);
            return formattedRate.substring(1, formattedRate.length);
        }
        return rate;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputBlur = event => {
        let incomingValue = event.target.value;
        if (incomingValue === '') {
            incomingValue = '0.00';
        }
        switch (event.target.name) {
            case 'shippingTier_XS':
                setShippingTier_XS(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_S':
                setShippingTier_S(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_M':
                setShippingTier_M(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_L':
                setShippingTier_L(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_XL':
                setShippingTier_XL(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTierGraded':
                setShippingTierGraded(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTierFree':
                setShippingTierFree(shippingRateFormatter(incomingValue));
                break;
        }
        handleModification();
    };

    const handleFreeShippingClick = () => {
        setShippingTier_XS('0.00');
        setShippingTier_S('0.00');
        setShippingTier_M('0.00');
        setShippingTier_L('0.00');
        setShippingTier_XL('0.00');
        setShippingTierGraded('0.00');
    };

    const handleRecommendedRatesClick = () => {
        setShippingTier_XS('0.85');
        setShippingTier_S('1.35');
        setShippingTier_M('3.99');
        setShippingTier_L('4.99');
        setShippingTier_XL('5.99');
        setShippingTierGraded('3.99');
    };

    useEffect(() => {
        handleModification();
    }, [shippingTier_XS, shippingTier_S, shippingTier_M, shippingTier_L, shippingTier_XL, shippingTierGraded, hasFreeShippingTier]);

    useEffect(() => {
        if (shippingTiers) {
            shippingTiers.forEach(tier => {
                switch (tier.tierCode) {
                    case 'XS':
                        setShippingTier_XS(shippingRateFormatter(tier.rate));
                        break;
                    case 'S':
                        setShippingTier_S(shippingRateFormatter(tier.rate));
                        break;
                    case 'M':
                        setShippingTier_M(shippingRateFormatter(tier.rate));
                        break;
                    case 'L':
                        setShippingTier_L(shippingRateFormatter(tier.rate));
                        break;
                    case 'XL':
                        setShippingTier_XL(shippingRateFormatter(tier.rate));
                        break;
                    case 'G':
                        setShippingTierGraded(shippingRateFormatter(tier.rate));
                        break;
                    case 'F':
                        setShippingTierFree(shippingRateFormatter(tier.rate));
                        setHasFreeShippingTier(true);
                        break;
                }
            });
        }
    }, [shippingTiers]);

    return (
        <Grid container className={classes.main} direction="column">
            {isEdit && (
                <Grid item container className={classes.topMargin}>
                    <Grid item md={9}>
                        &nbsp;
                    </Grid>
                    <Grid item md={3}>
                        <div className={classes.ctas}>
                            <BSCTypography size12 bold style={{paddingTop: 2, cursor: 'pointer'}} onClick={handleFreeShippingClick}>
                                Use Free Shipping
                            </BSCTypography>
                            <BSCTypography
                                size12
                                bold
                                color="primary"
                                style={{paddingTop: 2, cursor: 'pointer'}}
                                onClick={handleRecommendedRatesClick}
                            >
                                Use Recommended Rates
                            </BSCTypography>
                        </div>
                    </Grid>
                </Grid>
            )}
            <Grid item style={{marginTop: 4, border: 'solid 2px #DAE0E6'}}>
                <Grid container md={12} style={{textAlign: 'center'}}>
                    <div className={classes.shippingTiersCell} style={{width: '28%'}}>
                        <BSCTypography className={classes.shippingTiersLabel}># of Raw Cards</BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={classes.shippingTiersLabel}>1 - 4</BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={classes.shippingTiersLabel}>5 - 12</BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={classes.shippingTiersLabel}>13 - 24</BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={classes.shippingTiersLabel}>25 - 49</BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell} style={{borderRight: 'none'}}>
                        <BSCTypography className={classes.shippingTiersLabel}>50+</BSCTypography>
                    </div>
                </Grid>
                <Grid container md={12} style={{textAlign: 'center', borderTop: 'solid 1px #DAE0E6'}}>
                    <div className={classes.shippingTiersCell} style={{width: '28%'}}>
                        <BSCTypography style={{paddingTop: 8, fontSize: 12, color: '#41474D'}}>Price</BSCTypography>
                    </div>
                    <div className={isMobile ? classes.shippingTiersCellMobile : classes.shippingTiersCell}>
                        <BSCTextField
                            disabled={!isEdit}
                            inputKey="shippingTier_XS"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_XS(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            value={shippingTier_XS}
                        ></BSCTextField>
                    </div>
                    <div className={isMobile ? classes.shippingTiersCellMobile : classes.shippingTiersCell}>
                        <BSCTextField
                            disabled={!isEdit}
                            inputKey="shippingTier_S"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_S(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            value={shippingTier_S}
                        ></BSCTextField>
                    </div>
                    <div className={isMobile ? classes.shippingTiersCellMobile : classes.shippingTiersCell}>
                        <BSCTextField
                            disabled={!isEdit}
                            inputKey="shippingTier_M"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_M(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            value={shippingTier_M}
                        ></BSCTextField>
                    </div>
                    <div className={isMobile ? classes.shippingTiersCellMobile : classes.shippingTiersCell}>
                        <BSCTextField
                            disabled={!isEdit}
                            inputKey="shippingTier_L"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_L(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            value={shippingTier_L}
                        ></BSCTextField>
                    </div>
                    <div className={isMobile ? classes.shippingTiersCellMobile : classes.shippingTiersCell} style={{borderRight: 'none'}}>
                        <BSCTextField
                            disabled={!isEdit}
                            inputKey="shippingTier_XL"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_XL(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            value={shippingTier_XL}
                        ></BSCTextField>
                    </div>
                </Grid>
            </Grid>

            <Grid item className={classes.topMargin}>
                <div style={{width: '100%', display: 'inline-flex', marginBottom: 24}}>
                    <div style={{width: '32%'}}>
                        <BSCTypography className={classes.shippingTiersOptionsLabel}>Price Per Graded Card:</BSCTypography>
                    </div>
                    <div className={isMobile ? classes.shippingTierGraded : null} style={{width: '15%'}}>
                        <BSCTextField
                            disabled={!isEdit}
                            inputKey="shippingTierGraded"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTierGraded(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            value={shippingTierGraded}
                        ></BSCTextField>
                    </div>
                    <div style={isMobile ? {width: '50%', textAlign: 'right'} : {width: '50%', textAlign: 'right', marginRight: 8}}>
                        <span style={isMobile ? {display: 'inline-flex', width: '20%'} : {display: 'inline-block', width: '10%'}}>
                            <Checkbox
                                disabled={!isEdit}
                                className={classes.shippingTiersOptionsCheckbox}
                                color="primary"
                                onChange={e => {
                                    setHasFreeShippingTier(e.target.checked);
                                    if (!e.target.checked) {
                                        setShippingTierFree('0.00');
                                    }
                                }}
                                checked={hasFreeShippingTier}
                            />
                        </span>
                        <span style={isMobile ? {display: 'inline-block', width: '64%'} : {display: 'inline-block', width: '42%'}}>
                            <BSCTypography className={classes.shippingTiersOptionsLabel}>Free Shipping Over:</BSCTypography>
                        </span>
                    </div>
                    <div className={classes.shippingTierGraded} style={{width: '15%', marginLeft: 4}}>
                        <BSCTextField
                            disabled={!isEdit || !hasFreeShippingTier}
                            inputKey="shippingTierFree"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTierFree(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            value={shippingTierFree}
                            style={hasFreeShippingTier ? {} : {backgroundColor: 'rgba(0, 0, 0, 0.12)'}}
                        ></BSCTextField>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default ShippingTiersSettings;
