/* eslint-disable @typescript-eslint/no-unused-vars */
import {FormControlLabel, ModalProps} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useCallback, useState} from 'react';
import FormValidationsClient from '../../../../client/s3/formValidations/formValidationsClient';
import FormValidationsNormalizer from '../../../../client/s3/formValidations/formValidationsNormalizer';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCRadio from '../../../../features/common/bscRadio/bscRadio';
import BSCRadioGroup from '../../../../features/common/bscRadioGroup/bscRadioGroup';
import BSCTextField from '../../../../features/common/bscTextField/bscTextField';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import useForm from '../../../../packages/core/src/hooks/useForm';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {ProductApi} from '../../../../client/openapitools/marketplace/api';

interface ReportModalProps extends Omit<ModalProps, 'children'> {
    listingId: Number;
    cardId: Number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiFormGroup-root': {
            marginLeft: 16,
        },
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    marginTop: {marginTop: theme.spacing(1.5)},
}));

function ReportModal({open, onClose, cardId, listingId, ...otherProps}: ReportModalProps) {
    const classes = useStyles();
    const {isAuth, userState} = useReduxUser();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const [productIssueLoading, setProductIssueLoading] = useState(false);
    const [issueDataAdded, setIssueDataAdded] = useState(null);

    const {loading, data: formValidations} = useQuery(() => FormValidationsClient.getFormValidations(), []);
    const modalValidtionsSchema = FormValidationsNormalizer.getReportModalValidationsSchema(formValidations);
    const {
        validatedSchema: formStateValidations,
        formState,
        formComplete,
        registerFormChange,
    } = useForm({validationSchema: modalValidtionsSchema});

    const reasons = {
        ['Getting report form data']: loading,
    };

    const handleSubmit = useCallback(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        setProductIssueLoading(true);
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        const issueData = {
                            cardId: cardId,
                            productId: listingId,
                            issueType: formState.reason,
                            comments: formState.comments,
                            userId: userState.id,
                        };
                        try {
                            const productApi = new ProductApi(apiConfig);
                            const response = await productApi.reportIssue(issueData);
                            if (response) {
                                setIssueDataAdded(response.data);
                                addToast({
                                    severity: 'success',
                                    message: 'Successfully added Report',
                                    contextKey: 'Product Issue',
                                });
                                onClose();
                            }
                            setProductIssueLoading(false);
                        } catch (error) {
                            console.error('Error reporting an issue', error);
                            addToast({
                                severity: 'error',
                                message: 'An error reporting an issue has occurred.',
                                contextKey: 'Product Issue',
                            });
                            setProductIssueLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [formState]);

    return (
        <BSCModal headerLabel="Report A Problem" hasCustomHeaderStyling={true} {...otherProps} open={open} onClose={onClose}>
            <div className={classes.root}>
                <BSCRadioGroup
                    column
                    className={classes.marginBottom}
                    inputKey="reason"
                    onChange={registerFormChange}
                    formStateValidations={formStateValidations}
                    start={true}
                    semicolon={true}
                >
                    <FormControlLabel
                        className={classes.marginTop}
                        value="incorrect_image"
                        control={<BSCRadio />}
                        label="Incorrect Image"
                    />
                    <FormControlLabel value="incorrect_product_details" control={<BSCRadio />} label="Incorrect Product Details" />
                    <FormControlLabel value="inappropriate_content" control={<BSCRadio />} label="Inappropriate Content" />
                    <FormControlLabel value="other_feedback" control={<BSCRadio />} label="Other Feedback" />
                </BSCRadioGroup>
                <BSCTextField
                    className={classes.marginBottom}
                    inputKey="comments"
                    fullWidth
                    onChange={registerFormChange}
                    multiline
                    formStateValidations={formStateValidations}
                />
                <BSCButton disabled={!formComplete} loading={productIssueLoading} color="primary" fullWidth onClick={handleSubmit}>
                    Report
                </BSCButton>
            </div>
        </BSCModal>
    );
}

export default ReportModal;
