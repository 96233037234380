import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import Grid from '@material-ui/core/Grid';
import useDateNormalizer from '../../../../../../../client/common/normalizer/dateTimeNormalizer';
import Normalizer from '../../../../../../../client/common/normalizer/normalizer';

const useStyles = makeStyles(() => ({
    ItemHeaderRoot: {
        padding: '8px',
    },
}));

function OrderHistoryItemHeader({order}) {
    const classes = useStyles();
    if (!order) {
        return null;
    }

    const dateNormalizer = useDateNormalizer();

    return (
        <>
            <Grid container className={classes.ItemHeaderRoot}>
                <Grid item container md={3}>
                    <Grid item lg={2} md={3} sm={2} xs={3}>
                        <BSCTypography label="Date: " semibold />
                    </Grid>
                    <Grid item lg={10} md={9} sm={10} xs={9}>
                        <BSCTypography label={dateNormalizer.getDisplayDate(order?.orderDate)} />
                    </Grid>
                </Grid>
                <Grid item container md={3}>
                    <Grid item lg={2} md={3} sm={2} xs={3}>
                        <BSCTypography label="Seller: " semibold />
                    </Grid>
                    <Grid item lg={10} md={9} sm={10} xs={9}>
                        <BSCTypography label={order?.seller?.sellerProfile?.sellerStoreName ?? ''} />
                    </Grid>
                </Grid>
                <Grid item container md={3}>
                    <Grid item lg={3} md={4} sm={2} xs={3}>
                        <BSCTypography label="Order #:" semibold />
                    </Grid>
                    <Grid item lg={9} md={8} sm={10} xs={9}>
                        <BSCTypography label={order.orderNo} />
                    </Grid>
                </Grid>
                <Grid item container md={3}>
                    <Grid item lg={3} md={3} sm={2} xs={3}>
                        <BSCTypography label="Status: " semibold />
                    </Grid>
                    <Grid item lg={9} md={9} sm={10} xs={9}>
                        <BSCTypography label={Normalizer.getOrderStatusDisplay(order?.orderStatus)} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default OrderHistoryItemHeader;
