import React from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../../../../features/common/skeletons/BSCTableRowSkeleton';
import classNames from 'classnames';
import OrderCard from '../../../components/orderCard';
import Grid from '@material-ui/core/Grid';
import BSCCurrency from '../../../../../../../features/common/bscCurrency/bscCurrency';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    header: {
        color: '#41474C',
        textAlign: 'center',
        padding: theme.spacing(1),
        background: '#DAE0E5',
        borderBottom: 'none',
        '& p': {
            fontSize: '14px',
            fontWeight: 400,
        },
    },
    td: {
        display: 'flex',
        height: '100%',
        border: '1.5px solid #B8C2CC',
        textAlign: 'center',
        background: '#F5F8FA',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
    },
    card: {
        justifyContent: 'flex-start',
        padding: 0,
    },
    win: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        '& p': {
            fontSize: '14px',
            fontWeight: 400,
        },
    },
}));

const OrderItemsTable = ({items, loading}) => {
    const classes = useStyles();

    return (
        <>
            {!items && !loading && <BSCTypography label="No items on order." />}
            {(items || loading) && (
                <div className={classes.root}>
                    <div className={classes.header}>
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <BSCTypography label="card details" bold capitalize />
                            </Grid>
                            <Grid item md={4}>
                                <div className={classes.win}>
                                    <BSCTypography label="price" bold capitalize />
                                </div>
                            </Grid>
                            <Grid item md={3}>
                                <div className={classes.win}>
                                    <BSCTypography label="quantity" bold capitalize />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        {!items && (
                            <Table>
                                <BSCTableRowSkeleton amountOfRows={2} amountOfCells={3} />
                            </Table>
                        )}
                        {items?.map((item, index) => {
                            return (
                                <Grid container key={item.card.cardNo + index}>
                                    <Grid item xs={12} md={5}>
                                        <div
                                            key="cardDetails"
                                            className={classNames(classes.td, classes.card)}
                                            style={{backgroundColor: '#fff'}}
                                        >
                                            <OrderCard card={item} flag={false} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className={classNames(classes.td, classes.win)} style={{backgroundColor: '#fff'}}>
                                            <BSCCurrency amount={item.price} size12 />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div className={classNames(classes.td, classes.win)} style={{backgroundColor: '#fff'}}>
                                            <BSCTypography label={item.orderQuantity} size12 />
                                            {/* item.card.quantity */}
                                        </div>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderItemsTable;
