import React, {useCallback, useEffect, useState} from 'react';
import * as constants from '../../../../../../../shared/constants';
import {useLocation, useParams, useRouteMatch} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Accordion, AccordionDetails, AccordionSummary, Hidden} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid/Grid';
import SendMessagePanel from './sendMessagePanel';
import OrderDetails from './orderDetails';
import Cards from './cards';
import RecentMessages from './recentMessages';
import {Conversation, ConversationApi} from '../../../../../../../client/openapitools/common/api';
import {OrderApi as MarketplaceOrderApi} from '../../../../../../../client/openapitools/marketplace/api';
import {OrderApi as SellerOrderApi} from '../../../../../../../client/openapitools/seller/api';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';
import {Menu, MenuItem} from '@material-ui/core';
import './messages.scss';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';
import {RootState} from '../../../../../../../redux/reducers';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        background: '#fff',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '4px',
        padding: theme.spacing(1.5, 2),
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        },
    },
    menu: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
    },
    content: {
        background: '#F5F8FA',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '8px',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(2.75),
        '& > MuiSelect-icon': {
            color: '#EA3E2E !important',
        },
    },
    select: {
        width: '100%',
        background: theme.palette.common.white,
        border: '1px solid #B8C2CC',
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        '& .MuiFilledInput-underline:after': {
            borderBottom: 'none',
        },
    },
    textfield: {
        fontFamily: 'Poppins',
        background: theme.palette.common.white,
        '& textarea': {
            minHeight: '100px',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.subtitle2.fontSize,
            },
        },
    },
    optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
    },
    hidden: {
        position: 'absolute',
        opacity: '0',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    uppperCase: {
        textTransform: 'uppercase',
    },
    pointer: {
        cursor: 'pointer',
    },
    formControl: {
        minWidth: 74,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #DAE0E6',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        borderRadius: '4px',
        marginLeft: theme.spacing(1),
    },
    pageSizeSelectForm: {
        minWidth: '100% !important',
        height: '48px',
        opacity: '0',
        overflow: 'hidden',
        margin: '0',
        position: 'absolute',
    },
    pageSizeLabel: {
        display: 'flex',
        height: '48px',
        alignItems: 'center',
    },
    attachedFileListWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    win: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    mobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    buttons: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    fullWidth: {
        width: 'calc(100vw - 32px)',
    },
    weight400: {
        fontWeight: 400,
    },
}));

function ReplyMessage({messageRef, isMarketPlace = true, orderUrl = null}) {
    const {messageId} = useParams<{messageId: string}>();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const location = useLocation();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const authFunc = useAuthFunctions();
    const classes = useStyles();
    const history = useHistory();
    const {isDesktop, isBelowTablet, isMobile} = useScreenSnap();
    const [buyerLoading, setBuyerLoading] = useState(false);
    const [sellerLoading, setSellerLoading] = useState(false);
    const {addToast} = useReduxToast();
    const [messageInfo, setMessageInfo] = useState<Conversation>();
    const [orderInfo, setOrderInfo] = useState<Order>();
    const dispatch = useDispatch();
    const isSellers = useRouteMatch('/sellers');

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (isSellers) {
            setLoading(sellerLoading);
        } else {
            setLoading(buyerLoading);
        }
    }, [buyerLoading, sellerLoading]);

    useEffect(() => {
        if (messageId) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                const conversationApi = new ConversationApi(apiConfig);
                                const sellerOrderApi = new SellerOrderApi(apiConfig);
                                const marketplaceOrderApi = new MarketplaceOrderApi(apiConfig);
                                if (isSellers) {
                                    setSellerLoading(true);
                                    const getSellerConvoResponse = await conversationApi.getSellerConversationById(messageId);
                                    if (getSellerConvoResponse.data) {
                                        setMessageInfo(getSellerConvoResponse.data);
                                        sellerOrderApi
                                            .getOrderById(getSellerConvoResponse.data.orderId)
                                            .then(y => {
                                                setOrderInfo(y.data);
                                                setSellerLoading(false);
                                            })
                                            .catch(err => {
                                                console.log('error', err);
                                                addToast({
                                                    severity: 'error',
                                                    message: 'Message contains invalid data.',
                                                });
                                                setSellerLoading(false);
                                                setTimeout(() => {
                                                    history.goBack();
                                                }, 3000);
                                            });
                                    }
                                } else {
                                    setBuyerLoading(true);
                                    const getBuyerConvoResponse = await conversationApi.getBuyerConversationById(messageId);
                                    if (getBuyerConvoResponse.data) {
                                        setMessageInfo(getBuyerConvoResponse.data);
                                        marketplaceOrderApi
                                            .getOrderById(getBuyerConvoResponse.data.orderId)
                                            .then(y => {
                                                setOrderInfo(y.data);
                                                setBuyerLoading(false);
                                            })
                                            .catch(err => {
                                                console.log('error', err);
                                                addToast({
                                                    severity: 'error',
                                                    message: 'Message contains invalid data.',
                                                });
                                                setBuyerLoading(false);
                                                setTimeout(() => {
                                                    history.goBack();
                                                }, 3000);
                                            });
                                    }
                                }
                            } catch (error) {
                                console.log(error);
                                addToast({
                                    severity: 'error',
                                    message: 'Failed to Create Message.',
                                    contextKey: 'Create Message',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    }, [apiAuthConfig]);

    const replyMessageFunc = async (formState, attachedFiles) => {
        const account = instance.getActiveAccount();
        const attachments = [];

        const info: Conversation = {
            fromMarketPlace: isMarketPlace,
            subjectKey: formState.subject,
            read: false,
            escalated: false,
            orderNo: orderInfo?.orderNo,
            orderId: orderInfo?.orderId,
            convId: messageId,
            fromUserId: isSellers ? orderInfo?.seller?.id : orderInfo?.buyer?.id,
            toUserId: isSellers ? orderInfo?.buyer?.id : orderInfo?.seller?.id,
            fromUserName: isSellers
                ? orderInfo?.seller?.sellerProfile?.sellerStoreName
                : `${orderInfo?.buyer?.firstName} ${orderInfo?.buyer?.lastName}`,
            messages: [
                {
                    fromUserId: isSellers ? orderInfo?.seller?.id : orderInfo?.buyer?.id,
                    toUserId: isSellers ? orderInfo?.buyer?.id : orderInfo?.seller?.id,
                    userNameFrom: isSellers
                        ? orderInfo?.seller?.sellerProfile?.sellerStoreName
                        : `${orderInfo?.buyer?.firstName} ${orderInfo?.buyer?.lastName}`,
                    content: formState.reply,
                    attachments: attachments,
                },
            ],
        };

        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const conversationApi = new ConversationApi(apiConfig);
                            if (attachedFiles && attachedFiles.some(() => true)) {
                                try {
                                    for (let index = 0; index < attachedFiles.length; index++) {
                                        await conversationApi.uploadMessageAttachment(messageId, attachedFiles[index]).then(x => {
                                            if (x.error) {
                                                throw new Error();
                                            }
                                            attachments.push(x.data);
                                        });
                                    }
                                    info.messages[0].attachments = attachments;
                                    if (isSellers) {
                                        await conversationApi.updateConversationByIdForSeller(info).then(() => {
                                            conversationApi.getSellerConversationById(messageId).then(x => {
                                                setMessageInfo(x.data);
                                            });
                                        });
                                    } else {
                                        await conversationApi.updateConversationByIdForBuyer(info).then(() => {
                                            conversationApi.getBuyerConversationById(messageId).then(x => {
                                                setMessageInfo(x.data);
                                            });
                                        });
                                    }
                                    addToast({
                                        severity: 'success',
                                        message: 'Successfully sent reply',
                                    });
                                    setTimeout(() => history.goBack(), 2000);
                                } catch (error) {
                                    console.log(error);
                                    addToast({
                                        severity: 'error',
                                        message: 'Failed to Attach files and create reply.',
                                        contextKey: 'Attach Files',
                                    });
                                }
                            } else {
                                if (isSellers) {
                                    await conversationApi.updateConversationByIdForSeller(info).then(() => {
                                        conversationApi.getSellerConversationById(messageId).then(x => {
                                            setMessageInfo(x.data);
                                        });
                                    });
                                } else {
                                    await conversationApi.updateConversationByIdForBuyer(info).then(() => {
                                        conversationApi.getBuyerConversationById(messageId).then(x => {
                                            setMessageInfo(x.data);
                                        });
                                    });
                                }
                                addToast({
                                    severity: 'success',
                                    message: 'Successfully sent reply',
                                });
                                setTimeout(() => history.goBack(), 2000);
                            }
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to Create Reply.',
                                contextKey: 'Create Reply',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const onMarkUnread = () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const conversationApi = new ConversationApi(apiConfig);
                            if (isSellers) {
                                await conversationApi.updateConversationByIdForSeller({convId: messageId, read: false});
                            } else {
                                await conversationApi.updateConversationByIdForBuyer({convId: messageId, read: false});
                                const tempBuyersMessageCount = messagesCount + 1;
                                setMessagesCount(tempBuyersMessageCount);
                                dispatch({
                                    type: constants.BUYER_MESSAGES_COUNT,
                                    payload: {buyerMessagesCount: tempBuyersMessageCount},
                                });
                            }
                            addToast({
                                severity: 'success',
                                message: 'Successfully marked message as unread',
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to mark message as unread.',
                                contextKey: 'Mark as Unread',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const onEscalate = () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const conversationApi = new ConversationApi(apiConfig);
                            if (isSellers) {
                                await conversationApi.updateConversationByIdForSeller({convId: messageId, escalated: true});
                            } else {
                                await conversationApi.updateConversationByIdForBuyer({convId: messageId, escalated: true});
                            }
                            addToast({
                                severity: 'success',
                                message: 'Successfully escalated the message',
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to mark message as unread.',
                                contextKey: 'Mark as Unread',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
    const handleMenuOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenMenu(event.currentTarget);
        },
        [setOpenMenu]
    );
    const handleMenuClose = useCallback(() => {
        setOpenMenu(null);
    }, [setOpenMenu]);

    const [messagesCount, setMessagesCount] = useState(0);
    useEffect(() => {
        let incomingMessagesCount = 0;
        if (marketplaceState) {
            if (marketplaceState.buyerMessagesCount !== '' && marketplaceState.buyerMessagesCount !== null) {
                incomingMessagesCount = parseInt(marketplaceState.buyerMessagesCount);
                setMessagesCount(parseInt(marketplaceState.buyerMessagesCount));
            }
        }
        if (location?.state?.isAlreadyRead === false) {
            incomingMessagesCount--;
            setMessagesCount(incomingMessagesCount);
            dispatch({
                type: constants.BUYER_MESSAGES_COUNT,
                payload: {buyerMessagesCount: incomingMessagesCount === 0 ? '' : incomingMessagesCount},
            });
        }
    }, []);

    return (
        <>
            <div className={classes.wrapper}>
                <Grid container direction="row" justifyContent="space-between">
                    <div className={classNames(classes.menu, classes.pointer)} onClick={() => history.goBack()}>
                        <KeyboardArrowLeftIcon />
                        <Hidden smDown>
                            <BSCTypography
                                label="Back To messages"
                                className={classes.weight400}
                                capitalize
                                variant="subtitle2"
                                color="textPrimary"
                            />
                        </Hidden>
                        <Hidden mdUp>
                            <BSCTypography label="Back" bold />
                        </Hidden>
                    </div>
                    <div className={classNames(classes.menu)}>
                        {isDesktop && (
                            <Grid container className={classes.buttons} spacing={1}>
                                <Grid item>
                                    <BSCButton color="secondary" onClick={onMarkUnread}>
                                        <BSCTypography label="mark as unread" capitalize size14 />
                                    </BSCButton>
                                </Grid>
                                <Grid item>
                                    <BSCButton fullWidth color="secondary" onClick={onEscalate}>
                                        <BSCTypography label="escalate" capitalize size14 />
                                    </BSCButton>
                                </Grid>
                            </Grid>
                        )}
                        {!isDesktop && (
                            <Grid container className={classes.buttons} spacing={1}>
                                <Grid item>
                                    <BSCButton color="secondary" onClick={handleMenuOpen}>
                                        <BSCTypography label="manage" capitalize size14 />
                                    </BSCButton>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </Grid>
            </div>
            <Grid container spacing={3}>
                <Grid item lg={8} xs={12}>
                    <SendMessagePanel
                        messageRef={messageRef}
                        subjectKey={messageInfo?.subjectKey}
                        isCreateMessage={false}
                        onSubmit={replyMessageFunc}
                        loading={loading}
                    />
                    <RecentMessages messages={messageInfo?.messages} loading={loading} />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <Grid container spacing={2}>
                        {isDesktop && (
                            <Grid item lg={12} md={6} xs={12}>
                                <OrderDetails order={orderInfo} orderUrl={orderUrl} />
                            </Grid>
                        )}

                        {(isMobile || isBelowTablet) && (
                            <Grid container style={{padding: 8, borderRadius: 8}}>
                                <Grid item xs={12}>
                                    <Accordion square={false}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <BSCTypography style={{fontSize: 16}}>Order Details</BSCTypography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                            <OrderDetails order={orderInfo} orderUrl={orderUrl} hasTitle={false} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        )}

                        {isDesktop && (
                            <Grid item lg={12} md={6} xs={12}>
                                <Cards data={orderInfo?.orderItems} />
                            </Grid>
                        )}
                        {(isMobile || isBelowTablet) && (
                            <Grid container style={{padding: 8, borderRadius: 8}}>
                                <Grid item xs={12}>
                                    <Accordion square={false}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <BSCTypography style={{fontSize: 16}}>Cards</BSCTypography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                            <Cards data={orderInfo?.orderItems} hasTitle={false} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {!isDesktop && (
                <Menu id="simple-menu" anchorEl={openMenu} keepMounted open={Boolean(openMenu)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleMenuClose}>
                        <BSCButton color="secondary" onClick={onMarkUnread}>
                            <BSCTypography label="mark as unread" capitalize size14 />
                        </BSCButton>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <BSCButton fullWidth color="secondary" onClick={onEscalate}>
                            <BSCTypography label="escalate" capitalize size14 />
                        </BSCButton>
                    </MenuItem>
                </Menu>
            )}
        </>
    );
}

export default ReplyMessage;
