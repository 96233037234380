import React, {useState, useEffect} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import './shareSeller.scss';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import classNames from 'classnames';
import BSCCustomSwitch from '../../../../../features/common/bscSwitch/bscCustomSwitch';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import BSCModal from '../../../../../features/common/bscModal/bscModal';
import {SellerProfile} from '../../../../../client/openapitools/seller';
import {currencyFormatter, numberWithCommas} from '../../../../../packages/core/src/util/util';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import {UserApi} from '../../../../../client/openapitools/common/api';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../authConfig';
import {Configuration} from '../../../../../client/openapitools/common/configuration';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: '16px 16px 21px',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
    sellerIcon: {
        width: 100,
        height: 100,
        objectFit: 'cover',
        borderRadius: '50%',
    },
    switch: {
        '& > span:nth-child(2)': {
            backgroundColor: '#EF2424 !important',
            '&:before': {
                content: "''",
                fontSize: theme.typography.subtitle2.fontSize,
                fontStyle: 'italic',
            },

            '&:after': {
                content: "'Hidden'",
                color: theme.palette.common.white,
                fontSize: theme.typography.subtitle2.fontSize,
                fontStyle: 'italic',
            },
        },
    },
    checked: {
        '& > span:nth-child(2)': {
            backgroundColor: '#358856 !important',
            '&:before': {
                content: "'LIVE'",
            },

            '&:after': {
                content: "''",
            },
        },
    },
    shareBtn: {
        marginTop: '5px',
    },
    paddingTop: {
        marginTop: '15px',
    },
    sellerIconWrapper: {
        display: 'flex',
        position: 'relative',
        width: 100,
        height: 100,
        marginRight: theme.spacing(1),
    },
    sellerIconUploader: {
        position: 'absolute',
        width: '100%',
        minWidth: '100%',
        height: '100%',
        borderRadius: '50%',
        padding: theme.spacing(0.5),
        '& svg': {
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },
    uploaderWrapper: {
        width: 25,
        minWidth: 25,
        height: 25,
        right: 0,
        bottom: 0,
        position: 'absolute',
    },
    input: {
        width: '100%',
        height: '100%',
        opacity: 0,
        position: 'absolute',
    },
    bottomPadding: {
        paddingBottom: theme.spacing(1),
    },
    greenButton: {
        background: '#358856',
        color: '#fff',
        '&:hover': {
            background: '#11c157',
        },
    },
    modalHeaderText: {
        fontSize: 24,
        fontWeight: 600,
    },
    fullWidth: {width: '100%', textAlign: 'center', marginTop: '10px'},

    sizeError: {
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
}));

interface ShareSellerProps {
    information: SellerProfile;
    onTurnButtonClick: (value: boolean) => {};
}

interface InventoryToggleModalProps {
    openModal: boolean;
    onCancelClick: () => {};
    isLive: boolean;
    onTurnButtonClick: () => {};
}

const buttonStrings = {
    turnLive: 'Turn Inventory Live',
    turnHidden: 'Hide Inventory',
};

const InventoryToggleModal = ({openModal, onCancelClick, isLive, onTurnButtonClick}: InventoryToggleModalProps) => {
    const currentState = isLive ? 'Live' : 'Hidden';
    const targetState = isLive ? 'Hidden' : 'Live';

    const classes = useStyles();
    const onClose = () => {
        onCancelClick();
    };
    return (
        <BSCModal size="sm" open={openModal} onClose={onClose}>
            <>
                <Grid container align="center">
                    <Grid item xs={12}>
                        <BSCTypography className={classes.modalHeaderText} label="Inventory Status" />
                    </Grid>
                </Grid>
                <BSCDivider />
                <Grid container align="center">
                    <Grid item xs={12}>
                        <BSCTypography
                            align="left"
                            variant="subtitle1"
                            className={classes.bottomPadding}
                            label={`Your inventory is currently "${currentState}" on BSC.`}
                        />
                        <BSCTypography
                            align="left"
                            variant="subtitle1"
                            label={`If you would like to turn inventory "${targetState}", please select "${
                                isLive ? buttonStrings.turnHidden : buttonStrings.turnLive
                            }" below.`}
                        />
                    </Grid>
                </Grid>
                <BSCDivider />
                <BSCButton
                    variant="contained"
                    color={isLive ? 'primary' : 'default'}
                    className={isLive ? '' : classes.greenButton}
                    fullWidth
                    onClick={onTurnButtonClick}
                >
                    {isLive ? buttonStrings.turnHidden : buttonStrings.turnLive}
                </BSCButton>
            </>
        </BSCModal>
    );
};

const ShareSeller = ({information = {}, onTurnButtonClick}: ShareSellerProps) => {
    const classes = useStyles();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let userApi = new UserApi(apiAuthConfig);
    const [checked, setChecked] = useState(false);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const [sellerIconUrl, setSellerIconUrl] = useState(null);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const onChangeInventoryStatus = () => {
        setOpenModal(true);
    };

    const handleCapture = ({target}) => {
        const file = target.files[0];

        if (file.size > 1048576) {
            setFileSizeError(true);
        } else {
            setFileSizeError(false);
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                userApi = new UserApi(apiConfig);
                                const response = await userApi.uploadUserAttachment(file);
                                if (response.data) {
                                    setSellerIconUrl(response.data.url);
                                }
                            } catch (error) {
                                console.log(error);
                                addToast({
                                    severity: 'error',
                                    message: 'Error uploading image',
                                    contextKey: 'SellerPortal',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    };

    useEffect(() => setChecked(information?.inventoryStatus), [information?.inventoryStatus]);

    useEffect(() => {
        if (information && information.sellerIconUrl) {
            setSellerIconUrl(Normalizer.getS3ImagePath(information.sellerIconUrl));
        } else {
            setSellerIconUrl(Normalizer.getS3ImagePath('/images/UploadYourImage.jpg'));
        }
    }, [information]);

    const handleModalCancelClick = () => {
        setOpenModal(false);
    };

    const handleOnTurnButtonClick = () => {
        const c = checked;
        setChecked(!checked);
        setOpenModal(false);
        onTurnButtonClick(!c);
    };

    const shareSellerClick = () => {
        navigator.clipboard.writeText(
            encodeURI(`${window.location.protocol}//${window.location.host}/Seller/${information?.userId}/${information?.sellerStoreName}`)
        );
        addToast({
            severity: 'success',
            message: 'Copied to Clipboard',
        });
    };

    return (
        <BSCCardBox className={classes.root}>
            <InventoryToggleModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                isLive={checked}
                onCancelClick={handleModalCancelClick}
                onTurnButtonClick={handleOnTurnButtonClick}
            />

            <Grid container alignItems="center" justifyContent="center">
                <div className={classes.sellerIconWrapper}>
                    <BSCImage src={sellerIconUrl} className={classes.sellerIcon} />
                    <div className={classes.uploaderWrapper}>
                        <BSCButton color="primary" className={classes.sellerIconUploader}>
                            <PhotoCameraOutlinedIcon />
                        </BSCButton>
                        <input accept="image/*" onChange={handleCapture} className={classes.input} id="raised-button-file" type="file" />
                    </div>
                </div>
                <BSCTypography label={information.sellerStoreName} className={classes.fullWidth} variant="h3" semibold />
                {fileSizeError && <BSCTypography label="Please only use images under 1MB" className={classes.sizeError} />}
            </Grid>
            <BSCDivider />
            <Grid container alignItems="center" justifyContent="space-between">
                <BSCTypography variant="subtitle1" label="Inventory Status:" />
                <BSCCustomSwitch
                    className={classNames(classes.switch, {
                        [classes.checked]: checked,
                    })}
                    checked={checked}
                    onChange={() => onChangeInventoryStatus()}
                />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
                <BSCTypography variant="subtitle1" label="# of Listings:" />
                <BSCTypography variant="subtitle1" label={information.totalListings ? numberWithCommas(information.totalListings) : 0} />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
                <BSCTypography variant="subtitle1" label="# of Cards:" />
                <BSCTypography variant="subtitle1" label={information.totalCards ? numberWithCommas(information.totalCards) : 0} />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
                <BSCTypography variant="subtitle1" label="Listing Value ($):" />
                <BSCTypography variant="subtitle1" label={`$${information.totalValue ? currencyFormatter(information.totalValue) : 0}`} />
            </Grid>
            <Grid container justifyContent="flex-end" direction="column">
                <Grid item>
                    <BSCButton className={classes.shareBtn} color="primary" fullWidth onClick={shareSellerClick}>
                        Share Seller
                    </BSCButton>
                </Grid>
            </Grid>
        </BSCCardBox>
    );
};

export default ShareSeller;
