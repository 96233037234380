// import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './redux/store';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {MsalProvider} from '@azure/msal-react';
// MSAL imports
import {msalInstance} from './authConfig';
import Auth from './AuthContext';

import 'normalize.css/normalize.css';
import './index.scss';
import App from './app';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const root = createRoot(document.getElementById('root')!);

root.render(
    // <StrictMode>
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <Auth>
                <PersistGate loading={null} persistor={persistor}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Elements stripe={stripePromise}>
                            <ThemeProvider theme={theme}>
                                <BrowserRouter>
                                    <HelmetProvider>
                                        <App />
                                    </HelmetProvider>
                                </BrowserRouter>
                            </ThemeProvider>
                        </Elements>
                    </MuiPickersUtilsProvider>
                </PersistGate>
            </Auth>
        </Provider>
    </MsalProvider>
    // </StrictMode>
);
