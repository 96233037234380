/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid/Grid';
import SendMessagePanel from './sendMessagePanel';
import OrderDetails from './orderDetails';
import Cards from './cards';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import {Order} from '../../../../../../../client/openapitools/marketplace';
import {Configuration, Conversation} from '../../../../../../../client/openapitools/common';
import {ConversationApi} from '../../../../../../../client/openapitools/common/api';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(1),
    },
}));

interface LocationState {
    state: {order: Order};
}
function CreateMessage({messageRef, isMarketPlace = true, orderUrl = null}) {
    const classes = useStyles();
    const history = useHistory();
    const {state}: LocationState = useLocation();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const conversationApi = new ConversationApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    // BUYER
    const [createLoadingForBuyer, setCreateLoadingForBuyer] = useState(false);
    const [buyerCreateError, setBuyerCreateError] = useState('');

    const [startLoadingForBuyer, setStartLoadingForBuyer] = useState(false);
    const [buyerStartError, setBuyerStartError] = useState('');

    // SELLER
    const [createLoadingForSeller, setCreateLoadingForSeller] = useState(false);
    const [sellerCreateError, setSellerCreateError] = useState('');

    const [startLoadingForSeller, setStartLoadingForSeller] = useState(false);
    const [sellerStartError, setSellerStartError] = useState('');

    const [conversationId, setConversationId] = useState();
    const loadingForBuyer = createLoadingForBuyer || startLoadingForBuyer;
    const loadingForSeller = createLoadingForSeller || startLoadingForSeller;

    useEffect(() => {
        if (buyerCreateError || buyerStartError) {
            history.push('/dashboard/orderHistory');
        }
        if (sellerCreateError || sellerStartError) {
            history.push('/sellers/orders');
        }
    }, [buyerCreateError, buyerStartError, sellerCreateError, sellerStartError]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            let response;
                            const conversationApi = new ConversationApi(apiConfig);
                            if (isMarketPlace) {
                                response = await conversationApi.addConversationForBuyer({
                                    toUserId: state?.order?.seller?.id,
                                    orderId: state?.order?.orderId,
                                });
                            } else {
                                response = await conversationApi.addConversationForSeller({
                                    toUserId: state?.order?.buyer?.id,
                                    orderId: state?.order?.orderId,
                                });
                            }
                            setConversationId(response?.data?.convId);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to Create Message.',
                                contextKey: 'Create Message',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, []);

    const orderInfo = state.order;
    const createMessageFuc = async (formState, attachedFiles) => {
        const account = instance.getActiveAccount();
        const attachments = [];

        const info: Conversation = {
            convId: conversationId,
            fromMarketPlace: isMarketPlace,
            subjectKey: formState.subject,
            read: true,
            escalated: false,
            orderNo: state.order.orderNo,
            orderId: state.order.orderId,
            fromUserId: isMarketPlace ? state.order.buyer?.id : state.order.seller?.id,
            toUserId: isMarketPlace ? state.order.seller?.id : state.order.buyer?.id,
            messages: [
                {
                    content: formState.reply,
                    attachments: attachments,
                    fromUserId: isMarketPlace ? state.order.buyer?.id : state.order.seller?.id,
                    toUserId: isMarketPlace ? state.order.seller?.id : state.order.buyer?.id,
                },
            ],
        };

        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            let convoResponse;
                            const conversationApi = new ConversationApi(apiConfig);
                            if (attachedFiles && attachedFiles.some(() => true)) {
                                try {
                                    for (let index = 0; index < attachedFiles.length; index++) {
                                        await conversationApi.uploadMessageAttachment(conversationId, attachedFiles[index]).then(x => {
                                            if (x.error) {
                                                throw new Error();
                                            }
                                            attachments.push(x.data);
                                        });
                                    }
                                    info.messages[0].attachments = attachments;
                                    if (isMarketPlace) {
                                        convoResponse = await conversationApi.updateConversationByIdForBuyer(info);
                                    } else {
                                        convoResponse = await conversationApi.updateConversationByIdForSeller(info);
                                    }
                                    addToast({
                                        severity: 'success',
                                        message: 'Successfully sent message',
                                    });
                                    setTimeout(() => history.goBack(), 2000);
                                } catch (error) {
                                    console.log(error);
                                    addToast({
                                        severity: 'error',
                                        message: 'Failed to Attach files and create message.',
                                        contextKey: 'Attach Files',
                                    });
                                }
                            } else {
                                if (isMarketPlace) {
                                    convoResponse = await conversationApi.updateConversationByIdForBuyer(info);
                                } else {
                                    convoResponse = await conversationApi.updateConversationByIdForSeller(info);
                                }
                                addToast({
                                    severity: 'success',
                                    message: 'Successfully sent message',
                                });
                                setTimeout(() => history.goBack(), 2000);
                            }
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to Create Message.',
                                contextKey: 'Create Message',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const onMarkUnread = () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            let response;
                            const conversationApi = new ConversationApi(apiConfig);
                            if (isMarketPlace) {
                                response = await conversationApi.updateConversationByIdForBuyer({convId: conversationId, read: false});
                            } else {
                                response = await conversationApi.updateConversationByIdForSeller({convId: conversationId, read: false});
                            }
                            addToast({
                                severity: 'success',
                                message: 'Successfully marked message as unread',
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to mark message as unread.',
                                contextKey: 'Mark as Unread',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };
    const onEscalate = () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            let response;
                            const conversationApi = new ConversationApi(apiConfig);
                            if (isMarketPlace) {
                                response = await conversationApi.updateConversationByIdForBuyer({convId: conversationId, escalated: true});
                            } else {
                                response = await conversationApi.updateConversationByIdForSeller({convId: conversationId, escalated: true});
                            }
                            addToast({
                                severity: 'success',
                                message: 'Successfully escalated the message',
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to Escalate Message.',
                                contextKey: 'Create Escalate Message',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    if (loadingForBuyer || loadingForSeller) {
        return <BSCLoading loading />;
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item lg={8} xs={12}>
                <SendMessagePanel
                    messageRef={messageRef}
                    isCreateMessage
                    onSubmit={createMessageFuc}
                    loading={isMarketPlace ? loadingForBuyer : loadingForSeller}
                    onEscalate={onEscalate}
                    onMarkUnread={onMarkUnread}
                />
            </Grid>
            <Grid item lg={4} xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={6} xs={12}>
                        <OrderDetails order={orderInfo} orderUrl={orderUrl} />
                    </Grid>
                    <Grid item lg={12} md={6} xs={12}>
                        {orderInfo?.orderItems && <Cards data={orderInfo?.orderItems} /*loading={orderInfoLoading}*/ />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreateMessage;
