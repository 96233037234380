/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect} from 'react';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import SellerDetails from './components/sellerDetails';
import ShareSeller from './components/shareSeller';
import SellerImages from './components/sellerImages';
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router';
import DwollaPayment from './components/DwollaPayment';
import {useState} from 'react';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import {makeStyles} from '@material-ui/core';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {UserApi as SellerUserApi} from '../../../../client/openapitools/seller';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {AccountApi} from '../../../../client/openapitools/seller/api';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {Configuration} from '../../../../client/openapitools/seller/configuration';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    bigFirstSmallSecond: {
        [theme.breakpoints.down('sm')]: {
            //         order: 2,
            overflowX: 'scroll',
        },
    },
    bigSecondSmallFirst: {
        [theme.breakpoints.down('sm')]: {
            //        order: 1,
        },
    },
    promoImg: {
        position: 'absolute',
        bottom: 0,
    },
}));

function SellersPortal() {
    const history = useHistory();
    const {instance} = useMsal();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isDesktop, isBelowDesktop, isMobile} = useScreenSnap();
    const {apiAuthConfig} = useAuthState();

    const [userState, setUserState] = useState(null);
    // const {userState} = useReduxUser();
    // const {data: dashboardInformation, loading: dashboardInformationLoading} = useQuery(sellerAccountApi.getSellerInfo, []);
    const [dashboardInformation, setDastboardInformation] = useState(null);
    const [dashboardInformationLoading, setDashboardInformationLoading] = useState(true);
    const [iavToken, setIavToken] = useState<string>('');

    const updateInventoryLive = status => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const sellerAccountApi = new AccountApi(apiConfig);
                            const response = await sellerAccountApi.updateSellerInventoryStatus(status);
                        } catch (error) {
                            console.error('Error setting IavToken', error);
                            addToast({
                                severity: 'error',
                                message: 'Error setting IavToken',
                                contextKey: 'User Profile',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const classes = useStyles();
    const [sellerState, setSellerState] = useState(null);
    // const sellerState = useSelector((state: RootState) => state.seller);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const launchPromo = () => {
        history.push('/sellers/promo');
    };

    // useEffect(() => {
    //     if (dashboardInformation?.data) {
    //         console.log('>>>', dashboardInformation.data);
    //     }
    // }, [dashboardInformation]);

    useEffect(() => {
        if (userState && sellerState) {
            if (
                userState.roleId !== '2' ||
                (userState?.sellerProfile?.applicationStatus !== 'completed' && sellerState?.hasVerifiedMicroDeposits !== true)
            ) {
                history.replace('/sellers/signup');
            } else if (userState?.sellerProfile?.paymentSettings && !userState?.sellerProfile?.paymentSettings?.fundingSourceUrl) {
                // getIavToken().then(res => {
                //     if (res.status === res.statusTypes.fulfilled) {
                //         setIavToken(res.data.token);
                //     }
                // });
                const account = instance.getActiveAccount();
                if (account !== null) {
                    const tokenRequest: SilentRequest = {
                        account: account as AccountInfo | undefined,
                        scopes: AUTH_REQUESTS.LOGIN.scopes,
                    };
                    instance
                        .acquireTokenSilent(tokenRequest)
                        .then(async response => {
                            if (response.accessToken) {
                                const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                                try {
                                    const sellerUserApi = new SellerUserApi(apiConfig);
                                    const response = await sellerUserApi.getIavToken();
                                    if (response.data.token) {
                                        setIavToken(response.data.token);
                                    }
                                } catch (error) {
                                    console.error('Error setting IavToken', error);
                                    addToast({
                                        severity: 'error',
                                        message: 'Error setting IavToken',
                                        contextKey: 'User Profile',
                                    });
                                }
                            }
                        })
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        .catch(error => {
                            addToast({
                                severity: 'error',
                                message: 'User must be Signed In.',
                                contextKey: 'Authentication',
                            });
                            setTimeout(() => {
                                authFunc.logout();
                            }, 5000);
                        });
                }
            }
        }
    }, [userState, sellerState]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const sellerAccountApi = new AccountApi(apiConfig);
                            const accountResponse = await sellerAccountApi.getSellerInfo();
                            setSellerState(accountResponse.data);
                            setDastboardInformation(accountResponse);
                            setDashboardInformationLoading(false);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: "Seller's Locker",
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <>
            {dashboardInformationLoading && <BSCLoading loading={dashboardInformationLoading} />}
            {!dashboardInformationLoading && dashboardInformation.data.statistics && (
                <BSCContainer variant="page">
                    {!userState?.sellerProfile?.paymentSettings?.fundingSourceUrl ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={9} className={classes.bigFirstSmallSecond}>
                                        {/* {isDesktop && !isBelowDesktop && (
                                            <Grid item xs={12} container style={{cursor: 'pointer'}}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        height: 150,
                                                        background: '#ffc361',
                                                        border: 'solid 4px #000',
                                                        borderRadius: 8,
                                                        marginBottom: 12,
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <BSCImage
                                                        src={process.env.PUBLIC_URL + '/img/SummerSellerbration-Desktop-Payout.png'}
                                                        className={classes.promoImg}
                                                        // onClick={launchPromo}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )} */}

                                        <SellerDetails details={dashboardInformation?.data.statistics} />
                                    </Grid>
                                    <Grid item xs={12} md={3} className={classes.bigSecondSmallFirst}>
                                        {/* {isDesktop && !isBelowDesktop && (
                                            <Grid item xs={12} container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        height: 150,
                                                        background: '#fff',
                                                        border: 'solid 4px #000',
                                                        borderRadius: 8,
                                                        marginBottom: 12,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            padding: '12%',
                                                        }}
                                                    >
                                                        <BSCTypography size18>Your Earnings:&nbsp;</BSCTypography>
                                                        <BSCTypography size18>
                                                            {new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                            }).format(dashboardInformation?.data?.profile?.credits)}
                                                        </BSCTypography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )} */}
                                        <Grid item xs={12} container>
                                            <Grid item xs={12}>
                                                <ShareSeller
                                                    information={dashboardInformation?.data.profile}
                                                    onTurnButtonClick={updateInventoryLive}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {isMobile && (
                                        <Grid item xs={12}>
                                            <SellerImages />
                                        </Grid>
                                    )}
                                    {iavToken ? (
                                        <BSCCardBox color="secondary">
                                            <DwollaPayment iavToken={iavToken} />
                                        </BSCCardBox>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>
                            {dashboardInformationLoading && <BSCLoading loading={dashboardInformationLoading} />}
                            {!dashboardInformationLoading && dashboardInformation && dashboardInformation.data.statistics && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={9} className={classes.bigFirstSmallSecond}>
                                                {/* {isDesktop && !isBelowDesktop && (
                                                    <Grid item xs={12} container style={{cursor: 'pointer'}}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                height: 150,
                                                                background: '#ffc361',
                                                                border: 'solid 4px #000',
                                                                borderRadius: 8,
                                                                marginBottom: 12,
                                                            }}
                                                        >
                                                            <BSCImage
                                                                src={process.env.PUBLIC_URL + '/img/SummerSellerbration-Desktop-Payout.png'}
                                                                className={classes.promoImg}
                                                                // onClick={launchPromo}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )} */}
                                                <Grid item xs={12} container>
                                                    <Grid item xs={12}>
                                                        <SellerDetails details={dashboardInformation?.data.statistics} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={3} className={classes.bigSecondSmallFirst}>
                                                {/* {isDesktop && !isBelowDesktop && (
                                                    <Grid item xs={12} container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                height: 150,
                                                                background: '#fff',
                                                                border: 'solid 4px #000',
                                                                borderRadius: 8,
                                                                marginBottom: 12,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign: 'center',
                                                                    padding: '12%',
                                                                }}
                                                            >
                                                                <BSCTypography size18>Your Earnings:&nbsp;</BSCTypography>
                                                                <BSCTypography size18>
                                                                    {new Intl.NumberFormat('en-US', {
                                                                        style: 'currency',
                                                                        currency: 'USD',
                                                                    }).format(dashboardInformation?.data?.profile?.credits)}
                                                                </BSCTypography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                )} */}
                                                <Grid item xs={12} container>
                                                    <Grid item xs={12}>
                                                        <ShareSeller
                                                            information={dashboardInformation?.data.profile}
                                                            onTurnButtonClick={updateInventoryLive}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {isMobile && (
                                                <Grid item xs={12}>
                                                    <SellerImages />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={7}>
                                                <SellerBePro />
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <SellerFeedback />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}></Grid> */}
                                </Grid>
                            )}
                        </div>
                    )}
                </BSCContainer>
            )}
        </>
    );
}

export default SellersPortal;
