import {useReducer, useCallback, ChangeEvent, useMemo, useEffect} from 'react';
import {stateInputShape, validatedSchema, validationSchema} from '../../../../@types/validations/validations';
import validator from '../util/validator';

const reducer = (state: stateInputShape, action) => {
    switch (action.type) {
        case 'UPDATE': {
            return {
                ...state,
                [action.payload.name]: {
                    value: action.payload.value ?? null,
                    dirty: action.payload.dirty,
                },
            };
        }
        case 'BLUR': {
            return {
                ...state,
                [action.payload.name]: {
                    value: action.payload.value ?? null,
                    dirty: action.payload.dirty,
                },
            };
        }
        case 'SET_DEFAULT': {
            return {...state, ...action.payload.newState};
        }
        case 'CHANGE_DEFAULT': {
            return {...state, ...action.payload.newState};
        }
        case 'RESET': {
            return action.payload.defaultState;
        }
        default: {
            return state;
        }
    }
};

interface useFormProps {
    validationSchema: validationSchema | null;
    defaultValues?: Object | null;
}

type useFormReturn<T> = {
    formState: T;
    validatedSchema: validatedSchema;
    isErrors: boolean;
    registerFormChange: (event: ChangeEvent<HTMLInputElement>) => void;
    registerFormBlur: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    setDefaultValues: (defaultValues: T) => void;
    resetValues: () => void;
    formComplete: boolean;
};

function defaultValueFormatter(defaultValues) {
    const defaultState = {};
    Object.keys(defaultValues).forEach(key => (defaultState[key] = {value: defaultValues[key], dirty: true}));
    return defaultState;
}

function getDefaultValuesFromValidation(validationSchema: validationSchema) {
    const defaultValues = {};
    for (const key in validationSchema) {
        if (validationSchema[key]?.defaultValue) {
            defaultValues[key] = validationSchema[key].defaultValue;
        }
    }

    return defaultValueFormatter(defaultValues);
}

function useForm<T = {[key: string]: string | number | null}>({validationSchema, defaultValues}: useFormProps): useFormReturn<T> {
    const defaultState = defaultValues ? defaultValueFormatter(defaultValues) : getDefaultValuesFromValidation(validationSchema);
    const [formState, dispatch] = useReducer(reducer, defaultState);
    const [validatedSchema, isErrors, isFormDirty] = useMemo(() => validator(formState, validationSchema), [formState, validationSchema]);

    useEffect(() => {
        changeDefaultValues(defaultState);
    }, [validationSchema]);

    const memoizedState = useMemo(() => {
        const formattedFormState = {};
        Object.keys(formState).forEach(key => (formattedFormState[key] = formState[key].value));
        return formattedFormState;
    }, [formState]);

    const changeDefaultValues = useCallback(newDefaultValues => {
        dispatch({
            type: 'CHANGE_DEFAULT',
            payload: {
                newState: newDefaultValues,
            },
        });
    }, []);

    const resetValues = useCallback(() => {
        dispatch({
            type: 'RESET',
            payload: {
                defaultState,
            },
        });
    }, [defaultState]);

    const setDefaultValues = useCallback(newDefaultValues => {
        dispatch({
            type: 'SET_DEFAULT',
            payload: {
                newState: defaultValueFormatter(newDefaultValues),
            },
        });
    }, []);

    const registerFormChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.name) {
            throw Error('Must have names on inputs, if using BSCTextField set inputKey prop');
        }

        dispatch({
            type: 'UPDATE',
            payload: {
                name: event.target.name,
                value: event.target.value,
                dirty: true,
            },
        });
    }, []);

    const registerFormBlur = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.name) {
            throw Error('Must have names on inputs, if using BSCTextField set inputKey prop');
        }

        dispatch({
            type: 'BLUR',
            payload: {
                name: event.target.name,
                value: event.target.value,
                dirty: true,
            },
        });
    }, []);

    return {
        formState: memoizedState,
        validatedSchema,
        isErrors,
        registerFormChange,
        registerFormBlur,
        setDefaultValues,
        resetValues,
        formComplete: isFormDirty && !isErrors,
    };
}

export default useForm;
