import React, {useState, useEffect, useRef} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCAccordion from '../../../../../../../features/common/bscAccordion/bscAccordion';
import OrderHistoryItemInfo from './orderHistoryItemInfo';
import OrderItemsTable from './orderItemsTable';
import Collapse from '@material-ui/core/Collapse';
import OrderHistoryItemHeader from './orderHistoryItemHeader';
import {useLocation} from 'react-router';
import {useCallback} from 'react';
import {Order} from '../../../../../../../client/openapitools/marketplace/api';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(1.5),
        scrollMargin: '85px',
    },
    header: {
        marginBottom: theme.spacing(-1),
        padding: '0',
    },
}));

interface OrderHistoryItemProps {
    orderHistoryResult: Order;
    loading: boolean;
}

function OrderHistoryItem({orderHistoryResult, loading}: OrderHistoryItemProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {state} = useLocation();
    const handleClick = useCallback(() => setOpen(!open), [open, setOpen]);
    const titleRef = useRef();
    if (!orderHistoryResult) {
        return null;
    }

    useEffect(() => {
        if (state?.orderId === orderHistoryResult.orderId) {
            handleClick();
            titleRef.current.scrollIntoView();
        }
    }, []);

    return (
        <div className={classes.root} ref={titleRef}>
            <div className={classes.header}>
                <BSCAccordion open={open} label={<OrderHistoryItemHeader order={orderHistoryResult} />} onAccordionClick={handleClick} />
            </div>
            <Collapse in={open}>
                <div>
                    <OrderHistoryItemInfo order={orderHistoryResult} />
                    <OrderItemsTable items={orderHistoryResult.orderItems} loading={loading} />
                </div>
            </Collapse>
        </div>
    );
}

export default OrderHistoryItem;
