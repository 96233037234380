//Requires   async src="https://maps.googleapis.com/maps/api/js?key=<APIKEY>&libraries=places&callback=initMap"></script> to be in public/index.html
export const GoogleAutoCompelete = async text =>
    new Promise((resolve, reject) => {
        if (!text) {
            return reject('Need valid text input');
        }

        // for use in things like GatsbyJS where the html is generated first
        if (typeof window === 'undefined') {
            return reject('Need valid window object');
        }

        try {
            new window.google.maps.places.AutocompleteService().getPredictions(
                {input: text, componentRestrictions: {country: 'us'}, fields: ['address_components', 'geometry'], types: ['address']},
                resolve
            );
        } catch (e) {
            reject(e);
        }
    });

export const GetPostalCode = async placeId =>
    new Promise((resolve, reject) => {
        if (!placeId) reject('placeId not provided');

        try {
            new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
                {
                    placeId,
                    fields: ['address_components'],
                },
                details => {
                    return resolve(details);
                }
            );
        } catch (e) {
            reject(e);
        }
    });
