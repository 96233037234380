import {createTheme} from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
    interface Theme {
        containerWraps: {
            page: {
                padding: number | string;
                maxWidth: number;
                margin: string | number;
                // TODO: look at this any later
                [index: string]: unknown;
            };
            fullWidthPage: {
                // TODO: look at this any later
                [index: string]: unknown;
            };
        };
        backgroundColors: {
            black: string;
            white: string;
            offWhite: string;
            lightGray: string;
            darkGray: string;
        };
    }
    interface ThemeOptions {
        containerWraps?: {};
        backgroundColors?: {
            black: string;
            white: string;
            offWhite: string;
            lightGray: string;
            darkGray: string;
        };
    }
}

const font = "'Poppins', sans-serif";
const theme = createTheme({
    typography: {
        fontFamily: font,
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 16,
            lineHeight: 1.5,
        },
        h1: {
            fontWeight: 600,
            letterSpacing: 0.5,
            fontSize: 40,
        },
        h2: {
            fontWeight: 600,
            letterSpacing: 0.5,
            fontSize: 32,
        },
        h3: {
            fontWeight: 600,
            letterSpacing: 0.5,
            fontSize: 24,
        },
        h4: {
            fontWeight: 600,
            letterSpacing: 0.5,
            fontSize: 20,
        },
        h5: {
            fontWeight: 600,
            letterSpacing: 0.5,
            fontSize: 16,
        },
        h6: {
            fontWeight: 600,
            letterSpacing: 0.5,
            fontSize: 14,
        },
        subtitle1: {
            fontSize: 14,
            color: '#41474D',
        },
        subtitle2: {
            fontSize: 12,
            color: '#41474D',
        },
    },
    palette: {
        primary: {main: '#EF2424'},
        secondary: {main: '#182026'},
        info: {main: '#182026', contrastText: '#fff'},
        text: {
            primary: '#000',
        },
    },
    containerWraps: {
        page: {
            maxWidth: 1400,
            margin: 'auto',
        },
        fullWidthPage: {},
    },
    backgroundColors: {
        black: '#000',
        white: '#fff',
        offWhite: '#e5e5e5',
        lightGray: '#F5F8FA',
        darkGray: '#B8C2CC',
    },
});

theme.containerWraps.page.padding = theme.spacing(2);
theme.containerWraps.page[theme.breakpoints.down('sm')] = {
    padding: theme.spacing(1, 2),
};
theme.containerWraps.fullWidthPage = {
    padding: theme.spacing(2),
};
theme.containerWraps.fullWidthPage[theme.breakpoints.down('sm')] = {
    padding: theme.spacing(1),
};

export default theme;
