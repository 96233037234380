import {s3Client} from '../..';

const serviceUrls = {
    sellersNav: 's3/sellersNav',
};

const SellersNavigationClient = {
    getNav: () => s3Client.get(serviceUrls.sellersNav).then(res => res.data),
    getUserMenus: async () => {
        return {
            dashboard: [
                {
                    title: 'Seller Profile',
                    url: '/sellers/dashboard/profile',
                },
                {
                    title: 'Feedback',
                    url: '/sellers/dashboard/feedback',
                },
                {
                    title: 'FAQ',
                    url: '/sellers/dashboard/faq',
                },
                // {
                //     title: 'Be Pro',
                //     url: '/sellers/dashboard/bepro',
                // },
            ],
        };
    },
};

export default SellersNavigationClient;
