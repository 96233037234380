//import {ReactComponent as BSCEdit} from '../svgs/edit.svg';
import {ReactComponent as BSCEdit} from '../../../svgs/edit.svg';
import CloseIcon from '@material-ui/icons/Close';
import {Tooltip} from '@material-ui/core';
import BSCEditToggle from '../bscEditToggle/bscEditToggle';
import {MouseEventHandler} from 'react';

// editButton: {
//     cursor: 'pointer',
// },

// {
//     !isEdit && <BSCEdit className={classes.editButton} onClick={edit} />;
// }
// {
//     isEdit && (
//         <Tooltip title="Cancel">
//             <CloseIcon className={classes.editButton} onClick={() => edit()} />
//         </Tooltip>
//     );
// }

interface BscToggledEditButtonProps {
    /**
     * when set to true, will show X
     * When set to false will show the edit svg
     */
    isEdit: boolean;
    onCLick: MouseEventHandler;
}

const BscToggledEditButton = ({isEdit, onCLick}: BscToggledEditButtonProps) => {
    return (
        <BSCEditToggle
            isEdit={isEdit}
            trueChild={
                <Tooltip title="Cancel">
                    <CloseIcon style={{cursor: 'pointer', width: '25px', height: '22px'}} onClick={onCLick} />
                </Tooltip>
            }
            falseChild={<BSCEdit style={{cursor: 'pointer'}} onClick={onCLick} />}
        ></BSCEditToggle>
    );
};

export default BscToggledEditButton;
