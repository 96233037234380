/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {memo, useEffect, useState} from 'react';
import {makeStyles, Link, Theme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import Box from '@material-ui/core/Box';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import {Card} from '../../../../client/openapitools/marketplace';
import {useHistory} from 'react-router';

const baseStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    box: {
        background: theme.palette.common.white,
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: 4,
        paddingTop: theme.spacing(2),
    },
    buttonWrap: {
        paddingTop: 0,
    },
    heading: {
        padding: theme.spacing(0, 2),
    },
    noItalic: {
        fontSize: 14,
        right: '0px',
    },
    cardFeedSider: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        width: '58%',
    },
    dotActive: {
        background: theme.palette.primary.main,
    },
    stepper: {
        left: '50%',
        bottom: '16px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        justifyContent: 'center',
        paddingBottom: '16px',
    },
    fontBlack: {
        color: 'black',
    },
    cursorPointer: {
        cursor: 'pointer',
        color: 'black',
        textDecoration: 'none',
        fontSize: 14,
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        lineHeight: 1.75,
    },
    h1Zero: {
        display: 'none',
        width: 0,
        height: 0,
        margin: 0,
        padding: 0,
        color: 'transparent',
    },
    h2Zero: {
        display: 'none',
        width: 0,
        height: 0,
        margin: 0,
        padding: 0,
        color: 'transparent',
    },
    h3Zero: {
        display: 'none',
        width: 0,
        height: 0,
        margin: 0,
        padding: 0,
        color: 'transparent',
    },
}));

interface ProductInfoDetailsProps {
    productInfoData: null | Card;
    onReportClick: () => void;
    onSellThisClick: () => void;
}

function ProductInfoDetails({productInfoData, onReportClick, onSellThisClick}: ProductInfoDetailsProps) {
    const classes = baseStyles();
    const cardNormalizer = useCardNormalizer();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const history = useHistory();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hiddenSpiderLink, setHiddenSpiderLink] = useState<string>('');
    const setLinkableSetName = (category, productInfo): string => {
        const rawSport = productInfo.sport.toLowerCase();
        let spacePtr = productInfo.setName.indexOf(' ');
        let rawYear = productInfo.setName.substring(0, spacePtr);
        if (rawYear.includes('-')) {
            rawYear = rawYear.substring(0, rawYear.indexOf('-'));
        }
        const rawSetName = productInfo.setName.substring(++spacePtr, productInfo.setName.length).toLowerCase();
        const setName = encodeURIComponent(rawSetName).replace(new RegExp('%20', 'g'), '-');
        if (category === 'variant') {
            const rawVariantName = productInfo.variantName.toLowerCase();
            const variantName = encodeURIComponent(rawVariantName).replace(new RegExp('%20', 'g'), '-');
            return `${window.location.origin}/search?inStock=true&p=0&sport[]=${rawSport}&year[]=${rawYear}&setName[]=${setName}&variantName[]=${variantName}`;
        } else {
            return `${window.location.origin}/search?inStock=true&p=0&sport[]=${rawSport}&year[]=${rawYear}&setName[]=${setName}`;
        }
    };

    const [h1Data, setH1Data] = useState<string>();
    const [h2Data, setH2Data] = useState<string>();
    const [h3Data, setH3Data] = useState<string>();
    const [setLink, setSetLink] = useState<string>();
    const [variantLink, setVariantLink] = useState<string>();
    useEffect(() => {
        if (productInfoData) {
            setH1Data(
                productInfoData.players +
                    ' , #' +
                    productInfoData.cardNo +
                    ' , ' +
                    productInfoData.year +
                    ' , ' +
                    productInfoData.setName +
                    ' , ' +
                    cardNormalizer.getVariantAndVariantName(productInfoData)
            );
            setH2Data(productInfoData.teamName);
            setH3Data(productInfoData.playerAttributeAlias);
            const rawSport = productInfoData.sport.toLowerCase();
            let spacePtr = productInfoData.setName.indexOf(' ');
            let rawYear = productInfoData.setName.substring(0, spacePtr);
            if (rawYear.includes('-')) {
                rawYear = rawYear.substring(0, rawYear.indexOf('-'));
            }
            const rawSetName = productInfoData.setName.substring(++spacePtr, productInfoData.setName.length).toLowerCase();
            const setName = encodeURIComponent(rawSetName).replace(new RegExp('%20', 'g'), '-');
            setSetLink(setLinkableSetName('set', productInfoData));
            setVariantLink(setLinkableSetName('variant', productInfoData));
            setHiddenSpiderLink(
                `${window.location.origin}/search?inStock=true&p=0&sport[]=${rawSport}&year[]=${rawYear}&setName[]=${setName}`
            );
        }
    }, [productInfoData]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <Box p={2} className={classes.buttonWrap} style={{flex: 1, display: 'flex', flexDirection: 'column', paddingBottom: 0}}>
                <div style={{maxWidth: 450, margin: 'auto'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <BSCTypography className={classes.fontBlack} label="Set:" />
                        </Grid>
                        <Grid item xs={8}>
                            <Link rel="canonical" href={setLink} className={classes.cursorPointer}>
                                {productInfoData?.setName}
                            </Link>
                        </Grid>
                        <Grid item xs={4}>
                            <BSCTypography className={classes.fontBlack} label="Variant:" />
                        </Grid>
                        <Grid item xs={8}>
                            <Link rel="canonical" href={variantLink} className={classes.cursorPointer}>
                                {cardNormalizer.getVariantAndVariantName(productInfoData)}
                            </Link>
                        </Grid>
                        <Grid item xs={4}>
                            <BSCTypography className={classes.fontBlack} label="Card #:" />
                        </Grid>
                        <Grid item xs={8}>
                            <BSCTypography className={classes.fontBlack} label={productInfoData?.cardNo} />
                        </Grid>
                        <Grid item xs={4}>
                            <BSCTypography className={classes.fontBlack} label="Team:" />
                        </Grid>
                        <Grid item xs={8}>
                            <BSCTypography
                                className={classes.fontBlack}
                                label={productInfoData?.teamName}
                                style={{overflowWrap: 'break-word'}}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <BSCTypography className={classes.fontBlack} label="Attribute(s):" />
                        </Grid>
                        <Grid item xs={8}>
                            <BSCTypography className={classes.fontBlack} label={productInfoData?.playerAttributeAlias} />
                        </Grid>
                    </Grid>
                </div>
            </Box>
            <BSCDivider />
            <Box p={2} className={classes.buttonWrap}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <BSCButton className={classes.noItalic} fullWidth onClick={onSellThisClick}>
                                Sell This
                            </BSCButton>
                        </Grid>
                        <Grid item xs={6}>
                            <BSCButton className={classes.noItalic} fullWidth onClick={onReportClick}>
                                Report
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </div>
    );
}

export default memo(ProductInfoDetails);
