import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import logger from 'redux-logger';

import reducers from './reducers';

const middlewares = [
    // logger
];

const key = 'root';

const persistConfig = {
    key,
    storage,
    blacklist: ['user', 'toast', 'apiConfig', 'marketplaceSearch'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'posts/getUser/fulfilled'],
            },
        }),
        ...middlewares,
    ],
});

export const persistor = persistStore(store);
