import Grid from '@material-ui/core/Grid';
import {Children, ReactNode, useCallback, useEffect, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSwipeable} from 'react-swipeable';
import {RootState} from '../../../redux/reducers';

const NEXT = 'next';
const PREV = 'prev';

type Direction = typeof PREV | typeof NEXT;

interface CarouselState {
    pos: number;
    sliding: boolean;
    dir: Direction;
    isMax: boolean;
}

type CarouselAction = {type: Direction; numItems: number; isMax: boolean} | {type: 'stopSliding' | 'reset'};

const initialState: CarouselState = {pos: 0, sliding: false, dir: NEXT, isMax: false};
function reducer(state: CarouselState, action: CarouselAction): CarouselState {
    switch (action.type) {
        case 'reset':
            return initialState;
        case PREV:
            return {
                ...state,
                dir: PREV,
                sliding: true,
                pos: state.pos === 0 ? 2 : state.pos - 1,
            };
        case NEXT:
            return {
                ...state,
                dir: NEXT,
                sliding: true,
                pos: action.isMax ? 0 : state.pos + 1,
            };
        case 'stopSliding':
            return {...state, sliding: false};
        default:
            return state;
    }
}

interface BSCSliderProps {
    slideColumns: number;
    children: ReactNode;
    onSlide: (slidePos: number) => void;
    slidesPerNext: number;
    spacing?: number;
}

function BSCSlider({slideColumns = 1, slidesPerNext, children, onSlide, spacing = 1}: BSCSliderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const numItems = Children.count(children);
    const perRow = slideColumns;
    const percentage = 100 / perRow;
    const maxTranslate = (numItems - perRow) * percentage;
    const toTranslate = percentage * state.pos * slidesPerNext;
    const transitionTime = 500;
    const smooth = `transform ${transitionTime}ms ease`;
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        onSlide?.(state.pos);
    }, [state.pos, onSlide]);

    const slide = useCallback(
        (dir: Direction) => {
            dispatch({type: dir, numItems, isMax: maxTranslate <= toTranslate});
            setTimeout(() => {
                dispatch({type: 'stopSliding'});
            }, 50);
        },
        [maxTranslate, toTranslate, numItems]
    );

    useEffect(() => {
        const autoPlayInterval = setInterval(() => {
            slide(NEXT);
        }, 5000);

        return () => clearInterval(autoPlayInterval);
    }, [slide]);

    const handlers = useSwipeable({
        onSwipedLeft: () => slide(NEXT),
        onSwipedRight: () => slide(PREV),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <div {...handlers} style={pwaOS === null ? {} : {padding: '12px 0px 12px 12px', margin: 'auto'}}>
            <div style={pwaOS === null ? {overflow: 'hidden'} : {overflow: 'hidden', height: 80, borderRadius: 8}}>
                <Grid
                    container
                    wrap="nowrap"
                    style={{
                        transition: smooth,
                        transform: `translateX(-${toTranslate}%)`,
                    }}
                >
                    {/* dir and sliding */}
                    {Children.map(children, (child, index) => (
                        <>
                            {child && (
                                <div
                                    key={index}
                                    style={{
                                        flex: `1 0 ${percentage}%`,
                                        width: `${percentage}%`,
                                        maxWidth: `${percentage}%`,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 8 * spacing,
                                        }}
                                    >
                                        {child}
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
                </Grid>
            </div>
        </div>
    );
}

export default BSCSlider;
