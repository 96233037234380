/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Grid, ModalProps, Slider} from '@material-ui/core';
import BSCModal from '../../../../../features/common/bscModal/bscModal';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import {makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import BSCTextField from '../../../../../features/common/bscTextField/bscTextField';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import useSearch from '../../../../../packages/core/src/hooks/useSearch';

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    buttonsSection: {
        marginTop: 32,
        overflow: 'hidden',
        width: '100%',
    },
    modalDimensions: {
        height: '96px',
        [theme.breakpoints.down('xs')]: {height: '130px'},
    },
    deleteConfirmation: {
        fontSize: '20px !important',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: '1.167.toFixed',
        letterSpacing: 0.5,
    },
    checkBox: {
        padding: theme.spacing(0.25),
    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 96,
        background: '#DAE0E6',
        borderRadius: 4,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.25),
        paddingRight: theme.spacing(1),
        boxShadow: 'inset 0px -2px 4px #DAE0E6',
    },
    innerBoxWide: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 234,
        background: '#DAE0E6',
        borderRadius: 4,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.25),
        paddingRight: theme.spacing(1),
        boxShadow: 'inset 0px -2px 4px #DAE0E6',
    },
    rangeGrade: {
        flex: 2,
        '& input': {
            textAlign: 'center',
            fontSize: '12px !important',
            padding: '7.5px 4px',
        },
        '& label': {
            transform: 'translate(8px, 8px) scale(1)',
            fontSize: theme.typography.h6.fontSize,
        },
    },
    rangeGradeTextFieldArea: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '8px',
    },
    actionButton: {
        fontSize: '0.65rem',
        paddingTop: 9,
        paddingRight: 0,
    },
    verticalSeparator: {
        borderLeft: 'solid 3px #DAE0E6',
        paddingTop: 0,
        boxSizing: 'border-box',
    },
}));

const BSCCustomSlider = withStyles({
    root: {
        height: 3,
        padding: '13px 0',
    },
    thumb: {
        height: 16,
        width: 16,
        // backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: -7,
        // marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 2,
    },
    mark: {
        backgroundColor: '#bfbfbf',
        marginTop: -1,
        height: 4,
        width: 4,
        borderRadius: '50%',
        display: 'inline-block',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

const BSCCustomSliderThumbComponent = props => {
    return <span {...props}></span>;
};

interface SelectConditonsModalProps extends Omit<ModalProps, 'children'> {
    open: boolean;
    handleClose: () => void;
}

function SelectConditonsModal({open, handleClose}: SelectConditonsModalProps) {
    const classes = useStyles();
    const {isDesktop} = useScreenSnap();
    const {cleanedSearchQuery} = useSearch();

    const marks = [
        {value: 1},
        {value: 1.5},
        {value: 2},
        {value: 2.5},
        {value: 3},
        {value: 3.5},
        {value: 4},
        {value: 4.5},
        {value: 5},
        {value: 5.5},
        {value: 6},
        {value: 6.5},
        {value: 7},
        {value: 7.5},
        {value: 8},
        {value: 8.5},
        {value: 9},
        {value: 9.5},
        {value: 10},
    ];
    const [rawModernActionButtonTitle, setRawModernActionButtonTitle] = useState('Select All');
    const [rawVintageActionButtonTitle, setRawVintageActionButtonTitle] = useState('Select All');
    const [gradingCompanyActionButtonTitle, setGradingCompanyActionButtonTitle] = useState('Select All');
    const [value, setValue] = useState([Number(1), Number(10)]);
    const handleChange = (event, newValue) => setValue(newValue);
    const [rangeGradeMin, setRangeGradeMin] = useState(1);
    const [rangeGradeMax, setRangeGradeMax] = useState(10);
    const handleRangeGradeMin = event => {
        if (isNaN(event.target.value)) {
            return;
        }
        if (event.target.value.endsWith('.')) {
            setValue([Number(event.target.value), Number(value[1])]);
            setRangeGradeMin(event.target.value);
            return;
        }
        const numValue = Number(event.target.value);
        if (numValue !== 0) {
            if (numValue > 10) {
                setValue([Number(10), Number(value[1])]);
                setRangeGradeMin(10);
            } else {
                const newVal = Math.ceil(numValue * 2) / 2;
                setValue([Number(newVal), Number(value[1])]);
                setRangeGradeMin(newVal);
            }
        } else {
            setValue([Number(1), Number(value[1])]);
            setRangeGradeMin(1);
        }
    };

    const handleRangeGradeMinBlur = event => {
        if (event.target.value.endsWith('.')) {
            const tempMinValue = event.target.value.slice(0, -1);
            setValue([Number(tempMinValue), Number(value[1])]);
            setRangeGradeMin(tempMinValue);
        } else {
            const numValue = Number(event.target.value);
            if (numValue === 0) {
                setValue([Number(1), Number(value[1])]);
                setRangeGradeMin(1);
            }
            if (numValue > 10) {
                setValue([Number(10), Number(value[1])]);
                setRangeGradeMin(10);
            }
        }
    };

    const handleRangeGradeMax = event => {
        if (isNaN(event.target.value)) {
            return;
        }
        if (event.target.value.endsWith('.')) {
            setValue([Number(value[0]), Number(event.target.value)]);
            setRangeGradeMax(event.target.value);
            return;
        }
        const numValue = Number(event.target.value);
        if (numValue !== 0) {
            if (numValue > 10) {
                setValue([Number(value[0]), Number(10)]);
                setRangeGradeMax(10);
            } else {
                const newVal = Math.ceil(numValue * 2) / 2;
                setValue([Number(value[0]), Number(newVal)]);
                setRangeGradeMax(newVal);
            }
        }
    };

    const handleRangeGradeMaxBlur = event => {
        if (event.target.value.endsWith('.')) {
            const tempMaxValue = event.target.value.slice(0, -1);
            setValue([Number(value[0]), Number(tempMaxValue)]);
            setRangeGradeMax(tempMaxValue);
        } else {
            const numValue = Number(event.target.value);
            if (numValue === 0) {
                setValue([Number(value[0]), Number(1)]);
                setRangeGradeMax(1);
            }
            if (numValue > 10) {
                setValue([Number(value[0]), Number(10)]);
                setRangeGradeMax(10);
            }
        }
    };

    useEffect(() => {
        setRangeGradeMin(Number(value[0]));
        setRangeGradeMax(Number(value[1]));
    }, [value]);

    const [isNearMint, setIsNearMint] = useState(false);
    const [isExcellent, setIsExcellent] = useState(false);
    const [isVeryGood, setIsVeryGood] = useState(false);
    const [isPoor, setIsPoor] = useState(false);
    const toggleRawModernActions = () => {
        if (rawModernActionButtonTitle === 'Select All') {
            setIsNearMint(true);
            setIsExcellent(true);
            setIsVeryGood(true);
            setIsPoor(true);
        } else {
            setIsNearMint(false);
            setIsExcellent(false);
            setIsVeryGood(false);
            setIsPoor(false);
        }
        setRawModernActionButtonTitle(rawModernActionButtonTitle === 'Select All' ? 'Clear All' : 'Select All');
    };
    const [isMINT, setIsMINT] = useState(false);
    const [isNM_MINT, setIsNM_MINT] = useState(false);
    const [isNM, setIsNM] = useState(false);
    const [isEX_MT, setIsEX_MT] = useState(false);
    const [isEX, setIsEX] = useState(false);
    const [isVG_EX, setIsVG_EX] = useState(false);
    const [isVG, setIsVG] = useState(false);
    const [isGood_V, setIsGood_V] = useState(false);
    const [isFair, setIsFair] = useState(false);
    const [isPoor_V, setIsPoor_V] = useState(false);
    const toggleRawVintageActions = () => {
        if (rawVintageActionButtonTitle === 'Select All') {
            setIsMINT(true);
            setIsNM_MINT(true);
            setIsNM(true);
            setIsEX_MT(true);
            setIsEX(true);
            setIsVG_EX(true);
            setIsVG(true);
            setIsGood_V(true);
            setIsFair(true);
            setIsPoor_V(true);
        } else {
            setIsMINT(false);
            setIsNM_MINT(false);
            setIsNM(false);
            setIsEX_MT(false);
            setIsEX(false);
            setIsVG_EX(false);
            setIsVG(false);
            setIsGood_V(false);
            setIsFair(false);
            setIsPoor_V(false);
        }
        setRawVintageActionButtonTitle(rawVintageActionButtonTitle === 'Select All' ? 'Clear All' : 'Select All');
    };
    const [isPSA, setIsPSA] = useState(false);
    const [isSGC, setIsSGC] = useState(false);
    const [isBGS, setIsBGS] = useState(false);
    const [isCSG, setIsCSG] = useState(false);
    const [isPSA_DNA, setIsPSA_DNA] = useState(false);
    const [isBVG, setIsBVG] = useState(false);
    const [isCGC, setIsCGC] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const toggleGradingCompanyActions = () => {
        if (gradingCompanyActionButtonTitle === 'Select All') {
            setIsPSA(true);
            setIsSGC(true);
            setIsBGS(true);
            setIsCSG(true);
            setIsPSA_DNA(true);
            setIsBVG(true);
            setIsCGC(true);
            setIsOther(true);
        } else {
            setIsPSA(false);
            setIsSGC(false);
            setIsBGS(false);
            setIsCSG(false);
            setIsPSA_DNA(false);
            setIsBVG(false);
            setIsCGC(false);
            setIsOther(false);
        }
        setGradingCompanyActionButtonTitle(gradingCompanyActionButtonTitle === 'Select All' ? 'Clear All' : 'Select All');
    };
    const [isAuthentic, setIsAuthentic] = useState(false);
    const [isCertified, setIsCertified] = useState(false);
    const [isAutograph, setIsAutograph] = useState(false);
    const handleClearAll = () => {
        setIsNearMint(false);
        setIsExcellent(false);
        setIsVeryGood(false);
        setIsPoor(false);
        setIsMINT(false);
        setIsNM_MINT(false);
        setIsNM(false);
        setIsEX_MT(false);
        setIsEX(false);
        setIsVG_EX(false);
        setIsVG(false);
        setIsGood_V(false);
        setIsFair(false);
        setIsPoor_V(false);
        setIsPSA(false);
        setIsSGC(false);
        setIsBGS(false);
        setIsCSG(false);
        setIsPSA_DNA(false);
        setIsBVG(false);
        setIsCGC(false);
        setIsOther(false);
        setRangeGradeMin(1);
        setRangeGradeMax(10);
        setValue([Number(1), Number(10)]);
        setRawModernActionButtonTitle('Select All');
        setRawVintageActionButtonTitle('Select All');
        setGradingCompanyActionButtonTitle('Select All');
        setIsAuthentic(false);
        setIsCertified(false);
        setIsAutograph(false);
    };

    const handleSelect = () => {
        const conditionsData = {
            type: 'all',
            raw: [],
            graded: {
                company: [],
                rangeStart: rangeGradeMin,
                rangeEnd: rangeGradeMax,
                letterGrades: [],
            },
        };

        if (isNearMint) {
            conditionsData.raw.push('near_mint');
        }
        if (isExcellent) {
            conditionsData.raw.push('excellent');
        }
        if (isVeryGood) {
            conditionsData.raw.push('very_good');
        }
        if (isPoor) {
            conditionsData.raw.push('poor');
        }
        if (isMINT) {
            conditionsData.raw.push('mint');
        }
        if (isNM_MINT) {
            conditionsData.raw.push('nm-mint');
        }
        if (isNM) {
            conditionsData.raw.push('nm');
        }
        if (isEX_MT) {
            conditionsData.raw.push('ex-mt');
        }
        if (isEX) {
            conditionsData.raw.push('ex');
        }
        if (isVG_EX) {
            conditionsData.raw.push('vg-ex');
        }
        if (isVG) {
            conditionsData.raw.push('vg');
        }
        if (isGood_V) {
            conditionsData.raw.push('good-v');
        }
        if (isFair) {
            conditionsData.raw.push('fair');
        }
        if (isPoor_V) {
            conditionsData.raw.push('poor-v');
        }

        if (isPSA) {
            conditionsData.graded.company.push('psa');
        }
        if (isSGC) {
            conditionsData.graded.company.push('sgc');
        }
        if (isBGS) {
            conditionsData.graded.company.push('bgs');
        }
        if (isCSG) {
            conditionsData.graded.company.push('csg');
        }
        if (isPSA_DNA) {
            conditionsData.graded.company.push('psa_dna');
        }
        if (isBVG) {
            conditionsData.graded.company.push('bvg');
        }
        if (isCGC) {
            conditionsData.graded.company.push('cgc');
        }
        if (isOther) {
            conditionsData.graded.company.push('other');
        }

        if (isAuthentic) {
            conditionsData.graded.letterGrades.push('A');
            conditionsData.graded.company.push('psa');
            conditionsData.graded.company.push('sgc');
            conditionsData.graded.company.push('bgs');
            conditionsData.graded.company.push('csg');
            conditionsData.graded.company.push('bvg');
            conditionsData.graded.company.push('cgc');
            conditionsData.graded.company.push('other');
        }
        if (isCertified) {
            conditionsData.graded.letterGrades.push('CERT');
            conditionsData.graded.company.push('psa_dna');
        }
        if (isAutograph) {
            conditionsData.graded.letterGrades.push('AUTO');
            conditionsData.graded.company.push('psa');
            conditionsData.graded.company.push('sgc');
            conditionsData.graded.company.push('bgs');
            conditionsData.graded.company.push('csg');
            conditionsData.graded.company.push('bvg');
            conditionsData.graded.company.push('cgc');
            conditionsData.graded.company.push('other');
        }

        if (parseFloat(rangeGradeMin) !== 1.0 || parseFloat(rangeGradeMax) !== 10.0) {
            if (conditionsData.graded.company.length === 0) {
                conditionsData.graded.company.push('psa');
                conditionsData.graded.company.push('sgc');
                conditionsData.graded.company.push('bgs');
                conditionsData.graded.company.push('csg');
                conditionsData.graded.company.push('psa_dna');
                conditionsData.graded.company.push('bvg');
                conditionsData.graded.company.push('cgc');
                conditionsData.graded.company.push('other');
            }
        }

        if (conditionsData.raw.length > 0) {
            conditionsData.type = 'raw';
        }
        if (conditionsData.graded.company.length > 0) {
            conditionsData.type = 'graded';
        }
        if (conditionsData.raw.length > 0 && conditionsData.graded.company.length > 0) {
            conditionsData.type = 'all';
        }
        onClose(conditionsData, true);
    };

    const resetUserSelections = () => {
        handleClearAll();
        const {gradeRangeStart, gradeRangeEnd, gradingCompany, letterGrade, rawCondition} = cleanedSearchQuery;
        if (gradeRangeStart && gradeRangeEnd) {
            setValue([Number(gradeRangeStart), Number(gradeRangeEnd)]);
            setRangeGradeMin(gradeRangeStart);
            setRangeGradeMax(gradeRangeEnd);
        }
        if (gradingCompany) {
            gradingCompany.forEach(company => {
                switch (company) {
                    case 'psa':
                        setIsPSA(true);
                        break;
                    case 'sgc':
                        setIsSGC(true);
                        break;
                    case 'bgs':
                        setIsBGS(true);
                        break;
                    case 'csg':
                        setIsCSG(true);
                        break;
                    case 'psa_dna':
                        setIsPSA_DNA(true);
                        break;
                    case 'bvg':
                        setIsBVG(true);
                        break;
                    case 'cgc':
                        setIsCGC(true);
                        break;
                    case 'other':
                        setIsOther(true);
                        break;
                }
            });
            setGradingCompanyActionButtonTitle('Clear All');
        }
        if (letterGrade) {
            letterGrade.forEach(letter => {
                switch (letter) {
                    case 'A':
                        setIsAuthentic(true);
                        break;
                    case 'CERT':
                        setIsCertified(true);
                        break;
                    case 'AUTO':
                        setIsAutograph(true);
                        break;
                }
            });
        }
        if (rawCondition) {
            rawCondition.forEach(condition => {
                switch (condition) {
                    case 'near_mint':
                        setIsNearMint(true);
                        break;
                    case 'excellent':
                        setIsExcellent(true);
                        break;
                    case 'very_good':
                        setIsVeryGood(true);
                        break;
                    case 'poor':
                        setIsPoor(true);
                        break;
                    case 'mint':
                        setIsMINT(true);
                        break;
                    case 'nm-mint':
                        setIsNM_MINT(true);
                        break;
                    case 'nm':
                        setIsNM(true);
                        break;
                    case 'ex-mt':
                        setIsEX_MT(true);
                        break;
                    case 'ex':
                        setIsEX(true);
                        break;
                    case 'vg-ex':
                        setIsVG_EX(true);
                        break;
                    case 'vg':
                        setIsVG(true);
                        break;
                    case 'good-v':
                        setIsGood_V(true);
                        break;
                    case 'fair':
                        setIsFair(true);
                        break;
                    case 'poor-v':
                        setIsPoor_V(true);
                        break;
                }
            });
            if (isNearMint && isExcellent && isVeryGood && isPoor) {
                setRawModernActionButtonTitle('Clear All');
            }
            if (isMINT && isNM_MINT && isNM && isEX_MT && isEX && isVG_EX && isVG && isGood_V && isFair && isPoor_V) {
                setRawVintageActionButtonTitle('Clear All');
            }
        }
    };

    useEffect(() => {
        if (cleanedSearchQuery) {
            resetUserSelections();
        }
    }, [cleanedSearchQuery]);

    const onClose = (data, hasNewData?: false) => {
        if (hasNewData) {
            handleClose(data);
        } else {
            resetUserSelections();
            handleClose({});
        }
    };

    return (
        <BSCModal headerLabel="Select Conditions" open={open} onClose={onClose} hasCustomHeaderStyling={true}>
            <div>
                <Grid container wrap="wrap" style={{overflowX: 'hidden'}}>
                    <Grid item xs={12} md={6} style={isDesktop ? {maxWidth: 'calc(50% - 16px)', marginRight: 16} : {}}>
                        <Grid item xs={12} style={{marginBottom: 16}}>
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <BSCTypography bold label="Raw (Modern):" />
                                <Button
                                    className={classes.actionButton}
                                    onClick={toggleRawModernActions}
                                    style={rawModernActionButtonTitle === 'Clear All' ? {color: '#EF2424'} : null}
                                >
                                    {rawModernActionButtonTitle}
                                </Button>
                            </div>
                            <div className={classes.innerBoxWide}>
                                <Checkbox
                                    color="primary"
                                    checked={isNearMint}
                                    onClick={() => setIsNearMint(!isNearMint)}
                                    className={classes.checkBox}
                                />
                                <BSCTypography size12 label="Near Mint" />
                            </div>
                            <div className={classes.innerBoxWide}>
                                <Checkbox
                                    color="primary"
                                    checked={isExcellent}
                                    onClick={() => setIsExcellent(!isExcellent)}
                                    className={classes.checkBox}
                                />
                                <BSCTypography size12 label="Excellent" />
                            </div>
                            <div className={classes.innerBoxWide}>
                                <Checkbox
                                    color="primary"
                                    checked={isVeryGood}
                                    onClick={() => setIsVeryGood(!isVeryGood)}
                                    className={classes.checkBox}
                                />
                                <BSCTypography size12 label="Very Good" />
                            </div>
                            <div className={classes.innerBoxWide}>
                                <Checkbox
                                    color="primary"
                                    checked={isPoor}
                                    onClick={() => setIsPoor(!isPoor)}
                                    className={classes.checkBox}
                                />
                                <BSCTypography size12 label="Poor" />
                            </div>
                        </Grid>

                        <Grid item xs={12} style={{marginBottom: 16}}>
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <BSCTypography bold label="Raw (Vintage):" />
                                <Button
                                    className={classes.actionButton}
                                    onClick={toggleRawVintageActions}
                                    style={rawVintageActionButtonTitle === 'Clear All' ? {color: '#EF2424'} : null}
                                >
                                    {rawVintageActionButtonTitle}
                                </Button>
                            </div>
                            <Grid item container xs={12}>
                                <Grid item xs={6} style={{padding: '0px 2px 0px 0px'}}>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isMINT}
                                            onClick={() => setIsMINT(!isMINT)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="MINT" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isNM_MINT}
                                            onClick={() => setIsNM_MINT(!isNM_MINT)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="NM-MINT" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isNM}
                                            onClick={() => setIsNM(!isNM)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="NM" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isEX_MT}
                                            onClick={() => setIsEX_MT(!isEX_MT)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="EX-MT" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isEX}
                                            onClick={() => setIsEX(!isEX)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="EX" />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0px 2px 0px 16px'}}>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isVG_EX}
                                            onClick={() => setIsVG_EX(!isVG_EX)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="VG-EX" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isVG}
                                            onClick={() => setIsVG(!isVG)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="VG" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isGood_V}
                                            onClick={() => setIsGood_V(!isGood_V)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="Good" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isFair}
                                            onClick={() => setIsFair(!isFair)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="Fair" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isPoor_V}
                                            onClick={() => setIsPoor_V(!isPoor_V)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="Poor" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        className={!isDesktop ? '' : classes.verticalSeparator}
                        style={isDesktop ? {maxWidth: 'calc(50% - 2px)'} : {}}
                    >
                        <Grid item xs={12} style={isDesktop ? {marginLeft: 14, height: 106} : {marginBottom: 16}}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <BSCTypography bold label="Grading Company:" />
                                <Button
                                    className={classes.actionButton}
                                    onClick={toggleGradingCompanyActions}
                                    style={gradingCompanyActionButtonTitle === 'Clear All' ? {color: '#EF2424'} : null}
                                >
                                    {gradingCompanyActionButtonTitle}
                                </Button>
                            </div>
                            <Grid item container xs={12}>
                                <Grid item xs={6} style={{padding: '0px 2px 0px'}}>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isPSA}
                                            onClick={() => setIsPSA(!isPSA)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="PSA" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isSGC}
                                            onClick={() => setIsSGC(!isSGC)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="SGC" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isBGS}
                                            onClick={() => setIsBGS(!isBGS)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="BGS" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isCSG}
                                            onClick={() => setIsCSG(!isCSG)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="CSG" />
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{padding: '0px 2px 0px 16px'}}>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isPSA_DNA}
                                            onClick={() => setIsPSA_DNA(!isPSA_DNA)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="PSA/DNA" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isBVG}
                                            onClick={() => setIsBVG(!isBVG)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="BVG" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isCGC}
                                            onClick={() => setIsCGC(!isCGC)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="CGC" />
                                    </div>
                                    <div className={classes.innerBox}>
                                        <Checkbox
                                            color="primary"
                                            checked={isOther}
                                            onClick={() => setIsOther(!isOther)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="Other" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            container
                            style={{
                                marginLeft: isDesktop ? 14 : 0,
                                marginBottom: 16,
                                height: 112,
                                maxWidth: isDesktop ? 240 : null,
                                flexWrap: 'nowrap',
                                flexDirection: 'column',
                            }}
                        >
                            <BSCTypography bold label="Grade:" />
                            <Grid item xs={12} style={{position: 'relative', top: 2}}>
                                <div className={classes.rangeGradeTextFieldArea}>
                                    <BSCTextField
                                        className={classes.rangeGrade}
                                        onChange={handleRangeGradeMin}
                                        onBlur={handleRangeGradeMinBlur}
                                        value={rangeGradeMin}
                                    />
                                    <BSCTypography size12 style={{flex: 1}} label="to" />
                                    <BSCTextField
                                        className={classes.rangeGrade}
                                        onChange={handleRangeGradeMax}
                                        onBlur={handleRangeGradeMaxBlur}
                                        value={rangeGradeMax}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{position: 'relative', top: 4}}>
                                <BSCCustomSlider
                                    ThumbComponent={BSCCustomSliderThumbComponent}
                                    value={value}
                                    marks={marks}
                                    min={1}
                                    max={10}
                                    step={0.5}
                                    onChange={handleChange}
                                    style={{width: isDesktop ? 226 : 310, marginLeft: 6}}
                                />
                            </Grid>
                            <Grid item container xs={12}>
                                {/* <Grid item xs={6}>
                                    &nbsp;
                                </Grid> */}
                                {/* <Grid item container xs={12} direction="column" style={isDesktop ? {padding: '0 2px 0 16px'} : {}}> */}
                                <Grid item container xs={12} direction="column">
                                    <div className={classes.innerBoxWide}>
                                        <Checkbox
                                            color="primary"
                                            checked={isAuthentic}
                                            onClick={() => setIsAuthentic(!isAuthentic)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="Authentic" />
                                    </div>
                                    <div className={classes.innerBoxWide} style={{marginTop: 2}}>
                                        <Checkbox
                                            color="primary"
                                            checked={isCertified}
                                            onClick={() => setIsCertified(!isCertified)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="Certified" />
                                    </div>
                                    <div className={classes.innerBoxWide} style={{marginTop: 2}}>
                                        <Checkbox
                                            color="primary"
                                            checked={isAutograph}
                                            onClick={() => setIsAutograph(!isAutograph)}
                                            className={classes.checkBox}
                                        />
                                        <BSCTypography size12 label="Autograph" />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.buttonsSection} style={isDesktop ? null : {width: '100%', margin: '100px 0 200px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <BSCButton fullWidth color="primary" onClick={handleClearAll}>
                                    <BSCTypography label="Clear" />
                                </BSCButton>
                            </Grid>
                            <Grid item xs={6}>
                                <BSCButton fullWidth color="secondary" onClick={handleSelect}>
                                    <BSCTypography label="Select" />
                                </BSCButton>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </BSCModal>
    );
}

export default SelectConditonsModal;
