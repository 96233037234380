/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, IconButton, makeStyles} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classNames from 'classnames';
import React, {ReactNode, useState} from 'react';
import SwiperCore from 'swiper';
import {Navigation} from 'swiper/modules';
import {Swiper} from 'swiper/react';
import 'swiper/scss';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';
SwiperCore.use([Navigation]);

const useStyles = props =>
    makeStyles({
        navigationBtn: {
            backgroundColor: 'lightgrey',
            padding: '8px',
            color: 'white',
            '&:hover': {
                color: 'black',
                backgroundColor: 'lightgrey',
            },
        },
        backBtn: {
            left: 0,
            top: '35%',
            position: 'absolute',
            zIndex: 5,
        },
        forwardBtn: {
            right: 0,
            top: '35%',
            position: 'absolute',
            zIndex: 5,
        },
        swiper: {
            overflow: 'clip',
        },
    });

interface BSCSliderProps {
    children: ReactNode;
    spaceBetween?: number;
    slidesPerGroup?: number;
    slidesPerView?: number;
    onSlideChange?: () => void;
    onSwiper?: () => void;
    swiperKey: string;
    hideNavOnDesktop: boolean;
    marginTop: string;
}

function BSCSwiper({
    children,
    spaceBetween = 50,
    slidesPerView = 5,
    slidesPerGroup = 5,
    swiperKey,
    hideNavOnDesktop = false,
    marginTop = '5%',
}: BSCSliderProps) {
    const classes = useStyles({marginTop})();
    const {isTablet, isMobile} = useScreenSnap();

    const prevClass = `swiper-pre-${swiperKey}`;
    const nextClass = `swiper-next-${swiperKey}`;

    // const swiperConfig = isMobile || isTablet ? {slidesPerGroup: slidesPerGroup} : {slidesPerGroup: slidesPerView};
    const [isHoveringOverSwiper, setIsHoveringOverSwiper] = useState(false);

    return (
        <Box
            display="flex"
            position="relative"
            onMouseOver={() => setIsHoveringOverSwiper(true)}
            onMouseOut={() => setIsHoveringOverSwiper(false)}
        >
            <div hidden={isMobile || isTablet || ((!isHoveringOverSwiper || hideNavOnDesktop) && !isMobile && !isTablet)}>
                <IconButton
                    key={`prev-button-${swiperKey}`}
                    className={classNames(classes.navigationBtn, classes.backBtn, prevClass)}
                    aria-controls={'swiper-button-prev'}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <ArrowBackIcon />
                </IconButton>

                <IconButton
                    key={`next-button-${swiperKey}`}
                    className={classNames(classes.navigationBtn, classes.forwardBtn, nextClass)}
                    aria-controls={'swiper-button-next'}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <ArrowForwardIcon />
                </IconButton>
            </div>
            <Swiper
                key={swiperKey}
                spaceBetween={spaceBetween}
                navigation={{
                    prevEl: `.${prevClass}`,
                    nextEl: `.${nextClass}`,
                }}
                slidesPerView={slidesPerView}
                slidesPerGroup={3}
                // {...swiperConfig}
                speed={1500}
            >
                {children}
            </Swiper>
        </Box>
    );
}

export default BSCSwiper;
