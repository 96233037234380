import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import Box from '@material-ui/core/Box';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import {memo, useEffect, useState} from 'react';
import BSCSelectSplitButton from '../../../../features/common/bscSelectSplitButton/bscSelectSplitButton';
import MenuItem from '@material-ui/core/MenuItem';
import Normalizer from '../../../../client/common/normalizer/normalizer';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import {Card, CartItem} from '../../../../client/openapitools/marketplace';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';

const useStyles = makeStyles((theme: Theme) => ({
    boxWrap: {
        flex: 1,
        display: 'flex',
    },
    priceLeft: {
        fontSize: '16px',
        fontWeight: 400,
    },
    cartTitle: {
        fontSize: '18px', // theme.typography.h3.fontSize,
        marginBottom: theme.spacing(0.5),
    },
    setName: {
        fontSize: '14px',
        fontWeight: 400,
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0),
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    dot: {
        margin: theme.spacing(0, 0.5),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0),
        },
    },
    cardImg: {
        width: '95%',
    },
    condition: {
        fontSize: '14px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
        marginBottom: 28,
    },
    deleteBtn: {
        height: 34,
        fontSize: '12px',
    },
    qtyPadding: {
        paddingLeft: '10px',
    },
}));

interface CheckoutCardProps {
    productInfo: Card;
    // TODO: Fix this is it necessary to have both anymore..could we not just pass the Cart Item from product after the add?
    cartItem: CartItem;
    loading?: boolean;
    onDeleteCartItem?: (cartItemId) => void;
    onQuantitySelect?: (quantity, cartItem) => void;
}

function CheckoutCard({productInfo, cartItem, loading, onDeleteCartItem, onQuantitySelect}: CheckoutCardProps) {
    const classes = useStyles();
    const {addToast} = useReduxToast();
    const [selected, setSelected] = useState<number>(cartItem?.quantity ?? 1);
    const condition = useCardNormalizer();
    const imgFront = cartItem?.productItem?.sellerImgFront ?? productInfo?.stockImgFront ?? '';

    const handleDeleteCartItem = cartItemId => {
        if (onDeleteCartItem) {
            onDeleteCartItem(cartItemId);
            addToast({
                severity: 'success',
                message: 'Cart item successfully removed',
            });
        }
    };

    const [isVintage, setIsVintage] = useState(false);
    useEffect(() => {
        if (productInfo && cartItem.productItem.condition) {
            if (Number(productInfo.year) <= 1980) {
                setIsVintage(true);
            } else {
                setIsVintage(false);
            }
        }
    }, [productInfo, cartItem]);

    return (
        <BSCCardBox className={classes.boxWrap}>
            <Grid container spacing={2}>
                <Grid container direction="column" item xs={4} sm={3} justifyContent="center" alignItems="center">
                    <BSCImage className={classes.cardImg} src={Normalizer.getS3ImagePath(imgFront)} />
                </Grid>
                {productInfo && (
                    <Grid xs={8} sm={9} item container direction="column" justifyContent="center" style={{flex: 1}}>
                        <Box>
                            <BSCTypography className={classes.cartTitle} semibold label={condition.getPlayerName(productInfo)} />
                            <BSCDivider noMargin />
                            <Grid container alignItems="center" spacing={0}>
                                <BSCTypography className={classes.setName} label={condition.getSetAndVariant(productInfo)} />
                            </Grid>
                            <BSCTypography
                                className={classes.condition}
                                label={condition.getProductTypeAndCondition(cartItem.productItem, isVintage)}
                            />
                            <Grid container alignItems="center" spacing={0}>
                                <BSCCurrency
                                    bold
                                    className={classes.priceLeft}
                                    amount={cartItem?.price ? cartItem?.price : cartItem?.productItem?.price}
                                />
                                {!onDeleteCartItem && !onQuantitySelect && (
                                    <BSCTypography variant="subtitle1" className={classes.qtyPadding} label={`(Quantity: ${selected})`} />
                                )}
                            </Grid>
                            {onDeleteCartItem && onQuantitySelect && (
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <BSCSelectSplitButton
                                            alignItems={null}
                                            leftButtonLabel={selected ?? '-'}
                                            rightButtonLabel={`of ${cartItem?.productItem?.availableQuantity}`}
                                        >
                                            {Array.from(Array(cartItem?.productItem?.availableQuantity ?? 0), (_, i) => (
                                                <MenuItem
                                                    key={i}
                                                    onClick={() => {
                                                        onQuantitySelect(i + 1, cartItem);
                                                        setSelected(i + 1);
                                                    }}
                                                    selected={i + 1 === selected}
                                                    value={i + 1}
                                                >
                                                    {i + 1}
                                                </MenuItem>
                                            ))}
                                        </BSCSelectSplitButton>
                                    </Grid>
                                    <Grid item>
                                        <BSCButton
                                            className={classes.deleteBtn}
                                            loading={loading}
                                            color="secondary"
                                            onClick={() => handleDeleteCartItem(cartItem?.id)}
                                        >
                                            Remove
                                        </BSCButton>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </BSCCardBox>
    );
}

export default memo(CheckoutCard);
