import {Product} from '../../../../openapitools/marketplace';

const listingTypes = {
    GRADED: 'graded',
    RAW: 'raw',
};

const checkFilter = (filter, toCheck) => (filter ? toCheck === filter : true);

const CardListingNormalizer = {
    filterRawCards: (cardListings: Product[], {selectedCondition}) => {
        if (cardListings) {
            const filteredCardListing = cardListings.filter(card => {
                return card.productType === listingTypes.RAW && checkFilter(selectedCondition, card.condition);
            });
            return filteredCardListing.length > 0 ? filteredCardListing : null;
        }

        return null;
    },
    filterGradedCards: (cardListings: Product[], {selectedCompany, selectedGrade}) => {
        if (cardListings) {
            const filteredCardListing = cardListings.filter(
                card =>
                    card.productType === listingTypes.GRADED &&
                    checkFilter(selectedCompany, card.gradingCompany) &&
                    checkFilter(selectedGrade, card.grade)
            );
            return filteredCardListing.length > 0 ? filteredCardListing : null;
        }

        return null;
    },
    getSelectedListingOffer: selectedListing => selectedListing?.offers ?? null,
};

export default CardListingNormalizer;
