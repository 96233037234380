import {Box, Grid, makeStyles, MobileStepper, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useAuthFunctions} from '../../../../../AuthContext';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import {CarouselItemsResult} from '../../../../../client/s3/mpCarousel/carouselClient';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import BSCSlider from '../../../../../features/common/bscSlider/bscSlider';
import useReduxUser from '../../../../../features/hooks/redux/user/useReduxUser';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../../redux/reducers';
import {useStyles} from './styles';

const baseStyles = makeStyles(() => ({
    horizontalRibbon: {
        marginBottom: 6,
        width: '100%',
        overflowX: 'scroll',
        ['&::-webkit-scrollbar']: {
            display: 'none !important',
        },
    },
    sliderNoWrap: {
        flexWrap: 'nowrap !important' as 'nowrap',
    },
}));
interface HomeCarouselProps {
    carouselItems: CarouselItemsResult | null;
}

const HomeCarousel = ({carouselItems}: HomeCarouselProps) => {
    const baseClasses = baseStyles();
    const {isTablet, isMobile} = useScreenSnap();
    const classes = useStyles();
    const history = useHistory();
    const {userState} = useReduxUser();
    const authFunc = useAuthFunctions();
    const [activeStep, setActiveStep] = useState(0);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    function handleOnClick(slugTarget) {
        if (userState) {
            if (userState.roleId === '2') {
                //is seller
                history.push(slugTarget);
            } else if (userState.roleId === '1') {
                //is buyer
                history.push('/sellers/signup');
            }
        } else {
            authFunc.login();
        }
    }

    return (
        <Box className={pwaOS === null ? classes.root : classes.pwaRoot}>
            {pwaOS === null && carouselItems && (
                <div>
                    <BSCSlider className={classes.carouselWrapper} onSlide={setActiveStep} slideColumns={1} slidesPerNext={1} spacing={0}>
                        {carouselItems.bannerItems.map((item, index) => (
                            <Box className={classes.carouselImgWrapper} key={index} to={item.slug} component={Link}>
                                <BSCImage
                                    className={classes.image}
                                    style={{height: '100%', objectFit: 'cover !important', width: 'fit-content'}}
                                    src={Normalizer.getS3ImagePath(
                                        isMobile ? item.images.mobile : isTablet ? item.images.tablet : item.images.desktop
                                    )}
                                    alt={item.imgAlt}
                                />
                                <Box className={classes.carouselImgOverlay}>
                                    <Typography className={classes.headline} component={isMobile ? 'h3' : 'h2'}>
                                        {item.headerText}
                                    </Typography>
                                    {item?.buttonText !== '' && (
                                        <BSCButton className={classes.carouselBtn} onClick={() => handleOnClick(item.slug)} color="primary">
                                            {item.buttonText}
                                        </BSCButton>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </BSCSlider>
                    <MobileStepper
                        classes={{dotActive: classes.dotActive}}
                        activeStep={activeStep}
                        backButton={null}
                        className={classes.stepper}
                        nextButton={null}
                        position="static"
                        steps={carouselItems.bannerItems.length}
                        variant="dots"
                    />
                </div>
            )}
            {pwaOS !== null && (
                <Grid container alignItems="center" style={{padding: '12px 0 0 12px', margin: 'auto'}}>
                    <Grid item xs={12} className={baseClasses.horizontalRibbon}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={'flex-start'}
                            className={baseClasses.sliderNoWrap}
                        >
                            <Grid item style={{minWidth: 310, maxHeight: 100}}>
                                <BSCImage
                                    style={{height: 100}}
                                    src={process.env.PUBLIC_URL + 'img/test-thumbnails/rectangle3.png'}
                                    title={'placeholder'}
                                />
                            </Grid>
                            <Grid item style={{minWidth: 310, maxHeight: 100}}>
                                <BSCImage
                                    style={{height: 100}}
                                    src={process.env.PUBLIC_URL + 'img/test-thumbnails/rectangle3.png'}
                                    title={'placeholder'}
                                />
                            </Grid>
                            <Grid item style={{minWidth: 310, maxHeight: 100}}>
                                <BSCImage
                                    style={{height: 100}}
                                    src={process.env.PUBLIC_URL + 'img/test-thumbnails/rectangle3.png'}
                                    title={'placeholder'}
                                />
                            </Grid>
                            <Grid item style={{minWidth: 310, maxHeight: 100}}>
                                <BSCImage
                                    style={{height: 100}}
                                    src={process.env.PUBLIC_URL + 'img/test-thumbnails/rectangle3.png'}
                                    title={'placeholder'}
                                />
                            </Grid>
                            <Grid item style={{minWidth: 310, maxHeight: 100}}>
                                <BSCImage
                                    style={{height: 100}}
                                    src={process.env.PUBLIC_URL + 'img/test-thumbnails/rectangle3.png'}
                                    title={'placeholder'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default HomeCarousel;
