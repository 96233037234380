import {s3Client} from '..';
import useApi from '../../features/hooks/redux/apiConfig/useApi';
const serviceUrls = {
    urlKey: 's3/references',
};

// const messageRefUrls = {
//     urlKey: 'common/reference/message',
// };

// const orderHistoryRefUrls = {
//     urlKey: 'common/reference/orderHistory',
// };

const api = useApi();

const ReferencesClient = {
    getReferences: () => s3Client.get(serviceUrls.urlKey).then(res => res.data),
    getMessageReferences: () => api.commonApi.referenceApi.getMessageReference().then(res => res.data),
    //getOrderHistoryReferences: () => Client.get(orderHistoryRefUrls.urlKey).then(res => res.data),
};

export default ReferencesClient;
