import React from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import {getFormatedDate} from '../../../../../../../packages/core/src/util/util';
import classNames from 'classnames';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import BSCScroller from '../../../../../../../features/common/bscScroller/bscScroller';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'none',
        boxShadow: 'none',
        padding: theme.spacing(0),
        '& > MuiSelect-icon': {
            color: '#EA3E2E !important',
        },
    },
    rootMobile: {
        background: 'none',
        padding: theme.spacing(0),
        '& > MuiSelect-icon': {
            color: '#EA3E2E !important',
        },
    },
    header: {
        border: '1px solid #DAE0E6',
        padding: 12,
        background: '#fff',
        boxShadow: 'inset 0px -4px 4px #dae0e6',
        textAlign: 'center',
        borderRadius: '2px 2px 0px 0',
    },
    menu: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0.5),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5, 2),
        },
    },
    heading: {
        [theme.breakpoints.down('md')]: {
            background: theme.palette.common.white,
            border: '1px solid #DAE0E6',
            boxSizing: 'border-box',
            boxShadow: '0px 2px 0px #B8C2CC',
            padding: theme.spacing(1.25),
            width: '100%',
            margin: '0 0 2px 0',
            justifyContent: 'center',
        },
    },
    formControl: {
        minWidth: 74,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #DAE0E6',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        borderRadius: '4px',
        marginLeft: theme.spacing(1),
    },
    pageLabel: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    pageSizeLabel: {
        display: 'flex',
        height: '48px',
        alignItems: 'center',
    },
    expandIcon: {
        color: '#EA3E2E',
    },
    table: {
        background: '#f5f5f5',
    },
    tableMobile: {
        background: 'transparent',
    },
    tableHeader: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    th: {
        color: '#41474C',
        padding: theme.spacing(1, 2),
        background: '#DAE0E6',
        borderBottom: 'none',
    },
    td: {
        background: '#f5f8fa',
        borderTop: '2px solid #B8C2CC',
        fontSize: '14px',
        padding: theme.spacing(2),
    },
    tdFirstChild: {
        background: '#e9edf2',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    tdMobile: {
        background: '#f5f8fa',
        // borderBottom: '2px solid #B8C2CC',
        fontSize: '14px',
        padding: theme.spacing(2),
    },
    bgColor: {
        background: '#e9edf2',
    },
    tdFirstChildMobile: {
        background: '#e9edf2',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    fromName: {
        fontSize: '18px',
        marginBottom: theme.spacing(0.5),
    },
    date: {
        fontSize: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    fromNameMobile: {
        fontSize: '14px',
        marginBottom: theme.spacing(0.5),
    },
    dateMobile: {
        fontSize: '12px',
    },
    bodyDesktop: {
        flex: 1,
        whiteSpace: 'break-spaces',
    },
    bodyMobile: {
        fontSize: '12px',
        whiteSpace: 'break-spaces',
    },
    badge: {
        flex: 0,
        position: 'relative',
        top: -12,
        left: -12,
        background: 'red',
        minWidth: 20,
        maxHeight: 20,
        borderRadius: '50%',
        color: '#fff',
    },
    badgeData: {
        paddingTop: 3,
        paddingLeft: 6,
        fontWeight: 'bold',
        fontFamily: 'Poppins',
        fontSize: 12,
    },
    loading: {
        width: 100,
        height: 100,
    },
}));

const RecentMessages = ({messages, loading}) => {
    const classes = useStyles();
    const {isDesktop, isMobile, isBelowTablet} = useScreenSnap();
    const downloadAttachment = attachments => {
        attachments.forEach(a => {
            window.open(a.url, '_blank');
        });
    };

    return (
        <BSCCardBox className={isDesktop ? classes.root : classes.rootMobile}>
            <div className={classes.header}>
                <Grid container direction="row" justifyContent="center">
                    <BSCTypography style={{fontSize: 16}} label="Messages" />
                </Grid>
            </div>
            <BSCScroller maxHeight={500}>
                <div className={isDesktop ? classes.table : classes.tableMobile}>
                    <div className={classes.tableHeader}>
                        <Grid container>
                            <Grid item xs={4} className={classes.th}>
                                <BSCTypography label="From" />
                            </Grid>
                            <Grid item xs={8} className={classes.th}>
                                <BSCTypography label="Content" />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        {loading && <BSCLoading loading={loading} />}
                        {messages?.map(message => {
                            return (
                                <Grid container key={message.userNameFrom + message.date}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        className={classes.bgColor}
                                        key="from"
                                        style={
                                            isMobile || isBelowTablet
                                                ? {height: 42, filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3))'}
                                                : {}
                                        }
                                    >
                                        <div
                                            className={
                                                isMobile || isBelowTablet
                                                    ? classNames(classes.tdMobile, classes.tdFirstChildMobile)
                                                    : classNames(classes.td, classes.tdFirstChild)
                                            }
                                            style={isMobile || isBelowTablet ? {display: 'flex', flexDirection: 'row', padding: 8} : {}}
                                        >
                                            <BSCTypography
                                                label={message.userNameFrom}
                                                className={isDesktop ? classes.fromName : classes.fromNameMobile}
                                                style={isMobile || isBelowTablet ? {flex: 1, textAlign: 'left'} : {}}
                                            />
                                            <BSCTypography
                                                label={getFormatedDate(message.date)}
                                                className={isDesktop ? classes.date : classes.dateMobile}
                                                style={isMobile || isBelowTablet ? {flex: 1, textAlign: 'left', paddingTop: 2} : {}}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={8} key="content">
                                        <div
                                            className={isDesktop ? classes.td : classes.tdMobile}
                                            style={
                                                isMobile || isBelowTablet
                                                    ? {borderBottom: '2px solid #B8C2CC', marginBottom: 8}
                                                    : {display: 'flex'}
                                            }
                                        >
                                            <pre>
                                                <BSCTypography
                                                    className={!isDesktop ? classes.bodyMobile : classes.bodyDesktop}
                                                    label={message.content}
                                                />
                                            </pre>
                                            {message?.attachments?.some(() => true) && (
                                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                                                    <AttachFileIcon
                                                        onClick={() => downloadAttachment(message.attachments)}
                                                        style={{float: 'right', cursor: 'pointer'}}
                                                    />
                                                    <div className={classes.badge}>
                                                        <div className={classes.badgeData}>{message.attachments.length}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </div>
                </div>
            </BSCScroller>
        </BSCCardBox>
    );
};

export default RecentMessages;
