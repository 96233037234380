import React, {useState, useEffect, useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import useForm from '../../../../../../../packages/core/src/hooks/useForm';
import {useQuery} from '../../../../../../../packages/core/src/hooks/useQuery';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCTextField from '../../../../../../../features/common/bscTextField/bscTextField';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import SellersFormValidationsClient from '../../../../../../../client/s3/sellersFormValidations/sellersFormValidationsClient';
import SellersFormValidationsNormalizer from '../../../../../../../client/s3/sellersFormValidations/sellersFormValidationsNormalizer';
import BSCEditToggle from '../../../../../../../features/common/bscEditToggle/bscEditToggle';
import BscToggledEditButton from '../../../../../../../features/common/bscEditIcon/bscEditIcon';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';

const PrimaryContact = ({email, phoneNumber, onSave}) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const {data: sellersFormValidations, loading: sellersFormValidationsLoading} = useQuery(
        () => SellersFormValidationsClient.getSellersFormValidations(),
        []
    );
    const {isMobile} = useScreenSnap();
    const {
        formState,
        validatedSchema: sellersFormStateValidations,
        registerFormChange,
        formComplete,
        setDefaultValues,
    } = useForm({
        validationSchema: SellersFormValidationsNormalizer.getSellerContact(sellersFormValidations),
    });
    const edit = useCallback(() => {
        setIsEdit(prevState => !prevState);
    }, [setIsEdit]);
    const onSubmit = useCallback(() => {
        const data = {
            storePhoneNumber: formState.sellerPhoneNumber,
            sellerEmailId: formState.sellerEmail,
        };

        onSave(data);
        setIsEdit(!isEdit);
    }, [onSave, setIsEdit, isEdit, formState]);

    useEffect(() => {
        setDefaultValues({
            sellerEmail: email,
            sellerPhoneNumber: phoneNumber,
        });
    }, [email, phoneNumber]);

    return (
        <BSCCardBox>
            <Grid container justifyContent="space-between">
                <BSCTypography variant="body2" label="Primary Contact" />
                <BscToggledEditButton isEdit={isEdit} onCLick={edit}></BscToggledEditButton>
            </Grid>
            <BSCDivider />
            {sellersFormValidationsLoading && <BSCLoading loading={sellersFormValidationsLoading} />}
            {!sellersFormValidationsLoading && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={5} md={3}>
                                <BSCTypography label="Email Address:" variant="subtitle1" />
                            </Grid>
                            <Grid item xs={7} md={9}>
                                <BSCEditToggle
                                    isEdit={isEdit}
                                    trueChild={
                                        <BSCTextField
                                            inputKey="sellerEmail"
                                            formStateValidations={sellersFormStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    }
                                    falseChild={<BSCTypography label={email} variant="subtitle1" maxCharacters={isMobile ? 17 : 256} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={5} md={3}>
                                <BSCTypography label="Phone Number:" variant="subtitle1" />
                            </Grid>
                            <Grid item xs={7} md={9}>
                                <BSCEditToggle
                                    isEdit={isEdit}
                                    trueChild={
                                        <BSCTextField
                                            inputKey="sellerPhoneNumber"
                                            formStateValidations={sellersFormStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    }
                                    falseChild={<BSCTypography label={phoneNumber} variant="subtitle1" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {isEdit && (
                        <Grid item xs={12}>
                            <BSCDivider />
                            <BSCButton fullWidth color="primary" disabled={!formComplete} onClick={onSubmit}>
                                <BSCTypography label="Save Changes" size14 />
                            </BSCButton>
                        </Grid>
                    )}
                </Grid>
            )}
        </BSCCardBox>
    );
};

export default PrimaryContact;
