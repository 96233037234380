import {makeStyles, Theme} from '@material-ui/core/styles';
import useScreenSnap from '../../packages/core/src/hooks/useScreenSnap';
import BSCImage from '../common/bscImage/bscImage';
import Normalizer from '../../client/common/normalizer/normalizer';
import BSCContainer from '../../features/common/bscContainer/bscContainer';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: 'black',
        maxWidth: '100vw',
        height: '100%',
        [theme.breakpoints.down('md')]: {},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
        maxWidth: '1000px',
        //width: 'fit-content',
    },
}));

function NotFound() {
    const classes = useStyles();
    const {isMobile, isTablet} = useScreenSnap();

    return (
        <BSCContainer variant="page" className={classes.root}>
            <div className={classes.image}>
                {isMobile && <BSCImage alt="Page Not Found" src={Normalizer.getS3ImagePath('/images/404-Mobile.jpg')} />}
                {isTablet && !isMobile && <BSCImage alt="Page Not Found" src={Normalizer.getS3ImagePath('/images/404-Tablet.jpg')} />}
                {!isTablet && !isMobile && <BSCImage alt="Page Not Found" src={Normalizer.getS3ImagePath('/images/404-Desktop.jpg')} />}
            </div>
        </BSCContainer>
    );
}

export default NotFound;
