import React, {useState} from 'react';
import {memo} from 'react';
import CartUserNormalizer from '../../../../client/marketplace/cart/user/CartUserClientNormalizer';
import CartDetail from './cartDetail';
import {BSCWarningAlert} from '../../../../features/common/bscAlert/bscAlert';
import {useAlertContext} from '../../../../AlertContext';
import {Cart} from '../../../../client/openapitools/marketplace';

interface CartDetailsProps {
    userCart: Cart;
    loading: boolean;
    lowDollarAmount: number;
    onDeleteCartItem?: (cartItemId) => void;
    onQuantitySelect?: (quantity, cartItem) => void;
}

function CartDetails({userCart, loading, onDeleteCartItem, onQuantitySelect, lowDollarAmount}: CartDetailsProps) {
    const sellerCartGroup = CartUserNormalizer.getSellerCartGroup(userCart);
    const {messages} = useAlertContext();
    const [expansionPanelIndex, setExpansionPanelIndex] = useState(1);

    if (loading) {
        return null;
    }
    if (!sellerCartGroup) {
        return <BSCWarningAlert message={messages['addItemToCart']}></BSCWarningAlert>;
    }

    return sellerCartGroup?.map((sellerCartGroupItem, i) => (
        <CartDetail
            key={i}
            index={i + 1}
            loading={loading}
            total={sellerCartGroup.length}
            groupItem={sellerCartGroupItem}
            onDeleteCartItem={onDeleteCartItem}
            onQuantitySelect={onQuantitySelect}
            lowDollarAmount={lowDollarAmount}
            onExpansionPanel={setExpansionPanelIndex}
            expansionPanelIndex={loading ? 1 : expansionPanelIndex}
        ></CartDetail>
    ));
}

export default memo(CartDetails);
