import BSCModal from '../../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    modalDimensions: {
        height: '96px',
        [theme.breakpoints.down('xs')]: {height: '130px'},
    },
    deleteConfirmation: {
        fontSize: '20px !important',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: '1.167.toFixed',
        letterSpacing: 0.5,
    },
}));

interface DeleteConfirmationModalProps {
    open: boolean;
    onClose: boolean;
}

function DeleteConfirmationModal({open, onClose}: DeleteConfirmationModalProps) {
    const classes = useStyles();
    return (
        <BSCModal open={open} onClose={onClose}>
            <div className={classes.modalDimensions}>
                <BSCTypography align="center" label="Are you sure you want to delete this item?" className={classes.deleteConfirmation} />
                <BSCDivider />
                <div className={classes.overflowHidden}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <BSCButton fullWidth color="primary" onClick={() => onClose(false)}>
                                <BSCTypography label="Cancel" />
                            </BSCButton>
                        </Grid>
                        <Grid item xs={6}>
                            <BSCButton fullWidth color="secondary" onClick={() => onClose(true)}>
                                <BSCTypography label="Confirm" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BSCModal>
    );
}

export default DeleteConfirmationModal;
