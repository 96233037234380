import {FormControlLabel, Grid, makeStyles, MenuItem, Radio, RadioGroup, Theme, InputAdornment} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {useEffect, useMemo, useState} from 'react';
import {OrderRefund} from '../../../../client/openapitools/seller';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCSelect from '../../../../features/common/bscSelect/bscSelect';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
//import useApi from '../../../../features/hooks/redux/apiConfig/useApi';
// import useQuery from '../../../../packages/core/src/hooks/useQuery';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCTextField from '../../../../features/common/bscTextField/bscTextField';

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {marginRight: theme.spacing(1)},
    marginTop: {marginTop: theme.spacing(1)},
}));

interface OrderRefundModalProps {
    open: boolean;
    onClose(): void;
    fullRefundAmount: number;
    itemAmount: string;
    shippingAmount: string;
    onOrderRefundSubmitted(orderRefund: OrderRefund): void;
    shippingLabelText: string;
    allowFullrefund: boolean;
    refundReasonsLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refundReasons: undefined[];
}
const OrderRefundModal = ({
    open,
    onClose,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fullRefundAmount,
    itemAmount,
    shippingAmount,
    onOrderRefundSubmitted = () => onClose(),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shippingLabelText,
    allowFullrefund,
    refundReasons,
    refundReasonsLoading,
}: OrderRefundModalProps) => {
    const classes = useStyles();
    //const api = useApi();

    const [request, setRequest] = useState<OrderRefund>({
        refundType: 'full',
        subtotalRefund: itemAmount,
        shippingRefund: shippingAmount,
        //buyerReturn: true,
        refundReason: '',
    });
    const [isAmountFieldError, setIsAmountFieldError] = useState<boolean>();
    const [shippingCostFieldError, setshippingCostFieldError] = useState<boolean>();

    useEffect(() => {
        setRequest(state => {
            return {
                ...state,
                subtotalRefund: itemAmount,
                shippingRefund: shippingAmount,
            };
        });
    }, [itemAmount, shippingAmount]);

    useEffect(() => {
        setRequest(state => {
            return {...state, refundType: allowFullrefund === true ? 'full' : 'partial'};
        });
    }, [allowFullrefund]);

    const handleOnClose = () => {
        setRequest({
            refundType: allowFullrefund === true ? 'full' : 'partial',
            subtotalRefund: itemAmount,
            shippingRefund: shippingAmount,
            //buyerReturn: true,
            refundReason: '',
        });
        onClose();
    };

    const formValid = useMemo(() => {
        return (
            request.refundReason &&
            Number(request.subtotalRefund.toString().replace(',', '')) >= 0 &&
            Number(request.shippingRefund.toString().replace(',', '')) >= 0
        );
    }, [request]);

    return (
        <BSCModal
            open={open}
            onClose={handleOnClose}
            headerLabel="Issue Refund"
            size="sm"
            bscScrollerProps={{scrollX: false, scrollY: false}}
        >
            <>
                <BSCDivider></BSCDivider>
                {refundReasonsLoading && <Skeleton></Skeleton>}
                {!refundReasonsLoading && refundReasons && (
                    <Grid container alignItems="center">
                        <Grid item>
                            <BSCTypography className={classes.marginRight} label="Reason:" />
                        </Grid>
                        <Grid item style={{flex: 'auto'}}>
                            <BSCSelect
                                fullWidth
                                label="Select Reason"
                                selectedSortValue={request.refundReason}
                                onSortChange={e =>
                                    setRequest(state => {
                                        return {...state, refundReason: e};
                                    })
                                }
                            >
                                <MenuItem value="" disabled selected={true}>
                                    Select Reason
                                </MenuItem>
                                {
                                    //@ts-ignore this is right, the swagger is off
                                    refundReasons.map(x => {
                                        return (
                                            <MenuItem key={x.key} value={x.key}>
                                                <BSCTypography label={x.name} />
                                            </MenuItem>
                                        );
                                    })
                                }
                            </BSCSelect>
                        </Grid>
                    </Grid>
                )}
                <BSCDivider></BSCDivider>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <RadioGroup
                            row
                            name="refundType"
                            value={request.refundType}
                            onChange={e =>
                                setRequest(state => {
                                    return {
                                        ...state,
                                        refundType: e.target.value,
                                        subtotalRefund: e.target.value === 'full' ? itemAmount : state.subtotalRefund,
                                        shippingRefund: e.target.value === 'full' ? shippingAmount : state.shippingRefund,
                                    };
                                })
                            }
                        >
                            <FormControlLabel value="full" control={<Radio />} disabled={!allowFullrefund} label="Full Refund" />
                            <FormControlLabel value="partial" control={<Radio />} label="Partial Refund" />
                        </RadioGroup>
                    </Grid>
                    <Grid item container direction="row" spacing={2} alignItems="center">
                        <Grid item xs={5} container justifyContent="flex-end">
                            <BSCTypography label="Item(s) Total:" />
                        </Grid>
                        <Grid item xs={7}>
                            <BSCTextField
                                inputKey="Item(s) Total"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BSCTypography label="$" />
                                        </InputAdornment>
                                    ),
                                }}
                                value={request.subtotalRefund}
                                disabled={request.refundType === 'full'}
                                onChange={e =>
                                    setRequest(state => {
                                        return {...state, subtotalRefund: e.target.value};
                                    })
                                }
                                onBlur={e => {
                                    if (Number(e.target.value.replace(',', '')) > itemAmount.replace(',', '')) {
                                        setIsAmountFieldError(true);
                                        setRequest(state => {
                                            return {...state, subtotalRefund: itemAmount};
                                        });
                                    } else {
                                        setIsAmountFieldError(false);
                                        setRequest(state => {
                                            return {...state, subtotalRefund: currencyFormatter(e.target.value)};
                                        });
                                    }
                                }}
                                error={isAmountFieldError}
                                helperText={isAmountFieldError ? `Item refund amount cannot exceed ${itemAmount}` : ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2} alignItems="center">
                        <Grid item xs={5} container justifyContent="flex-end">
                            <BSCTypography label="Shipping Total:" />
                        </Grid>
                        <Grid item xs={7}>
                            <BSCTextField
                                inputKey="Shipping Total"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BSCTypography label="$" />
                                        </InputAdornment>
                                    ),
                                }}
                                value={request.shippingRefund}
                                disabled={request.refundType === 'full'}
                                onChange={e =>
                                    setRequest(state => {
                                        return {...state, shippingRefund: e.target.value};
                                    })
                                }
                                onBlur={e => {
                                    if (Number(e.target.value.replace(',', '')) > shippingAmount.replace(',', '')) {
                                        setshippingCostFieldError(true);
                                        setRequest(state => {
                                            return {...state, shippingRefund: shippingAmount};
                                        });
                                    } else {
                                        setshippingCostFieldError(false);
                                        setRequest(state => {
                                            return {...state, shippingRefund: currencyFormatter(e.target.value)};
                                        });
                                    }
                                }}
                                error={shippingCostFieldError}
                                helperText={shippingCostFieldError ? `Shipping refund amount cannot exceed ${shippingAmount}` : ''}
                            />
                        </Grid>
                    </Grid>
                    <BSCDivider noMargin></BSCDivider>
                    <Grid item container direction="row" spacing={2} alignItems="center">
                        <Grid item xs={5} container justifyContent="flex-end">
                            <BSCTypography label="Refund Total:" />
                        </Grid>
                        <Grid item xs={7}>
                            <BSCTextField
                                inputKey="Refund Total"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BSCTypography label="$" />
                                        </InputAdornment>
                                    ),
                                }}
                                value={currencyFormatter(
                                    isNaN(Number(request.subtotalRefund) + Number(request.shippingRefund))
                                        ? '0'
                                        : Number(request.subtotalRefund) + Number(request.shippingRefund)
                                )}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/*  <BSCDivider />
                <BSCDivider></BSCDivider>
                <Grid container>
                    <Grid item xs={12}>
                        <BSCTypography label="Is the Buyer making a return?" />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            name="return"
                            value={request.buyerReturn}
                            onChange={e =>
                                setRequest(state => {
                                    return {...state, buyerReturn: e.target.value};
                                })
                            }
                            row
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    {request.buyerReturn == 'true' && (
                        <Alert variant="outlined" color="error">
                            <BSCTypography label="We will send the buyer a return label and you will be charged for the shipping."></BSCTypography>
                        </Alert>
                    )}
                </Grid>
                <BSCDivider></BSCDivider> */}
                <BSCButton
                    color="primary"
                    fullWidth
                    disabled={!formValid}
                    onClick={() => {
                        onOrderRefundSubmitted(request);
                        handleOnClose();
                    }}
                    className={classes.marginTop}
                >
                    <BSCTypography label="Submit"></BSCTypography>
                </BSCButton>
            </>
        </BSCModal>
    );
};

export default OrderRefundModal;
