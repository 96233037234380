import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {useMemo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Menus} from '../../../@types/menus';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';
import BSCButton from '../../common/bscButton/bscButton';
import BSCCardBox from '../../common/bscCardBox/bscCardBox';
import BSCDivider from '../../common/bscDivider/bscDivider';
import BSCTypography from '../../common/bscTypography/bscTypography';
import useReduxHomePage from '../../hooks/redux/homePage/homePage';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // width: 680,
        pointerEvents: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    cardBox: {
        borderRadius: '0 0 4px 4px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(0.5),
            display: 'flex',
            flexDirection: 'column-reverse',
        },
    },
    menuWrap: {
        marginBottom: theme.spacing(1.5),
        borderRadius: '0 0 4px 4px',
    },
    menuTitle: {
        fontWeight: 600,
        fontStyle: 'normal',
        textTransform: 'capitalize',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    menuItem: {
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            padding: 0,
            minHeight: 0,
        },
    },
    goat: {
        background: '#E9EDF2',
        padding: theme.spacing(0.5, 0),
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
    },
    goatContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    shopAllWrap: {
        padding: theme.spacing(1.25, 1),
    },
    shopAllButton: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(2),
        },
        fontSize: 12,
    },
    link: {
        textTransform: 'uppercase',
        fontStyle: 'italic',
        textDecoration: 'underline',
        margin: theme.spacing(0, 0.5),
    },
    gridDisplay: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    gridDisplayOther: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
    },
}));

interface SubNavContentProps {
    menus: Menus[];
    onGoatClick: () => void;
    menuId: number;
    url: string;
}

function SubNavContent({menus, onGoatClick, menuId, url}: SubNavContentProps) {
    const classes = useStyles();
    const {topSellerState} = useReduxHomePage();
    const {isTablet, isMobile} = useScreenSnap();
    const {mpNavigation, loading: topSellerLoading} = topSellerState;

    const topSeller = useMemo(() => {
        if (mpNavigation) {
            const topSellerItem = mpNavigation.categories?.find(item => +item.order === +menuId);
            if (topSellerItem) {
                return topSellerItem.topSeller[0];
            }
        }
    }, [menuId, mpNavigation]);

    let colWidth = 4;
    const boxWidth = isTablet || isMobile ? '100%' : '680px';

    switch (menus.length) {
        case 3:
            colWidth = 4;
            break;
        case 2:
            colWidth = 6;
            break;
        case 1:
            colWidth = 12;
            //boxWidth = '343px';
            break;
        default:
            colWidth = 4;
    }
    return (
        <div className={classes.root} style={{width: boxWidth}}>
            <BSCCardBox className={classes.cardBox}>
                <div className={classes.menuWrap}>
                    {url?.endsWith('other') && (
                        <Grid container spacing={2}>
                            {menus?.map(menu => (
                                <Grid item key={menu.order} xs={12} md={12}>
                                    <BSCTypography className={classes.menuTitle} uppercase bold italic label={menu.title} />
                                    <BSCDivider noTopMargin />
                                    <div className={menus.length === 1 && !isTablet && !isMobile ? classes.gridDisplayOther : ''}>
                                        {menu.links.map(link => (
                                            <MenuItem
                                                className={classes.menuItem}
                                                disableGutters
                                                key={link.url}
                                                to={link.url}
                                                component={RouterLink}
                                                onClick={onGoatClick}
                                            >
                                                {link.title}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {!url?.endsWith('other') && (
                        <Grid container spacing={2}>
                            {menus?.map(menu => (
                                <Grid item key={menu.order} xs={12} sm={colWidth}>
                                    <BSCTypography className={classes.menuTitle} uppercase bold italic label={menu.title} />
                                    <BSCDivider noTopMargin />
                                    <div className={menus.length === 1 && !isTablet && !isMobile ? classes.gridDisplay : ''}>
                                        {menu.links.map(link => (
                                            <MenuItem
                                                className={classes.menuItem}
                                                disableGutters
                                                key={link.url}
                                                to={link.url}
                                                component={RouterLink}
                                                onClick={onGoatClick}
                                            >
                                                {link.title}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </div>
                {topSellerLoading ? (
                    <Skeleton height={50} width={200} />
                ) : topSeller ? (
                    <div className={classes.goat}>
                        <BSCTypography label={`Shop the ${topSeller.level}: `} className={classes.goatContent}>
                            <strong>
                                <Link
                                    className={classes.link}
                                    color="secondary"
                                    component={RouterLink}
                                    onClick={onGoatClick}
                                    to={topSeller.url}
                                >
                                    {topSeller.name}
                                </Link>
                            </strong>
                            <span
                                style={{
                                    fontSize: 30,
                                }}
                            >
                                🐐
                            </span>
                        </BSCTypography>
                    </div>
                ) : null}
                <BSCButton
                    className={classes.shopAllButton}
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={url}
                    onClick={onGoatClick}
                    fullWidth
                    style={url?.endsWith('other') ? {display: 'none'} : null}
                >
                    Shop All
                </BSCButton>
            </BSCCardBox>
        </div>
    );
}

export default SubNavContent;
