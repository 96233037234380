import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles(() => ({
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    modalDimensionsWithMessage: {
        height: 128,
    },
    modalDimensions: {
        height: 150,
    },
    restrictedBulkUploadAdvisory: {
        fontSize: '20px !important',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: '1.167.toFixed',
        letterSpacing: 0.5,
    },
}));

interface RestrictedBulkUploadAdvisoryModalProps {
    open: boolean;
    onClose: boolean;
    advisoryMessage?: string;
}

function RestrictedBulkUploadAdvisoryModal({open, onClose, advisoryMessage}: RestrictedBulkUploadAdvisoryModalProps) {
    const classes = useStyles();
    const {isDesktop} = useScreenSnap();

    return (
        <BSCModal open={open} onClose={onClose}>
            <div className={!isDesktop ? classes.modalDimensions : classes.modalDimensionsWithMessage}>
                {advisoryMessage && isDesktop && (
                    <>
                        <BSCTypography align="center" label={advisoryMessage} className={classes.restrictedBulkUploadAdvisory} />
                    </>
                )}
                {!isDesktop && (
                    <>
                        <BSCTypography align="center" label="Bulk Upload is Available" className={classes.restrictedBulkUploadAdvisory} />
                        <BSCTypography align="center" label="on Desktop Only" className={classes.restrictedBulkUploadAdvisory} />
                    </>
                )}
                <BSCDivider />
                <div className={classes.overflowHidden}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{textAlign: 'center', paddingTop: 24}}>
                            <BSCButton color="secondary" onClick={() => onClose(true)}>
                                <BSCTypography label="Ok" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BSCModal>
    );
}

export default RestrictedBulkUploadAdvisoryModal;
