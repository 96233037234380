const SellersFormValidationsNormalizer = {
    getOrders: validationResults => validationResults?.orders ?? null,
    getInventoryValidation: validationResults => validationResults?.inventory ?? null,
    getSellYourCard: validationResults => validationResults?.sellYourCard ?? null,
    getSellerContact: validationResults => validationResults?.sellerContact ?? null,
    getSellerAddressInformation: validationResults => validationResults?.sellerAddressInformation ?? null,
    getSellerSettings: validationResults => validationResults?.sellerSettings ?? null,
    getPaymentSettings: validationResults => validationResults?.paymentSettings ?? null,
    getSignupValidation: validationResults => validationResults?.signup ?? null,
};

export default SellersFormValidationsNormalizer;
