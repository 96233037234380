import {makeStyles, Theme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import BSCButton from '../../../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../../../features/common/bscCardBox/bscCardBox';
import BSCContainer from '../../../../../../features/common/bscContainer/bscContainer';
import BSCDivider from '../../../../../../features/common/bscDivider/bscDivider';
import BSCTextField from '../../../../../../features/common/bscTextField/bscTextField';
import BSCTypography from '../../../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../../../redux/reducers';
import './ContactPage.scss';
import ContactUsModal from './ContactUsModal';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        grecaptcha: undefined;
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    page: {
        padding: '24px',
    },
    pageMobile: {
        padding: 0,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
    },
    box: {
        background: theme.palette.common.white,
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '4px',
        padding: 0,
    },
    buttonWrap: {
        padding: '4px 16px 16px',
    },
    title: {
        padding: '16px',
        textTransform: 'capitalize',
        background: '#FFFFFF',
        border: '1px solid #DAE0E6',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        textAlign: 'center',
    },
    heading: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '18px',
    },
    formEntries: {
        padding: '16px',
        background: '#F5F8FA',
    },
    cardWrap: {
        width: '100%',
        background: '#fff',
        boxShadow: '0px 0px 4px #b8c2cc',
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: 0,
    },
    topWrap: {
        marginBottom: theme.spacing(2),
    },
    textBox: {
        background: '#FFF',
        marginBottom: '8px',
        width: '100%',
    },
    flex1: {
        flex: '1 1 0',
    },
    textArea: {
        fontFamily: 'Poppins',
        background: theme.palette.common.white,
        '& textarea': {
            minHeight: '100px',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.subtitle2.fontSize,
            },
        },
        width: '100%',
    },
    textAreaRed: {
        fontFamily: 'Poppins',
        background: theme.palette.common.white,
        '& textarea': {
            minHeight: '100px',
            color: '#f00 !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.subtitle2.fontSize,
            },
        },
        width: '100%',
    },
    captchaWrap: {
        padding: '0 0 16px 2px',
    },
    inline: {
        display: 'inline-flex',
    },
}));

const ContactUs = () => {
    const classes = useStyles();
    const happyMessage = 'Thank you for your message, we will get back to you shortly.';
    const unhappyMessage = "Sorry, something went wrong. Let's try an alternative approach - direct email.";
    const {isBelowTablet, isMobile} = useScreenSnap();
    const [modalOpen, toggleModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState(happyMessage);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isIncomplete, setIsIncomplete] = useState(true);
    const [isInvalidCaptcha, setIsInvalidCaptcha] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [captchaToken, setCaptchaToken] = useState(null);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    const checkIsComplete = () => {
        if (firstName === '' || lastName === '' || emailAddress === '' || subject === '' || message === '') {
            setIsIncomplete(true);
        } else {
            setIsIncomplete(false);
        }
    };

    const handleClose = () => {
        toggleModalOpen(false);
    };

    const handleLoaded = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6Ld4nFMmAAAAABsqsAjxkJCAcJ-Jl8oJyJnQpYWN', {action: 'support'}).then(token => {
                setCaptchaToken(token);
                setIsInvalidCaptcha(false);
            });
        });
    };

    const messageMaxLength = 5000;
    const [isMessageMaxed, setIsMessageMaxed] = useState(false);
    const handleChangeMessage = () => {
        const value = (document.querySelector('#message') as HTMLInputElement).value;
        const messageLength = (document.querySelector('#message') as HTMLInputElement).value.length;
        if (messageLength > messageMaxLength) {
            setIsMessageMaxed(true);
            return;
        }
        setIsMessageMaxed(false);
        setMessage(value);
    };

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
        window.scrollTo(0, 0);
        // Add reCaptcha
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6Ld4nFMmAAAAABsqsAjxkJCAcJ-Jl8oJyJnQpYWN';
        script.addEventListener('load', handleLoaded);
        document.body.appendChild(script);
    }, []);

    const submitData = e => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_BASE_URL}/marketplace/contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                emailId: emailAddress,
                subject: subject,
                message: message,
                'g-recaptcha-response': captchaToken,
            }),
        })
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    return response.json();
                } else if (response.status >= 400) {
                    throw new Error(unhappyMessage);
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .then(data => {
                setModalMessage(happyMessage);
                toggleModalOpen(true);
            })
            .catch(error => {
                setModalMessage(error.message);
                toggleModalOpen(true);
            });
    };

    return (
        <BSCContainer
            className={isBelowTablet || isMobile ? classes.pageMobile : classes.page}
            variant={isBelowTablet || isMobile ? '' : 'page'}
        >
            <Grid className={classes.root}>
                <Grid item style={{maxWidth: '679px'}}>
                    <BSCCardBox className={classes.cardWrap}>
                        {pwaOS === null && (
                            <div className={classes.title}>
                                <BSCTypography className={classes.heading} label="Contact Us" bold />
                            </div>
                        )}
                        <div className={classes.formEntries}>
                            <BSCTypography className="label" label="Name" variant="body1" />
                            <Grid container direction="row" spacing={2}>
                                <Grid item className={classes.flex1}>
                                    <BSCTextField
                                        className={classes.textBox}
                                        onBlur={checkIsComplete}
                                        onChange={e => setFirstName(e.target.value)}
                                        value={firstName}
                                        placeholder="First Name"
                                    />
                                </Grid>
                                <Grid item className={classes.flex1}>
                                    <BSCTextField
                                        className={classes.textBox}
                                        onBlur={checkIsComplete}
                                        onChange={e => setLastName(e.target.value)}
                                        value={lastName}
                                        placeholder="Last Name"
                                    />
                                </Grid>
                            </Grid>
                            <BSCTypography className="label" label="Email Address" variant="body1" />
                            <BSCTextField
                                className={classes.textBox}
                                onBlur={checkIsComplete}
                                onChange={e => setEmailAddress(e.target.value)}
                                value={emailAddress}
                                placeholder="Enter email address"
                            />
                            <BSCTypography className="label" label="Subject" variant="body1" />
                            <BSCTextField
                                className={classes.textBox}
                                onBlur={checkIsComplete}
                                onChange={e => setSubject(e.target.value)}
                                value={subject}
                                placeholder="Enter a subject"
                            />
                            <BSCTypography className="label" label="Message" variant="body1" />
                            <BSCTextField
                                id="message"
                                onBlur={checkIsComplete}
                                onChange={handleChangeMessage}
                                value={message}
                                placeholder="Enter Message"
                                multiline
                                className={isMessageMaxed ? classes.textAreaRed : classes.textArea}
                            />
                            <div style={{width: '100%', display: 'inline-flex', justifyContent: 'end'}}>
                                <BSCTypography
                                    style={{paddingTop: 4, paddingRight: 12, fontSize: 12, color: isMessageMaxed ? '#f00' : '#000'}}
                                    label={`${messageMaxLength - message.length} characters`}
                                    variant="body1"
                                />
                            </div>
                        </div>
                        <BSCDivider />
                        <div className={classes.buttonWrap}>
                            <div
                                className="g-recaptcha"
                                data-sitekey="6Ld4nFMmAAAAABsqsAjxkJCAcJ-Jl8oJyJnQpYWN"
                                data-size="invisible"
                            ></div>
                            <BSCButton onClick={submitData} disabled={isIncomplete || isInvalidCaptcha} fullWidth color="primary">
                                Submit
                            </BSCButton>
                            <br />
                            <br />
                            <div className={classes.inline}>
                                <BSCTypography variant="subtitle2" size10 label={'This site is protected by reCAPTCHA and the Google'} />
                                &nbsp;
                                <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                                    <BSCTypography variant="subtitle2" size10 label={'Privacy Policy'} />
                                </a>
                                &nbsp;
                                <BSCTypography label={'and'} variant="subtitle2" size10 />
                                &nbsp;
                                <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                                    <BSCTypography variant="subtitle2" size10 label={'Terms of Service'} />
                                </a>
                                &nbsp;
                                <BSCTypography label={'apply.'} variant="subtitle2" size10 />
                            </div>
                        </div>
                    </BSCCardBox>
                </Grid>
            </Grid>
            <ContactUsModal
                open={modalOpen}
                onClose={handleClose}
                enteredData={{firstName, lastName, emailAddress, subject, message}}
                message={modalMessage}
            />
        </BSCContainer>
    );
};

export default ContactUs;
