import React, {useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import MyCart from './myCart';
import CartDetails from './cartDetails';
import CartCheckoutHeader from './header/cartCheckoutHeader';
import {useCallback, useEffect} from 'react';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import {useHistory} from 'react-router';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import useReduxCart from '../../../../features/hooks/redux/cart/useReduxCart';
import Payment from '../../../../platforms/marketplace/pages/dashboard/tabs/account/components/payment';
import {OrderApi, PaymentMethod, UserApi} from '../../../../client/openapitools/marketplace';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    root: theme.containerWraps.page,
    container: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    dialogSpacing: {
        padding: '8px 16px',
    },
    wrapper: {
        padding: theme.spacing(2),
        background: 'white',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: 4,
        marginBottom: theme.spacing(2),
    },
}));

function CheckOutCart() {
    const classes = useStyles();
    // eslint-disable-next-line
    const {cartState, cartActionLoading, getCart} = useReduxCart();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {setUser} = useReduxUser();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const [addOrderLoading, setAddOrderLoading] = useState(false);
    const {userShipping, userBilling, userCompleteInfo, userLoading} = useReduxUser();
    const history = useHistory();
    const [paymentSelected, setPaymentSelected] = useState<PaymentMethod | undefined>();
    const loadingReasons = {
        ['Getting Updated Cart Details']: cartActionLoading,
        ['Placing Order']: addOrderLoading,
    };

    const handleOrderSubmit = useCallback(() => {
        if (paymentSelected && userCompleteInfo) {
            //     addOrder({
            //         paymentId: paymentSelected.id,
            //         shippingAddressId: userShipping.id,
            //         billingAddressId: userBilling.id,
            //     })
            //         .then(responseType => {
            //             setPaymentSelected();
            //             if (responseType.status === responseType.statusTypes.fulfilled) {
            //                 //Hopefully this is all that is needed.
            //                 //Will need to verify once the useReduxUser actually works again.
            //                 getCart();
            //                 history.push({
            //                     pathname: '/cart/thank-you',
            //                     state: {
            //                         thankYou: responseType.data.data,
            //                     },
            //                 });
            //             }
            //         })
            //         .catch(err => <BSCErrorBanner errors={err} />);
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            const order = {
                                paymentId: paymentSelected.id,
                                shippingAddressId: userShipping.id,
                                billingAddressId: userBilling.id,
                            };
                            try {
                                const orderApi = new OrderApi(apiConfig);
                                setAddOrderLoading(true);
                                const responseType = await orderApi.createOrder(order);
                                if (responseType.status === 200 || responseType.status === 201) {
                                    getCart();
                                    history.push({
                                        pathname: '/cart/thank-you',
                                        state: {
                                            thankYou: responseType.data,
                                        },
                                    });
                                    setAddOrderLoading(false);
                                }
                            } catch (error) {
                                console.error('*** Error placing order ***', error);
                                addToast({
                                    severity: 'error',
                                    message: error.message,
                                    contextKey: 'Cart Order',
                                });
                                setAddOrderLoading(false);
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    }, [history, paymentSelected]);

    const handleEditCart = useCallback(() => {
        history.push('/cart/edit');
    }, [history]);

    const lowDollarAmount = Number(process.env.REACT_APP_MINIMUM_PURCHASE_SINGLE_SELLER);
    const isLowDollarOnSeller = cartState?.sellerCartGroup?.some(x => x.subTotalPrice < lowDollarAmount);

    const [refreshFlag, setRefreshFlag] = React.useState(false);
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const response = await userApi.getUserProfile();
                            setUser(response.data);
                        } catch (error) {
                            console.error('Error fetching user', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Authentication',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance, refreshFlag]);

    return (
        <div className={classes.root}>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} md={8}>
                    {cartState?.sellerCartGroup?.some(() => true) && (
                        <>
                            <CartCheckoutHeader
                                isLoading={userLoading}
                                userShipping={userShipping}
                                userBilling={userBilling}
                                isAuth={true}
                                onAddressUpdate={() => setRefreshFlag(!refreshFlag)}
                            />
                            <div className={classes.wrapper}>
                                <Payment
                                    showRadioButton={true}
                                    enableSelectCard={true}
                                    userBilling={userBilling}
                                    setPaymentSelected={setPaymentSelected}
                                    paymentSelected={paymentSelected}
                                />
                            </div>
                        </>
                    )}
                    <BSCLoading loading={cartActionLoading || addOrderLoading} loadingReasons={loadingReasons} />
                    <CartDetails loading={cartActionLoading || addOrderLoading} userCart={cartState} lowDollarAmount={lowDollarAmount} />
                    <BSCDivider />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={8}>
                            <BSCButton
                                onClick={handleOrderSubmit}
                                disabled={!(userCompleteInfo && paymentSelected) || isLowDollarOnSeller}
                                loading={addOrderLoading}
                                fullWidth
                                color="primary"
                            >
                                Place Order
                            </BSCButton>
                        </Grid>
                        <Grid item xs={12} md={6} sm={4}>
                            <BSCButton onClick={handleEditCart} disabled={addOrderLoading} fullWidth color="secondary">
                                Edit Cart
                            </BSCButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <MyCart
                        {...cartState}
                        onOrderSubmit={handleOrderSubmit}
                        isLowDollarOnSeller={isLowDollarOnSeller}
                        loading={addOrderLoading}
                        disabled={!(userCompleteInfo && paymentSelected) || isLowDollarOnSeller}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default CheckOutCart;
