import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {UserNotifications, User} from '../../client/openapitools/marketplace';
import {Api} from '../../features/hooks/redux/apiConfig/useApi';
interface UserState {
    loading: boolean;
    user: User;
}

const initialState: UserState = {loading: false, user: null};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const setNotification = createAsyncThunk(
    'posts/getNotification',
    async ({api, userNotifications}: {api: Api; userNotifications: UserNotifications}) => {
        return await api.marketplaceApi.userApi.updateUserNotifications(userNotifications);
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = {...state.user, ...action.payload};
        },
    },
});

export const {setUser} = userSlice.actions;

export default userSlice.reducer;
