import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import {getTopSeller} from '../../../../redux/slices/homePage.slice';

function useReduxHomePage() {
    const dispatch = useDispatch();
    const topSellerState = useSelector((s: RootState) => s.navigation);

    const getTopSellerClient = useCallback(() => dispatch(getTopSeller()), [dispatch]);

    return {topSellerState, getTopSeller: getTopSellerClient};
}

export default useReduxHomePage;
