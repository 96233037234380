import Link from '@material-ui/core/Link';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useHistory} from 'react-router';
import BSCTypography from '../bscTypography/bscTypography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    label: {
        lineHeight: 1,
        fontSize: 10,
    },
}));
interface BSCBackProps {
    label?: string;
    fallbackRoute?: string;
}

function BSCBack({label = 'Go Back', fallbackRoute = '/'}: BSCBackProps) {
    const classes = useStyles();
    const history = useHistory();

    const routeBack = () => {
        if (history.length < 2) {
            history.push(fallbackRoute);
        } else {
            //eslint-disable-next-line
            history.goBack();
        }
    };

    return (
        <Link
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            component="button"
            color="secondary"
            onClick={routeBack}
        >
            <ChevronLeftIcon color="primary" />
            <BSCTypography label={label} display="inline" className={classes.label} />
        </Link>
    );
}

export default BSCBack;
