import moment from 'moment';

export class dateTimeNormalizer {
    constructor() {}
    getDisplayDate = (date, returnInputOnError = false) => {
        if (returnInputOnError && !moment(new Date(date)).isValid()) {
            return date;
        }
        return date ? moment(new Date(date)).format('MM/DD/YYYY') : '';
    };
    getDisplayDateTime = date => moment(new Date(date)).format('MM/DD/YYYY hh:mm A');
    getApiDateTime = date => moment(new Date(date)).format('YYYY-MM-DD');
}

const useDateTimeNormalizer = () => {
    return new dateTimeNormalizer();
};

export default useDateTimeNormalizer;
