import {Grid, ModalProps} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Autocomplete, createFilterOptions} from '@material-ui/lab';
import {useEffect, useState} from 'react';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCModal from '../../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useApi from '../../../../../features/hooks/redux/apiConfig/useApi';
import useQuery from '../../../../../packages/core/src/hooks/useQuery';

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    buttonsSection: {
        paddingTop: 32,
        overflow: 'hidden',
    },
    modalDimensions: {
        height: '96px',
        [theme.breakpoints.down('xs')]: {height: '130px'},
    },
    deleteConfirmation: {
        fontSize: '20px !important',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: '1.167.toFixed',
        letterSpacing: 0.5,
    },
}));
interface FindASellerModalProps extends Omit<ModalProps, 'children'> {
    open: boolean;
    onClose: (sellerData: {}) => void;
}

function FindASellerModal({open, onClose}: FindASellerModalProps) {
    const api = useApi();
    const classes = useStyles();
    const {data: sellersListing} = useQuery(() => api.marketplaceApi.sellerApi.getAllSellers(), []);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sellersData, setSellersData] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState(null);

    const OPTIONS_LIMIT = 10;
    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };
    const defaultFilterOptions = createFilterOptions({matchFrom: 'start'});

    useEffect(() => {
        if (sellersListing) {
            const sellersRawData = Object.assign([], sellersListing.data);
            const autoCompleteOptions = new Array<{}>();
            sellersRawData.forEach(srd => {
                autoCompleteOptions.push({
                    name: srd.sellerStoreName,
                    id: srd.userId,
                    feedbackRating: srd.aggregateRating,
                    cardsSold: srd.salesNumber,
                });
            });
            setSellersData(autoCompleteOptions);
        }
    }, [sellersListing]);

    return (
        <BSCModal headerLabel="Find a Seller" open={open} onClose={onClose} hasCustomHeaderStyling={true}>
            <div>
                {sellersData && (
                    <Autocomplete
                        id="sellersFilter"
                        options={sellersData}
                        value={selectedSeller}
                        filterOptions={filterOptions}
                        getOptionLabel={option => option.name}
                        onChange={(_event, newSeller) => {
                            setSelectedSeller(newSeller);
                        }}
                        renderInput={params => <TextField {...params} label="Sellers" variant="outlined" />}
                    />
                )}
            </div>
            <div className={classes.buttonsSection}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <BSCButton fullWidth color="primary" onClick={() => onClose(null)}>
                            <BSCTypography label="Cancel" />
                        </BSCButton>
                    </Grid>
                    <Grid item xs={6}>
                        <BSCButton fullWidth color="secondary" onClick={() => onClose(selectedSeller)}>
                            <BSCTypography label="Select" />
                        </BSCButton>
                    </Grid>
                </Grid>
            </div>
        </BSCModal>
    );
}

export default FindASellerModal;
