import React, {useEffect, useState} from 'react';
import {makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCGridTableRow from '../../../../features/common/bscGridTableRow/bscGridTableRow';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {BSCErrorAlert, BSCSuccessAlert} from '../../../../features/common/bscAlert/bscAlert';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import {toNumber} from 'lodash';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0 0 8px 8px',
        },
        height: '100%',
    },
    font14: {
        fontSize: '14px',
        color: 'black',
    },
    fontBlack: {
        color: 'black',
    },
    infoIcon: {
        fontSize: '12px',
        verticalAlign: 'super',
    },
    freeShippingAlert: {
        '& p': {
            fontSize: '12px',
        },
    },
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

function SellerSummaryCart({noOfItems, price, shippingCost, subTotalPrice, lowDollarAmount, seller, shippingTiers}) {
    const classes = useStyles();
    const isLowDollarOnSeller = toNumber(subTotalPrice) < toNumber(lowDollarAmount);
    const [toolTipOpen, setToolTipOpen] = useState(false);
    const handleTooltipClose = () => {
        setToolTipOpen(false);
    };
    const handleTooltipOpen = () => {
        setToolTipOpen(true);
    };
    const handleTooltipClick = () => {
        setToolTipOpen(!toolTipOpen);
    };

    const [gradedCardTier, setGradedCardTier] = useState(null);
    const [freeShippingTier, setFreeShippingTier] = useState(null);
    const [freeShippingDelta, setFreeShippingDelta] = useState(0.0);
    const [hasEarnedFreeShipping, setHasEarnedFreeShipping] = useState(false);
    useEffect(() => {
        if (shippingTiers) {
            const gradedTier = shippingTiers.find(tier => tier.tierCode === 'G' && tier.rate !== 0);
            if (gradedTier) {
                setGradedCardTier(gradedTier);
            }
            const freeTier = shippingTiers.find(tier => tier.tierCode === 'F' && tier.rate !== 0);
            if (freeTier) {
                setFreeShippingTier(freeTier);
                if (price >= freeTier.rate) {
                    setHasEarnedFreeShipping(true);
                } else {
                    setFreeShippingDelta(freeTier.rate - price);
                }
            }
        }
    }, [shippingTiers]);

    return (
        <BSCCardBox className={classes.root}>
            <BSCGridTableRow
                col8={<BSCTypography semibold label="Order Summary" className={classes.fontBlack} />}
                col4={<BSCTypography label={`(Items: ${noOfItems})`} size14 className={classes.fontBlack} />}
            />
            <BSCDivider />
            <BSCGridTableRow
                gutterBottom
                col8="Item(s):"
                className={classes.font14}
                col4={<BSCCurrency className={classes.font14} amount={price} />}
            />
            <BSCGridTableRow
                col8={
                    <>
                        <BSCTypography semibold label="Shipping:" className={classes.fontBlack}>
                            {shippingTiers && shippingTiers.length > 0 && (
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <HtmlTooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={toolTipOpen}
                                        disableFocusListener
                                        disableTouchListener
                                        onClick={handleTooltipClose}
                                        title={
                                            <>
                                                <br />
                                                <BSCTypography color="inherit" bold underline>
                                                    {seller}'s Shipping Rates
                                                </BSCTypography>
                                                <br />
                                                <BSCTypography color="inherit" bold underline>
                                                    Raw Cards:
                                                </BSCTypography>
                                                {shippingTiers.map((tier, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {tier.tierCode === 'XS' && (
                                                                <BSCTypography color="inherit">
                                                                    ${currencyFormatter(tier.rate)} = 1 to 4 Cards
                                                                </BSCTypography>
                                                            )}
                                                            {tier.tierCode === 'S' && (
                                                                <BSCTypography color="inherit">
                                                                    ${currencyFormatter(tier.rate)} = 5 to 12 Cards
                                                                </BSCTypography>
                                                            )}
                                                            {tier.tierCode === 'M' && (
                                                                <BSCTypography color="inherit">
                                                                    ${currencyFormatter(tier.rate)} = 13 to 24 Cards
                                                                </BSCTypography>
                                                            )}
                                                            {tier.tierCode === 'L' && (
                                                                <BSCTypography color="inherit">
                                                                    ${currencyFormatter(tier.rate)} = 25 to 49 Cards
                                                                </BSCTypography>
                                                            )}
                                                            {tier.tierCode === 'XL' && (
                                                                <BSCTypography color="inherit">
                                                                    ${currencyFormatter(tier.rate)} = 50+ Cards
                                                                </BSCTypography>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                                {gradedCardTier && (
                                                    <>
                                                        <br />
                                                        <BSCTypography color="inherit" bold underline>
                                                            Graded Cards:
                                                        </BSCTypography>
                                                        <BSCTypography color="inherit">
                                                            ${currencyFormatter(gradedCardTier['rate'])} per Graded Card
                                                        </BSCTypography>
                                                    </>
                                                )}
                                                {freeShippingTier && (
                                                    <>
                                                        <br />
                                                        <BSCTypography color="primary" bold underline>
                                                            Free shipping for orders over ${freeShippingTier['rate']}
                                                        </BSCTypography>
                                                    </>
                                                )}
                                                <br />
                                            </>
                                        }
                                    >
                                        <InfoOutlinedIcon
                                            className={classes.infoIcon}
                                            onClick={handleTooltipClick}
                                            onMouseOver={handleTooltipOpen}
                                            onMouseLeave={handleTooltipClose}
                                        />
                                    </HtmlTooltip>
                                </ClickAwayListener>
                            )}
                        </BSCTypography>
                    </>
                }
                col4={<BSCCurrency className={classes.font14} amount={shippingCost} />}
            />
            <BSCDivider />
            <BSCGridTableRow
                col8={<BSCTypography label={'Sub Total:'} size14 semibold className={classes.fontBlack} />}
                col4={<BSCCurrency size14 semibold amount={subTotalPrice} />}
            />
            {isLowDollarOnSeller && (
                <BSCErrorAlert icon={false} message={`Order Value Must Exceed $${currencyFormatter(lowDollarAmount.toString())}`} />
            )}
            {!isLowDollarOnSeller && hasEarnedFreeShipping && (
                <BSCSuccessAlert className={classes.freeShippingAlert} icon={false} message={'Free Shipping Applied!'} />
            )}
            {!isLowDollarOnSeller && freeShippingDelta > 0.0 && (
                <BSCErrorAlert
                    className={classes.freeShippingAlert}
                    icon={false}
                    message={`Just $${currencyFormatter(freeShippingDelta.toString())} more for Free Shipping`}
                />
            )}
        </BSCCardBox>
    );
}

export default SellerSummaryCart;
