/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useCallback, useMemo, useRef, useState} from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    withStyles,
    Collapse,
    Grid,
    Popper,
    Paper,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BSCModal from '../../../../../features/common/bscModal/bscModal';
import useApi from '../../../../../features/hooks/redux/apiConfig/useApi';
import useQuery from '../../../../../packages/core/src/hooks/useQuery';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import BscAccordion from '../../../../../features/common/bscAccordion/bscAccordion';
import {CardPart, Condition} from '../../../../../client/common/card/conditionsClient';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
//import BSCHeaderBlock from '../../../../../features/common/bscHeaderBlock/bscHeaderBlock';

import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCTabs from '../../../../../features/common/bscTabs/bscTabs';
import BSCTab from '../../../../../features/common/bscTab/bscTab';

interface CardConditionsProps {
    show: boolean;
    children: React.ReactNode;
    linkText?: string;
}

const StyledTable = withStyles((theme: Theme) => {
    return {
        root: {},
    };
})(Table);

const StyledTableRow = withStyles((theme: Theme) => {
    return {
        root: {
            '& td': {
                width: '10rem',
            },
            '&:nth-of-type(1) td:nth-of-type(1)': {
                backgroundColor: 'unset!important',
                borderBottom: 'none',
            },
            '&:nth-of-type(1) td': {
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem',
            },
            '& td:nth-of-type(1)': {
                paddingLeft: '0 !important',
                height: '1.5rem',
                listStyleType: 'none',

                textAlign: 'center',
                verticalAlign: 'middle',

                borderTopLeftRadius: '1rem',
                borderBottomLeftRadius: '1rem',
            },
            '& td:nth-last-of-type(1)': {
                borderTopRightRadius: '1rem',
                borderBottomRightRadius: '1rem',
            },
            '&:nth-last-of-type(1) td': {
                borderBottom: 'none',
                borderBottomLeftRadius: '1rem',
                borderBottomRightRadius: '1rem',
            },
            '&:nth-last-of-type(1) td:nth-of-type(1)': {
                borderBottomLeftRadius: '1rem',
            },
            '&:nth-last-of-type(1) td:nth-last-of-type(1)': {
                borderBottomRightRadius: '1rem',
            },
            '& td.withText:nth-of-type(1)': {
                borderTopLeftRadius: '1rem',
            },
        },
    };
})(TableRow);

const StyledTableCell = withStyles((theme: Theme) => {
    return {
        root: {
            position: 'relative',
            lineHeight: '22px',
            verticalAlign: 'middle',
            width: '220px',
            border: 'none',
            //borderRight: 'solid 1px #000',
            borderBottom: 'solid 1px #000',
            paddingLeft: '16px',
            paddingRight: '10px',
            '&:nth-of-type(odd)': {
                backgroundColor: theme.backgroundColors.lightGray,
            },
            '&:nth-of-type(even)': {
                backgroundColor: theme.backgroundColors.offWhite,
            },
        },
    };
})(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
    buttonContainer: {
        padding: '6px',
    },
    buttonContainerMobile: {
        padding: '2px 16px 12px',
    },
    tabMobile: {
        minHeight: '32px',
    },
    vintageHeader: {
        background: '#B8C2CC',
        color: '#000',
        borderRadius: 4,
        ['&:hover']: {
            // background: 'rgb(0 0 0 / 79%)',
            background: '#B8C2CC',
        },
        marginBottom: theme.spacing(1),

        textAlign: 'left',
        justifyContent: 'left !important',
        ['& .MuiAccordionSummary-content']: {
            justifyContent: 'left !important',
            margin: '0 !important',
        },
    },
    vintageAcc: {
        boxShadow: 'none',
        ['&.Mui-expanded']: {
            marginTop: 0,
        },
        ['& .Mui-expanded']: {
            minHeight: 0,
        },
    },
    vintageDesc: {
        padding: '0 8px 0 8px !important',
    },
}));

const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

const InfoCell = ({children, onInfoClick = () => {}, toolTipText, toolTipPlacement = 'bottom'}) => {
    const classes = useStylesBootstrap();
    const tipText = useMemo(() => {
        if (Array.isArray(toolTipText)) {
            return (
                <>
                    {toolTipText.map(x => (
                        <BSCTypography label={x} />
                    ))}
                </>
            );
        } else {
            return <BSCTypography label={toolTipText} />;
        }
    }, [toolTipText]);

    return (
        <StyledTableCell>
            {children}
            <Tooltip title={tipText} placement={toolTipPlacement} classes={classes}>
                <InfoOutlinedIcon
                    onClick={onInfoClick}
                    style={{position: 'absolute', top: '.5rem', right: '.5rem', fontSize: '1rem'}}
                ></InfoOutlinedIcon>
            </Tooltip>
        </StyledTableCell>
    );
};

const CardConditionAccordianPart = ({
    cardPart,
    cardCondition,
    handleClick,
}: {
    cardPart: CardPart;
    cardCondition: Condition;
    handleClick;
}) => {
    const labelRef = useRef();

    return (
        <Grid item xs={6} style={{cursor: 'pointer'}} onClick={e => handleClick({currentTarget: labelRef.current}, cardPart)}>
            <BSCTypography
                ref={labelRef}
                label={
                    <>
                        {cardPart.display}&nbsp;
                        <InfoOutlinedIcon fontSize="inherit" />
                    </>
                }
                underline
            ></BSCTypography>
            <BSCTypography label={cardCondition[cardPart.id]} />
        </Grid>
    );
};

const CardConditionAccordian = ({cardCondition, cardParts}: {cardCondition: Condition; cardParts: CardPart[]}) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const labelRef = useRef();
    const [popperCardPart, setPopperCardPart] = useState(null);
    const popperOpen = Boolean(anchorEl);
    const handleClick = (event, cardPart) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setPopperCardPart(cardPart);
    };

    return (
        <div>
            <div>
                <BscAccordion open={open} label={cardCondition.display} onAccordionClick={() => setOpen(!open)} />
            </div>
            <Collapse in={open}>
                <Grid container style={{paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem'}}>
                    <Grid item xs={12}>
                        {cardCondition.infoText.map(x => (
                            <>
                                <BSCTypography label={x} size16 style={{marginBottom: '.5rem'}} />
                            </>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <BSCDivider></BSCDivider>
                    </Grid>

                    {cardParts.map(x => (
                        <CardConditionAccordianPart
                            cardPart={x}
                            cardCondition={cardCondition}
                            handleClick={handleClick}
                        ></CardConditionAccordianPart>
                    ))}
                </Grid>
            </Collapse>
            <Popper open={popperOpen} anchorEl={anchorEl} style={{zIndex: 9999, maxWidth: '50%'}}>
                <Paper variant="outlined" style={{padding: '8px', textAlign: 'center', background: 'black', color: 'white'}}>
                    <BSCTypography label={popperCardPart?.infoText} />
                </Paper>
            </Popper>
        </div>
    );
};

const CardConditionTable = ({condition, cardPart}) => {
    return (
        <StyledTable>
            <TableBody>
                <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    {condition.map(x => (
                        <InfoCell key={x.id} toolTipText={x.infoText}>
                            <BSCTypography variant="body2" label={x.display}></BSCTypography>
                        </InfoCell>
                    ))}
                </StyledTableRow>
                {cardPart.map(x => (
                    <StyledTableRow>
                        <InfoCell toolTipText={x.infoText} toolTipPlacement={'right'}>
                            <BSCTypography variant="body2" label={x.display} />
                        </InfoCell>
                        {condition.map(y => (
                            <StyledTableCell>
                                <BSCTypography label={y[x.id]} />
                            </StyledTableCell>
                        ))}
                    </StyledTableRow>
                ))}
            </TableBody>
        </StyledTable>
    );
};

const Tabs = {
    Modern: 0,
    Vintage: 1,
};
const CardConditions = ({show, children, linkText}: CardConditionsProps) => {
    const [open, setOpen] = useState(false);
    const api = useApi();
    const classes = useStyles();
    const {data: cardConditionResponse} = useQuery(() => api.commonApi.conditions.getConditions(), []);
    const {data: vintageConditionResponse} = useQuery(() => api.commonApi.conditions.getVintageConditions(), []);
    const {isDesktop, isMobile, isBelowTablet} = useScreenSnap();
    const [activeTab, setActiveTab] = useState<number>(0);
    const handleTabChange = useCallback(value => setActiveTab(value), []);

    const handleTabClick = (tabNumber: number) => {
        document.getElementById('desktopScrollTag').scrollTo(0, 0);
        document.getElementById('mobileScrollTag').scrollTo(0, 0);
    };

    const [expanded, setExpanded] = React.useState('');

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : 'none');
    };

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            {children}&nbsp;
            {show && (
                <>
                    {linkText && (
                        <BSCTypography
                            label={linkText}
                            component="a"
                            underline
                            onClick={() => setOpen(true)}
                            style={{color: 'blue', cursor: 'pointer'}}
                        ></BSCTypography>
                    )}
                    {!linkText && (
                        <Tooltip title="Click for Raw Condition Guide" placement="right">
                            <InfoOutlinedIcon
                                onClick={() => setOpen(true)}
                                style={{fontSize: '0.9em', cursor: 'pointer'}}
                            ></InfoOutlinedIcon>
                        </Tooltip>
                    )}
                    <BSCModal open={open} onClose={() => setOpen(false)} size="custom" customSize="1500px">
                        <>
                            <Grid container alignContent="center">
                                <Grid item xs={0} sm={3}></Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={isBelowTablet || isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
                                        <BSCTabs
                                            activeTab={activeTab}
                                            onTabChange={e => {
                                                handleTabChange(e), handleTabClick(e);
                                            }}
                                        >
                                            <BSCTab className={isBelowTablet || isMobile ? classes.tabMobile : null} label="Modern" />
                                            <BSCTab className={isBelowTablet || isMobile ? classes.tabMobile : null} label="Vintage" />
                                        </BSCTabs>
                                    </div>
                                </Grid>
                                <Grid item xs={0} sm={3}></Grid>
                            </Grid>
                            {/* <BSCTypography variant="h4" underline={isMobile} label="Raw Condition Guide"></BSCTypography> */}
                            <BSCTypography
                                variant="subtitle2"
                                size10={isMobile}
                                label="These guidelines DO NOT apply to Graded Cards, which receive a Grade from their respected Grading Company"
                            ></BSCTypography>
                            <br />
                            {Tabs.Modern === activeTab && (
                                <>
                                    {!isDesktop && (
                                        <>
                                            {cardConditionResponse?.data.condition.map((y, index) => (
                                                <CardConditionAccordian
                                                    cardCondition={y}
                                                    key={y.id + '_' + index}
                                                    cardParts={cardConditionResponse?.data.cardPart}
                                                />
                                            ))}
                                        </>
                                    )}
                                    {isDesktop && (
                                        <CardConditionTable
                                            condition={cardConditionResponse?.data.condition}
                                            cardPart={cardConditionResponse?.data.cardPart}
                                        ></CardConditionTable>
                                    )}
                                </>
                            )}
                            {Tabs.Vintage === activeTab && (
                                <>
                                    {/* {!isDesktop && (
                                        <>*/}
                                    {vintageConditionResponse?.data.condition.map(y => (
                                        <Accordion
                                            square
                                            expanded={expanded === y.id}
                                            onChange={handleChange(y.id)}
                                            className={classes.vintageAcc}
                                        >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.vintageHeader}>
                                                <BSCTypography align="left" label={y.display} />
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.vintageDesc}>
                                                <BSCTypography label={y.infoText[0]} />
                                            </AccordionDetails>
                                        </Accordion>

                                        // <CardConditionAccordian
                                        //     cardCondition={y}
                                        //     key={y.id + '_vin_' + index}
                                        //     vintage={true}
                                        //     cardParts={vintageConditionResponse?.data.cardPart}
                                        // />
                                    ))}
                                    {/* </>
                                     )}*/}
                                    {/* {isDesktop && (
                                        <CardConditionTable
                                            condition={vintageConditionResponse?.data.condition}
                                            cardPart={vintageConditionResponse?.data.cardPart}
                                        ></CardConditionTable>
                                    )} */}
                                </>
                            )}
                        </>
                    </BSCModal>
                </>
            )}
        </div>
    );
};

export default CardConditions;
