import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {User} from '../../client/openapitools/seller';
import useApi from '../../features/hooks/redux/apiConfig/useApi';
interface UserState {
    loading: boolean;
    sellerProfile: User;
    xhrError: boolean;
}

const initialState: UserState = {loading: false, sellerProfile: null, xhrError: false};

export const getUser = createAsyncThunk('seller/getUser', async () => {
    const api = useApi();

    return await api.sellerApi.userApi.getSellerUserProfile();
});

const sellerLockerSlice = createSlice({
    name: 'sellerLocker',
    initialState,
    reducers: {
        // omit existing reducers here
    },
    extraReducers: builder => {
        builder.addCase(getUser.pending, state => {
            state.loading = true;
            state.xhrError = false;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loading = false;
            state.sellerProfile = action.payload.data;
            state.xhrError = false;
        });
        builder.addCase(getUser.rejected, state => {
            state.loading = false;
            state.sellerProfile = null;
            state.xhrError = true;
        });
    },
});

export default sellerLockerSlice.reducer;
