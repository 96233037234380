import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import RestrictedBulkUploadAdvisoryModal from '../../bulkUpload/restrictedBulkUploadModal';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'none',
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: 4,
        color: theme.palette.common.white,
        padding: 0,
        whiteSpace: 'inherit',
        boxShadow: '0px 4px 4px #b8c2cc',

        '& div': {
            display: 'flex',
            width: '100%',
            height: '100%',
        },

        '& h2': {
            [theme.breakpoints.down('sm')]: {
                fontSize: 24,
            },
            position: 'absolute',
            textAlign: 'center',
            padding: theme.spacing(2, 4),
        },
    },
}));

const SellerImage = ({path, title, url}) => {
    const {isDesktop, isTablet, isMobile} = useScreenSnap();
    const classes = useStyles({isTablet, isMobile});

    const [modalOpen, toggleModalOpen] = useState(false);
    const handleClose = () => {
        toggleModalOpen(false);
    };

    const advisory = () => {
        toggleModalOpen(true);
    };

    return (
        <>
            {isDesktop && (
                <MenuItem className={classes.root} component={Link} to={url}>
                    <BSCImage src={path} />
                    <BSCTypography label={title} bold italic variant="h2" />
                </MenuItem>
            )}
            {!isDesktop && title === 'Bulk Upload' && (
                <MenuItem className={classes.root} onClick={advisory}>
                    <BSCImage src={path} />
                    <BSCTypography label={title} bold italic variant="h2" />
                </MenuItem>
            )}
            {!isDesktop && title !== 'Bulk Upload' && (
                <MenuItem className={classes.root} component={Link} to={url}>
                    <BSCImage src={path} />
                    <BSCTypography label={title} bold italic variant="h2" />
                </MenuItem>
            )}
            <RestrictedBulkUploadAdvisoryModal open={modalOpen} onClose={handleClose} />
        </>
    );
};

export default SellerImage;
