/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import BSCTypography from '../features/common/bscTypography/bscTypography';
import BSCDivider from '../features/common/bscDivider/bscDivider';
import {useHistory} from 'react-router-dom';
import useReduxUser from '../features/hooks/redux/user/useReduxUser';
import {useAuthFunctions} from '../AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
    groupTitle: {
        color: theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 400,
    },
    linkWrapper: {
        padding: 0,
        minHeight: 'unset',
    },
    link: {
        color: '#F5F8FA',
    },
    divider: {
        backgroundColor: '#41474D',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
    },
}));

const LinkGroup = ({title, links, col}) => {
    const classes = useStyles();
    const history = useHistory();
    const {userState} = useReduxUser();
    const authFunc = useAuthFunctions();

    function handleSellWithBSC() {
        if (userState) {
            if (userState.roleId === '2') {
                //is seller
                history.push('/sellers');
            } else if (userState.roleId === '1') {
                //is buyer
                history.push('/sellers/signup');
            }
        } else {
            authFunc.login();
        }
    }

    return (
        <div>
            <BSCTypography className={classes.groupTitle} label={title} variant="body2" />
            <BSCDivider className={classes.divider} />
            <Grid container>
                {links?.map(link => {
                    return (
                        <Grid item xs={col} key={link.url}>
                            {link.url === '/sellers' && (
                                <MenuItem className={classes.linkWrapper} onClick={e => handleSellWithBSC()}>
                                    <BSCTypography label={link.title} variant="subtitle1" className={classes.link} />
                                </MenuItem>
                            )}
                            {link.url !== '/sellers' && (
                                <MenuItem
                                    className={classes.linkWrapper}
                                    component={Link}
                                    to={link.url}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <BSCTypography label={link.title} variant="subtitle1" className={classes.link} />
                                </MenuItem>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default LinkGroup;
