import {s3Client} from '../../index';

const serviceUrls = {
    carousel: 's3/mpCarousel',
};

export interface CarouselItemsResult {
    transitionTime: number;
    transitionType: string;
    continuousScroll: boolean;
    bannerItems: BannerItem[];
}

interface BannerItem {
    headerText: string;
    images: {
        desktop: string;
        tablet: string;
        mobile: string;
    };
    imgAlt: string;
    buttonText: string;
    buttonUrl: string;
    slug: string;
}

const CarouselClient = {
    getCarouselItems: () => s3Client.get(serviceUrls.carousel).then(res => (res as {data: unknown}).data),
};

export default CarouselClient;
