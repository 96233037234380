import {s3Client} from '../..';

const serviceUrls = {
    topSeller: 'mpNavigation.json',
};

const topSellerClient = {
    getTopSeller: () => s3Client.get(serviceUrls.topSeller).then(res => res.data),
};

export default topSellerClient;
