import {makeStyles, Theme} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import {DividerProps} from 'material-ui';

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        margin: theme.spacing(1.5, 0),
        borderBottom: '3px solid #DAE0E6',
        height: '0px !important',
    },
    noMargin: {
        margin: 0,
    },
    noTopMargin: {
        marginTop: 0,
    },
}));

interface BSCDividerProps extends DividerProps {
    noMargin?: boolean;
    noTopMargin?: boolean;
    className?: string;
}

function BSCDivider({noMargin = false, noTopMargin = false, className = null, ...otherProps}: BSCDividerProps) {
    const classes = useStyles();
    return (
        <Divider
            {...otherProps}
            className={classNames(classes.divider, className, {
                [classes.noMargin]: noMargin,
                [classes.noTopMargin]: noTopMargin,
            })}
        />
    );
}

export default BSCDivider;
