import {ReactNode} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    fullWidth: theme.containerWraps.fullWidthPage,
    page: theme.containerWraps.page,
}));

const variants = {
    fullWidth: 'fullWidth',
    page: 'page',
};

interface BSCContainerProps {
    children: ReactNode;
    variant?: 'fullWidth' | 'page';
    className?: string | null;
}

function BSCContainer({children, variant = 'fullWidth', className = null}: BSCContainerProps) {
    const classes = useStyles();
    return (
        <Box
            className={classNames(className, {
                [classes.fullWidth]: variant === variants.fullWidth,
                [classes.page]: variant === variants.page,
            })}
        >
            {children}
        </Box>
    );
}

export default BSCContainer;
