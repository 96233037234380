import _ from 'lodash';
import {Item} from '../../../openapitools/search';

const accepted = ['sn', '^rc$'];

const getStripedLabel = label => label.toLowerCase().replace('sn', '/').toUpperCase().trim();

const getColor = label => {
    const cleanedLabel = label.toLowerCase().trim().slice(0, 2);

    switch (cleanedLabel) {
        case 'sn':
            return 'gold';
        case 'rc':
            return 'red';
    }

    return null;
};

const searchFilterIgnores = ['p', 'q', 'sort', 'instock', 'condition', 'myinventory', 'sellerId', 'sellerName'];

const SearchShopResultsNormalizer = {
    getSelectedFilters: (parsedQueryResults, aggregatedValues) => {
        if (!parsedQueryResults || Object.values(parsedQueryResults).length === 0 || !aggregatedValues) return null;
        return _.flatten(
            Object.keys(parsedQueryResults)
                ?.filter(parsedQueryResultsKey => !searchFilterIgnores.includes(parsedQueryResultsKey.toLowerCase()))
                ?.map(parsedSearchTermKey => {
                    return aggregatedValues[parsedSearchTermKey]
                        ?.filter(item => {
                            return parsedQueryResults[parsedSearchTermKey].indexOf(item.slug) !== -1;
                        })
                        .map(item => ({
                            ...item,
                            searchTermKey: parsedSearchTermKey,
                        }));
                })
        );
    },
    cleanQueryResults: () => {},
    //main => base and SN && PR become / and gold
    getPlayerAttributes: (playerAttribute: string[]) => {
        if (playerAttribute) {
            return playerAttribute
                .filter(label => {
                    return accepted.find(accept => label.trim().toLowerCase().search(accept) !== -1);
                })
                .map(label => ({
                    color: getColor(label),
                    stripedLabel: getStripedLabel(label),
                    label,
                }));
        }

        return null;
    },
    getFilteredMenuItems: (menuItems: Item[], filterText) => {
        if (menuItems && filterText) {
            const filteredItems = menuItems?.filter(
                menuItem => menuItem.label.toLocaleLowerCase().search(filterText.toLocaleLowerCase()) !== -1
            );
            return filteredItems.length === 0 ? null : filteredItems;
        }

        return menuItems;
    },
};

export default SearchShopResultsNormalizer;
