import React, {useState, useCallback, useEffect} from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import {useHistory} from 'react-router-dom';
import {Menu, MenuItem} from '@material-ui/core';
import BSCButton from '../common/bscButton/bscButton';
import useScreenSnap from '../../packages/core/src/hooks/useScreenSnap';
import BSCTypography from '../common/bscTypography/bscTypography';
import {Skeleton} from '@material-ui/lab';
import BSCDivider from '../common/bscDivider/bscDivider';
import {useAuthFunctions} from '../../AuthContext';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {SignOutButton} from '../users/signOutButton';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/reducers';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontSize: '14px', //theme.typography.subtitle1.fontSize,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.body2.fontSize,
        },
    },
    menuItemCenter: {
        justifyContent: 'center',
        height: 60,
    },

    capitalCase: {
        textTransform: 'none!important' as 'none',
    },
    welcomeText: {
        fontSize: 'smaller',
        color: 'white',
        textTransform: 'capitalize',
    },
    largeIcon: {
        fontSize: '26pt',
        color: 'white',
    },
    menuStyle: {
        '& ul': {
            padding: '0 !important',
        },
    },
    signOutItem: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
}));

export const SellerSignInButton = ({userMenu, userMenuLoading = false, ...otherProps}) => {
    const {isMobile} = useScreenSnap();
    const classes = useStyles();
    const sellerState = useSelector((state: RootState) => state.seller);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {inProgress} = useMsal();
    const authFunc = useAuthFunctions();

    const handleLogin = () => {
        authFunc.login();
    };

    const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
    const history = useHistory();

    const handleLogout = () => {
        authFunc.logout();
    };

    const handleMenuOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenMenu(event.currentTarget);
        },
        [setOpenMenu]
    );

    const handleMenuOpenHover = useCallback(
        event => {
            setOpenMenu(event.currentTarget);
        },
        [setOpenMenu]
    );

    const handleMenuClose = useCallback(() => {
        setOpenMenu(null);
    }, [setOpenMenu]);

    const [userState, setUserState] = useState(null);
    useEffect(() => {
        if (sellerState) {
            setUserState(sellerState.sellersLockerUser);
        }
    }, [sellerState]);

    return (
        <>
            {inProgress === 'none' && (
                <>
                    <AuthenticatedTemplate>
                        <BSCButton
                            {...otherProps}
                            className={classes.root}
                            style={otherProps.style}
                            align="center"
                            variant="text"
                            onClick={handleMenuOpen}
                            onMouseEnter={handleMenuOpenHover}
                        >
                            <PersonOutlineIcon className={classes.largeIcon} />
                            {userState && (
                                <div>
                                    <BSCTypography label="Welcome back," className={classes.welcomeText} />
                                    <BSCTypography label={userState?.sellerProfile?.sellerStoreName} className={classes.welcomeText} />
                                </div>
                            )}
                        </BSCButton>
                        <Menu
                            id="simple-menu"
                            className={classes.menuStyle}
                            anchorEl={openMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                            keepMounted
                            open={Boolean(openMenu)}
                            onClose={handleMenuClose}
                        >
                            <div onMouseLeave={handleMenuClose}>
                                {userMenuLoading && <Skeleton />}
                                {!userMenuLoading && userMenu && (
                                    <>
                                        {userMenu.dashboard.map(x => (
                                            <div
                                                key={x['title']}
                                                onClick={() => {
                                                    handleMenuClose();
                                                    history.push({
                                                        pathname: x['url'],
                                                    });
                                                }}
                                            >
                                                <MenuItem onClick={handleMenuClose} className={classes.menuItemCenter}>
                                                    <BSCTypography align="center" size14 label={x['title']} />
                                                </MenuItem>
                                                <BSCDivider style={{margin: 0}} />
                                            </div>
                                        ))}
                                    </>
                                )}
                                <MenuItem className={classes.signOutItem} onClick={handleLogout}>
                                    <SignOutButton
                                        color="primary"
                                        align="center"
                                        variant="text"
                                        className={classes.capitalCase}
                                        size14={true}
                                        style={{height: 60}}
                                    ></SignOutButton>
                                </MenuItem>
                            </div>
                        </Menu>
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <BSCButton {...otherProps} className={classes.root} style={otherProps.style} color="primary" onClick={handleLogin}>
                            {isMobile ? 'Sign In' : 'Sign-in'}
                        </BSCButton>
                    </UnauthenticatedTemplate>
                </>
            )}
        </>
    );
};
