import {createSlice, createAction, createAsyncThunk} from '@reduxjs/toolkit';
import useApi from '../../features/hooks/redux/apiConfig/useApi';
import {SearchCard} from '../../client/openapitools/search';

interface MarketplaceSearch {
    loading: boolean;
    cardSuggestions: SearchCard[];
    searchString: string;
}

const initialState = {loading: false, cardSuggestions: [], searchString: ''} as MarketplaceSearch;
// @ts-ignore this is a bit of a mess with the swagger.  Its right though.
const searchSuggestionApiCall = createAsyncThunk('marketplace/search/searchsuggestions', async (search: string) => {
    if (!search) {
        return [];
    }

    const api = useApi();
    const result = await api.searchApi.marketplace.fetchSuggestions(search);
    // @ts-ignore this is a bit of a mess with the swagger.  Its right though.
    return result.data;
});

const searchChangedAction = createAction<string>('marketplace/search/searchChanged');

export const clearSearch = createAction('marketplace/search/clearSearch');

export const searchChanged = (search: string) => {
    return dispatch => {
        // dispatch(searchSuggestionApiCall(search));
        dispatch(searchChangedAction(search));
    };
};

const marketplaceSlice = createSlice({
    name: 'marketplaceSearch',
    initialState,
    reducers: {
        // omit existing reducers here
    },
    extraReducers: builder => {
        builder.addCase(searchSuggestionApiCall.pending, state => {
            state.loading = true;
            state.cardSuggestions = [];
        });
        builder.addCase(searchSuggestionApiCall.fulfilled, (state, action) => {
            state.loading = false;
            // @ts-ignore this is actually right.  swagger is off.
            state.cardSuggestions = action.payload;
        });
        builder.addCase(searchSuggestionApiCall.rejected, state => {
            state.loading = false;
            state.cardSuggestions = [];
        });
        builder.addCase(searchChangedAction, (state, action) => {
            state.searchString = action.payload;
        });
        builder.addCase(clearSearch, () => {
            return initialState;
        });
    },
});

export default marketplaceSlice.reducer;
