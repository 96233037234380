import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCTextField from '../../../../../features/common/bscTextField/bscTextField';
import BSCDateRangePicker from '../../../../../features/common/bscDatePicker/bscDateRangePicker';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCLoading from '../../../../../features/common/bscLoading/bscLoading';
import classNames from 'classnames';
import {Collapse, Hidden, useMediaQuery} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import '../orders.scss';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#F5F8FA',
        boxShadow: 'none',
        //minHeight: 136,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            display: 'flex',
        },
    },
    flexColumnSmall: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    flexcolumn: {
        flexDirection: 'column',
    },
    filterGroup: {
        borderRadius: 0,
        height: '100%',
    },
    formWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginRight: theme.spacing(0),
            '& > *': {
                width: '100%',
            },
        },
        '& input': {
            background: theme.palette.common.white,
            padding: '10px 14px',
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(1),
        },
    },
    label: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(1),
        },
    },
    actions: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {},
        '& .MuiButtonBase-root': {
            fontSize: 14,
        },
        marginTop: '-4px',
    },
    actionsMobile: {
        padding: '0 16px 16px',
        [theme.breakpoints.down('sm')]: {},
    },
    buttons: {
        flexBasis: 'auto',
    },
    loading: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
    },
    searchAccordian: {
        width: '100%',
        justifyContent: 'center',
    },
    smallRadio: {
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
        },
    },
}));

function OrderListFilter({
    onSearch,
    onClear,
    loading,
    formState,
    sellersFormStateValidations,
    registerFormChange,
    formComplete,
    sellersFormValidationsLoading,
}) {
    const classes = useStyles();
    const {isDesktop} = useScreenSnap();
    const location = useLocation();
    const handleEnterClick = useCallback(
        e => {
            if (e.key === 'Enter') {
                onSearch(formState);
            }
        },
        [formState]
    );

    const startProps = {
        inputKey: 'fromDate',
        onChange: registerFormChange,
        onKeyDown: handleEnterClick,
        formStateValidations: sellersFormStateValidations,
        fullWidth: true,
    };

    const endProps = {
        inputKey: 'toDate',
        onChange: registerFormChange,
        onKeyDown: handleEnterClick,
        formStateValidations: sellersFormStateValidations,
        fullWidth: true,
    };

    const gridProps = {xs: 12, md: 6};
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [hasClearedSearch, setHasClearedSearch] = useState(false);
    const clearSearch = () => {
        setHasClearedSearch(true);
        onClear();
    };

    useEffect(() => {
        if (location?.state?.orderNumber && !hasClearedSearch) {
            registerFormChange({target: {value: location.state.orderNumber, name: 'orderId'}});
        }
    }, [location, formState]);

    return (
        <div className={classes.root}>
            <Collapse in={open || matches}>
                {sellersFormValidationsLoading && (
                    <div className={classes.loading}>
                        <BSCLoading
                            loading
                            loadingReasons={{
                                ['Loading Seller Data']: true,
                            }}
                        />
                    </div>
                )}
                {!sellersFormValidationsLoading && formState && (
                    <Grid container className={classes.flexColumnSmall}>
                        <Grid item xs>
                            <BSCCardBox className={classNames(classes.root, classes.filterGroup)}>
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <div className={classes.formWrapper}>
                                            <Hidden mdDown>
                                                <BSCTypography className={classes.label} label="Search" />
                                            </Hidden>
                                            <BSCTextField
                                                inputKey="customerIdx"
                                                placeholder="Customer"
                                                onKeyDown={handleEnterClick}
                                                onChange={registerFormChange}
                                                formStateValidations={sellersFormStateValidations}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <div className={classes.formWrapper}>
                                            <Hidden mdDown>
                                                <BSCTypography className={classes.label} label="Order" />
                                            </Hidden>
                                            <BSCTextField
                                                inputKey="orderId"
                                                placeholder="Number ID"
                                                onChange={registerFormChange}
                                                onKeyDown={handleEnterClick}
                                                formStateValidations={sellersFormStateValidations}
                                                fullWidth
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <BSCDateRangePicker
                                            startPickerProps={startProps}
                                            endPickerProps={endProps}
                                            startGridProps={gridProps}
                                            endGridProps={gridProps}
                                        />
                                    </Grid>
                                </Grid>
                            </BSCCardBox>
                        </Grid>
                        <Grid item className={classes.buttons}>
                            <Grid
                                container
                                className={isDesktop ? classes.actions : classes.actionsMobile}
                                justifyContent="flex-start"
                                spacing={1}
                                alignItems="center"
                            >
                                <Grid item xs={6} md={6}>
                                    <BSCButton
                                        fullWidth
                                        color="primary"
                                        disabled={!formComplete}
                                        onClick={() => {
                                            formState.customerIdx = '';
                                            formState.orderId = '';
                                            formState.fromDate = '';
                                            formState.toDate = '';
                                            clearSearch();
                                        }}
                                        style={{fontSize: 12}}
                                    >
                                        Clear
                                    </BSCButton>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <BSCButton
                                        fullWidth
                                        disabled={loading}
                                        color="secondary"
                                        onClick={() => onSearch(formState)}
                                        style={{fontSize: 12}}
                                    >
                                        Search
                                    </BSCButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Collapse>
        </div>
    );
}

export default OrderListFilter;
