import {Redirect, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {makeStyles} from '@material-ui/core/styles';

interface PlaceOrderSuccess {
    email: string;
    estimatedDate: string;
    message: {description: string; level: string};
    orderId: string;
}

interface ThankYouData {
    thankYou: PlaceOrderSuccess;
}

const useStyles = makeStyles(() => ({
    textCenter: {
        textAlign: 'center',
    },
}));

function ThankYou() {
    const {state}: {state: ThankYouData} = useLocation();

    const thankYouData = state?.thankYou;
    const classes = useStyles();

    if (!thankYouData) {
        return <Redirect push to="/cart/checkout" />;
    }

    return (
        <BSCContainer variant="page">
            <BSCCardBox className={classes.textCenter}>
                <BSCTypography variant="h2" label="Thank you!" />
                {/* <BSCTypography label={`Your order will arrive by ${thankYouData.estimatedDate}`} /> */}
                <BSCDivider />
                <BSCTypography
                    label={`For all your order details, check out the confirmation email we’re sending to ${thankYouData.email}`}
                />
                <BSCTypography label={`Confirmation #: ${thankYouData.confirmationNumber}`} />
                <BSCButton component={Link} to="/dashboard/orderHistory" color="primary">
                    <BSCTypography size12 label={'View Order History'} />
                </BSCButton>
            </BSCCardBox>
        </BSCContainer>
    );
}

export default ThankYou;
