/* eslint-disable @typescript-eslint/no-explicit-any */
import {Card, Product} from '../../openapitools/marketplace';
import {useProductReferenceContext} from '../../../ProductReferenceContext';
import ProductReferencesNormalizer from '../../common/card/product/productReferencesNormalizer';
import {SearchCard} from '../../openapitools/search';

const findNameFromKey = (array, searchField) => {
    return (
        array?.find(item => {
            return item.key === searchField;
        })?.name ?? ''
    );
};

class cardNormalizer {
    constructor(productReferences: undefined) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        self.productType = ProductReferencesNormalizer.getProductType(productReferences);
        self.condition = ProductReferencesNormalizer.getCondition(productReferences);
        self.conditionVintage = ProductReferencesNormalizer.getConditionVintage(productReferences);
        self.gradingCompany = ProductReferencesNormalizer.getCompany(productReferences);
        self.productReferences = productReferences;
    }
    productReferences: undefined;
    productType: undefined;
    condition: undefined;
    conditionVintage: undefined;
    gradingCompany: undefined;

    getPackingSlipPlayerName = (playerData: Card | SearchCard) => {
        if (playerData?.players.length > 60) {
            return playerData?.players && playerData?.cardNo ? playerData?.players.substring(0, 60) + '... #' + playerData?.cardNo : null;
        }
        return playerData?.players && playerData?.cardNo ? `${playerData?.players} #${playerData?.cardNo}` : null;
    };
    getPlayerName = (playerData: Card | SearchCard) =>
        playerData?.players && playerData?.cardNo ? `${playerData?.players} #${playerData?.cardNo}` : null;
    getSetAndVariant = (playerData: Card | SearchCard) =>
        playerData?.setName + (playerData?.variantName ? ' • ' + playerData?.variantName : '');
    getVariantAndVariantName = (playerData: Card | SearchCard) =>
        playerData?.variantName && playerData?.variant ? playerData.variant + ' (' + playerData.variantName + ')' : playerData?.variant;
    getVariantNameAndVariant = (playerData: Card | SearchCard) =>
        playerData?.variantName && playerData?.variant ? playerData.variantName + ' (' + playerData.variant + ')' : playerData?.variant;

    getProductTypeAndCondition = (playerData: Product, isVintage?: boolean) => {
        let productTypeAndCondition;
        if (isVintage) {
            productTypeAndCondition =
                playerData?.productType && (playerData?.condition || playerData?.grade)
                    ? (this.productType?.find(x => x.key === playerData?.productType)?.name ?? playerData?.productType) +
                      ' (' +
                      this.getRawCondition(playerData, true) +
                      ')'
                    : null;
        } else {
            productTypeAndCondition =
                playerData?.productType && (playerData?.condition || playerData?.grade)
                    ? (this.productType?.find(x => x.key === playerData?.productType)?.name ?? playerData?.productType) +
                      ' (' +
                      this.getCondition(playerData) +
                      ')'
                    : null;
        }
        return productTypeAndCondition;
    };

    getCoGradeList = company => {
        switch (company) {
            case 'bgs':
                return this.productReferences?.bgsGrades;
            case 'bvg':
                return this.productReferences?.bvgGrades;
            case 'csg':
                return this.productReferences?.csgGrades;
            case 'hga':
                return this.productReferences?.hgaGrades;
            case 'psa':
                return this.productReferences?.psaGrades;
            case 'psa_dna':
                return this.productReferences?.psa_dnaGrades;
            case 'sgc':
                return this.productReferences?.sgcGrades;
            default:
                return this.productReferences?.bgsGrades;
        }
    };

    getGrade = (playerData: Product) => {
        const gradingCoName = findNameFromKey(this.gradingCompany, playerData.gradingCompany);

        const grade = findNameFromKey(this.getCoGradeList(playerData.gradingCompany), playerData.grade);

        if (gradingCoName && grade) {
            return `${gradingCoName ?? ''} ${grade ?? ''}`;
        } else {
            return '';
        }
    };
    getRawCondition = (playerData: Product, isVintage?: boolean) => {
        if (isVintage) {
            return findNameFromKey(this.conditionVintage, playerData.condition);
        } else {
            return findNameFromKey(this.condition, playerData.condition);
        }
    };
    getCondition = (playerData: Product) => {
        let conditionName = '';

        if (playerData.productType === 'graded') {
            conditionName = this.getGrade(playerData);
        } else {
            conditionName = this.getRawCondition(playerData);
        }
        return conditionName;
    };
}

export const useCardNormalizer = () => {
    const {productReferences} = useProductReferenceContext();

    return new cardNormalizer(productReferences);
};

export default cardNormalizer;
