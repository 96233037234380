import {createAction, createSlice} from '@reduxjs/toolkit';

export interface ApiConfigPortalContext {
    context: 'sellers' | 'buyers';
}

export interface ApiConfigApiToken {
    apiToken: string;
}

interface ApiConfigState {
    context: 'sellers' | 'buyers';
    apiToken: string;
}

export const changePortal = createAction<ApiConfigPortalContext>('apiConfig/changePortal');
export const setApiToken = createAction<ApiConfigApiToken>('apiConfig/setApiToken');

const initialState = {context: 'buyers', apiToken: ''} as ApiConfigState;

const apiConfigSlice = createSlice({
    name: 'apiConfig',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(changePortal, (state, action) => {
            state.context = action.payload.context;
        });
        builder.addCase(setApiToken, (state, action) => {
            state.apiToken = action.payload.apiToken;
        });
    },
});

export default apiConfigSlice.reducer;
