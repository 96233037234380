import {AccountInfo, Configuration, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import {AUTH_REQUESTS} from '../../authConfig';
import {useAuthFunctions, useAuthState} from '../../AuthContext';
import {UserApi} from '../../client/openapitools/seller/api';
import BSCLoading from '../../features/common/bscLoading/bscLoading';
import useReduxToast from '../../features/hooks/redux/toast/useReduxToast';
import {RootState} from '../../redux/reducers';
import * as constants from '../../shared/constants';
import PWASellerDashboard from '../marketplace/pages/pwa/pwaSellerDashboard';
import SellersPortal from './pages/sellersPortal/sellersPortal';

const SellersRoot = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const {apiAuthConfig} = useAuthState();
    const authFunc = useAuthFunctions();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const isSellerSignup = useRouteMatch('/sellers/signup');
    const history = useHistory();
    const {instance} = useMsal();
    const {addToast} = useReduxToast();

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const userResponse = await userApi.getSellerUserProfile();
                            setUser(userResponse.data);
                            dispatch({
                                type: constants.SET_SELLERS_LOCKER_USER,
                                payload: {sellersLockerUser: userResponse.data},
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: "Seller's Locker",
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    if (user === null) {
        return <BSCLoading loading={true}></BSCLoading>;
    }

    if (user?.sellerProfile?.hasVerifiedMicroDeposits) {
        console.log('>>>', user);
        if (marketplaceState.pwaSettings.pwaOS !== '') {
            return <PWASellerDashboard />;
        } else {
            return <SellersPortal />;
        }
    }

    if (user?.sellerProfile?.applicationStatus === 'completed') {
        console.log('>>>', user);
        if (marketplaceState.pwaSettings.pwaOS !== '') {
            history.replace('/sellers/pwa/dashboard/seller');
            return <></>;
        } else {
            return <SellersPortal />;
        }
    } else if (user?.sellerProfile?.applicationStatus === 'pending' && !isSellerSignup) {
        console.log('>>>', user);
        history.replace('/sellers/signup');
        return <></>;
    } else if (user?.sellerProfile?.applicationStatus === 'contact') {
        //TODO: validate the appication status that is a partial and needs to be sent to contact us page.
        history.replace('/support');
        return <></>;
    } else if (!isSellerSignup) {
        console.log('>>>', user);
        if (marketplaceState.pwaSettings.pwaOS !== '' && (user === {} || user?.roleId === '2')) {
            history.replace('/sellers/pwa/dashboard/seller');
        } else {
            history.replace('/sellers/signup');
        }
        return <></>;
    }
};

export default SellersRoot;
