import React from 'react';
import BSCLoading from '../../../../../features/common/bscLoading/bscLoading';
import SellYourCardEdit from './sellYourCardEdit';
import Alert from '@material-ui/lab/Alert';
import {Product, SellerSetting, Card} from '../../../../../client/openapitools/seller';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';

interface SellYourCardEditProps {
    cardListing: Product[];
    sellerSettings: SellerSetting;
    sellerCardInstance: string;
    sellerCardData: Card;
    loading: boolean;
    isVintage: boolean;
}
const SellYourCardUpdate = ({
    loading,
    cardListing,
    sellerSettings,
    sellerCardData,
    sellerCardInstance,
    isVintage,
}: SellYourCardEditProps) => {
    if (loading) {
        return <BSCLoading loading />;
    }
    return (
        <>
            {!loading && !cardListing?.length && (
                <Alert severity="warning" variant="filled">
                    <BSCTypography label="No listings added yet for this card" />
                </Alert>
            )}
            {!loading &&
                cardListing?.map(product => {
                    return (
                        <SellYourCardEdit
                            sellerCardInstance={sellerCardInstance}
                            key={product.productId}
                            loading={loading}
                            data={product}
                            sellerSettings={sellerSettings}
                            sellerCardData={sellerCardData}
                            isVintage={isVintage}
                        />
                    );
                })}
        </>
    );
};

export default SellYourCardUpdate;
