/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCCustomSwitch from '../../../../../../../features/common/bscSwitch/bscCustomSwitch';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import classNames from 'classnames';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import BSCEditToggle from '../../../../../../../features/common/bscEditToggle/bscEditToggle';
import {SellerSetting, ShippingSettings} from '../../../../../../../client/openapitools/seller';
import BscToggledEditButton from '../../../../../../../features/common/bscEditIcon/bscEditIcon';
import ShippingTiersSettings from './shippingTiersSettings';
import {original} from 'immer';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
    switch: {
        '& > span:nth-child(2)': {
            backgroundColor: '#EF2424 !important',
            '&:before': {
                content: "''",
                fontSize: theme.typography.subtitle2.fontSize,
                fontStyle: 'italic',
            },

            '&:after': {
                content: "'OFF'",
                color: theme.palette.common.white,
                fontSize: theme.typography.subtitle2.fontSize,
                fontStyle: 'italic',
            },
        },
    },
    checked: {
        '& > span:nth-child(2)': {
            backgroundColor: '#358856 !important',
            '&:before': {
                content: "'ON'",
            },

            '&:after': {
                content: "''",
            },
        },
    },
}));

interface SellerSettingsProps {
    listingSettings: SellerSetting;
    shippingSettings: ShippingSettings[];
    onSave: Function;
}

const SellerSettings = ({listingSettings, shippingSettings, onSave}: SellerSettingsProps) => {
    const classes = useStyles();
    const {isMobile} = useScreenSnap();
    const [updatedShippingSettings, setUpdatedShippingSettings] = useState<ShippingSettings[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isTakingOffers, setIsTakingOffers] = useState<boolean>(false);
    const edit = useCallback(() => {
        setIsEdit(prevState => !prevState);
    }, [setIsEdit]);
    const onSubmit = useCallback(() => {
        onSave({sellerSettings: {offers: isTakingOffers}, shippingSettings: updatedShippingSettings});
        setIsEdit(!isEdit);
    }, [onSave, setIsEdit, isEdit, isTakingOffers, updatedShippingSettings]);
    const onChangeOffersStatus = useCallback(() => {
        setIsTakingOffers(!isTakingOffers);
    }, [isTakingOffers, setIsTakingOffers]);

    const shippingSettingsModified = modifiedShippingSettings => {
        const shippingTiers = modifiedShippingSettings.shippingSettings;
        shippingTiers.forEach(shippingTier => {
            if (shippingTier.tierCode === 'XS') {
                shippingTier.rate = shippingTiers[0].rate;
            }
            if (shippingTier.tierCode === 'S') {
                shippingTier.rate = shippingTiers[1].rate;
            }
            if (shippingTier.tierCode === 'M') {
                shippingTier.rate = shippingTiers[2].rate;
            }
            if (shippingTier.tierCode === 'L') {
                shippingTier.rate = shippingTiers[3].rate;
            }
            if (shippingTier.tierCode === 'XL') {
                shippingTier.rate = shippingTiers[4].rate;
            }
            if (shippingTier.tierCode === 'G') {
                shippingTier.rate = shippingTiers[5].rate;
            }
            if (shippingTier.tierCode === 'F') {
                shippingTier.rate = shippingTiers[6]?.rate;
            }
        });
        setUpdatedShippingSettings(shippingTiers);
    };

    const [originalIsTakingOffers, setOriginalIsTakingOffers] = useState(listingSettings.offers);
    useEffect(() => {
        setOriginalIsTakingOffers(listingSettings.offers);
        setIsTakingOffers(listingSettings.offers);
    }, [listingSettings]);

    useEffect(() => {
        if (isEdit === false) {
            setIsTakingOffers(originalIsTakingOffers);
            setUpdatedShippingSettings(originalShippingSettings);
        }
    }, [isEdit]);

    const [originalShippingSettings, setOriginalShippingSettings] = useState(shippingSettings);
    useEffect(() => {
        setOriginalShippingSettings(shippingSettings);
        setUpdatedShippingSettings(shippingSettings);
    }, [shippingSettings]);

    return (
        <BSCCardBox className={classes.root}>
            <Grid container justifyContent="space-between">
                <BSCTypography variant="body2" label="Seller Settings" />
                <BscToggledEditButton isEdit={isEdit} onCLick={edit}></BscToggledEditButton>
            </Grid>
            <BSCDivider />
            {!shippingSettings && <BSCLoading loading={true} />}
            {shippingSettings && (
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={6} md={3}>
                                <BSCTypography label="Your Shipping Tiers:" variant="subtitle1" />
                            </Grid>
                            <Grid item xs={6} md={9} style={isMobile ? {maxWidth: 'none', flexBasis: 'auto'} : {}}>
                                {isEdit && (
                                    <BSCEditToggle
                                        isEdit={isEdit}
                                        trueChild={
                                            <ShippingTiersSettings
                                                isEdit={isEdit}
                                                shippingTiers={shippingSettings}
                                                onModification={shippingSettingsModified}
                                            ></ShippingTiersSettings>
                                        }
                                        falseChild={
                                            <ShippingTiersSettings
                                                isEdit={isEdit}
                                                shippingTiers={originalShippingSettings}
                                                onModification={shippingSettingsModified}
                                            ></ShippingTiersSettings>
                                        }
                                    />
                                )}
                                {!isEdit && (
                                    <BSCEditToggle
                                        isEdit={isEdit}
                                        trueChild={
                                            <ShippingTiersSettings
                                                isEdit={isEdit}
                                                shippingTiers={shippingSettings}
                                                onModification={shippingSettingsModified}
                                            ></ShippingTiersSettings>
                                        }
                                        falseChild={
                                            <ShippingTiersSettings
                                                isEdit={isEdit}
                                                shippingTiers={originalShippingSettings}
                                                onModification={shippingSettingsModified}
                                            ></ShippingTiersSettings>
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <BSCDivider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={6} md={3}>
                                <BSCTypography label="Accepting Offers:" variant="subtitle1" />
                            </Grid>
                            <Grid item xs={6} md={9}>
                                {isEdit && (
                                    <BSCCustomSwitch
                                        className={classNames(classes.switch, {
                                            [classes.checked]: isTakingOffers,
                                        })}
                                        checked={isTakingOffers}
                                        disabled={!isEdit}
                                        onClick={onChangeOffersStatus}
                                    />
                                )}
                                {!isEdit && (
                                    <BSCCustomSwitch
                                        className={classNames(classes.switch, {
                                            [classes.checked]: originalIsTakingOffers,
                                        })}
                                        checked={originalIsTakingOffers}
                                        disabled={!isEdit}
                                        onClick={onChangeOffersStatus}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    {isEdit && (
                        <Grid item xs={12}>
                            <BSCDivider />
                            <BSCButton fullWidth color="primary" onClick={onSubmit}>
                                <BSCTypography label="Save Changes" size14 />
                            </BSCButton>
                        </Grid>
                    )}
                </Grid>
            )}
        </BSCCardBox>
    );
};

export default SellerSettings;
