import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import {useCallback} from 'react';
import './productPage.scss';
import {makeStyles, MobileStepper, Checkbox, Theme} from '@material-ui/core';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {ModalProps} from '@material-ui/core/Modal';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import Chip from '@material-ui/core/Chip';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCGridTableRow from '../../../../features/common/bscGridTableRow/bscGridTableRow';
import BSCTextField from '../../../../features/common/bscTextField/bscTextField';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import {useHistory} from 'react-router';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import Grid from '@material-ui/core/Grid';
import useForm from '../../../../packages/core/src/hooks/useForm';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import FormValidationsNormalizer from '../../../../client/s3/formValidations/formValidationsNormalizer';
import FormValidationsClient from '../../../../client/s3/formValidations/formValidationsClient';
import {CircularProgress} from '@material-ui/core';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import {Card, OfferApi, Product} from '../../../../client/openapitools/marketplace';
import Normalizer from '../../../../client/common/normalizer/normalizer';
import {toNumber} from 'lodash';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {AccountInfo, Configuration, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    box: {
        width: '100%',
        maxWidth: 800,
        margin: 'auto',
    },
    icon: {
        position: 'absolute',
        top: 50,
        right: 50,
    },
    imageWrap: {display: 'flex', justifyContent: 'center', marginBottom: 0},
    loading: {textAlign: 'center', margin: theme.spacing(2, 0)},
    chip: {
        //marginRight: theme.spacing(2)
    },
    img: {
        width: 145,
        height: 190,
    },
    splitLeft: {
        align: 'left !important',
    },
    cardFeedSider: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        width: '58%',
    },
    dotActive: {
        background: theme.palette.primary.main,
    },
    stepper: {
        left: '50%',
        bottom: '16px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        justifyContent: 'center',
        paddingBottom: '16px',
    },
    labelText: {
        fontSize: '14px',
    },
    condition: {
        fontSize: '14px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
        marginBottom: 28,
    },
    cartTitle: {
        fontSize: '16px', // theme.typography.h3.fontSize,
        marginBottom: theme.spacing(0.5),
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: theme.typography.body2.fontSize,
        //     fontWeight: 'bold',
        //     lineHeight: 1.5,
        //     marginBottom: theme.spacing(0),
        // },
    },
    setName: {
        fontSize: '14px',
        fontWeight: 400,
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0),
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    priceLeft: {
        //fontSize: theme.typography.h3.fontSize,
        fontSize: '16px',
        fontWeight: 400,
    },
    priceError: {
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
    paddingLeft: {
        paddingLeft: '10px',
    },
}));

interface MakeAnOfferModalProps extends Omit<ModalProps, 'children'> {
    productInfo: Product;
    open: boolean;
    cardListing: Card;
    onModalClose: (open: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AuthContent({
    productId,
    quantity,
    price,
    currency,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sellerName,
    cardListing,
    grade,
    gradeCompany,
    condition,
    frontImg = null,
    backImg = null,
    productType,
    shippingTiers,
    onClose,
}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {isBelowTablet, isMobile} = useScreenSnap();
    const cardQuality = useCardNormalizer();
    const [priceAmount, setPriceAmount] = useState(currencyFormatter(price));
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const classes = useStyles();
    const isAuthenticated = useIsAuthenticated();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const authFunc = useAuthFunctions();
    const history = useHistory();
    const {data: validationSchema, loading: formValidationsLoading} = useQuery(() => FormValidationsClient.getFormValidations(), []);
    const {registerFormChange, formState, validatedSchema, formComplete} = useForm({
        validationSchema: FormValidationsNormalizer.getOfferModalValidationsSchema(validationSchema),
        defaultValues: {price: price, quantity: selectedQuantity},
    });
    const [activeStep, setActiveStep] = useState(0);
    const [isProductImageFrontView, setProductImageFrontView] = useState(true);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [featuredImageFront, setFeaturedImageFront] = useState(marketplaceState.productImageFront);
    const [featuredImageBack, setFeaturedImageBack] = useState(marketplaceState.productImageBack);
    const [priceError, setPriceError] = useState(false);
    const [lowPriceError, setLowPriceError] = useState(false);
    const [qtyError, setQtyError] = useState(false);
    const {addToast} = useReduxToast();
    const flipCard = () => {
        if (isProductImageFrontView) {
            setProductImageFrontView(false);
            setActiveStep(1);
        } else {
            setProductImageFrontView(true);
            setActiveStep(0);
        }
    };

    const lowDollarAmount = process.env.REACT_APP_MINIMUM_PURCHASE_SINGLE_SELLER;

    const handlePriceChange = event => {
        const inputPrice =
            event.target.value.indexOf('.') === 0
                ? toNumber('0' + event.target.value.replace(',', ''))
                : toNumber(event.target.value.replace(',', ''));
        const listingPrice = toNumber(price.replace(',', ''));

        setPriceAmount(inputPrice.toString());
        registerFormChange(event);

        if (inputPrice > listingPrice || inputPrice === 0) {
            setPriceError(true);
        } else setPriceError(false);
    };

    useEffect(() => {
        validateLowPrice(selectedQuantity, price);
        calculateShippingFee(selectedQuantity);
    }, []);

    const [shippingFee, setShippingFee] = useState(0.0);
    const validateLowPrice = (qty, price) => {
        if (price * qty + shippingFee < toNumber(lowDollarAmount)) {
            setLowPriceError(true);
        } else {
            setLowPriceError(false);
        }
    };

    const handlePriceBlur = event => {
        let formattedPrice = event.target.value;
        if (event.target.value.indexOf('.') === -1) {
            formattedPrice = currencyFormatter(`${event.target.value}.00`);
        } else {
            formattedPrice = currencyFormatter(event.target.value);
        }
        setPriceAmount(formattedPrice);
        event.target.value = formattedPrice;
        registerFormChange(event);
        validateLowPrice(formState.quantity, event.target.value);
    };

    const handleQuantityChange = event => {
        setShippingFee(0.0);
        setSelectedQuantity(event.target.value);
        registerFormChange(event);
        if (event.target.value > quantity || !event.target.value) {
            setQtyError(true);
        } else {
            setQtyError(false);
            validateLowPrice(event.target.value, formState.price);
            if (event.target.value > 0) {
                calculateShippingFee(event.target.value);
            }
        }
    };

    const [loading, setLoading] = useState(false);
    const handleSubmitOffer = useCallback(() => {
        if (!priceError && !qtyError && termsAccepted && !lowPriceError) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            setLoading(true);
                            const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                            const marketplaceOfferApi = new OfferApi(apiConfig);
                            try {
                                const offerData = {
                                    productId,
                                    offerPrice: priceAmount.replace(',', ''),
                                    currency: currency,
                                    offerQuantity: selectedQuantity,
                                    offerShippingCost: shippingFee,
                                };
                                const response = await marketplaceOfferApi.submitOffer(offerData);
                                if (response.status === 200 || response.status === 201) {
                                    addToast({
                                        severity: 'success',
                                        message: 'Offer successfully sent',
                                    });
                                    onClose();
                                }
                                setLoading(false);
                            } catch (error) {
                                console.log(error);
                                addToast({
                                    severity: 'error',
                                    message: 'Error sending offer',
                                });
                                setLoading(false);
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    }, [termsAccepted, history, productId, formState, currency]);

    const calculateShippingFee = quantity => {
        if (quantity === 0) {
            setShippingFee(0.0);
        } else {
            if (productType === 'raw') {
                if (quantity > 0 && quantity <= 4) {
                    setShippingFee(shippingTiers.find(tier => tier.tierCode === 'XS').rate);
                }
                if (quantity >= 5 && quantity <= 12) {
                    setShippingFee(shippingTiers.find(tier => tier.tierCode === 'S').rate);
                }
                if (quantity >= 13 && quantity <= 24) {
                    setShippingFee(shippingTiers.find(tier => tier.tierCode === 'M').rate);
                }
                if (quantity >= 25 && quantity <= 49) {
                    setShippingFee(shippingTiers.find(tier => tier.tierCode === 'L').rate);
                }
                if (quantity >= 50) {
                    setShippingFee(shippingTiers.find(tier => tier.tierCode === 'XL').rate);
                }
            } else {
                // GRADED
                setShippingFee(shippingTiers.find(tier => tier.tierCode === 'G').rate);
            }
            const freeShippingTier = shippingTiers.find(tier => tier.tierCode === 'F');
            if (freeShippingTier) {
                const freeRangeStart = freeShippingTier.rangeStart;
                const freeRangeEnd = freeShippingTier.rangeEnd;
                const calculatedAmount = formState.price * formState.quantity;
                if (calculatedAmount >= freeRangeStart && calculatedAmount <= freeRangeEnd) {
                    setShippingFee(0.0);
                }
            }
        }
    };

    const [isStockImageFront, setStockImageFront] = useState(false);
    const [isStockImageBack, setStockImageBack] = useState(false);
    const [ribbonSellerName, setRibbonSellerName] = useState(null);

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.sellerImageFront.includes(marketplaceState.stockImageFront)) {
                setStockImageFront(true);
            } else {
                setStockImageFront(false);
            }

            if (marketplaceState.sellerImageBack.includes(marketplaceState.stockImageBack)) {
                setStockImageBack(true);
            } else {
                setStockImageBack(false);
            }
            setFeaturedImageFront(frontImg ? Normalizer.getS3ImagePath(frontImg) : marketplaceState.sellerImageFront);
            setFeaturedImageBack(backImg ? Normalizer.getS3ImagePath(backImg) : marketplaceState.sellerImageBack);

            if (marketplaceState.sellerName?.length > 16) {
                setRibbonSellerName(marketplaceState.sellerName.slice(0, 16) + '...');
            } else {
                setRibbonSellerName(marketplaceState.sellerName);
            }
        }
    }, [marketplaceState]);

    if (!isAuthenticated) {
        addToast({
            severity: 'error',
            message: 'User must be Signed In.',
            contextKey: 'Authentication',
        });
    }

    return (
        <>
            {isProductImageFrontView && !isStockImageFront && marketplaceState.sellerName.length > 0 ? (
                <div
                    className={`${
                        isBelowTablet || isMobile
                            ? isMobile
                                ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                            : 'ribbon ribbon-top-left'
                    }`}
                >
                    <span>
                        Viewing<p style={{margin: '4px 0px 0px'}}>{ribbonSellerName}</p>
                    </span>
                </div>
            ) : null}

            {!isProductImageFrontView && !isStockImageBack && marketplaceState.sellerName.length > 0 ? (
                <div
                    className={`${
                        isBelowTablet || isMobile
                            ? isMobile
                                ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                            : 'ribbon ribbon-top-left'
                    }`}
                >
                    <span>
                        Viewing<p style={{margin: '4px 0px 0px'}}>{ribbonSellerName}</p>
                    </span>
                </div>
            ) : null}

            {isProductImageFrontView && isStockImageFront && marketplaceState.sellerName ? (
                <div
                    className={`${
                        isBelowTablet || isMobile
                            ? isMobile
                                ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                            : 'ribbon ribbon-top-left'
                    }`}
                >
                    <span style={{backgroundColor: '#EF2424'}}>
                        <p style={{margin: '4px 8px 4px 0'}}>No Seller Image</p>
                    </span>
                </div>
            ) : null}

            {!isProductImageFrontView && isStockImageBack && marketplaceState.sellerName ? (
                <div
                    className={`${
                        isBelowTablet || isMobile
                            ? isMobile
                                ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                            : 'ribbon ribbon-top-left'
                    }`}
                >
                    <span style={{backgroundColor: '#EF2424'}}>
                        <p style={{margin: '4px 8px 4px 0'}}>No Seller Image</p>
                    </span>
                </div>
            ) : null}

            <div className={classes.imageWrap}>
                <BSCImage className={classes.img} src={isProductImageFrontView ? featuredImageFront : featuredImageBack} />
            </div>
            <MobileStepper
                classes={{dotActive: classes.dotActive}}
                className={classes.stepper}
                activeStep={activeStep}
                onClick={() => flipCard()}
                steps={2}
                variant="dots"
            />
            <BSCTypography align="center" variant="body1" size18 label={cardListing?.productName} gutterBottom semibold />
            {/* <BSCTypography className={classes.setName} align="center" label={cardInfo.getSetAndVariant(cardListing)} gutterBottom /> */}

            <Grid container alignItems="center" justifyContent="center">
                <BSCDivider noMargin />
                {condition && (
                    <Chip
                        className={classes.chip}
                        label={cardQuality.getRawCondition({condition: `${condition}`}, marketplaceState.isVintage)}
                    />
                )}
                {grade && (
                    <>
                        <Chip
                            className={classes.chip}
                            label={cardQuality.getGrade({gradingCompany: `${gradeCompany}`, grade: `${grade}`})}
                        />
                    </>
                )}
            </Grid>
            <BSCDivider />
            <BSCTypography align="center" label="Enter Your Offer Below" gutterBottom />
            {formValidationsLoading && (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            )}
            {validationSchema && (
                <>
                    {priceError && (
                        <BSCTypography
                            className={classes.priceError}
                            size14
                            label={`The Offer price must be less than $${currencyFormatter(price)} and greater than 0`}
                        />
                    )}
                    {lowPriceError && (
                        <BSCTypography
                            className={classes.priceError}
                            size14
                            label={`The Offer price must be greater than $${currencyFormatter(lowDollarAmount)}`}
                        />
                    )}
                    {qtyError && (
                        <BSCTypography className={classes.priceError} size14 label={`The Quantity must be less than  ${quantity}`} />
                    )}
                    <BSCGridTableRow
                        gutterBottom
                        flip
                        col8={
                            <BSCGridTableRow
                                flip
                                col4={
                                    <BSCTextField
                                        inputKey="price"
                                        formStateValidations={validatedSchema}
                                        value={priceAmount}
                                        onBlur={handlePriceBlur}
                                        onChange={handlePriceChange}
                                        fullWidth
                                    />
                                }
                                col8={
                                    <BSCTypography
                                        className={classes.paddingLeft}
                                        variant="body1"
                                        align="left"
                                        size12
                                        label={shippingFee === 0.0 ? '+ Free Shipping' : `+ ${currencyFormatter(shippingFee)} Shipping`}
                                    />
                                }
                            />
                        }
                        col4={<div className={classes.labelText}>{`Price (Each) ${currency}`}</div>}
                    />
                    <BSCGridTableRow
                        gutterBottom
                        flip
                        col8={
                            <BSCTextField
                                inputKey="quantity"
                                formStateValidations={validatedSchema}
                                value={selectedQuantity}
                                onChange={handleQuantityChange}
                                fullWidth
                            />
                        }
                        col4={<div className={classes.labelText}>{`Quantity (${quantity} available)`}</div>}
                    />
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Checkbox color="primary" checked={termsAccepted} onChange={event => setTermsAccepted(event.target.checked)} />
                        <BSCTypography
                            label={
                                'By continuing, you authorize BSC to charge your payment method if this Seller accepts your offer. If accepted, applicable Sales Tax will be added to your offer total.'
                            }
                            size10
                        />
                    </div>
                </>
            )}
            <BSCButton
                disabled={!termsAccepted || !formComplete || qtyError || priceError || lowPriceError}
                loading={loading}
                onClick={handleSubmitOffer}
                fullWidth
                color="primary"
            >
                <BSCTypography label={`Send Offer ($${currencyFormatter(toNumber(priceAmount) * selectedQuantity + shippingFee)})`} />
            </BSCButton>
        </>
    );
}

function MakeAnOfferModal({open, onModalClose, productInfo, cardListing, ...otherProps}: MakeAnOfferModalProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (!productInfo) {
        return null;
    }

    return (
        <BSCModal {...otherProps} open={open} onClose={onModalClose}>
            <AuthContent
                productId={productInfo?.productId}
                quantity={productInfo?.availableQuantity}
                price={productInfo?.price}
                currency={productInfo?.currency}
                sellerName={productInfo?.seller?.sellerProfile?.sellerStoreName}
                grade={productInfo?.grade}
                gradeCompany={productInfo?.gradingCompany}
                condition={productInfo?.condition}
                cardListing={cardListing}
                frontImg={productInfo.sellerImgFront}
                backImg={productInfo.sellerImgBack}
                productType={productInfo.productType}
                shippingTiers={productInfo?.seller?.sellerProfile?.shippingSettings}
                onClose={onModalClose}
            />
        </BSCModal>
    );
}

export default MakeAnOfferModal;
