/* eslint-disable prettier/prettier */
/* eslint-disable no-prototype-builtins */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-bind */
import * as constants from '../../../../shared/constants';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import {Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import BSCTextField from '../../../../features/common/bscTextField/bscTextField';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../features/common/skeletons/BSCTableRowSkeleton';

import BSCImage from '../../../../features/common/bscImage/bscImage';
import BscImagePopper from '../../../../features/common/bscImagePopper/bscImagePopper';
import Normalizer from '../../../../client/common/normalizer/normalizer';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import Grid from '@material-ui/core/Grid';
import RestrictedBulkUploadAdvisoryModal from './restrictedBulkUploadModal';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {ProductApi} from '../../../../client/openapitools/common/api';
import {BulkUploadApi, Configuration} from '../../../../client/openapitools/seller';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'none',
    },
    selectionsWrapper: {
        margin: '12px 0',
        padding: '8px 0',
        border: '#c9c9c9 3px solid',
        background: '#fff',
    },
    fullWidth: {
        width: '100%',
        flexWrap: 'nowrap',
        height: 271,
        overflow: 'hidden',
    },
    selectDimensions: {
        height: 34,
        paddingTop: 10,
    },
    selectEllipsis: {
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    blackRow: {
        color: 'black',
    },
    numbers: {
        borderRadius: '4px 0 0 4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.24)',
        '& input': {
            textAlign: 'right',
            fontSize: 14,
            padding: 12,
            paddingBottom: 16,
        },
        '& *': {
            borderRadius: 0,
        },
        '& label': {
            transform: 'translate(0px, 8px) scale(1)',
            fontSize: theme.typography.h6.fontSize,
        },
    },
    sku: {
        borderRadius: '4px 0 0 4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.24)',
        '& input': {
            textAlign: 'left',
            fontSize: 14,
            padding: 12,
            paddingBottom: 16,
        },
        '& *': {
            borderRadius: 0,
        },
        '& label': {
            transform: 'translate(0px, 8px) scale(1)',
            fontSize: theme.typography.h6.fontSize,
        },
    },
    lowButton: {
        color: '#EF2424',
        background: '#e8e8e8',
    },
    highButton: {
        color: '#358856',
        background: '#e8e8e8',
    },
    cardImg: {
        maxHeight: 120,
        maxWidth: '100%',
        objectFit: 'contain',
        background: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.75)',
    },
    imageBackgroundContainer: {
        position: 'relative',
        height: 218,
        width: '100%',
        filter: 'drop-shadow(3px 3px 3px rgba(0, 0, 0, 0))',
    },
    deleteButtonWrapperContent: {
        margin: 0,
        background: '#e0e0e0',
        position: 'absolute',
        borderRadius: '50%',
        width: 18,
        top: 2,
        right: -18,
        filter: 'drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.25))',
        cursor: 'pointer',
        fontSize: 12,
        fontWeight: 600,
        transform: 'translate(-50%, -50%)',
        zIndex: 0,
        textAlign: 'center',
    },
    largeIcon: {
        cursor: 'pointer',
    },
    table: {
        ['& > thead']: {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        ['& > thead th']: {
            fontSize: '16px',
            color: theme.palette.common.white,
            textTransform: 'capitalize',
            padding: theme.spacing(1),
            '&:last-child': {
                padding: theme.spacing(1, 0),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0.5, 0.25),
            },
        },
        ['& td']: {
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '13px',
                padding: theme.spacing(1, 0.25),
            },
        },
        ['& tr']: {
            transition: '.25s ease',
            borderBottom: '2px solid transparent',
        },
    },
    tableSmall: {
        padding: theme.spacing(1),
        '&:last-child': {
            padding: theme.spacing(1, 0),
        },
        ['& td']: {},
    },
    tableContainer: {
        borderRadius: 0,
        minHeight: 3120,
    },
    stickyWrapper: {
        height: '46vh',
    },
    stickyWrapperMobile: {
        height: '40vh',
        overflowY: 'auto',
    },
    tableCellHeader: {
        background: '#182026',
    },
    loadingWrap: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
}));

interface BulkUploadResultsProps {
    cardCondition: string;
    defaultPrice: string;
    defaultSKU: string;
    products: [];
}

const BulkUploadResults = ({products, cardCondition, defaultPrice, defaultSKU}: BulkUploadResultsProps) => {
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let bulkUploadApi = new BulkUploadApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const authFunc = useAuthFunctions();
    const classes = useStyles();
    const dispatch = useDispatch();
    const sellerState = useSelector((state: RootState) => state.seller);
    const [productData, setProductData] = useState([]);
    const [year, setYear] = useState(null);
    const [setName, setSetName] = useState(null);
    const [variant, setVariant] = useState(null);
    const [variantName, setVariantName] = useState(null);

    const [indexesTouched, setIndexesTouched] = useState<number[]>([]);
    const handlePriceBlur = (event, index) => {
        let formattedPrice = event.target.value;
        if (formattedPrice !== '') {
            if (event.target.value.indexOf('.') === -1) {
                formattedPrice = currencyFormatter(`${event.target.value}.00`);
            } else {
                formattedPrice = currencyFormatter(event.target.value);
            }
            const tempProducts = [...productData];
            const numericPrice = Number(formattedPrice.replace(/[^0-9.-]+/g, ''));
            if (numericPrice < 0.25) {
                tempProducts[index]['price'] = '0.25';
            } else {
                tempProducts[index]['price'] = formattedPrice;
            }
            if (indexesTouched.indexOf(index) === -1) {
                setIndexesTouched([...indexesTouched, index]);
            }
            setProductData(tempProducts);
            setDirtyForm(true);
        }
    };

    const handlePriceChange = (event, index) => {
        const tempProducts = [...productData];
        tempProducts[index]['price'] = event.target.value;
        if (indexesTouched.indexOf(index) === -1) {
            setIndexesTouched([...indexesTouched, index]);
        }
        setProductData(tempProducts);
        setDirtyForm(true);
    };

    const handleQuantityChange = (event, index) => {
        const tempProducts = [...productData];
        tempProducts[index]['availableQuantity'] = event.target.value;
        if (indexesTouched.indexOf(index) === -1) {
            setIndexesTouched([...indexesTouched, index]);
        }
        setProductData(tempProducts);
        setDirtyForm(true);
    };

    const handleSKUChange = (event, index) => {
        const tempProducts = [...productData];
        tempProducts[index]['sellerSku'] = event.target.value;
        if (indexesTouched.indexOf(index) === -1) {
            setIndexesTouched([...indexesTouched, index]);
        }
        setProductData(tempProducts);
        setDirtyForm(true);
    };

    const [dirtyForm, setDirtyForm] = useState(false);
    useEffect(() => {
        if (dirtyForm === true) {
            dispatch({
                type: constants.BULK_UPLOAD_FORM_DIRTY,
                payload: {status: true},
            });
        }
    }, [dirtyForm]);

    const getBSCLow = ndx => {
        const tempProducts = [...productData];
        tempProducts[ndx]['price'] = tempProducts[ndx]['leastPriceForType'];
        setProductData(tempProducts);
        setDirtyForm(true);
    };

    const getBSCLastSold = ndx => {
        const tempProducts = [...productData];
        tempProducts[ndx]['price'] = tempProducts[ndx]['lastSoldPrice'];
        setProductData(tempProducts);
        setDirtyForm(true);
    };

    const [isProcessing, setIsProcessing] = useState(true);
    useEffect(() => {
        if (products) {
            products.forEach(predecorationProduct => {
                if (predecorationProduct.price === '0') {
                    predecorationProduct.price = '';
                }
            });
            products.forEach(product => {
                if (product.price !== undefined && product.price !== '') {
                    product.price = currencyFormatter(product.price);
                } else {
                    if (defaultPrice !== '') {
                        product.price = defaultPrice;
                    }
                }
                if (defaultSKU !== '') {
                    if (product.sellerSku === undefined || product.sellerSku === '' || product.sellerSku === null) {
                        product.sellerSku = defaultSKU;
                    }
                }
                if (product.availableQuantity === undefined || product.availableQuantity === '0' || product.availableQuantity === 0) {
                    product.availableQuantity = '';
                }
                if (product.leastPriceForType !== undefined) {
                    product.leastPriceForType = currencyFormatter(product.leastPriceForType);
                }
                if (product.lastSoldPrice !== undefined) {
                    product.lastSoldPrice = currencyFormatter(product.lastSoldPrice);
                }
            });
            setProductData(products);
            setDirtyForm(false);
            setIsProcessing(false);
            dispatch({
                type: constants.BULK_UPLOAD_FORM_DIRTY,
                payload: {status: false},
            });
        }
    }, [products]);

    useEffect(() => {
        if (sellerState) {
            setYear(sellerState.bulkUploadYear);
            setSetName(sellerState.bulkUploadSetName);
            setVariant(sellerState.bulkUploadVariant);
            const variantNameIndex = sellerState.bulkUploadSelections.findIndex(
                bulkUploadSelection => bulkUploadSelection.category === 'variantName'
            );
            if (variantNameIndex > 0) {
                setVariantName(sellerState.bulkUploadVariantName);
            }
        }
    }, [sellerState]);

    useEffect(() => {
        if (defaultPrice) {
            products.forEach(product => {
                if (product.price !== undefined && product.price !== '0' && product.price !== '0.0') {
                    product.price = currencyFormatter(product.price);
                } else {
                    if (defaultPrice !== '') {
                        product.price = defaultPrice;
                    }
                }
            });
        }
    }, [defaultPrice]);

    useEffect(() => {
        if (defaultSKU) {
            products.forEach(product => {
                if (product.sellerSku === undefined || product.sellerSku === '' || product.sellerSku === null) {
                    product.sellerSku = defaultSku;
                }
            });
        }
    }, [defaultSKU]);

    const handleKeyPress = (e, ndx) => {
        if (e.keyCode === 9) {
            e.preventDefault();
        }
        const fieldId = e.target.id;
        let nextfield;
        switch (e.keyCode) {
            case 37:
                if (fieldId.indexOf('sku') !== -1) {
                    nextfield = document.querySelector('#qty' + ndx);
                }
                if (fieldId.indexOf('qty') !== -1) {
                    nextfield = document.querySelector('#yourPrice' + ndx);
                }
                break;
            case 38:
                if (fieldId.indexOf('yourPrice') !== -1) {
                    if (ndx >= 0) {
                        nextfield = document.querySelector('#yourPrice' + --ndx);
                    }
                }
                if (fieldId.indexOf('qty') !== -1) {
                    if (ndx >= 0) {
                        nextfield = document.querySelector('#qty' + --ndx);
                    }
                }
                if (fieldId.indexOf('sku') !== -1) {
                    if (ndx >= 0) {
                        nextfield = document.querySelector('#sku' + --ndx);
                    }
                }
                break;
            case 39:
                if (fieldId.indexOf('yourPrice') !== -1) {
                    nextfield = document.querySelector('#qty' + ndx);
                }
                if (fieldId.indexOf('qty') !== -1) {
                    nextfield = document.querySelector('#sku' + ndx);
                }
                break;
            case 9:
            case 13:
            case 40:
                if (fieldId.indexOf('yourPrice') !== -1) {
                    if (ndx < productData.length - 1) {
                        handlePriceBlur(e, ndx);
                        nextfield = document.querySelector('#yourPrice' + ++ndx);
                    }
                }
                if (fieldId.indexOf('qty') !== -1) {
                    if (ndx < productData.length - 1) {
                        nextfield = document.querySelector('#qty' + ++ndx);
                    }
                }
                if (fieldId.indexOf('sku') !== -1) {
                    if (ndx < productData.length - 1) {
                        nextfield = document.querySelector('#sku' + ++ndx);
                    }
                }
                break;
        }
        if (nextfield !== undefined && nextfield !== null) {
            e.preventDefault();
            document.querySelector('#' + nextfield.id).focus();
            document.querySelector('#' + nextfield.id).setSelectionRange(0, document.querySelector('#' + nextfield.id).value.length);
        }
    };

    const [frontImgData, setFrontImgData] = useState(null);
    const [backImgData, setBackImgData] = useState(null);

    const addFrontImg = (cardId, productId, fileData) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const productApi = new ProductApi(apiConfig);
                            const frontImgData = await productApi.uploadProductAttachment(cardId, productId, fileData);
                            setFrontImgData(frontImgData);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error attaching image.',
                                contextKey: 'Bulk Upload',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const addBackImg = (cardId, productId, fileData) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const productApi = new ProductApi(apiConfig);
                            const frontImgData = await productApi.uploadProductAttachment(cardId, productId, fileData);
                            setBackImgData(frontImgData);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error attaching image.',
                                contextKey: 'Bulk Upload',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const [imageIndex, setImageIndex] = useState(-1);
    const [imageProdId, setImageProdId] = useState(null);
    const [imageCardId, setImageCardId] = useState(null);

    const addFrontImgAttachment = file => {
        addFrontImg(imageCardId, imageProdId, file);
    };
    const addBackImgAttachment = file => {
        addBackImg(imageCardId, imageProdId, file);
    };

    useEffect(() => {
        if (frontImgData) {
            const tempProducts = [...productData];
            tempProducts[imageIndex]['sellerImgFront'] = frontImgData.data.objectKey;
            setProductData(tempProducts);
        }
    }, [frontImgData]);

    useEffect(() => {
        if (backImgData) {
            const tempProducts = [...productData];
            tempProducts[imageIndex]['sellerImgBack'] = backImgData.data.objectKey;
            setProductData(tempProducts);
        }
    }, [backImgData]);

    const handleFrontImage = e => {
        addFrontImgAttachment(e.target.files[0]);
        document.querySelector('#addPhotoFront' + imageIndex).style = 'display: none';
        document.querySelector('#loadingPhotoFront' + imageIndex).style = 'display: block';
    };
    const triggerFrontCardFileChooser = (ndx, prodId, cardId) => {
        setImageIndex(ndx);
        setImageProdId(prodId);
        setImageCardId(cardId);
        document.querySelector('#frontImageInput' + ndx).click();
    };
    const handleBackImage = e => {
        addBackImgAttachment(e.target.files[0]);
        document.querySelector('#addPhotoBack' + imageIndex).style = 'display: none';
        document.querySelector('#loadingPhotoBack' + imageIndex).style = 'display: block';
    };
    const triggerBackCardFileChooser = ndx => {
        setImageIndex(ndx);
        document.querySelector('#backImageInput' + ndx).click();
    };

    const handleFrontImageDelete = ndx => {
        setImageIndex(ndx);
        const tempProducts = [...productData];
        delete tempProducts[ndx]['sellerImgFront'];
        setProductData(tempProducts);
        document.querySelector('#loadingPhotoFront' + ndx).style = 'display: none';
        document.querySelector('#addPhotoFront' + ndx).style = 'display: block';
        setDirtyForm(true);
    };

    const handleBackImageDelete = ndx => {
        setImageIndex(ndx);
        const tempProducts = [...productData];
        delete tempProducts[ndx]['sellerImgBack'];
        setProductData(tempProducts);
        document.querySelector('#loadingPhotoBack' + ndx).style = 'display: none';
        document.querySelector('#addPhotoBack' + ndx).style = 'display: block';
        setDirtyForm(true);
    };

    const handleFocus = e => {
        e.preventDefault();
        e.target.focus();
        e.target.setSelectionRange(0, e.target.value.length);
    };

    const [modalOpenAdvisory, toggleModalOpenAdvisory] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCloseAdvisory = e => {
        toggleModalOpenAdvisory(false);
    };

    const saveData = () => {
        setIsProcessing(true);
        let hasBadData = false;
        const completedData = productData.filter(productItem => {
            return productItem.price !== '';
        });
        if (completedData.length > 0) {
            completedData.forEach(productItem => {
                if (Number(productItem.price.replace(/[^0-9.-]+/g, '')) < 0.25) {
                    hasBadData = true;
                    const targetIndex = productData.findIndex(product => product.productId === productItem.productId);
                    document.querySelector(`#yourPrice${targetIndex}`).focus();
                    toggleModalOpenAdvisory(true);
                    return;
                }
            });
        }

        if (!hasBadData) {
            productData.forEach(productItem => {
                if (!productItem.sellerImgFront) {
                    productItem['sellerImgFront'] = productItem.card.stockImgFront;
                }
                if (!productItem.sellerImgBack) {
                    productItem['sellerImgBack'] = productItem.card.stockImgBack;
                }
            });

            const stagingData = JSON.parse(JSON.stringify(productData));
            stagingData.forEach((stagingData, index) => {
                if (stagingData.availableQuantity === '') {
                    stagingData.availableQuantity = '0';
                    productData[index].price = '';
                    productData[index].sellerSku = '';
                }
                stagingData.card = {};
            });

            const finalOutboundData = [];
            indexesTouched.forEach(index => {
                finalOutboundData.push(stagingData[index]);
            });

            const bulkUploadSaveRequest = {
                sellerId: sellerState.sellersLockerUser.sellerProfile.sellerId,
                listings: finalOutboundData,
            };

            bulkUploadApi.save(bulkUploadSaveRequest).then(res => {
                setIsProcessing(false);
                setIndexesTouched([]);
                if (res?.data?.result === 'Saved!') {
                    addToast({
                        severity: 'success',
                        message: 'Save successful',
                    });
                    dispatch({
                        type: constants.BULK_UPLOAD_FORM_DIRTY,
                        payload: {status: false},
                    });
                } else {
                    addToast({
                        severity: 'error',
                        message: res?.data?.result,
                    });
                }
            });
        }
    };

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            bulkUploadApi = new BulkUploadApi(apiConfig);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Error preparing results.',
                                contextKey: 'Bulk Upload',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.log(error);
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    // setTimeout(() => {
                    //     authFunc.logout();
                    // }, 5000);
                });
        }
    }, [instance]);

    useEffect(() => {
        setProductData([]);
    }, []);

    return (
        <>
            <div className={classes.selectionsWrapper}>
                <div style={{textAlign: 'right', paddingRight: 16}}>
                    <BSCButton color="secondary" style={{width: 108, marginBottom: 16}} onClick={saveData}>
                        <BSCTypography label="Save" size14></BSCTypography>
                    </BSCButton>
                </div>
                <TableContainer component={Paper} square={false} elevation={0} className={classes.tableContainer}>
                    <div className={classes.stickyWrapper}>
                        <Table stickyHeader size="medium" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" style={{fontWeight: 600}}>
                                        Card Name
                                    </TableCell>
                                    <TableCell component="th" style={{fontWeight: 600}} align="center">
                                        Card #
                                    </TableCell>
                                    <TableCell component="th" style={{fontWeight: 600}}>
                                        Condition
                                    </TableCell>
                                    <TableCell component="th" style={{fontWeight: 600}} align="center">
                                        Your Images
                                    </TableCell>
                                    <TableCell component="th" style={{fontWeight: 600}}>
                                        Comps
                                    </TableCell>
                                    <TableCell component="th" style={{fontWeight: 600, paddingBottom: 0}}>
                                        Your Price
                                        <BSCTypography
                                            label="($0.25 min)"
                                            size14
                                            style={{position: 'relative', top: -12, color: '#aaa', marginTop: 8}}
                                        />
                                    </TableCell>
                                    <TableCell component="th" style={{fontWeight: 600}} align="center">
                                        QTY
                                    </TableCell>
                                    <TableCell component="th" style={{fontWeight: 600}}>
                                        SKU
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {productData.length === 0 && (
                                <TableBody>
                                    <BSCTableRowSkeleton amountOfRows={20} amountOfCells={8} />
                                </TableBody>
                            )}
                            {isProcessing && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <div className={classes.loadingWrap}>
                                                <BSCLoading loading={isProcessing} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                            {productData.length > 0 && !isProcessing && (
                                <TableBody>
                                    {productData.map((listing, index) => (
                                        <TableRow hover key={listing.productId}>
                                            <TableCell style={{width: 400}}>
                                                <Grid container xs={12} direction="row">
                                                    <Grid item xs={3}>
                                                        {listing.card.stockImgFront && (
                                                            <BscImagePopper
                                                                imageUrl={Normalizer.getS3ImagePath(listing.card.stockImgFront)}
                                                            >
                                                                <BSCImage
                                                                    style={{maxHeight: '80px', width: '80px'}}
                                                                    src={Normalizer.getS3ImagePath(listing.card.stockImgFront)}
                                                                />
                                                            </BscImagePopper>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={9} style={{padding: '8px 0 0 12px'}}>
                                                        <Grid container direction="column">
                                                            <Grid item>
                                                                <BSCTypography
                                                                    className={classes.blackRow}
                                                                    label={`${listing.card.players} #${listing.card.cardNo}`}
                                                                    variant="subtitle1"
                                                                    maxCharacters={40}
                                                                    showValueAfter={'-'}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <BSCTypography
                                                                    className={classes.blackRow}
                                                                    label={
                                                                        variantName === null
                                                                            ? `${year} ${setName}  ` + '\u2022' + ` ${variant}`
                                                                            : `${year} ${setName}  ` +
                                                                              '\u2022' +
                                                                              ` ${variant}  ` +
                                                                              '\u2022' +
                                                                              ` ${variantName}`
                                                                    }
                                                                    variant="subtitle1"
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <BSCTypography
                                                                    className={classes.blackRow}
                                                                    label={listing.card.playerAttribute}
                                                                    variant="subtitle1"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center" style={{width: 60}}>
                                                <BSCTypography
                                                    className={classes.blackRow}
                                                    label={listing.card.cardNo}
                                                    variant="subtitle1"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <BSCTypography className={classes.blackRow} label={cardCondition} variant="subtitle1" />
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display: 'flex', textAlign: 'center'}}>
                                                    <div style={{width: '50%'}}>
                                                        <div
                                                            style={
                                                                listing.sellerImgFront
                                                                    ? {margin: '0px 8px', position: 'relative', textAlign: 'right'}
                                                                    : {display: 'none'}
                                                            }
                                                        >
                                                            <div
                                                                className={classes.deleteButtonWrapperContent}
                                                                onClick={() => handleFrontImageDelete(index)}
                                                            >
                                                                X
                                                            </div>
                                                            {listing.sellerImgFront && (
                                                                <img
                                                                    className={classes.cardImg}
                                                                    src={Normalizer.getS3ImagePath(listing?.sellerImgFront)}
                                                                />
                                                            )}
                                                        </div>
                                                        <div style={listing.sellerImgFront ? {display: 'none'} : null}>
                                                            <div id={`addPhotoFront${index}`}>
                                                                <AddPhotoIcon
                                                                    id={`frontImageIcon${index}`}
                                                                    className={classes.largeIcon}
                                                                    onClick={() => triggerFrontCardFileChooser(index)}
                                                                />
                                                                <input
                                                                    id={`frontImageInput${index}`}
                                                                    style={{display: 'none'}}
                                                                    type="file"
                                                                    onChange={handleFrontImage}
                                                                    accept="image/*"
                                                                />
                                                            </div>
                                                            <div id={`loadingPhotoFront${index}`} style={{display: 'none'}}>
                                                                <BSCLoading loading={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{width: '50%'}}>
                                                        <div
                                                            style={
                                                                listing.sellerImgBack
                                                                    ? {margin: '0px 8px', position: 'relative', textAlign: 'right'}
                                                                    : {display: 'none'}
                                                            }
                                                        >
                                                            <div
                                                                className={classes.deleteButtonWrapperContent}
                                                                onClick={() => handleBackImageDelete(index)}
                                                            >
                                                                X
                                                            </div>
                                                            {listing.sellerImgBack && (
                                                                <img
                                                                    className={classes.cardImg}
                                                                    src={Normalizer.getS3ImagePath(listing?.sellerImgBack)}
                                                                />
                                                            )}
                                                        </div>
                                                        <div style={listing.sellerImgBack ? {display: 'none'} : null}>
                                                            <div id={`addPhotoBack${index}`}>
                                                                <AddPhotoIcon
                                                                    id={`backImageIcon${index}`}
                                                                    className={classes.largeIcon}
                                                                    onClick={() => triggerBackCardFileChooser(index)}
                                                                />
                                                                <input
                                                                    id={`backImageInput${index}`}
                                                                    style={{display: 'none'}}
                                                                    type="file"
                                                                    onChange={handleBackImage}
                                                                    accept="image/*"
                                                                />
                                                            </div>
                                                            <div id={`loadingPhotoBack${index}`} style={{display: 'none'}}>
                                                                <BSCLoading loading={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{width: 180}}>
                                                <BSCButton
                                                    disabled={!listing.leastPriceForType}
                                                    fullWidth
                                                    className={classes.lowButton}
                                                    style={{marginBottom: 8}}
                                                    onClick={() => getBSCLow(index)}
                                                >
                                                    <BSCTypography
                                                        label="BSC Low:"
                                                        variant="subtitle1"
                                                        style={
                                                            listing.leastPriceForType
                                                                ? {flex: 1, textAlign: 'left', color: 'rgb(239, 36, 36)'}
                                                                : {flex: 1, textAlign: 'left', color: '#00000042'}
                                                        }
                                                    />
                                                    <BSCTypography
                                                        label={listing.leastPriceForType ? `$${listing.leastPriceForType}` : '-'}
                                                        variant="subtitle1"
                                                        style={
                                                            listing.leastPriceForType
                                                                ? {flex: 1, textAlign: 'right', color: 'rgb(239, 36, 36)'}
                                                                : {flex: 1, textAlign: 'right', color: '#00000042'}
                                                        }
                                                    />
                                                </BSCButton>
                                                <BSCButton
                                                    disabled={!listing.lastSoldPrice}
                                                    fullWidth
                                                    className={classes.lowButton}
                                                    onClick={() => getBSCLastSold(index)}
                                                >
                                                    <BSCTypography
                                                        label="BSC Last Sold:"
                                                        variant="subtitle1"
                                                        style={
                                                            listing.lastSoldPrice
                                                                ? {flex: 2, textAlign: 'left', color: 'rgb(53, 136, 86)'}
                                                                : {flex: 2, textAlign: 'left', color: '#00000042'}
                                                        }
                                                    />
                                                    <BSCTypography
                                                        label={listing.lastSoldPrice ? `$${listing.lastSoldPrice}` : '-'}
                                                        variant="subtitle1"
                                                        style={
                                                            listing.lastSoldPrice
                                                                ? {flex: 1, textAlign: 'right', color: 'rgb(53, 136, 86)'}
                                                                : {flex: 1, textAlign: 'right', color: '#00000042'}
                                                        }
                                                    />
                                                </BSCButton>
                                            </TableCell>
                                            <TableCell>
                                                <BSCTextField
                                                    id={`yourPrice${index}`}
                                                    className={classNames(classes.numbers)}
                                                    value={listing.price}
                                                    onFocus={handleFocus}
                                                    onBlur={e => {
                                                        if (
                                                            e.target.value !== '0' &&
                                                            e.target.value !== '0.0' &&
                                                            e.target.value !== '0.00'
                                                        ) {
                                                            handlePriceBlur(e, index);
                                                        }
                                                    }}
                                                    onChange={e => {
                                                        const re = /^(\d*\.{0,1}\d{0,2}$)/;
                                                        if (e.target.value === '' || re.test(e.target.value)) {
                                                            handlePriceChange(e, index);
                                                        }
                                                    }}
                                                    onKeyDown={e => handleKeyPress(e, index)}
                                                    style={{width: 90}}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <BSCTextField
                                                    id={`qty${index}`}
                                                    className={classes.numbers}
                                                    value={listing.availableQuantity === 0 ? '' : listing.availableQuantity}
                                                    onChange={e => {
                                                        const re = /^\d+$/;
                                                        if (e.target.value === '' || re.test(e.target.value)) {
                                                            handleQuantityChange(e, index);
                                                        }
                                                    }}
                                                    onFocus={handleFocus}
                                                    onKeyDown={e => handleKeyPress(e, index)}
                                                    style={{width: 80}}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <BSCTextField
                                                    id={`sku${index}`}
                                                    className={classes.sku}
                                                    value={listing.sellerSku}
                                                    onFocus={handleFocus}
                                                    onChange={e => handleSKUChange(e, index)}
                                                    onKeyDown={e => handleKeyPress(e, index)}
                                                    style={{width: 100}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </div>
                </TableContainer>
            </div>
            <RestrictedBulkUploadAdvisoryModal
                open={modalOpenAdvisory}
                onClose={(e: boolean) => handleCloseAdvisory(e)}
                advisoryMessage="$0.25 Minimum Price Required"
            />
        </>
    );
};

export default BulkUploadResults;
