import React, {useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import classNames from 'classnames';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../../features/common/skeletons/BSCTableRowSkeleton';
import BSCCurrency from '../../../../../features/common/bscCurrency/bscCurrency';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import useDateTimeNormalizer from '../../../../../client/common/normalizer/dateTimeNormalizer';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 8,
        boxShadow: 'none',
        padding: '0',
        marginBottom: theme.spacing(2),
        marginTop: '10px',

        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
        },
    },
    table: {
        minWidth: 505,
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    th: {
        color: '#41474C',
        textAlign: 'right',
        padding: theme.spacing(1),
        border: '1px solid lightgrey',
        '&:first-child': {
            textAlign: 'left',
        },
        '&:last-child': {},
    },
    td: {
        borderBottom: '2px solid #e5e5e5',
        padding: theme.spacing(1),
        textAlign: 'right',
        '&:first-child': {
            textAlign: 'left',
        },
    },
    checkboxWrapper: {
        background: theme.palette.common.white,
        border: '0.520833px solid #DAE0E6',
        boxShadow: '0px 0px 4px #B8C2CC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '25px',
        height: '25px',
    },
    checkbox: {
        background: '#EF2424',
        boxShadow: 'inset 0px 0px 2.08333px rgba(0, 0, 0, 0.25), inset 0px 2.08333px 2.08333px rgba(255, 255, 255, 0.24)',
        width: '17px',
        height: '17px',
    },
    checked: {
        background: '#e5e5e5',
    },
    summary: {
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    daily: {
        fontSize: 14,
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    summaryBg: {
        '& td': {
            backgroundColor: '#DAE0E5 !important' as '#DAE0E5',
        },
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
    },

    Printable: {
        '@media print': {
            position: 'absolute',
            top: 0,
        },
    },
}));

interface SalesSummaryTableProps {
    sizePerPage: number;
    page: number;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    salesSummary: undefined;
    loading: boolean;
}

const SalesSummaryTable = ({sizePerPage, page, salesSummary, loading}: SalesSummaryTableProps) => {
    const classes = useStyles();
    const dateNormalizer = useDateTimeNormalizer();
    const {isBelowTablet} = useScreenSnap();

    const [totalSalesSummaryRefunds, setTotalSalesSummaryRefunds] = useState(0);
    useEffect(() => {
        if (salesSummary) {
            salesSummary.totalSalesSummary['totalRefunds'] =
                parseFloat(salesSummary.totalSalesSummary.refunds) + parseFloat(salesSummary.totalSalesSummary.refundFees);
            setTotalSalesSummaryRefunds(salesSummary.totalSalesSummary['totalRefunds']);
        }
    }, [salesSummary]);

    return (
        <div className={classes.Printable}>
            <BSCCardBox className={classes.root}>
                <Table stickyHeader aria-label="sticky table" className={classes.table}>
                    <TableHead>
                        {!loading && (
                            <>
                                <TableRow className={classes.stickyHeader}>
                                    <TableCell key="date" className={classes.th}>
                                        <BSCTypography size12={isBelowTablet} size14={!isBelowTablet} label="Date" semibold />
                                    </TableCell>
                                    <TableCell key="numberOfOrders" className={classes.th}>
                                        <BSCTypography size12={isBelowTablet} size14={!isBelowTablet} label="Orders" semibold />
                                    </TableCell>
                                    <TableCell key="grossSales" className={classes.th}>
                                        <BSCTypography size12={isBelowTablet} size14={!isBelowTablet} label="Total Sales" semibold />
                                    </TableCell>
                                    <TableCell key="refunds" className={classes.th}>
                                        <BSCTypography size12={isBelowTablet} size14={!isBelowTablet} label="Refunds" semibold />
                                    </TableCell>
                                    <TableCell key="netSales" className={classes.th}>
                                        <BSCTypography size12={isBelowTablet} size14={!isBelowTablet} label="Net Sales" semibold />
                                    </TableCell>
                                    <TableCell key="bscSellerFees" className={classes.th}>
                                        <BSCTypography size12={isBelowTablet} size14={!isBelowTablet} label="BSC Seller Fees" semibold />
                                    </TableCell>
                                    <TableCell key="paymentFees" className={classes.th}>
                                        <BSCTypography
                                            size12={isBelowTablet}
                                            size14={!isBelowTablet}
                                            label="Payment Processing"
                                            capitalize
                                            semibold
                                        />
                                    </TableCell>
                                    <TableCell key="netSalesWoFees" className={classes.th}>
                                        <BSCTypography
                                            size12={isBelowTablet}
                                            size14={!isBelowTablet}
                                            label="Net Sales After Fees"
                                            capitalize
                                            semibold
                                        />
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableHead>
                    <TableBody>
                        {salesSummary && salesSummary.totalSalesSummary && (
                            <TableRow role="cell" tabIndex={-1} className={classNames(classes.th, classes.summaryBg)}>
                                <TableCell key="date" className={classes.td}>
                                    <BSCTypography className={classes.daily} label="Summary" />
                                </TableCell>
                                <TableCell key="numberOfOrders" className={classes.td}>
                                    <BSCTypography className={classes.daily} label={salesSummary.totalSalesSummary.numberOfOrders ?? 0} />
                                </TableCell>
                                <TableCell key="grossSales" className={classes.td}>
                                    <BSCCurrency className={classes.daily} amount={salesSummary.totalSalesSummary.grossSales ?? 0} />
                                </TableCell>
                                <TableCell key="refunds" className={classes.td}>
                                    <BSCCurrency className={classes.daily} amount={totalSalesSummaryRefunds * -1} />
                                </TableCell>
                                <TableCell key="netSales" className={classes.td}>
                                    <BSCCurrency className={classes.daily} amount={salesSummary.totalSalesSummary.netSales ?? 0} />
                                </TableCell>
                                <TableCell key="bscSellerFees" className={classes.td}>
                                    <BSCCurrency
                                        className={classes.daily}
                                        amount={(salesSummary.totalSalesSummary.bscSellerFees ?? 0) * -1}
                                    />
                                </TableCell>
                                <TableCell key="paymentFees" className={classes.td}>
                                    <BSCCurrency
                                        className={classes.daily}
                                        amount={(salesSummary.totalSalesSummary.paymentFees ?? 0) * -1}
                                    />
                                </TableCell>
                                <TableCell key="netSalesWoFees" className={classes.td}>
                                    <BSCCurrency className={classes.daily} amount={salesSummary.totalSalesSummary.netSalesWoFees ?? 0} />
                                </TableCell>
                            </TableRow>
                        )}
                        {loading && <BSCTableRowSkeleton amountOfRows={4} amountOfCells={9} />}
                        {!loading &&
                            salesSummary &&
                            Normalizer.getPagedResult(salesSummary?.results, page, sizePerPage)?.map((summary, index) => {
                                return (
                                    <TableRow hover role="button" tabIndex={-1} key={index} className={classes.tr}>
                                        <TableCell key="date" className={classes.td}>
                                            <BSCTypography className={classes.daily} label={dateNormalizer.getDisplayDate(summary.date)} />
                                        </TableCell>
                                        <TableCell key="numberOfOrders" className={classes.td} style={{width: '8%'}}>
                                            <BSCTypography className={classes.daily} label={summary.numberOfOrders} />
                                        </TableCell>
                                        <TableCell key="grossSales" className={classes.td} style={{width: '11%'}}>
                                            <BSCCurrency className={classes.daily} amount={summary.grossSales} />
                                        </TableCell>
                                        <TableCell key="refunds" className={classes.td} style={{width: '10%'}}>
                                            <BSCCurrency className={classes.daily} amount={summary.refunds * -1} />
                                        </TableCell>
                                        <TableCell key="netSales" className={classes.td} style={{width: '11%'}}>
                                            <BSCCurrency className={classes.daily} amount={summary.netSales} />
                                        </TableCell>
                                        <TableCell key="bscSellerFees" className={classes.td} style={{width: '13%'}}>
                                            <BSCCurrency className={classes.daily} amount={summary.bscSellerFees * -1} />
                                        </TableCell>
                                        <TableCell key="paymentFees" className={classes.td} style={{width: '15%'}}>
                                            <BSCCurrency className={classes.daily} amount={summary.paymentFees * -1} />
                                        </TableCell>
                                        <TableCell key="netSalesWoFees" className={classes.td} style={{width: '14%'}}>
                                            <BSCCurrency className={classes.daily} amount={summary.netSalesWoFees} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </BSCCardBox>
        </div>
    );
};

export default SalesSummaryTable;
