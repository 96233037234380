import BSCGridTableRow from '../../../../../features/common/bscGridTableRow/bscGridTableRow';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import {Shipment} from '../../../../../client/openapitools/seller';
import useDateTimeNormalizer from '../../../../../client/common/normalizer/dateTimeNormalizer';
import {useState} from 'react';
import BSCEditToggle from '../../../../../features/common/bscEditToggle/bscEditToggle';
import BSCTextField from '../../../../../features/common/bscTextField/bscTextField';
/* eslint-disable */
function ShippingLabelDetails({
    shippingLabelDetails,
    onAddTracking,
    onUpdateTracking,
    onCancelEdit,
    isEdit,
}: {
    shippingLabelDetails: Shipment;
    onAddTracking(): void;
    onUpdateTracking(trackingNumber: string): void;
    onCancelEdit(): void;
    isEdit: boolean;
}) {
    const normalizer = useDateTimeNormalizer();
    const [trackingNumber, setTrackingNumber] = useState(shippingLabelDetails?.trackingNumber ?? null);

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <BSCGridTableRow
                    col4={<BSCTypography label="Date:" />}
                    col8={<BSCTypography label={normalizer.getDisplayDate(shippingLabelDetails?.shippedDate)} />}
                    flip
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <BSCGridTableRow
                    col4={<BSCTypography label="Tracking #: " />}
                    col8={
                        <BSCEditToggle
                            isEdit={isEdit}
                            trueChild={
                                <BSCTextField
                                    value={trackingNumber}
                                    inputKey="trackingNumber"
                                    disabled={!shippingLabelDetails?.trackingNumber}
                                    onChange={e => setTrackingNumber(e.target.value)}
                                ></BSCTextField>
                            }
                            falseChild={<BSCTypography label={shippingLabelDetails?.trackingNumber} />}
                        />
                    }
                    flip
                />
            </Grid>
            {!shippingLabelDetails?.trackingNumber && !isEdit && (
                <Grid item xs={12} md={12} justifyContent="center">
                    <BSCDivider />
                    <div style={{textAlign: 'center'}}>
                        <BSCButton color="primary" onClick={onAddTracking}>
                            <BSCTypography label="Add Tracking" />
                        </BSCButton>
                    </div>
                </Grid>
            )}
            {isEdit && (
                <Grid item xs={12} md={12} justifyContent="center">
                    <BSCDivider />
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <BSCButton color="secondary" onClick={onCancelEdit}>
                                <BSCTypography label="Cancel" />
                            </BSCButton>
                        </Grid>
                        <Grid item>
                            <BSCButton color="primary" onClick={() => onUpdateTracking(trackingNumber)}>
                                <BSCTypography label="Update Tracking" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default ShippingLabelDetails;
