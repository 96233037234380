import {Client} from '../packages/core';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/`;
const s3Base = process.env.REACT_APP_S3_BASE_URL + process.env.REACT_APP_ENV + '/static/';

export const s3Client = Client(s3Base);
const client = Client(baseUrl);

client.instance.interceptors.request.use(config => {
    //is the AssumedRole header important to the API?  With the changes to use the path to handle swapping between sellers locker and marketplace I had to pull that off the axios client initialization.  if its important, let me know the logic for it and I can see what I can do to make it work again.
    //Sudeep  8:57 PM
    //yes it would be needed as currently many API endpoints are in common and they need to know where the user is interacting from... like messages are common for both buyer and seller ... We have sellers who are also buying and when they are in marketplace they interact with sellers from whom they bought products... so API needs to know if user is currently acting as seller or buyer

    //JO: Token no longer in local storage.  Migrate to useApi and redux stored token and new generated client
    //const token = localStorage.getItem('token');
    const AssumedRole = JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.portal)?.context ?? '';
    //config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers.AssumedRole = AssumedRole;
    return config;
});

export default client;
