import React from 'react';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BulkUpload from './bulkUpload';

const SellerBulkUpload = () => {
    return (
        <BSCContainer variant="page">
            <BulkUpload />
        </BSCContainer>
    );
};

export default SellerBulkUpload;
