import React, {useState} from 'react';
import MarketplaceHeader from '../../features/navigation/header/MarketplaceHeader';
import MarketPlaceHeaderProvider from './marketPlaceHeaderContext';
import MarketplaceRoutes from './routes';

function Marketplace() {
    const [headerBarChild, setHeaderBarChild] = useState();
    const [slidyHeader, setSlidyHeader] = useState(false);
    return (
        <MarketPlaceHeaderProvider value={{headerBarChild, setHeaderBarChild, slidyHeader, setSlidyHeader}}>
            <MarketplaceHeader />
            <MarketplaceRoutes />
        </MarketPlaceHeaderProvider>
    );
}

export default Marketplace;
