import {memo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import {Menus} from '../../../../@types/menus';
import SubNavContent from '../sellersSubNavContent';
interface SubNavProps {
    menus: Array<Menus> | null;
    open: boolean;
    anchorEl: HTMLElement | null;
    popoverEnter: (id: number) => void;
    popoverLeave: () => void;
    id: number;
}

const useStyles = makeStyles({
    popoverContent: {
        pointerEvents: 'auto',
        borderRadius: 0,
    },
});

function SLSubNav({menus, open, anchorEl, popoverEnter, popoverLeave, id}: SubNavProps) {
    const classes = useStyles();

    if (!open) {
        return null;
    }

    return (
        <Popover
            style={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transitionDuration={{appear: 2, enter: 1, exit: 0.2}}
            PaperProps={{onMouseEnter: () => popoverEnter(id), onMouseLeave: popoverLeave}}
            classes={{
                paper: classes.popoverContent,
            }}
        >
            <SubNavContent menus={menus} />
        </Popover>
    );
}

export default memo(SLSubNav);
