import numeral from 'numeral';
import BSCTypography from '../bscTypography/bscTypography';
import {BSCTypographyProps} from '../../../@types/features/common/bscTypography';

numeral.defaultFormat('$0,0.00');

export interface BSCCurrencyProps extends Omit<BSCTypographyProps, 'label'> {
    amount: number | string;
    format?: boolean;
}

function BSCCurrency({amount, format = true, ...otherProps}: BSCCurrencyProps) {
    const numeralNumber = numeral(amount);
    const formattedNumber = format ? numeralNumber.format() : numeralNumber.value();
    return <BSCTypography {...otherProps} label={formattedNumber} />;
}

export default BSCCurrency;
