import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import BSCModal from '../../../../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import {ReactComponent as EditIcon} from '../../../../../../../svgs/edit.svg';
import {ReactComponent as EmailIcon} from '../../../../../../../svgs/email.svg';
import {ReactComponent as PhoneIcon} from '../../../../../../../svgs/phone.svg';
import UserInfoFormEdit from './userInfoFormEdit';

function UserInfo({user}) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formModalOpen, setFormModalOpen] = useState(false);

    const setDataFromModal = modalData => {
        setFirstName(modalData.firstName);
        setLastName(modalData.lastName);
        setPhoneNumber(modalData.phoneNumber);
        setFormModalOpen(false);
    };

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setPhoneNumber(user.phoneNumber);
        }
    }, [user]);

    return (
        <>
            {!user && <BSCLoading loading={!user} />}
            {/* {user && firstName && lastName && phoneNumber && ( */}
            {user && firstName && lastName && (
                <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <BSCTypography label={firstName + ' ' + lastName} variant="body2" size16 semibold />
                        <EditIcon
                            onClick={() => setFormModalOpen(true)}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </Grid>
                    <BSCDivider />
                    <Grid container direction="column" spacing={4}>
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <PhoneIcon />
                                </Grid>
                                <Grid item>
                                    <BSCTypography label={phoneNumber} size14 grey />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <EmailIcon />
                                </Grid>
                                <Grid item>
                                    <BSCTypography label={user?.email} size14 grey />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {formModalOpen && (
                        <BSCModal open={formModalOpen} onClose={() => setFormModalOpen(false)}>
                            <UserInfoFormEdit
                                onComplete={setDataFromModal}
                                defaultValue={user}
                                editable={{firstName, lastName, phoneNumber}}
                            />
                        </BSCModal>
                    )}
                </>
            )}
        </>
    );
}
export default UserInfo;
