import {stateInputShape, validatedSchema, validationSchema, validationSchemaItems} from '../../../../@types/validations/validations';

const getErrors = (stateValue: number | string, {pattern}: validationSchemaItems) => {
    if (!stateValue) {
        return null;
    }
    const regExp = new RegExp(pattern);
    const matches = pattern && String(stateValue).match(regExp);

    if (!matches && pattern) {
        return 'Incorrect value given';
    }

    return null;
};

const getDepErrors = (currentValidationObjKey, state: stateInputShape, validationObj: validationSchema | null) => {
    for (const validationItemKey in validationObj) {
        if (validationItemKey === currentValidationObjKey) continue; //skip same item
        const validationItem = validationObj[validationItemKey];
        const stateItem = state?.[validationItemKey];
        const currentValidationItem = validationObj[currentValidationObjKey];
        // console.log(currentValidationItem, 'validationStuff');
        const currentStateItem = state?.[currentValidationObjKey];

        if (validationItem?.deps?.length) {
            if (
                (validationItem.deps[0].indexOf(currentValidationObjKey) !== -1 || //look for dep
                    (validationItem.deps[0].indexOf('all') !== -1 && !currentValidationItem.notDependable)) && //check if we have all
                !currentStateItem?.value && //if we don't have a state value
                stateItem?.value //does the dep have a state value
            )
                return `${currentValidationItem.label} Field required with ${validationItem.label}`;
        }
    }

    return null;
};

const validator = (state: stateInputShape, validationSchema: validationSchema | null): [validatedSchema, boolean, boolean] => {
    const validationObjKeys = Object.keys(validationSchema ?? {});
    const validationObj = {};
    let validationErrors = false;
    let formDirty = false;

    validationObjKeys.forEach(validationObjKey => {
        const validation = validationSchema[validationObjKey];
        const errors =
            getErrors(state?.[validationObjKey]?.value, validation) || getDepErrors(validationObjKey, state, validationSchema) || null;

        if (errors) {
            validationErrors = true;
        }

        // temporary hack until conversation rules pattern is enhanced
        if (validationSchema?.reply?.pattern === '/^[A-Za-z0-9_@./#&+-]*$/') {
            validationErrors = false;
        }

        if (state?.[validationObjKey]?.dirty) {
            formDirty = true;
        }

        validationObj[validationObjKey] = {
            label: validationSchema[validationObjKey].label ?? null,
            value: state?.[validationObjKey]?.value ?? validationSchema[validationObjKey].defaultValue,
            dirty: state?.[validationObjKey]?.dirty ?? false,
            errors,
        };
    });

    return [validationObj, validationErrors, formDirty];
};

export default validator;
