import React from 'react';
import BSCContainer from '../../../features/common/bscContainer/bscContainer';
import {makeStyles} from '@material-ui/core/styles';
import Normalizer from '../../../client/common/normalizer/normalizer';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: 12,
        justifyContent: 'center',
        alignContent: 'center',
        minHeight: '100%',
    },
    wrapper: {
        width: '100%',
        height: '100%',

        overflow: 'auto',
        position: 'relative',
        overflowScrolling: 'touch',
    },
}));

const BSCPromo = () => {
    const classes = useStyles();
    const {isMobile, isTablet} = useScreenSnap();

    const base = Normalizer.getS3ImagePath('/static/SummerSellerbration-PDF.pdf');
    const src = isMobile || isTablet ? `https://docs.google.com/gview?url=${base}&embedded=true` : base;
    return (
        <div>
            <BSCContainer variant="page" className={classes.root}>
                <iframe src={src} width={'100%'} height={'900px'}></iframe>
            </BSCContainer>
        </div>
    );
};

export default BSCPromo;
