import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Link} from 'react-router-dom';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCTextField from '../../../../features/common/bscTextField/bscTextField';
import SellersFormValidationsClient from '../../../../client/s3/sellersFormValidations/sellersFormValidationsClient';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import useForm from '../../../../packages/core/src/hooks/useForm';
import SellersFormValidationsNormalizer from '../../../../client/s3/sellersFormValidations/sellersFormValidationsNormalizer';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import {useCallback} from 'react';
import BSCRadioGroup from '../../../../features/common/bscRadioGroup/bscRadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BSCRadio from '../../../../features/common/bscRadio/bscRadio';
import axios from 'axios';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {UserApi as SellerUserApi} from '../../../../client/openapitools/seller';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#0002',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
    },
    container: {
        width: 490,
        height: 'fit-content',
        position: 'relative',
        padding: theme.spacing(2, 0),
    },
    closeIcon: {
        position: 'absolute',
        right: -6,
        top: -6,
        padding: theme.spacing(1),
        '&:hover': {
            color: theme.palette.common.black,
        },
    },
    label: {
        '&:after': {
            content: '*',
            color: theme.palette.primary,
        },
    },
    main: {
        padding: theme.spacing(2),
        '& input': {
            padding: theme.spacing(1.25, 1.75),
        },
    },
    radioGroup: {
        '&>label>span:first-child': {
            padding: theme.spacing(0.5),
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: theme.spacing(1),
    },
}));

const SellerPaymentSetting = () => {
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {instance} = useMsal();
    const classes = useStyles();
    const {apiAuthConfig} = useAuthState();
    const {loading: sellerFormValidationsLoading, data: sellerFormValidations} = useQuery(
        () => SellersFormValidationsClient.getSellersFormValidations(),
        []
    );

    const [getIavTokenLoading, setGetIavTokenLoading] = useState(false);
    const {
        formState,
        validatedSchema: formStateValidations,
        registerFormChange,
        formComplete,
    } = useForm({
        validationSchema: SellersFormValidationsNormalizer.getSignupValidation(sellerFormValidations),
    });

    const onSubmit = useCallback(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        setGetIavTokenLoading(true);
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const sellerUserApi = new SellerUserApi(apiConfig);
                            sellerUserApi.getIavToken();
                            axios.post('');
                            setGetIavTokenLoading(false);
                        } catch (error) {
                            console.error('Error fetching user profile', error);
                            addToast({
                                severity: 'error',
                                message: 'Error fetching user profile',
                                contextKey: 'User Profile',
                            });
                            setGetIavTokenLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [formState]);

    return (
        <BSCContainer className={classes.root}>
            <BSCCardBox className={classes.container}>
                <IconButton className={classes.closeIcon} color="default" aria-label="close" component={Link} to="/seller">
                    <CloseIcon fontSize="large" />
                </IconButton>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <BSCTypography label="Become a BSC Seller" variant="h3" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <BSCDivider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.main} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BSCTypography variant="body2" label="Seller Name" semibold className={classes.label} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BSCTextField
                                            inputKey="sellerName"
                                            placeholder="Choose a Seller Name"
                                            formStateValidations={formStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BSCTypography variant="body2" label="Contact" semibold className={classes.label} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BSCTextField
                                            inputKey="name"
                                            placeholder="Enter First & Last Name"
                                            formStateValidations={formStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BSCTypography variant="body2" label="Address" semibold className={classes.label} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BSCTextField
                                            inputKey="address"
                                            placeholder="Enter Address"
                                            formStateValidations={formStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BSCTypography variant="body2" label="Email address" semibold className={classes.label} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BSCTextField
                                            inputKey="email"
                                            placeholder="Enter Email"
                                            formStateValidations={formStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BSCTypography variant="body2" label="Phone Number" semibold className={classes.label} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BSCTextField
                                            inputKey="phoneNumber"
                                            placeholder="Enter Phone #"
                                            formStateValidations={formStateValidations}
                                            onChange={registerFormChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BSCTypography
                                            variant="body2"
                                            label="Are you a Local Card Shop (Brick & Mortar)?"
                                            semibold
                                            className={classes.label}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BSCRadioGroup
                                            inputKey="isLocalShop"
                                            onChange={registerFormChange}
                                            formStateValidations={formStateValidations}
                                            className={classes.radioGroup}
                                        >
                                            <FormControlLabel value="yes" control={<BSCRadio />} label="Yes" />
                                            <FormControlLabel value="no" control={<BSCRadio />} label="No" />
                                        </BSCRadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <BSCButton
                                    disabled={!formComplete || sellerFormValidationsLoading}
                                    color="primary"
                                    onClick={onSubmit}
                                    fullWidth
                                    loading={getIavTokenLoading}
                                >
                                    Create Funding Source
                                </BSCButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </BSCCardBox>
        </BSCContainer>
    );
};

export default SellerPaymentSetting;
