import {
    CardApi,
    CartApi,
    ContactApi,
    OfferApi,
    OrderApi,
    PaymentApi,
    ProductApi,
    SellerApi as MarketPlaceSellerApi,
    UserApi,
} from '../../../../client/openapitools/marketplace/api';
import {Configuration} from '../../../../client/openapitools/marketplace/configuration';
import {
    ConversationApi as CommonConversationApi,
    OfferApi as CommonOfferApi,
    ProductApi as CommonProductApi,
    ReferenceApi as CommonReferenceApi,
    UserApi as CommonUserApi,
} from '../../../../client/openapitools/common/api';
import {MarketplaceApi as MarketplaceSearchApi, SellerApi as SellersLockerSearchApi} from '../../../../client/openapitools/search/api';
import {StaticApi} from '../../../../client/openapitools/static/api';
import {
    AccountApi,
    OfferApi as SellerOfferApi,
    OrderApi as SellerOrderApi,
    PaymentApi as SellerPaymentApi,
    ProductApi as SellerProductApi,
    ReportsApi,
    ShippingApi,
    BulkUploadApi,
    UserApi as SellerUserApi,
    SellerSetting as SellerSettingApi,
} from '../../../../client/openapitools/seller/api';
import ConditionsApi from '../../../../client/common/card/conditionsClient';

export interface Api {
    marketplaceApi: {
        cardApi: CardApi;
        cartApi: CartApi;
        contactApi: ContactApi;
        offerApi: OfferApi;
        orderApi: OrderApi;
        paymentApi: PaymentApi;
        productApi: ProductApi;
        sellerApi: MarketPlaceSellerApi;
        userApi: UserApi;
    };
    commonApi: {
        conversationApi: CommonConversationApi;
        offerApi: CommonOfferApi;
        productApi: CommonProductApi;
        referenceApi: CommonReferenceApi;
        userApi: CommonUserApi;
        conditions: ConditionsApi;
    };
    searchApi: {
        marketplace: MarketplaceSearchApi;
        sellersLocker: SellersLockerSearchApi;
    };
    staticApi: StaticApi;
    sellerApi: {
        accountApi: AccountApi;
        offerApi: SellerOfferApi;
        orderApi: SellerOrderApi;
        paymentApi: SellerPaymentApi;
        productApi: SellerProductApi;
        reportsApi: ReportsApi;
        shippingApi: ShippingApi;
        userApi: SellerUserApi;
        bulkloadApi: BulkUploadApi;
        sellerSettingApi: SellerSettingApi;
    };
}

function useApi(apiAuthConfig?: Configuration): Api {
    const staticApiConfig = new Configuration({
        basePath: process.env.REACT_APP_S3_BASE_URL,
    });

    return {
        marketplaceApi: {
            cardApi: new CardApi(apiAuthConfig),
            cartApi: new CartApi(apiAuthConfig),
            contactApi: new ContactApi(apiAuthConfig),
            offerApi: new OfferApi(apiAuthConfig),
            orderApi: new OrderApi(apiAuthConfig),
            paymentApi: new PaymentApi(apiAuthConfig),
            productApi: new ProductApi(apiAuthConfig),
            sellerApi: new MarketPlaceSellerApi(apiAuthConfig),
            userApi: new UserApi(apiAuthConfig),
        },
        commonApi: {
            conversationApi: new CommonConversationApi(apiAuthConfig),
            offerApi: new CommonOfferApi(apiAuthConfig),
            productApi: new CommonProductApi(apiAuthConfig),
            referenceApi: new CommonReferenceApi(apiAuthConfig),
            userApi: new CommonUserApi(apiAuthConfig),
            conditions: new ConditionsApi(),
        },
        searchApi: {marketplace: new MarketplaceSearchApi(apiAuthConfig), sellersLocker: new SellersLockerSearchApi(apiAuthConfig)},
        staticApi: new StaticApi(staticApiConfig),
        sellerApi: {
            accountApi: new AccountApi(apiAuthConfig),
            offerApi: new SellerOfferApi(apiAuthConfig),
            orderApi: new SellerOrderApi(apiAuthConfig),
            paymentApi: new SellerPaymentApi(apiAuthConfig),
            productApi: new SellerProductApi(apiAuthConfig),
            reportsApi: new ReportsApi(apiAuthConfig),
            shippingApi: new ShippingApi(apiAuthConfig),
            userApi: new SellerUserApi(apiAuthConfig),
            bulkloadApi: new BulkUploadApi(apiAuthConfig),
        },
    };
}

export default useApi;
