import React from 'react';
import Grid from '@material-ui/core/Grid';
import SellerImage from './sellerImage';

const SellerImages = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
                <SellerImage path="/img/sell-cards-img.svg" title="Sell Cards" url="/sellers/inventory/?myInventory=false" />
            </Grid>
            <Grid item xs={6} sm={3}>
                <SellerImage path="/img/review-prices-img.svg" title="Bulk Upload" url="/sellers/bulk-upload" />
            </Grid>
            <Grid item xs={6} sm={3}>
                <SellerImage path="/img/set-shipping-img.svg" title="Set Shipping" url="/sellers/dashboard/profile" />
            </Grid>
            <Grid item xs={6} sm={3}>
                <SellerImage path="/img/faqs-img.svg" title="FAQs" url="/sellers/dashboard/faq" />
            </Grid>
        </Grid>
    );
};

export default SellerImages;
