import {forwardRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import {BSCTypographyProps} from '../../../@types/features/common/bscTypography';
import {maxCharacter} from '../../../packages/core/src/util/util';

const useStyles = makeStyles({
    center: {
        textAlign: 'center',
    },
    italic: {
        fontStyle: 'italic',
    },
    normal: {
        fontStyle: 'normal',
    },
    underline: {
        textDecoration: 'underline',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    bold: {
        fontWeight: 700,
    },
    semibold: {
        fontWeight: 600,
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    size10: {
        fontSize: '10px',
    },
    size12: {
        fontSize: '12px',
    },
    size14: {
        fontSize: '14px',
    },
    size16: {
        fontSize: '16px',
    },
    size18: {
        fontSize: '18px',
    },
    size20: {
        fontSize: '20px',
    },
    size24: {
        fontSize: '24px',
    },
    grey: {
        color: '#41474D',
    },
});

const BSCTypography = forwardRef<HTMLDivElement, BSCTypographyProps>(
    (
        {
            label,
            color,
            children = null,
            uppercase = false,
            italic = false,
            bold = false,
            semibold = false,
            capitalize = false,
            className = null,
            maxCharacters = 0,
            underline = false,
            loading = null,
            normal = false,
            size14 = false,
            size16 = false,
            size18 = false,
            size20 = false,
            size12 = false,
            size10 = false,
            size24 = false,
            grey = false,
            center = false,
            showValueAfter = '',
            ...otherProps
        }: BSCTypographyProps,
        ref
    ) => {
        const classes = useStyles();

        return (
            <Typography
                {...otherProps}
                ref={ref}
                color={color}
                className={classNames(
                    {
                        [classes.uppercase]: uppercase,
                        [classes.italic]: italic,
                        [classes.normal]: normal,
                        [classes.bold]: bold,
                        [classes.semibold]: semibold,
                        [classes.capitalize]: capitalize,
                        [classes.size14]: size14,
                        [classes.size16]: size16,
                        [classes.size20]: size20,
                        [classes.size12]: size12,
                        [classes.size10]: size10,
                        [classes.size18]: size18,
                        [classes.size24]: size24,
                        [classes.grey]: grey,
                        [classes.center]: center,
                        [classes.underline]: underline,
                    },
                    className
                )}
            >
                {label !== undefined && label !== null ? (
                    maxCharacters ? (
                        <span title={label}>{maxCharacter(label, maxCharacters, showValueAfter)}</span>
                    ) : (
                        label
                    )
                ) : loading !== null ? (
                    loading ? (
                        <Skeleton />
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )}

                {children ?? null}
            </Typography>
        );
    }
);

export default BSCTypography;
