/* eslint-disable prettier/prettier */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {FormControlLabel} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';
import React, {Fragment, memo, useCallback, useEffect, useReducer, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import {ShopAggregations} from '../../../../../client/openapitools/search';
import {AppliedFilter, SearchParsed} from '../../../../../enums/filterEnum';
import BSCErrorBanner from '../../../../../features/common/bscAlert/bscAlert';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BSCRadio from '../../../../../features/common/bscRadio/bscRadio';
import BSCRadioGroup from '../../../../../features/common/bscRadioGroup/bscRadioGroup';
import BSCScroller from '../../../../../features/common/bscScroller/bscScroller';
import BSCSelect from '../../../../../features/common/bscSelect/bscSelect';
import BSCTab from '../../../../../features/common/bscTab/bscTab';
import BSCTabs from '../../../../../features/common/bscTabs/bscTabs';
import BSCTextField from '../../../../../features/common/bscTextField/bscTextField';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import useSearch from '../../../../../packages/core/src/hooks/useSearch';
import {currencyFormatter} from '../../../../../packages/core/src/util/util';
import {RootState} from '../../../../../redux/reducers';
import * as constants from '../../../../../shared/constants';
import MissingDataModal from './MissingDataModal';
import FindASellerModal from './findASellerModal';
import SearchList from './searchList';
import SelectConditionsModal from './selectConditionsModal';

interface SearchResultsProps {
    isMobile: Boolean;
    loading: Boolean;
    aggregatedValues: ShopAggregations;
    aggregatedKeys: Array<string>;
    parsedQueryResults: SearchParsed;
    chipList?: Array<AppliedFilter>;
    onRadioChange: (searchTermKey, selectedSlug) => void;
    onCheckboxChange: (searchTermKey, selectedSlug) => void;
    onParamDelete: (searchTermKey: string, selectedSlug: string) => void;
    onClearAll: () => void;
    onStockToggleChange: (value) => void;
    onConditionsChange: (value) => void;
    onPriceRangeChange: (value) => void;
    onSortChange: (value) => void;
    errors: Error | null;
    selectedSortValue: string;
    showFindASeller: Boolean;
    inStockToggle: string;
    conditionToggle: string;
    showInStock: boolean;
    showCardType: boolean;
    showSelectSeller: boolean;
    showConditions: boolean;
    showPriceRange: boolean;
    showSortOrder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    sidebarContainer: {
        padding: 0,
        boxShadow: 'none!important' as 'none',
    },
    sidebarWrap: {
        padding: theme.spacing(2),
    },
    sidebarHeaderWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    sidebarFilterHeader: {
        // textTransform: "uppercase"
    },
    filterButtonsWrap: {display: 'flex', flexWrap: 'wrap'},
    filterButton: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        background: '#E9EDF2',
        boxShadow: 'inset 0px -2px 4px #DAE0E6',
        borderRadius: 24,
    },
    loadingWrap: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    error: {
        marginBottom: theme.spacing(2),
    },
    buttonWrap: {
        padding: theme.spacing(2),
    },
    button: {
        margin: 0,
        fontSize: theme.typography.subtitle1.fontSize,
    },
    noFiltersLabel: {
        background: '#DAE0E5',
        padding: theme.spacing(0.75, 1.75),
        borderRadius: 6,
        width: 'fit-content',
    },
    clearAll: {
        fontSize: '0.65rem',
    },
    infoIcon: {
        fontSize: '12px',
        verticalAlign: 'super',
    },
    missingDataLink: {
        fontSize: 12,
        color: '#000',
        textAlign: 'right',
        paddingRight: 8,
        ['&:hover']: {
            cursor: 'pointer',
            color: '#f00',
        },
    },
    collapseWrapper: {
        padding: theme.spacing(0, 2),
    },
    keyBoardArrow: {
        transition: '.5s ease',
    },
    keyboardArrowFlipped: {
        transform: 'rotate(-180deg)',
    },
    searchSize: {
        fontSize: '1.25rem',
    },
    box: {
        padding: theme.spacing(0.5, 0),
        '& .MuiMenuItem-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
    },
    boxMobile: {
        padding: theme.spacing(0.5, 0),
        '& .MuiMenuItem-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
            minHeight: '36px !important',
        },
    },
    catHeader: {
        fontSize: theme.typography.subtitle1.fontSize,
    },
    catMenuItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    checkBox: {
        padding: theme.spacing(0.25),
    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        background: '#DAE0E6',
        borderRadius: 4,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.25),
        paddingRight: theme.spacing(1),
        boxShadow: 'inset 0px -2px 4px #DAE0E6',
        maxWidth: '252px',
    },
    rangePrice: {
        flex: 2,
        '& input': {
            textAlign: 'center',
            fontSize: '12px !important',
            padding: '10px 4px',
        },
        '& label': {
            transform: 'translate(8px, 8px) scale(1)',
            fontSize: theme.typography.h6.fontSize,
        },
    },
    minMaxTextFieldArea: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '260px',
        marginTop: 8,
    },
    clearPrice: {
        width: 268,
        textAlign: 'right',
        marginTop: 8,
        cursor: 'pointer',
    },
    select: {
        width: '100%',
        height: '38px',
        '& > div': {
            height: '100%',
            '& > div': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& > svg': {
                    top: 8,
                },
            },
        },
        fontSize: '14px!important' as '14px',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    },
    sellersSelect: {
        width: '100%',
        height: '38px',
        '& > div': {
            height: '100%',
            background: '#DAE0E6 !important',
            '& > div': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& > svg': {
                    top: 8,
                },
            },
        },
        fontSize: '14px!important' as '14px',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    },
    bestSellerText: {
        fontSize: '14px!important' as '14px',
    },
    smallRadio: {
        '& .MuiFormControlLabel-label': {
            fontSize: 12,
        },
        padding: '0 8px',
    },
    paginationBtn: {
        height: 32,
        minWidth: 32,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0),
            height: 37,
            minWidth: 37,
        },
    },
    arrowBtn: {
        '&:hover': {
            backgroundColor: theme.palette.common.black,
            opacity: 0.6,
        },
        color: '#fff',
        backgroundColor: '#EF2424',
        width: 35,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    priceRangeCTAWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'end',
        paddingTop: 8,
    },
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

function SearchSidebar({
    loading,
    aggregatedValues,
    aggregatedKeys,
    parsedQueryResults,
    chipList,
    onRadioChange,
    onCheckboxChange,
    onClearAll,
    onParamDelete,
    onStockToggleChange,
    onConditionsChange,
    onPriceRangeChange,
    onSortChange,
    errors,
    selectedSortValue,
    showFindASeller,
    showSortOrder,
    inStockToggle,
    conditionToggle,
    showInStock,
    showCardType,
    showSelectSeller,
    showConditions,
    showPriceRange,
}: SearchResultsProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const {isMobile, isDesktop} = useScreenSnap();
    const {cleanedSearchQuery} = useSearch();
    const isSellers = useRouteMatch('/sellers');
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [selectedSellerName, setSelectedSellerName] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const handleRadioChange = (searchTermKey: string, selectedSlug: string) => {
        setIsSearching(true);
        forceUpdate();
        onRadioChange(searchTermKey, selectedSlug);
    };

    const handleCheckboxChange = (searchTermKey: string, selectedSlug: string) => {
        setIsSearching(true);
        forceUpdate();
        onCheckboxChange(searchTermKey, selectedSlug);
    };

    const [toolTipOpen, setToolTipOpen] = useState(false);
    const handleTooltipClose = () => {
        setToolTipOpen(false);
    };

    const handleTooltipOpen = () => {
        setToolTipOpen(true);
    };
    const handleTooltipClick = () => {
        setToolTipOpen(!toolTipOpen);
    };

    const handleClearAll = () => {
        setIsSearching(false);
        forceUpdate();
        onClearAll();
    };

    // const selectedFilters = SearchShopResultsNormalizer.getSelectedFilters(parsedQueryResults, aggregatedValues);
    const [pwaOS, setPwaOS] = useState(null);
    const [findASellerModalOpen, toggleFindASellerModalOpen] = useState(false);
    const [selectConditionsModalOpen, toggleSelectConditionsModalOpen] = useState(false);
    const handleFindASellerClose = selectedSeller => {
        toggleFindASellerModalOpen(false);
        if (selectedSeller && selectedSeller.id && selectedSeller.name) {
            onStockToggleChange(true);
            setStockToggleTab(0);
            dispatch({
                type: constants.SELLER_STOREFRONT_SELECTED,
                payload: {sellerId: selectedSeller.id, sellerName: selectedSeller.name},
            });
            if (pwaOS !== null) {
                console.log('>>> about to SET');
                dispatch({
                    type: constants.PWA_SELLER_STOREFRONT_FEEDBACK_RATING,
                    payload: {pwaSellerStorefrontFeedbackRating: selectedSeller.feedbackRating},
                });
                dispatch({
                    type: constants.PWA_SELLER_STOREFRONT_CARDS_SOLD,
                    payload: {pwaSellerStorefrontCardsSold: selectedSeller.cardsSold},
                });
            }
            setTimeout(() => {
                window.location.reload();
            }, 50);
        }
    };

    const handleSelectConditionsClose = selectedConditions => {
        toggleSelectConditionsModalOpen(false);
        if (selectedConditions.raw) {
            if (selectedConditions.raw.length > 0 || selectedConditions.graded.company.length > 0) {
                setConditionsButtonTitle(selectedConditions.raw.length + selectedConditions.graded.company.length + ' Selection(s)');
            } else {
                setConditionsButtonTitle('All Conditions');
            }
            setTimeout(() => {
                onConditionsChange(selectedConditions);
            }, 50);
        }
    };

    const [missingDataModalOpen, toggleMissingDataModalOpen] = useState(false);
    const handleMissingDataModalClose = () => {
        toggleMissingDataModalOpen(false);
    };

    const [stockToggleTab, setStockToggleTab] = useState(inStockToggle === 'true' ? 0 : 1);
    const handleTabChange = useCallback((newTabKey: number) => {
        if (newTabKey === 0) {
            onStockToggleChange(true);
            setStockToggleTab(0);
            dispatch({
                type: constants.SESSION_IN_STOCK,
                payload: {sessionInStock: true},
            });
        } else {
            onStockToggleChange(false);
            setStockToggleTab(1);
            dispatch({
                type: constants.SESSION_IN_STOCK,
                payload: {sessionInStock: false},
            });
        }
    }, []);

    const [sellersButtonTitle, setSellersButtonTitle] = useState('All Sellers');
    const [conditionsButtonTitle, setConditionsButtonTitle] = useState('All Conditions');

    const [toggleKey, setToggleKey] = useState(null);
    const handleToggleClick = key => {
        if (key === toggleKey) {
            setToggleKey(null);
        } else {
            setToggleKey(key);
        }
    };

    useEffect(() => {
        if (loading) {
            setIsSearching(false);
        }
    }, [loading]);

    useEffect(() => {
        if (inStockToggle && inStockToggle === 'true') {
            setStockToggleTab(0);
        } else {
            setStockToggleTab(1);
        }
    }, [inStockToggle]);

    useEffect(() => {
        if (conditionToggle) {
            if (conditionToggle === 'all') {
                setConditionToggleTab(0);
            } else if (conditionToggle === 'raw') {
                setConditionToggleTab(1);
            } else {
                setConditionToggleTab(2);
            }
        }
    }, [inStockToggle]);

    useEffect(() => {
        if (marketplaceState) {
            const tempSellerName = marketplaceState.sellerStorefront !== {} ? marketplaceState.sellerStorefront['sellerName'] : null;
            setSelectedSellerName(tempSellerName);
            if (marketplaceState.isPriceRangeReset === true) {
                handleClearPrice();
                dispatch({
                    type: constants.PRICE_RANGE_RESET,
                    payload: {isPriceRangeReset: false},
                });
            }
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    const [sortOrder, setSortOrder] = useState('');
    const updateSortState = value => {
        // dispatch({
        //     type: constants.UPDATE_SEARCH_SORT_PARAM,
        //     payload: {searchSortParam: value},
        // });
        onSortChange(value);
    };

    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');

    const handleMinPriceBlur = event => {
        let formattedPrice = event.target.value;
        formattedPrice = currencyFormatter(event.target.value);
        const numericPrice = Number(formattedPrice.replace(/[^0-9.-]+/g, ''));
        if (numericPrice === 0.0) {
            event.target.value = null;
        } else {
            event.target.value = formattedPrice;
        }
        setMinPrice(event.target.value);
        setPriceLabel(
            maxPrice === '' || maxPrice === undefined
                ? 'Over $' + currencyFormatter(minPrice)
                : event.target.value === '' || event.target.value === undefined
                  ? 'Under $' + currencyFormatter(maxPrice)
                  : '$' + currencyFormatter(event.target.value) + ' to $' + currencyFormatter(maxPrice)
        );
    };

    const handleMaxPriceBlur = event => {
        let formattedPrice = event.target.value;
        formattedPrice = currencyFormatter(event.target.value);
        const numericPrice = Number(formattedPrice.replace(/[^0-9.-]+/g, ''));
        if (numericPrice <= 0.0) {
            event.target.value = null;
        } else {
            event.target.value = formattedPrice;
        }
        setMaxPrice(event.target.value);
        setPriceLabel(
            minPrice === '' || minPrice === undefined
                ? 'Under $' + currencyFormatter(event.target.value)
                : event.target.value === '' || event.target.value === undefined
                  ? 'Over $' + minPrice
                  : '$' + currencyFormatter(minPrice) + ' to $' + currencyFormatter(event.target.value)
        );
    };

    const [priceLabel, setPriceLabel] = useState('');
    const [priceRangeLevel, setPriceRangeLevel] = useState('');
    const handlePriceRange = e => {
        if (e.target.value === '1' || e.target.value === '2' || e.target.value === '3') {
            const priceRangeData = {};
            setPriceRangeLevel(e.target.value);
            if (e.target.value === '1') {
                setMinPrice('');
                setMaxPrice('5.00');
                setPriceLabel('Under $5.00');
                priceRangeData.priceRangeStart = undefined;
                priceRangeData.priceRangeEnd = 5.0;
            } else if (e.target.value === '2') {
                setMinPrice('5.00');
                setMaxPrice('20.00');
                setPriceLabel('$5 to $20');
                priceRangeData.priceRangeStart = 5.0;
                priceRangeData.priceRangeEnd = 20.0;
            } else if (e.target.value === '3') {
                setMinPrice('20.00');
                setMaxPrice('');
                setPriceLabel('Over $20.00');
                priceRangeData.priceRangeStart = 20.0;
                priceRangeData.priceRangeEnd = undefined;
            }
            setTimeout(() => {
                onPriceRangeChange(priceRangeData);
            }, 50);
        }
    };

    const handleSetPriceRange = () => {
        setTimeout(() => {
            onPriceRangeChange({priceRangeStart: minPrice, priceRangeEnd: maxPrice});
        }, 50);
    };

    const handleClearPrice = () => {
        setMinPrice(null);
        setMaxPrice(null);
        setPriceLabel('');
        setPriceRangeLevel('');
        setTimeout(() => {
            onPriceRangeChange({});
        }, 50);
    };

    useEffect(() => {
        if (params && params.get('sellerName')) {
            setSellersButtonTitle(params.get('sellerName'));
        } else {
            setSellersButtonTitle('All Sellers');
        }
        if (cleanedSearchQuery) {
            const {gradingCompany, priceRangeStart, priceRangeEnd, rawCondition, sellerName} = cleanedSearchQuery;
            let totalConditions = 0;
            if (gradingCompany) {
                totalConditions += gradingCompany.length;
            }
            if (rawCondition) {
                totalConditions += rawCondition.length;
            }
            if (totalConditions > 0) {
                setConditionsButtonTitle(totalConditions + ' Selection(s)');
            } else {
                setConditionsButtonTitle('All Conditions');
            }
            if (priceRangeStart && priceRangeEnd) {
                if (minPrice === '' && maxPrice === '') {
                    const formattedMin = currencyFormatter(priceRangeStart);
                    setMinPrice(formattedMin);
                    const formattedMax = currencyFormatter(priceRangeEnd);
                    setMaxPrice(formattedMax);
                    setPriceLabel('$' + priceRangeStart + ' to $' + priceRangeEnd);
                    if ((priceRangeStart === '5' || priceRangeStart === '5.0') && (priceRangeEnd === '20' || priceRangeEnd === '20.0')) {
                        setPriceRangeLevel('2');
                    }
                }
            }
            if (priceRangeStart && !priceRangeEnd) {
                if (minPrice === '' && maxPrice === '') {
                    const formattedMin = currencyFormatter(priceRangeStart);
                    setMinPrice(formattedMin);
                    setMaxPrice(undefined);
                    setPriceLabel('Over $' + priceRangeStart);
                    if (priceRangeStart === '20' || priceRangeStart === '20.0') {
                        setPriceRangeLevel('3');
                    }
                }
            }
            if (!priceRangeStart && priceRangeEnd) {
                if (minPrice === '' && maxPrice === '') {
                    setMinPrice(undefined);
                    const formattedMax = currencyFormatter(priceRangeEnd);
                    setMaxPrice(formattedMax);
                    setPriceLabel('Under $' + priceRangeEnd);
                    if (priceRangeEnd === '5' || priceRangeEnd === '5.0') {
                        setPriceRangeLevel('1');
                    }
                }
            }
            if (sellerName) {
                setSellersButtonTitle(sellerName);
            }
        }
    }, [cleanedSearchQuery, params]);

    return (
        <>
            <BSCCardBox className={classes.sidebarContainer}>
                <div className={classes.sidebarWrap}>
                    <div className={classes.sidebarHeaderWrap}>
                        <BSCTypography variant="body2" className={classes.sidebarFilterHeader} label="Applied Vilters">
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <HtmlTooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={toolTipOpen}
                                    disableFocusListener
                                    disableTouchListener
                                    onClick={handleTooltipClose}
                                    title={
                                        <>
                                            <BSCTypography color="inherit">
                                                To improve loading speeds, the Applied Filter search function only looks at the Top 500
                                                results per filter.
                                            </BSCTypography>
                                            <br />
                                            <BSCTypography color="inherit">
                                                To find items that may be outside of the Top 500, begin narrowing your search starting with
                                                "Sport" and working your way down until your desired search term is captured in the results.
                                            </BSCTypography>
                                        </>
                                    }
                                >
                                    <InfoOutlinedIcon
                                        className={classes.infoIcon}
                                        onClick={handleTooltipClick}
                                        onMouseOver={handleTooltipOpen}
                                        onMouseLeave={handleTooltipClose}
                                    />
                                </HtmlTooltip>
                            </ClickAwayListener>
                        </BSCTypography>
                        <Button className={classes.clearAll} onClick={handleClearAll}>
                            Clear all
                        </Button>
                    </div>
                    <BSCScroller className={classes.filterButtonsWrap} maxHeight={200}>
                        {loading || isSearching ? (
                            <div className={classes.loadingWrap}>
                                <CircularProgress color="secondary" />
                            </div>
                        ) : chipList?.length > 0 ? (
                            chipList.map(item => (
                                <Chip
                                    key={item.slug}
                                    className={classes.filterButton}
                                    label={item.label}
                                    onDelete={() => onParamDelete(item?.type, item?.slug)}
                                />
                            ))
                        ) : (
                            <BSCTypography label="No Filters Selected" variant="subtitle2" className={classes.noFiltersLabel} />
                        )}
                    </BSCScroller>
                </div>
                <BSCDivider style={{margin: '4px 0', height: 1}} />
                {showSortOrder && aggregatedKeys && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCTypography label="Sort Order" size14 />
                            </div>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCSelect
                                    className={classNames(classes.bestSellerText, isSellers ? classes.sellersSelect : classes.select)}
                                    selectedSortValue={selectedSortValue}
                                    onSortChange={updateSortState}
                                    style={{width: '100%'}}
                                >
                                    <MenuItem className={classes.bestSellerText} value="" disabled>
                                        Select
                                    </MenuItem>
                                    {!isSellers && (
                                        <MenuItem
                                            className={classes.bestSellerText}
                                            value="best-sellers"
                                            onClick={e => setSortOrder(e.target.value)}
                                            selected={Boolean(selectedSortValue)}
                                        >
                                            Relevance
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="recently-added"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Recently Added
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="raw-price-hi-lo"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Price: Hi to Low
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="raw-price-lo-hi"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Price: Low to Hi
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="card-no-1-z"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Card #
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="newest"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Newest
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="oldest"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Oldest
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="a-z"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        A-Z
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        value="z-a"
                                        onClick={e => setSortOrder(e.target.value)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Z-A
                                    </MenuItem>
                                    <BSCDivider style={{margin: '4px 0', borderBottom: 'solid .5px #dae0e6'}} />
                                    <MenuItem
                                        className={classes.bestSellerText}
                                        onClick={() => setSortOrder(null)}
                                        selected={Boolean(selectedSortValue)}
                                    >
                                        Clear Sort Order
                                    </MenuItem>
                                </BSCSelect>
                            </div>
                        </div>
                        <BSCDivider style={{margin: '4px 0', height: 1}} />
                    </>
                )}
                {showInStock && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCTypography label="In Stock Only" size14 />
                            </div>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCTabs radius="4px" activeTab={stockToggleTab} onTabChange={handleTabChange}>
                                    <BSCTab skinny micro={true} label="On" />
                                    <BSCTab skinny micro={true} label="Off" />
                                </BSCTabs>
                            </div>
                        </div>
                        <BSCDivider style={{margin: '4px 0', height: 1}} />
                    </>
                )}
                {showSelectSeller && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCTypography label="Sellers" size14 />
                            </div>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCButton
                                    fullWidth
                                    style={
                                        sellersButtonTitle === 'All Sellers'
                                            ? {backgroundColor: '#B8C2CC', color: 'rgba(0, 0, 0, 0.54)'}
                                            : {backgroundColor: '#B8C2CC', padding: 4}
                                    }
                                    onClick={() => toggleFindASellerModalOpen(true)}
                                >
                                    <BSCTypography
                                        label={sellersButtonTitle}
                                        size12
                                        style={
                                            sellersButtonTitle === 'All Sellers'
                                                ? {}
                                                : {
                                                      background: 'white',
                                                      borderRadius: 4,
                                                      height: 23,
                                                      paddingTop: 3,
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis',
                                                      width: 110,
                                                      lineHeight: 'inherit',
                                                  }
                                        }
                                    />
                                </BSCButton>
                            </div>
                        </div>
                        <BSCDivider style={{margin: '4px 0', height: 1}} />
                    </>
                )}
                {showConditions && aggregatedKeys && (
                    <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCTypography label="Condition" size14 />
                            </div>
                            <div style={{flex: 1, padding: '0px 16px'}}>
                                <BSCButton
                                    fullWidth
                                    style={
                                        conditionsButtonTitle === 'All Conditions'
                                            ? {backgroundColor: '#B8C2CC', color: 'rgba(0, 0, 0, 0.54)'}
                                            : {backgroundColor: '#B8C2CC', padding: 4}
                                    }
                                    onClick={() => toggleSelectConditionsModalOpen(true)}
                                >
                                    <BSCTypography
                                        label={conditionsButtonTitle}
                                        size12
                                        style={
                                            conditionsButtonTitle === 'All Conditions'
                                                ? {}
                                                : {background: 'white', width: '100%', borderRadius: 4, height: 23, paddingTop: 5}
                                        }
                                    />
                                </BSCButton>
                            </div>
                        </div>
                        <BSCDivider style={{margin: '4px 0', height: 1}} />
                    </>
                )}
                {showPriceRange && aggregatedKeys && (
                    <>
                        <Box className={isMobile ? classes.boxMobile : classes.box}>
                            <div onClick={() => setOpen(!open)}>
                                <MenuItem disableRipple={false} className={classes.catMenuItem}>
                                    <BSCTypography className={classes.catHeader} label="Price" />
                                    <div style={{display: 'inline-flex'}}>
                                        <BSCTypography size12 label={priceLabel} />
                                        <KeyboardArrowDownIcon
                                            className={classNames({
                                                [classes.keyBoardArrow]: true,
                                                [classes.keyboardArrowFlipped]: open,
                                                [classes.searchSize]: true,
                                            })}
                                            style={{minWidth: 28}}
                                        />
                                    </div>
                                </MenuItem>
                            </div>
                            <Collapse in={open}>
                                <div className={classes.collapseWrapper}>
                                    <Box>
                                        <BSCRadioGroup
                                            value={priceRangeLevel}
                                            start
                                            noLabel={true}
                                            style={{marginLeft: -6, alignItems: 'start', flexDirection: 'column'}}
                                            onChange={e => {
                                                handlePriceRange(e);
                                            }}
                                        >
                                            <FormControlLabel
                                                className={classes.smallRadio}
                                                value="1"
                                                control={<BSCRadio />}
                                                label="Under $5.00"
                                            />
                                            <FormControlLabel
                                                className={classes.smallRadio}
                                                value="2"
                                                control={<BSCRadio />}
                                                label="$5 to $20"
                                            />
                                            <FormControlLabel
                                                className={classes.smallRadio}
                                                value="3"
                                                control={<BSCRadio />}
                                                label="Over $20.00"
                                            />
                                        </BSCRadioGroup>
                                    </Box>
                                    <div className={classes.minMaxTextFieldArea}>
                                        <BSCTypography size12 style={{color: '#bbb', paddingRight: 4, fontWeight: 300}} label="$" />
                                        <BSCTextField
                                            className={classes.rangePrice}
                                            value={minPrice}
                                            onChange={e => {
                                                const re = /^(\d*\.{0,1}\d{0,2}$)/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setMinPrice(e.target.value);
                                                    setPriceRangeLevel('');
                                                }
                                            }}
                                            onBlur={handleMinPriceBlur}
                                            placeholder="MIN"
                                        />
                                        <BSCTypography size12 style={{flex: 1}} label="to" />
                                        <BSCTextField
                                            className={classes.rangePrice}
                                            value={maxPrice}
                                            onChange={e => {
                                                const re = /^(\d*\.{0,1}\d{0,2}$)/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setMaxPrice(e.target.value);
                                                    setPriceRangeLevel('');
                                                }
                                            }}
                                            onBlur={handleMaxPriceBlur}
                                            placeholder="MAX"
                                        />
                                        <BSCButton
                                            onClick={handleSetPriceRange}
                                            type="button"
                                            className={classNames(classes.paginationBtn, classes.arrowBtn)}
                                        >
                                            <KeyboardArrowRightIcon />
                                        </BSCButton>
                                    </div>
                                    <div className={classes.priceRangeCTAWrapper}>
                                        <Button
                                            className={classes.clearAll}
                                            onClick={handleClearPrice}
                                            style={isDesktop ? {fontWeight: 'bold'} : {fontWeight: 'bold', paddingRight: 60}}
                                        >
                                            Clear Price
                                        </Button>
                                    </div>
                                </div>
                            </Collapse>
                        </Box>
                        <BSCDivider style={{margin: '4px 0', height: 1}} />
                    </>
                )}
                {loading ||
                    (isSearching && (
                        <div className={classes.loadingWrap}>
                            <CircularProgress color="secondary" />
                        </div>
                    ))}
                {errors && <BSCErrorBanner errors={errors} />}
                <div style={isDesktop ? {maxHeight: 450, overflowY: 'auto'} : {maxHeight: 595, overflowY: 'auto'}}>
                    <BSCScroller>
                        {aggregatedKeys?.map(searchTermKey => (
                            <Fragment key={searchTermKey}>
                                <SearchList
                                    searchTermKey={searchTermKey}
                                    menuItems={aggregatedValues?.[searchTermKey]}
                                    parsedQueryResults={parsedQueryResults}
                                    onRadioChange={handleRadioChange}
                                    onCheckboxChange={handleCheckboxChange}
                                    toggleKey={toggleKey}
                                    onListClick={handleToggleClick}
                                />
                                <BSCDivider style={{margin: '4px 0', height: 1}} />
                            </Fragment>
                        ))}
                    </BSCScroller>
                </div>
                {!showFindASeller && (
                    <Box className={classes.buttonWrap}>
                        <BSCTypography
                            className={classes.missingDataLink}
                            onClick={() => toggleMissingDataModalOpen(true)}
                            label="Missing Data?"
                        />
                    </Box>
                )}
            </BSCCardBox>
            <FindASellerModal open={findASellerModalOpen} onClose={handleFindASellerClose} />
            <SelectConditionsModal open={selectConditionsModalOpen} handleClose={handleSelectConditionsClose} />
            <MissingDataModal open={missingDataModalOpen} onClose={handleMissingDataModalClose} />
        </>
    );
}

export default memo(SearchSidebar);
