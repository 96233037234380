import React, {useEffect, useState} from 'react';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useStripe, useElements} from '@stripe/react-stripe-js';
import {makeStyles, Checkbox, FormControlLabel, Grid} from '@material-ui/core';
import BSCErrorBanner from '../../../../../../../features/common/bscAlert/bscAlert';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import {PaymentApi} from '../../../../../../../client/openapitools/marketplace/api';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';
import {useMsal} from '@azure/msal-react';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';

const useStyles = makeStyles(() => ({
    fullWidth: {
        width: '100%',
    },
    mt: {
        marginTop: 10,
    },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const CheckoutForm = ({paymentItem, isEdit, refetch = () => {}, handleClose = () => {}}) => {
    const classes = useStyles();
    const stripePayment = useStripe();
    const elements = useElements();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const [createPaymentLoading, setCreatePaymentLoading] = useState<boolean>(false);
    const [updatePaymentLoading, setUpdatePaymentLoading] = useState<boolean>(false);
    const [primary, setPrimary] = useState<boolean>(false);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    useEffect(() => {
        if (paymentItem) {
            setPrimary(paymentItem.defaultPaymentMethod);
        }
    }, [paymentItem]);

    const handleSetPrimary = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrimary(event.target.checked);
    };

    const handleSubmit = async (event: {preventDefault: () => void}) => {
        event.preventDefault();

        if (elements === null) {
            return;
        }
        const {error, paymentMethod} = await stripePayment.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
        if (paymentMethod) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const paymentApi = new PaymentApi(apiConfig);
                            if (isEdit && paymentItem) {
                                setUpdatePaymentLoading(true);
                                const updateResponse = await paymentApi.updatePaymentMethod({
                                    paymentMethodId: paymentItem.id,
                                    month: paymentMethod.card.exp_month,
                                    year: paymentMethod.card.exp_year,
                                    defaultPaymentMethod: primary,
                                });
                                setUpdatePaymentLoading(false);
                                if (updateResponse.status === 200 || updateResponse.status === 204) {
                                    addToast({
                                        severity: 'success',
                                        message: 'Card successfully edited.',
                                        contextKey: 'Payment Processing',
                                    });
                                    handleClose();
                                    refetch();
                                } else {
                                    addToast({
                                        severity: 'error',
                                        message: 'An error has occurred with this card.',
                                        contextKey: 'Payment Processing',
                                    });
                                }
                            } else {
                                setCreatePaymentLoading(true);
                                const createResponse = await paymentApi.attachPaymentMethod({
                                    paymentMethodId: paymentMethod.id,
                                    defaultPaymentMethod: primary,
                                });
                                setCreatePaymentLoading(false);
                                if (createResponse.status === 200 || createResponse.status === 202) {
                                    addToast({
                                        severity: 'success',
                                        message: 'Card successfully added.',
                                        contextKey: 'Payment Processing',
                                    });
                                    handleClose();
                                    refetch();
                                } else {
                                    addToast({
                                        severity: 'error',
                                        message: 'An error has occurred with this card.',
                                        contextKey: 'Payment Processing',
                                    });
                                }
                            }
                        } catch (error) {
                            setCreatePaymentLoading(false);
                            setUpdatePaymentLoading(false);
                            console.error('Error fetching Messages', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred with this card.',
                                contextKey: 'Payment Processing',
                            });
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        setCreatePaymentLoading(false);
                        setUpdatePaymentLoading(false);
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        } else {
            <BSCErrorBanner
                errors={{
                    name: error.code,
                    message: error.message,
                }}
            />;
        }
    };
    return (
        <form className={classes.fullWidth} onSubmit={handleSubmit}>
            <CardElement
                options={{
                    hidePostalCode: true,
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
            <FormControlLabel
                value={primary}
                className={classes.mt}
                checked={primary}
                onChange={handleSetPrimary}
                control={<Checkbox name="primary" />}
                label={<BSCTypography variant="subtitle1" label={'Set your default/primary card.'} />}
            />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <BSCButton loading={createPaymentLoading || updatePaymentLoading} fullWidth onClick={handleClose}>
                        Cancel
                    </BSCButton>
                </Grid>
                <Grid item xs={6}>
                    <BSCButton loading={createPaymentLoading || updatePaymentLoading} color="primary" fullWidth type="submit">
                        {isEdit ? 'Save' : 'Add Card'}
                    </BSCButton>
                </Grid>
            </Grid>
        </form>
    );
};

function PaymentForm({paymentItem, isEdit, refetch = () => {}, handleClose = () => {}}) {
    return (
        <div>
            <Elements stripe={stripePromise}>
                <CheckoutForm paymentItem={paymentItem} handleClose={handleClose} refetch={refetch} isEdit={isEdit} />
            </Elements>
        </div>
    );
}

export default PaymentForm;
