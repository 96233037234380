/* eslint-disable indent */
import {BscTableBlock, BscTableBlockColumnDefinition} from '../../../../features/common/bscTableBlock/bscTableBlock';
import {useEffect, useMemo, useState} from 'react';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import useDateTimeNormalizer from '../../../../client/common/normalizer/dateTimeNormalizer';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import {Configuration, PaymentApi, SellerPaymentDetails} from '../../../../client/openapitools/seller';
import {useHistory} from 'react-router';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';

const SellerPaymentsMain = () => {
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let paymentApi = new PaymentApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const history = useHistory();
    const {isMobile, isBelowTablet} = useScreenSnap();
    // const {data: futurePayments, loading: futurePaymentsLoading} = useQuery(paymentApi.getSellerFuturePayments, []);
    // const {data: pastPayments, loading: pastPaymentsLoading} = useQuery(paymentApi.getSellerPaymentsHistory, []);
    const [futurePayments, setFuturePayments] = useState(null);
    const [pastPayments, setPastPayments] = useState(null);
    const [futurePaymentsLoading, setFuturePaymentsLoading] = useState(true);
    const [pastPaymentsLoading, setPastPaymentsLoading] = useState(true);

    const dateNormalizer = useDateTimeNormalizer();

    const dateNormalizerFunc = s => <BSCTypography label={dateNormalizer.getDisplayDate(s, true)} />;
    const stringRenderFunc = s => <BSCTypography label={s} />;
    const moneyRenderFunc = s => <BSCCurrency amount={s} />;
    const negMoneyRender = s => <BSCTypography label={s !== undefined ? `-$${currencyFormatter(s)}` : '-$0.00'} />;

    const columns = useMemo((): BscTableBlockColumnDefinition<SellerPaymentDetails>[] => {
        return [
            {
                header: 'Estimated Date of Transfer',
                dataSelector: s => {
                    if (!s.estimatedTransferArrivalDate) {
                        if (!s.id) {
                            return 'Total:';
                        } else if (s?.id?.toUpperCase() === 'CURRENTDAYSHIPPED') {
                            return 'Shipped Today';
                        } else if (s?.id?.toUpperCase() === 'UNSCHPAYMENT') {
                            return 'Not Scheduled';
                        }
                    }

                    return dateNormalizerFunc(s.estimatedTransferArrivalDate);
                },
                renderFunc: s => s,
            },
            {header: 'Orders', dataSelector: s => s.numberOfOrders, renderFunc: stringRenderFunc},
            {header: 'Total Sales', dataSelector: s => s.totalSales, renderFunc: moneyRenderFunc},
            {header: 'Refunds', dataSelector: s => s.totalRefundAmount, renderFunc: negMoneyRender},
            {header: 'Payment Processing', dataSelector: s => s.paymentProcessingFee, renderFunc: negMoneyRender},
            {header: 'BSC Seller Fees', dataSelector: s => s.bscSellerFee, renderFunc: negMoneyRender},
            {header: 'BSC Shipping', dataSelector: s => s.bscShippingCharge, renderFunc: negMoneyRender},
            {header: 'Payment', dataSelector: s => s.payment, renderFunc: moneyRenderFunc},
            {
                header: 'View Orders',
                dataSelector: s => s.id,
                //eslint-disable-next-line @typescript-eslint/no-unused-vars
                renderFunc: s => {
                    return s ? (
                        <BSCButton color="primary" onClick={() => history.push(`/sellers/payments/${s}`)}>
                            <BSCTypography label="View"></BSCTypography>
                        </BSCButton>
                    ) : (
                        ''
                    );
                },
            },
        ];
    }, []);

    const pastColumns = useMemo((): BscTableBlockColumnDefinition<SellerPaymentDetails>[] => {
        return [
            {header: 'Payment Initiated On', dataSelector: s => s.paymentInitiatedOn, renderFunc: dateNormalizerFunc},
            {header: 'Orders', dataSelector: s => s.numberOfOrders, renderFunc: stringRenderFunc},
            {header: 'Total Sales', dataSelector: s => s.totalSales, renderFunc: moneyRenderFunc},
            {header: 'Refunds', dataSelector: s => s.totalRefundAmount, renderFunc: negMoneyRender},
            {header: 'Payment Processing', dataSelector: s => s.paymentProcessingFee, renderFunc: negMoneyRender},
            {header: 'BSC Seller Fees', dataSelector: s => s.bscSellerFee, renderFunc: negMoneyRender},
            {header: 'BSC Shipping', dataSelector: s => s.bscShippingCharge, renderFunc: negMoneyRender},
            {header: 'Payment', dataSelector: s => s.payment, renderFunc: moneyRenderFunc},
            {
                header: 'View Orders',
                dataSelector: s => s.id,
                //eslint-disable-next-line @typescript-eslint/no-unused-vars
                renderFunc: s => {
                    return s ? (
                        <BSCButton color="primary" onClick={() => history.push(`/sellers/payments/${s}`)}>
                            <BSCTypography label="View"></BSCTypography>
                        </BSCButton>
                    ) : (
                        ''
                    );
                },
            },
        ];
    }, []);
    const [futurePaymentData, setFuturePaymentData] = useState([]);
    const [pastPaymentData, setPastPaymentData] = useState([]);

    const [futurePaymentDataSummaryRow, setFuturePaymentSummaryRow] = useState<SellerPaymentDetails>();
    const [pastPaymentDataSummaryRow, setPastPaymentSummaryRow] = useState<SellerPaymentDetails>();

    useEffect(() => {
        if (futurePayments?.data) {
            //eslint-disable-next-line @typescript-eslint/no-unused-vars
            if (futurePayments.data?.results?.some(x => true)) {
                setFuturePaymentData(futurePayments?.data?.results);
            } else {
                setFuturePaymentData([[{...futurePayments?.data?.totalSellerPaymentSummary, paymentInitiatedOn: 'Total:'}]]);
            }

            setFuturePaymentSummaryRow({...futurePayments?.data?.totalSellerPaymentSummary, paymentInitiatedOn: 'Total:'});
        }
    }, [futurePayments]);

    useEffect(() => {
        if (pastPayments?.data) {
            //eslint-disable-next-line @typescript-eslint/no-unused-vars
            if (pastPayments.data?.results?.some(x => true)) {
                const tempData = pastPayments.data.results;
                if (tempData.every(x => x.numberOfOrders === 0)) {
                    setPastPaymentData([]);
                } else {
                    setPastPaymentData(tempData);
                }
            } else {
                setPastPaymentData([]);
            }

            setPastPaymentSummaryRow({...pastPayments?.data?.totalSellerPaymentSummary, paymentInitiatedOn: 'Total:'});
        }
    }, [pastPayments]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            paymentApi = new PaymentApi(apiConfig);
                            const futurePayments = await paymentApi.getSellerFuturePayments();
                            setFuturePaymentsLoading(false);
                            setFuturePayments(futurePayments);
                            const sellerPaymentsHistory = await paymentApi.getSellerPaymentsHistory();
                            setPastPaymentsLoading(false);
                            setPastPayments(sellerPaymentsHistory);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error fetching payments',
                                contextKey: 'Seller Payments',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <BSCContainer variant="page">
            <div
                style={
                    isMobile || isBelowTablet
                        ? {
                              background: '#fff',
                              margin: '0px 5%',
                              padding: 8,
                              fontSize: 15,
                              fontFamily: '"Poppins", sans-serif',
                              fontWeight: 600,
                              lineHeight: 1.5,
                              textAlign: 'center',
                              border: 'solid 1px #000',
                          }
                        : {
                              background: '#fff',
                              margin: '0px 5%',
                              padding: 16,
                              fontSize: 16,
                              fontFamily: '"Poppins", sans-serif',
                              fontWeight: 600,
                              lineHeight: 1.5,
                              textAlign: 'center',
                              border: 'solid 1px #000',
                          }
                }
            >
                Please Note: Payments are initiated on Tuesday & Friday and will completely transfer into your account and be accessible
                after <u>3-4 Business Days.</u>
            </div>
            <BscTableBlock
                blockHeader="Estimated Future Payments"
                viewingItemType="Payments"
                summaryRow={futurePaymentDataSummaryRow}
                columns={columns}
                enablePaging
                data={futurePaymentData}
                loading={futurePaymentsLoading}
                hasPagination={false}
            ></BscTableBlock>
            <BscTableBlock
                blockHeader="Past Payment History"
                viewingItemType="Payments"
                summaryRow={pastPaymentDataSummaryRow}
                enablePaging
                clientSidePaging
                columns={pastColumns}
                data={pastPaymentData}
                loading={pastPaymentsLoading}
            ></BscTableBlock>
        </BSCContainer>
    );
};

export default SellerPaymentsMain;
