import React from 'react';

export const MarketPlaceHeaderContext = React.createContext({
    headerBarChild: null,
    setHeaderBarChild: () => {},
    slidyHeader: null,
    setSlidyHeader: () => {},
});

interface MarketplaceHeaderContextState {
    headerBarChild;
    setHeaderBarChild;
    slidyHeader;
    setSlidyHeader;
}

export const useMarketPlaceHeaderContext = (): MarketplaceHeaderContextState => {
    const context = React.useContext(MarketPlaceHeaderContext);
    if (context === undefined) {
        throw new Error('useAlertContext must be used within an AlertContextProvider');
    }
    return context;
};

const MarketPlaceHeaderProvider = ({value, children}) => {
    return <MarketPlaceHeaderContext.Provider value={value}>{children}</MarketPlaceHeaderContext.Provider>;
};

export default MarketPlaceHeaderProvider;
