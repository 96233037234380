import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AUTH_REQUESTS} from '../../../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../../../AuthContext';
import OrderHistoryNormalizer from '../../../../../../client/marketplace/order/history/orderHistoryNormalizer';
import {OrderApi as MarketplaceOrderApi, OrderHistoryRequest} from '../../../../../../client/openapitools/marketplace/api';
import BSCHeaderBlock from '../../../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import BSCLoading from '../../../../../../features/common/bscLoading/bscLoading';
import BSCTypography from '../../../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../../../features/hooks/redux/toast/useReduxToast';
import {RootState} from '../../../../../../redux/reducers';
import OrderHistoryItem from './components/orderHistoryItem';

const sizePerPage = 1000; //reset to 10 after api paging added

function OrderHistory() {
    const [page] = useState(0); //add setPage back
    const {apiAuthConfig} = useAuthState();
    const {instance} = useMsal();
    const {addToast} = useReduxToast();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const authFunc = useAuthFunctions();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sortType, setSortType] = useState('allTime');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [search, setSearch] = useState<OrderHistoryRequest | null>();

    // let marketplaceOrderApi = new MarketplaceOrderApi(apiAuthConfig);
    // const {loading: loading, data: orderHistory} = useQuery(() => {
    //     if (search) return marketplaceOrderApi.getOrderHistory(search);
    // }, [search]);

    const [loading, setLoading] = useState(false);
    const [orderHistory, setOrderHistory] = useState(null);

    const YOUR_ORDER_HISTORY_IS_EMPTY = 'Your Order History is Empty';
    const orderHistoryResults = OrderHistoryNormalizer.getOrders(orderHistory?.data);
    //TODO: Figure out how to cache total number and only refresh when search changes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const totalOrderNum = OrderHistoryNormalizer.getTotalOrderNum(orderHistory?.data); //TODO: Figure out how to cache total number and only refresh when search changes

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        const params: OrderHistoryRequest = {
            //query: search,
            dateRange: sortType,
            page: page,
            size: sizePerPage,
        };
        if (apiAuthConfig) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setLoading(true);
                            const marketplaceOrderApi = new MarketplaceOrderApi(apiConfig);
                            const response = await marketplaceOrderApi.getOrderHistory(params);
                            if (response.data) {
                                setOrderHistory(response);
                            }
                            setLoading(false);
                        } catch (error) {
                            console.error('Error fetching Order History', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Messages',
                            });
                            setLoading(false);
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    }, [page, sortType, sizePerPage, apiAuthConfig]);

    return (
        <>
            {pwaOS === null && <BSCHeaderBlock align="center" label="Order History" hasGrid={false} />}
            <BSCLoading loading={loading} />

            {!loading && !orderHistoryResults && (
                <div style={{padding: 32, textAlign: 'center'}}>
                    <BSCTypography style={{fontSize: 16}} label={YOUR_ORDER_HISTORY_IS_EMPTY} />
                </div>
            )}

            {orderHistoryResults?.length > 0 &&
                orderHistoryResults?.map(orderHistoryResult => (
                    <OrderHistoryItem orderHistoryResult={orderHistoryResult} key={orderHistoryResult.orderId} />
                ))}
        </>
    );
}

export default OrderHistory;
