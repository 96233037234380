import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import * as constants from '../../shared/constants';

function PWA() {
    // placeholder component for future use
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // establish operational landscape (may have to move this back to /public/scripts and refactor)
        let osName: string = null;
        if (navigator.userAgent.indexOf('Win') !== -1) osName = 'Windows';
        if (navigator.userAgent.indexOf('Mac') !== -1) osName = 'Mac';
        if (navigator.userAgent.indexOf('Linux') !== -1) osName = 'Linux';
        if (navigator.userAgent.indexOf('Android') !== -1) osName = 'Android';
        if (navigator.userAgent.indexOf('like Mac') !== -1) osName = 'iOS';
        const isMobileDevice = window.matchMedia('only screen and (max-width: 1024px)').matches;
        if (isMobileDevice) {
            if (osName === 'Mac') {
                osName = 'iOS';
            }
        }
        dispatch({
            type: constants.PWA_SETTINGS,
            payload: {
                pwaSettings: {
                    isMobile: isMobileDevice,
                    pwaOS: osName,
                },
            },
        });
        history.push('../');
    }, [window.location.pathname]);

    return <></>;
}

export default PWA;
