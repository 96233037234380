import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    modalDimensions: {
        height: 128,
    },
    abandonConfirmation: {
        fontSize: '20px !important',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: '1.167.toFixed',
        letterSpacing: 0.5,
    },
}));

interface AbandonConfirmationModalProps {
    open: boolean;
    onClose: boolean;
}

function AbandonConfirmationModal({open, onClose}: AbandonConfirmationModalProps) {
    const classes = useStyles();
    return (
        <BSCModal open={open} onClose={onClose}>
            <div className={classes.modalDimensions}>
                <BSCTypography
                    align="center"
                    label="You have unsaved data - are you sure you want Reset/Edit?"
                    className={classes.abandonConfirmation}
                />
                <BSCDivider />
                <div className={classes.overflowHidden}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <BSCButton fullWidth color="primary" onClick={() => onClose(false)}>
                                <BSCTypography label="Cancel" />
                            </BSCButton>
                        </Grid>
                        <Grid item xs={6}>
                            <BSCButton fullWidth color="secondary" onClick={() => onClose(true)}>
                                <BSCTypography label="Confirm" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BSCModal>
    );
}

export default AbandonConfirmationModal;
