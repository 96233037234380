/* eslint-disable no-case-declarations */
import * as constants from '../../shared/constants';
import Normalizer from '../../client/common/normalizer/normalizer';

const initialState = {
    hasVerifiedMicroDeposits: false,
    stockImageFront: '',
    stockImageBack: '',
    productImageFront: '',
    productImageBack: '',
    returnToInventoryUrl: '',
    sellersLockerCardsActiveTab: -1,
    currentCardProductId: '',
    isEditingCurrentListingCard: false,
    currentListingCard: {},
    scrollableId: '',
    bulkUploadSelections: [
        {
            category: '',
            selection: '',
        },
    ],
    bulkUploadFormDirty: false,
    bulkUploadYear: '',
    bulkUploadSetName: '',
    bulkUploadVariant: '',
    bulkUploadVariantName: '',
    isDeviceFooterNavHomeActive: true,
    isDeviceFooterNavAccountActive: false,
    isDeviceFooterNavSearchActive: false,
    isDeviceFooterNavFAQsActive: false,
    isDeviceFooterNavSellingActive: false,
    sellersLockerUser: {},
};

export default (state = initialState, action) => {
    switch (action?.type) {
        case constants.HAS_VERIFIED_MICRO_DEPOSITS:
            return {
                ...state,
                hasVerifiedMicroDeposits: action.payload.hasVerifiedMicroDeposits,
            };
        case constants.CARD_IMAGE_SELECTED:
            return {
                ...state,
                productImageFront: Normalizer.getS3ImagePath(action.payload.imageFront),
                productImageBack: Normalizer.getS3ImagePath(action.payload.imageBack),
            };
        case constants.CARD_IMAGE_DEFAULT:
            return {
                ...state,
                stockImageFront: action.payload.imageFront,
                stockImageBack: action.payload.imageBack,
            };
        case constants.SELLER_INVENTORY_URL:
            return {
                ...state,
                returnToInventoryUrl: action.payload.returnToInventoryUrl,
            };
        case constants.CURRENT_CARD_PRODUCT_ID:
            return {
                ...state,
                currentCardProductId: action.payload.currentCardProductId,
            };
        case constants.SELLERS_LOCKER_CARDS_ACTIVE_TAB:
            return {
                ...state,
                sellersLockerCardsActiveTab: action.payload.sellersLockerCardsActiveTab,
            };
        case constants.IS_EDITING_CURRENT_LISTING_CARD:
            return {
                ...state,
                currentCardProductId: action.payload.currentCardProductId,
                isEditingCurrentListingCard: action.payload.isEditingCurrentListingCard,
            };
        case constants.CURRENT_LISTING_CARD:
            return {
                ...state,
                currentListingCard: action.payload.currentListingCard,
            };
        case constants.SET_SELLER_SCROLLABLE_ID:
            return {
                ...state,
                scrollableId: action.payload.scrollableId,
            };
        case constants.SET_SELLERS_LOCKER_USER:
            return {
                ...state,
                sellersLockerUser: action.payload.sellersLockerUser,
            };
        case constants.SET_BULK_UPLOAD_SELECTIONS:
            let tempArray = [...state.bulkUploadSelections];
            let ndx;
            switch (action.payload.category) {
                case 'sport':
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'sport');
                    tempArray = [];
                    if (ndx === -1) {
                        tempArray.push({category: action.payload.category, selection: action.payload.selection});
                    }
                    break;
                case 'year':
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'year');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    } else {
                        tempArray.push({category: action.payload.category, selection: action.payload.selection});
                    }
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'setName');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    }
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'variant');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    }
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'variantName');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    }
                    break;
                case 'setName':
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'setName');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    } else {
                        tempArray.push({category: action.payload.category, selection: action.payload.selection});
                    }
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'variant');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    }
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'variantName');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    }
                    break;
                case 'variant':
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'variant');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    } else {
                        tempArray.push({category: action.payload.category, selection: action.payload.selection});
                    }
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'variantName');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    }
                    break;
                case 'variantName':
                    ndx = tempArray.findIndex(tempItem => tempItem.category === 'variantName');
                    if (ndx > -1) {
                        tempArray.splice(ndx, 1);
                    } else {
                        tempArray.push({category: action.payload.category, selection: action.payload.selection});
                    }
                    break;
            }
            return {
                ...state,
                bulkUploadSelections: tempArray,
            };
        case constants.RESET_BULK_UPLOAD_SELECTIONS:
            return {
                ...state,
                bulkUploadSelections: [
                    {
                        category: '',
                        selection: '',
                    },
                ],
            };
        case constants.BULK_UPLOAD_FORM_DIRTY:
            return {
                ...state,
                bulkUploadFormDirty: action.payload.status,
            };
        case constants.BULK_UPLOAD_YEAR:
            return {
                ...state,
                bulkUploadYear: action.payload.bulkUploadYear,
            };
        case constants.BULK_UPLOAD_SET_NAME:
            return {
                ...state,
                bulkUploadSetName: action.payload.bulkUploadSetName,
            };
        case constants.BULK_UPLOAD_VARIANT:
            return {
                ...state,
                bulkUploadVariant: action.payload.bulkUploadVariant,
            };
        case constants.BULK_UPLOAD_VARIANT_NAME:
            return {
                ...state,
                bulkUploadVariantName: action.payload.bulkUploadVariantName,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_HOME:
            return {
                ...state,
                isDeviceFooterNavHomeActive: true,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_ACCOUNT:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: true,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_SEARCH:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: true,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_FAQS:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: true,
                isDeviceFooterNavSellingActive: false,
            };
        case constants.MOBILE_DEVICE_FOOTER_NAV_SELLING:
            return {
                ...state,
                isDeviceFooterNavHomeActive: false,
                isDeviceFooterNavAccountActive: false,
                isDeviceFooterNavSearchActive: false,
                isDeviceFooterNavFAQsActive: false,
                isDeviceFooterNavSellingActive: true,
            };

        default:
            return state;
    }
};
