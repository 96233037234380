import {useState, memo, useCallback, MouseEvent} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import {makeStyles, Theme} from '@material-ui/core/styles';
import SLSubNav from './slSubNav';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        marginRight: 4,
        fontSize: theme.typography.body2.fontSize,
        padding: theme.spacing(1),
        '&:hover': {
            background: theme.palette.common.white,
            color: theme.palette.common.black,
        },
    },
    menuContainer: {background: theme.palette.common.black},
    skeletonMenuItem: {marginRight: 20},
    menuWrap: {
        ...theme.containerWraps.page,
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    opened: {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
    },
}));

interface NavbarProps {
    //TODO: Fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menus: undefined;
    menusLoading?: boolean;
}

function Navbar({menus, menusLoading = false}: NavbarProps) {
    const classes = useStyles();
    const [hover, setHover] = useState<null | HTMLElement>(null);
    const [activeSubMenuKey, setActiveSubMenuKey] = useState<null | number>();
    const handleMouseEnter = (htmlElement: HTMLElement, i: number) => {
        setActiveSubMenuKey(i);
        setHover(htmlElement);
    };

    const handleMouseLeave = useCallback(() => {
        setActiveSubMenuKey(null);
    }, []);

    const handlePopoverEnter = useCallback((id: number) => {
        setActiveSubMenuKey(id);
    }, []);

    const handlePopoverLeave = useCallback(() => {
        setActiveSubMenuKey(null);
    }, []);

    return (
        <div className={classes.menuContainer}>
            <div className={classes.menuWrap}>
                <Grid container justifyContent="space-evenly">
                    {menusLoading && (
                        <>
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                            <Skeleton className={classes.skeletonMenuItem} width={125} height={40} />
                        </>
                    )}
                    {menus?.categories?.map((cats, i) => (
                        <div key={i}>
                            <MenuItem
                                onMouseEnter={(e: MouseEvent<HTMLElement>) => {
                                    handleMouseEnter(e.currentTarget, i);
                                }}
                                onMouseLeave={handleMouseLeave}
                                className={classNames(classes.root, {[classes.opened]: activeSubMenuKey === i})}
                                component={Link}
                                to={cats.url}
                            >
                                {cats.title}
                                {cats?.menus?.length > 0 && (
                                    <KeyboardArrowDownIcon
                                        style={{
                                            color: activeSubMenuKey === i ? 'black' : 'white',
                                        }}
                                    />
                                )}
                            </MenuItem>
                            {cats?.menus?.length > 0 && activeSubMenuKey === i && (
                                <SLSubNav
                                    id={i}
                                    anchorEl={hover}
                                    open={activeSubMenuKey === i}
                                    menus={cats.menus}
                                    popoverEnter={handlePopoverEnter}
                                    popoverLeave={handlePopoverLeave}
                                />
                            )}
                        </div>
                    ))}
                </Grid>
            </div>
        </div>
    );
}

export default memo(Navbar);
