import {forwardRef} from 'react';
import MuiTextField, {OutlinedTextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField';
import {validatedSchema} from '../../../@types/validations/validations';

export interface BSCTextFieldProps extends Omit<MuiTextFieldProps, 'error' | 'variant'> {
    inputKey: string;
    formStateValidations?: validatedSchema;
    placeholder?: null | string;
    value?: null | string | number;
    label?: null | string;
    error?: undefined | string | boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BSCBaseTextField = forwardRef<HTMLDivElement, undefined>(({...otherProps}, ref) => {
    return <MuiTextField {...otherProps} ref={ref} variant="outlined" />;
});

const BSCTextField = forwardRef<HTMLDivElement, BSCTextFieldProps>(
    (
        {inputKey, formStateValidations, placeholder = null, value = '', label = null, error = null, ...otherProps}: BSCTextFieldProps,
        ref
    ) => {
        return (
            <BSCBaseTextField
                {...otherProps}
                ref={ref}
                placeholder={formStateValidations?.[inputKey]?.label ?? placeholder}
                name={inputKey || ''}
                value={formStateValidations?.[inputKey]?.value || value}
                label={formStateValidations?.[inputKey]?.errors ?? label}
                error={Boolean(formStateValidations?.[inputKey]?.errors) ?? Boolean(error)}
            />
        );
    }
);

export default BSCTextField;
