/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import SwiperCore from 'swiper';
import {Navigation} from 'swiper/modules';
import 'swiper/scss';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import styles from './Styles';

const baseStyles = makeStyles(() => ({
    horizontalRibbon: {
        marginBottom: 6,
        width: '100%',
        overflowX: 'scroll',
        ['&::-webkit-scrollbar']: {
            display: 'none !important',
        },
    },
}));

SwiperCore.use([Navigation]);

export default function PhoneSportSlider(sliderData) {
    const classes = styles();
    const baseClasses = baseStyles();
    const [phoneSlideFactor, setPhoneSlideFactor] = useState(0);

    useEffect(() => {
        const deviceScreenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
        let phoneFactor = deviceScreenWidth / 165;
        if (Number.isInteger(phoneFactor)) {
            phoneFactor += 0.25;
        }
        setPhoneSlideFactor(phoneFactor);
    }, []);

    return (
        <>
            {sliderData &&
                sliderData.sliders &&
                sliderData.sliders.length > 0 &&
                sliderData.sliders.map((slider, index) => (
                    <Grid container alignItems="center" key={index}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent={'flex-start'}>
                                <BSCTypography variant="body1" style={{fontSize: 20, fontWeight: 600}} label={`${slider.slider_name}:`} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={baseClasses.horizontalRibbon}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={'flex-start'}
                                className={classes.sliderNoWrap}
                            >
                                {slider.items.map((item, index) => (
                                    <Grid item key={index}>
                                        <Link to={item.slug}>
                                            <BSCImage
                                                className={classes.sportImage}
                                                alt={item.img_alt}
                                                style={index === 0 ? {padding: '4px 4px 4px 0', width: 165} : {padding: '4px', width: 165}}
                                                title={item.title}
                                                src={Normalizer.getS3ImagePath(item.image)}
                                            />
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
        </>
    );
}
