import {Zoom} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import React from 'react';
import BSCImage from '../bscImage/bscImage';

interface BscImagePopperProps {
    children: React.ReactNode;
    imageUrl: string;
    id: string;
}
/* eslint-disable */

const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
        <div {...props} ref={ref}>
            Bin
        </div>
    );
});

const BscImagePopper = ({imageUrl, id, children}: BscImagePopperProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMouseEnter = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = event => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Tooltip
            arrow
            disableFocusListener
            disableTouchListener
            placement="right"
            TransitionComponent={Zoom}
            title={
                <React.Fragment>
                    <BSCImage src={imageUrl} />
                </React.Fragment>
            }
        >
            <div>{children}</div>
        </Tooltip>
    );
};

export default BscImagePopper;
