import {Grid, IconButton} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import * as constants from '../../../../shared/constants';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) => ({
    selectionsWrapper: {
        padding: '8px 8px 8px 6px !important',
    },
    selectionWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 4,
        marginBottom: 6,
    },
    largeIcon: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        border: 'solid 2px black',
        padding: 8,
        '& svg': {
            height: 32,
            width: 32,
        },
    },
}));

function PWASellerDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleDashSelection = (index: number) => {
        switch (index) {
            case 0:
                history.push('/sellers/dashboard/profile');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 1,
                    },
                });
                break;
            case 1:
                history.push('/sellers/inventory/inventory?myInventory=false');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 2,
                    },
                });
                break;
            case 2:
                history.push('/sellers/inventory/inventory?myInventory=true');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 3,
                    },
                });
                break;
            case 3:
                history.push('/sellers/orders');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 4,
                    },
                });
                break;
            case 4:
                history.push('/sellers/offers');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 5,
                    },
                });
                break;
            case 5:
                history.push('/sellers/sales-summary');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 6,
                    },
                });
                break;
            case 6:
                history.push('/sellers/payments');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 7,
                    },
                });
                break;
            case 7:
                history.push('/sellers/dashboard/feedback');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 8,
                    },
                });
                break;
            case 8:
                history.push('/sellers/dashboard/faq');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 9,
                    },
                });
                break;
            case 9:
                history.push('/sellers/dashboard/support');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 10,
                    },
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        dispatch({
            type: constants.PWA_SPORTS_CHIPS,
            payload: {
                isSportsChipsVisible: false,
            },
        });
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 4,
            },
        });
        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 0,
            },
        });
    }, []);

    return (
        <div className={classes.selectionsWrapper}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction="row">
                        <Grid item xs={12} onClick={() => handleDashSelection(0)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <PersonOutlineOutlinedIcon htmlColor="white" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Seller Profile
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(1)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">playing_cards</span>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Sell Your Cards
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(2)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">inventory_2</span>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        My Inventory
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(3)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">paid</span>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Orders
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(4)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">currency_exchange</span>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Offers
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(5)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">summarize</span>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Sales Summary
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(6)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">payments</span>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Payments
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(7)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <RateReviewOutlinedIcon htmlColor="white" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Feedback
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(8)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">question_mark</span>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        FAQs
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(9)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item xs={2}>
                                    <IconButton className={classes.largeIcon}>
                                        <ContactSupportOutlinedIcon htmlColor="white" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Contact Support
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default PWASellerDashboard;
