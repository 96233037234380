import {makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: 'auto',
        maxWidth: '100vw',
        background: theme.palette.common.white,
        '& .react-swipeable-view-container > div': {
            overflow: 'hidden',
        },
        position: 'relative',
    },
    pwaRoot: {
        minHeight: 'auto',
        maxWidth: '100vw',
        background: '#e5e5e5',
        '& .react-swipeable-view-container > div': {
            overflow: 'hidden',
        },
        position: 'relative',
    },
    carouselWrapper: {
        height: '100%',
    },
    carouselImgWrapper: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    carouselImgOverlay: {
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
    },
    headline: {
        color: theme.palette.common.white,
        fontStyle: 'italic',
        fontSize: '72px',
        fontWeight: 700,
        lineHeight: '72px',
        letterSpacing: '1px',
        margin: '16px auto 16px auto',
        textAlign: 'center',
        width: 756,
        [theme.breakpoints.down('md')]: {
            lineHeight: '40.67px',
            letterSpacing: '0.56px',
            fontSize: '40.67px',
            width: '320px',
        },
    },
    image: {
        background: 'linear-gradient(rgba(24, 32, 38, 0), rgba(24, 32, 38, 1))',
        width: '100%',
        minWidth: '100%',
        objectFit: 'cover',
        height: 'auto',
        zIndex: -1,
        top: 0,
    },
    dotActive: {
        background: theme.palette.common.white,
    },
    stepper: {
        background: 'transparent',
        justifyContent: 'center',
        marginTop: 21,
        position: 'absolute',
        bottom: 6,
        left: '50%',
        transform: 'translateX(-50%)',
    },
    carouselBtn: {
        marginBottom: 20,
    },
}));
