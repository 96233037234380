import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import SellIcon from '../../../../../public/img/sell_black_24dp.svg';
import ListIcon from '../../../../../public/img/list_alt_black_24dp.svg';
import StatsIcon from '../../../../../public/img/show_chart_black_24dp.svg';

const baseStyles = makeStyles(() => ({
    mobileNavWrapper: {
        padding: '0px 8px 4px',
    },
    optionsWrapper: {
        textAlign: 'center',
        borderBottom: 'solid 3px #DAE0E6',
        marginBottom: '12px',
        paddingTop: '12px !important',
    },
    optionsWrapperActive: {
        textAlign: 'center',
        borderBottom: 'solid 3px #41474D',
        marginBottom: '12px',
        paddingTop: '12px !important',
    },
    imageActive: {
        opacity: '1',
    },
    imageInactive: {
        opacity: '0.4',
    },
    imageFlipHorizontally: {
        transform: 'scaleX(-1)',
    },
    iconFont: {
        fontSize: '10px',
    },
    iconFontBold: {
        fontSize: '10px',
        fontWeight: 'bolder',
    },
}));

const ProductPageMobileNav = () => {
    const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const classes = baseStyles();

    useEffect(() => {
        dispatch({type: constants.NAVIGATE_MOBILE_SELL});
    }, [dispatch]);

    return (
        <Grid container spacing={2} className={classes.mobileNavWrapper}>
            <Grid
                item
                xs={4}
                className={marketplaceState.isMobileSellActive ? classes.optionsWrapperActive : classes.optionsWrapper}
                onClick={() => dispatch({type: constants.NAVIGATE_MOBILE_SELL})}
            >
                <div className={classes.imageFlipHorizontally}>
                    <img
                        src={SellIcon}
                        className={marketplaceState.isMobileSellActive ? classes.imageActive : classes.imageInactive}
                        alt="Listings"
                    />
                </div>
                <BSCTypography
                    variant="body2"
                    align="center"
                    className={marketplaceState.isMobileSellActive ? classes.iconFontBold : classes.iconFont}
                    label="Listings"
                />
            </Grid>
            <Grid
                item
                xs={4}
                className={marketplaceState.isMobileListActive ? classes.optionsWrapperActive : classes.optionsWrapper}
                onClick={() => dispatch({type: constants.NAVIGATE_MOBILE_LIST})}
            >
                <img
                    src={ListIcon}
                    className={marketplaceState.isMobileListActive ? classes.imageActive : classes.imageInactive}
                    alt="Details"
                />
                <BSCTypography
                    variant="body2"
                    align="center"
                    className={marketplaceState.isMobileListActive ? classes.iconFontBold : classes.iconFont}
                    label="Details"
                />
            </Grid>
            <Grid
                item
                xs={4}
                className={marketplaceState.isMobileHistoryActive ? classes.optionsWrapperActive : classes.optionsWrapper}
                onClick={() => dispatch({type: constants.NAVIGATE_MOBILE_HISTORY})}
            >
                <img
                    src={StatsIcon}
                    className={marketplaceState.isMobileHistoryActive ? classes.imageActive : classes.imageInactive}
                    alt="Past Sales"
                />
                <BSCTypography
                    variant="body2"
                    align="center"
                    className={marketplaceState.isMobileHistoryActive ? classes.iconFontBold : classes.iconFont}
                    label="Past Sales"
                />
            </Grid>
        </Grid>
    );
};

export default ProductPageMobileNav;
