import React from 'react';
import {useHistory} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: 4,
        padding: 0,
        whiteSpace: 'inherit',
        boxShadow: '0px 4px 4px #b8c2cc',
        '& div': {
            display: 'flex',
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    hint: {
        fontSize: 10,
        color: '#182026B2',
    },
    content: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
    },
    transparent: {
        opacity: 0,
    },
}));

const SellerDetail = ({value, title, hint, url}) => {
    const classes = useStyles();
    const {isMobile, isTablet} = useScreenSnap();
    const history = useHistory();

    return (
        <MenuItem
            className={classes.root}
            onClick={() => {
                if (url) history.push({pathname: url});
                else
                    history.push({
                        pathname: '/sellers/sales-summary',
                        state: {
                            search: 'today',
                        },
                    });
            }}
        >
            <BSCImage src={'/img/sell-cards-img.svg'} className={classes.transparent} />
            <div className={classes.content}>
                <BSCTypography variant={isMobile || isTablet ? 'h3' : 'h2'} label={value} size20={isMobile} />
                <BSCTypography variant={isMobile || isTablet ? 'subtitle1' : 'body1'} label={title} size12={isMobile} />
                <BSCTypography className={classes.hint} label={hint} />
            </div>
        </MenuItem>
    );
};

export default SellerDetail;
