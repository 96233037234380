import {makeStyles, Theme} from '@material-ui/core/styles';
import {useHistory, useLocation} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import Box from '@material-ui/core/Box';
import BSCGridTableRow from '../../../../features/common/bscGridTableRow/bscGridTableRow';
import CheckoutCard from './checkoutCard';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import useReduxCart from '../../../../features/hooks/redux/cart/useReduxCart';
import BSCBack from '../../../../features/common/bscBack/bscBack';
import {Card, CartItem} from '../../../../client/openapitools/marketplace';
import {BSCSuccessAlert, BSCWarningAlert} from '../../../../features/common/bscAlert/bscAlert';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import {useAuthFunctions} from '../../../../AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
    cartSummaryContainer: theme.containerWraps.page,
    boxWrap: {
        display: 'flex',
        width: '100%',
    },
    priceLeft: {
        fontSize: theme.typography.h3.fontSize,
    },
    buttonFont: {
        fontSize: '14px',
    },
    alert: {
        marginBottom: theme.spacing(2),
    },
    summaryTitle: {
        fontSize: '20px',
        //fontStyle: 'italic',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.body2.fontSize,
        },
    },
    columnReverse: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    displayNone: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    totalStyle: {
        fontSize: '16px!important' as '16px',
        fontWeight: 400,
        color: 'black!important' as 'black',
    },
}));

interface LocationState {
    state: {itemsInCart: CartItem; productInfo: Card};
}

function CartSummary() {
    const classes = useStyles();
    const {state}: LocationState = useLocation();
    const itemsInCart = state?.itemsInCart;
    const cartItem = state?.itemsInCart;
    const productInfo = state?.productInfo;
    const {cartState} = useReduxCart();
    const {isAuth} = useReduxUser();
    const {login} = useAuthFunctions();
    const history = useHistory();
    if (!itemsInCart) {
        history.replace('/cart/edit');
        return <></>;
    }

    return (
        <div className={classes.cartSummaryContainer}>
            <div className={classes.displayNone}>
                <BSCBack label="Back" />
            </div>
            <Grid container spacing={2} className={classes.columnReverse}>
                <Grid container item xs={12} md={8}>
                    <CheckoutCard productInfo={productInfo} cartItem={cartItem} />
                </Grid>
                <Grid container item xs={12} md={4}>
                    <BSCCardBox className={classes.boxWrap}>
                        <Grid container direction="column" justifyContent="space-between">
                            <div>
                                <Box>
                                    <BSCTypography className={classes.summaryTitle} capitalize label="Cart Summary" />
                                    <BSCDivider />
                                    <BSCGridTableRow
                                        gutterBottom
                                        col8={'Number of Item(s):'}
                                        className={classes.totalStyle}
                                        col4={cartState.noOfItems}
                                    />
                                    <BSCGridTableRow
                                        gutterBottom
                                        className={classes.totalStyle}
                                        col8={'Subtotal:'}
                                        col4={<BSCCurrency amount={cartState.price} className={classes.totalStyle} />}
                                    />
                                </Box>
                                {cartState?.messages?.find(x => x.productId === itemsInCart?.productId) ? (
                                    <BSCWarningAlert
                                        className={classes.alert}
                                        message={cartState?.messages?.find(x => x.productId === itemsInCart?.productId).description}
                                    />
                                ) : (
                                    <BSCSuccessAlert className={classes.alert} message={'Successfully added Item to cart'} />
                                )}
                            </div>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {isAuth && (
                                        <BSCButton
                                            fullWidth
                                            color="primary"
                                            className={classes.buttonFont}
                                            component={RouterLink}
                                            to="/cart/checkout"
                                        >
                                            Check Out
                                        </BSCButton>
                                    )}
                                    {!isAuth && (
                                        <BSCButton
                                            fullWidth
                                            color="primary"
                                            className={classes.buttonFont}
                                            onClick={() => login('/cart/checkout')}
                                        >
                                            Sign in to Check Out
                                        </BSCButton>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <BSCButton fullWidth className={classes.buttonFont} component={RouterLink} to="/cart/edit">
                                        Edit Cart
                                    </BSCButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BSCCardBox>
                </Grid>
            </Grid>
        </div>
    );
}

export default CartSummary;
