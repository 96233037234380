import {useEffect} from 'react';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    padding: {
        //padding: theme.spacing(1, 3),
        //overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '15px',
        },
    },
    fontWeight: {
        fontWeight: 400,
    },
    heading: {
        textAlign: 'center',
        marginTop: '20px',
    },
    textCenter: {
        textAlign: 'center',
    },
}));

export default function AboutUs() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const classes = useStyles();
    useEffect(() => {
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
        window.scrollTo(0, 0);
    }, []);
    const {isMobile} = useScreenSnap();

    return (
        <>
            <BSCContainer variant="page">
                <Grid container>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.heading}>
                        <BSCTypography variant="h3" className={classes.fontWeight}>
                            Introduction to BSC
                        </BSCTypography>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.textCenter}>
                        <iframe
                            width={isMobile ? '260px' : '640px'}
                            height={isMobile ? '130px' : '380px'}
                            src="https://www.youtube.com/embed/Io28bPKmTms"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.heading}>
                        <BSCTypography variant="h3" className={classes.fontWeight}>
                            0% Seller Fees?
                        </BSCTypography>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.textCenter}>
                        <iframe
                            width={isMobile ? '260px' : '640px'}
                            height={isMobile ? '130px' : '380px'}
                            src="https://www.youtube.com/embed/rcK7rxnvQDk"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.heading}>
                        <BSCTypography variant="h3" className={classes.fontWeight}>
                            Bank Information Needed?
                        </BSCTypography>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.textCenter}>
                        <iframe
                            width={isMobile ? '260px' : '640px'}
                            height={isMobile ? '130px' : '380px'}
                            src="https://www.youtube.com/embed/r2hPrdkcVzU"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.heading}>
                        <BSCTypography variant="h3" className={classes.fontWeight}>
                            About Our Team
                        </BSCTypography>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.textCenter}>
                        <iframe
                            width={isMobile ? '260px' : '640px'}
                            height={isMobile ? '130px' : '380px'}
                            src="https://www.youtube.com/embed/l1ys58XeXc8"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.heading}>
                        <BSCTypography variant="h3" className={classes.fontWeight}>
                            Built for Sports Cards
                        </BSCTypography>
                    </Grid>
                    <Grid item xs={12} alignContent="center" justifyContent="center" className={classes.textCenter}>
                        <iframe
                            width={isMobile ? '260px' : '640px'}
                            height={isMobile ? '130px' : '380px'}
                            src="https://www.youtube.com/embed/fEhEyGhkWuk"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Grid>
                </Grid>
            </BSCContainer>
        </>
    );
}
