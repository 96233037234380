import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect, useParams} from 'react-router';
import * as constants from '../../../../shared/constants';

const Seller = () => {
    const dispatch = useDispatch();
    const {sellerId} = useParams<{sellerId: string}>();
    const {sellerName} = useParams<{sellerName: string}>();
    useEffect(() => {
        dispatch({
            type: constants.SELLER_STOREFRONT_SELECTED,
            payload: {sellerId: sellerId, sellerName: sellerName},
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // http://localhost:3001/Seller/2281c169e5/Storename
    return <Redirect to="../../search?inStock=true&amp;p=0" />;
};

export default Seller;
