import React, {useState, useCallback, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid/Grid';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCSelect from '../../../../../../../features/common/bscSelect/bscSelect';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import FormValidationsClient from '../../../../../../../client/s3/formValidations/formValidationsClient';
import useForm from '../../../../../../../packages/core/src/hooks/useForm';
import FormValidationsNormalizer from '../../../../../../../client/s3/formValidations/formValidationsNormalizer';
import BSCTextField from '../../../../../../../features/common/bscTextField/bscTextField';
import {getNameList, removeFile} from '../../../../../../../packages/core/src/util/util';
import FileNameList from './fileNameList';
import {useQuery} from '../../../../../../../packages/core/src/hooks/useQuery';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import BSCHeaderBlock from '../../../../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import {MessageReference} from '../../../../../../../client/openapitools/common';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        background: '#F5F8FA',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '4px',
        padding: theme.spacing(1.5, 2),
        marginBottom: theme.spacing(2.75),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        },
    },
    menu: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
    },
    content: {
        background: '#F5F8FA',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '8px',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        marginBottom: 16,
        '& > MuiSelect-icon': {
            color: '#EA3E2E !important',
        },
    },
    select: {
        width: '100%',
        background: theme.palette.common.white,
        border: '1px solid #B8C2CC',
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        '& .MuiFilledInput-underline:after': {
            borderBottom: 'none',
        },
    },
    textfield: {
        fontFamily: 'Poppins',
        background: theme.palette.common.white,
        '& textarea': {
            minHeight: '100px',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.subtitle2.fontSize,
            },
        },
    },
    optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
    },
    hidden: {
        position: 'absolute',
        opacity: '0',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    uppperCase: {
        textTransform: 'uppercase',
    },
    pointer: {
        cursor: 'pointer',
    },
    formControl: {
        minWidth: 74,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #DAE0E6',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        borderRadius: '4px',
        marginLeft: theme.spacing(1),
    },
    pageSizeSelectForm: {
        minWidth: '100% !important',
        height: '48px',
        opacity: '0',
        overflow: 'hidden',
        margin: '0',
        position: 'absolute',
    },
    pageSizeLabel: {
        display: 'flex',
        height: '48px',
        alignItems: 'center',
    },
    attachedFileListWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    win: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    mobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    buttons: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    weight400: {
        fontWeight: 400,
    },
    paddingBottom: {
        paddingBottom: '10px',
    },
    subjectError: {
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
}));

interface SendMessagePanel {
    messageRef: MessageReference;
    isCreateMessage: boolean;
    onSubmit: (formState, attachedFiles) => void;
    loading: boolean;
    subjectKey?: string | null;
}

function SendMessagePanel({messageRef, isCreateMessage, onSubmit, loading, subjectKey = null}: SendMessagePanel) {
    const classes = useStyles();
    const [attachedFiles, setAttachedFiles] = useState<File[]>(null);
    const [subjectError, setSubjectError] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {loading: formValidationsLoading, data: formValidations} = useQuery(() => FormValidationsClient.getFormValidations(), []);
    const {
        formState,
        validatedSchema: formStateValidations,
        isErrors: isFormErrors,
        registerFormChange,
        registerFormBlur,
        formComplete,
        setDefaultValues,
    } = useForm({
        validationSchema: FormValidationsNormalizer.getConversationValidationsSchema(formValidations),
    });

    useEffect(() => {
        setDefaultValues({
            subject: subjectKey,
        });
    }, [subjectKey, messageRef, setDefaultValues]);

    const handleUploadChange = useCallback(
        event => {
            if (attachedFiles === event.target.files) return;
            setAttachedFiles([...event.target.files]);
        },
        [attachedFiles]
    );

    const handleRemovingFiles = useCallback(name => {
        setAttachedFiles(prevFileList => removeFile(name, prevFileList));
    }, []);

    useEffect(() => {
        if (formState.subject === 'default' && formState.reply && isCreateMessage === true) {
            setSubjectError(true);
        } else {
            setSubjectError(false);
        }
    }, [formState]);

    const handleSubmit = () => {
        onSubmit(formState, attachedFiles);

        if (!isCreateMessage) {
            setDefaultValues({
                ...formState,
                reply: '',
            });
            setAttachedFiles(null);
        }
    };

    return (
        <Grid item justifyContent="space-between" spacing={3}>
            <BSCLoading loading={!messageRef} />
            {messageRef && (
                <div>
                    <BSCHeaderBlock label={isCreateMessage ? 'Create Message' : 'Reply'} />
                    <BSCCardBox className={classes.content}>
                        {subjectError && (
                            <BSCTypography className={classes.subjectError} size14 label={'Please select a Subject for your message.'} />
                        )}
                        {isCreateMessage && (
                            <BSCSelect
                                className={classes.select}
                                formStateValidations={formStateValidations}
                                inputKey="subject"
                                onInputChange={registerFormChange}
                                disabled={!isCreateMessage}
                            >
                                <MenuItem value="default" disabled>
                                    Select subject
                                </MenuItem>
                                {messageRef?.subjectTypes?.map(subject => (
                                    <MenuItem value={subject.key} key={subject.key} selected={subject.key === formState.subject}>
                                        {subject.name}
                                    </MenuItem>
                                ))}
                            </BSCSelect>
                        )}
                        {!isCreateMessage && messageRef && (
                            <BSCTypography
                                align="left"
                                className={classes.paddingBottom}
                                label={`Subject: ${
                                    messageRef.subjectTypes.find(item => {
                                        return item.key === subjectKey ?? formState.subject;
                                    })?.name
                                }`}
                            />
                        )}
                        <BSCTextField
                            onBlur={registerFormBlur}
                            formStateValidations={formStateValidations}
                            inputKey="reply"
                            fullWidth
                            multiline
                            onChange={registerFormChange}
                            className={classes.textfield}
                        />
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid lg={6} item container alignItems="center">
                                <div className={classes.attachedFileListWrapper}>
                                    {attachedFiles && (
                                        <FileNameList nameList={getNameList(attachedFiles)} removeFile={handleRemovingFiles} />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <BSCDivider />
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <BSCButton
                                    fullWidth
                                    color="secondary"
                                    containerElement="label"
                                    onClick={() => {
                                        document.getElementById('attachmentInput').click();
                                    }}
                                >
                                    <BSCTypography label="Add Attachment" capitalize size14 />
                                </BSCButton>
                                <input type="file" multiple onChange={handleUploadChange} id="attachmentInput" className={classes.hidden} />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <BSCButton
                                    fullWidth
                                    color="primary"
                                    onClick={handleSubmit}
                                    type="button"
                                    loading={loading}
                                    disabled={!formComplete || isFormErrors || subjectError}
                                >
                                    <BSCTypography label="Send" capitalize size14 />
                                </BSCButton>
                            </Grid>
                        </Grid>
                    </BSCCardBox>
                </div>
            )}
        </Grid>
    );
}

export default SendMessagePanel;
