import {makeStyles, Theme} from '@material-ui/core/styles';
import {ReactNode} from 'react';
import {BSCTypographyProps} from '../../../@types/features/common/bscTypography';
import BSCCardBox from '../bscCardBox/bscCardBox';
import BSCTypography from '../bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        borderRadius: '2px 2px 0px 0',
        background: theme.palette.common.white,
        border: '1px solid #DAE0E6',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        // margin: theme.spacing(-2, -2, 2.25, -2),
        padding: theme.spacing(1.5),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
}));

interface BSCHeaderBlockProps {
    label: string | ReactNode;
    align?: BSCTypographyProps['align'];
    size20?: boolean;
    rightButtons?: ReactNode;
    hasGrid?: boolean;
    hasCustomRadius?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    otherProps?: undefined;
}

function BSCHeaderBlock({
    label,
    align = 'center',
    rightButtons,
    hasGrid = true,
    hasCustomRadius = false,
    ...otherProps
}: BSCHeaderBlockProps) {
    const classes = useStyles();
    return (
        <BSCCardBox className={classes.header} style={hasCustomRadius ? {borderRadius: '4px 4px 0px 0px !important'} : {}}>
            <div
                style={
                    hasGrid
                        ? {display: 'grid', gridTemplateColumns: '1fr 3fr 1fr', gridTemplateAreas: '"empty text buttons"'}
                        : {display: 'block'}
                }
            >
                <BSCTypography align={align} label={label} variant="body2" style={{gridArea: 'text'}} {...otherProps} />

                <div style={{justifySelf: 'end', gridArea: 'buttons'}}>{rightButtons}</div>
            </div>
        </BSCCardBox>
    );
}

export default BSCHeaderBlock;
