import {CircularProgress, makeStyles, Theme} from '@material-ui/core';
import MUIButton from '@material-ui/core/Button';
import classNames from 'classnames';
import {BSCButtonProps} from '../../../@types/features/common/bscButton';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        padding: theme.spacing(1, 2),
        fontSize: theme.typography.body2.fontSize,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.24)',
        fontStyle: 'none',
        textTransform: 'none', // removes uppercase transformation
    },
    textButton: {
        fontSize: theme.typography.body2.fontSize,
    },
    gutterBottom: {
        marginBottom: theme.spacing(2),
    },
    fullWidth: {
        width: '100%',
    },
    white: {
        color: theme.palette.common.white,
    },
    fullHeight: {
        height: '100%',
    },
}));

function BSCButton({
    children,
    color,
    variant = 'contained',
    className,
    gutterBottom = false,
    fullWidth = false,
    fullHeight = false,
    loading = false,
    disabled,
    ...otherProps
}: BSCButtonProps) {
    const classes = useStyles();
    return (
        <MUIButton
            {...otherProps}
            // endIcon={}
            disabled={loading || disabled}
            classes={{contained: classes.button, text: classes.textButton}}
            className={classNames(className, {
                [classes.gutterBottom]: gutterBottom,
                [classes.fullWidth]: fullWidth,
                [classes.fullHeight]: fullHeight,
            })}
            variant={variant}
            color={color}
        >
            {loading ? <CircularProgress size={30} className={classes.white} /> : children}
        </MUIButton>
    );
}

export default BSCButton;
