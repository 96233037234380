import Alert from '@material-ui/lab/Alert';
import BSCTypography from '../bscTypography/bscTypography';
interface BSCErrorBannerProps {
    errors: Error;
}

interface AlertProps {
    message: string;
    filled?: boolean;
}

export const BSCInfoAlert = ({message, filled, ...otherProps}: AlertProps) => {
    return (
        <Alert {...otherProps} severity="info" variant={filled ? 'filled' : 'standard'}>
            <BSCTypography>{message}</BSCTypography>
        </Alert>
    );
};

export const BSCSuccessAlert = ({message, filled, ...otherProps}: AlertProps) => {
    return (
        <Alert {...otherProps} severity="success" variant={filled ? 'filled' : 'standard'}>
            <BSCTypography>{message}</BSCTypography>
        </Alert>
    );
};

export const BSCWarningAlert = ({message, filled, ...otherProps}: AlertProps) => {
    return (
        <Alert {...otherProps} severity="warning" variant={filled ? 'filled' : 'standard'}>
            <BSCTypography>{message}</BSCTypography>
        </Alert>
    );
};

export const BSCErrorAlert = ({message, filled, ...otherProps}: AlertProps) => {
    return (
        <Alert {...otherProps} severity="error" variant={filled ? 'filled' : 'standard'}>
            <BSCTypography>{message}</BSCTypography>
        </Alert>
    );
};

function BSCErrorBanner({errors}: BSCErrorBannerProps) {
    return (
        <Alert severity="error">
            <BSCTypography>{errors.message}</BSCTypography>
        </Alert>
    );
}

export default BSCErrorBanner;
