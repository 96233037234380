import React from 'react';
import BSCGridTableRow from '../../../../../features/common/bscGridTableRow/bscGridTableRow';
import {Link} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import {OrderApi, Shipment} from '../../../../../client/openapitools/seller';
import useDateTimeNormalizer from '../../../../../client/common/normalizer/dateTimeNormalizer';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';
import moment from 'moment';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {Configuration} from '../../../../../client/openapitools/common/configuration';
import {AUTH_REQUESTS} from '../../../../../authConfig';

function ShippingDetails({
    orderId,
    shippingDetails,
    refreshOrder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handlePrintLabel,
}: {
    orderId: string;
    shippingDetails: Shipment;
    refreshOrder?: () => void;
    handlePrintLabel?: () => void;
}) {
    const packageDetails = shippingDetails?.shippingLabel;
    const normalizer = useDateTimeNormalizer();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const allowVoid =
        shippingDetails?.bscShipped &&
        shippingDetails?.shippedDate &&
        shippingDetails?.shippingLabel?.service_code !== 'BSC Standard Envelope' &&
        moment(new Date()).isBefore(moment(shippingDetails.shippedDate).add(2, 'day'));

    const voidShippingLabel = shippingLabelId => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const orderApi = new OrderApi(apiConfig);
                            orderApi.voidLabel(orderId, shippingLabelId).then(response => {
                                if (response.data) {
                                    addToast({
                                        severity: response?.data?.approved ? 'success' : 'error',
                                        message: response?.data?.message,
                                    });
                                    refreshOrder();
                                }
                            });
                        } catch (error) {
                            console.error(error);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <BSCGridTableRow
                    col4={<BSCTypography label="Date:" />}
                    col8={<BSCTypography label={normalizer.getDisplayDate(shippingDetails?.shippedDate)} />}
                    flip
                />
                <BSCGridTableRow
                    col4={<BSCTypography label="Confirmation:" />}
                    col8={<BSCTypography label={packageDetails?.packageOptions?.confirmation} />}
                    flip
                />
                <BSCGridTableRow
                    col4={<BSCTypography label="Insurance:" />}
                    col8={<BSCTypography label={packageDetails?.packageOptions?.insurance ? 'Yes' : 'No'} />}
                    flip
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {!packageDetails?.tracking_number && (
                    <BSCGridTableRow col4={<BSCTypography label="Tracking #:" />} col8={<BSCTypography label="No Tracking" />} flip />
                )}
                {packageDetails?.tracking_number && (
                    <BSCGridTableRow
                        col4={<BSCTypography label="Tracking #:" />}
                        col8={
                            <Link href={`https://parcelsapp.com/en/tracking/${packageDetails?.tracking_number}`} target="_blank">
                                <BSCTypography label={packageDetails?.tracking_number} bold />
                            </Link>
                        }
                        flip
                    />
                )}
                <BSCGridTableRow
                    col4={<BSCTypography label="Service:" />}
                    col8={<BSCTypography label={packageDetails?.service_code} />}
                    flip
                />
                <BSCGridTableRow
                    col4={<BSCTypography label="Weight:" />}
                    col8={
                        <BSCTypography
                            label={`${packageDetails?.packageOptions?.weight?.value} ${packageDetails?.packageOptions?.weight?.unit}`}
                        />
                    }
                    flip
                />
            </Grid>
            {shippingDetails?.shippingLabel?.label_download?.href && (
                <>
                    <Grid item xs={12} container justifyContent="center" spacing={2}>
                        <Grid item>
                            {allowVoid && (
                                <BSCButton onClick={() => voidShippingLabel(shippingDetails.shippingLabel.label_id)} color="primary">
                                    <BSCTypography label="Void Label" size12 />
                                </BSCButton>
                            )}
                        </Grid>
                        <Grid item>
                            <BSCButton onClick={handlePrintLabel} color="secondary">
                                <BSCTypography label="Print Label" size12 />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default ShippingDetails;
