/* eslint-disable */
import {FormControlLabel, ModalProps, Slide} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {TransitionProps} from '@material-ui/core/transitions';
import React, {useEffect, useState} from 'react';
import BSCRadio from '../../../../features/common/bscRadio/bscRadio';
import BSCRadioGroup from '../../../../features/common/bscRadioGroup/bscRadioGroup';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';

interface PWASearchSortComponentProps extends Omit<ModalProps, 'children'> {}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        zIndex: 1,
        position: 'relative',
        margin: theme.spacing(1),
    },
    dialogPaper: {
        height: 504,
        width: '100%',
        margin: 0,
        borderRadius: '32px 32px 0 0',
        position: 'fixed',
        bottom: 0,
        overflowX: 'hidden',
    },
    sortOptionsContent: {
        padding: '0 !important',
    },
    sortOption: {
        borderBottom: '2px solid rgb(230, 230, 230)',
        width: '100%',
        height: 48,
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 16,
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {children?: React.ReactElement<undefined>},
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PWASearchSortComponent({open, onSortChange, currentSortOption, onClose = () => {}}: PWASearchSortComponentProps) {
    const classes = useStyles();
    const [sortOptionValue, setSortOptionValue] = useState('best-sellers');
    const handleSortOptionChange = e => {
        setSortOptionValue(e.target.value);
        onSortChange(e.target.value);
        onClose({}, 'backdropClick');
    };

    useEffect(() => {
        if (currentSortOption) {
            setSortOptionValue(currentSortOption);
        }
    }, [currentSortOption]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} PaperProps={{className: classes.dialogPaper}}>
            <DialogTitle style={{padding: '8px 0', borderBottom: '2px solid #E0E0E0'}}>
                <BSCTypography size24 label="Sort By:" style={{fontWeight: 600, textAlign: 'center'}} />
            </DialogTitle>
            <DialogContent className={classes.sortOptionsContent}>
                <BSCRadioGroup
                    value={sortOptionValue}
                    start
                    noLabel={true}
                    style={{alignItems: 'start', flexDirection: 'column', width: '100%', margin: 0}}
                    hasFullWidth={true}
                    onChange={e => {
                        handleSortOptionChange(e);
                    }}
                >
                    <FormControlLabel
                        className={classes.sortOption}
                        value="best-sellers"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Relevance
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="recently-added"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Recently Added
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="raw-price-hi-lo"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Price: Hi to Low
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="raw-price-lo-hi"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Price: Low to Hi
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="card-no-1-z"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Card #
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="newest"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Newest
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="oldest"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Oldest
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="a-z"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                A to Z
                            </BSCTypography>
                        }
                    />
                    <FormControlLabel
                        className={classes.sortOption}
                        value="z-a"
                        control={<BSCRadio />}
                        label={
                            <BSCTypography size16 bold>
                                Z to A
                            </BSCTypography>
                        }
                    />
                </BSCRadioGroup>
            </DialogContent>
        </Dialog>
    );
}

export default PWASearchSortComponent;
