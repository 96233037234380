/* eslint-disable */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthState} from '../../../../AuthContext';
import {RecentSearchControllerApi, SavedSearchControllerApi} from '../../../../client/openapitools/common/api';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import * as constants from '../../../../shared/constants';
import SearchDashboardList from '../search/SearchDashboard/SearchDashboardList';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: undefined;
    value: undefined;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: undefined) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        height: 'calc(100% - 100px)',
    },
    capitalizeTabs: {
        textTransform: 'none !important',
    },
    noPad: {
        padding: 'none !important',
    },
}));

function PWASearchDashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const {apiAuthConfig} = useAuthState();
    const {getCartSessionId, sessionId} = useReduxUser();
    const {instance} = useMsal();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const [recentSearchResults, setRecentSearchResults] = useState([]);
    const [savedSearchResults, setSavedSearchResults] = useState([]);
    useEffect(() => {
        dispatch({
            type: constants.PWA_SPORTS_CHIPS,
            payload: {
                isSportsChipsVisible: false,
            },
        });
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 2,
            },
        });
        const fetchData = async () => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                const recentSearchApi = new RecentSearchControllerApi(apiConfig);
                                const recentSearchResults = await recentSearchApi.getAllRecentSearchByUserId();
                                if (recentSearchResults.status === 200) {
                                    setRecentSearchResults(recentSearchResults.data);
                                }
                                const savedSearchApi = new SavedSearchControllerApi(apiConfig);
                                const savedSearchResults = await savedSearchApi.getAllSavedSearchByUserId();
                                if (savedSearchResults.status === 200) {
                                    setSavedSearchResults(savedSearchResults.data);
                                }
                            } catch (error) {}
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user', error);
                    });
            } else {
                const sessionId = getCartSessionId();
                const recentSearchApi = new RecentSearchControllerApi();
                const recentSearchResults = await recentSearchApi.getAllRecentSearchBySessionId(sessionId);
                if (recentSearchResults.status === 200) {
                    setRecentSearchResults(recentSearchResults.data);
                }
            }
        };
        fetchData();
    }, []);

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="full width tabs example"
                >
                    <Tab
                        label="Recent"
                        {...a11yProps(0)}
                        className={classes.capitalizeTabs}
                        style={value === 0 ? {fontWeight: 'bolder'} : {fontWeight: 400}}
                    />
                    <Tab
                        label="Saved"
                        {...a11yProps(1)}
                        className={classes.capitalizeTabs}
                        style={value === 1 ? {fontWeight: 'bolder'} : {fontWeight: 400}}
                    />
                </Tabs>
            </AppBar>
            <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <SearchDashboardList listings={recentSearchResults} view={0} />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <SearchDashboardList listings={savedSearchResults} view={1} />
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}

export default PWASearchDashboard;
