import {OrderHistoryResponse} from '../../../openapitools/marketplace/api';
import Normalizer from '../../../common/normalizer/normalizer';
const OrderHistoryNormalizer = {
    getFilteredOrderHistory: (orderHistory, search) =>
        orderHistory.filter(
            order =>
                order.orderId.toLowerCase().includes(search.toLowerCase()) || order.sellerName.toLowerCase().includes(search.toLowerCase())
        ),
    getPagedAndFilteredHistory: (orderHistory, page, sizePerPage, search) => {
        const pagedHistory = Normalizer.getPagedResult(orderHistory, page, sizePerPage);
        return pagedHistory ? OrderHistoryNormalizer.getFilteredOrderHistory(pagedHistory, search) : null;
    },
    getOrders: (orderHistory): OrderHistoryResponse => orderHistory?.results,
    getTotalOrderNum: (orderHistory): OrderHistoryResponse => orderHistory?.totalResults ?? 0,
    getPageIdxOfOrder: (nth, sizePerPage) => Math.floor(nth / sizePerPage),
};

export default OrderHistoryNormalizer;
