import React, {memo, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import SellersNavigationClient from '../../../client/sellers/navigation/SellersNavigationClient';
import {useQuery} from '../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../redux/reducers';
import DesktopNav from './desktopNav/sellersDesktopNav';
import MobileNav from './mobileNav/sellersMobileNav';
import SellersPWANav from './pwaNav/sellersPWANav';

function SellersLockerHeader() {
    const {isTablet, isMobile} = useScreenSnap();
    const history = useHistory();
    const isSellersSignup = useRouteMatch('/sellers/signup');
    const {loading: menusLoading, data: menus} = useQuery(() => SellersNavigationClient.getNav(), []);
    const {loading: userMenuLoading, data: userMenu} = useQuery(() => SellersNavigationClient.getUserMenus(), []);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    const handlePortalChange = () => {
        history.push('/');
    };

    return !isTablet && !isMobile ? (
        <DesktopNav
            menus={menus}
            menusLoading={menusLoading}
            userMenuLoading={userMenuLoading}
            userMenu={userMenu}
            onPortalChange={handlePortalChange}
        />
    ) : (
        <>
            {pwaOS !== null && !isSellersSignup && (
                <SellersPWANav menus={menus} menusLoading={menusLoading} userMenuLoading={userMenuLoading} userMenu={userMenu} />
            )}
            {pwaOS === null && !isSellersSignup && (
                <MobileNav
                    menus={menus}
                    menusLoading={menusLoading}
                    userMenuLoading={userMenuLoading}
                    userMenu={userMenu}
                    onPortalChange={handlePortalChange}
                />
            )}
        </>
    );
}

export default memo(SellersLockerHeader);
