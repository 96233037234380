import {makeStyles, Theme} from '@material-ui/core/styles';
import Box, {BoxProps} from '@material-ui/core/Box';
import {ReactNode} from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    cardWrap: {
        background: theme.palette.common.white,
        borderRadius: 4,
        boxShadow: '0px 0px 4px #B8C2CC',
        width: '100%',
        boxSizing: 'border-box',
    },
}));

interface BSCCardBoxProps extends BoxProps {
    children: ReactNode;
    className?: string;
}

function BSCCardBox({children, className = null, p = 2, ...otherProps}: BSCCardBoxProps) {
    const classes = useStyles();
    return (
        <Box p={p} {...otherProps} className={classNames(classes.cardWrap, className)}>
            {children && <>{children}</>}
        </Box>
    );
}

export default BSCCardBox;
