import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import BSCTypography from '../features/common/bscTypography/bscTypography';
import BSCDivider from '../features/common/bscDivider/bscDivider';
//import BSCImage from '../features/common/bscImage/bscImage';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles((theme: Theme) => ({
    groupTitle: {
        color: theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 400,
    },
    linkWrapper: {
        padding: 0,
    },
    divider: {
        backgroundColor: '#41474D',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
    },
    whiteText: {
        color: 'white',
    },
}));

const SocialLinkIconGroup = ({title, links}) => {
    const classes = useStyles();
    return (
        <div>
            <BSCTypography className={classes.groupTitle} label={title} variant="body2" />
            <BSCDivider className={classes.divider} />
            <Grid container spacing={1}>
                {links?.map(link => {
                    return (
                        <Grid item key={link.url}>
                            <Link href={link.url} target="_blank">
                                <>
                                    {link?.title?.toLowerCase() === 'facebook' && <FacebookIcon className={classes.whiteText} />}
                                    {link?.title?.toLowerCase() === 'instagram' && <InstagramIcon className={classes.whiteText} />}
                                    {link?.title?.toLowerCase() === 'twitter' && <TwitterIcon className={classes.whiteText} />}
                                    {link?.title?.toLowerCase() === 'youtube' && <YouTubeIcon className={classes.whiteText} />}
                                </>
                                {/* <BSCImage src={'/img/' + link?.title?.toLowerCase() + '.png'} /> */}
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default SocialLinkIconGroup;
