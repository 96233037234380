import {Grid, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {useState} from 'react';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import BSCModal from '../../../../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCRatingField from '../../../../../../../features/common/bscRatingField/bscRating';

const useStyles = makeStyles((theme: Theme) => ({
    padding: {
        paddingBottom: theme.spacing(2),
    },
    xLargeFont: {
        fontSize: 'xx-large',
    },
    paddingBottom24: {
        paddingBottom: theme.spacing(3),
    },
    ratingSize: {
        fontSize: '3em',
    },
}));

const OrderFeedbackBody = ({rating, onRatingChange, comment, onCommentChange}) => {
    const classes = useStyles();
    const maxSize = 500;
    const handleCommentChange = e => {
        if (e.target.value?.length > 500) {
            return;
        }
        onCommentChange(e.target.value);
    };

    return (
        <>
            <Grid container align="center">
                <Grid item xs={12}>
                    <BSCTypography variant="body1" semibold label="Rate Seller" />
                </Grid>
            </Grid>
            <Grid container align="center">
                <Grid item xs={12} className={classes.padding}>
                    <BSCRatingField
                        value={rating ?? null}
                        name="rating"
                        className={classes.ratingSize}
                        onChange={(event, newValue) => onRatingChange(newValue)}
                    ></BSCRatingField>
                </Grid>
            </Grid>
            <Grid container align="center">
                <Grid item xs={12}>
                    <BSCTypography variant="body1" semibold label="Leave a comment" />
                </Grid>
            </Grid>
            <Grid container align="center">
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth multiline={true} value={comment} onChange={handleCommentChange} />
                </Grid>
            </Grid>
            <Grid container align="right" className={classes.paddingBottom24}>
                <Grid item xs={12}>
                    <BSCTypography label={`${comment?.length ?? 0} of ${maxSize}`} size10></BSCTypography>
                </Grid>
            </Grid>
        </>
    );
};
const OrderFeedbackFooter = ({onSubmit}) => {
    return (
        <BSCButton fullWidth color="primary" onClick={onSubmit}>
            <BSCTypography size14 variant="body1" label="Submit Feedback" />
        </BSCButton>
    );
};

const OrderFeedbackContent = ({order, onSubmit}) => {
    const [rating, setRating] = useState(order?.feedback?.rating);
    const [comment, setComment] = useState(order?.feedback?.comment);

    const handleSubmit = () => {
        onSubmit({rating: rating, comments: comment});
    };
    return (
        <div>
            <OrderFeedbackBody
                rating={rating}
                onRatingChange={setRating}
                comment={comment}
                onCommentChange={setComment}
            ></OrderFeedbackBody>
            <OrderFeedbackFooter onSubmit={handleSubmit}></OrderFeedbackFooter>
        </div>
    );
};

const OrderFeedbackModal = ({modalOpen, setModalOpen, order, onSubmit}) => {
    return (
        <>
            {modalOpen && (
                <BSCModal
                    headerLabel={`Order #: ${order.orderNo}`}
                    hasCustomHeaderStyling={true}
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                >
                    <OrderFeedbackContent order={order} onSubmit={onSubmit}></OrderFeedbackContent>
                </BSCModal>
            )}
        </>
    );
};

export default OrderFeedbackModal;
