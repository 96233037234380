import Normalizer from '../../common/normalizer/normalizer';

const SliderClient = {
    getSliderItems: async () => {
        const basePath = Normalizer.getS3ImagePath('/static/mp_sliders.json');
        const data = await fetch(basePath).then(res => res.json());
        return data;
    },
    // getSliderItems: async () => {
    //     const basePath = 'https://bsc-media-images.s3.us-east-1.amazonaws.com/static/mp_sliders.csv';
    //     const blob = await fetch(basePath).then(response => response.blob());
    //     const data = await new Response(blob).text();
    //     return data;
    // },
};

export default SliderClient;
