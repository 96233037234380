// TODO: Check this any later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const URLBuilder = (paramObject: {[key: string]: undefined}) => ({
    hasValueInParam: (paramKey: string, paramValue: string) => {
        const lowerCasedParamValue = paramValue.toLowerCase();
        return Array.isArray(paramObject?.[paramKey])
            ? paramObject[paramKey].includes(lowerCasedParamValue)
            : paramObject?.[paramKey]?.toLowerCase() === lowerCasedParamValue;
    },
    addToParam: (paramKey: string, paramValue: string) => {
        const shallowParamObjectCopy = {...paramObject};
        Array.isArray(shallowParamObjectCopy[paramKey])
            ? shallowParamObjectCopy[paramKey].push(paramValue)
            : (shallowParamObjectCopy[paramKey] = [paramValue]);

        return shallowParamObjectCopy;
    },
    removeParamItem: (paramKey: string) => {
        const shallowParamObjectCopy = {...paramObject};
        if (shallowParamObjectCopy[paramKey]) {
            delete shallowParamObjectCopy[paramKey];
        }

        return shallowParamObjectCopy;
    },
    removeParam: (paramKey: string, paramValue: string) => {
        const shallowParamObjectCopy = {...paramObject};
        if (Array.isArray(shallowParamObjectCopy[paramKey])) {
            shallowParamObjectCopy[paramKey] = shallowParamObjectCopy[paramKey].filter(
                (oldParamValue: string) => oldParamValue.toLowerCase() !== paramValue.toLowerCase()
            );
            if (shallowParamObjectCopy[paramKey].length === 0) delete shallowParamObjectCopy[paramKey];
            return shallowParamObjectCopy;
        }

        if (shallowParamObjectCopy[paramKey]) {
            delete shallowParamObjectCopy[paramKey];
        }

        return shallowParamObjectCopy;
    },
});
