import {makeStyles, Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
import {MenuItemProps} from '@material-ui/core/MenuItem';
import {ReactNode} from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    menuItem: {
        background: '#B8C2CC',
        color: '#000',
        borderRadius: 4,
        ['&:hover']: {
            // background: 'rgb(0 0 0 / 79%)',
            background: '#B8C2CC',
        },
        marginBottom: theme.spacing(1),
        paddingRight: `calc(25px + ${theme.spacing(2)}px)`,
    },
    arrowIcon: {
        position: 'absolute',
        right: 5,
    },
    arrowIconOpen: {
        transform: 'rotate(-180deg)',
    },
}));

export interface BSCAccordionProps extends MenuItemProps {
    label: string | ReactNode;
    open: boolean;
    onAccordionClick?: (event) => void;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    menuItemClasses?: undefined;
}

function BSCAccordion({label, open, onAccordionClick = null, menuItemClasses = [], ...otherProps}: BSCAccordionProps) {
    const classes = useStyles();
    return (
        <MenuItem {...otherProps} className={classNames(...menuItemClasses, classes.menuItem)} onClick={onAccordionClick}>
            {label}
            <KeyboardArrowDownIcon
                className={classNames(classes.arrowIcon, {
                    [classes.arrowIconOpen]: open,
                })}
            />
        </MenuItem>
    );
}

export default BSCAccordion;
