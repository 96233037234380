import {Grid, Modal, ModalProps, useTheme} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import BSCCardBox from '../bscCardBox/bscCardBox';
import BSCScroller from '../bscScroller/bscScroller';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useMemo} from 'react';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import BSCTypography from '../bscTypography/bscTypography';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {display: 'flex', justifyContent: 'center', alignItems: 'center'},
    cardBoxWrap: {
        width: `calc(100% - ${theme.spacing(4)}px)`,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    closeIcon: {
        marginLeft: 'auto',
        paddingBottom: '5px',
        paddingTop: '5px',
        fontSize: '1.5rem',
        padding: theme.spacing(1),
        '&:hover': {
            color: theme.palette.common.black,
        },
    },
    cardBox: {width: '100%'},
    cardBoxPrint: {width: '100%', boxShadow: 'none !important'},
    'size-sm': {maxWidth: 400},
    'size-md': {maxWidth: 550},
    'size-lg': {maxWidth: 900},
    'size-custom': {},
}));

interface BSCModalProps extends ModalProps {
    size?: 'sm' | 'md' | 'lg' | 'custom';
    headerLabel?: string;
    bscScrollerProps?;
    customSize?: string;
    hasCustomHeaderStyling?: boolean;
    isPlainForPrint?: boolean;
}
/**
 * For a custom size, pass size='custom' customSize='sizeForMaxWidth'
 * @param properties
 * @returns
 */
function BSCModal({
    open,
    onClose,
    headerLabel,
    children = null,
    size = 'md',
    customSize,
    hasCustomHeaderStyling = false,
    isPlainForPrint = false,
    bscScrollerProps = {},
}: BSCModalProps) {
    const classes = useStyles();
    const theme = useTheme();
    const {isMobile} = useScreenSnap();
    const styles = useMemo(() => {
        if (size !== 'custom') return {} as CSSProperties;
        return {maxWidth: customSize};
    }, []);

    return (
        <Modal className={classes.root} open={open} onClose={onClose}>
            <div className={classNames(classes.cardBoxWrap, classes[`size-${size}`])} style={styles}>
                <BSCCardBox
                    className={isPlainForPrint ? classes.cardBoxPrint : classes.cardBox}
                    style={hasCustomHeaderStyling ? {paddingLeft: 0, paddingRight: 0} : {}}
                >
                    {!isPlainForPrint && (
                        <Grid
                            container
                            alignItems="center"
                            style={
                                hasCustomHeaderStyling
                                    ? {paddingBottom: 12, boxShadow: 'inset 0px -8px 4px -2px #dae0e6', marginBottom: 24}
                                    : {}
                            }
                        >
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                {headerLabel && (
                                    <BSCTypography
                                        variant={hasCustomHeaderStyling ? 'h4' : 'h5'}
                                        center
                                        label={headerLabel}
                                    ></BSCTypography>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                container
                                justifyContent="flex-end"
                                style={hasCustomHeaderStyling ? {paddingRight: 16, paddingBottom: 8} : {}}
                            >
                                <IconButton
                                    className={classes.closeIcon}
                                    color="default"
                                    aria-label="close"
                                    component="span"
                                    onClick={onClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                    <BSCScroller maxHeight={isMobile ? 'calc(100vh - 110px)' : `calc(100vh - ${theme.spacing(4)}px)`} {...bscScrollerProps}>
                        <div style={hasCustomHeaderStyling ? {marginTop: 5, paddingLeft: 16, paddingRight: 16} : {marginTop: 5}}>
                            {children}
                        </div>
                    </BSCScroller>
                </BSCCardBox>
            </div>
        </Modal>
    );
}

export default BSCModal;
