/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace API
 * All Marketplace APIs for UI to access
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'taxRate'?: string;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface ApiMessage
 */
export interface ApiMessage {
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    'productId'?: string;
}
/**
 * A User\'s buyer profile associated with marketplace
 * @export
 * @interface BuyerProfile
 */
export interface BuyerProfile {
    /**
     * 
     * @type {Array<Address>}
     * @memberof BuyerProfile
     */
    'shippingAddresses'?: Array<Address>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof BuyerProfile
     */
    'billingAddresses'?: Array<Address>;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof BuyerProfile
     */
    'paymentMethods'?: Array<Payment>;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * The Set Name associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'setName'?: string;
    /**
     * The name of the Player or Players on the Card.
     * @type {string}
     * @memberof Card
     */
    'players'?: string;
    /**
     * The Card Number associated with this Card.
     * @type {string}
     * @memberof Card
     */
    'cardNo'?: string;
    /**
     * The variant of the card.
     * @type {string}
     * @memberof Card
     */
    'variant': CardVariantEnum;
    /**
     * The Variant name associated with this Set variant for Card.
     * @type {string}
     * @memberof Card
     */
    'variantName'?: string;
    /**
     * The name of Sport associated with this Cards Player when it was printed.
     * @type {string}
     * @memberof Card
     */
    'sport'?: CardSportEnum;
    /**
     * The Year or Season when this Set or Card was release.
     * @type {string}
     * @memberof Card
     */
    'year'?: string;
    /**
     * The Team name(s) associated with this Player(s) on the Card when it was printed.
     * @type {string}
     * @memberof Card
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'rawLeastPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'gradedLeastPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttribute'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttributeAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'playerAttributeDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'stockImgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'stockImgBack'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'totalListings'?: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    'lowestPrice'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Card
     */
    'listings'?: Array<Product>;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    '_id'?: string;
}

export const CardVariantEnum = {
    Base: 'Base',
    Insert: 'Insert',
    MailIn: 'Mail-In',
    Parallel: 'Parallel',
    Promo: 'Promo',
    Proof: 'Proof'
} as const;

export type CardVariantEnum = typeof CardVariantEnum[keyof typeof CardVariantEnum];
export const CardSportEnum = {
    Baseball: 'Baseball',
    Basketball: 'Basketball',
    Boxing: 'Boxing',
    Football: 'Football',
    Golf: 'Golf',
    Hockey: 'Hockey',
    MiscSports: 'Misc. Sports',
    Mma: 'MMA',
    MultiSport: 'Multi-Sport',
    NonSport: 'Non-Sport',
    Racing: 'Racing',
    Soccer: 'Soccer',
    Tennis: 'Tennis',
    Wrestling: 'Wrestling'
} as const;

export type CardSportEnum = typeof CardSportEnum[keyof typeof CardSportEnum];

/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {Array<SellerCartGroup>}
     * @memberof Cart
     */
    'sellerCartGroup'?: Array<SellerCartGroup>;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    'noOfItems'?: number;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    'noOfPackages'?: number;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'currency'?: string;
    /**
     * 
     * @type {Array<ApiMessage>}
     * @memberof Cart
     */
    'messages'?: Array<ApiMessage>;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    'estimatedTaxRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'estimatedSalesTax'?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'totalPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    'buyerTotalPrice'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Cart
     */
    'applyPromo'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    'discountPercentage'?: number;
}
/**
 * 
 * @export
 * @interface CartItem
 */
export interface CartItem {
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CartItem
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    'buyerPrice'?: string;
    /**
     * 
     * @type {Product}
     * @memberof CartItem
     */
    'productItem'?: Product;
}
/**
 * 
 * @export
 * @interface CartRequest
 */
export interface CartRequest {
    /**
     * The action on the cart
     * @type {string}
     * @memberof CartRequest
     */
    'action': CartRequestActionEnum;
    /**
     * The Cart Id for updating/editing
     * @type {string}
     * @memberof CartRequest
     */
    'id'?: string;
    /**
     * The Cart Session Id is used for anonymous or unauthenticated users so we can use store cart details
     * @type {string}
     * @memberof CartRequest
     */
    'cartSessionId'?: string;
    /**
     * The CartItem is a Product and this Id represents the key for product being added
     * @type {string}
     * @memberof CartRequest
     */
    'productId'?: string;
    /**
     * The quantity of product(s) added to the cart.
     * @type {number}
     * @memberof CartRequest
     */
    'quantity'?: number;
    /**
     * The User Id of the logged in user. May not be required due to JWT object present in header.
     * @type {string}
     * @memberof CartRequest
     */
    'userId'?: string;
}

export const CartRequestActionEnum = {
    Add: 'add',
    Update: 'update',
    Delete: 'delete',
    Merge: 'merge'
} as const;

export type CartRequestActionEnum = typeof CartRequestActionEnum[keyof typeof CartRequestActionEnum];

/**
 * 
 * @export
 * @interface ContactUs
 */
export interface ContactUs {
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'emailId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUs
     */
    'g-recaptcha-response'?: string;
}
/**
 * 
 * @export
 * @interface OfferRequest
 */
export interface OfferRequest {
    /**
     * 
     * @type {boolean}
     * @memberof OfferRequest
     */
    'quantityCheck'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'offerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'cardId'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferRequest
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferRequest
     */
    'offerShippingCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferRequest
     */
    'offerPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferRequest
     */
    'offerQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'offerStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'declineReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferRequest
     */
    'expiration'?: string;
    /**
     * 
     * @type {ApiMessage}
     * @memberof OfferRequest
     */
    'message'?: ApiMessage;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'confirmationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'subTotal'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'orderSubtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'buyerDiscountAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'subtotalRefundable'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'shippingRefundable'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'refundTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'shippingRefundTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'salesTax'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderCurrency'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'total'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'sellerNetTotal'?: string;
    /**
     * The Order Status.
     * @type {string}
     * @memberof Order
     */
    'orderStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'stripeTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'avaTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'stripePaymentFee'?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'numberOfPackages'?: number;
    /**
     * 
     * @type {User}
     * @memberof Order
     */
    'seller'?: User;
    /**
     * 
     * @type {User}
     * @memberof Order
     */
    'buyer'?: User;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    'billingAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    'shippingAddress'?: Address;
    /**
     * 
     * @type {OrderPayment}
     * @memberof Order
     */
    'payment'?: OrderPayment;
    /**
     * 
     * @type {OrderFeedback}
     * @memberof Order
     */
    'feedback'?: OrderFeedback;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Order
     */
    'orderItems'?: Array<Product>;
    /**
     * 
     * @type {Array<Shipment>}
     * @memberof Order
     */
    'shipments'?: Array<Shipment>;
    /**
     * 
     * @type {Array<Refund>}
     * @memberof Order
     */
    'refunds'?: Array<Refund>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface OrderFeedback
 */
export interface OrderFeedback {
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'rating'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'comment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderFeedback
     */
    'itemAsDescribed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderFeedback
     */
    'avgRating'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderFeedback
     */
    'sellerId'?: string;
}
/**
 * 
 * @export
 * @interface OrderHistoryRequest
 */
export interface OrderHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryRequest
     */
    'query'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryRequest
     */
    'dateRange'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryRequest
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface OrderHistoryResponse
 */
export interface OrderHistoryResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryResponse
     */
    'totalResults'?: number;
    /**
     * 
     * @type {Array<Order>}
     * @memberof OrderHistoryResponse
     */
    'results'?: Array<Order>;
}
/**
 * 
 * @export
 * @interface OrderPayment
 */
export interface OrderPayment {
    /**
     * 
     * @type {PaymentMethod}
     * @memberof OrderPayment
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof OrderPayment
     */
    'amountCharged'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPayment
     */
    'currency'?: string;
}
/**
 * 
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'paymentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'shippingAddressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'billingAddressId'?: string;
}
/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'confirmationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'estimatedDate'?: string;
    /**
     * 
     * @type {ApiMessage}
     * @memberof OrderResponse
     */
    'message'?: ApiMessage;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderResponse
     */
    'orderNumbers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'totalOrderAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'totalTax'?: string;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'maskedCardNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'expiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'cardType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    'primary'?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'expiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'cardType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'maskedCardNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    'primary'?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'paymentMethodId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'year'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentRequest
     */
    'defaultPaymentMethod'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentRequest
     */
    'orderNumbers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PaymentSetting
 */
export interface PaymentSetting {
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'fundingSourceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'fundingSourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'fundingSourceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'verifyMicroDepositsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'bankAccountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'bankAccountType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSetting
     */
    'customerUrl'?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * The Product Id is unique identifier of a listing added by seller.
     * @type {string}
     * @memberof Product
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerImgFront'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerImgBack'?: string;
    /**
     * The product type or listing type of the product.
     * @type {string}
     * @memberof Product
     */
    'productType': ProductProductTypeEnum;
    /**
     * The Card Id associated with this product listing.
     * @type {string}
     * @memberof Product
     */
    'cardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'buyerPrice'?: string;
    /**
     * The condition is the value set by seller.
     * @type {string}
     * @memberof Product
     */
    'condition'?: ProductConditionEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'availableQuantity'?: number;
    /**
     * The number of products in the order placed by the buyer from website.
     * @type {number}
     * @memberof Product
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'active'?: boolean;
    /**
     * The grading companies that BSC allows to sell on the website.
     * @type {string}
     * @memberof Product
     */
    'gradingCompany'?: ProductGradingCompanyEnum;
    /**
     * The various grades given by the grading companies.
     * @type {string}
     * @memberof Product
     */
    'grade'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shippingCost'?: string;
    /**
     * Total price including the shipping cost.
     * @type {number}
     * @memberof Product
     */
    'totalCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'cartQty'?: number;
    /**
     * 
     * @type {Card}
     * @memberof Product
     */
    'card'?: Card;
    /**
     * 
     * @type {User}
     * @memberof Product
     */
    'seller'?: User;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sellerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lastSoldPrice'?: number;
    /**
     * Sport Id for the given Card
     * @type {string}
     * @memberof Product
     */
    'sportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'year'?: string;
    /**
     * 
     * @type {Array<ShippingSettings>}
     * @memberof Product
     */
    'shippingSettings'?: Array<ShippingSettings>;
}

export const ProductProductTypeEnum = {
    Raw: 'raw',
    Graded: 'graded'
} as const;

export type ProductProductTypeEnum = typeof ProductProductTypeEnum[keyof typeof ProductProductTypeEnum];
export const ProductConditionEnum = {
    Mint: 'mint',
    NearMint: 'near_mint',
    VeryGood: 'very_good',
    Poor: 'poor',
    Fair: 'fair'
} as const;

export type ProductConditionEnum = typeof ProductConditionEnum[keyof typeof ProductConditionEnum];
export const ProductGradingCompanyEnum = {
    Bvg: 'bvg',
    Psa: 'psa',
    Sgc: 'sgc',
    Hga: 'hga',
    Bgs: 'bgs',
    Csg: 'csg'
} as const;

export type ProductGradingCompanyEnum = typeof ProductGradingCompanyEnum[keyof typeof ProductGradingCompanyEnum];

/**
 * 
 * @export
 * @interface ProductIssue
 */
export interface ProductIssue {
    /**
     * The Card Id for which the issue is reported
     * @type {string}
     * @memberof ProductIssue
     */
    'cardId'?: string;
    /**
     * The Product Id for which the issue is reported
     * @type {string}
     * @memberof ProductIssue
     */
    'productId'?: string;
    /**
     * The type of issue selected from dropdown
     * @type {string}
     * @memberof ProductIssue
     */
    'issueType'?: ProductIssueIssueTypeEnum;
    /**
     * Additional information entered by the user describing the issue. User can enter up to 900 characters.
     * @type {string}
     * @memberof ProductIssue
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductIssue
     */
    'emailId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductIssue
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductIssue
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductIssue
     */
    'userId'?: string;
}

export const ProductIssueIssueTypeEnum = {
    IncorrectImage: 'incorrect_image',
    IncorrectProductDetails: 'incorrect_product_details',
    InappropriateContent: 'inappropriate_content',
    OtherFeedback: 'other_feedback'
} as const;

export type ProductIssueIssueTypeEnum = typeof ProductIssueIssueTypeEnum[keyof typeof ProductIssueIssueTypeEnum];

/**
 * 
 * @export
 * @interface Refund
 */
export interface Refund {
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'returnItem'?: string;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'subtotalRefund'?: number;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'subtotalRefundTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'subtotalRefundTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'shippingRefund'?: number;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'shippingRefundTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'shippingRefundTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'transactionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'refundType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'refundFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'refundReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Refund
     */
    'buyerReturn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'avaTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Refund
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Refund
     */
    'refundTotal'?: number;
}
/**
 * 
 * @export
 * @interface SellerCartGroup
 */
export interface SellerCartGroup {
    /**
     * 
     * @type {Array<CartItem>}
     * @memberof SellerCartGroup
     */
    'cartItems'?: Array<CartItem>;
    /**
     * 
     * @type {number}
     * @memberof SellerCartGroup
     */
    'sequence'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerCartGroup
     */
    'noOfItems'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerCartGroup
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerCartGroup
     */
    'buyerPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerCartGroup
     */
    'shippingCost'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerCartGroup
     */
    'subTotalPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerCartGroup
     */
    'buyerSubTotalPrice'?: string;
}
/**
 * If User is a seller as well you will get the additional details for his profile
 * @export
 * @interface SellerProfile
 */
export interface SellerProfile {
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerStoreName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerRating'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerSales'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'totalQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'storePhoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerProfile
     */
    'localCardShop'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'applicationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'aggregateRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'salesNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'shipmentCost'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SellerProfile
     */
    'offers'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerMarketUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerIconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'sellerEmailId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'referralChannel'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'defaultCurrency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerProfile
     */
    'inventoryStatus'?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof SellerProfile
     */
    'storeAddress'?: Address;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'totalCards'?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerProfile
     */
    'totalListings'?: number;
    /**
     * 
     * @type {SellerSetting}
     * @memberof SellerProfile
     */
    'listingSettings'?: SellerSetting;
    /**
     * 
     * @type {PaymentSetting}
     * @memberof SellerProfile
     */
    'paymentSettings'?: PaymentSetting;
    /**
     * 
     * @type {Array<ShippingSettings>}
     * @memberof SellerProfile
     */
    'shippingSettings'?: Array<ShippingSettings>;
    /**
     * 
     * @type {string}
     * @memberof SellerProfile
     */
    'addressId'?: string;
}
/**
 * 
 * @export
 * @interface SellerSetting
 */
export interface SellerSetting {
    /**
     * 
     * @type {string}
     * @memberof SellerSetting
     */
    'shippingFee'?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerSetting
     */
    'currency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerSetting
     */
    'offers'?: boolean;
}
/**
 * 
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'shippedDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    'bscShipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Shipment
     */
    'shipmentShippingAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Shipment
     */
    'shipmentStoreAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'status'?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Shipment
     */
    'shipmentItems'?: Array<Product>;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'serviceCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'shippingCharge'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'insuranceCost'?: string;
}
/**
 * 
 * @export
 * @interface ShippingSettings
 */
export interface ShippingSettings {
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'sellerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'tierCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingSettings
     */
    'rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingSettings
     */
    'rangeStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingSettings
     */
    'rangeEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingSettings
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Unique identifier of the User.
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * Username as chosen by the application for the User.
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * FirstName of the User.
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * Lastname of the User.
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * Role Identifier of the User.
     * @type {string}
     * @memberof User
     */
    'roleId': string;
    /**
     * Phone number of the User.
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * Email of the User.
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Customer identifier on payment system Stripe.
     * @type {string}
     * @memberof User
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {UserNotifications}
     * @memberof User
     */
    'userNotifications'?: UserNotifications;
    /**
     * 
     * @type {BuyerProfile}
     * @memberof User
     */
    'buyerProfile'?: BuyerProfile;
    /**
     * 
     * @type {SellerProfile}
     * @memberof User
     */
    'sellerProfile'?: SellerProfile;
}
/**
 * All notification settings for the user in marketplace
 * @export
 * @interface UserNotifications
 */
export interface UserNotifications {
    /**
     * 
     * @type {string}
     * @memberof UserNotifications
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'orderConfirmation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'receivedOffers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'messages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifications
     */
    'discountsOffersAndSpecialPromotions'?: boolean;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {User}
     * @memberof UserProfile
     */
    'user'?: User;
}

/**
 * CardApi - axios parameter creator
 * @export
 */
export const CardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Card Listings by card id
         * @param {string} cardId Card id to get the listings for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListings: async (cardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/card/{cardId}/card-listing`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Card Listings by card id and seller id
         * @param {string} cardId Card id to get the listings for
         * @param {string} sellerId Seller id to get the listings for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListingsBySeller: async (cardId?: string, sellerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/card/{cardId}/seller/{sellerId}/card-listing`
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"sellerId"}}`, encodeURIComponent(String(sellerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardApi - functional programming interface
 * @export
 */
export const CardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Card Listings by card id
         * @param {string} cardId Card id to get the listings for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardListings(cardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardListings(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Card Listings by card id and seller id
         * @param {string} cardId Card id to get the listings for
         * @param {string} sellerId Seller id to get the listings for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardListingsBySeller(cardId?: string, sellerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardListingsBySeller(cardId, sellerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CardApi - factory interface
 * @export
 */
export const CardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Card Listings by card id
         * @param {string} cardId Card id to get the listings for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListings(cardId?: string, options?: undefined): AxiosPromise<Card> {
            return localVarFp.getCardListings(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Card Listings by card id and seller id
         * @param {string} cardId Card id to get the listings for
         * @param {string} sellerId Seller id to get the listings for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListingsBySeller(cardId?: string, sellerId?: string, options?: undefined): AxiosPromise<Card> {
            return localVarFp.getCardListingsBySeller(cardId, sellerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardApi - object-oriented interface
 * @export
 * @class CardApi
 * @extends {BaseAPI}
 */
export class CardApi extends BaseAPI {
    /**
     * 
     * @summary Get Card Listings by card id
     * @param {string} cardId Card id to get the listings for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public getCardListings = (cardId?: string, options?: AxiosRequestConfig) => {
        return CardApiFp(this.configuration).getCardListings(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Card Listings by card id and seller id
     * @param {string} cardId Card id to get the listings for
     * @param {string} sellerId Seller id to get the listings for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardApi
     */
    public getCardListingsBySeller = (cardId?: string, sellerId?: string, options?: AxiosRequestConfig) => {
        return CardApiFp(this.configuration).getCardListingsBySeller(cardId, sellerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete all Cart Items
         * @param {CartRequest} cartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (cartRequest?: CartRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cart by sessionId
         * @param {string} sessionId 
         * @param {string} [uid] Contains unique identifier for browser session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartBySessionId: async (sessionId?: string, uid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/cart/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined && uid !== null) {
                localVarHeaderParameter['uid'] = String(uid);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cart by userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/cart/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Cart Item
         * @param {CartRequest} cartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (cartRequest?: CartRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete all Cart Items
         * @param {CartRequest} cartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(cartRequest?: CartRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(cartRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cart by sessionId
         * @param {string} sessionId 
         * @param {string} [uid] Contains unique identifier for browser session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartBySessionId(sessionId?: string, uid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartBySessionId(sessionId, uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cart by userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Cart Item
         * @param {CartRequest} cartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(cartRequest?: CartRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(cartRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete all Cart Items
         * @param {CartRequest} cartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(cartRequest?: CartRequest, options?: undefined): AxiosPromise<Cart> {
            return localVarFp._delete(cartRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cart by sessionId
         * @param {string} sessionId 
         * @param {string} [uid] Contains unique identifier for browser session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartBySessionId(sessionId?: string, uid?: string, options?: undefined): AxiosPromise<Cart> {
            return localVarFp.getCartBySessionId(sessionId, uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cart by userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartByUserId(options?: undefined): AxiosPromise<Cart> {
            return localVarFp.getCartByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Cart Item
         * @param {CartRequest} cartRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(cartRequest?: CartRequest, options?: undefined): AxiosPromise<Cart> {
            return localVarFp.update(cartRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI {
    /**
     * 
     * @summary Delete all Cart Items
     * @param {CartRequest} cartRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public _delete = (cartRequest?: CartRequest, options?: AxiosRequestConfig) => {
        return CartApiFp(this.configuration)._delete(cartRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cart by sessionId
     * @param {string} sessionId 
     * @param {string} [uid] Contains unique identifier for browser session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCartBySessionId = (sessionId?: string, uid?: string, options?: AxiosRequestConfig) => {
        return CartApiFp(this.configuration).getCartBySessionId(sessionId, uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cart by userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getCartByUserId = (options?: AxiosRequestConfig) => {
        return CartApiFp(this.configuration).getCartByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Cart Item
     * @param {CartRequest} cartRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public update = (cartRequest?: CartRequest, options?: AxiosRequestConfig) => {
        return CartApiFp(this.configuration).update(cartRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CatalogControllerApi - axios parameter creator
 * @export
 */
export const CatalogControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCards: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/catalog/update-by-cardids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCards1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/catalog/update-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCardsLastDay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/catalog/update-last-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogControllerApi - functional programming interface
 * @export
 */
export const CatalogControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllCards(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllCards(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllCards1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllCards1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllCardsLastDay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllCardsLastDay(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CatalogControllerApi - factory interface
 * @export
 */
export const CatalogControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCards(requestBody?: Array<string>, options?: undefined): AxiosPromise<void> {
            return localVarFp.updateAllCards(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCards1(options?: undefined): AxiosPromise<void> {
            return localVarFp.updateAllCards1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCardsLastDay(options?: undefined): AxiosPromise<void> {
            return localVarFp.updateAllCardsLastDay(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogControllerApi - object-oriented interface
 * @export
 * @class CatalogControllerApi
 * @extends {BaseAPI}
 */
export class CatalogControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogControllerApi
     */
    public updateAllCards = (requestBody?: Array<string>, options?: AxiosRequestConfig) => {
        return CatalogControllerApiFp(this.configuration).updateAllCards(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogControllerApi
     */
    public updateAllCards1 = (options?: AxiosRequestConfig) => {
        return CatalogControllerApiFp(this.configuration).updateAllCards1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogControllerApi
     */
    public updateAllCardsLastDay = (options?: AxiosRequestConfig) => {
        return CatalogControllerApiFp(this.configuration).updateAllCardsLastDay(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Save contact-us information
         * @param {ContactUs} contactUs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveContactUs: async (contactUs?: ContactUs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/contact-us`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactUs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Collect marketing emails on landing page
         * @param {ContactUs} contactUs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEmail: async (contactUs?: ContactUs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/contact-us/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactUs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Save contact-us information
         * @param {ContactUs} contactUs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveContactUs(contactUs?: ContactUs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveContactUs(contactUs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Collect marketing emails on landing page
         * @param {ContactUs} contactUs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEmail(contactUs?: ContactUs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEmail(contactUs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Save contact-us information
         * @param {ContactUs} contactUs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveContactUs(contactUs?: ContactUs, options?: undefined): AxiosPromise<void> {
            return localVarFp.saveContactUs(contactUs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Collect marketing emails on landing page
         * @param {ContactUs} contactUs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEmail(contactUs?: ContactUs, options?: undefined): AxiosPromise<void> {
            return localVarFp.saveEmail(contactUs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @summary Save contact-us information
     * @param {ContactUs} contactUs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public saveContactUs = (contactUs?: ContactUs, options?: AxiosRequestConfig) => {
        return ContactApiFp(this.configuration).saveContactUs(contactUs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Collect marketing emails on landing page
     * @param {ContactUs} contactUs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public saveEmail = (contactUs?: ContactUs, options?: AxiosRequestConfig) => {
        return ContactApiFp(this.configuration).saveEmail(contactUs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Submit an offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitOffer: async (offerRequest?: OfferRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer: async (offerRequest?: OfferRequest, authorization?: string, offerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Submit an offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitOffer(offerRequest?: OfferRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitOffer(offerRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOffer(offerRequest?: OfferRequest, authorization?: string, offerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOffer(offerRequest, authorization, offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Submit an offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitOffer(offerRequest?: OfferRequest, authorization?: string, options?: undefined): AxiosPromise<OfferRequest> {
            return localVarFp.submitOffer(offerRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update offer
         * @param {OfferRequest} offerRequest 
         * @param {string} authorization 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer(offerRequest?: OfferRequest, authorization?: string, offerId?: string, options?: undefined): AxiosPromise<OfferRequest> {
            return localVarFp.updateOffer(offerRequest, authorization, offerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Submit an offer
     * @param {OfferRequest} offerRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public submitOffer = (offerRequest?: OfferRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return OfferApiFp(this.configuration).submitOffer(offerRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update offer
     * @param {OfferRequest} offerRequest 
     * @param {string} authorization 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public updateOffer = (offerRequest?: OfferRequest, authorization?: string, offerId?: string, options?: AxiosRequestConfig) => {
        return OfferApiFp(this.configuration).updateOffer(offerRequest, authorization, offerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Order feedback
         * @param {OrderFeedback} orderFeedback 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedback: async (orderFeedback?: OrderFeedback, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/order/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderFeedback, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an Order
         * @param {OrderRequest} orderRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (orderRequest?: OrderRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order by orderId
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderById: async (orderId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order history
         * @param {OrderHistoryRequest} orderHistoryRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistory: async (orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/order/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order feedback
         * @param {OrderFeedback} orderFeedback 
         * @param {string} authorization 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedback: async (orderFeedback?: OrderFeedback, authorization?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/order/feedback/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderFeedback, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Order feedback
         * @param {OrderFeedback} orderFeedback 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeedback(orderFeedback?: OrderFeedback, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderFeedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeedback(orderFeedback, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an Order
         * @param {OrderRequest} orderRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(orderRequest?: OrderRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(orderRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order by orderId
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderById(orderId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderById(orderId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order history
         * @param {OrderHistoryRequest} orderHistoryRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistory(orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistory(orderHistoryRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order feedback
         * @param {OrderFeedback} orderFeedback 
         * @param {string} authorization 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeedback(orderFeedback?: OrderFeedback, authorization?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderFeedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeedback(orderFeedback, authorization, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Order feedback
         * @param {OrderFeedback} orderFeedback 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedback(orderFeedback?: OrderFeedback, authorization?: string, options?: undefined): AxiosPromise<OrderFeedback> {
            return localVarFp.createFeedback(orderFeedback, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an Order
         * @param {OrderRequest} orderRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(orderRequest?: OrderRequest, authorization?: string, options?: undefined): AxiosPromise<OrderResponse> {
            return localVarFp.createOrder(orderRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order by orderId
         * @param {string} orderId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderById(orderId?: string, authorization?: string, options?: undefined): AxiosPromise<Order> {
            return localVarFp.getOrderById(orderId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order history
         * @param {OrderHistoryRequest} orderHistoryRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistory(orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options?: undefined): AxiosPromise<OrderHistoryResponse> {
            return localVarFp.getOrderHistory(orderHistoryRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order feedback
         * @param {OrderFeedback} orderFeedback 
         * @param {string} authorization 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedback(orderFeedback?: OrderFeedback, authorization?: string, id?: string, options?: undefined): AxiosPromise<OrderFeedback> {
            return localVarFp.updateFeedback(orderFeedback, authorization, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Create Order feedback
     * @param {OrderFeedback} orderFeedback 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createFeedback = (orderFeedback?: OrderFeedback, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).createFeedback(orderFeedback, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an Order
     * @param {OrderRequest} orderRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createOrder = (orderRequest?: OrderRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).createOrder(orderRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order by orderId
     * @param {string} orderId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderById = (orderId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).getOrderById(orderId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order history
     * @param {OrderHistoryRequest} orderHistoryRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderHistory = (orderHistoryRequest?: OrderHistoryRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).getOrderHistory(orderHistoryRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order feedback
     * @param {OrderFeedback} orderFeedback 
     * @param {string} authorization 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateFeedback = (orderFeedback?: OrderFeedback, authorization?: string, id?: string, options?: AxiosRequestConfig) => {
        return OrderApiFp(this.configuration).updateFeedback(orderFeedback, authorization, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary API for attaching a PaymentMethod to customer in Stripe for the User
         * @param {PaymentRequest} paymentRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPaymentMethod: async (paymentRequest?: PaymentRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/payment/attach`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API for detaching a PaymentMethod from customer in Stripe for the User
         * @param {string} paymentMethodId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachPaymentMethod: async (paymentMethodId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/payment/payment-method/{paymentMethodId}`
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Customer Payment Methods
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPaymentMethods: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/payment/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API for retrieving a PaymentMethod in Stripe for the User
         * @param {string} paymentMethodId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethod: async (paymentMethodId?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/payment/payment-method/{paymentMethodId}`
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary API for updating a PaymentMethod with new month and year in Stripe for the User
         * @param {PaymentRequest} paymentRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethod: async (paymentRequest?: PaymentRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/payment/payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary API for attaching a PaymentMethod to customer in Stripe for the User
         * @param {PaymentRequest} paymentRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachPaymentMethod(paymentRequest?: PaymentRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachPaymentMethod(paymentRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API for detaching a PaymentMethod from customer in Stripe for the User
         * @param {string} paymentMethodId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachPaymentMethod(paymentMethodId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachPaymentMethod(paymentMethodId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Customer Payment Methods
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerPaymentMethods(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerPaymentMethods(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API for retrieving a PaymentMethod in Stripe for the User
         * @param {string} paymentMethodId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethod(paymentMethodId?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethod(paymentMethodId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary API for updating a PaymentMethod with new month and year in Stripe for the User
         * @param {PaymentRequest} paymentRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentMethod(paymentRequest?: PaymentRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentMethod(paymentRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary API for attaching a PaymentMethod to customer in Stripe for the User
         * @param {PaymentRequest} paymentRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachPaymentMethod(paymentRequest?: PaymentRequest, authorization?: string, options?: undefined): AxiosPromise<string> {
            return localVarFp.attachPaymentMethod(paymentRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API for detaching a PaymentMethod from customer in Stripe for the User
         * @param {string} paymentMethodId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachPaymentMethod(paymentMethodId?: string, authorization?: string, options?: undefined): AxiosPromise<string> {
            return localVarFp.detachPaymentMethod(paymentMethodId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Customer Payment Methods
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPaymentMethods(authorization?: string, options?: undefined): AxiosPromise<Payment> {
            return localVarFp.getCustomerPaymentMethods(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API for retrieving a PaymentMethod in Stripe for the User
         * @param {string} paymentMethodId 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethod(paymentMethodId?: string, authorization?: string, options?: undefined): AxiosPromise<Payment> {
            return localVarFp.getPaymentMethod(paymentMethodId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary API for updating a PaymentMethod with new month and year in Stripe for the User
         * @param {PaymentRequest} paymentRequest 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethod(paymentRequest?: PaymentRequest, authorization?: string, options?: undefined): AxiosPromise<string> {
            return localVarFp.updatePaymentMethod(paymentRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary API for attaching a PaymentMethod to customer in Stripe for the User
     * @param {PaymentRequest} paymentRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public attachPaymentMethod = (paymentRequest?: PaymentRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).attachPaymentMethod(paymentRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API for detaching a PaymentMethod from customer in Stripe for the User
     * @param {string} paymentMethodId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public detachPaymentMethod = (paymentMethodId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).detachPaymentMethod(paymentMethodId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Customer Payment Methods
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getCustomerPaymentMethods = (authorization?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).getCustomerPaymentMethods(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API for retrieving a PaymentMethod in Stripe for the User
     * @param {string} paymentMethodId 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getPaymentMethod = (paymentMethodId?: string, authorization?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).getPaymentMethod(paymentMethodId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary API for updating a PaymentMethod with new month and year in Stripe for the User
     * @param {PaymentRequest} paymentRequest 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public updatePaymentMethod = (paymentRequest?: PaymentRequest, authorization?: string, options?: AxiosRequestConfig) => {
        return PaymentApiFp(this.configuration).updatePaymentMethod(paymentRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Product Listings for google feed
         * @param {number} limitNbr 
         * @param {number} fileNbr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGoogleFeed: async (limitNbr?: number, fileNbr?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/product/google-feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limitNbr !== undefined) {
                localVarQueryParameter['limitNbr'] = limitNbr;
            }

            if (fileNbr !== undefined) {
                localVarQueryParameter['fileNbr'] = fileNbr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product Listings for google feed
         * @param {number} limitNbr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListingsForFeed: async (limitNbr?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/product/google-feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limitNbr !== undefined) {
                localVarQueryParameter['limitNbr'] = limitNbr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report an issue about card/product
         * @param {ProductIssue} productIssue 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportIssue: async (productIssue?: ProductIssue, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/product/issue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productIssue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Product Listings for google feed
         * @param {number} limitNbr 
         * @param {number} fileNbr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateGoogleFeed(limitNbr?: number, fileNbr?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateGoogleFeed(limitNbr, fileNbr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Product Listings for google feed
         * @param {number} limitNbr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardListingsForFeed(limitNbr?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardListingsForFeed(limitNbr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report an issue about card/product
         * @param {ProductIssue} productIssue 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportIssue(productIssue?: ProductIssue, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportIssue(productIssue, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Product Listings for google feed
         * @param {number} limitNbr 
         * @param {number} fileNbr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGoogleFeed(limitNbr?: number, fileNbr?: number, options?: undefined): AxiosPromise<string> {
            return localVarFp.generateGoogleFeed(limitNbr, fileNbr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Product Listings for google feed
         * @param {number} limitNbr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardListingsForFeed(limitNbr?: number, options?: undefined): AxiosPromise<string> {
            return localVarFp.getCardListingsForFeed(limitNbr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report an issue about card/product
         * @param {ProductIssue} productIssue 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportIssue(productIssue?: ProductIssue, authorization?: string, options?: undefined): AxiosPromise<void> {
            return localVarFp.reportIssue(productIssue, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Generate Product Listings for google feed
     * @param {number} limitNbr 
     * @param {number} fileNbr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public generateGoogleFeed = (limitNbr?: number, fileNbr?: number, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).generateGoogleFeed(limitNbr, fileNbr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Product Listings for google feed
     * @param {number} limitNbr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getCardListingsForFeed = (limitNbr?: number, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).getCardListingsForFeed(limitNbr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report an issue about card/product
     * @param {ProductIssue} productIssue 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public reportIssue = (productIssue?: ProductIssue, authorization?: string, options?: AxiosRequestConfig) => {
        return ProductApiFp(this.configuration).reportIssue(productIssue, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SellerApi - axios parameter creator
 * @export
 */
export const SellerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All Sellers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSellers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/seller/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Seller
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeller: async (sellerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/seller/{sellerId}`
                .replace(`{${"sellerId"}}`, encodeURIComponent(String(sellerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerApi - functional programming interface
 * @export
 */
export const SellerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All Sellers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSellers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSellers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Seller
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeller(sellerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeller(sellerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerApi - factory interface
 * @export
 */
export const SellerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All Sellers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSellers(options?: undefined): AxiosPromise<SellerProfile> {
            return localVarFp.getAllSellers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Seller
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeller(sellerId?: string, options?: undefined): AxiosPromise<SellerProfile> {
            return localVarFp.getSeller(sellerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerApi - object-oriented interface
 * @export
 * @class SellerApi
 * @extends {BaseAPI}
 */
export class SellerApi extends BaseAPI {
    /**
     * 
     * @summary Get All Sellers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public getAllSellers = (options?: AxiosRequestConfig) => {
        return SellerApiFp(this.configuration).getAllSellers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Seller
     * @param {string} sellerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public getSeller = (sellerId?: string, options?: AxiosRequestConfig) => {
        return SellerApiFp(this.configuration).getSeller(sellerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Address for the user
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User details using user email id
         * @param {string} emailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmailId: async (emailId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/user/{emailId}`
                .replace(`{${"emailId"}}`, encodeURIComponent(String(emailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Profile
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAddressByType: async (address?: Address, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update address
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress: async (address?: Address, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Notifications
         * @param {UserNotifications} userNotifications 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNotifications: async (userNotifications?: UserNotifications, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/user/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNotifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Profile
         * @param {UserProfile} userProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (userProfile?: UserProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {

            const localVarPath = `/marketplace/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as undefined;
            const localVarQueryParameter = {} as undefined;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Address for the user
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddress(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddress(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User details using user email id
         * @param {string} emailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByEmailId(emailId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByEmailId(emailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Profile
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAddressByType(address?: Address, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAddressByType(address, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update address
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddress(address?: Address, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAddress(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User Notifications
         * @param {UserNotifications} userNotifications 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserNotifications(userNotifications?: UserNotifications, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserNotifications(userNotifications, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User Profile
         * @param {UserProfile} userProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(userProfile?: UserProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(userProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Address for the user
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress(authorization?: string, options?: undefined): AxiosPromise<Address> {
            return localVarFp.getAddress(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User details using user email id
         * @param {string} emailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmailId(emailId?: string, options?: undefined): AxiosPromise<string> {
            return localVarFp.getUserByEmailId(emailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Profile
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(authorization?: string, options?: undefined): AxiosPromise<User> {
            return localVarFp.getUserProfile(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save address
         * @param {Address} address 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAddressByType(address?: Address, authorization?: string, options?: undefined): AxiosPromise<Address> {
            return localVarFp.saveAddressByType(address, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update address
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress(address?: Address, options?: undefined): AxiosPromise<Address> {
            return localVarFp.updateAddress(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Notifications
         * @param {UserNotifications} userNotifications 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNotifications(userNotifications?: UserNotifications, authorization?: string, options?: undefined): AxiosPromise<UserNotifications> {
            return localVarFp.updateUserNotifications(userNotifications, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Profile
         * @param {UserProfile} userProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(userProfile?: UserProfile, options?: undefined): AxiosPromise<UserProfile> {
            return localVarFp.updateUserProfile(userProfile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get Address for the user
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAddress = (authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getAddress(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User details using user email id
     * @param {string} emailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserByEmailId = (emailId?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getUserByEmailId(emailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Profile
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserProfile = (authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).getUserProfile(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save address
     * @param {Address} address 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public saveAddressByType = (address?: Address, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).saveAddressByType(address, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update address
     * @param {Address} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateAddress = (address?: Address, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).updateAddress(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Notifications
     * @param {UserNotifications} userNotifications 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserNotifications = (userNotifications?: UserNotifications, authorization?: string, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).updateUserNotifications(userNotifications, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Profile
     * @param {UserProfile} userProfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserProfile = (userProfile?: UserProfile, options?: AxiosRequestConfig) => {
        return UserApiFp(this.configuration).updateUserProfile(userProfile, options).then((request) => request(this.axios, this.basePath));
    }
}


