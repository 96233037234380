/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {ModalProps} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {MobileStepper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import Normalizer from '../../../../client/common/normalizer/normalizer';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiFormGroup-root': {
            marginLeft: 16,
        },
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    marginTop: {marginTop: theme.spacing(1.5)},
    message: {
        padding: '32px 0',
    },
    verticalWrapper: {
        alignContent: 'center',
        textAlign: 'center',
        height: '100%',
        width: 'auto',
        margin: 0,
    },
    flexButton: {
        flex: 1,
    },
    cardImg: {
        maxHeight: 218,
        objectFit: 'contain',
        margin: '32px 0',
        textAlign: 'center',
        width: '100%',
    },
    image: {
        width: 226,
        height: 226,
        objectFit: 'contain',
        margin: theme.spacing(0, 'auto'),
        display: 'block',
        filter: 'drop-shadow(0px 2.7409px 2.7409px rgba(0, 0, 0, 0.25))',

        [theme.breakpoints.down('sm')]: {
            width: 162,
            height: 235,
        },
    },
    stepper: {
        bottom: '0px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        zIndex: 0,
        justifyContent: 'center',
        paddingBottom: '16px',
    },
    stepperMobile: {
        bottom: '0px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        justifyContent: 'center',
        padding: '8px 0px 12px',
    },
    dotActive: {
        background: theme.palette.primary.main,
    },
}));

interface OfferResponseModalProps extends Omit<ModalProps, 'children'> {
    productName: string;
    offerPrice: number;
    sellerPrice: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sellerImgFront: undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sellerImgBack: undefined;
    shippingCost: number;
    open: boolean;
    onClose: (open: boolean) => void;
    onModalResponse: (response: boolean) => void;
}

const OfferResponseModal = ({
    productName,
    offerPrice,
    sellerPrice,
    sellerImgFront,
    sellerImgBack,
    shippingCost,
    open,
    onClose,
    onModalResponse,
}: OfferResponseModalProps) => {
    const ACCEPT_OPTION = 'Accept';
    const DECLINE_OPTION = 'Decline';
    const classes = useStyles();
    const {isMobile, isBelowTablet} = useScreenSnap();
    const [activeStep, setActiveStep] = useState(0);
    const [isProductImageFrontView, setProductImageFrontView] = useState(true);
    const flipCard = () => {
        if (isProductImageFrontView) {
            setProductImageFrontView(false);
            setActiveStep(1);
        } else {
            setProductImageFrontView(true);
            setActiveStep(0);
        }
    };

    return (
        <BSCModal headerLabel="Review Offer" hasCustomHeaderStyling={true} open={open} onClose={onClose}>
            <div className={classes.root}>
                <BSCImage
                    className={classes.image}
                    src={isProductImageFrontView ? Normalizer.getS3ImagePath(sellerImgFront) : Normalizer.getS3ImagePath(sellerImgBack)}
                />
                <MobileStepper
                    classes={{dotActive: classes.dotActive}}
                    className={isBelowTablet || isMobile ? classes.stepperMobile : classes.stepper}
                    activeStep={activeStep}
                    onClick={() => flipCard()}
                    steps={2}
                    variant="dots"
                />
                <BSCTypography style={{minHeight: 32}} align="center" variant="h4" normal={true} label={productName} />
                <BSCDivider style={{marginBottom: 24}} />

                <Grid container style={{border: 'solid 1px #B8C2CC'}}>
                    <Grid item container md={12} style={{background: '#f5f8fa', borderBottom: 'solid 1px #B8C2CC'}}>
                        <Grid item xs={6} style={{borderRight: 'solid 1px #B8C2CC'}}>
                            <BSCTypography
                                style={{minHeight: 32, paddingTop: 12, background: '#DAE0E6'}}
                                align="center"
                                variant="h5"
                                normal={true}
                                label="Your Listing"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSCTypography
                                style={{minHeight: 32, paddingTop: 12, background: '#DAE0E6'}}
                                align="center"
                                variant="h5"
                                normal={true}
                                label="Offer"
                            />
                        </Grid>
                    </Grid>
                    <Grid item container md={12}>
                        <Grid item xs={6} style={{borderRight: 'solid 1px #B8C2CC'}}>
                            <BSCCurrency style={{paddingTop: 12}} align="center" variant="h5" amount={sellerPrice} />
                        </Grid>
                        <Grid item xs={6}>
                            <BSCCurrency style={{paddingTop: 12}} align="center" variant="h5" amount={offerPrice} />
                        </Grid>
                    </Grid>
                    <Grid item container md={12}>
                        <Grid item xs={6} style={{borderRight: 'solid 1px #B8C2CC'}}>
                            <BSCTypography
                                size12
                                style={{paddingBottom: 12}}
                                align="center"
                                label={`+ ${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(shippingCost)} Shipping`}
                                variant="subtitle2"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BSCTypography
                                size12
                                style={{paddingBottom: 12}}
                                align="center"
                                label={`+ ${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(shippingCost)} Shipping`}
                                variant="subtitle2"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <BSCDivider />
                <Grid container spacing={1} className={classes.verticalWrapper}>
                    <Grid item xs={12} className={classes.flexButton}>
                        <BSCButton style={{fontSize: 12}} fullWidth color="secondary" onClick={() => onModalResponse(true)}>
                            {ACCEPT_OPTION}
                        </BSCButton>
                    </Grid>
                    <Grid item xs={12} className={classes.flexButton}>
                        <BSCButton style={{fontSize: 12}} fullWidth color="primary" onClick={() => onModalResponse(false)}>
                            {DECLINE_OPTION}
                        </BSCButton>
                    </Grid>
                </Grid>
            </div>
        </BSCModal>
    );
};

export default OfferResponseModal;
