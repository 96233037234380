/* eslint-disable indent */
import React, {ReactNode, forwardRef, ForwardedRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box, {BoxProps} from '@material-ui/core/Box';
import classNames from 'classnames';
import {useResizeDetector} from 'react-resize-detector';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';

interface StyleProps {
    maxHeight: BSCScrollerProps['maxHeight'];
}

const useStyles = makeStyles(theme => ({
    root: {
        ['&::-webkit-scrollbar']: {
            width: 4,
        },
        ['&::-webkit-scrollbar-track']: {
            background: '#E9EDF2',
        },
        ['&::-webkit-scrollbar-thumb']: {
            background: 'rgba(24, 32, 38, 0.7)',
            borderRadius: 4,
            boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
        },
    },
    scrollY: {
        overflowY: 'auto',
        borderRadius: '8px',
    },
    scrollY_mobile: {
        overflowY: 'auto',
        borderRadius: '0',
    },
    scrollX: {
        overflowX: 'auto',
        display: 'flex',
        paddingBottom: 8,
        ['&::-webkit-scrollbar']: {
            height: 4,
        },
    },
    overFlow: {
        paddingRight: theme.spacing(0.5),
    },
    maxHeight: {
        maxHeight: ({maxHeight}: StyleProps) => maxHeight,
    },
}));

declare module '@material-ui/core/Box' {
    interface BoxProps {
        ref?: ForwardedRef<HTMLDivElement>;
    }
}

interface BSCScrollerProps extends BoxProps {
    children: ReactNode;
    scrollY?: boolean;
    scrollX?: boolean;
    maxHeight?: number | null | '100vh' | string;
}

const BSCScroller = forwardRef<HTMLDivElement, BSCScrollerProps>(
    ({children, scrollY = true, scrollX = false, maxHeight = null}: BSCScrollerProps, ref) => {
        const classes = useStyles({maxHeight});
        const {isDesktop} = useScreenSnap();
        const {height, ref: childRef} = useResizeDetector();

        return (
            <Box
                ref={ref}
                className={
                    isDesktop
                        ? classNames(
                              classes.root,
                              {
                                  [classes.scrollY]: scrollY,
                                  [classes.scrollX]: scrollX,
                                  [classes.maxHeight]: maxHeight,
                              },
                              {
                                  [classes.overFlow]:
                                      maxHeight === '100vh' ? window.innerHeight < childRef?.current?.clientHeight : maxHeight < height,
                              }
                          )
                        : classNames(
                              classes.root,
                              {
                                  [classes.scrollY_mobile]: scrollY,
                                  [classes.scrollX]: scrollX,
                                  [classes.maxHeight]: maxHeight,
                              },
                              {
                                  [classes.overFlow]:
                                      maxHeight === '100vh' ? window.innerHeight < childRef?.current?.clientHeight : maxHeight < height,
                              }
                          )
                }
            >
                <div ref={childRef}>{children}</div>
            </Box>
        );
    }
);

export default BSCScroller;
