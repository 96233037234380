import {s3Client} from '../../index';

const serviceUrls = {
    mpFooter: 'mpFooter.json',
    sellerFooter: 'sellersFooter.json',
    terms: 'BSCTerms.html',
    privacy: 'BSCPrivacy.html',
};

const FooterClient = {
    getMarketPlaceFooter: () => s3Client.get(serviceUrls.mpFooter).then(res => res.data),
    getSellersLockerFooter: () => s3Client.get(serviceUrls.sellerFooter).then(res => res.data),
    getTermsAndConditions: () => s3Client.get(serviceUrls.terms).then(res => res.data),

    getPrivacyPolicy: () => s3Client.get(serviceUrls.privacy).then(res => res.data),
};

export default FooterClient;
