import {s3Client} from '../..';

const serviceUrls = {
    sellersSellersFormValidations: 's3/sellersFormValidations',
};

const SellersFormValidationsClient = {
    getSellersFormValidations: () => s3Client.get(serviceUrls.sellersSellersFormValidations).then(res => res.data),
};

export default SellersFormValidationsClient;
