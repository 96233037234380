/* eslint-disable @typescript-eslint/no-unused-vars */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {AUTH_REQUESTS} from '../../authConfig';
import {useAuthFunctions, useAuthState} from '../../AuthContext';
import {CartApi} from '../../client/openapitools/marketplace';
import useReduxCart from '../../features/hooks/redux/cart/useReduxCart';
import {RootState} from '../../redux/reducers';
import * as constants from '../../shared/constants';
import {ReactComponent as ShoppingCart} from '../../svgs/cart.svg';
import useReduxToast from '../hooks/redux/toast/useReduxToast';

const useStyles = makeStyles((theme: Theme) => ({
    cartWrapper: {position: 'relative'},
    cart: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 4,
        right: 4,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 14,
        height: 14,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    cartIcon: {
        width: 34,
        height: 34,
        //fill: 'white',
        [theme.breakpoints.down('sm')]: {
            height: 28,
        },
    },
    cartIconWhite: {
        fill: 'white',
    },
    cartIconBlack: {
        fill: 'black',
    },
    largeIcon: {
        //     height: 34,
        //  width: 34,
        '& svg': {
            height: 32,
            width: 32,
        },
    },
}));

function CartButton(whiteIcon) {
    const showWhite = whiteIcon?.whiteIcon ?? true;
    const classes = useStyles();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const dispatch = useDispatch();
    const {cartState, getCart} = useReduxCart();
    const {instance} = useMsal();
    const [numberOfItems, setNumberOfItems] = useState(0);
    const {addToast} = useReduxToast();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const authFunc = useAuthFunctions();
    const {apiAuthConfig} = useAuthState();

    const hideHomeSliders = () => {
        dispatch({
            type: constants.PWA_SPORTS_CHIPS,
            payload: {
                isSportsChipsVisible: false,
            },
        });
    };

    useEffect(() => {
        if (marketplaceState && cartState) {
            if (marketplaceState.hasNewCartQuantity) {
                getCart();
                dispatch({
                    type: constants.HAS_NEW_CART_QUANTITY,
                    payload: false,
                });
            }
        }
    }, [marketplaceState, cartState]);

    useEffect(() => {
        if (cartState) {
            setNumberOfItems(cartState.noOfItems);
        }
    }, [cartState]);

    const [badgePadding, setBadgePadding] = useState(4);
    useEffect(() => {
        const badgeNumerals = numberOfItems.toString().length;
        setBadgePadding(badgeNumerals + 3);
    }, [numberOfItems]);

    useEffect(() => {
        const fetchData = async () => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                const cartApi = new CartApi(apiConfig);
                                const cartResponse = await cartApi.getCartByUserId();
                                if (cartResponse && cartResponse.data) {
                                    setNumberOfItems(cartResponse.data?.noOfItems);
                                }
                                return cartResponse.data;
                            } catch (error) {
                                console.error('Error fetching cart data', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'Cart Data',
                                });
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user', error);
                    });
            } else {
                const cartApi = new CartApi(apiAuthConfig);
                const cartResponse = await cartApi.getCartBySessionId(localStorage.getItem('sessionId'));
                if (cartResponse && cartResponse.data) {
                    setNumberOfItems(cartResponse.data?.noOfItems);
                }
                return cartResponse.data;
            }
        };
        fetchData();
    }, []);

    return (
        <div className={classes.cartWrapper} onClick={hideHomeSliders}>
            <Link to="/cart/edit">
                <span className={classes.cart} style={{padding: badgePadding}}>
                    {numberOfItems}
                </span>
            </Link>
            <IconButton component={Link} to="/cart/edit" className={classes.largeIcon}>
                <ShoppingCart className={(classes.cartIcon, showWhite ? classes.cartIconWhite : classes.cartIconBlack)} />
            </IconButton>
        </div>
    );
}

export default CartButton;
