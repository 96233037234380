import React, {ReactNode} from 'react';

interface BSCRepeaterProps {
    amount: number;
    children: ReactNode;
}

function BSCRepeater({amount, children}: BSCRepeaterProps) {
    return <>{Array.from(Array(amount), () => children)}</>;
}

export default BSCRepeater;
