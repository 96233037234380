import EventEmitter from 'eventemitter3';

const EE = new EventEmitter();

class Event {
    emit(key: string) {
        EE.emit(key);
    }

    on(key: string, fn) {
        EE.on(key, fn);
    }

    addEvent(key, fn) {
        EE.addListener(key, fn);
    }

    removeEvent(key) {
        EE.removeListener(key);
    }
}

export default new Event();
