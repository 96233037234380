import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Normalizer from '../../../../../../../client/common/normalizer/normalizer';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(0),
        flexDirection: 'column',
        alignItems: 'center',
    },
    font20: {
        fontSize: 20,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        position: 'static',
        width: '100%',
        background: 'linear-gradient(180deg, #FFFFFF 65.52%, #DAE0E6 100%)',
        borderRadius: '4px 4px 0px 0px',
        boxSizing: 'border-box',
        fontSize: 16,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    table: {
        '& p': {
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '10px',
            },
        },
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
        '&:nth-child(even)': {
            backgroundColor: '#eee',
        },
    },
    th: {
        color: '#41474C',
        padding: theme.spacing(1),
        background: '#DAE0E6',
        borderBottom: 'none',
        borderTop: '2px solid #B8C2CC',
    },
    td: {
        borderBottom: '2px solid #e5e5e5',
        padding: theme.spacing(1.875),
    },
}));

function FeedbackRankings({rankings, loading, filters}) {
    const classes = useStyles();

    return (
        <BSCCardBox className={classes.root}>
            <BSCTypography className={classes.header} label="Feedback" />
            {loading && (
                <div className={classes.content}>
                    <BSCLoading loading={loading} />
                </div>
            )}
            {rankings && (
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell key="rating" className={classes.th}>
                                    <BSCTypography label="rating" capitalize semibold />
                                </TableCell>
                                {rankings.map(rank => {
                                    return (
                                        <TableCell key={rank.durationKey} className={classes.th}>
                                            <BSCTypography label={rank.duration} capitalize semibold />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Normalizer.getObjectKeys(filters).map(key => {
                                if (!filters[key]) return null;
                                return (
                                    <TableRow role="cell" key={key} className={classes.tr}>
                                        <TableCell className={classes.td}>
                                            <BSCTypography label={key} capitalize />
                                        </TableCell>
                                        {rankings.map(rank => {
                                            return (
                                                <TableCell key={rank.durationKey} className={classes.td}>
                                                    <BSCTypography label={rank[key]} capitalize />
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            <TableRow role="cell" className={classes.tr}>
                                <TableCell className={classes.td}>
                                    <BSCTypography label="Order Count" capitalize semibold />
                                </TableCell>
                                {rankings.map(rank => {
                                    return (
                                        <TableCell key={rank.durationKey} className={classes.td}>
                                            <BSCTypography label={rank.count} capitalize semibold />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </BSCCardBox>
    );
}

export default FeedbackRankings;
