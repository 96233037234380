/* eslint-disable @typescript-eslint/no-unused-vars */
import {Grid, IconButton} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import SignInConfirmationModal from '../../../../bscFooter/SignInConfirmationModal';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import * as constants from '../../../../shared/constants';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) => ({
    selectionsWrapper: {
        padding: '8px 8px 8px 6px !important',
    },
    selectionWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        marginBottom: 6,
    },
    emptySelectionWrapper: {
        height: 64,
        backgroundColor: 'transparent',
        marginBottom: 6,
    },
    largeIcon: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        padding: 8,
        '& svg': {
            height: 32,
            width: 32,
        },
    },
}));

function PWABSCAccount() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [modalOpen, toggleModalOpen] = useState(false);

    const [targetTab, setTargetTab] = useState(1);
    const handleDashSelection = (index: number) => {
        setTargetTab(index);
        switch (index) {
            case 0:
                if (localStorage.getItem('accessToken') === null) {
                    toggleModalOpen(true);
                }
                break;
            case 1:
                if (localStorage.getItem('accessToken') === null) {
                    toggleModalOpen(true);
                }
                break;
            case 2:
                history.push('../../faq');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 6,
                    },
                });
                break;
            case 3:
                history.push('../../support');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 7,
                    },
                });
                break;
            default:
                break;
        }
    };

    const handleModalClose = e => {
        toggleModalOpen(false);
        if (e === true) {
            history.push('/pwa/dashboard/buyer');
            dispatch({
                type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                payload: {
                    pwaBuyerDashboardSelection: 0,
                },
            });
        }
    };

    useEffect(() => {
        dispatch({
            type: constants.PWA_SPORTS_CHIPS,
            payload: {
                isSportsChipsVisible: false,
            },
        });
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 1,
            },
        });
    }, []);

    return (
        <>
            <div className={classes.selectionsWrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={12} onClick={() => handleDashSelection(0)}>
                                <Grid container alignItems="center" className={classes.selectionWrapper}>
                                    <Grid item xs={2}>
                                        <IconButton className={classes.largeIcon}>
                                            <AddCircleOutlineOutlinedIcon htmlColor="white" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <BSCTypography size18 style={{fontWeight: 700}}>
                                            Create Account
                                        </BSCTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} onClick={() => handleDashSelection(1)}>
                        <Grid container alignItems="center" className={classes.selectionWrapper}>
                            <Grid item xs={2}>
                                <IconButton className={classes.largeIcon}>
                                    <span className="material-symbols-outlined">login</span>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <BSCTypography size18 style={{fontWeight: 700}}>
                                    Sign In
                                </BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" className={classes.emptySelectionWrapper}>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} onClick={() => handleDashSelection(2)}>
                        <Grid container alignItems="center" className={classes.selectionWrapper}>
                            <Grid item xs={2}>
                                <IconButton className={classes.largeIcon}>
                                    <span className="material-symbols-outlined">question_mark</span>
                                    {/* <HelpOutlineOutlinedIcon htmlColor="white" /> */}
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <BSCTypography size18 style={{fontWeight: 700}}>
                                    FAQs
                                </BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} onClick={() => handleDashSelection(3)}>
                        <Grid container alignItems="center" className={classes.selectionWrapper}>
                            <Grid item xs={2}>
                                <IconButton className={classes.largeIcon}>
                                    <ContactSupportOutlinedIcon htmlColor="white" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <BSCTypography size18 style={{fontWeight: 700}}>
                                    Contact Support
                                </BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <SignInConfirmationModal open={modalOpen} onClose={e => handleModalClose(e)} targetTab={targetTab} />
        </>
    );
}

export default PWABSCAccount;
