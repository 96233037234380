/* tslint:disable */
/* eslint-disable */
/* prettier-ignore-start */
/**
 * Static
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<InlineResponse200Faq1>}
     * @memberof InlineResponse200
     */
    'faq'?: Array<InlineResponse200Faq1>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    'transitionTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    'transitionType'?: InlineResponse2001TransitionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2001
     */
    'continuousScroll'?: boolean;
    /**
     * 
     * @type {Array<InlineResponse2001BannerItems>}
     * @memberof InlineResponse2001
     */
    'bannerItems'?: Array<InlineResponse2001BannerItems>;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse2001TransitionTypeEnum {
    Fade = 'fade',
    Slide = 'slide'
}

/**
 * 
 * @export
 * @interface InlineResponse2001BannerItems
 */
export interface InlineResponse2001BannerItems {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001BannerItems
     */
    'bgImgUrl'?: string;
    /**
     * 
     * @type {Array<InlineResponse2001Cards>}
     * @memberof InlineResponse2001BannerItems
     */
    'cards'?: Array<InlineResponse2001Cards>;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001BannerItems
     */
    'textHeader'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001BannerItems
     */
    'button'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001BannerItems
     */
    'buttonUrl'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001Cards
 */
export interface InlineResponse2001Cards {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001Cards
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001Cards
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001Cards
     */
    'alt'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {Array<InlineResponse2002Categories>}
     * @memberof InlineResponse2002
     */
    'categories'?: Array<InlineResponse2002Categories>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002Categories
 */
export interface InlineResponse2002Categories {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002Categories
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002Categories
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002Categories
     */
    'url'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse2002Categories
     */
    'topSeller'?: Array<object>;
    /**
     * 
     * @type {Array<InlineResponse2002Menus>}
     * @memberof InlineResponse2002Categories
     */
    'menus'?: Array<InlineResponse2002Menus>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002Links
 */
export interface InlineResponse2002Links {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002Links
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002Links
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002Menus
 */
export interface InlineResponse2002Menus {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002Menus
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002Menus
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002Menus
     */
    'url'?: string;
    /**
     * 
     * @type {Array<InlineResponse2002Links>}
     * @memberof InlineResponse2002Menus
     */
    'links'?: Array<InlineResponse2002Links>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {InlineResponse2003Shop}
     * @memberof InlineResponse2003
     */
    'shop'?: InlineResponse2003Shop;
    /**
     * 
     * @type {InlineResponse2003Events}
     * @memberof InlineResponse2003
     */
    'events'?: InlineResponse2003Events;
    /**
     * 
     * @type {InlineResponse2003About}
     * @memberof InlineResponse2003
     */
    'about'?: InlineResponse2003About;
    /**
     * 
     * @type {InlineResponse2003Help}
     * @memberof InlineResponse2003
     */
    'help'?: InlineResponse2003Help;
}
/**
 * 
 * @export
 * @interface InlineResponse2003About
 */
export interface InlineResponse2003About {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003About
     */
    'title'?: string;
    /**
     * 
     * @type {Array<InlineResponse2003AboutLinks>}
     * @memberof InlineResponse2003About
     */
    'links'?: Array<InlineResponse2003AboutLinks>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003AboutLinks
 */
export interface InlineResponse2003AboutLinks {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003AboutLinks
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003AboutLinks
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Events
 */
export interface InlineResponse2003Events {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003Events
     */
    'title'?: string;
    /**
     * 
     * @type {Array<InlineResponse2003EventsLinks>}
     * @memberof InlineResponse2003Events
     */
    'links'?: Array<InlineResponse2003EventsLinks>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003EventsLinks
 */
export interface InlineResponse2003EventsLinks {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003EventsLinks
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003EventsLinks
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Help
 */
export interface InlineResponse2003Help {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003Help
     */
    'title'?: string;
    /**
     * 
     * @type {Array<InlineResponse2003HelpLinks>}
     * @memberof InlineResponse2003Help
     */
    'links'?: Array<InlineResponse2003HelpLinks>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003HelpLinks
 */
export interface InlineResponse2003HelpLinks {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003HelpLinks
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003HelpLinks
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003Shop
 */
export interface InlineResponse2003Shop {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003Shop
     */
    'title'?: string;
    /**
     * 
     * @type {Array<InlineResponse2003ShopLinks>}
     * @memberof InlineResponse2003Shop
     */
    'links'?: Array<InlineResponse2003ShopLinks>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003ShopLinks
 */
export interface InlineResponse2003ShopLinks {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003ShopLinks
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003ShopLinks
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {Array<InlineResponse2004Categories>}
     * @memberof InlineResponse2004
     */
    'categories'?: Array<InlineResponse2004Categories>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004Categories
 */
export interface InlineResponse2004Categories {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004Categories
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004Categories
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004Categories
     */
    'url'?: string;
    /**
     * 
     * @type {Array<InlineResponse2004Menus>}
     * @memberof InlineResponse2004Categories
     */
    'menus'?: Array<InlineResponse2004Menus>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004Menus
 */
export interface InlineResponse2004Menus {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004Menus
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004Menus
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004Menus
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {Array<InlineResponse2005Help>}
     * @memberof InlineResponse2005
     */
    'help'?: Array<InlineResponse2005Help>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005Help
 */
export interface InlineResponse2005Help {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005Help
     */
    'title'?: string;
    /**
     * 
     * @type {Array<InlineResponse2005Links>}
     * @memberof InlineResponse2005Help
     */
    'links'?: Array<InlineResponse2005Links>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005Links
 */
export interface InlineResponse2005Links {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005Links
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005Links
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200Content
 */
export interface InlineResponse200Content {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Content
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200Content
     */
    'priority'?: number;
    /**
     * 
     * @type {Array<InlineResponse200Data>}
     * @memberof InlineResponse200Content
     */
    'data'?: Array<InlineResponse200Data>;
}
/**
 * 
 * @export
 * @interface InlineResponse200Data
 */
export interface InlineResponse200Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200Data
     */
    'priority'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse200Data
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Data
     */
    'q'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Data
     */
    'a'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200Faq
 */
export interface InlineResponse200Faq {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Faq
     */
    'category'?: string;
    /**
     * 
     * @type {Array<InlineResponse200Content>}
     * @memberof InlineResponse200Faq
     */
    'content'?: Array<InlineResponse200Content>;
}
/**
 * 
 * @export
 * @interface InlineResponse200Faq1
 */
export interface InlineResponse200Faq1 {
    /**
     * 
     * @type {Array<InlineResponse200Faq>}
     * @memberof InlineResponse200Faq1
     */
    'faq'?: Array<InlineResponse200Faq>;
}
/**
 * The result object for displaying the details of a card or set on results page. These are master data information and not the actual product being sold.
 * @export
 * @interface Results
 */
export interface Results {
    /**
     * Unique ID generated for the reference card
     * @type {string}
     * @memberof Results
     */
    'id'?: string;
    /**
     * The name of the sport to be displayed for the master data.
     * @type {string}
     * @memberof Results
     */
    'sport'?: string;
    /**
     * The year of the set released
     * @type {string}
     * @memberof Results
     */
    'year'?: string;
    /**
     * The name of the player to be displayed on results.
     * @type {string}
     * @memberof Results
     */
    'players'?: string;
    /**
     * The name of the product (name+card number) to be displayed on results.
     * @type {string}
     * @memberof Results
     */
    'productName'?: string;
    /**
     * The product type identifies if this is a card(open) or a set (sealed).
     * @type {string}
     * @memberof Results
     */
    'productType'?: string;
    /**
     * Card Number as displayed on the physical card or set
     * @type {string}
     * @memberof Results
     */
    'cardNo'?: string;
    /**
     * The name of the set to be displayed on results.
     * @type {string}
     * @memberof Results
     */
    'setName'?: string;
    /**
     * The URL for the set and may not be needed when the result is a card.
     * @type {string}
     * @memberof Results
     */
    'slug'?: string;
    /**
     * The set typically belongs to Base or insert/parallel/mailin type.
     * @type {string}
     * @memberof Results
     */
    'variant'?: ResultsVariantEnum;
    /**
     * The related set name from above setType.
     * @type {string}
     * @memberof Results
     */
    'variantName'?: string;
    /**
     * The code associated with the player/card attribute.
     * @type {string}
     * @memberof Results
     */
    'playerAttribute'?: ResultsPlayerAttributeEnum;
    /**
     * The description associated with the player/card attribute.
     * @type {string}
     * @memberof Results
     */
    'playerAttributeDesc'?: string;
    /**
     * This is TCDB stored information on card
     * @type {string}
     * @memberof Results
     */
    'additionalInfo'?: string;
    /**
     * Team name to be displayed
     * @type {string}
     * @memberof Results
     */
    'teamName'?: string;
    /**
     * The related lowest available price for raw card if available.
     * @type {string}
     * @memberof Results
     */
    'rawLeastPrice'?: string;
    /**
     * The related lowest available price for graded card if available.
     * @type {string}
     * @memberof Results
     */
    'gradedLeastPrice'?: string;
    /**
     * The total number of listings available for this card/set.
     * @type {number}
     * @memberof Results
     */
    'totalListings'?: number;
    /**
     * The image URL or location on S3
     * @type {string}
     * @memberof Results
     */
    'stockImgFront'?: string;
    /**
     * The location of image for back of the card
     * @type {string}
     * @memberof Results
     */
    'stockImgBack'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResultsVariantEnum {
    Base = 'base',
    Proof = 'proof',
    Promo = 'promo',
    Mailin = 'mailin',
    Insert = 'insert',
    Parallel = 'parallel'
}
/**
    * @export
    * @enum {string}
    */
export enum ResultsPlayerAttributeEnum {
    Rc = 'RC',
    Uer = 'UER',
    Aut = 'AUT',
    Etc = 'etc'
}


/**
 * StaticApi - axios parameter creator
 * @export
 */
export const StaticApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all the help links available for the seller on admin site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestSellers(options: undefined = {}): RequestArgs {
            const localVarPath = `/static/event-driven`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} ;
            const localVarQueryParameter = {} ;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary json file representing the carousel and image references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carousel(options: undefined = {}): RequestArgs {
            const localVarPath = `/static/mpCarousel`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} ;
            const localVarQueryParameter = {} ;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Static json file for FAQs on the BSC MarketPlace and Sellers Locker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqs(options: undefined = {}): RequestArgs {
            const localVarPath = `/static/bsc-faqs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} ;
            const localVarQueryParameter = {} ;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the help links available for the seller on admin site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpLinks(options: undefined = {}): RequestArgs {
            const localVarPath = `/static/sellersHelp`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} ;
            const localVarQueryParameter = {} ;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the static footer items to display on the buyers website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceFooter(options: undefined = {}): RequestArgs {
            const localVarPath = `/static/mpFooter`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} ;
            const localVarQueryParameter = {} ;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the static navigation items to display on the buyers website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceNavigation(options: undefined = {}): RequestArgs {
            const localVarPath = `/static/mpNavigation`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} ;
            const localVarQueryParameter = {} ;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the static navigation items to display on the sellers locker/portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellersNavigation(options: undefined = {}): RequestArgs {
            const localVarPath = `/static/sellersNav`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} ;
            const localVarQueryParameter = {} ;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticApi - functional programming interface
 * @export
 */
export const StaticApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all the help links available for the seller on admin site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestSellers(options?: undefined): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>> {
            const localVarAxiosArgs = StaticApiAxiosParamCreator(configuration).bestSellers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary json file representing the carousel and image references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carousel(options?: undefined): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001> {
            const localVarAxiosArgs = StaticApiAxiosParamCreator(configuration).carousel(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Static json file for FAQs on the BSC MarketPlace and Sellers Locker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqs(options?: undefined): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
            const localVarAxiosArgs = StaticApiAxiosParamCreator(configuration).faqs(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the help links available for the seller on admin site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpLinks(options?: undefined): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005> {
            const localVarAxiosArgs = StaticApiAxiosParamCreator(configuration).helpLinks(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the static footer items to display on the buyers website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceFooter(options?: undefined): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003> {
            const localVarAxiosArgs = StaticApiAxiosParamCreator(configuration).marketplaceFooter(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the static navigation items to display on the buyers website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceNavigation(options?: undefined): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002> {
            const localVarAxiosArgs = StaticApiAxiosParamCreator(configuration).marketplaceNavigation(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the static navigation items to display on the sellers locker/portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellersNavigation(options?: undefined): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004> {
            const localVarAxiosArgs = StaticApiAxiosParamCreator(configuration).sellersNavigation(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaticApi - factory interface
 * @export
 */
export const StaticApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary List of all the help links available for the seller on admin site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestSellers(options?: undefined): AxiosPromise<Array<object>> {
            return StaticApiFp(configuration).bestSellers(options)(axios, basePath);
        },
        /**
         * 
         * @summary json file representing the carousel and image references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carousel(options?: undefined): AxiosPromise<InlineResponse2001> {
            return StaticApiFp(configuration).carousel(options)(axios, basePath);
        },
        /**
         * 
         * @summary Static json file for FAQs on the BSC MarketPlace and Sellers Locker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqs(options?: undefined): AxiosPromise<InlineResponse200> {
            return StaticApiFp(configuration).faqs(options)(axios, basePath);
        },
        /**
         * 
         * @summary List of all the help links available for the seller on admin site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpLinks(options?: undefined): AxiosPromise<InlineResponse2005> {
            return StaticApiFp(configuration).helpLinks(options)(axios, basePath);
        },
        /**
         * 
         * @summary List of all the static footer items to display on the buyers website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceFooter(options?: undefined): AxiosPromise<InlineResponse2003> {
            return StaticApiFp(configuration).marketplaceFooter(options)(axios, basePath);
        },
        /**
         * 
         * @summary List of all the static navigation items to display on the buyers website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceNavigation(options?: undefined): AxiosPromise<InlineResponse2002> {
            return StaticApiFp(configuration).marketplaceNavigation(options)(axios, basePath);
        },
        /**
         * 
         * @summary List of all the static navigation items to display on the sellers locker/portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellersNavigation(options?: undefined): AxiosPromise<InlineResponse2004> {
            return StaticApiFp(configuration).sellersNavigation(options)(axios, basePath);
        },
    };
};

/**
 * StaticApi - object-oriented interface
 * @export
 * @class StaticApi
 * @extends {BaseAPI}
 */
export class StaticApi extends BaseAPI {
    /**
     * 
     * @summary List of all the help links available for the seller on admin site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public bestSellers = (options?: undefined) => {
        return StaticApiFp(this.configuration).bestSellers(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary json file representing the carousel and image references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public carousel = (options?: undefined) => {
        return StaticApiFp(this.configuration).carousel(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Static json file for FAQs on the BSC MarketPlace and Sellers Locker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public faqs = (options?: undefined) => {
        return StaticApiFp(this.configuration).faqs(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary List of all the help links available for the seller on admin site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public helpLinks = (options?: undefined) => {
        return StaticApiFp(this.configuration).helpLinks(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary List of all the static footer items to display on the buyers website
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public marketplaceFooter = (options?: undefined) => {
        return StaticApiFp(this.configuration).marketplaceFooter(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary List of all the static navigation items to display on the buyers website
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public marketplaceNavigation = (options?: undefined) => {
        return StaticApiFp(this.configuration).marketplaceNavigation(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary List of all the static navigation items to display on the sellers locker/portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticApi
     */
    public sellersNavigation = (options?: undefined) => {
        return StaticApiFp(this.configuration).sellersNavigation(options)(this.axios, this.basePath);
    }

}


