import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {SellerStatistic} from '../../../../../client/openapitools/seller';
import BSCLoading from '../../../../../features/common/bscLoading/bscLoading';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import {getFormatedNumberWithK} from '../../../../../packages/core/src/util/util';
import SellerDetail from './sellerDetail';
import SellerImages from './sellerImages';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            width: '150%',
        },
    },
    hint: {
        fontSize: 10,
        color: '#182026B2',
    },
    card: {
        padding: 0,
        boxSizing: 'border-box',
    },
}));

interface SellerDetailsProps {
    details: SellerStatistic;
}

const SellerDetails = ({details = {}}: SellerDetailsProps) => {
    const classes = useStyles();
    const {isMobile} = useScreenSnap();

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (details && Object.keys(details).length > 0) {
            setIsLoading(false);
        }
    }, [details]);

    return (
        <>
            {isLoading && <BSCLoading loading={isLoading} />}
            {!isLoading && (
                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={3} md={3}>
                        <SellerDetail
                            value={details.todaysSales ? '$' + getFormatedNumberWithK(Number(details.todaysSales)) : '$0'}
                            title="Today’s Sales"
                            hint="View Report"
                        />
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <SellerDetail value={details.openOrders} title="Open Orders" hint="Start Fulfillment" url="/sellers/orders" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <SellerDetail value={details.buyerMessages} title="Unread Messages" hint="Respond" url="/sellers/messages" />
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <SellerDetail value={details.newOffers} title="New Offers" hint="Review" url="/sellers/offers" />
                    </Grid>
                    {!isMobile && (
                        <Grid item xs={12}>
                            <SellerImages />
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default SellerDetails;
