import React from 'react';
import {Switch, Route} from 'react-router';
import ReferencesClient from '../../../../../../client/references/referencesClient';
import ReplyMessage from './components/replyMessage';
import CreateMessage from './components/createMessage';
import MessageList from './components/messageList';
import {useQuery} from '../../../../../../packages/core/src/hooks/useQuery';

function Messages() {
    const {data: msgRef} = useQuery(() => ReferencesClient.getMessageReferences(), []);

    return (
        <Switch>
            <Route exact path="/dashboard/messages">
                <MessageList msgRef={msgRef} />
            </Route>
            <Route exact path={'/dashboard/messages/reply/:messageId'}>
                <ReplyMessage isMarketPlace messageRef={msgRef} orderUrl="/dashboard/orderHistory" />
            </Route>
            <Route exact path={'/dashboard/messages/create/'}>
                <CreateMessage isMarketPlace messageRef={msgRef} orderUrl="/dashboard/orderHistory" />
            </Route>
        </Switch>
    );
}

export default Messages;
