import React, {useEffect} from 'react';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import {ReactComponent as PaymentIcon} from '../../../../../../../svgs/payment.svg';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import {makeStyles, Grid, DialogTitle, Dialog, DialogContent, DialogActions, Theme} from '@material-ui/core';
import PaymentItem from './paymentItem';
import {useState} from 'react';
import PaymentForm from './paymentForm';
import {Skeleton} from '@material-ui/lab';
import {isArray} from 'lodash';
import BscToggledEditButton from '../../../../../../../features/common/bscEditIcon/bscEditIcon';
import {Alert} from '@material-ui/lab';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import {PaymentApi} from '../../../../../../../client/openapitools/marketplace';
import {useMsal} from '@azure/msal-react';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';

const useStyles = makeStyles((theme: Theme) => ({
    fullWidth: {
        width: '100%',
    },
    btnStyles: {
        marginTop: 10,
        fontSize: '14px',
    },
    borderButton: {
        border: '2px solid rgba(0, 0, 0, 0.54)',
        borderRadius: 15,
    },
    dialogTitle: {
        '& h2': {
            fontSize: '1.25rem',
        },
        padding: '16px 24px 8px 24px',
    },
    dialogSpacing: {
        padding: '8px 16px',
    },
    dialogButtons: {
        padding: '8px 16px',
        justifyContent: 'center',
    },
    alert: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));

function Payment({userBilling, enableSelectCard = false, setPaymentSelected = () => {}, paymentSelected, showRadioButton}) {
    const classes = useStyles();
    const {apiAuthConfig} = useAuthState();
    let paymentApi = new PaymentApi(apiAuthConfig);
    const [isFetching, setIsFetching] = useState(false);
    // const {loading: getPaymentsLoading, data, error, refetch} = useQuery(() => paymentApi.getCustomerPaymentMethods(), []);
    const [getPaymentsLoading, setPaymentsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const refetch = () => {
        setIsFetching(!isFetching);
    };

    const [loading, setLoading] = useState(false);
    const [paymentAction, setPaymentAction] = useState({
        payment: null,
        type: '',
    });
    const apiData = data?.data ?? [];

    const {instance} = useMsal();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    useEffect(() => {
        if (apiAuthConfig) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            paymentApi = new PaymentApi(apiConfig);
                            const response = await paymentApi.getCustomerPaymentMethods();
                            setPaymentsLoading(false);
                            setData(response);
                        } catch (error) {
                            setError(error);
                            console.error('Error fetching Payment Methods', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Payment Methods',
                            });
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    }, [apiAuthConfig, isFetching]);

    const primaryCard = isArray(apiData) && apiData.find(payment => payment.primary);

    useEffect(() => {
        if (primaryCard) {
            setPaymentSelected(primaryCard);
        }
    }, [primaryCard]);

    const isOpen = Boolean(paymentAction.type);
    const isDelete = paymentAction.type === 'delete';
    const isEdit = paymentAction.type === 'edit';
    const visaTitle = paymentAction.payment ? `Card ending in ${paymentAction.payment.maskedCardNumber}` : '';

    const handleOpen = () => {
        setPaymentAction({
            payment: null,
            type: 'add',
        });
    };

    const handleClose = () => {
        setPaymentAction({
            payment: null,
            type: '',
        });
    };

    const handleDeletePayment = () => {
        if (paymentAction.payment) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        setLoading(true);
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const paymentApi = new PaymentApi(apiConfig);
                            const deleteResponse = await paymentApi.detachPaymentMethod(paymentAction.payment.id);
                            if (deleteResponse.status === 200 || deleteResponse.status === 204) {
                                addToast({
                                    severity: 'success',
                                    message: 'Card successfully deleted.',
                                    contextKey: 'Payment Processing',
                                });
                                handleClose();
                                refetch();
                            } else {
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred with this card.',
                                    contextKey: 'Payment Processing',
                                });
                            }
                            setLoading(false);
                        } catch (error) {
                            console.error('Error fetching Messages', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred with this card.',
                                contextKey: 'Payment Processing',
                            });
                            setLoading(false);
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    };

    return (
        <>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <PaymentIcon />
                </Grid>
                <Grid item>
                    <BSCTypography label="Payment" variant="body2" />
                </Grid>
            </Grid>
            <BSCDivider />
            <Grid container>
                {getPaymentsLoading || error ? (
                    <Skeleton height={50} width={200} />
                ) : (
                    <Grid item sm={12}>
                        {isArray(apiData) &&
                            apiData.map(payment => (
                                <PaymentItem
                                    showRadioButton={showRadioButton}
                                    enableSelectCard={enableSelectCard}
                                    paymentSelected={paymentSelected}
                                    setPaymentSelected={setPaymentSelected}
                                    setPaymentAction={setPaymentAction}
                                    key={payment.id}
                                    payment={payment}
                                    canDelete={apiData.length > 1}
                                />
                            ))}
                    </Grid>
                )}
                {userBilling && (
                    <BSCButton onClick={handleOpen} className={classes.btnStyles} color="primary" fullWidth type="submit">
                        Add Card
                    </BSCButton>
                )}
                {!userBilling && (
                    <Alert className={classes.alert} severity="warning" variant="filled">
                        Please add a Billing Address before adding a Payment Method
                    </Alert>
                )}
            </Grid>
            <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <BSCTypography label={isDelete ? '' : isEdit ? `Edit ${visaTitle}` : 'Add card'} variant="body2" />
                        </Grid>
                        <Grid item>
                            {/* this is on a modal  always set it to true */}
                            <BscToggledEditButton isEdit={true} onCLick={handleClose} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                {isDelete ? (
                    <>
                        <DialogContent className={classes.dialogSpacing}>
                            <BSCTypography align="center" label={`Are you sure you want to delete this ${visaTitle}?`} variant="body1" />
                        </DialogContent>
                        <DialogActions className={classes.dialogButtons}>
                            <BSCButton loading={loading} color="primary" onClick={handleDeletePayment}>
                                Delete
                            </BSCButton>
                            <BSCButton onClick={handleClose} autoFocus>
                                Cancel
                            </BSCButton>
                        </DialogActions>
                    </>
                ) : (
                    <DialogContent className={classes.dialogSpacing}>
                        <PaymentForm paymentItem={paymentAction.payment} handleClose={handleClose} refetch={refetch} isEdit={isEdit} />
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}

export default Payment;
