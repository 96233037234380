import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import React from 'react';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) => ({
    notificationsWrapper: {
        height: '100%',
        paddingTop: '36%',
        paddingLeft: '15%',
        paddingRight: '15%',
    },
    selectionWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        marginBottom: 6,
    },
    emptySelectionWrapper: {
        height: 64,
        backgroundColor: 'transparent',
        marginBottom: 6,
    },
    largeIcon: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        padding: 8,
        '& svg': {
            height: 32,
            width: 32,
        },
    },
}));

function PWANotifications() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.notificationsWrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            {/* <NotificationsIcon style={{width: '7em', height: '7em'}} /> */}
                            <BSCImage src="/img/notifications.png" style={{height: 180}} />
                            <BSCTypography label="Coming Soon..." style={{fontSize: 28, fontWeight: 700}} />
                            <BSCTypography label="Notification Feed!" style={{fontSize: 28, fontWeight: 700}} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default PWANotifications;
