import BSCModal from '../../../../../features/common/bscModal/bscModal';
import BSCTextField from '../../../../../features/common/bscTextField/bscTextField';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import Grid from '@material-ui/core/Grid';
import {useCallback, useState} from 'react';

const styles = {
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
};

interface ShippingModalProps {
    open: boolean;
    handleModalClose(): void;
    onShippingSave(trackingNumber: string): void;
    requireTrackingNumber: boolean;
}

function ShippingModal({open, handleModalClose, onShippingSave, requireTrackingNumber}: ShippingModalProps) {
    const [trackingNumber, setTrackingNumber] = useState<string>(null);

    const handleTextField = useCallback(e => {
        setTrackingNumber(e.target.value);
    }, []);

    const handleShippingSave = useCallback(() => {
        if (trackingNumber || !requireTrackingNumber) {
            onShippingSave(trackingNumber);
        }
    }, [trackingNumber, onShippingSave]);

    return (
        <BSCModal open={open} onClose={handleModalClose}>
            <div>
                <BSCTypography align="center" label="Mark as Shipped" variant="h3" />
                <BSCDivider />
                <div style={styles.marginBottom}>
                    <BSCTypography align="center" label="Enter Tracking #" variant="h5" />
                    <BSCTypography align="center" label="(if applicable)" variant="subtitle2" />
                </div>
                <BSCTextField
                    inputKey="tracking"
                    fullWidth
                    placeholder="Enter Tracking #"
                    onChange={handleTextField}
                    value={trackingNumber}
                />
                <BSCDivider />
                <div style={styles.overflowHidden}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <BSCButton fullWidth color="primary" onClick={handleModalClose}>
                                <BSCTypography label="Cancel" />
                            </BSCButton>
                        </Grid>
                        <Grid item xs={6}>
                            <BSCButton
                                disabled={!trackingNumber && requireTrackingNumber}
                                fullWidth
                                color="secondary"
                                onClick={handleShippingSave}
                            >
                                <BSCTypography label="Save" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BSCModal>
    );
}

export default ShippingModal;
