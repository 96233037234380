import React, {useCallback, useState} from 'react';
import {Product, ProductConfig, Card} from '../../../../../client/openapitools/seller';
import SellCard from './sellCard';

interface SellYourCardAddProps {
    updateProduct: Function;
    sellerSettings: ProductConfig;
    sellerCardData: Card;
    loading: boolean;
    isVintage: boolean;
}
const SellYourCardAdd = ({updateProduct, sellerSettings, sellerCardData, loading, isVintage}: SellYourCardAddProps) => {
    const clearForm = (_, __, clear) => clear();
    const [product] = useState<Product>({});
    const update = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (formState, {backImg, frontImg, sellerSku}) => {
            const qty = formState.quantity;
            updateProduct({
                ...formState,
                sellerImgBack: backImg?.objectKey,
                sellerImgFront: frontImg?.objectKey,
                sellerSku: sellerSku,
                availableQuantity: qty,
            });
        },
        [updateProduct]
    );
    return (
        <>
            <SellCard
                disabled={false}
                loading={loading}
                isVintage={isVintage}
                setting={sellerSettings}
                sellerCardData={sellerCardData}
                product={product}
                leftButtonLabel="Clear"
                rightButtonLabel="Add"
                onFormSubmit={update}
                onLeftButtonClick={clearForm}
            />
        </>
    );
};

export default SellYourCardAdd;
