import React from 'react';

const AlertContext = React.createContext({});

const AlertContextProvider = AlertContext.Provider;
/*  eslint-disable @typescript-eslint/no-explicit-any */

export const useAlertContext = (): undefined => {
    const context = React.useContext(AlertContext);
    if (context === undefined) {
        throw new Error('useAlertContext must be used within an AlertContextProvider');
    }
    return context;
};

export default AlertContextProvider;
