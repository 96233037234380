import React, {useCallback, useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Rating from '@material-ui/lab/Rating';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../../../../features/common/skeletons/BSCTableRowSkeleton';
import BSCPagination from '../../../../../../../features/common/bscPagination/bscPagination';
import Normalizer from '../../../../../../../client/common/normalizer/normalizer';
import {Feedback} from '../../../../../../../client/openapitools/seller';
import classNames from 'classnames';
import useDateTimeNormalizer from '../../../../../../../client/common/normalizer/dateTimeNormalizer';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        padding: '0',
        marginBottom: theme.spacing(2),
        overflowX: 'auto',
    },
    table: {
        '& p': {
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '10px',
            },
        },
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
        '&:nth-child(even)': {
            backgroundColor: '#eee',
        },
    },
    th: {
        color: '#41474C',
        padding: ' 8px 15px',
        background: '#DAE0E6',
        borderTop: '2px solid #B8C2CC',
        borderBottom: 'none',
    },
    td: {
        borderBottom: '2px solid #e5e5e5',
    },
    rating: {
        whiteSpace: 'nowrap',
    },
    dateWidth: {
        minWidth: 175,
        [theme.breakpoints.down('xs')]: {
            minWidth: 75,
        },
    },
    orderWidth: {
        minWidth: 200,
        [theme.breakpoints.down('xs')]: {
            minWidth: 70,
        },
    },
    buyerWidth: {
        minWidth: 105,
        [theme.breakpoints.down('xs')]: {
            minWidth: 75,
        },
    },
    commentWidth: {
        minWidth: 300,
    },
}));

interface FeedbackListProps {
    page: number;
    sizePerPage: number;
    feedbackList: Array<Feedback>;
    loading: boolean;
    onPageChange: Function;
}

const FeedbackList = ({page, sizePerPage, feedbackList, loading, onPageChange}: FeedbackListProps) => {
    const classes = useStyles();
    const dateNormalizer = useDateTimeNormalizer();
    const [pagedResults, setPagedResults] = useState([]);
    const pageHandler = useCallback(
        (event, page) => {
            onPageChange(page);
            window.scrollTo(0, 0);
        },
        [onPageChange]
    );

    useEffect(() => {
        const results = Normalizer.getPagedResult(feedbackList, page - 1, sizePerPage);
        setPagedResults(results);
    }, [page, sizePerPage, feedbackList]);

    return (
        <>
            <BSCCardBox className={classes.root}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" className={classes.table}>
                        <TableHead>
                            {(feedbackList?.length > 0 || loading) && (
                                <TableRow>
                                    <TableCell key="orderDate" className={classNames(classes.th, classes.dateWidth)}>
                                        <BSCTypography label="order date" size14 capitalize semibold />
                                    </TableCell>
                                    <TableCell key="rating" className={classes.th}>
                                        <BSCTypography label="rating" size14 capitalize semibold />
                                    </TableCell>
                                    <TableCell key="orderId" className={classNames(classes.th, classes.orderWidth)}>
                                        <BSCTypography label="order #" size14 capitalize semibold />
                                    </TableCell>
                                    <TableCell key="buyer" className={classNames(classes.th, classes.buyerWidth)}>
                                        <BSCTypography label="buyer" size14 capitalize semibold />
                                    </TableCell>
                                    <TableCell key="comment" className={classNames(classes.th, classes.commentWidth)}>
                                        <BSCTypography label="feedback" size14 capitalize semibold />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableHead>
                        <TableBody>
                            {loading && <BSCTableRowSkeleton amountOfRows={4} amountOfCells={5} />}
                            {pagedResults &&
                                pagedResults.map((feedback, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="button"
                                            tabIndex={-1}
                                            key={feedback.orderId + '-' + index}
                                            className={classes.tr}
                                        >
                                            <TableCell key="orderDate" className={classes.td}>
                                                <BSCTypography label={dateNormalizer.getDisplayDateTime(feedback.orderDate)} />
                                            </TableCell>
                                            <TableCell key="rating" className={classes.rating}>
                                                <Rating
                                                    className={classes.rating}
                                                    name="half-rating"
                                                    size="small"
                                                    defaultValue={Number(feedback.rating)}
                                                    precision={0.5}
                                                    readOnly
                                                />
                                            </TableCell>
                                            <TableCell key="orderId" className={classes.td}>
                                                <BSCTypography label={feedback.orderNo} />
                                            </TableCell>
                                            <TableCell key="buyerName" className={classes.td}>
                                                <BSCTypography label={feedback.buyerName} />
                                            </TableCell>
                                            <TableCell key="comment" className={classes.td}>
                                                <BSCTypography
                                                    label={
                                                        feedback.comment !== '' && feedback.comment !== undefined
                                                            ? feedback.comment
                                                            : 'No Comment Left'
                                                    }
                                                    style={feedback.comment !== '' && feedback.comment !== undefined ? {} : {color: '#888'}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </BSCCardBox>
            <BSCPagination total={feedbackList?.length} sizePerPage={sizePerPage} defaultPage={page} onChange={pageHandler} />
        </>
    );
};

export default FeedbackList;
