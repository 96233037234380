import {forwardRef, ReactNode} from 'react';
import Tab from '@material-ui/core/Tab';
import {makeStyles, Theme} from '@material-ui/core/styles';

interface StyleProps {
    skinny: boolean;
    micro: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        flex: 1,
        background: '#B8C2CC',
        transition: '.56s ease',
        minWidth: 'auto',
        padding: theme.spacing(0.2),
        minHeight: ({skinny, micro}: StyleProps) => (micro ? 20 : skinny ? 28 : 46),
        fontSize: ({skinny, micro}: StyleProps) => (skinny || micro ? theme.typography.subtitle2.fontSize : 16),
        maxWidth: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: ({skinny, micro}: StyleProps) =>
                skinny || micro ? theme.typography.subtitle2.fontSize : theme.typography.body2.fontSize,
        },
        textTransform: 'capitalize',
        fontWeight: 'normal',
    },
    tabSelected: {
        background: '#F5F8FA',
        boxShadow: 'inset 0px 4px 4px #FFFFFF',
        borderRadius: 4,
        color: 'black !important',
    },
    micro: {
        minHeight: 20,
        fontSize: 12,
    },
}));

interface BSCTabProps {
    label: ReactNode;
    skinny?: boolean;
    classesOverrides?: Object;
    micro?: boolean;
}
const BSCTab = forwardRef<HTMLButtonElement, BSCTabProps>(
    ({label, skinny = false, micro = false, classesOverrides = {}, ...otherProps}: BSCTabProps, ref) => {
        const classes = useStyles({skinny, micro});
        return (
            <Tab
                {...otherProps}
                component="button"
                ref={ref}
                classes={{root: classes.tab, selected: classes.tabSelected, ...classesOverrides}}
                label={label}
            />
        );
    }
);

export default BSCTab;
