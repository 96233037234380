import {useEffect, useState} from 'react';
import FormValidationsClient from '../../../../../../../client/s3/formValidations/formValidationsClient';
import FormValidationsNormalizer from '../../../../../../../client/s3/formValidations/formValidationsNormalizer';
import useForm from '../../../../../../../packages/core/src/hooks/useForm';
import {useQuery} from '../../../../../../../packages/core/src/hooks/useQuery';
import {UserApi, UserProfile} from '../../../../../../../client/openapitools/marketplace';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';
import useReduxUser from '../../../../../../../features/hooks/redux/user/useReduxUser';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCGridTableRow from '../../../../../../../features/common/bscGridTableRow/bscGridTableRow';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import BSCTextField from '../../../../../../../features/common/bscTextField/bscTextField';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';

interface UserInfoFormEditProps {
    type: string;
    defaultValue: UserProfile;
    editable: udefined;
    checked?: boolean;
    onChecked?: (s) => void;
    onComplete?: () => void;
}

function UserInfoFormEdit({type, defaultValue, editable, onComplete}: UserInfoFormEditProps) {
    const {
        loading: formValidationsLoading,
        error: formValidationsErrors,
        data: formValidations,
    } = useQuery(() => FormValidationsClient.getFormValidations(), []);
    const {setUser} = useReduxUser();
    const {apiAuthConfig} = useAuthState();
    const [loading, setLoading] = useState(false);
    const loadingReasons = {['Loading Form Details']: formValidationsLoading};
    const {instance} = useMsal();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const {
        formState,
        validatedSchema: formStateValidations,
        isErrors: isFormErrors,
        registerFormChange,
        registerFormBlur,
        setDefaultValues,
        formComplete,
    } = useForm({
        validationSchema: FormValidationsNormalizer.getUserInfoValidationsSchema(formValidations),
    });

    useEffect(() => {
        setDefaultValues({
            username: defaultValue?.username,
            firstName: editable?.firstName,
            lastName: editable?.lastName,
            phoneNumber: editable?.phoneNumber,
        });
    }, [defaultValue, editable]);

    const handleSubmit = e => {
        e.preventDefault();
        if (formComplete) {
            const info: UserProfile = {
                user: {
                    id: defaultValue?.id,
                    username: defaultValue?.username,
                    firstName: formState?.firstName,
                    lastName: formState?.lastName,
                    phoneNumber: formState?.phoneNumber,
                    email: defaultValue?.email,
                },
            };
            // updateProfile({...info, type}).then(() => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                setLoading(true);
                                const userApi = new UserApi(apiConfig);
                                const updateResponse = await userApi.updateUserProfile({...info, type});
                                if (updateResponse.status === 200) {
                                    const response = await userApi.getUserProfile();
                                    setUser(response.data);
                                    onComplete({
                                        firstName: formState?.firstName,
                                        lastName: formState?.lastName,
                                        phoneNumber: formState?.phoneNumber,
                                    });
                                }
                                setLoading(false);
                            } catch (error) {
                                console.error('Error processing user info', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'user info update',
                                });
                                setLoading(false);
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    };

    if (formValidationsErrors) {
        return <Alert severity="error">{formValidationsErrors.message}</Alert>;
    }

    if (formValidationsLoading) {
        return <BSCLoading loading={formValidationsLoading} loadingReasons={loadingReasons} gutterBottom />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <BSCGridTableRow
                flip
                gutterBottom
                col4={<BSCTypography bold label="First Name:" />}
                col8={
                    <BSCTextField
                        onBlur={registerFormBlur}
                        formStateValidations={formStateValidations}
                        inputKey="firstName"
                        fullWidth
                        onChange={registerFormChange}
                    />
                }
            />
            <BSCGridTableRow
                flip
                gutterBottom
                col4={<BSCTypography bold label="Last Name:" />}
                col8={
                    <BSCTextField
                        onBlur={registerFormBlur}
                        formStateValidations={formStateValidations}
                        inputKey="lastName"
                        fullWidth
                        onChange={registerFormChange}
                    />
                }
            />
            <BSCGridTableRow
                flip
                gutterBottom
                col4={<BSCTypography bold label="Phone Number:" />}
                col8={
                    <BSCTextField
                        onBlur={registerFormBlur}
                        formStateValidations={formStateValidations}
                        inputKey="phoneNumber"
                        fullWidth
                        onChange={registerFormChange}
                    />
                }
            />
            <BSCDivider />
            <BSCButton disabled={!formComplete || isFormErrors} loading={loading} fullWidth type="submit" color="primary">
                Save
            </BSCButton>
        </form>
    );
}

export default UserInfoFormEdit;
