/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import React, {useCallback, useEffect, useState, useRef} from 'react';
import {ModalProps, MobileStepper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCEnlargedModal from '../../../../features/common/bscEnlargedModal/bscEnlargedModal';
import {makeStyles, Theme} from '@material-ui/core';
import './productPage.scss';
import Magnifier from 'react-magnifier';
import {useResizeDetector} from 'react-resize-detector';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';

const baseStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%',
        padding: 0,
        cursor: 'zoom-in',
    },
    rootMobile: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '8px',
    },
    stepperMobile: {
        position: 'relative',
        transform: 'none',
        background: 'transparent',
        justifyContent: 'center',
    },
    dotActive: {
        background: theme.palette.primary.main,
        margin: '8px',
    },
    dot: {
        height: '16px',
        width: '16px',
        margin: '8px',
    },
}));

interface productInfoModalProps extends Omit<ModalProps, 'children'> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enlargedImgFront: undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enlargedImgBack: undefined;
    isStockImageFront: boolean;
    isStockImageBack: boolean;
    marketplaceStateSellerName: string;
    ribbonSellerName: string;
    open: boolean;
    onClose: (open: boolean) => void;
}

const productInfoModal = ({
    open,
    enlargedImgFront,
    enlargedImgBack,
    isStockImageFront,
    isStockImageBack,
    marketplaceStateSellerName,
    ribbonSellerName,
    onClose,
}: productInfoModalProps) => {
    const classes = baseStyles();
    const {isMobile} = useScreenSnap();
    const {width, height, ref} = useResizeDetector();
    const [maxHeight, setMaxHeight] = useState(0);
    const [isProductImageFrontView, setProductImageFrontView] = useState(true);
    const [frontImageHeight, setFrontImageHeight] = useState(null);
    const [frontImageWidth, setFrontImageWidth] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const onFrontImageLoad = ({target: img}) => {
        const {offsetHeight, offsetWidth} = img;
        setMaxHeight(window.innerHeight - 120);
        if (offsetHeight <= maxHeight) {
            if (offsetHeight > offsetWidth) {
                setFrontImageHeight(maxHeight * 0.9 + 'px');
            } else {
                setFrontImageWidth(width * 0.5 + 'px');
            }
        } else {
            if (maxHeight < offsetWidth) {
                setFrontImageHeight(maxHeight * 0.9 + 'px');
            } else {
                setFrontImageWidth(width * 0.5 + 'px');
            }
        }
    };

    const [backImageHeight, setBackImageHeight] = useState(null);
    const [backImageWidth, setBackImageWidth] = useState(null);
    const onBackImageLoad = ({target: img}) => {
        const {offsetHeight, offsetWidth} = img;
        if (offsetHeight <= maxHeight) {
            if (offsetHeight > offsetWidth) {
                setBackImageHeight(maxHeight * 0.9 + 'px');
            } else {
                setBackImageWidth(width * 0.5 + 'px');
            }
        } else {
            if (maxHeight < offsetWidth) {
                setBackImageHeight(maxHeight * 0.9 + 'px');
            } else {
                setBackImageWidth(width * 0.5 + 'px');
            }
        }
    };
    const flipCard = () => {
        if (isProductImageFrontView) {
            setProductImageFrontView(false);
            setActiveStep(1);
        } else {
            setProductImageFrontView(true);
            setActiveStep(0);
        }
    };

    useEffect(() => {
        if (width || height) {
            setMaxHeight(height - 120);
            if (frontImageHeight <= height) {
                if (frontImageHeight > frontImageWidth) {
                    setFrontImageHeight(height * 0.9 + 'px');
                } else {
                    setFrontImageWidth(width * 0.5 + 'px');
                }
            } else {
                if (height < frontImageWidth) {
                    setFrontImageHeight(height * 0.9 + 'px');
                } else {
                    setFrontImageWidth(width * 0.5 + 'px');
                }
            }
            if (backImageHeight <= height) {
                if (backImageHeight > backImageWidth) {
                    setBackImageHeight(height * 0.9 + 'px');
                } else {
                    setBackImageWidth(width * 0.5 + 'px');
                }
            } else {
                if (height < backImageWidth) {
                    setBackImageHeight(height * 0.9 + 'px');
                } else {
                    setBackImageWidth(width * 0.5 + 'px');
                }
            }
        }
    }, [width, height]);

    return (
        <BSCEnlargedModal open={open} onClose={onClose}>
            <div className={classes.root} style={isMobile ? {background: '#fff'} : {}}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    justifyContent="center"
                    direction="column"
                    style={isMobile ? {height: 'calc(100vh - 100px)'} : {height: '100vh'}}
                >
                    <Grid ref={ref} item md={12} style={!isMobile ? {flex: 5, transform: 'translateY(5%)'} : {textAlign: 'center'}}>
                        {!isMobile && (
                            <>
                                <img onLoad={onFrontImageLoad} src={enlargedImgFront} style={{position: 'absolute', left: -9999}} />
                                <img onLoad={onBackImageLoad} src={enlargedImgBack} style={{position: 'absolute', left: -4444}} />
                                <Magnifier
                                    src={isProductImageFrontView ? enlargedImgFront : enlargedImgBack}
                                    height={maxHeight}
                                    style={{height: '100%', width: '100%', objectFit: 'contain'}}
                                />
                            </>
                        )}
                        {isMobile && (
                            <>
                                {isProductImageFrontView && (
                                    <img
                                        onLoad={onFrontImageLoad}
                                        src={enlargedImgFront}
                                        style={{
                                            width: 'auto',
                                            maxHeight: '440px',
                                            maxWidth: '98%',
                                            objectFit: 'contain',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.75)',
                                        }}
                                    />
                                )}
                                {!isProductImageFrontView && (
                                    <img
                                        onLoad={onBackImageLoad}
                                        src={enlargedImgBack}
                                        style={{
                                            width: 'auto',
                                            maxHeight: '440px',
                                            maxWidth: '98%',
                                            objectFit: 'contain',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.75)',
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {isProductImageFrontView && isStockImageFront && (
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <BSCTypography label="(Stock Image)" style={{color: '#aaa', fontSize: 10, fontWeight: 400}} />
                            </div>
                        )}
                        {!isProductImageFrontView && isStockImageBack && (
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <BSCTypography label="(Stock Image)" style={{color: '#aaa', fontSize: 10, fontWeight: 400}} />
                            </div>
                        )}
                        {isMobile && (
                            <MobileStepper
                                classes={{dot: classes.dot, dotActive: classes.dotActive}}
                                className={classes.stepperMobile}
                                activeStep={activeStep}
                                onClick={() => flipCard()}
                                steps={2}
                                variant="dots"
                            />
                        )}
                    </Grid>
                    {!isMobile && (
                        <Grid
                            item
                            md={12}
                            style={{
                                flex: 1,
                                width: '100%',
                                position: 'sticky',
                                minHeight: 100,
                                boxShadow: '0 4px 8px #efefef, 0 -4px 8px #ccc',
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                    cursor: 'auto',
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    textAlign: 'center',
                                }}
                            >
                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justifyContent="center"
                                    justifyContent="center"
                                    direction="row"
                                    style={{padding: 16}}
                                >
                                    <Grid item md={6} style={{margin: '0 16px', flexBasis: 'unset'}}>
                                        <img
                                            src={enlargedImgFront}
                                            style={
                                                isProductImageFrontView
                                                    ? {border: 'solid 2px #EF2424', padding: 4, height: '10vh'}
                                                    : {border: 'none', padding: 4, height: '10vh'}
                                            }
                                            onClick={() => setProductImageFrontView(true)}
                                        />
                                    </Grid>
                                    <Grid item md={6} style={{margin: '0 16px', flexBasis: 'unset'}}>
                                        <img
                                            src={enlargedImgBack}
                                            style={
                                                !isProductImageFrontView
                                                    ? {border: 'solid 2px #EF2424', padding: 4, height: '10vh'}
                                                    : {border: 'none', padding: 4, height: '10vh'}
                                            }
                                            onClick={() => setProductImageFrontView(false)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </BSCEnlargedModal>
    );
};

export default productInfoModal;
