import {Box} from '@material-ui/core';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCHeaderBlock from '../../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import Grid from '@material-ui/core/Grid';
import BSCLoading from '../../../../../features/common/bscLoading/bscLoading';
import BuyShippingForm from './buyShippingForm';
import ShippingDetails from './shippingDetails';
import ShippingLabelDetails from './shippingLabel';
import ShippingModal from './shippingModal';
import {OrderApi, Shipment} from '../../../../../client/openapitools/seller';
import useDateTimeNormalizer from '../../../../../client/common/normalizer/dateTimeNormalizer';
import moment from 'moment';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BscToggledEditButton from '../../../../../features/common/bscEditIcon/bscEditIcon';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../../authConfig';
import {Configuration} from '../../../../../client/openapitools/common';

const useStyles = makeStyles(() => ({
    bgGrayLight: {
        backgroundColor: '#F5F8FA',
    },
    boxPadding: {
        padding: '16px',
    },
}));

const VIEWS = {
    BUY_SHIPPING: 'BUY_SHIPPING',
    SHIPPING_DETAILS: 'SHIPPING_DETAILS',
    SHIPPING_LABEL: 'SHIPPING_LABEL',
    DEFAULT: 'DEFAULT',
};

function ShippingStatus({
    orderLoading,
    shippingDetails,
    orderId,
    getSellersOrderInstance,
    orderCost,
    hasTitle = true,
    order,
    refreshOrder,
    handlePrintLabel,
}: {
    orderLoading: boolean;
    shippingDetails: Shipment;
    orderId: string;
    order: Order;
    getSellersOrderInstance: string;
    orderCost: number;
    hasTitle?: boolean;
    refreshOrder?: () => void;
    handlePrintLabel?: () => void;
}) {
    const dollarAmountToRequireTracking = useMemo(() => 50, []);
    const classes = useStyles();
    const [view, setView] = useState(VIEWS.DEFAULT);
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let orderApi = new OrderApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const normalizer = useDateTimeNormalizer();

    const [shippingLabelPutLoading, setShippingLabelPutLoading] = useState(false);
    const updateShippingLabel = trackingNumber => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        setShippingLabelPutLoading(true);
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const newData = {
                                orderId,
                                bscShipped: false,
                                active: true,
                                trackingNumber: trackingNumber ?? '',
                                createdTimestamp: normalizer.getApiDateTime(moment()),
                            };
                            orderApi = new OrderApi(apiConfig);
                            orderApi.updateOrder(newData, orderId).then(response => {
                                if (response.data) {
                                    addToast({
                                        severity: 'success',
                                        message: 'Successfully updated Tracking',
                                    });
                                    refreshOrder();
                                }
                                setShippingLabelPutLoading(false);
                            });
                        } catch (error) {
                            setShippingLabelPutLoading(false);
                            console.error(error);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    setShippingLabelPutLoading(false);
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const loading = orderLoading || shippingLabelPutLoading;

    // const [isShippingStatusRevealed, setShippingStatusRevealed] = useState(false);
    const handleBuy = useCallback(() => {
        //setShippingStatusRevealed(true);
        setView(VIEWS.BUY_SHIPPING);
    }, []);

    const [open, setOpen] = useState(false);

    const handleModalOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleConfirmShipment = useCallback(() => {
        handleModalOpen();
    }, [handleModalOpen]);

    const handleCreateShippingLabel = () => {
        setView(VIEWS.SHIPPING_DETAILS);
        refreshOrder?.();
    };

    const handleCancelBuy = useCallback(() => {
        //   setShippingStatusRevealed(false);
        setView(VIEWS.DEFAULT);
    }, []);

    const handleCancelEdit = (setting?: boolean) => {
        setShippingStatusIsEdit(typeof setting === 'boolean' ? setting : !shippingStatusIsEdit);
    };

    const checkShippingDetailsFileType = () => {
        if (shippingDetails.shippingLabel?.label_download.href.includes('.pdf')) {
            window.open(shippingDetails.shippingLabel?.label_download?.href, '_blank').focus();
        } else {
            handlePrintLabel();
        }
    };

    const handleShippingSave = useCallback(
        (trackingNumber: string) => {
            updateShippingLabel(trackingNumber);
            handleModalClose();
            handleCancelEdit(false);
        },
        [updateShippingLabel, handleModalClose]
    );

    useEffect(() => {
        if (!shippingDetails) {
            setView(VIEWS.DEFAULT);
        } else if (shippingDetails.bscShipped) {
            setView(VIEWS.SHIPPING_DETAILS);
        } else if (!shippingDetails.bscShipped) {
            setView(VIEWS.SHIPPING_LABEL);
        }
    }, [shippingDetails]);

    useEffect(() => {
        setView(VIEWS.DEFAULT);
    }, [orderId]);

    const [shippingStatusIsEdit, setShippingStatusIsEdit] = useState(false);
    const showToggle = useMemo(() => {
        switch (view) {
            case VIEWS.SHIPPING_LABEL:
                return true;
        }
    }, [view]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        orderApi = new OrderApi(apiConfig);
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        // this min height may have been added to line up the card list to the bottom of refunds when there is a single card and no refunds, but its causing extra padding at the bottom of shipment status other solutions should be discussed
        <BSCCardBox p={0} height="100%" className={classes.bgGrayLight}>
            {hasTitle && (
                <BSCHeaderBlock
                    label="Shipping Status"
                    hasGrid={true} //This has to be True for the edit button and header to layout properly.  what is the reason behind this prop its only used here and breaks the layout on desktop.
                    rightButtons={
                        showToggle && <BscToggledEditButton isEdit={shippingStatusIsEdit} onCLick={handleCancelEdit}></BscToggledEditButton>
                    }
                ></BSCHeaderBlock>
            )}
            <Box
                className={classes.boxPadding}
                style={{minHeight: 102, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
            >
                {loading && (
                    <BSCLoading
                        loading
                        loadingReasons={{
                            'Getting Shipping Details': loading,
                            'Adding Tracking Information': shippingLabelPutLoading,
                        }}
                    />
                )}
                {view === VIEWS.BUY_SHIPPING && !loading && (
                    <BuyShippingForm
                        getSellersOrderInstance={getSellersOrderInstance}
                        orderId={orderId}
                        onCreateShippingLabel={handleCreateShippingLabel}
                        onCancelBuy={handleCancelBuy}
                        orderTotal={orderCost}
                    />
                )}
                {view === VIEWS.SHIPPING_DETAILS && !loading && (
                    <ShippingDetails
                        orderId={orderId}
                        shippingDetails={shippingDetails}
                        refreshOrder={refreshOrder}
                        order={order}
                        handlePrintLabel={checkShippingDetailsFileType}
                    />
                )}
                {view === VIEWS.SHIPPING_LABEL && !loading && (
                    <ShippingLabelDetails
                        isEdit={shippingStatusIsEdit}
                        shippingLabelDetails={shippingDetails}
                        onAddTracking={handleConfirmShipment}
                        onCancelEdit={handleCancelEdit}
                        onUpdateTracking={handleShippingSave}
                    />
                )}
                {view === VIEWS.DEFAULT && !loading && (
                    <Grid container align="center" justifyContent="center">
                        <Grid item md={3} xs={12}></Grid>
                        <Grid item md={6} xs={12}>
                            <BSCButton onClick={handleBuy} color="secondary" style={{marginRight: 8, width: 160}}>
                                <BSCTypography size12 label="Buy Shipping" />
                            </BSCButton>
                            <BSCButton onClick={handleConfirmShipment} color="primary" style={{marginLeft: 8, width: 160}}>
                                <BSCTypography size12 label="Mark as Shipped" />
                            </BSCButton>
                        </Grid>
                        <Grid item md={3} xs={12}></Grid>
                    </Grid>
                )}
            </Box>
            {open && (
                <ShippingModal
                    open={open}
                    handleModalClose={handleModalClose}
                    onShippingSave={handleShippingSave}
                    requireTrackingNumber={orderCost > dollarAmountToRequireTracking}
                />
            )}
        </BSCCardBox>
    );
}

export default ShippingStatus;
